/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable react/require-default-props */

'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import autobind from 'autobind-decorator';
import classnames from 'classnames';
import _ from 'lodash';
import { GLOBAL_ELEMENTS } from '@uxpin/shared-components';
import { Link, APPEARANCES } from '@uxpin/design-system';
import RoleItem from './RoleItem';

export default class TeamManagement extends React.Component {
  constructor(props) {
    super(props);

    this.CLASS_NAMES = {
      LOADING: 'loading',
    };
  }

  getTeamManagementUsers() {
    const { membersData, projectMembers } = this.props;

    return membersData.filter((member) => projectMembers.indexOf(member.id_user) !== -1);
  }

  renderRoleItems() {
    const teamUsers = this.getTeamManagementUsers();

    return teamUsers.map((user, id) => (
      // eslint-disable-next-line react/no-array-index-key
      <RoleItem key={id} user={user} />
    ));
  }

  getLoadingClass() {
    const { isProjectMembersLoading } = this.props;

    return isProjectMembersLoading ? this.CLASS_NAMES.LOADING : '';
  }

  @autobind
  openModalboxAddUser(e) {
    e.preventDefault();
    this.props.openModalboxAddUser(this.props.project);
  }

  shouldHideManageUsersBtn() {
    const { permissions } = this.props;

    return _.get(permissions, 'can_add_users_to_projects.selected') !== 'all';
  }

  renderManageUsersBtn() {
    if (this.shouldHideManageUsersBtn()) {
      return null;
    }

    return (
      <Link
        appearance={APPEARANCES.NEUTRAL}
        href="#add-user"
        onClick={this.openModalboxAddUser}>
        Manage Users
      </Link>
    );
  }

  render() {
    const rolesListClassNames = classnames('roles-list', this.getLoadingClass(), GLOBAL_ELEMENTS.UNORDERED_LIST);

    return (
      <section id="team-management">
        <ul className={rolesListClassNames}>
          {this.renderRoleItems()}
        </ul>
        <p className="add-user">
          {this.renderManageUsersBtn()}
        </p>
      </section>
    );
  }
}

TeamManagement.defaultProps = {
  membersData: [],
  projectMembers: [],
  isProjectMembersLoading: false,
};

TeamManagement.propTypes = {
  membersData: PropTypes.arrayOf(PropTypes.object).isRequired,
  projectMembers: PropTypes.array.isRequired,
  isProjectMembersLoading: PropTypes.bool.isRequired,
};
