'use strict';

export const searchActions = {
  SEARCH_PROJECTS_ON: 'SEARCH_PROJECTS_ON',
  SEARCH_PROJECTS_OFF: 'SEARCH_PROJECTS_OFF',
  FETCHING_SEARCH_DATA: 'FETCHING_SEARCH_DATA',
  REQUEST_FETCHING_SEARCH_DATA: 'REQUEST_FETCHING_SEARCH_DATA',
  SEARCH_DATA_RENAME_PROJECT: 'SEARCH_DATA_RENAME_PROJECT',
  START_SEARCH_DATA_ACTION: 'START_SEARCH_DATA_ACTION',
  STOP_SEARCH_DATA_ACTION: 'STOP_SEARCH_DATA_ACTION',
  SEARCH_DATA_CHANGE_GROUP: 'SEARCH_DATA_CHANGE_GROUP',
  SEARCH_DATA_REMOVE_PROJECT: 'SEARCH_DATA_REMOVE_PROJECT',
  SEARCH_DATA_DUPLICATE_PROJECT: 'SEARCH_DATA_DUPLICATE_PROJECT',
  SEARCH_DATA_UPDATE_DUPLICATED_PROJECT: 'SEARCH_DATA_UPDATE_DUPLICATED_PROJECT',
  SEARCH_DATA_UPDATE_DUPLICATED_PROJECT_STATUS: 'SEARCH_DATA_UPDATE_DUPLICATED_PROJECT_STATUS',
  SEARCH_DATA_RENAME_DOCUMENT: 'SEARCH_DATA_RENAME_DOCUMENT',
  SEARCH_DATA_MOVE_DOCUMENT: 'SEARCH_DATA_MOVE_DOCUMENT',
  SEARCH_DATA_REMOVE_DOCUMENT: 'SEARCH_DATA_REMOVE_DOCUMENT',
  SEARCH_DATA_DUPLICATE_DOCUMENT: 'SEARCH_DATA_DUPLICATE_DOCUMENT',
  SEARCH_DATA_UPDATE_DUPLICATED_DOCUMENT: 'SEARCH_DATA_UPDATE_DUPLICATED_DOCUMENT',
  SEARCH_DATA_UPDATE_DUPLICATED_DOCUMENT_STATUS: 'SEARCH_DATA_UPDATE_DUPLICATED_DOCUMENT_STATUS',
};

export const searchElementType = {
  PROJECT: 'project',
  DOCUMENT: 'document',
  PROTOTYPE: 'prototype',
};
