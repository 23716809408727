import { connect } from 'react-redux';
import ExtendTrialFormComponent from '../components/Modalboxes/components/ExtendTrialForm';
import { savePartialLeadForm } from '../../controllers/actions/onboarding.actions';

export const mapStateToProps = (state) => ({
  accountProspectData: state.accountSettings.accountProspectData,
});

export const mapDispatchToProps = (dispatch) => ({
  savePartialLeadForm: (data) => dispatch(savePartialLeadForm(data)),
});

export const ExtendTrialForm = connect(mapStateToProps, mapDispatchToProps)(ExtendTrialFormComponent);
