const imageImports = require.context('dashboard/images', true);

export const images = importAll(imageImports);

function importAll(r) {
  return r.keys().map(r);
}

export function getImagePath(imageName) {
  return imageImports(`./${imageName}`);
}
