/* eslint-disable max-len */
/* eslint-disable react/prop-types */

'use strict';

import React from 'react';
import autobind from 'autobind-decorator';
import { removingGroupsModes } from '../../../../constants/projectsGroupsManagement.constants';

export default class GroupActions extends React.Component {
  @autobind
  onEdit(e) {
    e.preventDefault();
    const { onEdit } = this.props;
    onEdit();
  }

  @autobind
  onDelete(e) {
    e.preventDefault();
    const {
      openGroupsDeleteModal, startDeleteGroup, data, index,
    } = this.props;
    if (data.projects && data.projects.length > 0) {
      openGroupsDeleteModal(data, index);
    } else {
      startDeleteGroup(index, removingGroupsModes.REMOVE, 0);
    }
  }

  render() {
    return (
      <ul>
        <li>
          <a onClick={this.onEdit} href="#edit-name" className="icon-general-pencil only-icon-font blue-hover">Edit group name</a>
        </li>
        <li>
          <a onClick={this.onDelete} href="#delete-name" className="icon-general-trash only-icon-font red-hover">Delete group</a>
        </li>
      </ul>
    );
  }
}
