'use strict';

import { projectSelectorActions } from '../../constants/projectSelector.constants';

function removeProject(selectedProjects, idProject) {
  const projects = Object.assign({}, selectedProjects);
  delete projects[idProject];
  return projects;
}

export function projectSelectorReducer(state = {
  selectedProjects: {},
}, action) {
  let projects;
  switch (action.type) {
    case projectSelectorActions.SELECT_PROJECT:
      projects = Object.assign({}, state.selectedProjects);
      projects[action.data.idProject] = action.data;
      return Object.assign({}, state, {
        selectedProjects: projects,
      });
    case projectSelectorActions.UNSELECT_PROJECT:
      return Object.assign({}, state, {
        selectedProjects: removeProject(state.selectedProjects, action.idProject),
      });
    case projectSelectorActions.CLEAR_SELECTED_PROJECT:
      return Object.assign({}, state, {
        selectedProjects: {},
      });
    default:
      return state;
  }
}
