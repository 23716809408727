/* eslint-disable react/destructuring-assignment,react/jsx-closing-tag-location */
/* eslint-disable react/jsx-indent */

'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import autobind from 'autobind-decorator';
import config from 'react-global-configuration';
import classnames from 'classnames';
import { isEmpty, isFunction, capitalize } from 'lodash';
import { Button } from '@uxpin/design-system';
import { DesignSystemUtils, isSafari } from '@uxpin/shared-components';
// eslint-disable-next-line max-len
import { getEditUri, getCreateFromScratchUri, getUserAvailableLibraries } from '../../../controllers/helpers/designSystems.helper';
import { fixSafariCacheReload } from '../../../utils/browser';
import { DesignSystemsLibrariesSelect } from '../../containers/DesignSystemsLibrariesSelect';
import { DesignSystemsTemplatesSelect } from '../../containers/DesignSystemsTemplatesSelect';
import { goTo } from '../../../utils/router';
import routes from '../../../constants/routes.constants';
import { accountPlanTypes, accountPlanTypesUnique } from '../../../constants/accountSettings.constants';
import { isPlanType, isPlanTypeUnique } from '../../../utils/account';
import { preventDefaultEvent } from '../../../utils/common';
import DesignSystemsCreateOption from './components/DesignSystemsCreateOption';
import { FEATURES, FEATURE_STATUS } from '../../../constants/features.constants';
import { STATUS } from '../../../constants/designSystems.constants';

const OPTION_TYPES = {
  LIBRARY: 'library',
  SCRATCH: 'scratch',
  TEMPLATE: 'template',
};

const NOTIFICATIONS = {
  LIBRARY: (<span>
    To build Design System from library
    <br />
    you need to create any library first.
  </span>),
  SCRATCH: (<span>
    To build Design System from scratch
    <br />
    please delete existing Design System library.
  </span>),
  TEMPLATE: (<span>
    To build Design System from template
    <br />
    please delete existing Design System library.
  </span>),
};

export default class DesignSystemsCreate extends React.Component {
  constructor(props) {
    super(props);

    this.baseUrl = config.get('BASE_URL');
    this.duplicatedSystemTimeout = null;
    this.state = {
      libraryLink: null,
      templateName: null,
      isCreating: false,
      isLibraryEnabled: false,
      isScratchEnabled: false,
      isTemplateEnabled: false,
      optionsSet: false,
      isDSSamplesEnabled: false,
    };

    props.getTemplates();
  }

  componentDidMount() {
    this.props.isDesignSystemsLimitReached()
      .then((isHit) => {
        if (isHit) {
          goTo(routes.DESIGN_SYSTEMS);
        }
      });
  }

  componentWillReceiveProps(nextProps) {
    const {
      isDataFetched, idDuplicatedSystem, status, duplicatedLibraryHash, features,
    } = nextProps;
    const isDSSamplesEnabled = features[FEATURES.DESIGN_SYSTEMS_SAMPLES] === FEATURE_STATUS.ENABLED;

    if (isDataFetched && !this.state.optionsSet) {
      this.setAvailableOptions(nextProps);
    }

    if (this.state.isDSSamplesEnabled !== isDSSamplesEnabled) {
      this.setState({
        isDSSamplesEnabled,
      });
    }

    if (idDuplicatedSystem !== this.props.idDuplicatedSystem) {
      this.setState({
        // eslint-disable-next-line react/no-unused-state
        idDuplicatedSystem,
      });
    }

    const hasDuplicationFinished = this.props.status === STATUS.DUPLICATING && status === STATUS.NORMAL;

    if (hasDuplicationFinished) {
      clearTimeout(this.duplicatedSystemTimeout);
      window.location.href = getEditUri(duplicatedLibraryHash);
    }
  }

  // eslint-disable-next-line complexity
  setAvailableOptions(props) {
    const {
      libraries, plan, isDataFetched, currentUserData, features
    } = props;
    const {
      TRIAL, SYSTEMS, ENTERPRISE, COMPANY, SMALL_TEAM, EDU,
    } = accountPlanTypes;
    const { SMALLTEAM2019Q2, TEAM2019Q1, Y2020M06_PROFESSIONAL } = accountPlanTypesUnique;

    if (!isDataFetched) {
      return;
    }

    const hasDemoPlan = !isEmpty(plan.demo_plan);
    const planName = hasDemoPlan ? plan.demo_plan.name : plan.name;
    const uniqueName = plan.unique_name;

    const isSystemsEligiblePlan = isPlanType(planName, TRIAL) || isPlanType(planName, SYSTEMS)
      || isPlanType(planName, COMPANY) || isPlanType(planName, SMALL_TEAM)
      || isPlanType(planName, EDU) || isPlanTypeUnique(uniqueName, TEAM2019Q1)
      || isPlanTypeUnique(uniqueName, SMALLTEAM2019Q2);

    const isProfessionalEnterprise = isPlanTypeUnique(uniqueName, Y2020M06_PROFESSIONAL);
    const isEnterprise = plan && (isPlanType(plan.name, ENTERPRISE) || isProfessionalEnterprise);

    const isDSFeatureEnabled = features[FEATURES.DESIGN_SYSTEMS] === FEATURE_STATUS.ENABLED;
    const isDSEnabled = isDSFeatureEnabled || isEnterprise;

    const availableLibraries = getUserAvailableLibraries(libraries, currentUserData.id);
    const hasAvailableLibraries = availableLibraries && availableLibraries.length > 0;

    const dsLimitReached = DesignSystemUtils.isDesignSystemsLimitReached(plan);
    const isBelowSystemLimit = isSystemsEligiblePlan && !dsLimitReached;

    const isLibraryEnabled = hasAvailableLibraries && (isDSEnabled || isBelowSystemLimit);
    const isScratchEnabled = isDSEnabled || isBelowSystemLimit;
    const isTemplateEnabled = isDSEnabled || isBelowSystemLimit;

    this.setState({
      isLibraryEnabled,
      isScratchEnabled,
      isTemplateEnabled,
      optionsSet: true,
    });
  }

  @autobind
  createLibraryLink(libraryHash) {
    this.setState({ libraryLink: getEditUri(libraryHash) });
  }

  @autobind
  setTemplateName(templateName) {
    this.setState({ templateName });
  }

  handleDesignSystemCreation(optionType, handler) {
    this.props.isDesignSystemsLimitReached()
      .then((isHit) => {
        if (isHit) {
          this.props.showDesignLimitModal();
          return;
        }

        if (isFunction(handler)) {
          handler();
        }

        this.setState({ isCreating: true });
      });
  }

  @autobind
  handleLinkClick(e, optionType, link) {
    preventDefaultEvent(e);

    if (this.state.isCreating) {
      return;
    }

    this.handleDesignSystemCreation(optionType, () => {
      if (isSafari) {
        window.setTimeout(() => {
          window.location.href = link;
        }, 100);
      }

      window.location.href = link;
    });
  }

  @autobind
  checkDuplicationStatus(idDuplicatedSystem) {
    clearTimeout(this.duplicatedSystemTimeout);

    this.duplicatedSystemTimeout = setTimeout(() => {
      this.props.checkDuplicationStatus(idDuplicatedSystem);
      this.checkDuplicationStatus(idDuplicatedSystem);
    }, 2500);
  }

  @autobind
  handleCreateFromTemplate(e, optionType) {
    preventDefaultEvent(e);

    if (this.state.isCreating) {
      return;
    }

    this.handleDesignSystemCreation(optionType, () => {
      this.props.copyTemplate(this.state.templateName)
        .then((libraryHash) => {
          if (libraryHash) {
            window.location.href = `${getEditUri(libraryHash)}/colors`;
          }
        });
    });
  }

  isButtonDisabled() {
    return this.state.isCreating;
  }

  renderFooterFromLibrary() {
    const librariesList = this.props.libraries;
    const libraryCreateLink = this.state.libraryLink || '#create-from-library';
    const isButtonDisabled = this.isButtonDisabled();

    return (
      <div className="from-library-footer">
        <DesignSystemsLibrariesSelect
          librariesList={librariesList}
          onLibrarySelect={this.createLibraryLink}
          isDisabled={isButtonDisabled}
        />
        <Button
          weight="light"
          className="btn--select-library"
          isDisabled={!this.state.libraryLink || isButtonDisabled}
          onClick={(e) => this.handleLinkClick(e, OPTION_TYPES.LIBRARY, libraryCreateLink)}>
          Select
        </Button>
      </div>
    );
  }

  renderFooterFromTemplate() {
    const templatesList = this.props.templates;
    const isButtonDisabled = this.isButtonDisabled();

    return (
      <div className="from-library-footer">
        <DesignSystemsTemplatesSelect
          templatesList={templatesList}
          onTemplateSelect={this.setTemplateName}
          isDisabled={isButtonDisabled}
        />
        <Button
          weight="light"
          className="btn--select-library"
          isDisabled={!this.state.templateName || isButtonDisabled}
          onClick={(e) => this.handleCreateFromTemplate(e, OPTION_TYPES.TEMPLATE)}>
          Select
        </Button>
      </div>
    );
  }

  renderCreate() {
    const {
      isLibraryEnabled, isScratchEnabled, isTemplateEnabled, optionsSet,
    } = this.state;

    if (!optionsSet) {
      return null;
    }

    const classList = classnames('ds-creator-options', { 'with-ds-samples': this.state.isDSSamplesEnabled });

    return (
      <div className="design-system-create">
        <header className="heading-major">
          <h1 className="title">Create Design System</h1>
        </header>

        <section className={classList}>
          <DesignSystemsCreateOption
            className="create-from-library"
            title="From existing library"
            description="Use existing library as a foundation."
            isDisabled={!isLibraryEnabled}
            notification={NOTIFICATIONS.LIBRARY}>
            {this.renderFooterFromLibrary()}
          </DesignSystemsCreateOption>

          <DesignSystemsCreateOption
            className="create-from-scratch"
            title="From scratch"
            description="Start with empty Design System."
            isDisabled={!isScratchEnabled}
            notification={NOTIFICATIONS.SCRATCH}>
            <Button
              isDisabled={this.isButtonDisabled()}
              onClick={(e) => this.handleLinkClick(e, OPTION_TYPES.SCRATCH, getCreateFromScratchUri())}>
              Create from scratch
            </Button>
          </DesignSystemsCreateOption>

          {this.state.isDSSamplesEnabled
            && (
              <DesignSystemsCreateOption
                className="create-from-template"
                title="From template"
                description="Start with Bootstrap or Material Design."
                isDisabled={!isTemplateEnabled}
                notification={NOTIFICATIONS.TEMPLATE}>
                {this.renderFooterFromTemplate()}
              </DesignSystemsCreateOption>
            )
          }
        </section>
      </div>
    );
  }

  render() {
    fixSafariCacheReload();

    return (
      <div id="design-system-screen" className={classnames({ loading: !this.state.optionsSet })}>
        {this.renderCreate()}
      </div>
    );
  }
}

DesignSystemsCreate.propTypes = {
  libraries: PropTypes.array,
  showDesignLimitModal: PropTypes.func.isRequired,
  isDataFetched: PropTypes.bool,
  templates: PropTypes.array,
  getTemplates: PropTypes.func.isRequired,
  features: PropTypes.object.isRequired,
  idDuplicatedSystem: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
  copyTemplate: PropTypes.func.isRequired,
  checkDuplicationStatus: PropTypes.func.isRequired,
  duplicatedLibraryHash: PropTypes.string.isRequired,
  isDesignSystemsLimitReached: PropTypes.func.isRequired,
};

DesignSystemsCreate.defaultProps = {
  libraries: [],
  templates: [],
  isDataFetched: false,
};
