'use strict';

import { dashboard } from '../dashboardStore';
import { modalsIds } from '../../constants/modal.constants';
import { closeUpgradeModal } from './upgradeModal.actions';
import { openModal, closeModal } from './modal.actions';
import { goTo } from '../../utils/router';
import routes from '../../constants/routes.constants';

const externalCalls = {
  openLimitsModal: (planType, limitType) => {
    dashboard.dispatch(closeUpgradeModal());
    dashboard.dispatch(openModal(modalsIds.LIMITS_EXCEEDED, {
      chosenPlan: planType,
      limitType,
    }));
  },
  closeUpgradeModal: () => {
    dashboard.dispatch(closeUpgradeModal());
  },
  closeTeamCampaignModal: () => {
    dashboard.dispatch(closeModal(modalsIds.TEAM_UPSELL));
    goTo(routes.PROJECTS);
  },
  closeProCampaignModal: () => {
    dashboard.dispatch(closeModal(modalsIds.PRO_UPSELL));
    goTo(routes.PROJECTS);
  },
};

export const callDashFunction = externalCalls;
