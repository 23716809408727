'use strict';

export const projectStatusActions = {
  REQUEST_FETCHING_PROJECT_SETTINGS: 'REQUEST_FETCHING_PROJECT_SETTINGS',
  REQUEST_SAVING_PROJECT_SETTINGS: 'REQUEST_SAVING_PROJECT_SETTINGS',
  FETCH_PROJECT_SETTINGS: 'FETCH_PROJECT_SETTINGS',
  PROJECT_SETTINGS_ERROR: 'PROJECT_SETTINGS_ERROR',
  FETCH_PROGRESS: 'FETCH_PROGRESS',
  SAVE_PROJECT_SETTINGS: 'SAVE_PROJECT_SETTINGS',
  FETCH_PROJECT_MEMBERS: 'FETCH_PROJECT_MEMBERS',
  FETCH_PROJECT_SELECTED_USERS: 'FETCH_PROJECT_SELECTED_USERS',
};
