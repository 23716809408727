/* eslint-disable react/prop-types */

'use strict';

import React from 'react';
import _ from 'lodash';
import { QuickActions } from './components/QuickActions';
import { ProjectActions } from '../../containers/ProjectActionsBar';

export default class ProjectBar extends React.Component {
  constructor(props) {
    super(props);

    this.classes = {
      SHADOW: 'shadow',
    };
  }

  shouldComponentUpdate(nextProps) {
    // eslint-disable-next-line react/destructuring-assignment
    return Object.keys(this.props).some((propName) => !_.isEqual(this.props[propName], nextProps[propName]));
  }

  getStyle() {
    const { isLoading } = this.props;
    if (isLoading) {
      return { visibility: 'hidden' };
    }
  }

  render() {
    const {
      setSortType,
      sortType,
      changeFilterType,
      filterType,
    } = this.props;

    return (
      <div id="project-bar" style={this.getStyle()}>
        <div className="left-panel">
          <QuickActions />
        </div>
        <div className="right-panel">
          <ProjectActions
            setSortType={setSortType}
            sortType={sortType}
            changeFilterType={changeFilterType}
            filterType={filterType}
          />
        </div>
      </div>
    );
  }
}
