/* eslint-disable no-param-reassign */
/* eslint-disable indent */

'use strict';

import config from 'react-global-configuration';
import fetch from 'isomorphic-fetch';
import WebFont from 'webfontloader';

const status = (response) => {
  // eslint-disable-next-line no-shadow
  const { status } = response;
  if (status >= 200 && status < 300) {
    return Promise.resolve(response);
  }

  return Promise.reject(response);
};

const json = (response) => response.json();

const extendOptions = (options) => {
  options = Object.assign({}, {
    credentials: 'include',
    mode: 'cors',
  }, options || {});

  if (options.method !== 'GET') {
    options.headers = options.headers || {};
    options.headers.Accept = 'application/json';
    options.headers['Content-Type'] = 'application/json';
  }

  if (options.body) {
    options.body = JSON.stringify(options.body);
  }

  return options;
};

const doRequest = (url, options, isResponseEmpty) => {
  const promise = fetch.call(this, url, extendOptions(options))
    .then(status);

  if (isResponseEmpty) {
    return promise;
  }

  return promise.then(json);
};

export const getRequestMethods = (request) => ({
  get(url, options = {}, isResponseEmpty = false) {
    options = Object.assign({}, { method: 'GET' }, options);
    return request(url, options, isResponseEmpty);
  },

  patch(url, options = {}, isResponseEmpty = false) {
    options = Object.assign({}, { method: 'PATCH' }, options);
    return request(url, options, isResponseEmpty);
  },

  post(url, options = {}, isResponseEmpty = false) {
    options = Object.assign({}, { method: 'POST' }, options);
    return request(url, options, isResponseEmpty);
  },

  put(url, options = {}, isResponseEmpty = false) {
    options = Object.assign({}, { method: 'PUT' }, options);
    return request(url, options, isResponseEmpty);
  },

  delete(url, options = {}, isResponseEmpty = false) {
    options = Object.assign({}, { method: 'DELETE' }, options);
    return request(url, options, isResponseEmpty);
  },
});

/*
 Hack to load ProximaNova-Light.woff before open modal to prevent slow header loading
 */
export const forceLoadProximaNova = () => {
  WebFont.load({
    custom: {
      families: ['ProximaNova:n3', 'ProximaNova:i4'],
    },
  });
};

export const uploadFile = (url, data) => fetch(config.get('API_URL') + url, {
  credentials: 'include',
  mode: 'cors',
  method: 'POST',
  body: data,
}).then(json);

// PRIMARY API
export const request = (url, options, isResponseEmpty) => {
  url = config.get('API_URL') + url;
  return doRequest(url, options, isResponseEmpty);
};

export const appRequest = (url, options, isResponseEmpty) => {
  url = config.get('APP_URL') + url;
  return doRequest(url, options, isResponseEmpty);
};

Object.assign(request, getRequestMethods(request));
Object.assign(appRequest, getRequestMethods(appRequest));
