/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */

'use strict';

import React from 'react';
import { Cloud, GLOBAL_ELEMENTS } from '@uxpin/shared-components';
import classnames from 'classnames';
import autobind from 'autobind-decorator';
import { insideProjectViewModes } from '../../../../constants/insideProject.constants';

@Cloud()
export default class ViewSettings extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mode: this.props.insideProjectViewMode,
    };

    this.MODE_NAMES = {
      BIG: 'Big',
      SMALL: 'Small',
      LIST: 'List',
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.mode !== nextProps.insideProjectViewMode) {
      this.setState({
        mode: nextProps.insideProjectViewMode,
      });
    }
  }

  renderSelectedView() {
    let currentMode;

    switch (this.state.mode) {
      case insideProjectViewModes.BIG:
        currentMode = this.MODE_NAMES.BIG;
        break;
      case insideProjectViewModes.SMALL:
        currentMode = this.MODE_NAMES.SMALL;
        break;
      case insideProjectViewModes.LIST:
        currentMode = this.MODE_NAMES.LIST;
        break;

      // no default
    }

    return <span>{currentMode}</span>;
  }

  setViewMode(e, mode) {
    e.preventDefault();
    if (this.state.mode !== mode) {
      this.props.setInsideProjectViewMode(mode);
    }
  }

  isSelected(mode) {
    return this.state.mode === mode;
  }

  getSelectedClass(mode) {
    return classnames({ selected: this.isSelected(mode) });
  }

  renderViewModes() {
    const modes = [];

    for (const mode in this.MODE_NAMES) {
      // eslint-disable-next-line no-prototype-builtins
      if (this.MODE_NAMES.hasOwnProperty(mode)) {
        modes.push(
          <li key={mode}>
            <a
              href={`#view-mode-${insideProjectViewModes[mode]}`}
              // eslint-disable-next-line max-len
              className={`icon-general-view-mode-${mode.toLowerCase()} ${this.getSelectedClass(insideProjectViewModes[mode])}`}
              onClick={(e) => this.setViewMode(e, insideProjectViewModes[mode])}>
              {this.MODE_NAMES[mode]}
            </a>
          </li>
        );
      }
    }

    return modes;
  }

  @autobind
  getChevronClass() {
    return classnames('gray-link icon-general-chevron', { active: this.props.isOpen });
  }

  render() {
    const headingClassNames = classnames('select-label', GLOBAL_ELEMENTS.HEADING_5);

    return (
      <div className="cloud-wrapper view-settings">
        <h5 className={headingClassNames}>View:</h5>
        <a href="#open-view-cloud" className={this.getChevronClass()} {...this.props.trigger}>
          {this.renderSelectedView()}
        </a>
        <ul {...this.props.cloud} className="cloud small options-cloud with-icons view-settings-cloud">
          {this.renderViewModes()}
        </ul>
      </div>
    );
  }
}
