'use strict';

export const shareActions = {
  FETCH_SHARE_DATA: 'FETCH_SHARE_DATA',
  REQUEST_SHARING_DOCUMENT_BY_EMAIL: 'REQUEST_SHARING_DOCUMENT_BY_EMAIL',
  SHARE_DOCUMENT_BY_EMAIL: 'SHARE_DOCUMENT_BY_EMAIL',
  SHARE_DOCUMENT_BY_EMAIL_ERROR: 'SHARE_DOCUMENT_BY_EMAIL_ERROR',
  CHECK_SHARE_EMAIL_RECAPTCHA: 'CHECK_SHARE_EMAIL_RECAPTCHA',
  FETCH_EXPORTS_STATUSES: 'FETCH_EXPORTS_STATUSES',
  DISCARD_EXPORT: 'DISCARD_EXPORT',
  REQUEST_EXPORT_DOCUMENT: 'REQUEST_EXPORT_DOCUMENT',
  FINISH_EXPORT_DOCUMENT: 'FINISH_EXPORT_DOCUMENT',
  FETCH_TYPEKIT_FONTS_IN_PROJECT_INFO: 'FETCH_TYPEKIT_FONTS_IN_PROJECT_INFO',
};
