/* eslint-disable max-len */

'use strict';

import { connect } from 'react-redux';
import _ from 'lodash';
import { Prismic } from '@uxpin/shared-components';
import * as currentUser from '../../controllers/actions/currentUser.actions';
import * as roles from '../../controllers/actions/roles.actions';
import * as modal from '../../controllers/actions/modal.actions';
import * as members from '../../controllers/actions/members.actions';
import { checkShareDocumentByEmailReCaptcha } from '../../controllers/actions/share.actions';
import * as slack from '../../controllers/actions/slack.actions';
import * as upgradeModal from '../../controllers/actions/upgradeModal.actions';
import * as status from '../../controllers/actions/status.actions';
import * as experiments from '../../controllers/actions/experiments.actions';

import { closeFeatureUpgrade, closePricing, openPricing } from '../../controllers/actions/pricing.actions';
import { getAccountSettings } from '../../controllers/actions/accountSettings.actions';
import { getFeatures } from '../../controllers/actions/features.actions';
import { fetchSettings } from '../../controllers/actions/settings.actions';
import LayoutComponent from '../components/Layout/Layout';
import { modalsIds } from '../../constants/modal.constants';
import { promotions } from '../../constants/marketing.constants';
import { getPromotionByName } from '../../utils/marketing';
import { getCurrentAccountTrialDates } from '../../utils/account';

const getDocument = (documents, document) => {
  if (!document.idDocument) {
    return {};
  }

  return _.first(documents.filter((doc) => doc.idDocument === document.idDocument));
};

const getValueWithDefaultBool = (value) => value || false;
const getValueWithDefaultObject = (value) => value || {};

export const mapStateToProps = (state) => { // eslint-disable-line complexity
  const { isFetchUserDataSuccess, data } = state.currentUser;
  const { accountSettings, accountProspectData, isFetchAccountProspectDataSuccess, accountPlanFetched } = state.accountSettings;
  const { features } = state.features;
  const { isUpgradeModalOpen, upgradeModalData } = state.upgradeModal;
  const { isSearchMode } = state.search;
  const { data: modalData, openedModalsIds, gdprClosed } = state.modal;
  const { documents } = state.insideProject;
  const { showTermsOfServiceModal } = state.currentUser;
  const { accountPlan } = state.accountSettings;
  const { maintenanceSettings } = state.status;
  const membersCount = state.members.membersData.length;
  const { totalDocumentsCount: documentsCount, totalDocumentsCountFetched: documentsCountFetched } = state.insideProject;
  const { areSettingsFetched, data: settings } = state.settings;
  const marketing = state.marketing;
  const { changelog: changelogItems } = state.prismicContent;
  const { shouldRenderReCaptcha } = state.share;
  const { isFeatureUpgradeOpen, isPricingOpen, featureUpgradeName } = state.pricing;
  const gtmDataReady = documentsCountFetched && isFetchUserDataSuccess && accountPlanFetched;
  const accountTrialDates = gtmDataReady ? getCurrentAccountTrialDates(data.idActiveAccount, data.accounts) : {};
  const gtmData = gtmDataReady ? {
    userId: data.id,
    ownerId: data.activeAccountOwner,
    accountId: data.idActiveAccount,
    projectsCount: documentsCount.used,
    accountLevel: accountPlan.accountLevel,
    accountType: data.accountType,
  } : {};

  const showOnboardingPasswordField = isFetchUserDataSuccess
    ? !_.isEmpty(getPromotionByName(promotions.SIGNED_UP_FROM_URL, data.promotions))
    : false;

  return {
    isFetchUserDataSuccess,
    currentUserData: data,
    accountSettings,
    accountProspectData,
    isFetchAccountProspectDataSuccess,
    features,
    isTrial: getValueWithDefaultBool(accountPlan && accountPlan.is_trial),
    isUpgradeModalOpen,
    isPricingOpen,
    isFeatureUpgradeOpen,
    featureUpgradeName,
    isSearchMode,
    upgradeModalData,
    addUserModalData: getValueWithDefaultObject(modalData[modalsIds.MODALBOX_ADD_USER]),
    isAddUserModalOpen: getValueWithDefaultBool(openedModalsIds[modalsIds.MODALBOX_ADD_USER]),
    modalboxGeneralMessage: modalData[modalsIds.MODALBOX_GENERAL_MESSAGE] || {
      title: '',
      message: '',
    },
    isModalboxGeneralMessageOpen: getValueWithDefaultBool(openedModalsIds[modalsIds.MODALBOX_GENERAL_MESSAGE]),
    modalboxProjectChangeNameData: getValueWithDefaultObject(modalData[modalsIds.MODALBOX_PROJECT_CHANGE_NAME]),
    modalboxProjectDeleteData: getValueWithDefaultObject(modalData[modalsIds.MODALBOX_PROJECT_DELETE]),
    modalboxProjectChangeGroupInfo: getValueWithDefaultObject(modalData[modalsIds.MODALBOX_PROJECT_CHANGE_GROUP_INFO]),
    modalboxProjectChangeGroupData: getValueWithDefaultObject(modalData[modalsIds.MODALBOX_PROJECT_CHANGE_GROUP]),
    modalboxDesignLimitReached: getValueWithDefaultObject(modalData[modalsIds.MODALBOX_DESIGN_LIMIT_REACHED]),
    projectSettingsData: getValueWithDefaultObject(modalData[modalsIds.PROJECT_SETTINGS]),
    approvalProcessData: getDocument(documents, getValueWithDefaultObject(modalData[modalsIds.APPROVAL_PROCESS])),
    isModalboxProjectChangeNameOpen: getValueWithDefaultBool(openedModalsIds[modalsIds.MODALBOX_PROJECT_CHANGE_NAME]),
    isModalboxProjectChangeGroupInfoOpen: getValueWithDefaultBool(openedModalsIds[modalsIds.MODALBOX_PROJECT_CHANGE_GROUP_INFO]),
    isModalboxProjectChangeGroupOpen: getValueWithDefaultBool(openedModalsIds[modalsIds.MODALBOX_PROJECT_CHANGE_GROUP]),
    isModalboxProjectDeleteOpen: getValueWithDefaultBool(openedModalsIds[modalsIds.MODALBOX_PROJECT_DELETE]),
    isModalboxChangelogOpen: getValueWithDefaultBool(openedModalsIds[modalsIds.MODALBOX_CHANGELOG]),
    isProjectSettingsOpen: getValueWithDefaultBool(openedModalsIds[modalsIds.PROJECT_SETTINGS]),
    isApprovalProcessOpen: getValueWithDefaultBool(openedModalsIds[modalsIds.APPROVAL_PROCESS]),
    isDesignLimitReachedModalOpen: getValueWithDefaultBool(openedModalsIds[modalsIds.MODALBOX_DESIGN_LIMIT_REACHED]),
    showTermsOfServiceModal,
    maintenanceSettings,
    accountPlan,
    membersCount,
    documentsCount,
    documentsCountFetched,
    areSettingsFetched,
    accountPlanFetched,
    settings,
    marketing,
    changelogItems,
    gdprClosed,
    shouldRenderReCaptcha,
    showOnboardingPasswordField,
    gtmDataReady,
    gtmData,
    accountTrialDates,
  };
};

export const mapDispatchToProps = (dispatch) => ({
  getUserData: () => {
    dispatch(currentUser.getUserData());
  },

  fetchSettings: () => {
    dispatch(fetchSettings());
  },

  closeUpgradeModal: () => {
    dispatch(upgradeModal.closeUpgradeModal());
  },

  getSlackState: () => {
    dispatch(slack.getState());
  },

  getRoles: () => {
    dispatch(roles.getRoles());
  },

  closeModalboxAddUser: () => {
    dispatch(modal.closeModal(modalsIds.MODALBOX_ADD_USER));
  },

  closeModalboxChangelog: () => {
    dispatch(modal.closeModal(modalsIds.MODALBOX_CHANGELOG));
  },

  closeModalboxProjectChangeName: () => {
    dispatch(modal.closeModal(modalsIds.MODALBOX_PROJECT_CHANGE_NAME));
  },

  closeModalboxProjectChangeGroup: () => {
    dispatch(modal.closeModal(modalsIds.MODALBOX_PROJECT_CHANGE_GROUP));
  },

  closeModalboxProjectDelete: () => {
    dispatch(modal.closeModal(modalsIds.MODALBOX_PROJECT_DELETE));
  },

  closeProjectSettings: () => {
    dispatch(modal.closeModal(modalsIds.PROJECT_SETTINGS));
  },

  closeApprovalProcess: () => {
    dispatch(modal.closeModal(modalsIds.APPROVAL_PROCESS));
  },

  closeDesignLimitReachedModal: () => {
    dispatch(modal.closeModal(modalsIds.MODALBOX_DESIGN_LIMIT_REACHED));
  },

  closeWelcomeModal: (data) => {
    dispatch(modal.closeWelcomeModal(data));
  },

  closeTermsOfServiceModal: (data) => {
    dispatch(modal.closeTermsOfServiceModal(data));
  },

  closeGdprModal: () => {
    dispatch(modal.closeGdprModal());
  },

  closeBillingSystemChangeModal: (data) => {
    dispatch(modal.closeBillingSystemChangeModal(data));
  },

  closeModal: (data) => {
    dispatch(modal.closeModal(data));
  },

  getFeatures: () => {
    dispatch(getFeatures());
  },

  getMembers: () => {
    dispatch(members.getMembers());
  },

  getAccountSettings: () => {
    dispatch(getAccountSettings());
  },

  getStatus: () => {
    dispatch(status.fetchStatus());
  },

  checkShareDocumentByEmailReCaptcha: () => {
    dispatch(checkShareDocumentByEmailReCaptcha());
  },

  experimentsPairIds: () => {
    experiments.pairIds();
  },

  loadChangelogItems: () => {
    dispatch(Prismic.actions.fetchChangelog());
  },

  tagAccount: (timestamp) => {
    experiments.tagAccount(timestamp);
  },

  openPricing: () => {
    dispatch(openPricing());
  },

  closePricing: () => {
    dispatch(closePricing());
  },

  closeFeatureUpgrade: () => {
    dispatch(closeFeatureUpgrade());
  },

});

export const Layout = connect(mapStateToProps, mapDispatchToProps)(LayoutComponent);
