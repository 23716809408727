'use strict';

export const documentsActions = {
  INIT_REMOVING_COUNTER: 'INIT_REMOVING_COUNTER',
  CLEAR_DOCUMENTS_ACTIONS: 'CLEAR_DOCUMENTS_ACTIONS',
  COUNT_TIME_TO_REMOVE_DOCUMENT: 'COUNT_TIME_TO_REMOVE_DOCUMENT',
  REQUEST_ACTION_ON_DOCUMENT: 'REQUEST_ACTION_ON_DOCUMENT',
  STOP_ACTION_ON_DOCUMENT: 'STOP_ACTION_ON_DOCUMENT',
  SET_DOCUMENT_ERROR: 'SET_DOCUMENT_ERROR',
  FETCH_FIRST_PROJECT: 'FETCH_FIRST_PROJECT',
};

export const documentsActionsOnHover = {
  RENAMING: 'RENAMING',
  MOVING: 'MOVING',
  REMOVING: 'REMOVING',
  COUNTING: 'COUNTING',
  RESTORING: 'RESTORING',
  CHECKING_DUPLICATION_STATUS: 'CHECKING_DUPLICATION_STATUS',
};

export const documentsTypes = {
  DOCUMENT: 'document',
  PROTOTYPE: 'prototype',
};
