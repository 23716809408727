/* eslint-disable max-len */

'use strict';

import React from 'react';
import config from 'react-global-configuration';
import _ from 'lodash';
import PropTypes from 'prop-types';

export default function UserTeam(props) {
  const { permissions } = props;

  if (_.isEmpty(permissions) || (permissions.can_add_users_to_account && permissions.can_add_users_to_account.selected === 'no')) {
    return null;
  }

  return (
    <li className="tab">
      <a href={`${config.get('APP_URL')}/dms/UserManagement/Default/`} className="tab-link">Manage your team</a>
    </li>
  );
}

UserTeam.propTypes = {
  permissions: PropTypes.object,
};

UserTeam.defaultProps = {
  permissions: {},
};
