/* eslint-disable react/destructuring-assignment,react/prop-types */

'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export default class Tooltip extends React.Component {
  componentDidMount() {
    this.node.parentNode.dataset.showNode = 'tooltip';
  }

  componentWillUnmount() {
    this.node.parentNode.removeAttribute('data-show-node');
  }

  render() {
    return (
      <em
        ref={(node) => { this.node = node; }}
        className={classnames('tooltip', this.props.className)}>
        {this.props.children}
      </em>
    );
  }
}

Tooltip.propTypes = {
  // eslint-disable-next-line react/require-default-props
  className: PropTypes.string,
};
