/* eslint-disable max-len,react/no-unescaped-entities */
/* eslint-disable react/destructuring-assignment */

'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@uxpin/shared-components';
import autobind from 'autobind-decorator';
import _ from 'lodash';

export default class ModalboxBetaWelcome extends React.Component {
  constructor(props) {
    super(props);
    this.MODAL = {
      WRAPPER_ID: 'beta-welcome-modal-box',
      CLASS_NAMES: ['no-overlay'],
    };
  }

  @autobind
  onClose(e) {
    // eslint-disable-next-line react/prop-types
    const { features } = this.props;
    if (_.isObject(e)) {
      e.preventDefault();
    }

    if (features.uxpin30_dashboard === 'enabled') {
      this.props.onClose({ showWelcomeModal: 'false' });
    } else if (features.dashboard_closed_beta === 'enabled') {
      this.props.onClose({ isNewDashboardEnabled: true });
    }
  }

  render() {
    return (
      <Modal
        wrapperId={this.MODAL.WRAPPER_ID}
        classNames={this.MODAL.CLASS_NAMES}
        isOpen={this.props.isOpen}
        onClose={this.props.onClose}>
        <section className="modal-box-content">
          <h1>Welcome to your new Dashboard</h1>
          <h2>Our redesigned Dashboard helps you and your team better manage design projects.</h2>
          <article className="features">
            <ul>
              <li>
                <h3>Easily group projects</h3>
                <p>Store hundreds of projects in one place with Project Groups. Organize prototypes by simply dragging and dropping them into groups.</p>
              </li>
              <li>
                <h3>
                  Change the project's status
                  <small className="pro-label pro-tooltip">Pro+</small>
                </h3>
                <p>Keep everyone in the loop with our project status feature. Easily update the status and everyone will be informed.</p>
              </li>
              <li>
                <h3>Quickly navigate projects</h3>
                <p>Easily swift between projects in your dashboard. You can enter a one project then simply navigate to any other project.</p>
              </li>
            </ul>
          </article>
          <ul className="btns-group">
            <li>
              <button className="btn-solid big" type="button" onClick={this.onClose}>Lets get organized</button>
            </li>
          </ul>
        </section>
      </Modal>
    );
  }
}

ModalboxBetaWelcome.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
