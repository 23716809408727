/* eslint-disable react/default-props-match-prop-types,jsx-a11y/no-onchange,react/button-has-type */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable react/require-default-props */

'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@uxpin/shared-components';
import autobind from 'autobind-decorator';
import _ from 'lodash';
import { APPEARANCES, Button, Link } from '@uxpin/design-system';

export default class ModalboxDocumentChangeProject extends React.Component {
  constructor(props) {
    super(props);
    this.MODAL = {
      WRAPPER_ID: 'project-change-group-modal-box',
      CLASS_NAMES: ['modal-box-medium-width'],
      TITLE: 'Change project',
    };

    this.state = {
      selectedProject: 0,
      projects: [],
    };
  }

  @autobind
  changeSelectedProject(e) {
    this.setState({
      // eslint-disable-next-line radix
      selectedProject: parseInt(e.target.value),
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.projects) {
      this.setState({
        projects: _.sortBy(nextProps.projects, (project) => project.name.toLowerCase()),
      });
    }
  }

  renderProjects() {
    // eslint-disable-next-line react/no-array-index-key
    return this.state.projects.map((project, id) => (<option key={id} value={id}>{project.name}</option>));
  }

  getDocumentName() {
    const { data } = this.props;

    if (data) {
      return data.name || '';
    }
  }

  @autobind
  save(e) {
    e.preventDefault();
    const { moveDocument, data } = this.props;
    moveDocument(data.idDocument, this.state.projects[this.state.selectedProject].idProject);
    this.props.onClose();
  }

  @autobind
  onClose(e) {
    e.preventDefault();
    this.props.onClose();
  }

  shouldComponentUpdate(nextProps, nextState) {
    const propsNames = Object.keys(ModalboxDocumentChangeProject.propTypes);
    return propsNames.some((propsName) => !_.isEqual(this.props[propsName], nextProps[propsName]))
      || !_.isEqual(this.state, nextState);
  }

  render() {
    return (
      <Modal
        wrapperId={this.MODAL.WRAPPER_ID}
        classNames={this.MODAL.CLASS_NAMES}
        title={this.MODAL.TITLE}
        isOpen={this.props.isOpen}
        onClose={this.onClose}>
        <section className="modal-box-content">
          <div className="full-width">
            <label htmlFor="project-name" className="block-label">{this.getDocumentName()}</label>
            {/* Temporary solution - will be replaced with hulkselect: */}
            <select name="select" value={this.state.selectedProject} onChange={this.changeSelectedProject}>
              {this.renderProjects()}
            </select>
          </div>
        </section>
        <footer>
          <ul className="btns-group">
            <li>
              <Link appearance={APPEARANCES.NEUTRAL} href="#cancel" onClick={this.onClose}>Cancel</Link>
            </li>
            <li>
              <Button onClick={this.save}>Save</Button>
            </li>
          </ul>
        </footer>
      </Modal>
    );
  }

}

ModalboxDocumentChangeProject.defaultProps = {
  data: {},
  isOpen: false,
  moveDocument: _.noop,
  onClose: _.noop,
};

ModalboxDocumentChangeProject.propTypes = {
  data: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  moveDocument: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};
