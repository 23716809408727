import { connect } from 'react-redux';
import StakeholderComponent from '../../../components/ApprovalProcess/components/Stakeholder';
import * as stakeholders from '../../../../controllers/actions/stakeholders.actions';

export const mapStateToProps = () => ({});

export const mapDispatchToProps = (dispatch) => ({
  restoreStakeholder: (idApproval, id) => {
    dispatch(stakeholders.restoreStakeholder(idApproval, id));
  },

  removeStakeholder: (id) => {
    dispatch(stakeholders.removeStakeholder(id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(StakeholderComponent);
