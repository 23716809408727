import { find } from 'lodash';
import { groupTypes } from '../constants/projectsGroupsManagement.constants';
import { types as projectTypes } from '../constants/projects.constants';

export const getActiveProjectsCount = (groups) => {
  let projectsCount = 0;
  groups.forEach((group) => {
    if (group.type !== groupTypes.ARCHIVED) {
      projectsCount += group.projects.length;
    }
  });

  return projectsCount;
};

export const getDiscoverProject = (items) => {
  const groups = items.slice(0);
  const collections = groups.reduce((prev, curr) => [...prev, ...curr.projects], []);

  return find(collections, { type: projectTypes.DISCOVER_ONBOARDING_COLLECTION });
};
