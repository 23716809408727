/* eslint-disable react/destructuring-assignment,react/no-unescaped-entities,react/button-has-type */
/* eslint-disable react/prop-types */

'use strict';

import React from 'react';
import { Modal } from '@uxpin/shared-components';
import PatternReadyImg2x from "dashboard/images/pattern-ready-@2x.png";

export default class ModalboxDownloadPlugin extends React.Component {
  constructor(props) {
    super(props);
    this.MODAL = {
      WRAPPER_ID: 'patterns-info-modal-box',
      CLASS_NAMES: 'center-buttons',
    };
  }

  render() {
    return (
      <Modal
        wrapperId={this.MODAL.WRAPPER_ID}
        classNames={this.MODAL.CLASS_NAMES}
        title="Your Pattern is ready!"
        isOpen={this.props.isOpen}
        onClose={this.props.onClose}>
        <section className="modal-box-content">
          <article>
            <figure>
              <img
                src={PatternReadyImg2x}
                alt="Patterns in the Editor"
                width="521"
                height="318"
              />
            </figure>
            <h5 className="main-info">Find it in the Smart Elements Library, in the Editor's left side panel.</h5>
            <p>Please note: If you already have the Editor opened, you may need to refresh the page.</p>
          </article>
        </section>
        <footer>
          <ul className="btns-group">
            <li>
              <button className="btn-solid btn-wide big" onClick={this.props.onClose}>Got it!</button>
            </li>
          </ul>
        </footer>
      </Modal>
    );
  }
}
