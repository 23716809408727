/* eslint-disable react/destructuring-assignment */

'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import TooltipWithShortcut from '../../Helpers/TooltipWithShortcut';

export default class SearchBtn extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isActive: false,
    };

    this.classes = {
      ACTIVE: 'active',
    };

    this.eventRefs = {
      handleClick: (e) => this.handleClick(e),
    };
  }

  componentWillReceiveProps(nextProps) {
    const { isSearchMode } = nextProps;
    const isActive = isSearchMode;
    this.setState({
      isActive,
    });
  }

  handleClick(e) {
    const { enterSearchMode, exitSearchMode } = this.props;
    // eslint-disable-next-line react/no-access-state-in-setstate
    const isActive = !this.state.isActive;
    e.preventDefault();
    this.setState({
      isActive,
    }, () => {
      if (isActive) {
        enterSearchMode();
      } else {
        exitSearchMode();
      }
    });
  }

  render() {
    return (
      // eslint-disable-next-line max-len
      <a onClick={this.eventRefs.handleClick} href="#search" className={`global-search-btn btn-icon icon-general-search ${this.state.isActive ? this.classes.ACTIVE : ''}`}>
        <TooltipWithShortcut>
          Search
          <small className="shortcut">
            <span className="only-icon-font icon-general-cmd-key">Cmd</span>
&nbsp;+ F
          </small>
        </TooltipWithShortcut>
      </a>
    );
  }
}

SearchBtn.propTypes = {
  enterSearchMode: PropTypes.func.isRequired,
  exitSearchMode: PropTypes.func.isRequired,
  isSearchMode: PropTypes.bool.isRequired,
};
