import { connect } from 'react-redux';
import AddOnsFollowUpModalsComponent from '../components/Layout/helpers/AddOnsFollowUpModals';
import { getAddOnInfo } from '../../controllers/actions/marketing.actions';

export const mapStateToProps = (state) => {
  const { accountPlan } = state.accountSettings;
  const { addOnData } = state.marketing;
  const { currentUser } = state;

  const planName = (accountPlan && accountPlan.name) || null;

  return {
    addOnData,
    currentUser,
    planName,
  };
};

export const mapDispatchToProps = (dispatch) => ({
  getAddOnInfo: (idAddOn) => {
    dispatch(getAddOnInfo(idAddOn));
  },
});

export const AddOnsFollowUpModals = connect(mapStateToProps, mapDispatchToProps)(AddOnsFollowUpModalsComponent);
