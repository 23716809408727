/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/require-default-props */

'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import autobind from 'autobind-decorator';
import _ from 'lodash';

export default class InsideProjectActions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isWorking: false,
      isWorkingMerge: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    const { isDocumentAdding, currentAddedDocument } = nextProps;

    // eslint-disable-next-line react/destructuring-assignment
    if (this.props.isDocumentAdding && !isDocumentAdding && currentAddedDocument) {
      window.location.href = currentAddedDocument.url;
    }
  }

  @autobind
  createDocument(e, openLibraries = false) {
    e.preventDefault();
    const { createDocument, project } = this.props;

    this.setState({
      isWorking: true && !openLibraries,
      isWorkingMerge: !!openLibraries,
    });

    createDocument(project.idProject, null, openLibraries);
  }

  @autobind
  createUXDocument(documentType) {
    const { createDocument, project } = this.props;

    this.setState({
      isWorking: true,
    });

    createDocument(project.idProject, documentType);
  }

  render() {
    return null;
  }
}

InsideProjectActions.propTypes = {
  isDocumentAdding: PropTypes.bool.isRequired,
  project: PropTypes.object.isRequired,
  currentAddedDocument: PropTypes.object,
  createDocument: PropTypes.func.isRequired,
};

InsideProjectActions.defaultProps = {
  createDocument: _.noop,
  project: {},
  currentAddedDocument: {},
  isDocumentAdding: false,
};
