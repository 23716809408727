'use strict';

export const slackActions = {
  FETCH_SLACK_CHANNELS: 'FETCH_SLACK_CHANNELS',
  SLACK_ERROR: 'SLACK_ERROR',
  FETCH_SLACK_STATE: 'FETCH_SLACK_STATE',
  REQUEST_SLACK_MESSAGE_SENDING: 'REQUEST_SLACK_MESSAGE_SENDING',
  SLACK_MESSAGE_HAVE_BEEN_SENT: 'SLACK_MESSAGE_HAVE_BEEN_SENT',
  SLACK_SENDING_ERROR: 'SLACK_SENDING_ERROR',
  SET_SLACK_CHANNEL: 'SET_SLACK_CHANNEL',
  REQUEST_FETCHING_SLACK_CHANNELS: 'REQUEST_FETCHING_SLACK_CHANNELS',
};
