import { pricingActions } from '../../constants/pricing.constants';

export const openPricing = () => ({
  type: pricingActions.OPEN_PRICING,
});

export const closePricing = () => ({
  type: pricingActions.CLOSE_PRICING,
});

export const openFeatureUpgrade = (feature) => ({
  type: pricingActions.OPEN_FEATURE_UPGRADE,
  feature,
});

export const closeFeatureUpgrade = () => ({
  type: pricingActions.CLOSE_FEATURE_UPGRADE,
});
