import mixpanelLib from 'mixpanel-browser';
import config from 'react-global-configuration';

const MIXPANEL_PROJECT_TOKEN = config.get('MIXPANEL_PROJECT_TOKEN');
const API_URL = config.get('API_URL');
// Set this to true to enable Mixpanel logging
const debug = false;

export function getMixpanel(callback) {
  // there is no builtin way to know if the library has loaded or not
  // if get_distinct_id fails, it still has to finish loading
  try {
    mixpanelLib.get_distinct_id();
    callback(mixpanelLib);
  } catch (error) {
    // if not initialized yet, call the callback on 'loaded'
    mixpanelLib.init(MIXPANEL_PROJECT_TOKEN, { debug, loaded: callback });
  }
}

async function getMixpanelProperties() {
  return fetch(`${API_URL}/mixpanel/properties`, { credentials: 'include' })
    .then((response) => (response.status === 200 ? response.json() : null))
    .catch((error) => console.error(error));
}

export async function handleMixpanelProperties(mixpanel) {
  const mixpanelProperties = await getMixpanelProperties();

  if (mixpanelProperties) {
    const {
      user_profile_properties: userProfileProperties,
      group_profile_properties: groupProfileProperties,
      super_properties: superProperties,
      all_id_accounts: allIdAccounts,
    } = mixpanelProperties;

    // set user profile properties
    mixpanel.people.set(userProfileProperties);
    // set super props
    mixpanel.register(superProperties);
    // create/get group and set/update group profile properties
    mixpanel
      .get_group('id_account', groupProfileProperties.id_account)
      .set(groupProfileProperties);
    // make user part of the group
    mixpanel.set_group('id_account', allIdAccounts);
  }
}
