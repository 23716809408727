import React from 'react';
import { getMixpanel } from '../../../../../shared/mixpanel';
import { GLOBAL_LIBRARIES } from '../../../../enums/designLibraries';

export class LibrarySelectorCloud extends React.PureComponent {
  handleAddNewLibrary = (e) => {
    e.preventDefault();
    const { openModalboxAddMergeLibrary } = this.props;
    openModalboxAddMergeLibrary();

    getMixpanel((mixpanel) => {
      mixpanel.track('add_library_modal_viewed');
    });
  }

  render() {
    const { cloud, createMergeDocument } = this.props;

    return (
      <ul {...cloud} className="cloud options-cloud library-selector-cloud">
        {GLOBAL_LIBRARIES.map((lib) => {
          if (!this.props.isMergeLimitedLibrariesEnabled && lib.isLimitedLibrary) {
            return null;
          }

          return (<li onClick={(e) => createMergeDocument(e, lib.name)} className="with-icon">
            <a href={`#${lib.label}`}>{lib.label}</a>
            <i className={lib.icon} />
          </li>);
        })}
        <li className="separator" />
        <li className>
          <a className="ds-icon--font__plus" onClick={this.handleAddNewLibrary} href="#new-lib">Add new Library</a>
        </li>
      </ul>
    );
  }
}
