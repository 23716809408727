/* eslint-disable camelcase */

'use strict';

import _ from 'lodash';
import { insideProjectActions, insideProjectViewModes } from '../../constants/insideProject.constants';
import { projectsSortingTypes } from '../../constants/projects.constants';
import { documentsTypes } from '../../constants/documents.constants';

const {
  // document actions:
  UPDATE_DOCUMENT_STATUS,
  UPDATE_DOCUMENT_COVER,
  UPDATE_DOCUMENT_NAME,
  SET_DOCUMENT_APPROVAL,
  UPDATE_DOCUMENT_PASSWORD,
  UPDATE_DUPLICATED_DOCUMENT,
  UPDATE_DUPLICATED_DOCUMENT_STATUS,
  UPDATE_DOCUMENT_CONVERSION_STATUS,
  UPDATE_FAILED_REIMPORT_STATUS,
  UPDATE_FAILED_SKETCH_IMPORT,
  REMOVE_DOCUMENT,
  DUPLICATE_DOCUMENT,
  UPDATE_DOCUMENT_AFTER_CHROME_EXTENSION_UPLOAD,
  START_CHROME_EXTENSION_UPLOAD,
  CREATE_DOCUMENT_BY_CHROME_EXTENSION,
  CREATE_DOCUMENT_BY_UPLOAD_FILE,
  UPDATE_UPLOAD_DOCUMENT_PROGRESS,
  UPDATE_UPLOAD_DOCUMENT_DATA,

  // inside project actions:
  FETCH_PROJECT_DATA,
  FETCH_DOCUMENTS,
  FETCH_PROJECTS_LIST,
  FETCH_DOCUMENTS_COUNT,
  REQUEST_FETCHING_PROJECT_DATA,
  REQUEST_CREATING_DOCUMENT,
  CREATE_DOCUMENT,
  UPDATE_PROGRESS,
  STOP_LOADER,
  SET_DOCUMENTS_COMMENTS_COUNT,
  REQUEST_SAVING_PROJECT_MEMBERS,
  SAVE_PROJECT_MEMBERS,
  PROJECT_MEMBERS_SAVING_ERROR,
  SET_DOCUMENT_WORKING_STATE,
  SET_VIEW_MODE,
  SET_SORT_TYPE,
} = insideProjectActions;

const actionUpdateDocumentStatus = (state, action) => (
  Object.assign({}, state, {
    documents: state.documents.map((document) => {
      const newDoc = Object.assign({}, document);
      if (document.idDocument === action.idDocument) {
        newDoc.statusName = action.statusName;
        newDoc.idStatus = action.idStatus;
      }

      return newDoc;
    }),
  })
);

const actionUpdatedocumentCover = (state, action) => (
  Object.assign({}, state, {
    documents: state.documents.map((document) => {
      const newDoc = Object.assign({}, document);
      if (newDoc.idDocument === action.idDocument) {
        newDoc.cover = action.cover;
      }

      return newDoc;
    }),
  })
);

const actionUpdateDocumentName = (state, action) => (
  Object.assign({}, state, {
    documents: state.documents.map((document) => {
      const newDoc = Object.assign({}, document);
      if (document.idDocument === action.idDocument) {
        newDoc.name = action.name;
      }

      return newDoc;
    }),
  })
);

const actionSetDocumentApproval = (state, action) => (
  Object.assign({}, state, {
    documents: state.documents.map((document) => {
      const newDoc = Object.assign({}, document);
      if (document.idDocument === action.idDocument) {
        newDoc.idApproval = action.idApproval;
      }

      return newDoc;
    }),
  })
);

const actionUpdateDocumentPassword = (state, action) => (
  Object.assign({}, state, {
    documents: state.documents.map((document) => {
      const newDoc = Object.assign({}, document);
      if (document.idDocument === action.idDocument) {
        newDoc.password = action.password;
      }

      return newDoc;
    }),
  })
);

const actionUpdateDuplicatedDocument = (state, action) => (
  Object.assign({}, state, {
    documents: state.documents.map((document) => {
      const newDoc = Object.assign({}, document);
      if (document.idDocument === action.temporaryId) {
        newDoc.name = action.documentName;
        newDoc.idDocument = action.idDocument;
        newDoc.hash = action.hash;
        delete newDoc.hasTemporaryId;
        delete newDoc.idApproval;
      }

      return newDoc;
    }),
  })
);

const actionUpdateDuplicatedDocumentStatus = (state, action) => (
  Object.assign({}, state, {
    documents: state.documents.map((document) => {
      const newDoc = Object.assign({}, document);
      if (document.idDocument === action.idDocument) {
        newDoc.status = 0;
      }

      return newDoc;
    }),
  })
);

const actionUpdateDocumentConversionStatus = (state, action) => (
  Object.assign({}, state, {
    documents: state.documents.map((document) => {
      const newDoc = Object.assign({}, document);
      if (document.idDocument === action.idDocument) {
        newDoc.isConverted = action.isConverted;
        newDoc.idMainPage = action.idMainPage;
        newDoc.progress = action.progress;
        newDoc.status = action.status;
      }

      return newDoc;
    }),
  })
);

const actionUpdateFailedReimportStatus = (state, action) => (
  Object.assign({}, state, {
    documents: state.documents.map((document) => {
      const newDoc = Object.assign({}, document);
      if (document.idDocument === action.idDocument) {
        newDoc.status = action.status;
      }

      return newDoc;
    }),
  })
);

const actionUpdateFailedSketchImportStatus = (state, action) => (
  Object.assign({}, state, {
    documents: state.documents.map((document) => {
      const newDoc = Object.assign({}, document);
      if (document.idDocument === action.idDocument) {
        newDoc.status = action.status;
      }

      return newDoc;
    }),
  })
);

const actionRemoveDocument = (state, action) => (
  Object.assign({}, state, {
    documents: state.documents.filter((document) => document.idDocument !== action.idDocument),
  })
);

const actionDuplicateDocument = (state, action) => {
  const document = Object.assign({}, _.find(state.documents, (doc) => doc.idDocument === action.idDocument));

  if (!document) {
    return Object.assign({}, state, {
      documents: state.documents,
    });
  }

  document.status = 5;
  document.name = `${document.name} - Copy`;
  document.hasTemporaryId = true;
  document.idDocument = action.temporaryId;

  return Object.assign({}, state, {
    documents: [...state.documents, document],
  });
};

const actionUpdateDocumentAfterChromeExtensionUpload = (state, action) => (
  Object.assign({}, state, {
    documents: state.documents.map((document) => {
      const newDoc = Object.assign({}, document);
      if (newDoc.idDocument === action.idDocument) {
        delete newDoc.isFilesUploading;
        delete newDoc.isChromeExtensionUpload;
        newDoc.status = 0;
        newDoc.hash = action.data.hash;
        newDoc.idProject = parseInt(action.data.id_collection, 10);
        newDoc.idDocument = parseInt(action.data.id_project, 10);
        newDoc.idOriginalDocument = parseInt(action.data.first_id_document, 10);
        newDoc.statusName = action.data.progress_status_name;
        newDoc.idStatus = parseInt(action.data.progress_status, 10);
        newDoc.type = documentsTypes.DOCUMENT;
      }

      return newDoc;
    }),
  })
);

const actionStartChromeExtensionUpload = (state, action) => (
  Object.assign({}, state, {
    documents: state.documents.map((document) => {
      const newDoc = Object.assign({}, document);
      if (newDoc.idDocument === action.idDocument) {
        newDoc.isFilesUploading = true;
      }

      return newDoc;
    }),
  })
);

const actionCreateDocumentByChromeExtension = (state, action) => {
  const document = {};
  document.idDocument = action.idDocument;
  document.fileId = action.fileId;
  document.name = action.fileName;
  document.isChromeExtensionUpload = true;
  document.insertDate = action.createdAt;
  document.lastUpdate = action.createdAt;
  document.port = action.port;

  return Object.assign({}, state, {
    documents: [...state.documents, document],
  });
};

const actionCreateDocumentByUploadFile = (state, action) => {
  const document = {};
  document.idDocument = action.idDocument;
  document.name = action.fileName;
  document.isFilesUploading = true;
  if (!action.withoutProgress) {
    document.progress = 0;
  }

  document.insertDate = new Date().toISOString();
  document.lastUpdate = new Date().toISOString();

  return Object.assign({}, state, {
    documents: [...state.documents, document],
  });
};

const actionUpdateUploadDocumentProgress = (state, action) => (
  Object.assign({}, state, {
    documents: state.documents.map((document) => {
      const newDoc = Object.assign({}, document);
      if (document.idDocument === action.idDocument) {
        newDoc.progress = action.progress;
      }

      return newDoc;
    }),
  })
);

const actionUpdateUploadDocumentData = (state, action) => (
  Object.assign({}, state, {
    documents: state.documents.map((document) => {
      let newDoc = Object.assign({}, document);
      if (document.idDocument === action.idDocument && action.data.idDocument) {
        newDoc = action.data;
      }

      return newDoc;
    }),
  })
);

const documentActionHandler = {
  [UPDATE_DOCUMENT_STATUS]: actionUpdateDocumentStatus,
  [UPDATE_DOCUMENT_COVER]: actionUpdatedocumentCover,
  [UPDATE_DOCUMENT_NAME]: actionUpdateDocumentName,
  [SET_DOCUMENT_APPROVAL]: actionSetDocumentApproval,
  [UPDATE_DOCUMENT_PASSWORD]: actionUpdateDocumentPassword,
  [UPDATE_DUPLICATED_DOCUMENT]: actionUpdateDuplicatedDocument,
  [UPDATE_DUPLICATED_DOCUMENT_STATUS]: actionUpdateDuplicatedDocumentStatus,
  [UPDATE_DOCUMENT_CONVERSION_STATUS]: actionUpdateDocumentConversionStatus,
  [UPDATE_FAILED_REIMPORT_STATUS]: actionUpdateFailedReimportStatus,
  [UPDATE_FAILED_SKETCH_IMPORT]: actionUpdateFailedSketchImportStatus,
  [REMOVE_DOCUMENT]: actionRemoveDocument,
  [DUPLICATE_DOCUMENT]: actionDuplicateDocument,
  [UPDATE_DOCUMENT_AFTER_CHROME_EXTENSION_UPLOAD]: actionUpdateDocumentAfterChromeExtensionUpload,
  [START_CHROME_EXTENSION_UPLOAD]: actionStartChromeExtensionUpload,
  [CREATE_DOCUMENT_BY_CHROME_EXTENSION]: actionCreateDocumentByChromeExtension,
  [CREATE_DOCUMENT_BY_UPLOAD_FILE]: actionCreateDocumentByUploadFile,
  [UPDATE_UPLOAD_DOCUMENT_PROGRESS]: actionUpdateUploadDocumentProgress,
  [UPDATE_UPLOAD_DOCUMENT_DATA]: actionUpdateUploadDocumentData,
};


function handleDocumentActions(state, action) {
  if (!_.has(documentActionHandler, action.type)) {
    return false;
  }

  return documentActionHandler[action.type](state, action);
}


const actionFetchProjectData = (state, action) => (
  Object.assign({}, state, {
    project: action.project,
  })
);

const actionFetchDocuments = (state, action) => (
  Object.assign({}, state, {
    documents: action.documents,
  })
);

const actionFetchProjectsList = (state, action) => (
  Object.assign({}, state, {
    projectsList: action.projectsList,
  })
);

const actionFetchDocumentsCount = (state, action) => (
  Object.assign({}, state, {
    totalDocumentsCount: action.data,
    totalDocumentsCountFetched: true,
  })
);

const actionRequestFetchingProjectData = (state) => (
  Object.assign({}, state, {
    isLoading: true,
    progress: 0,
  })
);

const actionRequestCreatingDocument = (state) => (
  Object.assign({}, state, {
    isDocumentAdding: true,
  })
);

const actionCreateDocument = (state, action) => (
  Object.assign({}, state, {
    isDocumentAdding: false,
    currentAddedDocument: action.data,
  })
);

const actionUpdateProgress = (state, action) => (
  Object.assign({}, state, {
    progress: state.progress + action.progress,
  })
);

const actionStopLoader = (state) => (
  Object.assign({}, state, {
    isLoading: false,
    progress: 1,
  })
);

const actionSetDocumentsCommentsCount = (state, action) => (
  Object.assign({}, state, {
    commentsCount: action.commentsCount,
  })
);

const actionRequestSavingProjectMembers = (state) => (
  Object.assign({}, state, {
    isError: false,
    isProjectMembersSaving: true,
  })
);

const actionSaveProjectMembers = (state) => (
  Object.assign({}, state, {
    isProjectMembersSaving: false,
  })
);

const actionProjectMembersSavingError = (state) => (
  Object.assign({}, state, {
    isError: true,
    isProjectMembersSaving: false,
  })
);

const actionSetDocumentWorkingState = (state, action) => (
  Object.assign({}, state, {
    workingDocumentId: action.workingDocumentId,
  })
);

const actionSetViewMode = (state, action) => (
  Object.assign({}, state, {
    insideProjectViewMode: action.mode,
  })
);

const actionSetSortType = (state, action) => (
  Object.assign({}, state, {
    sortType: action.sortType,
  })
);

const insideProjectsActionHandler = {
  [FETCH_PROJECT_DATA]: actionFetchProjectData,
  [FETCH_DOCUMENTS]: actionFetchDocuments,
  [FETCH_PROJECTS_LIST]: actionFetchProjectsList,
  [FETCH_DOCUMENTS_COUNT]: actionFetchDocumentsCount,
  [REQUEST_FETCHING_PROJECT_DATA]: actionRequestFetchingProjectData,
  [REQUEST_CREATING_DOCUMENT]: actionRequestCreatingDocument,
  [CREATE_DOCUMENT]: actionCreateDocument,
  [UPDATE_PROGRESS]: actionUpdateProgress,
  [STOP_LOADER]: actionStopLoader,
  [SET_DOCUMENTS_COMMENTS_COUNT]: actionSetDocumentsCommentsCount,
  [REQUEST_SAVING_PROJECT_MEMBERS]: actionRequestSavingProjectMembers,
  [SAVE_PROJECT_MEMBERS]: actionSaveProjectMembers,
  [PROJECT_MEMBERS_SAVING_ERROR]: actionProjectMembersSavingError,
  [SET_DOCUMENT_WORKING_STATE]: actionSetDocumentWorkingState,
  [SET_VIEW_MODE]: actionSetViewMode,
  [SET_SORT_TYPE]: actionSetSortType,
};

function handleInsideProjectActions(action, state) {
  if (!_.has(insideProjectsActionHandler, action.type)) {
    return false;
  }

  return insideProjectsActionHandler[action.type](state, action);
}

export function insideProjectReducer(state = {
  isLoading: true,
  progress: 0,
  project: {},
  sortType: projectsSortingTypes.CREATE_DATE_DESC,
  documents: [],
  commentsCount: {},
  projectsList: [],
  currentAddedDocument: null,
  isDocumentAdding: false,
  isProjectMembersSaving: false,
  isError: false,
  workingDocumentId: 0,
  insideProjectViewMode: insideProjectViewModes.BIG,
  totalDocumentsCountFetched: false,
}, action) {
  return handleDocumentActions(state, action) || handleInsideProjectActions(action, state) || state;
}
