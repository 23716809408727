'use strict';

export const infobarActions = {
  FETCH_SPECMODE_REQUEST_DEMO_EMAIL: 'FETCH_SPECMODE_REQUEST_DEMO_EMAIL',
  SAVE_SPECMODE_REQUEST_DEMO_EMAIL: 'SAVE_SPECMODE_REQUEST_DEMO_EMAIL',
};

export const infobarIds = {
  SPECMODE_WINBACK: 'specmode3',
  SPECMODE_WINBACK_FOLLOW_UP: 'specmode3-followup',
  SPECMODE_REQUEST_DEMO: 'specmode-requestdemo2',
  SPECMODE_REQUEST_DEMO_FOLLOW_UP: 'specmode-requestdemo2-followup',
  PLAN_SWITCH_DISCOUNT: 'plan-switch-discount-info-bar',
  JULY_DS_TRIAL_NOTIFICATION: 'july-ds-trial-notification-bar',
  ADD_ON_SPEC_MODE_PROMO: 'add-on-spec-mode-promo-bar',
  ADD_ON_CUSTOM_FONTS_PROMO: 'add-on-custom-fonts-promo-bar',
  PROTOTYPING_UPSELL: 'prototyping-upsell-bar',
  ADD_ON_FREE_FLOW_DOCS_PROMO: 'add-on-free-flow-docs-promo-bar',
};
