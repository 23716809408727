'use strict';

export const rolesActions = {
  FETCH_ROLES: 'FETCH_ROLES',
};

export const rolesId = {
  OWNER: 1,
  MANAGER: 2,
  CREATOR: 3,
  COLLABORATOR: 4,
  ADMIN: 5,
  DEVELOPER: 6,
};
