import { connect } from 'react-redux';
import DesignSystemsTemplatesSelectComponent from '../components/DesignSystems/components/DesignSystemsTemplatesSelect';

export const mapStateToProps = (state) => {
  const { templates } = state.designSystems;

  return {
    templates,
  };
};

export const DesignSystemsTemplatesSelect = connect(mapStateToProps)(DesignSystemsTemplatesSelectComponent);
