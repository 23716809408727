/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable react/require-default-props */

'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalboxGeneralMessage } from '@uxpin/shared-components';
import autobind from 'autobind-decorator';
import classnames from 'classnames';
import _ from 'lodash';
import EmptyView from './components/EmptyView';
import StakeholdersView from '../../containers/ApprovalProcess/components/StakeholdersView';
import ModalboxAddStakeholder from '../../containers/ApprovalProcess/modalboxes/ModalboxAddStakeholder';
import ModalboxSendRequest from '../../containers/ApprovalProcess/modalboxes/ModalboxSendRequest';

export default class ApprovalProcess extends React.Component {
  constructor(props) {
    super(props);
    this.MODAL = {
      WRAPPER_ID: 'approval-process-modal-box',
    };

    this.state = {
      isModalboxAddStakeholderOpen: false,
      isModalboxGeneralMessageOpen: false,
      isModalboxSendRequestOpen: false,
      isHidden: false,
      isNewRequest: false,
      currentStatus: '',
      selectedStakeholder: null,
      generalMessageTitle: 'Restart the approval process',
      generalMessageContent: 'Restarting the process resets the approval status for every stakeholder. '
                             + "Your stakeholders won't be removed. "
                             + 'However, everything else will get a fresh start.',
      generalMessageBtnLabel: 'Restart',
      generalMessageBtnClass: 'red',
    };
  }

  @autobind
  onOpen() {
    const { fetchStakeholders, data } = this.props;
    const idApproval = data.idApproval;
    const idDocument = data.idDocument;
    fetchStakeholders(idDocument, idApproval);
  }

  @autobind
  onClose(e) {
    const { onClose, clearStakeholders } = this.props;
    e.preventDefault();
    onClose();

    setTimeout(() => {
      clearStakeholders();
    }, 500);
  }

  @autobind
  openModalboxAddStakeholder(e, selectedStakeholder) {
    e.preventDefault();
    this.setState({
      isModalboxAddStakeholderOpen: true,
      isHidden: true,
      selectedStakeholder,
    });
  }

  @autobind
  closeModalboxAddStakeholder() {
    this.setState({
      isModalboxAddStakeholderOpen: false,
      isHidden: false,
    });
  }

  @autobind
  openModalboxGeneralMessage(e) {
    e.preventDefault();
    this.setState({
      isModalboxGeneralMessageOpen: true,
      isHidden: true,
    });
  }

  @autobind
  closeModalboxGeneralMessageOpen() {
    this.setState({
      isModalboxGeneralMessageOpen: false,
      isHidden: false,
    });
  }

  @autobind
  openModalboxSendRequest(stakeholder, isNewRequest, currentStatus) {
    this.setState({
      isModalboxSendRequestOpen: true,
      selectedStakeholder: stakeholder,
      isHidden: true,
      isNewRequest,
      currentStatus,
    });
  }

  @autobind
  closeModalboxSendRequest() {
    this.setState({
      isModalboxSendRequestOpen: false,
      isHidden: false,
    });
  }

  renderClassNames() {
    const { isLoading, stakeholders } = this.props;
    return classnames({ 'empty center-buttons': !stakeholders.length, loading: isLoading });
  }

  @autobind
  renderTitle() {
    const { data } = this.props;

    return (
      <h2>
        <span className="title">Approvals</span>
        <span className="project-name">{data.name}</span>
      </h2>
    );
  }

  @autobind
  renderDescription() {
    return (
      <p>
        Choose who can approve your design by sending an approval request.
        <br />
        Need to start over?
        {' '}
        <a className="blue-link underline-link" href="#restart" onClick={this.openModalboxGeneralMessage}>Restart</a>
        {' '}
        the approval process.
      </p>
    );
  }

  @autobind
  renderEmptyView() {
    return (
      <EmptyView openModalboxAddStakeholder={this.openModalboxAddStakeholder} />
    );
  }

  @autobind
  renderStakeholdersView() {
    return (
      <section className="modal-box-content">
        <StakeholdersView
          document={this.props.data}
          stakeholders={this.props.stakeholders}
          openModalboxAddStakeholder={this.openModalboxAddStakeholder}
          openModalboxConfirmSend={this.openModalboxConfirmSend}
          openModalboxSendRequest={this.openModalboxSendRequest}
        />
      </section>
    );
  }

  @autobind
  restartApproval() {
    const { restartApproval, data } = this.props;
    const idApproval = data.idApproval;
    const idDocument = data.idDocument;

    restartApproval(idDocument, idApproval);
  }

  componentWillReceiveProps(nextProps) {
    const { isRequestBeingModified } = this.props;

    if (isRequestBeingModified && !nextProps.isRequestBeingModified && !nextProps.isRequestSendingError) {
      this.closeModalboxGeneralMessageOpen();
    }
  }

  renderContent() {
    if (this.props.stakeholders.length) {
      return this.renderStakeholdersView();
    }

    return this.renderEmptyView();
  }

  renderAddStakeholderLink() {
    if (!this.props.stakeholders.length) {
      return null;
    }

    return (
      <nav>
        <a
          href="#add-stakeholder"
          className="btn-blue-simple-add with-underline"
          onClick={(e) => this.openModalboxAddStakeholder(e)}>
          <span>Add stakeholder</span>
        </a>
      </nav>
    );
  }

  render() {
    return (
      <Modal
        wrapperId={this.MODAL.WRAPPER_ID}
        classNames={this.renderClassNames()}
        renderTitle={this.renderTitle}
        renderDescription={this.renderDescription}
        isOpen={this.props.isOpen}
        onOpen={this.onOpen}
        onClose={this.onClose}
        isHidden={this.state.isHidden}>
        {this.renderContent()}
        {this.renderAddStakeholderLink()}
        <ModalboxAddStakeholder
          isOpen={this.state.isModalboxAddStakeholderOpen}
          document={this.props.data}
          stakeholder={this.state.selectedStakeholder}
          onStakeholderAdded={this.fetchStakeholdersList}
          onClose={this.closeModalboxAddStakeholder}
        />
        <ModalboxSendRequest
          isOpen={this.state.isModalboxSendRequestOpen}
          document={this.props.data}
          stakeholder={this.state.selectedStakeholder}
          isNewRequest={this.state.isNewRequest}
          currentStatus={this.state.currentStatus}
          onClose={this.closeModalboxSendRequest}
        />
        <ModalboxGeneralMessage
          isOpen={this.state.isModalboxGeneralMessageOpen}
          isError={this.props.isRequestSendingError}
          isWorking={this.props.isRequestBeingModified}
          onAccept={this.restartApproval}
          onClose={this.closeModalboxGeneralMessageOpen}
          title={this.state.generalMessageTitle}
          message={this.state.generalMessageContent}
          btnLabel={this.state.generalMessageBtnLabel}
          btnClass={this.state.generalMessageBtnClass}
        />
      </Modal>
    );
  }
}

ApprovalProcess.defaultProps = {
  isOpen: false,
  onClose: _.noop,
};

ApprovalProcess.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
