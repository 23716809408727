'use strict';

import { isUndefined } from 'lodash';
import { modalActions } from '../../constants/modal.constants';
import { updateSettings, saveSettings } from './settings.actions';
import { fetchTermsOfServiceModalVisibility } from './currentUser.actions';
import { fetchAccountSettings } from './accountSettings.actions';
import { request } from '../../utils/request';

export const openModal = (id, data) => ({
  type: modalActions.OPEN_MODAL,
  idModal: id,
  data,
});

export const closeModal = (id) => ({
  type: modalActions.CLOSE_MODAL,
  idModal: id,
});

export const closeOpenedModals = () => ({
  type: modalActions.CLOSE_OPENED_MODAL,
});

export const closeWelcomeModal = (data) => {
  if (!isUndefined(data.showWelcomeModal)) {
    return updateSettings('showWelcomeModal', data.showWelcomeModal);
  }

  request.put('/account/settings', {
    body: data,
  });
  return fetchAccountSettings(data);
};

export const closeTermsOfServiceModal = () => {
  request.put('/tos');
  return fetchTermsOfServiceModalVisibility(false);
};

export const closeGdprModal = () => (dispatch) => {
  request.put('/me/gdpr')
    .then(() => {
      dispatch({
        type: modalActions.CLOSE_GDPR,
      });
    });
};

export const closeProductNewsModal = (data) => (
  updateSettings('productNewsUpdateSeen', data.productNewsUpdateSeen)
);

export const closeBillingSystemChangeModal = (data) => (
  updateSettings('showBillingSystemChangeModal', data.showBillingSystemChangeModal)
);

export const closeModalboxForPayingUsers = (data) => (
  updateSettings('modalboxForPayingUsersSeen', data.modalboxForPayingUsersSeen)
);

export const closeModalboxForTrialUsers = (data) => (
  updateSettings('modalboxForTrialUsersSeen', data.modalboxForTrialUsersSeen)
);

export const setAccountSetupFormAsFilled = () => saveSettings('accountSetupFormFilled', true, true);

export const closeModalboxSketchPluginPromo = (data) => (
  updateSettings('sketchPluginPromoModalboxVersionSeen', data.sketchPluginPromoModalboxVersionSeen)
);

function requestSubmittingWelcomeForm() {
  return {
    type: modalActions.REQUEST_SUBMITTING_WELCOME_FORM,
  };
}

export const submitWelcomeForm = (data) => ({
  type: modalActions.SUBMIT_WELCOME_FORM,
  data,
});

export const saveUserData = (formData) => (dispatch) => {
  dispatch(requestSubmittingWelcomeForm());
  request.post('/accountProspectData', {
    body: {
      teamSize: formData.teamSize,
      role: formData.role,
      problem: formData.problemToSolve,
      likeADemo: formData.demo,
      phone: formData.phoneNumber,
    },
  })
    .then((data) => {
      dispatch(submitWelcomeForm(data));
    })
    .catch((e) => {
      e.json().then((errorData) => {
        dispatch({
          type: modalActions.SUBMITTING_WELCOME_FORM_ERROR,
          errorType: errorData.message,
        });
      });
    });
};

export const saveLeadForm = (formData) => (dispatch) => {
  dispatch(requestSubmittingWelcomeForm());
  request.post('/account/saveLeadForm', {
    body: {
      teamSize: formData.teamSize,
      role: formData.role,
      problem: formData.problemToSolve,
      likeADemo: formData.demo,
      phone: formData.phoneNumber,
    },
  })
    .then((data) => {
      dispatch({
        type: modalActions.SUBMIT_LEAD_FORM,
        data,
      });
    })
    .catch((e) => {
      e.json().then((errorData) => {
        dispatch({
          type: modalActions.SUBMITTING_LEAD_FORM_ERROR,
          errorType: errorData.message,
        });
      });
    });
};
