/* eslint-disable react/destructuring-assignment,max-len */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */

'use strict';

import React from 'react';
import { Modal } from '@uxpin/shared-components';
import autobind from 'autobind-decorator';
import config from 'react-global-configuration';
import classnames from 'classnames';

export default class ModalboxSendRequest extends React.Component {
  constructor(props) {
    super(props);

    this.MODAL = {
      WRAPPER_ID: 'send-request-modal-box',
      TITLE: 'Send your approval request',
      CLASS_NAMES: 'no-transition',
    };
  }

  @autobind
  onClose(e) {
    const { clearRequestError, onClose } = this.props;
    if (e) {
      e.preventDefault();
    }

    clearRequestError();
    onClose();
  }

  @autobind
  handleContentChange(e) {
    this.setState({
      // eslint-disable-next-line react/no-unused-state
      contentLength: e.target.innerText.length,
    });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.isRequestBeingModified && !nextProps.isRequestBeingModified && !nextProps.isRequestSendingError) {
      this.onClose();
    }
  }

  getBtnClasses() {
    return classnames('btn-solid', 'blue', 'wide', { working: this.props.isRequestBeingModified });
  }

  renderError() {
    const { isRequestSendingError } = this.props;

    if (isRequestSendingError) {
      return (
        <li>
          <p className="error">There was an error. Try again.</p>
        </li>
      );
    }
  }

  getApprovalUrl() {
    const { APPROVAL_URL } = config.get();

    return APPROVAL_URL;
  }

  renderRequestForm() {
    const { stakeholder, document } = this.props;
    if (!stakeholder) { return null; }

    const stakeholderName = `${stakeholder.firstname}`;

    return (
      // eslint-disable-next-line max-len,no-return-assign
      <article ref={(ref) => this.modalBoxContent = ref} onInput={this.handleContentChange} contentEditable className="request-form" suppressContentEditableWarning>
        Hello {stakeholderName},<br /><br />
        I'd like to ask you for a review and approval of design for {document.name}. I'd appreciate if you could take care of it as soon as possible.<br /><br />
        If you approve this design just click the button "approved" at the top of the screen.
      </article>
    );
  }

  @autobind
  sendApprovalRequest() {
    const {
      stakeholder, sendRequest, restartRequest, isNewRequest, currentStatus,
    } = this.props;

    if (isNewRequest) {
      restartRequest(stakeholder.idApproval, stakeholder.request.id, {
        body: this.modalBoxContent.innerText,
        currentStatus,
        urlHash: stakeholder.request.urlHash,
      }, this.getApprovalUrl());
    } else {
      sendRequest(stakeholder.idApproval, stakeholder.request.id, {
        body: this.modalBoxContent.innerText,
        url: [this.getApprovalUrl(), '/', stakeholder.request.urlHash].join(''),
        currentStatus,
        urlHash: stakeholder.request.urlHash,
      });
    }
  }

  isEmpty() {
    return this.modalBoxContent && this.modalBoxContent.innerText.trim().length <= 0;
  }

  render() {
    return (
      <Modal
        wrapperId={this.MODAL.WRAPPER_ID}
        title={this.MODAL.TITLE}
        classNames={this.MODAL.CLASS_NAMES}
        isOpen={this.props.isOpen}
        onClose={this.props.onClose}>
        <section className="modal-box-content">
          {this.renderRequestForm()}
        </section>
        <footer>
          <ul className="btns-group">
            {this.renderError()}
            <li>
              <a href="#cancel" className="gray-style cancel-button action-close" onClick={this.onClose}>Cancel</a>
            </li>
            <li>
              <button
                className={this.getBtnClasses()}
                type="button"
                onClick={this.sendApprovalRequest}
                disabled={this.isEmpty()}>
                Send your request
              </button>
            </li>
          </ul>
        </footer>
      </Modal>
    );
  }
}
