/* eslint-disable max-len */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */

'use strict';

import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import autobind from 'autobind-decorator';
import classnames from 'classnames';
import { Cloud, cloudPositions } from '@uxpin/shared-components';
import { projectsStatuses } from '../../../../constants/projects.constants';
import { isDesignCountOverLimit, isDesignLimitReached } from '../../../../utils/designLimit';
import { preventDefaultEvent } from '../../../../utils/common';

export class ProjectActionsCloud extends React.Component {
  archiveFeatureEnabled() {
    return this.props.features && this.props.features.features.project_archive === 'enabled';
  }

  @autobind
  unarchiveLink() {
    const { projectData, totalDocumentsCount: designCount, isFreePlan } = this.props;
    const willExceedDesignLimit = !isFreePlan && projectData && projectData.documentCount > 1
      && designCount && isDesignCountOverLimit(designCount.in_plan, designCount.used, projectData.documentCount);
    const limitExceededOnFreePlan = isFreePlan && designCount && designCount.used > 1;
    const unarchiveAction = (limitExceededOnFreePlan || willExceedDesignLimit) ? this.triggerDesignLimitsModal : this.unarchiveProject;

    return (<li><a href="#unarchive" onClick={unarchiveAction} className="icon-general-unarchive">Unarchive</a></li>);
  }

  archiveLink() {
    const classes = classnames('icon-general-archive', { disabled: !this.archiveFeatureEnabled() });
    return (<a href="#archive" onClick={this.archiveProject} className={classes}>Archive</a>);
  }

  availableInPaidTip() {
    if (!this.archiveFeatureEnabled()) {
      return (<em className="tooltip float-left">Available in paid plans</em>);
    }
  }

  @autobind
  triggerDesignLimitsModal(e) {
    preventDefaultEvent(e);
    this.props.showUnarchivePrototypesLimitsModal();
  }

  @autobind
  triggerPrototypesLimitModal(e) {
    const { showAddPrototypesLimitsModal } = this.props;
    preventDefaultEvent(e);
    showAddPrototypesLimitsModal();
  }

  renderArchiveLink() {
    if (this.props.projectData.status === projectsStatuses.ARCHIVED) {
      if (this.props.allProjects.length > 1) {
        return this.unarchiveLink();
      }
    }

    return (
      <li style={{ position: 'relative' }} data-show-node="tooltip">
        {this.archiveLink()}
        {this.availableInPaidTip()}
      </li>
    );
  }

  renderChangeNameElement() {
    if (this.props.projectData.status === projectsStatuses.ARCHIVED) {
      return;
    }

    return (<li><a href="#change-name" onClick={this.openModalboxProjectChangeName} className="icon-general-pencil">Change name</a></li>);
  }

  renderChangeGroupElement() {
    if (this.props.isAccountParked || (this.props.projectData.idProjectGroup && this.props.allProjects.length <= 3) || this.props.projectData.status === projectsStatuses.ARCHIVED) {
      return;
    }

    if (!this.props.projectData.idProjectGroup && this.props.allProjects.length <= 2) {
      return;
    }

    return (<li><a onClick={this.openModalboxProjectChangeGroup} href="#change-group" className="icon-general-change-group">Change project group</a></li>);
  }

  renderRemoveProjectFromGroupElement() {
    if (this.props.isAccountParked || !this.props.projectData.idProjectGroup || this.props.projectData.status === projectsStatuses.ARCHIVED) {
      return;
    }

    return (<li><a onClick={this.openModalboxGeneralMessage} href="#remove-project-from-group" className="icon-general-remove-from-group">Remove from group</a></li>);
  }

  renderDuplicateListElement() {
    const {
      isAccountParked, projectData, isLimitExceeded, totalDocumentsCount: docsCount,
    } = this.props;
    const limitReached = docsCount && isDesignLimitReached(docsCount.in_plan, docsCount.used);
    const duplicateAction = limitReached ? this.triggerPrototypesLimitModal : this.duplicateProject;

    if (isAccountParked || projectData.status === projectsStatuses.ARCHIVED) {
      return null;
    }

    if (isLimitExceeded) {
      return (
        <li style={{ position: 'relative' }} data-show-node="tooltip">
          <a onClick={duplicateAction} href="#duplicate" className="icon-general-copy disabled">
          Duplicate
          </a>
          <em className="tooltip float-left">This account reached its project limit.</em>
        </li>
      );
    }

    return (<li><a onClick={duplicateAction} href="#duplicate" className="icon-general-copy">Duplicate</a></li>);
  }

  @autobind
  archiveProject(e) {
    const { projectData, archiveProject } = this.props;
    preventDefaultEvent(e);
    archiveProject(projectData.idProject);
  }

  @autobind
  duplicateProject(e) {
    const { projectData, duplicateProject } = this.props;
    preventDefaultEvent(e);
    duplicateProject(projectData.idProject);
  }

  @autobind
  unarchiveProject(e) {
    const { projectData, unarchiveProject, unarchiveAndRemoveProjectFromGroup } = this.props;
    preventDefaultEvent(e);
    if (this.doesGroupExist(projectData.idProjectGroup)) {
      unarchiveProject(projectData.idProject, projectData.idProjectGroup);
    } else {
      unarchiveAndRemoveProjectFromGroup(projectData.idProject);
    }
  }

  @autobind
  openModalboxProjectChangeName(e) {
    preventDefaultEvent(e);
    const { openModalboxProjectChangeName, projectData, renameProject } = this.props;
    const actions = {
      handleRenameProject: (name) => renameProject(projectData.idProject, name),
    };

    openModalboxProjectChangeName(projectData, actions);
  }

  @autobind
  openModalboxGeneralMessage(e) {
    preventDefaultEvent(e);
    const { openModalboxGeneralMessage, projectData, removeProjectFromGroup } = this.props;
    openModalboxGeneralMessage('Are you sure?', '', () => {
      removeProjectFromGroup(projectData.idProject);
    });
  }

  @autobind
  openModalboxProjectChangeGroup(e, isUnarchiveMode) {
    preventDefaultEvent(e);
    const {
      openModalboxProjectChangeGroup, projectData, allProjects, changeGroup, unarchiveAndChangeGroup,
    } = this.props;
    const data = {
      project: projectData,
      groups: allProjects,
      isUnarchiveMode: isUnarchiveMode === true,
    };
    const actions = {
      handleChangeGroupProject: (idGroup, hasProjectGroup, groupColor, force) => {
        if (isUnarchiveMode === true) {
          unarchiveAndChangeGroup(projectData.idProject, idGroup);
        } else {
          changeGroup(projectData.idProject, idGroup, hasProjectGroup, groupColor, force);
        }
      },
    };

    openModalboxProjectChangeGroup(data, actions);
  }

  @autobind
  openModalboxProjectDelete(e) {
    preventDefaultEvent(e);
    const {
      openModalboxProjectDelete, projectData, archiveProject, unarchiveProject, unarchiveAndRemoveProjectFromGroup, removeProject,
    } = this.props;
    const actions = {
      handleArchiveProject: () => archiveProject(projectData.idProject),
      handleUnarchiveProject: () => {
        if (this.doesGroupExist(projectData.idProjectGroup)) {
          unarchiveProject(projectData.idProject, projectData.idProjectGroup);
        } else {
          unarchiveAndRemoveProjectFromGroup(projectData.idProject);
        }
      },

      handleRemoveProject: () => removeProject(projectData.idProject),
    };

    const allowArchive = this.archiveFeatureEnabled();

    openModalboxProjectDelete(projectData, actions, allowArchive);
  }

  doesGroupExist(idProjectGroup) {
    // eslint-disable-next-line array-callback-return
    return !!this.props.allProjects.filter((group) => {
      if (group.id === idProjectGroup) {
        return true;
      }
    }).length;
  }

  shouldComponentUpdate(nextProps) {
    return Object.keys(this.props).some((propName) => !_.isEqual(this.props[propName], nextProps[propName]));
  }

  render() {
    return (
      <div {...this.props.trigger} className="more-actions-wrapper">
        <a className="icon-general-dots only-icon-font">More</a>
        <ul {...this.props.cloud} className="cloud project-actions-cloud options-cloud with-icons">
          {this.renderChangeNameElement()}
          {this.renderChangeGroupElement()}
          {this.renderRemoveProjectFromGroupElement()}
          {this.renderDuplicateListElement()}
          {this.renderArchiveLink()}
          <li className="separator"><a href="#delete" onClick={this.openModalboxProjectDelete} className="icon-general-trash danger custom-color">Delete</a></li>
        </ul>
      </div>
    );
  }
}

ProjectActionsCloud.propTypes = {
  allProjects: PropTypes.array,
  projectData: PropTypes.object,
  openModalboxProjectDelete: PropTypes.func,
  archiveProject: PropTypes.func,
  unarchiveProject: PropTypes.func,
  unarchiveAndRemoveProjectFromGroup: PropTypes.func,
  removeProject: PropTypes.func,
  changeGroup: PropTypes.func,
  renameProject: PropTypes.func,
  totalDocumentsCount: PropTypes.object,
  showUnarchivePrototypesLimitsModal: PropTypes.func,
  isFreePlan: PropTypes.bool.isRequired,
};

ProjectActionsCloud.defaultProps = {
  allProjects: [],
  projectData: {},
  openModalboxProjectDelete: _.noop,
  archiveProject: _.noop,
  unarchiveProject: _.noop,
  unarchiveAndRemoveProjectFromGroup: _.noop,
  removeProject: _.noop,
  changeGroup: _.noop,
  renameProject: _.noop,
  totalDocumentsCount: {},
  showUnarchivePrototypesLimitsModal: _.noop,
};

export default Cloud({
  availablePositions: [cloudPositions.RIGHT_TOP, cloudPositions.LEFT_TOP, cloudPositions.TOP, cloudPositions.BOTTOM],
  offset: {
    rightTop: { x: 10, y: 12 },
    leftTop: { x: -10, y: 12 },
    top: { x: 0, y: -5 },
    bottom: { x: 0, y: 5 },
  },
})(ProjectActionsCloud);
