import { connect } from 'react-redux';
import DesignSystemsLibrariesSelectComponent from '../components/DesignSystems/components/DesignSystemsLibrariesSelect';

export const mapStateToProps = (state) => {
  const { data: currentUserData } = state.currentUser;

  return {
    currentUserData,
  };
};

export const DesignSystemsLibrariesSelect = connect(mapStateToProps)(DesignSystemsLibrariesSelectComponent);
