/* eslint-disable react/destructuring-assignment,react/button-has-type,max-len */

'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@uxpin/shared-components';
import classnames from 'classnames';
import autobind from 'autobind-decorator';
import { LIBRARY_TYPES } from '../../../../enums/designLibraries';

export default class ModalboxDesignSystemDelete extends React.Component {
  constructor(props) {
    super(props);
    this.MODAL = {
      WRAPPER_ID: 'design-system-delete-modal-box',
      CLASS_NAMES: ['modal-box-medium-width'],
    };

    this.state = {
      deleteWithLibrary: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.isDesignSystemDeleting && !nextProps.isDesignSystemDeleting) {
      this.props.onClose();
    }
  }

  @autobind
  renderTitle() {
    const { data } = this.props;
    if (!data) {
      return null;
    }

    return (
      <h2>Delete {data.name}?</h2>
    );
  }

  @autobind
  renderDescription() {
    const { data } = this.props;

    if (!data) {
      return null;
    }

    if ((data.type === LIBRARY_TYPES.CODE_SYNC || data.type === LIBRARY_TYPES.STORYBOOK) && !data.libraryHash) {
      return (
        <p>
          This will remove the library you used in the editor.<br />Projects based on these elements will not display correctly.
        </p>
      );
    }

    return (
      <section>
        <p>
          This will remove all documentation added to <strong>{data.name}</strong>, but the library itself won’t be deleted.
        </p>
        <input
          type="checkbox"
          id="delete-with-library"
          checked={this.state.deleteWithLibrary}
          onChange={this.onChange}
          className="state-helper"
        />
        <label className="fake-choose-label fake-checkbox" htmlFor="delete-with-library">Delete the <strong>{data.name}</strong> library as well</label>
      </section>
    );
  }

  @autobind
  deleteDesignSystem() {
    const { deleteDesignSystem, deleteDesignSystemWithLibrary, deleteMergeLibrary, data } = this.props;

    if ((data.type === LIBRARY_TYPES.CODE_SYNC || data.type === LIBRARY_TYPES.STORYBOOK) && !data.libraryHash) {
      return deleteMergeLibrary(data.id);
    }

    if (this.state.deleteWithLibrary) {
      deleteDesignSystemWithLibrary(data.idLibrary);
    } else {
      deleteDesignSystem(data.id, data.idLibrary);
    }
  }

  @autobind
  onClose(e) {
    const { onClose } = this.props;
    e.preventDefault();
    onClose();
  }

  @autobind
  onChange() {
    // eslint-disable-next-line react/no-access-state-in-setstate
    const deleteWithLibrary = !this.state.deleteWithLibrary;

    this.setState({
      deleteWithLibrary,
    });
  }

  render() {
    const deleteBtnClass = classnames('btn-solid red', {
      working: this.props.isDesignSystemDeleting,
    });

    return (
      <Modal
        wrapperId={this.MODAL.WRAPPER_ID}
        classNames={this.MODAL.CLASS_NAMES}
        renderTitle={this.renderTitle}
        isOpen={this.props.isOpen}
        onClose={this.onClose}>
        {this.renderDescription()}
        <footer>
          <ul className="btns-group">
            <li>
              <a href="#cancel" onClick={this.onClose} className="gray-style cancel-button action-close">Cancel</a>
            </li>
            <li>
              <button onClick={this.deleteDesignSystem} className={deleteBtnClass}>Delete</button>
            </li>
          </ul>
        </footer>
      </Modal>
    );
  }
}

ModalboxDesignSystemDelete.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.string,
    idLibrary: PropTypes.number,
    id: PropTypes.number,
    libraryHash: PropTypes.string,
  }),
  isOpen: PropTypes.bool.isRequired,
  isDesignSystemDeleting: PropTypes.bool.isRequired,
  deleteDesignSystem: PropTypes.func.isRequired,
  deleteDesignSystemWithLibrary: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  deleteMergeLibrary: PropTypes.func.isRequired,
};

ModalboxDesignSystemDelete.defaultProps = {
  data: {
    name: '',
    type: '',
    idLibrary: null,
    id: null,
    libraryHash: null,
  },
};
