'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import autobind from 'autobind-decorator';
import { noop } from 'lodash';
import FieldElement from './FieldElement';

export default class Input extends FieldElement {
  @autobind
  handleInputBlur(e) {
    const { onBlur } = this.props;

    this.handleBlur(e, onBlur);
  }

  @autobind
  handleInputChange(e) {
    const { onChange } = this.props;
    this.handleChange(e, onChange);
  }

  @autobind
  handleInputKeyDown(e) {
    const { onKeyDown } = this.props;
    this.handleKeyDown(e, onKeyDown);
  }

  render() {
    const {
      name, id, type, placeholder,
    } = this.props;

    return (
      <div>
        <input
          type={type}
          ref={this.getRef}
          className={this.renderClassNames()}
          name={name}
          id={id}
          value={this.state.value}
          placeholder={placeholder}
          onChange={this.handleInputChange}
          onBlur={this.handleInputBlur}
          onKeyDown={this.handleInputKeyDown}
        />
        {this.renderErrorMessage()}
      </div>
    );
  }
}

Input.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  onBlur: PropTypes.func,
  onKeyDown: PropTypes.func,
  onChange: PropTypes.func,
};

Input.defaultProps = {
  type: 'text',
  placeholder: '',
  onBlur: noop,
  onKeyDown: noop,
  onChange: noop,
};
