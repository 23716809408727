'use strict';

import { connect } from 'react-redux';
import { setAccountSetupFormAsFilled } from '../../controllers/actions/modal.actions';
import ModalboxOnboardingComponent from '../components/Modalboxes/ModalboxOnboarding';

export const mapStateToProps = (state) => {
  const { features } = state.features;

  return {
    features,
  };
};

export const mapDispatchToProps = (dispatch) => ({
  setAccountSetupFormAsFilled: () => dispatch(setAccountSetupFormAsFilled()),
});

export const ModalboxOnboarding = connect(mapStateToProps, mapDispatchToProps)(ModalboxOnboardingComponent);
