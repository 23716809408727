import { connect } from 'react-redux';
import { find } from 'lodash';
import ModalboxDesignSystemPermission from '../components/Modalboxes/ModalboxDesignSystemPermission';
import { modalsIds } from '../../constants/modal.constants';
import { closeModal } from '../../controllers/actions/modal.actions';
import { updateLibraryPermissions } from '../../controllers/actions/designSystems.actions';

export const mapStateToProps = (state) => {
  const modalData = state.modal.data[modalsIds.MODALBOX_DESIGN_SYSTEM_PERMISSIONS];
  const openedModalsId = state.modal.openedModalsIds[modalsIds.MODALBOX_DESIGN_SYSTEM_PERMISSIONS];
  const designSystemId = modalData ? modalData.id : null;
  // eslint-disable-next-line max-len
  const designSystemData = state.designSystems ? find(state.designSystems.systems, (system) => system.id === designSystemId) : {};

  return {
    isOpen: openedModalsId || false,
    designSystemData: designSystemData || {},
  };
};

export const mapDispatchToProps = (dispatch) => ({
  updateLibraryPermissions: (idLibrary, libraryData) => {
    dispatch(updateLibraryPermissions(idLibrary, libraryData));
  },

  onClose: () => {
    dispatch(closeModal(modalsIds.MODALBOX_DESIGN_SYSTEM_PERMISSIONS));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalboxDesignSystemPermission);
