'use strict';

import { upgradeModalActions, upgradeModalSpecModePromoActions } from '../../constants/upgradeModal.constants';

export function upgradeModalReducer(state = {
  isUpgradeModalOpen: false,
  upgradeModalData: {},
}, action) {
  switch (action.type) {
    case upgradeModalActions.OPEN_UPGRADE_MODAL:
      return Object.assign({}, state, {
        isUpgradeModalOpen: true,
        upgradeModalData: action.data || {},
      });
    case upgradeModalActions.CLOSE_UPGRADE_MODAL:
      return Object.assign({}, state, {
        isUpgradeModalOpen: false,
      });
    default:
      return state;
  }
}

export function upgradeModalSpecModePromoReducer(state = {
  isSpecModePromoUpgradeModalOpen: false,
}, action) {
  switch (action.type) {
    case upgradeModalSpecModePromoActions.OPEN_SPECMODE_PROMO_UPGRADE_MODAL:
      return Object.assign({}, state, {
        isSpecModePromoUpgradeModalOpen: true,
      });
    case upgradeModalSpecModePromoActions.CLOSE_SPECMODE_PROMO_UPGRADE_MODAL:
      return Object.assign({}, state, {
        isSpecModePromoUpgradeModalOpen: false,
      });
    default:
      return state;
  }
}
