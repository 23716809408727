'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import { includes, snakeCase, toUpper } from 'lodash';
import { AddOnsFollowUpModals } from '../../containers/AddOnsFollowUpModals';
import { goTo } from '../../../utils/router';
import { addOns } from '../../../constants/marketing.constants';

export default class ModalboxAddOnsHelper extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      addOnId: null,
    };
  }

  componentWillMount() {
    const { params: { addOn } } = this.props;
    const addOnId = toUpper(snakeCase(addOn));

    if (includes(addOns, addOnId)) {
      this.setState({
        addOnId,
      });
    } else {
      goTo('not-found');
    }
  }

  render() {
    const { addOnId } = this.state;

    if (!addOnId) {
      return null;
    }

    return (
      <AddOnsFollowUpModals
        addOn={addOnId}
      />
    );
  }
}

ModalboxAddOnsHelper.propTypes = {
  params: PropTypes.object,
};

ModalboxAddOnsHelper.defaultProps = {
  params: {},
};
