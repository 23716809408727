/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable react/require-default-props */

'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import { isDesktopApp } from '@uxpin/shared-components';
import { Button } from '@uxpin/design-system';
import autobind from 'autobind-decorator';
import classnames from 'classnames';
import _ from 'lodash';
import ModalboxProjectNew from '../../Modalboxes/ModalboxProjectNew';
import { EVENT_CREATE_NEW_PROJECT } from '../../../../constants/desktopAppEvents.constants';

export default class NewProjectBtn extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isModalboxProjectNewOpen: false,
    };
  }

  componentDidMount() {
    const { shouldAddDesktopAppListener } = this.props;
    if (isDesktopApp && shouldAddDesktopAppListener) {
      window.addEventListener(EVENT_CREATE_NEW_PROJECT, this.openModal);
    }
  }

  componentWillUnmount() {
    const { shouldAddDesktopAppListener } = this.props;
    if (isDesktopApp && shouldAddDesktopAppListener) {
      window.removeEventListener(EVENT_CREATE_NEW_PROJECT, this.openModal);
    }
  }

  @autobind
  openModal(e) {
    e.preventDefault();
    this.setState({
      isModalboxProjectNewOpen: true,
    });
  }

  @autobind
  closeModal(e) {
    e.preventDefault();
    const { clearAddProjectError, isAddingError } = this.props;

    this.setState({
      isModalboxProjectNewOpen: false,
    }, () => {
      if (isAddingError) {
        clearAddProjectError();
      }
    });
  }

  getBtnClasses() {
    return classnames('btn-new-project', { disabled: this.props.isLimitExceeded }, this.props.classes);
  }

  getBtnContainerClasses() {
    return classnames(this.props.containerClassName);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return Object.keys(this.props).some((propName) => !_.isEqual(this.props[propName], nextProps[propName]))
     || Object.keys(this.state).some((propName) => !_.isEqual(this.state[propName], nextState[propName]));
  }

  renderTooltip() {
    if (this.props.isLimitExceeded) {
      return (<em className="tooltip float-left">This account reached its project limit.</em>);
    }
  }

  render() {
    const {
      projectsGroups,
      saveProject,
      isAdding,
      currentAddedProjectId,
      label,
      isLimitExceeded,
      idProjectGroup,
      isUngroupedProjectsGroup,
      permissions,
      isAddingError,
      errorType,
      isAccountParked,
    } = this.props;

    // eslint-disable-next-line max-len
    if (isAccountParked || _.isEmpty(permissions) || (permissions.can_create_projects && permissions.can_create_projects.selected === 'no')) {
      return null;
    }

    return (
      <li data-show-node="tooltip" className={`with-separator ${this.getBtnContainerClasses()}`}>
        <Button
          className={this.getBtnClasses()}
          iconName="plus"
          isDisabled={isLimitExceeded}
          onClick={this.openModal}
          href="#new-project">
          { label || 'New project' }
        </Button>
        {this.renderTooltip()}
        <ModalboxProjectNew
          isOpen={this.state.isModalboxProjectNewOpen}
          onClose={this.closeModal}
          projectsGroups={projectsGroups.map((item) => {
            const newItem = Object.assign({}, item);
            newItem.projects = null;
            return newItem;
          })}
          idProjectGroup={idProjectGroup}
          isUngroupedProjectsGroup={isUngroupedProjectsGroup}
          isError={isAddingError}
          errorType={errorType}
          isAdding={isAdding}
          currentAddedProjectId={currentAddedProjectId}
          saveProject={saveProject}
        />
      </li>
    );
  }
}

NewProjectBtn.defaultProps = {
  // eslint-disable-next-line react/default-props-match-prop-types
  isUngroupedProjectsGroup: false,
  shouldAddDesktopAppListener: false,
};

NewProjectBtn.propTypes = {
  projectsGroups: PropTypes.array.isRequired,
  saveProject: PropTypes.func.isRequired,
  isAdding: PropTypes.bool.isRequired,
  currentAddedProjectId: PropTypes.number,
  idProjectGroup: PropTypes.number,
  shouldAddDesktopAppListener: PropTypes.bool,
};
