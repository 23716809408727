import { connect } from 'react-redux';
import ModalboxAddStakeholderComponent from '../../../components/ApprovalProcess/modalboxes/ModalboxAddStakeholder';
import * as stakeholders from '../../../../controllers/actions/stakeholders.actions';

export const mapStateToProps = (state) => {
  const {
    // eslint-disable-next-line no-shadow
    stakeholders, isLoading, isStakeholderModificationError, isStakeholderBeingModified,
  } = state.stakeholders;

  return {
    stakeholders,
    isLoading,
    isStakeholderModificationError,
    isStakeholderBeingModified,
  };
};

export const mapDispatchToProps = (dispatch) => ({
  updateStakeholder: (idApproval, id, data) => {
    dispatch(stakeholders.updateStakeholder(idApproval, id, data));
  },

  clearError: () => {
    dispatch(stakeholders.clearError());
  },

  closeEditModal: () => {
    dispatch(stakeholders.closeEditModal());
  },

  addStakeholder: (idApproval, data) => {
    dispatch(stakeholders.addStakeholder(idApproval, data));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalboxAddStakeholderComponent);
