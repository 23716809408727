import { projectsActions } from '../../constants/projects.constants';

export const initRemovingCounter = (idProject) => ({
  type: projectsActions.INIT_REMOVING_COUNTER,
  idProject,
});

export const countTimeToRemoveProject = (ids) => ({
  type: projectsActions.COUNT_TIME_TO_REMOVE_PROJECT,
  ids,
});

export const setProjectError = (idProject, tryAgain, cancel) => ({
  type: projectsActions.SET_PROJECT_ERROR,
  idProject,
  tryAgain,
  cancel,
});

export const removeProjectError = (idProject) => ({
  type: projectsActions.REMOVE_PROJECT_ERROR,
  idProject,
});

export const requestActionOnProject = (idProject, type) => ({
  type: projectsActions.REQUEST_ACTION_ON_PROJECT,
  idProject,
  actionOnProject: type,
});

export const stopActionOnProject = (idProject) => ({
  type: projectsActions.STOP_ACTION_ON_PROJECT,
  idProject,
});
