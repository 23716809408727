/* eslint-disable react/button-has-type,jsx-a11y/alt-text */

import React from 'react';
import config from 'react-global-configuration';
import SlackLogoColor from 'dashboard/images/slack-logo-color.svg';

// eslint-disable-next-line react/prefer-stateless-function
export default class TabIntegrations extends React.Component {
  render() {
    return (
      // eslint-disable-next-line react/destructuring-assignment,react/prop-types
      <section className={this.props.classNames} id="integrations">
        <h3>Integrations</h3>
        <div className="row">
          <figure>
            <img src={SlackLogoColor} width="40" height="40" />
          </figure>
          <h3>Slack</h3>
          <p>
            Connect to receive UXPin notification on a Slack channel of your choice
            and send direct feedback requests to people in our company
          </p>
          <button className="btn-ghost blue">Connect</button>
        </div>
      </section>
    );
  }
}
