/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import config from 'react-global-configuration';
import { goTo } from '../../../../utils/router';
import { isLimitHitCurrently } from '../../../../controllers/helpers/designSystems.helper';
import IntroScreenIcon from '../../../../../images/design-systems/intro-screen-icon.svg';

const DesignSystemCreateItem = (props) => {
  const handleClick = (e) => {
    e.preventDefault();

    isLimitHitCurrently()
      .then((isHit) => {
        if (isHit) {
          // eslint-disable-next-line react/prop-types
          return props.showDesignLimitModal();
        }

        goTo(props.createUri);
      });
  };

  return (
    <li className="list-item create-design-system-item">
      <a className="gray-link" href={`${config.get('BASE_URL')}${props.createUri}`} onClick={handleClick}>
        <img
          className="create-icon"
          src={IntroScreenIcon}
          width="89"
          height="61"
          alt="Create design system"
        />
        <span className="create-text">Create new Design System</span>
      </a>
    </li>
  );
};

DesignSystemCreateItem.propTypes = {
  createUri: PropTypes.string.isRequired,
};

export default DesignSystemCreateItem;
