import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const MultiSelectBox = ({ onClick, optionName, selected, text, value }) => {
  const classes = classnames('multi-select-option', {
    'multi-select-option--selected': selected,
  });

  const onSelect = () => onClick(value);

  return (
    <label htmlFor={value} className={classes}>
      <input
        type="checkbox"
        id={value}
        name={optionName}
        value={value}
        onChange={onSelect}
        checked={selected}
      />
      <span className="multi-select-option-name">{text}</span>
    </label>
  );
};

MultiSelectBox.propTypes = {
  text: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
  optionName: PropTypes.string,
};

MultiSelectBox.defaultProps = {
  optionName: '',
};

export default MultiSelectBox;
