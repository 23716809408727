/* eslint-disable max-len */

import React from 'react';

// eslint-disable-next-line react/prefer-stateless-function
export default class TabSecurity extends React.Component {
  render() {
    return (
      // eslint-disable-next-line react/destructuring-assignment,react/prop-types
      <section className={this.props.classNames} id="security">
        <div className="column">
          <h3>Password change</h3>
          <fieldset className="control-wrapper">
            <h4><label>Current password</label></h4>
            <input type="text" />
          </fieldset>
          <fieldset className="control-wrapper">
            <h4><label>New password</label></h4>
            <input type="text" />
          </fieldset>
          <fieldset className="control-wrapper">
            <h4><label>Re-enter new password</label></h4>
            <input type="text" />
          </fieldset>
        </div>
        <div className="column">
          <h3>2-Step Verification</h3>
          2-Step Verification is <span className="tsv-status enabled">enabled</span>. <a href="#toggle-two-step" className="blue-link underline-link toggle-tsv">Disable</a>
          <div className="codes">
            <h4>Send codes to</h4>
            <label className="phone-prefix">+48</label>
            <input className="phone-number" type="text" defaultValue="505 506 607" />
          </div>

          <div className="links-group">
            <a href="#change-billing" className="blue-link underline-link">Add a backup phone number</a>
            <a href="#change-billing" className="blue-link underline-link">Show emergency code</a>
          </div>
        </div>
      </section>
    );
  }
}
