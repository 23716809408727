'use strict';

import { browserHistory } from 'react-router';
import config from 'react-global-configuration';
import { hasIn, includes } from 'lodash';

const { BASE_URL } = config.get();

export const isPage = (props, route, withFollowingSlash = false) => {
  const currentPath = hasIn(props, 'location.pathname') ? props.location.pathname : '';
  const followingSlash = withFollowingSlash ? '/' : '';
  const checkedPath = BASE_URL + route + followingSlash;

  return includes(currentPath, checkedPath);
};

export function goTo(destination) {
  browserHistory.push(BASE_URL + destination);
}

export function goToExternal(destination) {
  window.location.href = destination;
}

export function getUrl(url) {
  return BASE_URL + url;
}

export function redirectToNotFoundView() {
  goTo('not-found');
}

export function getLogoutUrl() {
  return `${config.get('APP_URL')}/mod/Login/Logout`;
}
