/* eslint-disable react/no-unescaped-entities */

'use strict';

import React from 'react';
import config from 'react-global-configuration';
import { Link } from '@uxpin/design-system';
import { GLOBAL_ELEMENTS } from '@uxpin/shared-components';
import EmptyApproval from 'dashboard/images/empty-approval.svg';

// eslint-disable-next-line react/prefer-stateless-function
export default class EmptyView extends React.Component {
  render() {
    return (
      <section className="empty-view">
        <img
          src={EmptyApproval}
          alt="Approvals"
          width="379"
          height="91"
        />
        <h3 className={GLOBAL_ELEMENTS.HEADING_3}>Get your designs approved.</h3>
        <p>
          Easily get your work reviewed and accepted. Simply add your stakeholders and send them
          <br />
          an approval request. They'll receive an email with a secure link to your design.&nbsp;
          <Link
            href="https://www.uxpin.com/community/tutorials/approval-process/"
            target="_blank"
            openInBrowser>
            Learn more
          </Link>
.
        </p>
        <footer>
          <ul className="btns-group">
            <li>
              <button
                className="btn-solid blue big"
                type="button"
                // eslint-disable-next-line react/destructuring-assignment,react/prop-types
                onClick={(e) => this.props.openModalboxAddStakeholder(e)}>
                Add stakeholder
              </button>
            </li>
          </ul>
        </footer>
      </section>
    );
  }
}
