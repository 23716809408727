/* eslint-disable max-len */
/* eslint-disable react/button-has-type */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */

'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Cloud, GLOBAL_ELEMENTS } from '@uxpin/shared-components';
import classnames from 'classnames';
import autobind from 'autobind-decorator';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { badgeLabels } from '../../../../constants/projects.constants';
import Tooltip from '../../Helpers/Tooltip';
import StakeholderActions from '../../../containers/ApprovalProcess/components/StakeholderActions';
import StakeholderDeleted from './StakeholderDeleted';

@Cloud()
export default class Stakeholder extends Component {
  renderName() {
    const { stakeholder: { firstname, lastname } } = this.props;
    const fullName = `${firstname} ${lastname}`;
    const headingClasses = classnames('name', GLOBAL_ELEMENTS.HEADING_4);

    return (
      <h4 className={headingClasses} title={fullName}>{fullName}</h4>
    );
  }

  renderStatusBadge() {
    let { stakeholder: { request: { status } } } = this.props;

    const badges = [
      badgeLabels.pending,
      badgeLabels.rejected,
      badgeLabels.approved,
      badgeLabels.inactive,
    ];

    status = status.toLowerCase();

    if (badges.indexOf(status) > -1) {
      return (
        <small className="status-badge big" data-status={status}>{status}</small>
      );
    }

    return null;
  }

  @autobind
  openRequestModalbox(e, isNewRequest) {
    e.preventDefault();
    const { stakeholder: { request: { status } } } = this.props;

    this.props.openModalboxSendRequest(this.props.stakeholder, isNewRequest, status);
  }

  renderApprovalRequest() {
    return (
      <button type="button" onClick={(e) => this.openRequestModalbox(e, false)} className="btn-ghost blue with-icon icon-general-approval">
        <span>Request approval</span>
      </button>
    );
  }

  renderFeedback() {
    const { stakeholder } = this.props;
    const feedback = stakeholder.request && stakeholder.request.feedback;

    if (isEmpty(feedback)) {
      return null;
    }

    return (
      <div className="cloud-wrapper">
        <a {...this.props.trigger} href="#" className="underline-link gray-link icon-general-comments">Note</a>
        <div {...this.props.cloud} className="cloud">
          <p>{feedback}</p>
        </div>
      </div>
    );
  }

  renderTimestamp() {
    const { stakeholder } = this.props;
    const seenAt = stakeholder.request && stakeholder.request.seenAt;
    const sentAt = stakeholder.request && stakeholder.request.updatedAt;

    if (seenAt) {
      const formattedSeenAt = moment(new Date(seenAt)).utc().local().format('DD MMMM YYYY, H:mm');

      return (
        <span className="timestamp icon-general-ticker">
          Seen
          <Tooltip className="on-right-side">
            {formattedSeenAt}
          </Tooltip>
        </span>
      );
    }

    if (sentAt) {
      const formattedSentAt = moment(new Date(sentAt)).utc().local().format('DD MMMM YYYY, H:mm');

      return (
        <span className="timestamp">
          Sent
          <Tooltip className="on-right-side">
            {formattedSentAt}
          </Tooltip>
        </span>
      );
    }

    return null;
  }

  renderPendingStatus() {
    return (
      <div>
        <button href="#resend-request" className="btn-ghost light-gray with-icon icon-general-approval" onClick={(e) => this.openRequestModalbox(e, false)}>
          <span>Resend request</span>
        </button>
        {this.renderStatusBadge()}
        {this.renderTimestamp()}
      </div>
    );
  }

  renderApprovedOrRejectedStatus() {
    return (
      <div>
        <button href="#send-another-request" className="btn-ghost light-gray with-icon icon-general-approval" onClick={(e) => this.openRequestModalbox(e, true)}>
          <span>Resend request</span>
        </button>
        {this.renderStatusBadge()}
        {this.renderFeedback()}
      </div>
    );
  }

  renderStatuses() {
    const { stakeholder } = this.props;
    const status = stakeholder.request && stakeholder.request.status;

    if (isEmpty(status)) {
      return this.renderApprovalRequest();
    }

    switch (status) {
      case badgeLabels.pending:
        return this.renderPendingStatus();
      case badgeLabels.approved:
      case badgeLabels.rejected:
        return this.renderApprovedOrRejectedStatus();
      case badgeLabels.inactive:
      default:
        return this.renderApprovalRequest();
    }
  }

  getClasses() {
    const { stakeholder } = this.props;
    return classnames('item-wrapper', 'editable-item', { deleting: stakeholder.isRemoved });
  }

  @autobind
  removeStakeholder() {
    const { stakeholder, removeStakeholder } = this.props;
    removeStakeholder(stakeholder.id);
  }

  @autobind
  restoreStakeholder() {
    const { stakeholder, restoreStakeholder, document } = this.props;
    const idApproval = document.idApproval;
    restoreStakeholder(idApproval, stakeholder.id);
  }

  render() {
    const { openModalboxAddStakeholder, stakeholder } = this.props;
    return (
      <section className={this.getClasses()}>
        <div className="item-data">
          <div className="name-wrapper">
            {this.renderName()}
            <StakeholderActions
              document={this.props.document}
              openModalboxAddStakeholder={openModalboxAddStakeholder}
              id={stakeholder.id}
            />
          </div>
        </div>
        <div className="request-status">
          {this.renderStatuses()}
        </div>
        <StakeholderDeleted
          restoreStakeholder={this.restoreStakeholder}
          removeStakeholder={this.removeStakeholder}
          stakeholder={stakeholder}
        />
      </section>
    );
  }
}

Stakeholder.propTypes = {
  stakeholder: PropTypes.object.isRequired,
};
