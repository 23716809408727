'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import MergeLibrarySetting from '../../containers/MergeLibrarySetting';

export default function ModalboxMergeLibrarySetting(props) {
  const { isOpen, onClose, designSystemData } = props;

  return (
    <MergeLibrarySetting
      isOpen={isOpen}
      onClose={onClose}
      editedLibrary={designSystemData}
    />
  );
}

ModalboxMergeLibrarySetting.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  designSystemData: PropTypes.object.isRequired,
};
