/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable react/require-default-props */

'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import { cloudPositions, triggerTypes } from '@uxpin/shared-components';
import classnames from 'classnames';
import autobind from 'autobind-decorator';
import _ from 'lodash';
import key from 'keymaster';
import ProjectPending from '../../ProjectsList/components/ProjectPending';
import { ProjectActions } from '../../../containers/ProjectActions';
import GroupColor from '../../Helpers/GroupColor';
import { statusIdToClassMap, projectsStatuses, projectsActionsOnHover } from '../../../../constants/projects.constants';
import routes from '../../../../constants/routes.constants';

export default class ProjectSearch extends React.Component {
  constructor(props) {
    super(props);

    this.MIDDLE_MOUSE_BTN = 1;
    this.LEFT_MOUSE_BTN = 0;

    this.eventRefs = {
      handleMouseMove: () => props.onMouseMove(this.refs.projectSearch),
      handleClick: (e) => {
        if (e.button === this.MIDDLE_MOUSE_BTN
          || (e.button === this.LEFT_MOUSE_BTN && key.ctrl || key.command || key.shift)) {
          return;
        }

        if (e.button === this.LEFT_MOUSE_BTN) {
          e.preventDefault();
        }

        props.action(props.data, { currentProjectsActions: props.currentProjectsActions });
      },
    };

    this.classes = {
      SELECTED: 'selected',
      UNGROUPED: 'ungrouped',
      DELETING: 'deleting',
      RENAMING: 'renaming',
      ARCHIVING: 'archiving',
      UNARCHIVING: 'unarchiving',
      COUNTING: 'deleting-counter',
      RESTORING: 'restoring',
      MOVING: 'moving',
      DUPLICATING: 'duplicating',
      NO_HOVER: 'no-hover',
    };
  }

  renderStatusInfo() {
    const { data, isProjectStatusEnabled } = this.props;

    if (data.hasProgressInfo && isProjectStatusEnabled) {
      return (
        <span
          className="status"
          data-status={statusIdToClassMap[data.statusId]}
          title={`${data.stageName} - ${data.statusName}`}
        />
      );
    }
  }

  @autobind
  openModalboxAddUser(e) {
    e.preventDefault();
    this.props.openModalboxAddUser(Object.assign({}, this.props.data, {
      idProject: this.props.data.id,
    }));
  }

  shouldHideManageUsersBtn() {
    const { permissions } = this.props;

    if (_.isEmpty(permissions)) {
      return true;
    }

    if (permissions.can_add_users_to_projects.selected !== 'all') {
      return true;
    }

    return false;
  }

  renderManageUsers() {
    if (this.shouldHideManageUsersBtn()) {
      return null;
    }

    return (
      <span className="manage-users">
        <a
          href="#add-user"
          onClick={this.openModalboxAddUser}
          className="icon-general-add-user underline-link gray-link">
          Manage Users
        </a>
      </span>
    );
  }

  canManageProjects() {
    const { permissions } = this.props;
    return _.get(permissions, 'can_create_projects.selected') === 'yes';
  }

  hasDuplicationStatusRequestBeenSent() {
    const { data, currentProjectsActions } = this.props;
    return (currentProjectsActions[data.id] === projectsActionsOnHover.CHECKING_DUPLICATION_STATUS);
  }

  isProjectBeingRemoved() {
    const { data, currentProjectsActions } = this.props;
    return (currentProjectsActions[data.id] === projectsActionsOnHover.REMOVING);
  }

  isProjectBeingMoved() {
    const { data, currentProjectsActions } = this.props;
    return (currentProjectsActions[data.id] === projectsActionsOnHover.MOVING);
  }

  isProjectInCountingMode() {
    const { data, currentProjectsActions } = this.props;
    return (currentProjectsActions[data.id] === projectsActionsOnHover.COUNTING);
  }

  isProjectBeingRestored() {
    const { data, currentProjectsActions } = this.props;
    return (currentProjectsActions[data.id] === projectsActionsOnHover.RESTORING);
  }

  isProjectBeingRenamed() {
    const { data, currentProjectsActions } = this.props;
    return (currentProjectsActions[data.id] === projectsActionsOnHover.RENAMING);
  }

  isProjectBeingArchived() {
    const { data, currentProjectsActions } = this.props;
    return (currentProjectsActions[data.id] === projectsActionsOnHover.ARCHIVING);
  }

  isProjectBeingUnarchived() {
    const { data, currentProjectsActions } = this.props;
    return (currentProjectsActions[data.id] === projectsActionsOnHover.UNARCHIVING);
  }

  isProjectBeingDuplicated() {
    const { data } = this.props;
    return [projectsStatuses.DUPLICATED, projectsStatuses.ARCHIVED_DUPLICATED].indexOf(data.status) !== -1;
  }

  getProjectClassNames() {
    const { data, isSelected } = this.props;
    const classes = [];

    if (isSelected) {
      classes.push(this.classes.SELECTED);
    }

    if (!data.groupColor) {
      classes.push(this.classes.UNGROUPED);
    }

    const actionClasses = {};
    actionClasses[this.classes.DELETING] = this.isProjectBeingRemoved();
    actionClasses[this.classes.RENAMING] = this.isProjectBeingRenamed();
    actionClasses[this.classes.ARCHIVING] = this.isProjectBeingArchived();
    actionClasses[this.classes.UNARCHIVING] = this.isProjectBeingUnarchived();
    actionClasses[this.classes.COUNTING] = this.isProjectInCountingMode();
    actionClasses[this.classes.RESTORING] = this.isProjectBeingRestored();
    actionClasses[this.classes.DUPLICATING] = this.isProjectBeingDuplicated();
    actionClasses[this.classes.MOVING] = this.isProjectBeingMoved();
    actionClasses[this.classes.NO_HOVER] = _.values(actionClasses).some((value) => value);

    return classnames('project-box', 'project-search', classes, actionClasses);
  }

  shouldComponentUpdate(nextProps) {
    const { currentProjectsActions, removingCounters, data } = this.props;

    if (!_.isEqual(nextProps.currentProjectsActions[nextProps.data.id], currentProjectsActions[data.id])) {
      return true;
    }

    if (!_.isEqual(nextProps.removingCounters[nextProps.data.id], removingCounters[data.id])) {
      return true;
    }

    if (!_.isEqual(nextProps.data, this.props.data)) {
      return true;
    }

    if (this.props.isSelected !== nextProps.isSelected) {
      return true;
    }

    return false;
  }

  getParentNodeEl() {
    return document.getElementById('search-layer');
  }

  renderProjectActions() {
    const offset = {
      rightTop: { x: 10, y: 8 },
      leftTop: { x: -10, y: 8 },
    };

    if (this.canManageProjects()) {
      return (
        <ProjectActions
          defaultPosition={cloudPositions.RIGHT_TOP}
          triggerType={triggerTypes.HOVER}
          offset={offset}
          autoClose
          projectData={Object.assign({}, this.props.data, { idProject: this.props.data.id })}
          getParentNodeEl={this.getParentNodeEl}
        />
      );
    }
  }

  render() {
    const {
      // eslint-disable-next-line max-len
      data, removeProjectPermanently, restoreProject, stopActionOnProject, removingCounters, requestCheckingDuplicationStatus,
    } = this.props;

    return (
      <li onMouseMove={this.eventRefs.handleMouseMove} ref="projectSearch" className={this.getProjectClassNames()}>
        <a href={`${routes.PROJECT}/${data.id}`} className="gray-link header" onClick={this.eventRefs.handleClick}>
          <GroupColor color={`#${data.groupColor}`} />
          <h3 className="name">
            <span className="original-name" title={data.name}>{data.name}</span>
            {this.renderStatusInfo()}
          </h3>
        </a>
        <footer className="details">
          {this.renderManageUsers()}
          {this.renderProjectActions()}
        </footer>

        <ProjectPending
          shouldStartCheckingDuplicationStatus={this.isProjectBeingDuplicated()}
          projectName={data.name}
          idProject={data.id}
          hasTemporaryId={data.hasTemporaryId}
          stopActionOnProject={stopActionOnProject}
          removeProjectPermanently={removeProjectPermanently}
          removingCounter={removingCounters[data.id]}
          restoreProject={restoreProject}
          hasDuplicationStatusRequestBeenSent={this.hasDuplicationStatusRequestBeenSent()}
          requestCheckingDuplicationStatus={requestCheckingDuplicationStatus}
        />
      </li>
    );
  }
}

ProjectSearch.propTypes = {
  onMouseMove: PropTypes.func,
  action: PropTypes.func,
  data: PropTypes.object,
  isSelected: PropTypes.bool,
};
