'use strict';

import React from 'react';

export function InvoiceOverdueComponent() {
  return (
    <section id="top-info-bar" className="invoice-overdue-bar">
      <div>
        Your account has an overdue balance.
        Complete your payment to remove account restrictions. Questions or already paid?
        {' '}
        <a href="mailto:sales@uxpin.com">Contact us</a>
        {' '}for assistance.
      </div>
    </section>
  );
}
