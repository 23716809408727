'use strict';

export const projectsGroupsActions = {
  REQUEST_FETCHING_PROJECTS_GROUPS: 'REQUEST_FETCHING_PROJECTS_GROUPS',
  FETCH_PROJECTS_GROUPS: 'FETCH_PROJECTS_GROUPS',
  REQUEST_SAVING_PROJECTS_GROUPS: 'REQUEST_SAVING_PROJECTS_GROUPS',
  PROJECTS_GROUPS_ERROR: 'PROJECTS_GROUPS_ERROR',
  SAVE_PROJECTS_GROUPS: 'SAVE_PROJECTS_GROUPS',
  FETCH_PROJECTS_GROUPS_ACCESS: 'FETCH_PROJECTS_GROUPS_ACCESS',
};

export const groupTypes = {
  ARCHIVED: 'archived',
  UNGROUPED: 'ungrouped',
};

export const removingGroupsModes = {
  ARCHIVE: 'archive',
  UNGROUP: 'ungroup',
  REMOVE: 'remove',
};
