import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { IconSwitcher, WEIGHTS } from '@uxpin/design-system';
import { starFilteringTypes } from '../../../constants/settings.constants';

const iconName = {
  default: 'star-outline',
  checked: 'star-solid',
};

const label = {
  default: 'Unstarred',
  checked: 'Starred',
};

export function StarButton(props) {
  const {
    isStarred,
    toggleStar,
  } = props;

  const iconClassNames = classnames(
    [
      'star-button',
      isStarred
        ? starFilteringTypes.STARRED
        : starFilteringTypes.UNSTARRED,
    ]
  );

  return (
    <IconSwitcher
      className={iconClassNames}
      iconName={iconName}
      label={label}
      isChecked={isStarred}
      onChange={toggleStar}
      includeTooltip={false}
      weight={WEIGHTS.X_LIGHT}>
      Star
    </IconSwitcher>
  );
}

StarButton.propTypes = {
  isStarred: PropTypes.bool.isRequired,
  toggleStar: PropTypes.func.isRequired,
};
