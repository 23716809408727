import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import OnboardingFlowComponent from '../../components/Onboarding/OnboardingFlow';
import { updateSettings } from '../../../controllers/actions/settings.actions';
import { onboardingFlowSettings } from '../../../constants/onboarding.constants';
import { getSettingSeenName, getBoolSetting } from '../../../utils/settings';
import { promotions } from '../../../constants/marketing.constants';
import { getPromotionByName } from '../../../utils/marketing';
import { addUsersOnboarding } from '../../../controllers/actions/onboarding.actions';


const { SIGNED_UP_FROM_URL } = promotions;

const stepSeenSettingName = getSettingSeenName(onboardingFlowSettings.ONBOARDING_STEP);
const onboardingSeenSettingName = getSettingSeenName(onboardingFlowSettings.ONBOARDING_FLOW);

export const mapStateToProps = (state) => {
  const { data: settings, areSettingsFetched } = state.settings;
  const currentStep = settings[stepSeenSettingName];
  const { isFetchUserDataSuccess, data } = state.currentUser;

  const onboardingSeen = getBoolSetting(settings[onboardingSeenSettingName]);
  const dataFetched = isFetchUserDataSuccess && areSettingsFetched;

  let showPasswordField = false;

  if (isFetchUserDataSuccess) {
    showPasswordField = !isEmpty(getPromotionByName(SIGNED_UP_FROM_URL, data.promotions));
  }

  const { addUsersError, addedUsersCount } = state.accountSettings;

  return {
    currentStep,
    onboardingSeen,
    showPasswordField,
    dataFetched,
    currentUserData: data,
    addUsersError,
    addedUsersCount,
  };
};

export const mapDispatchToProps = (dispatch) => ({
  setOnboardingFlowStepAsSeen: (stepNumber) => dispatch(updateSettings(stepSeenSettingName, stepNumber)),
  setOnboardingFlowAsSeen: () => {
    dispatch(updateSettings(onboardingSeenSettingName, 'true'));
    dispatch(updateSettings('onboardingWelcomeScreenSeen', 'true'));
  },
  dispatchAddUsersOnboarding: (emails) => dispatch(addUsersOnboarding(emails)),
});

export const OnboardingFlow = connect(mapStateToProps, mapDispatchToProps)(OnboardingFlowComponent);
