'use strict';

import { settingsSuffix } from '../constants/settings.constants';

export const getSettingSeenName = (name) => `${name}${settingsSuffix.SEEN}`;

export const getSettingVersionSeenName = (name) => `${name}${settingsSuffix.VERSION}${settingsSuffix.SEEN}`;

export const getSettingPurchasedName = (name) => `${name}${settingsSuffix.PURCHASED}`;

export const getSettingEventSentName = (name) => `${name}${settingsSuffix.EVENT_SENT}`;

export const getBoolSetting = (value, defaultValue = false) => (value ? !!JSON.parse(value) : defaultValue);

export const getIntegerSetting = (value, defaultValue = 0) => (value ? parseInt(JSON.parse(value), 10) : defaultValue);

export const getObjectSetting = (value, defaultValue = {}) => (value ? JSON.parse(value) : defaultValue);

export const getMixedTypeSetting = (value, defaultValue = false) => (value ? JSON.parse(value) : defaultValue);
