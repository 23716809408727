/* globals window */

'use strict';

import config from 'react-global-configuration';
import { currentUserActions } from '../../constants/currentUser.constants';
import { apiErrors } from '../../constants/errors.constants';
import { permissionsActions } from '../../constants/permissions.constants';
import { fetchAccountPlan } from './accountSettings.actions';
import { request } from '../../utils/request';
import { logOut } from '../../utils/account';
import { microsoftClarity } from '../../utils/analyticsManager';

const switchUserAccountError = () => ({
  type: currentUserActions.SWITCH_USER_ACCOUNT_ERROR,
  isError: true,
});

export const fetchTermsOfServiceModalVisibility = (showTermsOfServiceModal) => ({
  type: currentUserActions.FETCH_TOS_MODAL_VISIBILITY,
  showTermsOfServiceModal,
});

export const getUserData = () => (
  function (dispatch) {
    request.get('/me')
      .then((data) => {
        microsoftClarity.set('Account id', data.idActiveAccount);
        microsoftClarity.set('User Email', data.email);
        dispatch(fetchTermsOfServiceModalVisibility(!data.tosAccepted));
        dispatch({
          type: currentUserActions.FETCH_CURRENT_USER_DATA,
          data,
        });

        return data;
      }, () => { window.location.href = config.get('APP_URL'); })
      .catch(() => {
        // TODO HANDLE ERROR
      });

    fetchAccountPlan()(dispatch);

    request('/members/permissions/')
      .then((data) => {
        dispatch({
          type: permissionsActions.FETCH_PERMISSIONS,
          permissions: data,
        });
      });
  });

export const switchUserAccount = (idAccount, activeTabUri) => (
  function (dispatch) {
    request.post('/me/account', {
      body: {
        idAccount,
      },
    }, true)
      .then((data) => {
        if (data.status === 200) {
          dispatch({
            type: currentUserActions.SWITCH_USER_ACCOUNT,
            isSwitchAccountSuccess: true,
            activeTabUri,
          });
        } else {
          dispatch(switchUserAccountError());
        }
      })
      .catch((error) => {
        dispatch(switchUserAccountError());

        if (error.status === apiErrors.UNAUTHORIZED) {
          logOut();
        }
      });
  });
