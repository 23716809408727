/* eslint-disable jsx-a11y/alt-text */

import React from 'react';
import autobind from 'autobind-decorator';
import config from 'react-global-configuration';
import { goTo } from '../../../utils/router';
import routes from '../../../constants/routes.constants';
import NotFoundImage from 'dashboard/images/404-full.svg';


export default class NotFound extends React.Component {
  @autobind
  goToMainPage(e) {
    e.preventDefault();
    goTo(routes.PROJECTS);
  }

  render() {
    return (
      <div className="page-404">
        <header>
          <a
            onClick={this.goToMainPage}
            onKeyDown={this.goToMainPage}
            href="#logotype">
            <img
              className="icon-general-uxpin-logo"
              src="/images/UXPin-Logo-BlackFill.svg"
              alt="UXPin-Logo-Black"
            />
          </a>
        </header>
        <section className="content">
          <figure>
            <img src={NotFoundImage} width="785" className="planet-image" />
          </figure>
          <h1>404</h1>
          <h2>Hey captain! Looks like you’re heading to a wrong planet!</h2>
          <button onClick={this.goToMainPage} type="button" className="btn-solid">Take me back to projects</button>
        </section>
      </div>
    );
  }
}
