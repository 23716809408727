export const designSystemsActions = {
  DESIGN_SYSTEMS_DELETED: 'DESIGN_SYSTEMS_DELETED',
  DESIGN_SYSTEMS_REQUEST_DELETING: 'DESIGN_SYSTEMS_REQUEST_DELETING',
  FETCH_DESIGN_SYSTEMS: 'FETCH_DESIGN_SYSTEMS',
  FETCH_LIBRARIES: 'FETCH_LIBRARIES',
  LIBRARY_ADDED: 'LIBRARY_ADDED',
  LIBRARY_PERMISSIONS_UPDATED: 'LIBRARY_PERMISSIONS_UPDATED',
  LIBRARY_STATE_UPDATED: 'LIBRARY_STATE_UPDATED',
  LIBRARY_DELETED: 'LIBRARY_DELETED',
  LIBRARY_NAME_UPDATED: 'LIBRARY_NAME_UPDATED',
  DESIGN_SYSTEMS_DUPLICATION_STARTED: 'DESIGN_SYSTEMS_DUPLICATION_STARTED',
  DESIGN_SYSTEMS_CHECK_STATUS: 'DESIGN_SYSTEMS_CHECK_STATUS',
  DESIGN_SYSTEMS_GET_TEMPLATE: 'DESIGN_SYSTEMS_GET_TEMPLATE',
  DESIGN_SYSTEMS_GET_TEMPLATES: 'DESIGN_SYSTEMS_GET_TEMPLATES',
  LIBRARIES_CHANGED_EDITED_LIBRARY: 'LIBRARIES_CHANGED_EDITED_LIBRARY',
};

export const PERMISSION_ACCESS_SCOPE = {
  ACCOUNT: 'account',
  CUSTOM: 'custom',
  USER: 'user',
  GLOBAL: 'global',
};

export const ERROR_TYPES = {
  ITEM_ADDING: 'item-adding',
  ITEM_DELETING: 'item-deleting',
  LIBRARY_ADDING: 'library-adding',
  LIBRARY_DELETING: 'library-deleting',
  LIBRARY_UPDATING: 'library-updating',
};

export const COPY_TYPE = {
  PROTOTYPE: 'PROTOTYPE',
  DESIGN_SYSTEM: 'DESIGN_SYSTEM',
};

export const LIBRARY_TYPE = {
  SKETCH: 'sketch',
  UXPIN: 'uxpin',
};

export const TEMPLATES = {
  BOOTSTRAP: 'bootstrap',
  MATERIAL: 'material',
};

export const STATUS = {
  NORMAL: 'normal',
  DUPLICATING: 'duplicating',
};
