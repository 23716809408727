import { connect } from 'react-redux';
import ModalboxAddOnsFollowUpSuccessComponent from '../../../components/Modalboxes/Promo/ModalboxAddOnsFollowUpSuccess';
import { planPeriods } from '../../../../constants/accountSettings.constants';

export const mapStateToProps = (state) => {
  const { accountPlan } = state.accountSettings;
  const isMonthly = accountPlan && accountPlan.plan_period === planPeriods.MONTH;

  return {
    isMonthly,
  };
};

export const ModalboxAddOnsFollowUpSuccess = connect(mapStateToProps, null)(ModalboxAddOnsFollowUpSuccessComponent);
