'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import autobind from 'autobind-decorator';
import classnames from 'classnames';
import { isEmpty, noop, mapValues } from 'lodash';
import { Button, DeprecatedInputText, Label } from '@uxpin/design-system';
import { GLOBAL_ELEMENTS } from '@uxpin/shared-components';
import { preventDefaultEvent } from '../../../../utils/common';
import { passwordMinCharCount } from '../../../../constants/onboarding.constants';

const isPasswordValid = (password) => (
  !isEmpty(password) && password.length >= passwordMinCharCount
);

const isFormValid = (fieldFlags) => (
  Object.values(fieldFlags).every((field) => (field === true))
);

const PASSWORD = 'password';

export default class PasswordFormComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      processing: false,
      formSubmitted: false,
      formField: {
        [PASSWORD]: '',
      },
      fieldValid: {
        [PASSWORD]: true,
      },
    };
  }

  @autobind
  handleSuccess(formData) {
    const { savePartialLeadForm, onSuccess } = this.props;

    this.setState({
      processing: true,
    });

    savePartialLeadForm(formData)
      .then(() => {
        onSuccess();
      });
  }

  getCurrentFormValues() {
    return mapValues(this.customForm.fields, (field) => field.state.value);
  }

  @autobind
  validateForm() {
    const { formField } = this.state;
    const { password } = formField;

    const fieldValid = {
      [PASSWORD]: isPasswordValid(password),
    };

    this.setState({ fieldValid });

    return isFormValid(fieldValid);
  }

  @autobind
  handleSubmit(e) {
    const { formField } = this.state;
    preventDefaultEvent(e);

    const formValid = this.validateForm();

    this.setState({
      formSubmitted: true,
    });

    if (formValid) {
      this.handleSuccess(formField);
    }
  }

  @autobind
  handleFieldChange(event) {
    const { name, value } = event.target;
    const { formField, formSubmitted } = this.state;
    formField[name] = value;

    this.setState({ formField }, () => {
      if (name === PASSWORD && formSubmitted) {
        this.validateForm();
      }
    });
  }

  @autobind
  renderPasswordField() {
    const { fieldValid, formField } = this.state;

    const invalidPassword = !fieldValid[PASSWORD];

    return (
      <fieldset className={classnames('full-width', 'password-field', { error: invalidPassword })}>
        <h4 className={classnames(GLOBAL_ELEMENTS.HEADING_4, 'password-label')}>
          <Label
            forId={PASSWORD}
            hasInheritStyle>
            Password
          </Label>
        </h4>
        <DeprecatedInputText
          id={PASSWORD}
          isError={invalidPassword}
          name={PASSWORD}
          onChange={this.handleFieldChange}
          placeholder="Minimum 6 characters."
          size="l"
          type="password"
          value={formField[PASSWORD]}
          width="m"
        />
      </fieldset>
    );
  }

  @autobind
  render() {
    const { fieldValid, processing } = this.state;

    const invalidPassword = !fieldValid[PASSWORD];

    return (
      <form onSubmit={this.handleSubmit} className="password-form">
        {this.renderPasswordField()}

        <Button
          className="btn-submit"
          isWorking={processing}
          size="l"
          type="submit">
          Continue ⟶
        </Button>

        { invalidPassword
        && <span className="error-message">Minimum 6 characters. Better safe than sorry!</span>
        }
      </form>
    );
  }
}

PasswordFormComponent.propTypes = {
  onSuccess: PropTypes.func,
  savePartialLeadForm: PropTypes.func,
};

PasswordFormComponent.defaultProps = {
  onSuccess: noop,
  savePartialLeadForm: noop,
};
