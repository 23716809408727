'use strict';

import { connect } from 'react-redux';
import { projects } from '../../controllers/actions/projects.actions';
import * as projectActions from '../../controllers/actions/projectActions.actions';
import * as projectSelector from '../../controllers/actions/projectSelector.actions';
import * as settings from '../../controllers/actions/settings.actions';
import ProjectComponent from '../components/ProjectsList/components/Project';
import { modalsIds } from '../../constants/modal.constants';
import * as modal from '../../controllers/actions/modal.actions';
import { isAccountParked } from '../../utils/account';
import { FEATURES, FEATURE_STATUS } from '../../constants/features.constants';

export const mapStateToProps = (state, props) => {
  const { permissions } = state.permissions;
  const { data: currentUser } = state.currentUser;
  const { selectedProjects } = state.projectSelector;
  const {
    features: {
      project_status: projectStatus,
      [FEATURES.REFRESHED_LAYOUT]: refreshedLayout,
    },
  } = state.features;
  const isStarred = settings.isStarredProject(state, props.data.idProject);

  return {
    currentProjectsActions: state.projectActions.currentProjectsActions,
    removingCounters: state.projectActions.removingCounters,
    errors: state.projectActions.errors,
    selectedProjects,
    isAccountParked: isAccountParked(currentUser.idActiveAccount, currentUser.accounts),
    permissions,
    currentUser,
    isProjectStatusEnabled: projectStatus === FEATURE_STATUS.ENABLED,
    refreshedLayout: refreshedLayout === FEATURE_STATUS.ENABLED,
    isStarred,
  };
};

export const mapDispatchToProps = (dispatch, props) => ({
  selectProject: (data) => {
    dispatch(projectSelector.selectProject(data));
  },

  unselectProject: (idProject) => {
    dispatch(projectSelector.unselectProject(idProject));
  },

  clearSelectedProjects: () => {
    dispatch(projectSelector.clearSelectedProjects());
  },

  stopActionOnProject: (idProject) => {
    dispatch(projectActions.stopActionOnProject(idProject));
  },

  removeProjectPermanently: (idProject) => {
    dispatch(projects.removeProjectPermanently(idProject));
  },

  restoreProject: (idProject, isArchived) => {
    dispatch(projects.restoreProject(idProject, isArchived));
  },

  requestCheckingDuplicationStatus: (idProject) => {
    dispatch(projects.requestCheckingDuplicationStatus(idProject));
  },

  renameProject: (idProject, name) => {
    dispatch(projects.renameProject(idProject, name));
  },

  openModalboxAddUser: (data) => {
    dispatch(modal.openModal(modalsIds.MODALBOX_ADD_USER, data));
  },

  openProjectSettings: (data) => {
    dispatch(modal.openModal(modalsIds.PROJECT_SETTINGS, data));
  },

  toggleStar: () => {
    const { data: { idProject } } = props;
    dispatch(settings.toggleProjectStar(idProject));
  },
});

export const ProjectOnList = connect(mapStateToProps, mapDispatchToProps)(ProjectComponent);
