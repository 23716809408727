/* eslint-disable max-len */

'use strict';

import _ from 'lodash';
import { projectStatusActions } from '../../constants/projectStatus.constants';
import { insideProjectActions } from '../../constants/insideProject.constants';
import { projectsActions } from '../../constants/projects.constants';
import { refreshActivityFeed } from './activityFeed.actions';
import { request } from '../../utils/request';

const requestSavingProjectSettings = () => ({
  type: projectStatusActions.REQUEST_SAVING_PROJECT_SETTINGS,
});

const requestFetchingProjectSettings = () => ({
  type: projectStatusActions.REQUEST_FETCHING_PROJECT_SETTINGS,
});

const getAddedStages = (stages) => {
  const addedStages = _.reduce(stages, (result, stage, index) => {
    if (stage.hasTemporaryId && !stage.isBeingAdded && !stage.isBeingDeleted && !stage.isDeleted) {
      result.push({
        name: stage.name,
        position: (index + 1),
      });
    }

    return result;
  }, []);

  return {
    stages: addedStages,
  };
};

const getDeletedStages = (stages) => {
  const deletedStages = stages.filter((stage) => stage.idStage && !stage.hasTemporaryId && (stage.isBeingDeleted || stage.isDeleted)).map((stage) => ({
    idStage: stage.idStage,
  }));

  return {
    stages: deletedStages,
  };
};

const getStagesOrder = (stages) => {
  const stagesOrder = stages.filter((stage) => !stage.isBeingDeleted && !stage.isDeleted && !stage.hasTemporaryId).map((stage, id) => ({
    idStage: stage.idStage,
    position: (id + 1),
  }));

  return {
    stages: stagesOrder,
  };
};

const getRenamedStages = (stages) => {
  const renamedStages = stages.filter((stage) => stage.isUpdated && !stage.isAdded && !stage.isBeingDeleted && !stage.isDeleted).map((stage) => ({
    idStage: stage.idStage,
    name: stage.name,
  }));

  return {
    stages: renamedStages,
  };
};

const addStages = (idProject, stages) => {
  const addedStages = getAddedStages(stages);
  if (addedStages.stages.length) {
    return request.post(`/progress/project/${idProject}/stages`, {
      body: addedStages,
    }).then((addedStagesResponse) => {
      const stagesList = stages;
      Object.keys(addedStagesResponse).forEach((i) => {
        if ({}.hasOwnProperty.call(addedStagesResponse, i)) {
          stagesList[addedStagesResponse[i].position - 1].idStage = parseInt(i, 10);
          stagesList[addedStagesResponse[i].position - 1].isAdded = true;
          delete stagesList[addedStagesResponse[i].position - 1].hasTemporaryId;
        }
      });
    });
  }

  return Promise.resolve(true);
};

const deleteStages = (idProject, stages) => {
  const deletedStages = getDeletedStages(stages);

  if (deletedStages.stages.length) {
    return request.delete(`/progress/project/${idProject}/stages`, {
      body: deletedStages,
    });
  }

  return Promise.resolve(true);
};

const renameStages = (idProject, stages) => {
  const renamedStages = getRenamedStages(stages);

  if (renamedStages.stages.length) {
    return request.post(`/progress/project/${idProject}/stages/rename`, {
      body: renamedStages,
    });
  }

  return Promise.resolve(true);
};

const setStagesOrder = (idProject, stages) => {
  const stagesOrder = getStagesOrder(stages);

  return request.post(`/progress/project/${idProject}/stages/order`, {
    body: stagesOrder,
  }, true);
};

export const setNotificationSettings = (idProject, projectStages, notificationData, currentStage, currentStatus) => {
  const idStage = !currentStage.hasTemporaryId ? currentStage.idStage : projectStages[currentStage.position].idStage;
  const idStatus = currentStatus.idStatus;

  return request.post(`/progress/project/${idProject}/notify`, {
    body: {
      idsUser: notificationData.idsUser,
      idStage,
      idStatus,
      idStageOld: notificationData.idStageOld,
      idStatusOld: notificationData.idStatusOld,
    },
  }, true);
};

const setCurrentProgress = (idProject, projectStages, notificationData, currentStage, currentStatus) => {
  const idStage = !currentStage.hasTemporaryId ? currentStage.idStage : projectStages[currentStage.position].idStage;
  const idStatus = currentStatus.idStatus;

  if (notificationData.idStatusOld !== idStatus || idStage !== notificationData.idStageOld) {
    return request.post(`/progress/project/${idProject}`, {
      body: {
        idStage,
        idStatus,
      },
    }, true).then(() => idStage);
  }

  return Promise.resolve(idStage);
};

export const saveProjectSettings = (idProject, stages, currentStage, currentStatus, notificationData) => (dispatch) => {
  const projectStages = _.cloneDeep(stages);
  dispatch(requestSavingProjectSettings());
  addStages(idProject, projectStages)
    .then(() => deleteStages(idProject, projectStages))
    .then(() => renameStages(idProject, projectStages))
    .then(() => setStagesOrder(idProject, projectStages))
    .then(() => setNotificationSettings(idProject, projectStages, notificationData, currentStage, currentStatus))
    .then(() => setCurrentProgress(idProject, projectStages, notificationData, currentStage, currentStatus))
    .then((idStage) => {
      dispatch({
        type: projectStatusActions.SAVE_PROJECT_SETTINGS,
      });
      dispatch({
        type: projectStatusActions.FETCH_PROGRESS,
        progress: {
          stage: _.find(projectStages, (stage) => stage.idStage === idStage),
          status: currentStatus,
        },
      });
      dispatch({
        type: projectsActions.UPDATE_PROJECT_STATUS,
        idProject,
        data: {
          statusName: currentStatus.name,
          statusId: currentStatus.idStatus,
          stageName: currentStage.name,
          stageId: currentStage.idStage,
        },
      });
      refreshActivityFeed(idProject)(dispatch);
    })
    .catch(() => {
      dispatch({
        type: projectStatusActions.PROJECT_SETTINGS_ERROR,
      });
    });
};

export const setDocumentStatus = (document, status) => (dispatch) => {
  dispatch({
    type: insideProjectActions.UPDATE_DOCUMENT_STATUS,
    idDocument: document.idDocument,
    statusName: status.name,
    idStatus: status.idStatus,
  });

  if (status.idStatus !== document.idStatus) {
    request.post(`/progress/document/${document.idDocument}`, {
      body: { idStatus: status.idStatus },
    }).then(() => {
      refreshActivityFeed(document.idProject)(dispatch);
    });
  }
};

export const fetchProjectSettings = (idProject) => (dispatch) => {
  dispatch(requestFetchingProjectSettings());

  request.get(`/progress/project/${idProject}/notification`)
    .then((data) => {
      dispatch({
        type: projectStatusActions.FETCH_PROJECT_SELECTED_USERS,
        selectedUsers: data,
      });
    })
    .then(() => request.get(`/progress/project/${idProject}`))
    .then((data) => {
      dispatch({
        type: projectStatusActions.FETCH_PROGRESS,
        progress: data,
      });
    })
    .then(() => request.get(`/progress/project/${idProject}/settings`))
    .then((data) => {
      dispatch({
        type: projectStatusActions.FETCH_PROJECT_SETTINGS,
        projectStages: data,
      });
    })
    .catch(() => {
      dispatch({
        type: projectStatusActions.PROJECT_SETTINGS_ERROR,
      });
    });
};
