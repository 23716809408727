/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */

'use strict';

import React from 'react';
import classnames from 'classnames';
import { statusIdToClassMap } from '../../../constants/projects.constants';

export default class SelectStatus extends React.Component {
  getClassesNames() {
    return classnames('cloud', 'options-cloud', 'status', 'with-icons');
  }

  setStatus(e, status) {
    e.preventDefault();
    const { setCurrentStatus, currentStatusId } = this.props;
    if (currentStatusId !== status.idStatus) {
      setCurrentStatus(status);
    }
  }

  render() {
    return (
      <ul style={this.props.style} className={this.getClassesNames()}>
        {this.props.projectStatuses.map((status, id) => (
          // eslint-disable-next-line max-len,react/no-array-index-key
          <li key={id}><a onClick={(e) => this.setStatus(e, status)} href="#waiting" className="status" data-status={statusIdToClassMap[status.idStatus]}>{status.name}</a></li>
        ))}
      </ul>
    );
  }
}

SelectStatus.defaultProps = {
  projectStatuses: [],
  style: {},
  currentStatusId: 0,
};
