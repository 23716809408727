/* eslint-disable complexity */
/* eslint-disable max-len */
/* eslint-disable no-shadow */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */

'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import autobind from 'autobind-decorator';
import {
  cloudPositions, triggerTypes, InlineEdit, isFirefox, isSafari,
} from '@uxpin/shared-components';
import classnames from 'classnames';
import _ from 'lodash';
import { DragSource } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import key from 'keymaster';
import moment from 'moment';
import { ProjectActions } from '../../../containers/ProjectActions';
import ProjectPending from './ProjectPending';
import ProjectStatus from '../../InsideProjectBar/components/ProjectStatus';
import DateTime from '../../DateTime/DateTime';
import { projectsStatuses, projectsActionsOnHover } from '../../../../constants/projects.constants';
import { goTo } from '../../../../utils/router';
import { sortableItemsTypes } from '../../../../constants/sortableItems.constants';
import routes from '../../../../constants/routes.constants';
import { StarButton } from '../../StarButton/StarButton';

let currentDraggedProject;
let currentEditedProjectId = null;

const setCurrentEditedProjectId = (id) => {
  currentEditedProjectId = id;
};

const boxSource = {
  beginDrag(props) {
    currentDraggedProject = props.data.idProject;
    if (!props.selectedProjects[props.data.idProject]) {
      props.clearSelectedProjects();
    }

    return {
      id: props.data.idProject,
      data: props.data,
      idProjectGroup: props.idProjectGroup,
      projectGroupIndex: props.projectGroupIndex,
      groupType: props.groupType,
    };
  },

  canDrag(props) {
    return _.isUndefined(props.errors[props.data.idProject])
      && _.get(props.permissions, 'can_create_projects.selected') === 'yes'
      && currentEditedProjectId !== props.data.idProject
      && !props.isAccountParked
      && !props.currentProjectsActions[props.data.idProject];
  },

  endDrag() {
    currentDraggedProject = null;
  },
};

export class Project extends React.Component {
  constructor(props) {
    super(props);

    this.classes = {
      DELETING: 'deleting',
      SELECTED: 'selected',
      RENAMING: 'renaming',
      ARCHIVING: 'archiving',
      UNARCHIVING: 'unarchiving',
      COUNTING: 'deleting-counter',
      RESTORING: 'restoring',
      MOVING: 'moving',
      DUPLICATING: 'duplicating',
      NO_HOVER: 'no-hover',
      ERROR: 'error',
      DRAGGING_PLACEHOLDER: 'dragging-placeholder',
      MULTIPLE_SELECT: 'multiple-select',
      PROJECT_BOX_REFRESHED: 'project-box-refreshed',
    };

    this.state = {
      isEditing: false,
      isProjectActionOpened: false,
      href: false,
    };

    this.timer = 0;
    this.DOUBLE_CLICK_DELAY = 250;
    this.LEFT_MOUSE_BTN = 0;
    this.MIDDLE_MOUSE_BTN = 1;
  }

  renderProjectStatus() {
    const { data, isProjectStatusEnabled } = this.props;

    if (data.hasProgressInfo && isProjectStatusEnabled) {
      return (
        <ProjectStatus
          project={data}
          canAddEditDeleteProjectStatus={this.canAddEditDeleteProjectStatus() && !this.isStatusArchived()}
          currentStatus={data.statusName}
          currentStage={data.stageName}
          currentStatusId={data.statusId}
          openProjectSettings={this.openProjectSettings}
        />
      );
    }
  }

  @autobind
  openProjectSettings(e) {
    e.preventDefault();
    const { data } = this.props;

    if (this.isMultipleSelectionMode(e)) {
      this.toggleProjectSelection();
      return;
    }

    this.props.openProjectSettings(data.idProject);
  }

  @autobind
  openModalBoxAddUser(e) {
    e.preventDefault();

    if (this.isMultipleSelectionMode(e)) {
      this.toggleProjectSelection();
      return;
    }

    this.props.openModalboxAddUser(this.props.data);
  }

  canAddEditDeleteProjectStatus() {
    const { permissions } = this.props;
    return _.get(permissions, 'can_add_edit_delete_project_status.selected') === 'yes';
  }

  shouldHideManageUsersBtn() {
    const { permissions, data } = this.props;

    if (_.isEmpty(permissions) || _.isEmpty(data)) {
      return true;
    }

    if (data.status === projectsStatuses.ARCHIVED
      || permissions.can_add_users_to_projects.selected !== 'all') {
      return true;
    }

    return false;
  }

  canManageProjects() {
    const { permissions } = this.props;
    return _.get(permissions, 'can_create_projects.selected') === 'yes';
  }

  renderManageUsersBtn() {
    if (this.shouldHideManageUsersBtn()) {
      return null;
    }

    return (
      <span className="manage-users">
        <a
          href="#add-user"
          className="icon-general-add-user underline-link gray-link"
          onClick={this.openModalBoxAddUser}>
          Manage Users
        </a>
      </span>
    );
  }

  renderLastEditDate(data, userName) {
    const label = this.props.data.status === projectsStatuses.ARCHIVED ? 'Archived' : 'Last edit';
    const isAuthorInfo = this.props.data.status === projectsStatuses.ARCHIVED || !_.isEmpty(this.props.data.user);

    if (data.lastUpdate) {
      return (
        <p className="last-edit-date">
          <span>
            {label}
            :&nbsp;
          </span>
          <DateTime
            date={moment.utc(data.lastUpdate, 'YYYY-MM-DD h:mm:ss').utcOffset('+01:00', true).toDate()}
            showFromNow
            fromNowDaysLimit={14}
          />
          {this.renderAuthorInfo(isAuthorInfo, data, userName)}
        </p>
      );
    }
  }

  renderAuthorInfo(isAuthorInfo, data, userName) {
    if (!isAuthorInfo) {
      return;
    }

    return (
      <span>
        &nbsp;by&nbsp;
        <span
          className="user-mail"
          title={userName || data.user.email}>
          {userName || data.user.email}
        </span>
      </span>
    );
  }

  isProjectBeingDuplicated() {
    const { data } = this.props;
    return [projectsStatuses.DUPLICATED, projectsStatuses.ARCHIVED_DUPLICATED].indexOf(data.status) !== -1;
  }

  isError() {
    const { errors, data } = this.props;
    return !_.isUndefined(errors[data.idProject]);
  }

  isSelected(props = this.props) {
    const { data: { idProject }, selectedProjects } = props;
    return !!selectedProjects[idProject];
  }

  toggleProjectSelection() {
    const { data, selectProject, unselectProject } = this.props;
    if (!this.isSelected()) {
      selectProject(data);
    } else {
      unselectProject(data.idProject);
    }
  }

  isMultipleSelectionMode(e) {
    return (key.ctrl || key.command) && e.button === this.LEFT_MOUSE_BTN;
  }

  @autobind
  handleMouseDown(e) {
    if (this.isMultipleSelectionMode(e)) {
      e.preventDefault();
      e.stopPropagation();
      this.toggleProjectSelection();
    }
  }

  @autobind
  handleClick(event) {
    const tagName = event.target.tagName.toLowerCase();

    if (this.isMultipleSelectionMode(event)) {
      event.preventDefault();
      return;
    }

    if (!key.ctrl && !key.command && !key.shift && event.button === this.LEFT_MOUSE_BTN) {
      event.preventDefault();
    }

    this.setState({
      href: false,
    });

    if (this.isError()
      || this.isStatusArchived()
      || event.button === this.MIDDLE_MOUSE_BTN
      || tagName === 'input') {
      return;
    }

    if (!this.canManageProjects()) {
      event.stopPropagation();
      this.goToProject();
      return;
    }

    if (this.timer) {
      clearTimeout(this.timer);
    }

    this.timer = setTimeout(() => { this.goToProject(); }, this.DOUBLE_CLICK_DELAY);
  }

  @autobind
  handleDoubleClick() {
    if (this.isStatusArchived() || !this.canManageProjects()) {
      return;
    }

    clearTimeout(this.timer);
  }

  isStatusArchived() {
    const { data } = this.props;

    return data.status === projectsStatuses.ARCHIVED;
  }

  goToProject() {
    const { data } = this.props;
    const url = `${routes.PROJECT}/${data.idProject}`;

    goTo(url);
  }

  @autobind
  handleInlineEditBlur() {
    const { setCurrentEditedProjectId, handleInlineEditFocus } = this.props;
    this.setState({
      isEditing: false,
    });
    setCurrentEditedProjectId(null);
    handleInlineEditFocus(false);
  }

  @autobind
  handleInlineEditFocus() {
    const { handleInlineEditFocus, setCurrentEditedProjectId, data } = this.props;
    this.setState({
      isEditing: true,
    });
    setCurrentEditedProjectId(data.idProject);
    handleInlineEditFocus(true);
  }

  @autobind
  saveProjectName(projectName) {
    const { renameProject, data } = this.props;
    renameProject(data.idProject, projectName);
  }

  componentDidMount() {
    this.props.connectDragPreview(getEmptyImage(), {
      captureDraggingState: true,
    });
  }

  hasDuplicationStatusRequestBeenSent() {
    const { data, currentProjectsActions } = this.props;
    return (currentProjectsActions[data.idProject] === projectsActionsOnHover.CHECKING_DUPLICATION_STATUS);
  }

  isProjectBeingRemoved() {
    const { data, currentProjectsActions } = this.props;
    return (currentProjectsActions[data.idProject] === projectsActionsOnHover.REMOVING);
  }

  isProjectBeingMoved() {
    const { data, currentProjectsActions } = this.props;
    return (currentProjectsActions[data.idProject] === projectsActionsOnHover.MOVING);
  }

  isProjectInCountingMode() {
    const { data, currentProjectsActions } = this.props;
    return (currentProjectsActions[data.idProject] === projectsActionsOnHover.COUNTING);
  }

  isProjectBeingRestored() {
    const { data, currentProjectsActions } = this.props;
    return (currentProjectsActions[data.idProject] === projectsActionsOnHover.RESTORING);
  }

  isProjectBeingRenamed() {
    const { data, currentProjectsActions } = this.props;
    return (currentProjectsActions[data.idProject] === projectsActionsOnHover.RENAMING);
  }

  isProjectBeingArchived() {
    const { data, currentProjectsActions } = this.props;
    return (currentProjectsActions[data.idProject] === projectsActionsOnHover.ARCHIVING);
  }

  isProjectBeingUnarchived() {
    const { data, currentProjectsActions } = this.props;
    return (currentProjectsActions[data.idProject] === projectsActionsOnHover.UNARCHIVING);
  }

  getClasses() {
    const { refreshedLayout } = this.props;
    const classes = {};
    classes[this.classes.SELECTED] = this.state.isProjectActionOpened;
    classes[this.classes.DELETING] = this.isProjectBeingRemoved();
    classes[this.classes.RENAMING] = this.isProjectBeingRenamed();
    classes[this.classes.ARCHIVING] = this.isProjectBeingArchived();
    classes[this.classes.UNARCHIVING] = this.isProjectBeingUnarchived();
    classes[this.classes.COUNTING] = this.isProjectInCountingMode();
    classes[this.classes.RESTORING] = this.isProjectBeingRestored();
    classes[this.classes.DUPLICATING] = this.isProjectBeingDuplicated() && !this.isError();
    classes[this.classes.MOVING] = this.isProjectBeingMoved();
    classes[this.classes.ERROR] = this.isError();
    classes[this.classes.DRAGGING_PLACEHOLDER] = (this.props.currentDraggedProject && this.isSelected()) || (this.props.currentDraggedProject === this.props.data.idProject);
    classes[this.classes.NO_HOVER] = !!this.props.currentProjectsActions[this.props.data.idProject]
      || this.props.currentDraggedProject || this.state.isEditing || this.isError()
      || this.isProjectBeingDuplicated() || (this.isSelected() && !this.props.currentDraggedProject);
    classes[this.classes.MULTIPLE_SELECT] = this.isSelected() && !this.props.currentDraggedProject;

    classes[this.classes.PROJECT_BOX_REFRESHED] = refreshedLayout;

    return classnames(classes);
  }

  @autobind
  onProjectActionCloudOpen() {
    this.setState({
      isProjectActionOpened: true,
    });
  }

  @autobind
  onProjectActionCloudClose() {
    this.setState({
      isProjectActionOpened: false,
    });
  }

  renderProjectActions() {
    const { data } = this.props;
    if (this.canManageProjects()) {
      return (
        <ProjectActions
          projectData={data}
          triggerType={triggerTypes.HOVER}
          autoClose
          onOpen={this.onProjectActionCloudOpen}
          onClose={this.onProjectActionCloudClose}
          defaultPosition={cloudPositions.RIGHT_TOP}
        />
      );
    }

    return null;
  }

  renderTitle() {
    const { data } = this.props;

    return (
      <InlineEdit
        value={data.name}
        title={data.name}
        onFocus={this.handleInlineEditFocus}
        onBlur={this.handleInlineEditBlur}
        onChange={this.saveProjectName}
        disabled={this.isStatusArchived()}
      />
    );
  }

  @autobind
  onMouseDown(e) {
    // Safari fix - we have to remove href attribute before dnd to rid of grey box with information about link
    if (e.button === this.LEFT_MOUSE_BTN) {
      this.setState({
        href: null,
      });
    }
  }

  @autobind
  onMouseUp() {
    this.setState({
      href: false,
    });
  }

  getHref() {
    const { data } = this.props;
    return this.state.href !== false ? this.state.href : `${routes.PROJECT}/${data.idProject}`;
  }

  getStyle() {
    const styles = {};

    if (this.props.refreshedLayout) {
      styles.borderTopColor = this.props.color;
    }

    if ((isFirefox || isSafari) && this.props.currentDraggedProject && this.props.currentDraggedProject !== this.props.data.idProject) {
      styles.pointerEvents = 'none';
    }

    return styles;
  }

  renderProject() {
    const {
      data,
      requestCheckingDuplicationStatus,
      stopActionOnProject,
      restoreProject,
      errors,
      removeProjectPermanently,
      removingCounters,
      toggleStar,
      isStarred,
    } = this.props;

    const userName = data.user && (data.user.firstname || data.user.lastname)
      ? (`${data.user.firstname} ${data.user.lastname}`).trim() : '';

    const isArchived = this.props.data.status === projectsStatuses.ARCHIVED;

    return (
      <section
        data-test={`project-box-${data.idProject}`}
        onMouseDown={this.onMouseDown}
        onMouseUp={this.onMouseUp}
        style={this.getStyle()}
        className={`project-box project-single ${this.getClasses()}`}>
        <div className="star-button-container">
          <StarButton toggleStar={toggleStar} isStarred={isStarred} />
        </div>
        {this.renderProjectStatus()}
        <a
          href={this.getHref()}
          className="gray-link header"
          onMouseDown={this.handleMouseDown}
          onClick={this.handleClick}
          onDoubleClick={this.handleDoubleClick}>
          <h3 className="name" data-test={`project-box-title-${data.idProject}`} title={data.name}>
            {this.renderTitle()}
          </h3>
          {(data.user) ? (
            this.renderLastEditDate(data, userName)
          ) : ''}
          <p className="project-start-date">
            Started:&nbsp;
            <DateTime
              date={moment.utc(data.insertDate, 'YYYY-MM-DD h:mm:ss').utcOffset('+00:00', true).toDate()}
              showFromNow
              fromNowDaysLimit={14}
            />
          </p>
        </a>
        <footer className="details">
          {this.renderManageUsersBtn()}
          {this.renderProjectActions()}
        </footer>
        <ProjectPending
          shouldStartCheckingDuplicationStatus={this.isProjectBeingDuplicated()}
          projectName={data.name}
          idProject={data.idProject}
          hasTemporaryId={data.hasTemporaryId}
          stopActionOnProject={stopActionOnProject}
          removingCounter={removingCounters[data.idProject]}
          removeProjectPermanently={removeProjectPermanently}
          restoreProject={restoreProject}
          isArchived={isArchived}
          error={errors[data.idProject]}
          hasDuplicationStatusRequestBeenSent={this.hasDuplicationStatusRequestBeenSent()}
          requestCheckingDuplicationStatus={requestCheckingDuplicationStatus}
        />
      </section>
    );
  }

  render() {
    const {
      connectDragSource,
    } = this.props;

    return connectDragSource(this.renderProject(), 'move');
  }
}

Project.propTypes = {
  data: PropTypes.object.isRequired,
  isStarred: PropTypes.bool.isRequired,
  stopActionOnProject: PropTypes.func.isRequired,
  restoreProject: PropTypes.func.isRequired,
  // eslint-disable-next-line react/require-default-props
  permissions: PropTypes.object,
};

export default DragSource(sortableItemsTypes.PROJECT, boxSource, (connect) => ({
  connectDragSource: connect.dragSource(),
  connectDragPreview: connect.dragPreview(),
  currentDraggedProject,
  setCurrentEditedProjectId,
}))(Project);
