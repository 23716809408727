import { connect } from 'react-redux';
import ModalboxDesignSystemDelete from '../components/Modalboxes/ModalboxDesignSystemDelete';
import { modalsIds } from '../../constants/modal.constants';
import { closeModal } from '../../controllers/actions/modal.actions';
import {
  deleteDesignSystem,
  deleteDesignSystemWithLibrary,
  actionDeleteLibrary,
} from '../../controllers/actions/designSystems.actions';


export const mapStateToProps = (state) => {
  const { data: modalData, openedModalsIds } = state.modal;
  const { isDesignSystemDeleting } = state.designSystems;

  return {
    isDesignSystemDeleting,
    isOpen: openedModalsIds[modalsIds.MODALBOX_DESIGN_SYSTEM_DELETE] || false,
    data: modalData[modalsIds.MODALBOX_DESIGN_SYSTEM_DELETE] || {},
  };
};

export const mapDispatchToProps = (dispatch) => ({
  deleteDesignSystem: (idDesignSystem, idLibrary) => {
    dispatch(deleteDesignSystem(idDesignSystem, idLibrary));
  },

  deleteDesignSystemWithLibrary: (idLibrary) => {
    dispatch(deleteDesignSystemWithLibrary(idLibrary));
  },

  onClose: () => {
    dispatch(closeModal(modalsIds.MODALBOX_DESIGN_SYSTEM_DELETE));
  },

  deleteMergeLibrary: (idLibrary) => {
    dispatch(actionDeleteLibrary(idLibrary));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalboxDesignSystemDelete);
