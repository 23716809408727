/* eslint-disable react/destructuring-assignment */

'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autobind from 'autobind-decorator';

import DesignSystemPermissions from '../../containers/DesignSystemPermissions';

export default class ModalboxDesignSystemPermission extends Component {
  constructor(props) {
    super(props);
    this.MODAL = {
      WRAPPER_ID: 'design-system-permissions',
      CLASS_NAMES: ['modal-box-medium-width'],
    };
  }

  @autobind
  onClose(e) {
    if (e) {
      e.preventDefault();
    }

    this.props.onClose();
  }

  @autobind
  onUpdateLibraryPermissions(libraryData) {
    const { designSystemData } = this.props;
    const { accessScope, editScope } = libraryData;

    const id = libraryData.id ? libraryData.id : designSystemData.idLibrary;
    const name = libraryData.name ? libraryData.name : designSystemData.name;

    this.props.updateLibraryPermissions(id, { accessScope, editScope, name });
  }

  render() {
    const { designSystemData, isOpen } = this.props;
    return (
      <DesignSystemPermissions
        isOpen={isOpen}
        onClose={this.onClose}
        onSuccess={this.onUpdateLibraryPermissions}
        editedLibrary={designSystemData}
      />
    );
  }
}

ModalboxDesignSystemPermission.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  designSystemData: PropTypes.object.isRequired,
  updateLibraryPermissions: PropTypes.func.isRequired,
};
