/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */

'use strict';

import React from 'react';
import autobind from 'autobind-decorator';

export default class StakeholderDeleted extends React.Component {
  @autobind
  undo(e) {
    const { restoreStakeholder } = this.props;
    e.preventDefault();
    e.stopPropagation();
    restoreStakeholder();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.stakeholder.time === 0) {
      nextProps.removeStakeholder();
    }
  }

  @autobind
  removeStakeholder(e) {
    e.preventDefault();
    // eslint-disable-next-line react/destructuring-assignment
    this.props.removeStakeholder();
  }

  render() {
    const { stakeholder } = this.props;
    return (
      <section className="deleted-item-info">
        <p className="remaining-time hidden-info">
          {stakeholder.time}
s
        </p>
        <h5 className="info">
Stakeholder "
          <span className="item-name">{`${stakeholder.firstname} ${stakeholder.lastname}`}</span>
" has been deleted.&nbsp;
          <a onClick={this.undo} href="#undo-delete" className="blue-link underline-link">Undo.</a>
          <a
            onClick={this.removeStakeholder}
            href="#execute"
            title="Execute"
            className="execute icon-general-close only-icon-font">
            Execute
          </a>
        </h5>
      </section>
    );
  }
}
