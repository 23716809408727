import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { MergeCreateLibrary } from '../../containers/MergeCreateLibrary';
import { MergeLibraryList } from '../../containers/MergeLibraryList';
import { getEditableAndVisibleMergeLibraries } from '../../../controllers/helpers/designSystems.helper';

export default class MergeTabContent extends React.Component {
  renderMergeLibrariesList() {
    const {
      libraries,
      isLibraryListFetched,
      isMergeEnabled,
      isStorybookEnabled,
      hiddenLibraries,
      currentUserData: { id: currentUserId },
    } = this.props;

    if (!isLibraryListFetched || !currentUserId) {
      return null;
    }

    const visibleEditableMergeLibraries = getEditableAndVisibleMergeLibraries(
      libraries,
      hiddenLibraries,
      currentUserId,
      isMergeEnabled,
      isStorybookEnabled
    );

    if (isEmpty(visibleEditableMergeLibraries)) {
      return <MergeCreateLibrary />;
    }
    return <MergeLibraryList />;
  }

  render() {
    const { features } = this.props;

    if (isEmpty(features)) {
      return null;
    }

    return this.renderMergeLibrariesList();
  }
}

MergeTabContent.propTypes = {
  currentUserData: PropTypes.shape({
    id: PropTypes.number,
  }),
  features: PropTypes.object.isRequired,
  isLibraryListFetched: PropTypes.bool.isRequired,
  isMergeEnabled: PropTypes.bool.isRequired,
  isStorybookEnabled: PropTypes.bool.isRequired,
  libraries: PropTypes.array.isRequired,
  hiddenLibraries: PropTypes.array.isRequired,
};

MergeTabContent.defaultProps = {
  currentUserData: {},
};
