import { connect } from 'react-redux';
import * as accountSettings from '../../controllers/actions/accountSettings.actions';
import ModalboxBetaWelcomeComponent from '../components/Beta/ModalboxBetaWelcome';

export const mapStateToProps = (state) => {
  const { isRevertSuccess, isError } = state.accountSettings;
  const { features } = state.features;

  return {
    features,
    isRevertSuccess,
    isError,
  };
};

export const mapDispatchToProps = (dispatch) => ({
  revertToOldDashboard: (isEnabled) => {
    dispatch(accountSettings.revertToOldDashboard(isEnabled));
  },
});

export const ModalboxBetaWelcome = connect(mapStateToProps, mapDispatchToProps)(ModalboxBetaWelcomeComponent);
