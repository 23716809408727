'use strict';

import React from 'react';
import config from 'react-global-configuration';
import Tooltip from '../../Helpers/Tooltip';

export default class CreateAccount extends React.Component {
  createNewAccount(e) {
    e.preventDefault();

    window.location.href = `${config.get('APP_URL')}/dms/DPAccountSettings/CreateAccountForNonOwner`;
  }

  render() {
    return (
      <a
        className="btn-white-simple-add big rounded"
        href="#create-own-account"
        onClick={this.createNewAccount}>
        <Tooltip className="on-right-side">
          Create your own UXPin account
        </Tooltip>
      </a>
    );
  }
}
