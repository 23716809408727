import { isEmpty, isFunction } from 'lodash';
import { ITEM_TYPES } from '../../../enums/designLibraries';

const storybookLibraries = {};
const MAX_ATTEMPTS = 100;
const TIMEOUT = 100;
// TODO - has to be moved to uxpin-shared-components it used in uxpin-app as well
export const getStorybookBaseUrl = (url) => {
  if (!url) {
    return '';
  }

  try {
    let formattedUrl = new URL(url);
    formattedUrl = formattedUrl.href.replace(formattedUrl.search, '');


    if (/\/iframe.html.*$/.test(formattedUrl)) {
      const splittedUrl = formattedUrl.split('/');
      splittedUrl.pop();
      formattedUrl = splittedUrl.join('/');
    }

    const parsedURL = new URL(formattedUrl);

    if (parsedURL.protocol === 'http:') {
      parsedURL.protocol = 'https:';
    }

    const href = parsedURL.href;

    if (href.slice(-1) === '/') {
      return href.slice(0, -1);
    }

    return href;
  } catch (e) {
    return '';
  }
};

export const getStorybookComponentUrl = (baseUrl, id) => `${baseUrl}/iframe.html?id=${id}`;

export const getProxiedStorybookUrl = (baseUrl) => `${window.location.origin}/storybook/${baseUrl}/iframe.html`;

export const extractCategoriesAndItemsFromStories = (stories, url, idLibrary) => {
  if (!stories) {
    return { categories: {}, items: [] };
  }

  return Object.values(stories).reduce((result, story, index) => {
    if (story.parameters && story.parameters.docsOnly) {
      return result;
    }

    const arr = story.kind.split('/');
    const categoryId = arr.shift();
    // eslint-disable-next-line no-param-reassign
    result.categories[categoryId] = {
      id: categoryId,
      name: categoryId,
      idLibrary,
      type: ITEM_TYPES.STORYBOOK_COMPONENT,
    };

    const breadcrumbs = arr.join(' / ');
    result.items.push({
      id: index,
      idBreadcrumbs: breadcrumbs,
      idCategory: categoryId,
      idLibrary,
      type: ITEM_TYPES.STORYBOOK_COMPONENT,
      name: story.name,
      url: getStorybookComponentUrl(url, story.id),
    });

    return result;
  }, { categories: {}, items: [] });
};

const getStorybookApi = (iframe, attempt = 1) => new Promise((resolve) => {
  const storyBookApi = iframe.contentWindow && iframe.contentWindow.__STORYBOOK_CLIENT_API__;
  if (!storyBookApi) {
    resolve();
  }

  resolve(storyBookApi);
}).then((api) => {
  if (api) {
    return api;
  }

  return new Promise((resolve) => {
    setTimeout(async () => {
      if (attempt <= MAX_ATTEMPTS) {
        resolve(await getStorybookApi(iframe, attempt + 1));
      } else {
        resolve();
      }
    }, TIMEOUT);
  });
});

function getStore(storyBookApi, iframe) {
  if (isFunction(storyBookApi.store)) {
    return storyBookApi.store();
  }

  if (storyBookApi.storyStore) {
    return storyBookApi.storyStore;
  }

  if (storyBookApi._storyStore) {
    return storyBookApi._storyStore;
  }

  return iframe.contentWindow.__STORYBOOK_STORY_STORE__;
}

export const getStorybookStories = async (iframe) => {
  try {
    const storyBookApi = await getStorybookApi(iframe);
    if (!storyBookApi) {
      return false;
    }

    const store = getStore(storyBookApi, iframe);

    if (!store) {
      return false;
    }

    if (store.getStoriesJsonData) {
      try {
        return store.getStoriesJsonData().stories;
      } catch (e) {
        if (store.cacheAllCSFFiles) {
          await store.cacheAllCSFFiles();
          return store.getStoriesJsonData().stories;
        }

        throw e;
      }
    }

    return store._stories || store._data;
  } catch (e) {
    return false;
  }
};

export const loadStorybookLibrary = (url, idLibrary) => {
  if (storybookLibraries[url]) {
    return storybookLibraries[url];
  }

  storybookLibraries[url] = new Promise((resolve) => {
    window.requestAnimationFrame(() => {
      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      iframe.onload = async () => {
        const stories = await getStorybookStories(iframe);
        const {
          categories,
          items,
        } = extractCategoriesAndItemsFromStories(stories, url, idLibrary);
        resolve([categories, items]);
        iframe.remove();
      };
      iframe.src = getProxiedStorybookUrl(url);
      document.body.append(iframe);
    });
  });

  return storybookLibraries[url];
};

export const isStorybookValidated = async (url) => {
  const [categories, items] = await loadStorybookLibrary(url);
  return !isEmpty(categories) && !isEmpty(items);
};
