/* eslint-disable max-len */
/* eslint-disable react/prop-types */

'use strict';

import React from 'react';
import autobind from 'autobind-decorator';

export default class StakeholderActions extends React.Component {
  @autobind
  onEdit(e) {
    e.preventDefault();
    e.stopPropagation();
    const { openModalboxAddStakeholder } = this.props;
    openModalboxAddStakeholder(e);
  }

  @autobind
  onDelete(e) {
    e.preventDefault();
    e.stopPropagation();
    const { startRemovingStakeholder, id, document } = this.props;
    const idApproval = document.idApproval;
    startRemovingStakeholder(idApproval, id);
  }

  render() {
    return (
      <aside className="item-actions">
        <ul>
          <li>
            <a href="#rename" onClick={this.onEdit} className="icon-general-pencil only-icon-font blue-hover">Edit name</a>
          </li>
          <li>
            <a href="#delete" onClick={this.onDelete} className="icon-general-trash only-icon-font red-hover">Delete stakeholder</a>
          </li>
        </ul>
      </aside>
    );
  }
}
