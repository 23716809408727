/* eslint-disable react/destructuring-assignment */

'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Button } from '@uxpin/design-system';
import { GLOBAL_ELEMENTS } from '@uxpin/shared-components';
import config from 'react-global-configuration';
import autobind from 'autobind-decorator';
import { noop } from 'lodash';
import { preventDefaultEvent } from '../../../../utils/common';
import { limitsButtonTypes } from '../../../../constants/modal.constants';
import { getMixpanel } from '../../../../../../shared/mixpanel';

const {
  MANAGE_USERS,
  SEND_CONTACT_REQUEST,
  UPGRADE,
  CANCEL,
} = limitsButtonTypes;

const USER_MANAGEMENT_URL = `${config.get('APP_URL')}/dms/UserManagement/Default/`;


export default class ModalboxLimitsExceededButtonsComponent extends React.Component {
  constructor(props) {
    super(props);

    this.buttonRenders = {
      [MANAGE_USERS]: this.renderManageYourUsers,
      [SEND_CONTACT_REQUEST]: this.renderSendContactRequest,
      [UPGRADE]: this.renderUpgrade,
      [CANCEL]: this.renderCancel,
    };
  }

  @autobind
  actionClose(e) {
    preventDefaultEvent(e);
    this.props.closeModal();
  }

  actionManageUsers(e) {
    preventDefaultEvent(e);
    window.location.href = USER_MANAGEMENT_URL;
  }

  @autobind
  actionSendContactRequest(e) {
    preventDefaultEvent(e);
    this.actionClose();
    this.props.openContactRequestModal();
  }

  @autobind
  actionUpgrade(e) {
    preventDefaultEvent(e);
    this.actionClose();
    this.props.openUpgradeModal();
  }

  renderButton(action, text, isLight) {
    const btnWeight = isLight ? 'light' : 'default';

    return (
      <Button
        onClick={action}
        weight={btnWeight}>
        {text}
      </Button>
    );
  }

  @autobind
  renderUpgrade(isPrimary) {
    const text = 'Upgrade now';
    const action = this.actionUpgrade;

    function handleUpgradeButtonClick(e) {
      getMixpanel((mixpanel) => {
        mixpanel.track('upgrade_button_clicked', {
          app_part: 'uxpin-app-limit-modal',
        });
      });

      action(e);
    }

    return this.renderButton(handleUpgradeButtonClick, text, !isPrimary);
  }

  @autobind
  renderSendContactRequest(isPrimary) {
    const text = 'Talk to us';
    const action = this.actionSendContactRequest;

    return this.renderButton(action, text, !isPrimary);
  }

  @autobind
  renderManageYourUsers(isPrimary) {
    const text = 'Manage your users';
    const action = this.actionManageUsers;

    return this.renderButton(action, text, !isPrimary);
  }

  @autobind
  renderCancel(isPrimary) {
    const text = 'Cancel';
    const action = this.actionClose;

    return this.renderButton(action, text, !isPrimary);
  }

  renderPrimaryButton() {
    const { primaryButton } = this.props;

    if (!primaryButton) {
      return null;
    }

    return (
      <li className="button">
        {this.buttonRenders[primaryButton](true)}
      </li>
    );
  }

  renderSecondaryButton() {
    const { secondaryButton } = this.props;

    if (!secondaryButton) {
      return null;
    }

    return (
      <li className="button">
        {this.buttonRenders[secondaryButton](false)}
      </li>
    );
  }

  render() {
    return (
      <ul className={classnames(GLOBAL_ELEMENTS.UNORDERED_LIST, 'buttons')}>
        {this.renderSecondaryButton()}
        {this.renderPrimaryButton()}
      </ul>
    );
  }
}

ModalboxLimitsExceededButtonsComponent.propTypes = {
  primaryButton: PropTypes.string,
  secondaryButton: PropTypes.string,
  closeModal: PropTypes.func,
  openUpgradeModal: PropTypes.func,
  openContactRequestModal: PropTypes.func,
};

ModalboxLimitsExceededButtonsComponent.defaultProps = {
  primaryButton: '',
  secondaryButton: '',
  closeModal: noop,
  openUpgradeModal: noop,
  openContactRequestModal: noop,
};
