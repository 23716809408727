/* eslint-disable max-len */

'use strict';

import React from 'react';
import autobind from 'autobind-decorator';
import { Cloud, FilterListCloud, SelectUser } from '@uxpin/shared-components';
import _ from 'lodash';
import { rolesId } from '../../../../constants/roles.constants';

export class UsersAccess extends FilterListCloud {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      cloudPositionClass: '',
      selectedUsers: [],
      items: [],
      filterString: '',
      style: null,
    };

    this.isMaxHeightSet = true;
  }

  componentWillMount() {
    this.setState({
      items: [...this.props.itemsList],
      selectedUsers: [...this.props.selectedUsers],
    });
    this.cache = {};
  }

  componentWillReceiveProps(nextProps) {
    if (!_.isEqual(this.props.selectedUsers, nextProps.selectedUsers)) {
      this.setState({
        selectedUsers: [...nextProps.selectedUsers],
      });
    }
  }

  @autobind
  setNodesReferences(ref) {
    this.selectUserCloud = ref;
    if (this.selectUserCloud) {
      this.filterForm = this.selectUserCloud.querySelector('form.search-form');
    }
  }

  @autobind
  updateSelectedUser(selectedUsers) {
    const { addSelectedUsersToGroup, index } = this.props;
    this.setState({
      selectedUsers,
    });
    addSelectedUsersToGroup(selectedUsers, index);
  }

  @autobind
  getDisabledUserList() {
    const { itemsList, selectedUsers } = this.props;
    if (selectedUsers.length === 1) {
      return [];
    }
    const rolesCanSeeAllProject = [
      rolesId.OWNER,
      rolesId.MANAGER,
      rolesId.ADMIN,
    ];
    return _.map(_.filter(itemsList, (user) => rolesCanSeeAllProject.includes(user.role)), 'id');
  }

  renderLink(label) {
    const { canAddEditDeleteProjectGroups } = this.props;
    if (!canAddEditDeleteProjectGroups) {
      return (
        <span className="select-users underline-link blue-hover">
          {label}
        </span>
      );
    }

    return (
      <a {...this.props.trigger} href="#access" className="select-users underline-link blue-hover">
        {label}
      </a>
    );
  }

  isCurrentUserInSelectedUsers() {
    const { currentUserId } = this.props;
    return !!this.state.selectedUsers.filter((user) => user === currentUserId).length;
  }

  // eslint-disable-next-line complexity
  renderAccessLabel() {
    const isCurrentUserInSelectedUsers = this.isCurrentUserInSelectedUsers();
    let projectGroupUserCount = 0;
    if (this.state.selectedUsers.length) {
      projectGroupUserCount = _.union(this.state.selectedUsers, this.getDisabledUserList()).length;
    }
    if (projectGroupUserCount === 1 && isCurrentUserInSelectedUsers) {
      return (<span>Access:&nbsp; {this.renderLink('only you')} </span>);
    } if (projectGroupUserCount === 1 && !isCurrentUserInSelectedUsers) {
      return (<span>Access:&nbsp; {this.renderLink('1 user')} </span>);
    } if (projectGroupUserCount === this.props.itemsList.length) {
      return (<span>Access:&nbsp; {this.renderLink('everyone')}</span>);
    } if (projectGroupUserCount === 2 && isCurrentUserInSelectedUsers) {
      return (<span>Access:&nbsp; {this.renderLink('you and 1 user')}</span>);
    } if (projectGroupUserCount > 2 && isCurrentUserInSelectedUsers) {
      return (<span>Access:&nbsp; {this.renderLink(`you and ${projectGroupUserCount - 1} users`)}</span>);
    } if (projectGroupUserCount >= 2 && !isCurrentUserInSelectedUsers) {
      return (<span>Access:&nbsp; {this.renderLink(`${projectGroupUserCount} users`)}</span>);
    }

    return <span />;
  }

  shouldComponentUpdate(nextProps, nextState) {
    /* istanbul ignore next */
    return Object.keys(this.props).some((propName) => !_.isEqual(this.props[propName], nextProps[propName]))
      || Object.keys(this.state).some((propName) => !_.isEqual(this.state[propName], nextState[propName]));
  }

  render() {
    const { itemsList, currentUserId } = this.props;
    return (
      <small ref={this.setNodesReferences} className="users-access">
        {this.renderAccessLabel()}
        <SelectUser
          {...this.props.cloud}
          filterElements={this.filterItems}
          visibleUsers={this.state.items}
          selectedUsers={this.state.selectedUsers}
          updateSelectedUser={this.updateSelectedUser}
          currentUserId={currentUserId}
          disabledUserIds={this.getDisabledUserList()}
          allUsers={itemsList}
          className="permissions-cloud auto-width"
        />
      </small>
    );
  }
}

export default Cloud()(UsersAccess);
