/* eslint-disable react/button-has-type,max-len */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */

'use strict';

import React from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import { Link } from 'react-router';
import { APPEARANCES, Link as DSLink } from '@uxpin/design-system';
import { Modal } from '@uxpin/shared-components';
import { projectsStatuses } from '../../../constants/projects.constants';
import { getUrl } from '../../../utils/router';
import routes from '../../../constants/routes.constants';

export default class ModalboxProjectDelete extends React.Component {
  constructor(props) {
    super(props);
    this.MODAL = {
      WRAPPER_ID: 'project-delete-modal-box',
      CLASS_NAMES: ['modal-box-medium-width'],
    };
  }

  renderTitle() {
    const { data } = this.props;
    return (
      <h2>Are you sure, you want to delete project "{ data.name }"?</h2>
    );
  }

  renderDescription() {
    if (this.props.isPrototype) {
      return;
    }

    const { data } = this.props;
    let filesCountLabel = '';

    if (_.isUndefined(data.documentCount)) {
      return (<p>Here are your options:</p>);
    }

    if (data.documentCount === 0) {
      filesCountLabel = 'no files';
    } else if (data.documentCount === 1) {
      filesCountLabel = '1 file';
    } else {
      filesCountLabel = `${data.documentCount} files`;
    }

    return (
      <p>
        This project has&nbsp;
        <Link
          to={getUrl(`${routes.PROJECT}/${data.idProject}`)}
          href="#"
          className="blue-link underline-link">
          {filesCountLabel}
        </Link>
        . Here are your options:
      </p>
    );
  }

  renderArchiveButton() {
    if (this.props.isPrototype) {
      return;
    }

    const commonClasses = 'btn-ghost light-gray btn-wide with-icon';
    let tooltip;

    if (this.props.allowArchive === false) {
      tooltip = (<em className="tooltip">Available in paid plans</em>);
    }

    if (this.props.data && this.props.data.status === projectsStatuses.ARCHIVED) {
      return (
        <li data-show-node="tooltip">
          <a
            onClick={this.unarchiveProject.bind(this)}
            className={classnames(commonClasses, 'icon-general-unarchive', { disabled: !this.props.allowArchive })}>
          Unarchive project
          </a>
          {tooltip}
        </li>
      );
    }

    return (
      <li data-show-node="tooltip">
        <a
          onClick={this.archiveProject.bind(this)}
          className={classnames(commonClasses, 'icon-general-archive', { disabled: this.props.allowArchive === false })}>
        Archive project
        </a>
        {tooltip}
      </li>
    );
  }

  removeProject() {
    const { actions, onClose } = this.props;

    if (_.isFunction(actions.handleRemoveProject)) {
      actions.handleRemoveProject();
    }

    onClose();
  }

  archiveProject() {
    const { actions, onClose } = this.props;

    if (_.isFunction(actions.handleArchiveProject)) {
      actions.handleArchiveProject();
    }

    onClose();
  }

  onClose(e) {
    const { onClose } = this.props;
    e.preventDefault();
    onClose();
  }

  unarchiveProject() {
    const { actions, onClose } = this.props;

    if (_.isFunction(actions.handleUnarchiveProject)) {
      actions.handleUnarchiveProject();
    }

    onClose();
  }

  shouldComponentUpdate(nextProps) {
    return Object.keys(this.props).some((propName) => !_.isEqual(this.props[propName], nextProps[propName]));
  }

  render() {
    return (
      <Modal
        wrapperId={this.MODAL.WRAPPER_ID}
        classNames={this.MODAL.CLASS_NAMES}
        renderTitle={this.renderTitle.bind(this)}
        renderDescription={this.renderDescription.bind(this)}
        isOpen={this.props.isOpen}
        onClose={this.onClose.bind(this)}>
        <footer>
          <ul className="btns-group">
            <li>
              <DSLink appearance={APPEARANCES.NEUTRAL} href="#cancel" onClick={this.onClose.bind(this)}>Cancel</DSLink>
            </li>
            {this.renderArchiveButton()}
            <li>
              <button
                onClick={this.removeProject.bind(this)}
                className="btn-solid red btn-wide with-icon icon-general-trash">
                Delete project
              </button>
            </li>
          </ul>
        </footer>
      </Modal>
    );
  }
}
