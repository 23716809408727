import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Cloud, GLOBAL_ELEMENTS } from '@uxpin/shared-components';
import { starFilteringTypes } from '../../../../constants/settings.constants';

const StarFilter = (props) => {
  const {
    isOpen,
    cloud,
    trigger,
    changeFilterType,
    filterType,
  } = props;

  const selectedFilteringType = (type) => {
    switch (type) {
      case starFilteringTypes.ALL:
        return <div>All</div>;
      case starFilteringTypes.STARRED:
        return <div>Starred</div>;
      case starFilteringTypes.UNSTARRED:
        return <div>Unstarred</div>;

      // no default
    }
  };

  const getHeadingClass = () => classnames('select-label', GLOBAL_ELEMENTS.HEADING_5);

  const getChevronClass = () => (
    classnames('gray-link icon-general-chevron', { active: isOpen })
  );

  const isSelected = (starType) => starType === filterType;

  const getSelectedClass = (starType) => classnames({ selected: isSelected(starType) });

  const changeFilterTypeOnClick = (e, newFilterType) => changeFilterType(newFilterType);

  return (
    <div className="cloud-wrapper">
      <h5 className={getHeadingClass()}>Filter:</h5>
      <a className={getChevronClass()} href="#filter" {...trigger}>
        {selectedFilteringType(filterType)}
      </a>
      <ul className="cloud options-cloud" {...cloud}>
        <li>
          <a
            className={getSelectedClass(starFilteringTypes.ALL)}
            onClick={(e) => changeFilterTypeOnClick(e, starFilteringTypes.ALL)}
            href="#all">
            All
          </a>
        </li>
        <li>
          <a
            className={getSelectedClass(starFilteringTypes.STARRED)}
            onClick={(e) => changeFilterTypeOnClick(e, starFilteringTypes.STARRED)}
            href="#starred">
            Starred
          </a>
        </li>
        <li>
          <a
            className={getSelectedClass(starFilteringTypes.UNSTARRED)}
            onClick={(e) => changeFilterTypeOnClick(e, starFilteringTypes.UNSTARRED)}
            href="#unstarred">
            Unstarred
          </a>
        </li>
      </ul>
    </div>
  );
};

StarFilter.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  cloud: PropTypes.object.isRequired,
  trigger: PropTypes.object.isRequired,
  changeFilterType: PropTypes.func.isRequired,
  filterType: PropTypes.string.isRequired,
};

export default Cloud()(StarFilter);
