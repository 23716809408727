'use strict';

import { projectSelectorActions } from '../../constants/projectSelector.constants';

export const selectProject = (data) => ({
  type: projectSelectorActions.SELECT_PROJECT,
  data,
});

export const unselectProject = (idProject) => ({
  type: projectSelectorActions.UNSELECT_PROJECT,
  idProject,
});

export const clearSelectedProjects = () => ({
  type: projectSelectorActions.CLEAR_SELECTED_PROJECT,
});
