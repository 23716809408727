'use strict';

import { rolesActions } from '../../constants/roles.constants';
import { request } from '../../utils/request';

export const getRoles = () => (dispatch) => {
  request.get('/roles/')
    .then((data) => {
      dispatch({
        type: rolesActions.FETCH_ROLES,
        data,
      });
      return data;
    }).catch(() => {
      // TODO HANDLE ERROR
    });
};
