import { connect } from 'react-redux';
import ModalboxPrivacyPolicyComponent from '../components/Modalboxes/ModalboxPrivacyPolicy';
import { modalsIds } from '../../constants/modal.constants';
import { closeModal } from '../../controllers/actions/modal.actions';
import { updateSettings } from '../../controllers/actions/settings.actions';
import { settingsName } from '../../constants/settings.constants';

export const mapDispatchToProps = (dispatch) => ({
  onClose: () => {
    dispatch(updateSettings(settingsName.PRIVACY_POLICY_0620_SEEN, 'true'));
    dispatch(closeModal(modalsIds.MODALBOX_PRIVACY_POLICY));
  },
});

export default connect(null, mapDispatchToProps)(ModalboxPrivacyPolicyComponent);
