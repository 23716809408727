import React from 'react';
import PropTypes from 'prop-types';
import autobind from 'autobind-decorator';
import { noop } from 'lodash';
import { PasswordForm } from '../../containers/Onboarding/PasswordForm';

export default class OnboardingPasswordFormComponent extends React.Component {
  @autobind
  onSuccess() {
    const { setNextStep, setOnboardingFlowAsSeen } = this.props;

    setNextStep();
    setOnboardingFlowAsSeen();
  }

  render() {
    return (
      <div className="onboarding-content">
        <h2 className="header">Create a password</h2>
        <PasswordForm
          onSuccess={this.onSuccess}
        />
      </div>
    );
  }
}

OnboardingPasswordFormComponent.propTypes = {
  setNextStep: PropTypes.func,
  setOnboardingFlowAsSeen: PropTypes.func,
};

OnboardingPasswordFormComponent.defaultProps = {
  setNextStep: noop,
  setOnboardingFlowAsSeen: noop,
};
