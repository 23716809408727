import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { LibraryPermissions } from '@uxpin/shared-components';
import * as modal from '../../controllers/actions/modal.actions';
import { modalsIds } from '../../constants/modal.constants';
import {
  actionUpdateLibrary,
  actionDeleteLibrary,
  actionClearEditedLibrary,
  actionShowDesignLibrariesSettingsModal,
  actionChangeEditedLibrary,
} from '../../controllers/actions/designSystems.actions';
import { FEATURE_STATUS } from '../../constants/features.constants';
import { getMixpanel as mixpanel } from '../../../../shared/mixpanel';
import { settingsName } from '../../constants/settings.constants';
import { updateSettings } from '../../controllers/actions/settings.actions';

export const onMergeRequestSubmit = (responseId) => ((d) => {
  d(updateSettings(settingsName.MERGE_FROM_SUBMITTED, true));
  d(updateSettings(settingsName.MERGE_TYPEFORM_RESPONSE, responseId));
});

const MergeLibrarySetting = connect(
  (state) => ({
    accessScope: state.libraryPermissions.accessScope,
    currentUserId: state.currentUser.data.id,
    currentUserEmail: state.currentUser.data.email,
    editScope: state.libraryPermissions.editScope,
    error: state.libraryPermissions.errorLibrary,
    idActiveAccount: state.currentUser.data.idActiveAccount,
    isLibraryDeleting: state.libraryPermissions.isLibraryDeleting,
    isLibraryProcessing: state.libraryPermissions.isLibraryProcessing,
    membersData: state.members.membersData,
    features: state.features,
    className: 'design-library-modalbox',
    isMergeVersionControlHideBranchEnabled: false,
    usePortal: true,
    isMergeEnabled: state.features.features.merge === FEATURE_STATUS.ENABLED,
    isMergeRequestSubmitted: !!state.settings.data[settingsName.MERGE_FROM_SUBMITTED],
    isGitOnlyEnabled: state.features.features.merge === FEATURE_STATUS.ENABLED
      && state.features.features.merge_git_only === FEATURE_STATUS.ENABLED,
    isPackageIntegrationOnlyEnabled: state.features.features.merge === FEATURE_STATUS.ENABLED
      && state.features.features.merge_package_integration_only === FEATURE_STATUS.ENABLED,
    getMixpanel: mixpanel,
  }),

  (dispatch) => ({
    actions: bindActionCreators({
      clearLibraryError: LibraryPermissions.actions.clearLibraryError,
      requestSetViewPermissions: LibraryPermissions.actions.requestSetViewPermissions,
      requestSetEditPermissions: LibraryPermissions.actions.requestSetEditPermissions,
      hideDesignLibrariesAddUpdateModal: () => modal.closeModal(modalsIds.MODALBOX_MERGE_SETTING),
      clearEditedLibrary: actionClearEditedLibrary,
      changeEditedLibrary: actionChangeEditedLibrary,
      showDesignLibrariesSettingsModal: actionShowDesignLibrariesSettingsModal,
      startDeletingLibrary: actionDeleteLibrary,
      updateLibrary: actionUpdateLibrary,
      onMergeRequestSubmit,
    }, dispatch),
  })
)(LibraryPermissions.PanelModalbox);

export default MergeLibrarySetting;
