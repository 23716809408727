/* eslint-disable jsx-a11y/alt-text */

import React from 'react';

// eslint-disable-next-line react/prefer-stateless-function
export default class TabProfile extends React.Component {
  render() {
    return (
      // eslint-disable-next-line react/destructuring-assignment,react/prop-types
      <section className={this.props.classNames} id="your-profile">
        <h3>Your Profile</h3>
        <div className="column">
          <fieldset className="control-wrapper">
            <h4><label>First Name</label></h4>
            <input type="text" />
          </fieldset>
          <fieldset className="control-wrapper">
            <h4><label>Last Name</label></h4>
            <input type="text" />
          </fieldset>
          <fieldset className="control-wrapper">
            <h4><label>Phone Number</label></h4>
            <input type="text" />
          </fieldset>
          <fieldset className="control-wrapper">
            <h4><label>E-mail</label></h4>
            <input type="email" />
          </fieldset>
        </div>
        <div className="column">
          <fieldset className="control-wrapper">
            <h4><label>Time zone</label></h4>
            <select>
              <option>GMT-8, Pacific Time (US & Canada)</option>
              <option>GMT-6</option>
              <option>GMT-4</option>
              <option>GMT</option>
            </select>
          </fieldset>
        </div>
        <div className="column">
          <h4>Avatar</h4>
          <figure className="avatar">
            <img src="" />
            <figcaption>HS</figcaption>
          </figure>
          <div className="links-group">
            <a href="#upload-avatar" className="underline-link gray-link">Upload avatar</a>
            <em>1.5mb max. PNG, JPG - accepted</em>
            <a href="#import-avatar" className="underline-link gray-link">Import from Gravatar</a>
          </div>
        </div>
      </section>
    );
  }
}
