import { connect } from 'react-redux';
import ModalboxSendRequestComponent from '../../../components/ApprovalProcess/modalboxes/ModalboxSendRequest';
import * as stakeholders from '../../../../controllers/actions/stakeholders.actions';

export const mapStateToProps = (state) => {
  const { isRequestBeingModified, isRequestSendingError } = state.stakeholders;
  const { features } = state.features;

  return {
    isRequestBeingModified,
    isRequestSendingError,
    features,
  };
};

export const mapDispatchToProps = (dispatch) => ({
  sendingRequest: () => {
    dispatch(stakeholders.sendingRequest());
  },

  setRequestError: () => {
    dispatch(stakeholders.setRequestError());
  },

  clearRequestError: () => {
    dispatch(stakeholders.clearRequestError());
  },

  sendRequest: (idApproval, idRequest, messageBody) => {
    dispatch(stakeholders.sendRequest(idApproval, idRequest, messageBody));
  },

  restartRequest: (idApproval, idRequest, messageBody, approvalUrl) => {
    dispatch(stakeholders.restartRequest(idApproval, idRequest, messageBody, approvalUrl));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalboxSendRequestComponent);
