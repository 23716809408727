import { connect } from 'react-redux';
import UserTeamComponent from '../components/Topbar/components/UserTeam';

export const mapStateToProps = (state) => {
  const { permissions } = state.permissions;

  return {
    permissions,
  };
};

export const mapDispatchToProps = () => ({});

export const UserTeam = connect(mapStateToProps, mapDispatchToProps)(UserTeamComponent);
