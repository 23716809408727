/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */

import React from 'react';
import PropTypes from 'prop-types';
import { Cloud, cloudPositions } from '@uxpin/shared-components';
import autobind from 'autobind-decorator';
import classNames from 'classnames';
import {
  noop, toNumber, isEmpty, map,
} from 'lodash';
import Tooltip from '../../Helpers/Tooltip';
// eslint-disable-next-line max-len
import { getLibrariesWithDesignSystem, getUserAvailableLibraries } from '../../../../controllers/helpers/designSystems.helper';

export const TOOLTIP_COPY = {
  NO_ITEMS: "You don't have any libraries yet.",
  NO_ITEMS_AVAILABLE: "You don't have any libraries to choose from.",
};

const SELECT_PLACEHOLDER = 'Select library';

export class DesignSystemsLibrariesSelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedName: '',
      selectedElementId: '',
    };
  }

  @autobind
  handleSelect(e) {
    const data = e.target.dataset;
    const selectedName = data.name;
    const selectedElementId = data.id;

    if (!selectedName) {
      return;
    }

    this.setState({
      selectedName,
      selectedElementId,
    });
    this.props.onLibrarySelect(data.hash);
  }

  @autobind
  renderLibraryItemAvailable(library) {
    const { id, hash, name } = library;
    const selectedElementId = toNumber(this.state.selectedElementId);
    const elementClassName = classNames({ selected: selectedElementId === id });

    return (
      <li
        className={elementClassName}
        data-hash={hash}
        data-id={id}
        key={id}
        onClick={this.handleSelect}
        data-name={name}>
        {name}
      </li>
    );
  }

  @autobind
  renderLibraryItemUsed(library) {
    return (<li key={library.id} className="disabled">{library.name}</li>);
  }

  renderLibrariesList(librariesAvailable, librariesUsed) {
    return (
      <ul {...this.props.cloud} className="cloud select-style-list without-arrow scroll-ready options-cloud">
        {map(librariesAvailable, this.renderLibraryItemAvailable)}

        { !isEmpty(librariesUsed)
          && (
          <li className="options-separator">
            <span className="section-name">Already a Design System</span>
          </li>
          )
        }

        {map(librariesUsed, this.renderLibraryItemUsed)}
      </ul>
    );
  }

  render() {
    const { currentUserData, librariesList, isDisabled } = this.props;
    const librariesAvailable = getUserAvailableLibraries(librariesList, currentUserData.id);
    const librariesUsed = getLibrariesWithDesignSystem(librariesList);
    const selectedName = this.state.selectedName || SELECT_PLACEHOLDER;
    const isEmptyList = !librariesList.length || !librariesAvailable.length;
    const wrapperClassNames = classNames('select-wrapper', {
      'empty-list': isEmptyList || isDisabled,
    });

    if (isEmptyList || isDisabled) {
      return (
        <div className={wrapperClassNames}>
          <div className="value-placeholder">{selectedName}</div>
          {isEmptyList
            && (
            <Tooltip className="on-top">
              { !librariesList.length ? TOOLTIP_COPY.NO_ITEMS : TOOLTIP_COPY.NO_ITEMS_AVAILABLE }
            </Tooltip>
            )
          }
        </div>
      );
    }

    return (
      <div className={wrapperClassNames} {...this.props.trigger}>
        {this.renderLibrariesList(librariesAvailable, librariesUsed)}
        <div className="value-placeholder">{selectedName}</div>
      </div>
    );
  }
}

DesignSystemsLibrariesSelect.propTypes = {
  currentUserData: PropTypes.object.isRequired,
  librariesList: PropTypes.array,
  onLibrarySelect: PropTypes.func,
};

DesignSystemsLibrariesSelect.defaultProps = {
  librariesList: [],
  onLibrarySelect: noop,
};

export default Cloud({
  availablePositions: [cloudPositions.TOP, cloudPositions.BOTTOM],
  offset: {
    bottom: { x: 0, y: -50 },
    top: { x: 0, y: 50 },
  },
})(DesignSystemsLibrariesSelect);
