'use strict';

import { connect } from 'react-redux';
import ProjectSettingsComponent from '../components/ProjectsSettings/ProjectsSettings';
import * as projectStatus from '../../controllers/actions/projectStatus.actions';
import { openUpgradeModal } from '../../controllers/actions/upgradeModal.actions';

export const mapStateToProps = (state) => {
  const {
    projectStages, currentStage, currentStatus, isLoading, isError, isSaving, selectedUsers,
  } = state.projectStatus;
  const { membersData, projectMembers } = state.members;
  const { data } = state.currentUser;
  // eslint-disable-next-line max-len
  const { features: { project_status_customization: projectStatusCustomization } } = state.features; // eslint-disable-line camelcase

  return {
    projectStages,
    currentStage,
    currentStatus,
    isLoading,
    projectMembers,
    isSaving,
    isError,
    isCustomizationEnabled: projectStatusCustomization === 'enabled',
    selectedUsers,
    membersData,
    currentUserId: data.id,
  };
};

export const mapDispatchToProps = (dispatch) => ({
  fetchProjectSettings: (idProject) => {
    dispatch(projectStatus.fetchProjectSettings(idProject));
  },

  saveProjectSettings: (idProject, stages, currentStage, currentStatus, notificationData) => {
    dispatch(projectStatus.saveProjectSettings(idProject, stages, currentStage, currentStatus, notificationData));
  },

  openUpgradeModal: () => {
    dispatch(openUpgradeModal());
  },
});

export const ProjectSettings = connect(mapStateToProps, mapDispatchToProps)(ProjectSettingsComponent);
