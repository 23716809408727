'use strict';

import { projectStatusActions } from '../../constants/projectStatus.constants';

export function projectStatusReducer(state = {
  isLoading: false,
  isError: false,
  isSaving: false,
  projectMembers: [],
  projectStages: {
    stages: [],
    statuses: [],
  },
  selectedUsers: [],
  currentStage: {},
  currentStatus: {},
}, action) {
  switch (action.type) {
    case projectStatusActions.REQUEST_FETCHING_PROJECT_SETTINGS:
      return Object.assign({}, state, {
        isLoading: true,
        isError: false,
      });
    case projectStatusActions.REQUEST_SAVING_PROJECT_SETTINGS:
      return Object.assign({}, state, {
        isSaving: true,
      });
    case projectStatusActions.SAVE_PROJECT_SETTINGS:
      return Object.assign({}, state, {
        isSaving: false,
      });
    case projectStatusActions.PROJECT_SETTINGS_ERROR:
      return Object.assign({}, state, {
        isError: true,
        isSaving: false,
      });
    case projectStatusActions.FETCH_PROGRESS:
      return Object.assign({}, state, {
        currentStage: action.progress.stage,
        currentStatus: action.progress.status,
      });
    case projectStatusActions.FETCH_PROJECT_SELECTED_USERS:
      return Object.assign({}, state, {
        selectedUsers: action.selectedUsers,
      });
    case projectStatusActions.FETCH_PROJECT_SETTINGS:
      return Object.assign({}, state, {
        isLoading: false,
        projectStages: action.projectStages,
      });
    case projectStatusActions.FETCH_PROJECT_MEMBERS:
      return Object.assign({}, state, {
        projectMembers: action.projectMembers,
      });
    default:
      return state;
  }
}
