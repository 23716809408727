/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
/* global XMLHttpRequest, navigator */

'use strict';

import md5 from 'md5';
import { request } from './request';
import { FEATURE_STATUS } from '../constants/features.constants';
import { getMixpanel } from '../../../shared/mixpanel';

const EXTENSION_UXPIN_PROJECT = 'uxp';
const EXTENSION_PLUGIN_EXPORT = 'uxpin';
const EXTENSION_SKETCH = 'sketch';
const EXTENSION_PSD = 'psd';
export const BASIC_FILE_TYPES = 'PNG, JPG, PDF, UXP';

const createUploadHash = () => md5(navigator.userAgent + new Date().getTime() + Math.floor((Math.random() * 10000) + 1));

const getProgress = (loadedData, totalSize) => {
  let loaded = 0;
  for (const i in loadedData) {
    // eslint-disable-next-line no-prototype-builtins
    if (loadedData.hasOwnProperty(i)) {
      loaded += loadedData[i];
    }
  }

  return Math.round((loaded / totalSize) * 100);
};

const getTotalSize = (files) => {
  let total = 0;
  files.forEach((file) => {
    total += file.size;
  });
  return total;
};

// eslint-disable-next-line no-confusing-arrow
const standarizeExtension = (extension) => typeof extension === 'string' ? extension.toLowerCase() : '';

export const getStandarizedExtension = (fileName) => standarizeExtension(fileName.split('.').pop());

export const isUXPinFile = (file) => getStandarizedExtension(file.name) === EXTENSION_UXPIN_PROJECT;
export const isUXPinPluginFile = (file) => getStandarizedExtension(file.name) === EXTENSION_PLUGIN_EXPORT;

const getFileTypeByExtension = (extension) => ({
  [EXTENSION_PLUGIN_EXPORT]: 'application/octet-stream',
  [EXTENSION_SKETCH]: 'application/octet-stream',
  [EXTENSION_UXPIN_PROJECT]: 'application/octet-stream',
}[standarizeExtension(extension)]);

const getFileType = (file) => {
  if (file.type) {
    return file.type;
  }

  return getFileTypeByExtension(getStandarizedExtension(file.name));
};

const getUploadRequestBody = (files) => files.map((file, index) => ({
  fileHash: createUploadHash(),
  fileName: file.name,
  fileType: getFileType(file),
  fileIndex: index,
}));

const sendToS3 = ({
  url, fileData, file, index, onProgress, loadedData, totalSize,
}) => (
  new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open('PUT', url);

    xhr.onload = () => {
      if (xhr.status === 200) {
        resolve(fileData);
      }
    };

    xhr.onerror = (e) => {
      reject(e);
    };

    xhr.upload.onprogress = (e) => {
      loadedData[index] = e.loaded;
      onProgress(getProgress(loadedData, totalSize));
    };

    xhr.setRequestHeader('Content-Type', getFileType(file));
    xhr.setRequestHeader('x-amz-acl', 'public-read');
    xhr.send(file);
  })
);

// This type is set on EXE files but only on FF/Safari
export const OCTET_STREAM = 'application/octet-stream';

export const acceptedFileTypes = [
  'image/jpeg',
  'image/png',
  'application/pdf',
  OCTET_STREAM,
  'image/vnd.adobe.photoshop',
  '',
];

export const allowedOctetFileExtensions = [
  EXTENSION_PLUGIN_EXPORT,
  EXTENSION_SKETCH,
  EXTENSION_UXPIN_PROJECT,
];

export const acceptedFileSize = 367001600; // 350mb (350 * 1024 * 1024)

export const isPsd = (file) => file.type === 'image/vnd.adobe.photoshop' || getStandarizedExtension(file.name) === EXTENSION_PSD;

export const isSketchFile = (file) => getStandarizedExtension(file.name) === EXTENSION_SKETCH;

export const isSketchDirectUploadAllowed = (sketchDirectUploadFeature) => sketchDirectUploadFeature === FEATURE_STATUS.ENABLED;

const getMixpanelDocumentCreatedType = (body) => {
  let numberOfSketchFiles = 0;
  let numberOfOtherFiles = 0;

  body.forEach((file) => {
    if (isSketchFile({ name: file.fileName })) {
      numberOfSketchFiles += 1;
    } else {
      numberOfOtherFiles += 1;
    }
  });

  if (numberOfSketchFiles === 0) {
    return 'Import file';
  }

  if (numberOfOtherFiles === 0) {
    return 'Import Sketch';
  }

  return 'Import Sketch and file';
};

export const uploadStaticFiles = (files, onProgress) => {
  if (!files.length) {
    return;
  }

  const body = getUploadRequestBody(files);

  const documentCreatedType = getMixpanelDocumentCreatedType(body);
  getMixpanel((mixpanel) => {
    mixpanel.track('prototype_created', {
      document_created_type: documentCreatedType,
      prototype_created_library_chosen: 'None',
    });
    mixpanel.people.increment('number_of_prototypes_created');
  });

  return request.post('/upload/static', {
    body,
  }).then((filesData) => {
    const promises = [];
    const totalSize = getTotalSize(files);
    const loadedData = {};
    filesData.forEach((fileData, i) => {
      const data = {
        fileKey: fileData.fileKey,
        fileHash: body[i].fileHash,
        fileName: fileData.fileName,
      };

      promises.push(sendToS3({
        file: files[i],
        fileData: data,
        index: i,
        loadedData,
        onProgress,
        totalSize,
        url: decodeURIComponent(fileData.fileUrl),
      }));
    });
    return Promise.all(promises);
  });
};
