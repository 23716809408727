/* eslint-disable max-len */
/* eslint-disable react/destructuring-assignment */

'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import autobind from 'autobind-decorator';
import classnames from 'classnames';
import { noop, mapValues } from 'lodash';
import { GLOBAL_ELEMENTS } from '@uxpin/shared-components';
import { isTeamSizeValid, isFormValid } from './helpers/AccountSetupFormHelpers';
import { preventDefaultEvent } from '../../../../utils/common';

const teamSizeRadioButtons = [
  {
    name: 'small',
    label: '1-3',
  },
  {
    name: 'medium',
    label: '4-6',
  },
  {
    name: 'large',
    label: '7-9',
  },
  {
    name: 'larger',
    label: '10+',
  },
];

const TEAM_SIZE = 'teamSize';


export default class AccountSetupFormComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      processing: false,
      formSubmitted: false,
      formValid: false,
      formField: {
        [TEAM_SIZE]: '',
      },
      fieldValid: {
        [TEAM_SIZE]: true,
      },
    };
  }

  @autobind
  handleSuccess(formData) {
    const { savePartialLeadForm, onSubmit } = this.props;

    this.setState({
      processing: true,
    });

    savePartialLeadForm(formData)
      .then(() => {
        onSubmit();
      });
  }

  getCurrentFormValues() {
    return mapValues(this.customForm.fields, (field) => field.state.value);
  }

  @autobind
  validateForm() {
    const { teamSize } = this.state.formField;

    const fieldValid = {
      [TEAM_SIZE]: isTeamSizeValid(teamSize),
    };

    const formValid = isFormValid(fieldValid);

    this.setState({
      fieldValid,
      formValid,
    });

    return formValid;
  }

  @autobind
  handleSubmit(e) {
    preventDefaultEvent(e);

    const formValid = this.validateForm();

    this.setState({
      formValid,
      formSubmitted: true,
    });

    if (formValid) {
      this.handleSuccess(this.state.formField);
    }
  }

  @autobind
  handleFieldChange(event) {
    const { name, value } = event.target;
    const { formField } = this.state;
    formField[name] = value;

    this.setState({ formField }, () => {
      if (name === TEAM_SIZE) {
        this.validateForm();
      }
    });
  }

  @autobind
  renderRadioButtons(teamSizeInvalid) {
    const className = classnames('fake-choose-label', 'fake-radio', {
      error: teamSizeInvalid,
    });

    return teamSizeRadioButtons.map((button) => (
      <div key={`team-size-${button.name}`} className="team-size-radio">
        <input type="radio" name={TEAM_SIZE} id={`team-size-${button.name}`} className="state-helper" value={button.label} onChange={this.handleFieldChange} />
        <label htmlFor={`team-size-${button.name}`} className={className}>{button.label}</label>
      </div>
    ));
  }

  @autobind
  renderTeamSizeField() {
    const { formSubmitted, fieldValid } = this.state;
    const teamSizeInvalid = formSubmitted && !fieldValid[TEAM_SIZE];

    return (
      <fieldset className={classnames('full-width', { error: teamSizeInvalid })}>
        <h4 className={GLOBAL_ELEMENTS.HEADING_4}>
          <label
            className={GLOBAL_ELEMENTS.LABEL}
            htmlFor={`team-size-${teamSizeRadioButtons[0].name}`}>
            What size is your design team?
          </label>
        </h4>
        <div className="team-size-wrapper">
          {this.renderRadioButtons(teamSizeInvalid)}
        </div>
        {teamSizeInvalid
          && <span className="error-message">Please select your team size.</span>
        }
      </fieldset>
    );
  }

  @autobind
  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        {this.renderTeamSizeField()}

        <ul className="btns-group">
          <li>
            <button
              type="submit"
              className={classnames('btn-solid', 'btn-wide', 'blue', { inactive: !this.state.formValid, working: this.state.processing })}>
              Start your journey!
            </button>
          </li>
        </ul>
      </form>
    );
  }
}

AccountSetupFormComponent.propTypes = {
  onSubmit: PropTypes.func,
  savePartialLeadForm: PropTypes.func,
};

AccountSetupFormComponent.defaultProps = {
  onSubmit: noop,
  savePartialLeadForm: noop,
};
