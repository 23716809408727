import { connect } from 'react-redux';
import TrialInfoComponent from '../components/Trial/TrialInfo';
import { getAccountStatus, isTrialPlanType } from '../../utils/account';
import { openPricing } from '../../controllers/actions/pricing.actions';

export const mapStateToProps = (state) => {
  const { accountPlan, extendTrialFormResponse } = state.accountSettings;
  const { data } = state.currentUser;
  const { features } = state.features;

  return {
    accountPlan,
    isTrialPlan: !!(accountPlan && isTrialPlanType(accountPlan)),
    accountStatus: getAccountStatus(data.idActiveAccount, data.accounts),
    extendTrialFormResponse,
    features,
  };
};

export const mapDispatchToProps = (dispatch) => ({
  openUpgradeModal: () => {
    dispatch(openPricing());
  },
});

export const TrialInfo = connect(mapStateToProps, mapDispatchToProps)(TrialInfoComponent);
