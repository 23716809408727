import React from 'react';
import { PricingModal } from '@uxpin/shared-components';
import { IntercomAPI } from 'react-intercom';

export default function InactiveAccount() {
  return (
    <PricingModal
      isOpen
      isInactiveAccount
      intercom={IntercomAPI}
    />
  );
}
