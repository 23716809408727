import React from 'react';
import classnames from 'classnames';
import { OnboardingWidget as Widget } from '@uxpin/shared-components';
import PropTypes from 'prop-types';
import { sendToAutopilotProxy } from '../../../utils/marketing';

const MERGE_TUTORIAL_URL = 'https://www.uxpin.com/studio/blog/how-to-design-with-coded-components/';
const MERGE_ONBOARDING_TUTORIAL_CLICKED = 'merge - onboarding tutorial clicked';

const libraryContent = [
  /* eslint-disable max-len */
  {
    header: '👀 Tutorial: simple layout with MUI',
    content: (
      <div>
        <figure className="image-wrapper">
          <img
            src="https://uxpin.s3.amazonaws.com/app/images/onboarding/widget/560x400_Merge_+MUIv5_popup.gif"
            alt="how to create a library"
            height="200px"
          />
        </figure>
        <p className="text">
          How to design with <strong>coded components</strong> and build a dashboard in minutes?
          Follow our <strong>step-by-step walkthrough</strong> and use our built-in <strong>MUI</strong> library.🧩
        </p>
        <p className="text">
          Go to the <strong>tutorial article</strong> and build a <strong>dashboard prototype</strong> with no hassle.
        </p>
      </div>
    ),
  },
];

const hideButtons = {
  first: true,
};

export class MergeOnboardingWidget extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      assetsPanelOpen: false,
      content: libraryContent,
    };
  }

  componentDidMount() {
    this.initWidget();
  }

  initWidget = () => {
    const {
      isMergeOnBoardingEnabled,
    } = this.props;

    this.setState({ visible: isMergeOnBoardingEnabled });
  };

  closeWidget = () => {
    this.setState({ visible: false });
  };

  handleOnClickPrimary = () => {
    const { userId } = this.props;

    sendToAutopilotProxy(userId, MERGE_ONBOARDING_TUTORIAL_CLICKED);
    window.open(MERGE_TUTORIAL_URL, '_blank');
  }


  render() {
    const { assetsPanelOpen, visible, content } = this.state;
    const { projectListLoadCompleted } = this.props;

    const classes = classnames('editor-widget--storybook', {
      'is-editor-assets-open': assetsPanelOpen,
    });

    if (projectListLoadCompleted && visible) {
      return (
        <Widget
          additionalClasses={classes}
          content={content}
          hideButtons={hideButtons}
          hideSlideCounter
          slidesOffset={0}
          onClickPrimary={() => this.handleOnClickPrimary()}
          onClickSecondary={() => this.closeWidget()}
          hideNavigationButtons
          closeLabel="Skip"
          linkLabel="See tutorial"
        />
      );
    }
    return null;
  }
}
MergeOnboardingWidget.propTypes = {
  isMergeOnBoardingEnabled: PropTypes.bool,
  projectListLoadCompleted: PropTypes.bool,
  userId: PropTypes.number.isRequired,
};

MergeOnboardingWidget.defaultProps = {
  isMergeOnBoardingEnabled: false,
  projectListLoadCompleted: false,
};
