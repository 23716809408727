/* eslint-disable max-len */
/* eslint-disable no-plusplus */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */

'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import autobind from 'autobind-decorator';
import _ from 'lodash';
import { DropTarget } from 'react-dnd';
import { ProjectsGroup } from '../../containers/ProjectsGroup';
import { projectsConstants } from '../../../constants/projects.constants';
import { sortItems } from '../../../utils/projectsSorting';
import CurrentGroup from './components/CurrentGroup';
import { sortableItemsTypes } from '../../../constants/sortableItems.constants';
import { getBoxTarget } from '../../../utils/dndScrollFix';

export class ProjectsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalboxGeneralBtnAction: _.noop,
      visibleProjects: [],
      groupsExpandedMode: {},
      isInlineEditing: false,
      currentGroupIndex: 0,
      currentGroupPosition: false,
    };

    this.MAX_DISTANCE_TO_NEXT_ELEMENT = 22;

    this.CLASS = {
      LOADING: 'loading',
      EDITING: 'editing',
    };

    this.allProjects = [];
    this.totalPages = 0;
  }

  updateGroupsExpandedMode(nextProps) {
    const groupsExpandedMode = Object.assign({}, nextProps.groupsExpandedMode);
    this.setState({
      groupsExpandedMode,
    });
  }

  updateProjectsList(props) {
    this.allProjects = props.items.slice(0);
    this.allProjects = this.sortProjects(this.allProjects, props.sortType);
    const data = this.getVisibleProjects(this.allProjects, props.page);
    this.setState({
      visibleProjects: data.visibleProjects,
    });
    this.totalPages = data.totalPages;
  }

  componentWillMount() {
    this.updateGroupsExpandedMode(this.props);
    this.updateProjectsList(this.props);
  }

  findElWithClass(el, cls) {
    while (el && !el.classList.contains(cls)) {
      // eslint-disable-next-line no-param-reassign
      el = el.parentElement;
    }

    return el;
  }

  @autobind
  handleDocumentMouseDown(e) {
    if (!this.findElWithClass(e.target, 'project-box')) {
      this.props.clearSelectedProjects();
    }
  }

  componentDidMount() {
    this.projectsGroupNodes = this.node.querySelectorAll('.projects-group');
    document.addEventListener('mousedown', this.handleDocumentMouseDown);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleDocumentMouseDown);
  }

  componentDidUpdate() {
    this.projectsGroupNodes = this.node.querySelectorAll('.projects-group');
    this.maxScrollPosition = this.node.scrollHeight - this.node.offsetHeight;
  }

  getCurrentGroupData(scrollPosition) {
    let index = 0;
    let nextElement;
    let distanceToNextElement = 0;
    for (let i = 0; i < this.projectsGroupNodes.length; i++) {
      if (this.projectsGroupNodes[i].offsetTop - scrollPosition < 0) {
        index = i;
      }
    }

    // eslint-disable-next-line prefer-const
    nextElement = this.projectsGroupNodes[index + 1];
    if (nextElement) {
      distanceToNextElement = nextElement.offsetTop - scrollPosition;
    }

    return {
      distanceToNextElement,
      index,
    };
  }

  getLastVisibleGroupIndex(nodeScrollTop) {
    let index = 0;
    for (let i = 0; i < this.projectsGroupNodes.length; i++) {
      if (nodeScrollTop > this.projectsGroupNodes[i].offsetTop) {
        index = i;
      }
    }

    return index;
  }

  componentWillReceiveProps(nextProps) {
    if (!_.isEqual(this.props.items, nextProps.items) || this.props.sortType !== nextProps.sortType || this.props.page !== nextProps.page) {
      this.updateProjectsList(nextProps);
    }

    if (this.props.groupsExpandedMode !== nextProps.groupsExpandedMode) {
      this.updateGroupsExpandedMode(nextProps);
    }
  }

  sortProjects(groups, sortType) {
    return groups.map((group) => {
      const newGroup = Object.assign({}, group);
      const projects = newGroup.projects.slice(0);
      sortItems(projects, sortType);
      newGroup.projects = projects;
      return newGroup;
    });
  }

  getProjectsCount(data, index) {
    let projectsCount = 0;
    if (_.isEmpty(data)) {
      return 0;
    }

    data.forEach((projectGroup, id) => {
      if (index !== undefined && id <= index) {
        projectsCount += projectGroup.projects.length;
      } else if (index === undefined) {
        projectsCount += projectGroup.projects.length;
      }
    });

    return projectsCount;
  }

  getVisibleProjects(data, page) {
    let visibleProjectCount = 0;
    const visibleProjects = [];
    const projectToShowCount = page * projectsConstants.PROJECTS_ON_PAGE;
    const allProjectsCount = this.getProjectsCount(data);
    const totalPages = Math.ceil(allProjectsCount / projectsConstants.PROJECTS_ON_PAGE);

    if (projectToShowCount > allProjectsCount) {
      return {
        visibleProjects: data,
        totalPages,
      };
    }

    for (let i = 0; i < data.length; i++) {
      visibleProjects.push(Object.assign({}, data[i]));
      if (visibleProjectCount < projectToShowCount) {
        const projects = visibleProjects[i].projects.slice(0, projectToShowCount - visibleProjectCount);
        visibleProjects[i].projects = projects;
        visibleProjectCount += projects.length;
      } else if (visibleProjectCount === projectToShowCount) {
        visibleProjects[i].projects = [];
      }
    }

    return {
      visibleProjects,
      totalPages,
    };
  }

  @autobind
  handleInlineEditFocus(isInlineEditing) {
    this.setState({
      isInlineEditing,
    });
  }

  getProjectsListClasses() {
    const { isLoading, refreshedLayout } = this.props;
    const classes = [];

    if (isLoading) {
      classes.push(this.CLASS.LOADING);
    }

    if (this.state.isInlineEditing) {
      classes.push(this.CLASS.EDITING);
    }

    if (refreshedLayout) {
      classes.push('left-labels');
    }
    return classes.join(' ');
  }

  setCurrentGroupState(nodeScrollTop) {
    const currentGroupData = this.getCurrentGroupData(nodeScrollTop);
    const currentGroupIndex = currentGroupData.index;

    if (currentGroupIndex !== this.state.currentGroupIndex) {
      this.setState({
        currentGroupIndex,
      });
    }

    if (currentGroupData.distanceToNextElement < this.MAX_DISTANCE_TO_NEXT_ELEMENT) {
      this.setState({
        currentGroupPosition: this.MAX_DISTANCE_TO_NEXT_ELEMENT - currentGroupData.distanceToNextElement,
      });
    } else if (this.state.currentGroupPosition !== false) {
      this.setState({
        currentGroupPosition: false,
      });
    }
  }

  onScroll() {
    const { getNextProjects, page } = this.props;
    const nodeScrollTop = this.node.scrollTop;
    const index = this.getLastVisibleGroupIndex(nodeScrollTop + this.node.offsetHeight);
    const projectCount = this.getProjectsCount(this.allProjects, index);
    const visibleProjectsCount = page * projectsConstants.PROJECTS_ON_PAGE + projectsConstants.PROJECTS_ON_PAGE;
    let currentPage;
    if (visibleProjectsCount < projectCount) {
      currentPage = Math.ceil(visibleProjectsCount / projectsConstants.PROJECTS_ON_PAGE);
    } else {
      currentPage = Math.ceil(this.getProjectsCount(this.allProjects, index) / projectsConstants.PROJECTS_ON_PAGE);
    }

    if (nodeScrollTop > (this.node.scrollHeight - this.node.offsetHeight) * 0.5 && currentPage > page && page < this.totalPages) {
      getNextProjects(currentPage - page > 1 ? page + 1 : currentPage);
    }

    this.setCurrentGroupState(nodeScrollTop);
  }

  @autobind
  onCollapsing() {
    const { getNextProjects } = this.props;
    const index = this.getLastVisibleGroupIndex(this.node.scrollTop + this.node.offsetHeight);
    let visibleProjectsCount;
    if (index > 0) {
      visibleProjectsCount = this.getProjectsCount(this.allProjects, index - 1) + projectsConstants.PROJECTS_ON_PAGE;
    } else {
      visibleProjectsCount = projectsConstants.PROJECTS_ON_PAGE;
    }

    const currentPage = Math.ceil((visibleProjectsCount + projectsConstants.PROJECTS_ON_PAGE) / projectsConstants.PROJECTS_ON_PAGE);
    getNextProjects(currentPage);
    this.setCurrentGroupState(this.node.scrollTop);
  }

  @autobind
  onExpanding(id) {
    const { getNextProjects } = this.props;
    let visibleProjectsCount;

    if (this.allProjects[id].projects.length >= projectsConstants.PROJECTS_ON_PAGE) {
      if (id > 0) {
        visibleProjectsCount = this.getProjectsCount(this.allProjects, id - 1) + projectsConstants.PROJECTS_ON_PAGE;
      } else {
        visibleProjectsCount = projectsConstants.PROJECTS_ON_PAGE;
      }
    } else {
      const index = this.getLastVisibleGroupIndex(this.node.scrollTop + this.node.offsetHeight);
      if (index > 0) {
        visibleProjectsCount = this.getProjectsCount(this.allProjects, index - 1) + projectsConstants.PROJECTS_ON_PAGE;
      } else {
        visibleProjectsCount = projectsConstants.PROJECTS_ON_PAGE;
      }
    }

    const currentPage = Math.ceil(visibleProjectsCount / projectsConstants.PROJECTS_ON_PAGE);
    getNextProjects(currentPage);
    this.setCurrentGroupState(this.node.scrollTop);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return Object.keys(this.props).some((propName) => !_.isEqual(this.props[propName], nextProps[propName]))
      || Object.keys(this.state).some((propName) => !_.isEqual(this.state[propName], nextState[propName]));
  }

  @autobind
  saveProjectsGroupsExpandedMode(id, state, onComplete = _.noop) {
    const { saveProjectsGroupsExpandedMode } = this.props;

    // eslint-disable-next-line react/no-access-state-in-setstate
    const groups = Object.assign({}, this.state.groupsExpandedMode);
    groups[id] = state;

    this.setState({
      groupsExpandedMode: groups,
    }, () => {
      saveProjectsGroupsExpandedMode(this.state.groupsExpandedMode);
      onComplete();
    });
  }

  renderProjectsGroups() {
    const { groupsExpandedMode } = this.state;

    return this.state.visibleProjects.map((projectGroup, id) => {
      let isExpanded = true;

      if (!_.isUndefined(groupsExpandedMode[projectGroup.id])) {
        isExpanded = groupsExpandedMode[projectGroup.id];
      } else if (!_.isUndefined(groupsExpandedMode[projectGroup.type])) {
        isExpanded = groupsExpandedMode[projectGroup.type];
      }

      return (
        <ProjectsGroup
          // eslint-disable-next-line react/no-array-index-key
          key={id}
          id={id}
          projectGroupId={projectGroup.id}
          type={projectGroup.type}
          name={projectGroup.name}
          color={projectGroup.color}
          projects={projectGroup.projects}
          allProjects={this.allProjects}
          onCollapsing={this.onCollapsing}
          onExpanding={this.onExpanding}
          saveProjectsGroupsExpandedMode={this.saveProjectsGroupsExpandedMode}
          openModalboxAddUser={this.props.openModalboxAddUser}
          isExpanded={isExpanded}
          handleInlineEditFocus={this.handleInlineEditFocus}
          refreshedLayout={this.props.refreshedLayout}
        />
      );
    });
  }

  renderFixedGroup() {
    const { refreshedLayout } = this.props;
    const fixedGroup = this.state.visibleProjects[this.state.currentGroupIndex];

    const transformValue = (this.state.currentGroupPosition ? `translate(0, ${-this.state.currentGroupPosition}px)` : undefined);
    const styles = {
      transform: transformValue,
      WebkitTransition: transformValue,
    };

    if (fixedGroup && !refreshedLayout) {
      return (
        <CurrentGroup
          groupsExpandedMode={this.state.groupsExpandedMode}
          style={styles}
          saveProjectsGroupsExpandedMode={this.saveProjectsGroupsExpandedMode}
          onCollapsing={this.onCollapsing}
          onExpanding={this.onExpanding}
          projectGroupId={fixedGroup.id || fixedGroup.type}
          name={fixedGroup.name}
          currentGroupIndex={this.state.currentGroupIndex}
          id={`group-${this.state.currentGroupIndex}`}
        />
      );
    }
  }

  render() {
    return this.props.connectDropTarget(
      <section
        onScroll={() => this.onScroll()}
        ref={(node) => { this.node = node; }}
        id="projects-list"
        className={this.getProjectsListClasses()}>
        {this.renderFixedGroup()}
        <div ref="wrapper" className="list-wrapper">
          {this.renderProjectsGroups()}
        </div>
      </section>
    );
  }
}

export default DropTarget(sortableItemsTypes.PROJECT, getBoxTarget(), (connect) => ({
  connectDropTarget: connect.dropTarget(),
}))(ProjectsList);

ProjectsList.propTypes = {
  getNextProjects: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
};
