import { connect } from 'react-redux';
import ModalboxDocumentChangeNameComponent from '../components/Modalboxes/ModalboxDocumentChangeName';
import { modalsIds } from '../../constants/modal.constants';
import * as modal from '../../controllers/actions/modal.actions';
import * as documents from '../../controllers/actions/document.actions';

export const mapStateToProps = (state) => {
  const { data: modalData, openedModalsIds } = state.modal;

  return {
    isOpen: openedModalsIds[modalsIds.MODALBOX_DOCUMENT_CHANGE_NAME] || false,
    data: modalData[modalsIds.MODALBOX_DOCUMENT_CHANGE_NAME] || {},
  };
};

export const mapDispatchToProps = (dispatch) => ({
  renameDocument: (idDocument, name) => {
    dispatch(documents.renameDocument(idDocument, name));
  },

  updateDocumentName: (idDocument, name) => {
    dispatch(documents.updateDocumentName(idDocument, name));
  },

  onClose: () => {
    dispatch(modal.closeModal(modalsIds.MODALBOX_DOCUMENT_CHANGE_NAME));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalboxDocumentChangeNameComponent);
