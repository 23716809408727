/* eslint-disable max-len */
/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable camelcase */

'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import { Cloud, cloudPositions } from '@uxpin/shared-components';
import autobind from 'autobind-decorator';
import classnames from 'classnames';
import config from 'react-global-configuration';
import _ from 'lodash';
import { PlanInfo } from '../../../containers/PlanInfo';
import { documentsTypes as DOCUMENT_TYPES } from '../../../../constants/documents.constants';
import { FEATURES, FEATURE_STATUS } from '../../../../constants/features.constants';
import { preventDefaultEvent } from '../../../../utils/common';
import { isDesignLimitReached } from '../../../../utils/designLimit';

export class ProjectBoxActionsCloud extends React.Component {
  getCloudClasses() {
    return classnames('cloud', 'options-cloud', 'with-icons', 'inside-project-actions-cloud');
  }

  @autobind
  duplicateDocument(e) {
    const { duplicateDocument } = this.props;
    preventDefaultEvent(e);
    duplicateDocument();
  }

  @autobind
  showPrototypesLimitModal(e) {
    const { showAddPrototypesLimitsModal } = this.props;
    preventDefaultEvent(e);
    showAddPrototypesLimitsModal();
  }

  renderMoveProjectAction() {
    const { document, isAccountParked, hasAccessToEditor } = this.props;

    if (document.isChromeExtensionUpload || isAccountParked || !hasAccessToEditor) { return null; }

    if (this.props.projectsList.length > 1) {
      return <li><a href="#move-to-project" className="icon-general-change-group" onClick={this.props.openModalboxDocumentChangeProject}>Move to project</a></li>;
    }
  }

  getLivePresentationUrl() {
    const { document } = this.props;
    return `${config.get('PREVIEW_URL')}/${document.hash}#/pages/${document.idMainPage}/live-presentation-configuration`;
  }

  getUsabilityTestingUrl() {
    const { document } = this.props;
    return `${config.get('PREVIEW_URL')}/${document.hash}#/pages/${document.idMainPage}/usability-test/1`;
  }

  getIterationsUrl() {
    const { APP_URL, EDITOR_NEW_ENGINE_URL } = config.get();
    const { document, features } = this.props;

    return `${features && features.uxpin30_engine === 'enabled' ? EDITOR_NEW_ENGINE_URL : APP_URL}/edit/${document.idDocument}/#iterations`;
  }

  renderLivePresentationLink() {
    const { features, document, isAccountParked } = this.props;

    if (document.isChromeExtensionUpload || isAccountParked || document.type === DOCUMENT_TYPES.DOCUMENT) {
      return null;
    }

    if (features.live_share === 'enabled') {
      return (
        <li>
          <a
            data-url-browser="true"
            href={this.getLivePresentationUrl()}
            className="icon-general-live-presentation">
            Live Presentation
          </a>
        </li>
      );
    }

    return (
      <li>
        <span className="icon-general-live-presentation disabled">
          <span>Live Presentation</span>
          <PlanInfo label="N/A" onlyLabel />
        </span>
      </li>
    );
  }

  renderDeleteAction() {
    const { document, hasAccessToEditor } = this.props;
    if (document.isChromeExtensionUpload || !hasAccessToEditor) { return null; }

    return (
      <li className="separator">
        <a
          href="#delete"
          className="icon-general-trash danger custom-color"
          onClick={this.props.openModalboxDocumentDelete}>
          Delete
        </a>
      </li>
    );
  }

  renderDuplicateAction() {
    const {
      document, isAccountParked, hasAccessToEditor, totalDocumentsCount: docsCount,
    } = this.props;
    const limitReached = docsCount && isDesignLimitReached(docsCount.in_plan, docsCount.used);
    const clickAction = limitReached ? this.showPrototypesLimitModal : this.duplicateDocument;

    if (document.isChromeExtensionUpload || isAccountParked || !hasAccessToEditor) { return null; }

    return (<li><a onClick={clickAction} href="#duplicate" className="icon-general-copy">Duplicate</a></li>);
  }

  renderIterationsLink() {
    const {
      document, isAccountParked, hasAccessToEditor, features,
    } = this.props;
    const featureEnabled = features[FEATURES.ITERATIONS] === FEATURE_STATUS.ENABLED;
    const isDocument = document.type === DOCUMENT_TYPES.DOCUMENT;

    if (!featureEnabled || document.isChromeExtensionUpload || isAccountParked || !hasAccessToEditor || isDocument) {
      return null;
    }

    return (<li><a href={this.getIterationsUrl()} className="icon-general-iterations">Iterations (History)</a></li>);
  }

  renderRenameAction() {
    const { isAccountParked, document, hasAccessToEditor } = this.props;

    if (!hasAccessToEditor) {
      return;
    }

    if (isAccountParked || document.isChromeExtensionUpload || document.type === DOCUMENT_TYPES.DOCUMENT) {
      return (
        <li>
          <a href="#change-name" className="icon-general-pencil" onClick={this.props.openModalboxDocumentChangeName}>Change name</a>
        </li>
      );
    }

    return (
      <li className="separator">
        <a href="#change-name" className="icon-general-pencil" onClick={this.props.openModalboxDocumentChangeName}>Change name</a>
      </li>
    );
  }

  renderUsabilityTestingLink() {
    const { features, document, isAccountParked } = this.props;

    if (document.isChromeExtensionUpload || isAccountParked || features.user_testing !== 'enabled' || document.type === DOCUMENT_TYPES.DOCUMENT) {
      return null;
    }

    return (
      <li>
        <a
          data-url-browser="true"
          href={this.getUsabilityTestingUrl()}
          className="icon-general-usability-testing">
          Usability Testing
        </a>
      </li>
    );

    // TODO: remove when we're sure that we don't need it
    /* return (
      <li>
        <span className="icon-general-usability-testing disabled">
          <span>Usability Testing</span>
          <PlanInfoPresenter>
            <PlanInfo label={Plans.plans.TEAM} onlyLabel>
              Usability Testing is available in Team Plan.
            </PlanInfo>
            <PlanInfo label={'N/A'} onlyLabel for={Plans.planTypes.DESIGN_VIEWS}>
              Usability Testing is not available in this plan.
            </PlanInfo>
          </PlanInfoPresenter>

        </span>
      </li>
    ); */
  }

  render() {
    return (
      <div {...this.props.trigger}>
        <a className="icon-general-dots only-icon-font">More</a>
        <ul {...this.props.cloud} className={this.getCloudClasses()}>
          {this.renderLivePresentationLink()}
          {this.renderUsabilityTestingLink()}
          {this.renderIterationsLink()}
          {this.renderRenameAction()}
          {this.renderMoveProjectAction()}
          {this.renderDuplicateAction()}
          {this.renderDeleteAction()}
        </ul>
      </div>
    );
  }
}

ProjectBoxActionsCloud.propTypes = {
  duplicateDocument: PropTypes.func.isRequired,
  document: PropTypes.object.isRequired,
  features: PropTypes.object.isRequired,
  openModalboxDocumentChangeName: PropTypes.func.isRequired,
  openModalboxDocumentDelete: PropTypes.func.isRequired,
  isFreePlan: PropTypes.bool.isRequired,
};

ProjectBoxActionsCloud.defaultProps = {
  duplicateDocument: _.noop,
  document: {},
  features: {},
  openModalboxDocumentChangeName: _.noop,
};

export default Cloud({
  availablePositions: [cloudPositions.RIGHT_TOP, cloudPositions.LEFT_TOP, cloudPositions.TOP, cloudPositions.BOTTOM],
  offset: {
    bottom: { x: 0, y: 5 },
    top: { x: 0, y: -5 },
    rightTop: { x: 25, y: 12 },
    leftTop: { x: -5, y: 12 },
  },
})(ProjectBoxActionsCloud);
