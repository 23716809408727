import { connect } from 'react-redux';
import StakeholderActionsComponent from '../../../components/ApprovalProcess/components/StakeholderActions';
import * as stakeholders from '../../../../controllers/actions/stakeholders.actions';

export const mapStateToProps = () => ({});

export const mapDispatchToProps = (dispatch) => ({
  updateStakeholder: (idApproval, id, data) => {
    dispatch(stakeholders.updateStakeholder(idApproval, id, data));
  },

  startRemovingStakeholder: (idApproval, id) => {
    dispatch(stakeholders.startRemovingStakeholder(idApproval, id));
  },

  restoreStakeholder: (idApproval, id) => {
    dispatch(stakeholders.restoreStakeholder(idApproval, id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(StakeholderActionsComponent);
