'use strict';

import { connect } from 'react-redux';
import { search } from '../../controllers/actions/search.actions';
import SearchComponent from '../components/Search/Search';

export const mapStateToProps = (state) => {
  const {
    isSearchMode, isSearchDataLoading, searchData, isActionOnSearchData,
  } = state.search;
  const { currentProjectsActions } = state.projectActions;
  const { currentDocumentsActions } = state.documentActions;
  const { permissions: { can_add_edit_delete_approvals: canAddEditDeleteApprovals } } = state.permissions;

  return {
    isSearchMode,
    isSearchDataLoading,
    searchData,
    isActionOnSearchData,
    currentProjectsActions,
    currentDocumentsActions,
    canAddEditDeleteApprovals: canAddEditDeleteApprovals && canAddEditDeleteApprovals.selected === 'yes',
  };
};

export const mapDispatchToProps = (dispatch) => ({
  enterSearchMode: () => {
    dispatch(search.enterSearchMode());
  },

  exitSearchMode: () => {
    dispatch(search.exitSearchMode());
  },

  fetchSearchData: () => {
    dispatch(search.fetchSearchData());
  },
});

export const Search = connect(mapStateToProps, mapDispatchToProps)(SearchComponent);
