'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Button, Link, SCALE } from '@uxpin/design-system';
import FooterDsTutorial from '../../../../images/design-systems/footer-ds-tutorial.svg';
import MergeTabEmptyView from '../../../../images/merge-info/MergeTabEmptyView.svg';
import { getMixpanel } from '../../../../../shared/mixpanel';

export default function MergeCreateLibrary(props) {
  const handleModalboxAddMergeLibrary = (e) => {
    const { openModalboxAddMergeLibrary } = props;
    e.preventDefault();
    getMixpanel((mixpanel) => {
      mixpanel.track('add_library_modal_viewed');
    });
    openModalboxAddMergeLibrary();
  };

  const renderButton = (onClick, copy, appearance = 'default') => {
    const buttonClasses = classnames('merge-option-button');

    return (
      <Button
        appearance={appearance}
        className={buttonClasses}
        size={SCALE.L}
        onClick={onClick}>
        {copy}
      </Button>
    );
  };

  return (
    <div id="merge-create-library" className="create-merge-screen">
      <section className="merge-create-intro">
        <figure className="merge-create-icon">
          <img
            src={MergeTabEmptyView}
            width="142"
            alt="Merge tab empty view"
          />
        </figure>
        <h2>
          Add Your Component Library
        </h2>
        <p>
          Sync your UI components from a Storybook library, npm package, or any Git repository. <br />
          Design with the same components that your devs build the products with
          and achieve the ultimate UI consistency.
        </p>
        {renderButton(handleModalboxAddMergeLibrary, 'Add new library')}
      </section>

      <ul className="merge-footer">
        <li>
          <figure>
            <figcaption>
              <h3>Learn more about Merge</h3>
              <p>Read the technical documentation to learn all about Merge technology.</p>
              <Link
                href="https://www.uxpin.com/docs/merge/what-is-uxpin-merge/"
                rel="noopener noreferrer"
                target="_blank">
                Visit our Docs
              </Link>
            </figcaption>
            <img className="item-icon" src={FooterDsTutorial} width="109" alt="Design Systems Tutorial" />
          </figure>
        </li>
      </ul>
    </div>
  );
}


MergeCreateLibrary.propTypes = {
  openModalboxAddMergeLibrary: PropTypes.func.isRequired,
};
