/* eslint-disable max-len,jsx-a11y/no-onchange,react/button-has-type */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/prop-types */

'use strict';

import React from 'react';
import { Modal } from '@uxpin/shared-components';
import autobind from 'autobind-decorator';
import _ from 'lodash';
import classnames from 'classnames';
import { APPEARANCES, Button, Link } from '@uxpin/design-system';

export default class ModalboxProjectChangeGroup extends React.Component {
  constructor(props) {
    super(props);
    this.MODAL = {
      WRAPPER_ID: 'project-change-group-modal-box',
      CLASS_NAMES: ['modal-box-medium-width'],
    };

    this.state = {
      selectedGroup: 0,
      groups: [],
      isHidden: false,
      isModalboxGroupsSettingsOpen: false,
    };
  }

  @autobind
  changeSelectedGroup(e) {
    this.setState({
      // eslint-disable-next-line radix
      selectedGroup: parseInt(e.target.value),
    });
  }

  @autobind
  modalboxGeneralAction() {
    this.setState({
      isHidden: false,
    });
    this.onSave(true);
  }

  // eslint-disable-next-line complexity
  componentWillReceiveProps(nextProps) {
    if (this.props.isCheckingPossibilityOfChangingGroup && !nextProps.isCheckingPossibilityOfChangingGroup && nextProps.isChangeGroupAlertVisible) {
      let projectGroupName = nextProps.newProjectGroupName;

      if (!projectGroupName) {
        projectGroupName = this.state.groups[this.state.selectedGroup].name;
        this.setState({
          isHidden: true,
        });
      }

      const cancelAction = this.getCancelAction(nextProps);
      const acceptAction = this.getAcceptAction(nextProps);
      const messages = this.getMessages(nextProps, projectGroupName);

      nextProps.openModalboxGeneralMessage('Change project group', messages, acceptAction, cancelAction);
    }

    if (this.props.isCheckingPossibilityOfChangingGroup && !nextProps.isCheckingPossibilityOfChangingGroup && !nextProps.isChangeGroupAlertVisible) {
      nextProps.onClose();
    }

    if (!_.isEmpty(this.state.groups) && _.isEmpty(nextProps.items)) {
      this.onClose();
    }

    if (nextProps.items) {
      // eslint-disable-next-line array-callback-return
      const groups = nextProps.items.filter((projectsGroup) => {
        if (!projectsGroup.type && nextProps.items.idProjectGroup !== projectsGroup.id) {
          return true;
        }
      });

      if (_.isEmpty(groups)) {
        this.onClose();
        return;
      }

      if (nextProps.data && nextProps.data.project.idProjectGroup) {
        const groupIds = _.map(groups, 'id');
        const openedProjectGroupId = nextProps.data.project.idProjectGroup;

        if (groupIds.indexOf(openedProjectGroupId) === -1) {
          this.onClose();
          return;
        }
      }

      this.setState({
        groups,
      });
    }

    if (nextProps.isModalboxGroupsSettingsOpen !== this.state.isModalboxGroupsSettingsOpen) {
      this.setState({
        isHidden: nextProps.isModalboxGroupsSettingsOpen,
        isModalboxGroupsSettingsOpen: nextProps.isModalboxGroupsSettingsOpen,
      });
    }
  }


  getCancelAction(props) {
    if (props.changeGroupAlertActions && _.isFunction(props.changeGroupAlertActions.cancel)) {
      return props.changeGroupAlertActions.cancel;
    }

    return () => this.setState({ isHidden: false });
  }

  getAcceptAction(props) {
    if (props.changeGroupAlertActions && _.isFunction(props.changeGroupAlertActions.accept)) {
      return props.changeGroupAlertActions.accept;
    }

    return this.modalboxGeneralAction;
  }

  getMessages(props, projectGroupName) {
    const messages = [];
    if (props.isUserToAdd) {
      messages.push(<span key={0}>
        You have moved a project into the <strong>{projectGroupName}</strong> group.
        All users in this group will now have access to this project.
                    </span>);
    }

    if (props.hasProjectGroup) {
      messages.push(<span key={1}>
        This project is in a different group.
        If you move it to current  group, users from
        another group will see it as ungrouped, and all
        users of <strong>{projectGroupName}</strong> going to be added to this project
                    </span>);
    }

    return messages;
  }

  renderProjectsGroups() {
    // eslint-disable-next-line react/no-array-index-key
    return this.state.groups.map((projectsGroup, id) => (<option key={id} value={id}>{projectsGroup.name}</option>));
  }

  @autobind
  save(e) {
    e.preventDefault();
    this.onSave(false);
  }

  onSave(force) {
    const { actions: { handleChangeGroupProject }, onClose, data: { project } } = this.props;
    const hasProjectGroup = project.hasProjectGroup && !project.idProjectGroup;
    const selectedGroup = this.state.groups[this.state.selectedGroup];

    handleChangeGroupProject(selectedGroup.id, hasProjectGroup, selectedGroup.color, force);
    if (force) {
      onClose();
    }
  }

  @autobind
  onClose(e) {
    if (!_.isEmpty(e)) {
      e.preventDefault();
    }

    this.props.onClose();
  }

  getProjectName() {
    const { data } = this.props;
    let projectName;

    if (data && data.project) {
      projectName = data.project.name || '';
    }

    return `Move "${projectName}" project into group:`;
  }

  shouldComponentUpdate(nextProps, nextState) {
    return Object.keys(this.props).some((propName) => !_.isEqual(this.props[propName], nextProps[propName]))
      || Object.keys(this.state).some((stateName) => !_.isEqual(this.state[stateName], nextState[stateName]));
  }

  getBtnIsWorking() {
    const { isCheckingPossibilityOfChangingGroup } = this.props;
    return isCheckingPossibilityOfChangingGroup;
  }

  @autobind
  openModalboxGroupsSettings(e) {
    e.preventDefault();
    this.props.openModalboxGroupsSettings();
  }

  renderClassNames() {
    const { isLoading } = this.props;
    return classnames(this.MODAL.CLASS_NAMES, { loading: isLoading });
  }

  render() {
    return (
      <Modal
        wrapperId={this.MODAL.WRAPPER_ID}
        classNames={this.renderClassNames()}
        title="Change project group"
        isOpen={this.props.isOpen}
        isHidden={this.state.isHidden}
        onClose={this.onClose}>
        <section className="modal-box-content">
          <div className="full-width">
            <label htmlFor="project-name" className="block-label">{this.getProjectName()}</label>
            {/* Temporary solution - will be replaced with hulkselect: */}
            <select name="select" value={this.state.selectedGroup} onChange={this.changeSelectedGroup}>
              {this.renderProjectsGroups()}
            </select>
          </div>
        </section>
        <footer>
          <p>
            <Link href="#projects-groups" onClick={this.openModalboxGroupsSettings}>Manage groups</Link>
          </p>
          <ul className="btns-group">
            <li>
              <Link appearance={APPEARANCES.NEUTRAL} href="#cancel" onClick={this.onClose}>Cancel</Link>
            </li>
            <li>
              <Button isWorking={this.getBtnIsWorking()} onClick={this.save}>Save</Button>
            </li>
          </ul>
        </footer>
      </Modal>
    );
  }
}
