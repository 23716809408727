/* eslint-disable max-len */

'use strict';

import _ from 'lodash';
import { searchActions, searchElementType } from '../../constants/search.constants';
import { projectsStatuses } from '../../constants/projects.constants';

const {
  REQUEST_FETCHING_SEARCH_DATA,
  FETCHING_SEARCH_DATA,
  START_SEARCH_DATA_ACTION,
  SEARCH_DATA_RENAME_PROJECT,
  SEARCH_DATA_DUPLICATE_PROJECT,
  SEARCH_DATA_UPDATE_DUPLICATED_PROJECT,
  SEARCH_DATA_UPDATE_DUPLICATED_PROJECT_STATUS,
  SEARCH_DATA_REMOVE_PROJECT,
  SEARCH_DATA_CHANGE_GROUP,
  SEARCH_DATA_RENAME_DOCUMENT,
  SEARCH_DATA_MOVE_DOCUMENT,
  SEARCH_DATA_DUPLICATE_DOCUMENT,
  SEARCH_DATA_UPDATE_DUPLICATED_DOCUMENT,
  SEARCH_DATA_UPDATE_DUPLICATED_DOCUMENT_STATUS,
  SEARCH_DATA_REMOVE_DOCUMENT,
  SEARCH_PROJECTS_ON,
  SEARCH_PROJECTS_OFF,
} = searchActions;

function assignDataToProject(searchData, idProject, data) {
  return searchData.map((item) => {
    if (item.id === idProject && item.type === searchElementType.PROJECT) {
      const project = Object.assign({}, item);
      _.assign(project, data);
      return project;
    }

    return item;
  });
}

function assignDataToDocument(searchData, idDocument, data) {
  return searchData.map((item) => {
    if (item.id === idDocument && item.type !== searchElementType.PROJECT) {
      const project = Object.assign({}, item);
      _.assign(project, data);
      return project;
    }

    return item;
  });
}

function changeProjectGroup(searchData, idProject, idProjectGroup, groupColor, hasProjectGroup) {
  return searchData.map((item) => {
    if (item.id === idProject && item.type === searchElementType.PROJECT) {
      const project = Object.assign({}, item);
      project.idProjectGroup = idProjectGroup;
      project.groupColor = groupColor;
      project.hasProjectGroup = hasProjectGroup;
      return project;
    }

    return item;
  });
}

function removeProject(searchData, idProject) {
  return searchData.filter((item) => (item.id !== idProject && item.type === searchElementType.PROJECT) || [searchElementType.PROTOTYPE, searchElementType.DOCUMENT].indexOf(item.type) !== -1);
}

function removeDocument(searchData, idDocument) {
  return searchData.filter((item) => (item.id !== idDocument && item.type !== searchElementType.PROJECT) || item.type === searchElementType.PROJECT);
}

function findProject(searchData, idProject) {
  let index = null;
  const project = _.first(searchData.filter((item, id) => {
    if (item.id === idProject && item.type === searchElementType.PROJECT) {
      index = id;
      return true;
    }

    return false;
  }));

  return {
    project,
    index,
  };
}

function findDocument(searchData, idDocument) {
  let index = null;
  const document = _.first(searchData.filter((item, id) => {
    if (item.id === idDocument && item.type !== searchElementType.PROJECT) {
      index = id;
      return true;
    }

    return false;
  }));

  return {
    document,
    index,
  };
}


const actionRequestFetchingSearchData = (state) => (
  Object.assign({}, state, {
    isSearchDataLoading: true,
  })
);

const actionFetchingSearchData = (state, action) => (
  Object.assign({}, state, {
    isSearchDataLoading: false,
    searchData: action.searchData,
  })
);
const actionStartSearchDataAction = (state) => (
  Object.assign({}, state, {
    isActionOnSearchData: true,
  })
);
const actionSearchDataRenameProject = (state, action) => (
  Object.assign({}, state, {
    isActionOnSearchData: false,
    searchData: assignDataToProject(state.searchData, action.idProject, { name: action.name }),
  })
);
const actionSearchDataDuplicateProject = (state, action) => {
  const projectData = findProject(state.searchData, action.idProject);
  const projectCopy = Object.assign({}, projectData.project);
  projectCopy.status = projectsStatuses.DUPLICATED;
  projectCopy.name = `${projectCopy.name} - Copy`;
  projectCopy.hasTemporaryId = true;
  projectCopy.id = action.temporaryId;

  return Object.assign({}, state, {
    isActionOnSearchData: false,
    searchData: [
      ...state.searchData.slice(0, projectData.index + 1),
      projectCopy,
      ...state.searchData.slice(projectData.index + 1),
    ],
  });
};
const actionSearchDataUpdateDuplicatedProject = (state, action) => (
  Object.assign({}, state, {
    isActionOnSearchData: false,
    searchData: assignDataToProject(state.searchData, action.temporaryId, {
      id: action.idProject,
      name: action.projectName,
      hasTemporaryId: null,
    }),
  })
);

const actionSearchDataUpdateDuplicatedProjectStatus = (state, action) => (
  Object.assign({}, state, {
    isActionOnSearchData: false,
    searchData: assignDataToProject(state.searchData, action.idProject, {
      status: null,
    }),
  })
);
const actionSearchDataRemoveProject = (state, action) => (
  Object.assign({}, state, {
    isSearchDataLoading: false,
    isActionOnSearchData: false,
    searchData: removeProject(state.searchData, action.idProject),
  })
);
const actionSearchDataChangeGroup = (state, action) => (
  Object.assign({}, state, {
    isSearchDataLoading: false,
    isActionOnSearchData: false,
    searchData: changeProjectGroup(state.searchData, action.idProject, action.idProjectGroup, action.groupColor, action.hasProjectGroup),
  })
);
const actionSearchDataRenameDocument = (state, action) => (
  Object.assign({}, state, {
    isActionOnSearchData: false,
    searchData: assignDataToDocument(state.searchData, action.idDocument, { name: action.name }),
  })
);
const actionSearchDataMoveDocument = (state, action) => (
  Object.assign({}, state, {
    isActionOnSearchData: false,
    searchData: assignDataToDocument(state.searchData, action.idDocument, { idProject: action.idProject }),
  })
);
const actionSearchDataDuplicateDocument = (state, action) => {
  const documentData = findDocument(state.searchData, action.idDocument);
  const documentCopy = Object.assign({}, documentData.document);
  documentCopy.status = projectsStatuses.DUPLICATED;
  documentCopy.name = `${documentCopy.name} - Copy`;
  documentCopy.hasTemporaryId = true;
  documentCopy.id = action.temporaryId;

  return Object.assign({}, state, {
    isActionOnSearchData: false,
    searchData: [
      ...state.searchData.slice(0, documentData.index + 1),
      documentCopy,
      ...state.searchData.slice(documentData.index + 1),
    ],
  });
};
const actionSearchDataUpdateDuplicatedDocument = (state, action) => (
  Object.assign({}, state, {
    isActionOnSearchData: false,
    searchData: assignDataToDocument(state.searchData, action.temporaryId, {
      hash: action.hash,
      id: action.idDocument,
      name: action.documentName,
      hasTemporaryId: null,
    }),
  })
);
const actionSearchDataUpdateDuplicatedDocumentStatus = (state, action) => (
  Object.assign({}, state, {
    isActionOnSearchData: false,
    searchData: assignDataToDocument(state.searchData, action.idDocument, {
      status: null,
    }),
  })
);
const actionSearchDataRemoveDocument = (state, action) => (
  Object.assign({}, state, {
    isSearchDataLoading: false,
    isActionOnSearchData: false,
    searchData: removeDocument(state.searchData, action.idDocument),
  })
);
const actionSearchProjectsOn = (state) => (
  Object.assign({}, state, {
    isSearchMode: true,
  })
);
const actionSearchProjectsOff = (state) => (
  Object.assign({}, state, {
    isSearchMode: false,
  })
);

const searchHandler = {
  [REQUEST_FETCHING_SEARCH_DATA]: actionRequestFetchingSearchData,
  [FETCHING_SEARCH_DATA]: actionFetchingSearchData,
  [START_SEARCH_DATA_ACTION]: actionStartSearchDataAction,
  [SEARCH_DATA_RENAME_PROJECT]: actionSearchDataRenameProject,
  [SEARCH_DATA_DUPLICATE_PROJECT]: actionSearchDataDuplicateProject,
  [SEARCH_DATA_UPDATE_DUPLICATED_PROJECT]: actionSearchDataUpdateDuplicatedProject,
  [SEARCH_DATA_UPDATE_DUPLICATED_PROJECT_STATUS]: actionSearchDataUpdateDuplicatedProjectStatus,
  [SEARCH_DATA_REMOVE_PROJECT]: actionSearchDataRemoveProject,
  [SEARCH_DATA_CHANGE_GROUP]: actionSearchDataChangeGroup,
  [SEARCH_DATA_RENAME_DOCUMENT]: actionSearchDataRenameDocument,
  [SEARCH_DATA_MOVE_DOCUMENT]: actionSearchDataMoveDocument,
  [SEARCH_DATA_DUPLICATE_DOCUMENT]: actionSearchDataDuplicateDocument,
  [SEARCH_DATA_UPDATE_DUPLICATED_DOCUMENT]: actionSearchDataUpdateDuplicatedDocument,
  [SEARCH_DATA_UPDATE_DUPLICATED_DOCUMENT_STATUS]: actionSearchDataUpdateDuplicatedDocumentStatus,
  [SEARCH_DATA_REMOVE_DOCUMENT]: actionSearchDataRemoveDocument,
  [SEARCH_PROJECTS_ON]: actionSearchProjectsOn,
  [SEARCH_PROJECTS_OFF]: actionSearchProjectsOff,
};

const handleSearchActions = (state, action) => {
  if (!_.has(searchHandler, action.type)) {
    return state;
  }

  return searchHandler[action.type](state, action);
};

export function searchReducer(state = {
  isSearchMode: false,
  isSearchDataLoading: false,
  isActionOnSearchData: false,
  searchData: [],
}, action) {
  return handleSearchActions(state, action);
}
