export const EVENT_COLLECTION_ARCHIVED = 'EVENT_COLLECTION_ARCHIVED';
export const EVENT_COLLECTION_UNARCHIVED = 'EVENT_COLLECTION_UNARCHIVED';
export const EVENT_CREATE_NEW_PROJECT = 'EVENT_CREATE_NEW_PROJECT';
export const EVENT_CREATE_NEW_PROTOTYPE = 'EVENT_CREATE_NEW_PROTOTYPE';
export const EVENT_DESIGN_SYSTEM_REMOVED = 'EVENT_DESIGN_SYSTEM_REMOVED';
export const EVENT_DESIGN_SYSTEM_RENAMED = 'EVENT_DESIGN_SYSTEM_RENAMED';
export const EVENT_PROTOTYPE_REMOVED = 'EVENT_PROTOTYPE_REMOVED';
export const EVENT_PROTOTYPE_RENAMED = 'EVENT_PROTOTYPE_RENAMED';
export const EVENT_PROTOTYPE_RESTORED = 'EVENT_PROTOTYPE_RESTORED';
export const EVENT_SHOW_CHANGELOG = 'EVENT_SHOW_CHANGELOG';
