/* eslint-disable max-len */

'use strict';

import _ from 'lodash';
import * as constants from '../../constants/projects.constants';
import * as projectActions from './projectActions.actions';
import { projects } from './projects.actions';
import { request } from '../../utils/request';
import { groupTypes } from '../../constants/projectsGroupsManagement.constants';
import { modalsIds, limitsContentTypes } from '../../constants/modal.constants';
import { openModal, closeModal } from './modal.actions';

function archiveProjects(dispatch, draggedProjects) {
  const projectsGroupsIds = [];
  const projectIdsToArchive = _.compact(_.map(draggedProjects, (project) => {
    if (project.status !== constants.projectsStatuses.ARCHIVED) {
      projectsGroupsIds.push(project.idProjectGroup);
      return project.idProject;
    }
    return null;
  }));

  if (projectIdsToArchive.length) {
    dispatch({
      type: constants.projectsActions.ARCHIVE_PROJECT,
      ids: projectIdsToArchive,
    });

    projects.archiveProjects(projectIdsToArchive, projectsGroupsIds)(dispatch);
  }
}

function moveProjects(dispatch, draggedProjects, newProjectGroupId = null, newProjectGroupName, totalDocumentsCount, isFreePlan) {
  const projectIdsToUnarchive = [];
  const projectsGroupsIdsUnarchive = [];
  const projectGroupsIdsMove = [];
  const projectIdsToMove = [];

  _.forEach(draggedProjects, (project) => {
    if (project.idProjectGroup === newProjectGroupId && project.status === constants.projectsStatuses.ACTIVE) {
      return;
    }

    if (project.status === constants.projectsStatuses.ARCHIVED) {
      projectIdsToUnarchive.push(project.idProject);
      projectsGroupsIdsUnarchive.push(project.idProjectGroup);
    } else if (project.status === constants.projectsStatuses.ACTIVE) {
      projectIdsToMove.push(project.idProject);
      projectGroupsIdsMove.push(project.idProjectGroup);
    }
  });

  if (projectIdsToUnarchive.length) {
    const limitReachedOnFreePlan = isFreePlan && totalDocumentsCount.used > 1;

    if (limitReachedOnFreePlan) {
      dispatch(openModal(modalsIds.LIMITS_EXCEEDED, {
        forcedContentType: limitsContentTypes.PROTOTYPE_UNARCHIVE_LIMIT,
      }));
    } else {
      dispatch({
        type: constants.projectsActions.UNARCHIVE_PROJECT,
        ids: projectIdsToUnarchive,
        idProjectGroup: newProjectGroupId,
      });

      const data = [];

      projectIdsToUnarchive.forEach((idProject) => {
        data.push({
          idProject,
          idProjectGroup: newProjectGroupId,
        });
      });

      if (newProjectGroupId) {
        projects.unarchiveProjectsAndChangeGroup(projectIdsToUnarchive, projectsGroupsIdsUnarchive, data)(dispatch);
      } else {
        projects.unarchiveProjectsAndRemoveFromGroup(projectIdsToUnarchive)(dispatch);
      }
    }
  }

  if (projectIdsToMove.length) {
    dispatch({
      type: constants.projectsActions.CHANGE_GROUP,
      ids: projectIdsToMove,
      idProjectGroup: newProjectGroupId,
    });

    if (newProjectGroupId) {
      changeProjectsGroup(projectIdsToMove, projectGroupsIdsMove, newProjectGroupId, newProjectGroupName, draggedProjects)(dispatch);
    } else {
      projects.removeProjectsFromGroup(projectIdsToMove, projectGroupsIdsMove)(dispatch);
    }
  }
}

function archiveProject(dispatch, idProject, oldProjectGroupId) {
  dispatch({
    type: constants.projectsActions.ARCHIVE_PROJECT,
    ids: [idProject],
  });
  projects.archiveProject(idProject, true, oldProjectGroupId)(dispatch);
}

function unarchiveAndChangeGroup(dispatch, idProject, newProjectGroupId) {
  dispatch({
    type: constants.projectsActions.UNARCHIVE_PROJECT,
    ids: [idProject],
    idProjectGroup: newProjectGroupId,
  });
  projects.unarchiveAndChangeGroup(idProject, newProjectGroupId, true)(dispatch);
}

function removeProjectFromGroup(dispatch, idProject, oldProjectGroupId) {
  dispatch({
    type: constants.projectsActions.CHANGE_GROUP,
    ids: [idProject],
    idProjectGroup: null,
  });
  projects.removeProjectFromGroup(idProject, true, oldProjectGroupId)(dispatch);
}

function unarchiveAndRemoveProjectFromGroup(dispatch, idProject) {
  dispatch({
    type: constants.projectsActions.UNARCHIVE_PROJECT,
    ids: [idProject],
    idProjectGroup: null,
  });
  projects.unarchiveAndRemoveProjectFromGroup(idProject, true)(dispatch);
}

function cancelChangingGroupDnd(dispatch, idProject, oldProjectGroupId = null) {
  dispatch(projectActions.stopActionOnProject(idProject, constants.projectsActionsOnHover.MOVING));
  dispatch({
    type: constants.projectsActions.CHANGE_GROUP,
    ids: [idProject],
    idProjectGroup: oldProjectGroupId,
  });
}

function onChangeGroupDnd(dispatch, idProject, idProjectGroup, oldProjectGroupId = null) {
  dispatch(projects.stopCheckingPossibilityOfChangingGroup(false, false, false, null, ''));
  request.put('/project_groups/project', {
    body: [{
      idProject,
      idProjectGroup,
    }],
  }).then(() => {
    dispatch(projectActions.stopActionOnProject(idProject));
  }).catch(() => {
    dispatch(projectActions.stopActionOnProject(idProject));
    dispatch(projectActions.setProjectError(idProject,
      () => onChangeGroupDnd(dispatch, idProject, idProjectGroup, oldProjectGroupId),
      () => {
        dispatch(projectActions.removeProjectError(idProject));
        dispatch({
          type: constants.projectsActions.CHANGE_GROUP,
          ids: [idProject],
          idProjectGroup: oldProjectGroupId,
        });
      }));
  });
}

function getAcceptChangingGroupCallback(dispatch, projectsIds, projectsGroupsIds, changeGroupData) {
  return () => {
    onChangeProjectsGroups(projectsIds, projectsGroupsIds, changeGroupData)(dispatch);
    dispatch(closeModal(modalsIds.MODALBOX_PROJECT_CHANGE_GROUP_INFO));
  };
}

function getCloseChangingGroupCallback(dispatch, projectsIds, projectsGroupsIds) {
  return () => {
    projectsIds.forEach((id, index) => {
      dispatch(projectActions.stopActionOnProject(id, constants.projectsActionsOnHover.MOVING));
      dispatch({
        type: constants.projectsActions.CHANGE_GROUP,
        ids: [id],
        idProjectGroup: projectsGroupsIds[index],
      });
    });

    dispatch(closeModal(modalsIds.MODALBOX_PROJECT_CHANGE_GROUP_INFO));
  };
}

function onChangeProjectsGroups(projectsIds, projectsGroupsIds, data) {
  return (dispatch) => {
    request.put('/project_groups/project', {
      body: data,
    }).then(() => {
      projectsIds.forEach((id) => {
        dispatch(projectActions.stopActionOnProject(id, constants.projectsActionsOnHover.MOVING));
      });
    }).catch(() => {
      projectsIds.forEach((id, index) => {
        dispatch(projectActions.stopActionOnProject(id));
        dispatch(projectActions.setProjectError(id,
          () => dispatch(onChangeProjectsGroups(projectsIds, projectsGroupsIds, data)),
          () => {
            dispatch(projectActions.removeProjectError(id));
            dispatch({
              type: constants.projectsActions.CHANGE_GROUP,
              ids: [id],
              idProjectGroup: projectsGroupsIds[index],
            });
          }));
      });
    });
  };
}

function changeProjectsGroup(projectsIds, projectsGroupsIds, newProjectGroupId, newProjectGroupName, draggedProjects) {
  return (dispatch) => {
    projectsIds.forEach((id) => {
      dispatch(projectActions.requestActionOnProject(id, constants.projectsActionsOnHover.MOVING));
    });

    const promises = [];
    promises.push(request.get('/project_groups/access'));
    promises.push(request.get(`/projects/members?idsProject=[${projectsIds}]`));

    const changeGroupData = [];

    projectsIds.forEach((idProject) => {
      changeGroupData.push({
        idProject,
        idProjectGroup: newProjectGroupId,
      });
    });

    Promise.all(promises)
      .then((data) => {
        const groupUsers = _.find(data[0], (group) => group.idProjectGroup === newProjectGroupId).userIds;
        const projectUsers = data[1];

        const projectsList = [];
        const projectsWithGroups = [];
        _.forEach(projectUsers, (users, projectId) => {
          if (_.difference(groupUsers, users).length) {
            projectsList.push(draggedProjects[projectId]);
          }
        });

        projectsIds.forEach((id) => {
          if (draggedProjects[id].hasProjectGroup && !draggedProjects[id].idProjectGroup) {
            projectsWithGroups.push(draggedProjects[id]);
          }
        });

        if (projectsList.length || projectsWithGroups.length) {
          dispatch(openModal(modalsIds.MODALBOX_PROJECT_CHANGE_GROUP_INFO, {
            title: 'Change project group',
            projectsList,
            projectsWithGroups,
            projectGroupName: newProjectGroupName,
            onAccept: getAcceptChangingGroupCallback(dispatch, projectsIds, projectsGroupsIds, changeGroupData),
            onClose: getCloseChangingGroupCallback(dispatch, projectsIds, projectsGroupsIds),
          }));
        } else {
          onChangeProjectsGroups(projectsIds, projectsGroupsIds, changeGroupData)(dispatch);
        }
      });
  };
}

function changeGroupDnd(idProject, idProjectGroup, newProjectGroupName, oldProjectGroupId = null, hasProjectGroup) {
  return (dispatch) => {
    dispatch(projectActions.requestActionOnProject(idProject, constants.projectsActionsOnHover.MOVING));
    dispatch({
      type: constants.projectsActions.CHANGE_GROUP,
      ids: [idProject],
      idProjectGroup,
    });

    const promises = [];
    promises.push(request.get('/project_groups/access'));
    promises.push(request.get(`/project/${idProject}/members`));

    dispatch({
      type: constants.projectsActions.START_CHECKING_POSSIBILITY_OF_CHANGING_GROUP,
    });

    Promise.all(promises)
      .then((data) => {
        const groupUsers = _.find(data[0], (group) => group.idProjectGroup === idProjectGroup).userIds;
        const projectUsers = data[1];
        const isUserToAdd = !!_.difference(groupUsers, projectUsers).length;

        if (isUserToAdd || hasProjectGroup) {
          dispatch(projects.stopCheckingPossibilityOfChangingGroup(true, isUserToAdd, hasProjectGroup, {
            cancel() { cancelChangingGroupDnd(dispatch, idProject, oldProjectGroupId); },

            accept() { onChangeGroupDnd(dispatch, idProject, idProjectGroup, oldProjectGroupId); },
          }, newProjectGroupName));
        } else {
          onChangeGroupDnd(dispatch, idProject, idProjectGroup, oldProjectGroupId);
        }
      })
      .catch(() => {
        dispatch(projects.stopCheckingPossibilityOfChangingGroup(false, false, false, null, ''));
        dispatch(projectActions.stopActionOnProject(idProject));
        dispatch(projectActions.setProjectError(idProject,
          () => dispatch(changeGroupDnd(idProject, idProjectGroup, newProjectGroupName, oldProjectGroupId, hasProjectGroup)),
          () => {
            dispatch(projectActions.removeProjectError(idProject));
            dispatch({
              type: constants.projectsActions.CHANGE_GROUP,
              ids: [idProject],
              idProjectGroup: oldProjectGroupId,
            });
          }));
      });
  };
}

export function moveProjectsByDnd(newProjectGroupId, newProjectGroupType, newProjectGroupName, draggedProjects, totalDocumentsCount, isFreePlan) {
  return (dispatch) => {
    if (newProjectGroupType === groupTypes.ARCHIVED) {
      archiveProjects(dispatch, draggedProjects);
    } else if (newProjectGroupId || newProjectGroupType === groupTypes.UNGROUPED) {
      moveProjects(dispatch, draggedProjects, newProjectGroupId, newProjectGroupName, totalDocumentsCount, isFreePlan);
    }
  };
}

export function moveProjectByDnd(newProjectGroupId, newProjectGroupType, newProjectGroupName, draggedProject, totalDocumentsCount, isFreePlan) {
  const oldProjectGroupType = draggedProject.groupType;
  const idProject = draggedProject.id;
  const oldProjectGroupId = draggedProject.idProjectGroup;
  const hasProjectGroup = draggedProject.groupType === groupTypes.UNGROUPED && draggedProject.data.hasProjectGroup;
  const limitReachedOnFreePlan = isFreePlan && totalDocumentsCount.used > 1;

  // eslint-disable-next-line complexity
  return (dispatch) => {
    if (newProjectGroupType === groupTypes.ARCHIVED) {
      archiveProject(dispatch, idProject, oldProjectGroupId);
    } else if (oldProjectGroupType === groupTypes.ARCHIVED && newProjectGroupId) {
      if (limitReachedOnFreePlan) {
        dispatch(openModal(modalsIds.LIMITS_EXCEEDED, {
          forcedContentType: limitsContentTypes.PROTOTYPE_UNARCHIVE_LIMIT,
        }));
      } else {
        unarchiveAndChangeGroup(dispatch, idProject, newProjectGroupId);
      }
    } else if (newProjectGroupId && oldProjectGroupType !== groupTypes.ARCHIVED) {
      changeGroupDnd(idProject, newProjectGroupId, newProjectGroupName, oldProjectGroupId, hasProjectGroup)(dispatch);
    } else if (newProjectGroupType === groupTypes.UNGROUPED && !oldProjectGroupType) {
      removeProjectFromGroup(dispatch, idProject, oldProjectGroupId);
    } else if (oldProjectGroupType === groupTypes.ARCHIVED && newProjectGroupType === groupTypes.UNGROUPED) {
      if (limitReachedOnFreePlan) {
        dispatch(openModal(modalsIds.LIMITS_EXCEEDED, {
          forcedContentType: limitsContentTypes.PROTOTYPE_UNARCHIVE_LIMIT,
        }));
      } else {
        unarchiveAndRemoveProjectFromGroup(dispatch, idProject);
      }
    }
  };
}
