/* eslint-disable max-len */

'use strict';

import { connect } from 'react-redux';
import ProjectsGroupComponent from '../components/ProjectsList/components/ProjectsGroup';
import * as projectSelector from '../../controllers/actions/projectSelector.actions';
import { moveProjectsByDnd, moveProjectByDnd } from '../../controllers/actions/projectsDnD.actions';
import { isFreePlanType } from '../../utils/account';

export const mapStateToProps = (state) => {
  const { selectedProjects } = state.projectSelector;
  const { totalDocumentsCount } = state.insideProject;
  const { accountPlan } = state.accountSettings;
  const isFreePlan = isFreePlanType(accountPlan);
  const { favsProjects, favsFilters } = state.settings.data;
  const { favsProjects: filterType } = favsFilters;
  
  return {
    selectedProjects,
    totalDocumentsCount,
    isFreePlan,
    favsProjects,
    filterType,
  };
};

export const mapDispatchToProps = (dispatch) => ({
  clearSelectedProjects: () => {
    dispatch(projectSelector.clearSelectedProjects());
  },

  moveProjectsByDnd: (newProjectGroupId, newProjectGroupType, newProjectGroupName, draggedProjects, totalDocumentsCount, isFreePlan) => {
    dispatch(moveProjectsByDnd(newProjectGroupId, newProjectGroupType, newProjectGroupName, draggedProjects, totalDocumentsCount, isFreePlan));
  },

  moveProjectByDnd: (newProjectGroupId, newProjectGroupType, newProjectGroupName, draggedProject, totalDocumentsCount, isFreePlan) => {
    dispatch(moveProjectByDnd(newProjectGroupId, newProjectGroupType, newProjectGroupName, draggedProject, totalDocumentsCount, isFreePlan));
  },
});

export const ProjectsGroup = connect(mapStateToProps, mapDispatchToProps)(ProjectsGroupComponent);
