import { connect } from 'react-redux';
import MergeTabContentComponent from '../components/Merge/MergeTabContent';

export const mapStateToProps = (state) => {
  const { data: currentUserData } = state.currentUser;
  const { features } = state.features;
  const { libraries, isLibraryListFetched } = state.designSystems;
  const { hiddenLibraries } = state.settings.data.designLibraries;

  return {
    currentUserData,
    features,
    libraries,
    isLibraryListFetched,
    isMergeEnabled: state.features.features.merge === 'enabled',
    isStorybookEnabled: state.features.features.storybook_libraries === 'enabled',
    hiddenLibraries: hiddenLibraries || [],
  };
};

export const MergeTabContent = connect(mapStateToProps, null)(MergeTabContentComponent);
