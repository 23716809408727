'use strict';

import { omit } from 'lodash';
import { projectsActions } from '../../constants/projects.constants';
import { settingsAction } from '../../constants/settings.constants';
import { insideProjectActions } from '../../constants/insideProject.constants';
import { mergeTutorialPopup, projectListLoadCompleted } from '../../constants/onboarding.constants';
import { putRequest } from '../actions/settings.actions';

export function settingsReducer(state = {
  data: {},
  areSettingsFetched: false,
  enableMergeTutorial: false,
  projectListLoadCompleted: false,
}, action) {
  switch (action.type) {
    case settingsAction.SET_SETTINGS:
      return Object.assign({}, state, {
        data: action.data,
        areSettingsFetched: true,
      });
    case settingsAction.UPDATE_SETTING:
      return Object.assign({}, state, {
        data: Object.assign({}, state.data, { [action.name]: action.value }),
      });
    case projectsActions.REMOVE_PROJECT_PERMANENTLY:
      return removeFavsItems(state, 'favsProjects', action.idProject);
    case insideProjectActions.REMOVE_DOCUMENT:
      return removeFavsItems(state, 'favsPrototypes', action.idDocument);
    case mergeTutorialPopup:
      return Object.assign({}, state, {
        enableMergeTutorial: action.data,
      });
    case projectListLoadCompleted:
      return Object.assign({}, state, {
        projectListLoadCompleted: action.data,
      });
    default:
      return state;
  }
}

function removeFavsItems(state, key, deletedId) {
  const starredItemsById = state.data[key];
  const updatedStarredItemsById = omit(starredItemsById, deletedId);
  putRequest(key, updatedStarredItemsById, true);

  return {
    ...state,
    data: {
      ...state.data,
      [key]: updatedStarredItemsById,
    },
  };
}
