'use strict';

import { save, load } from './localStorage';

const getLSKey = (id) => `uxpm-${id}-closed`;

const isClosed = (id) => load(getLSKey(id));

const setClosed = (id) => {
  save(getLSKey(id), true);
};

export const isActive = (settings) => {
  if (settings.isActive) {
    const now = new Date();
    const start = new Date(settings.startShowBarTime);
    const end = new Date(settings.endShowBarTime);
    return now >= start && now <= end;
  }

  return false;
};

export const isVisible = (settings) => isActive(settings) && !isClosed(settings.id);

export const setInvisible = (id) => {
  setClosed(id);
};
