'use strict';

import { featuresActions } from '../../constants/features.constants';
import { request } from '../../utils/request';

export const getFeatures = () => (dispatch) => {
  request.get('/account/features/')
    .then((data) => {
      dispatch({
        type: featuresActions.FETCH_FEATURES,
        features: data,
      });
      return data;
    })
    .catch(() => {
      // TODO HANDLE ERROR
    });
};
