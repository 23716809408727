import { isSafari } from '@uxpin/shared-components';

export const fixSafariCacheReload = () => {
  // Fix for Safari's cache issue
  if (isSafari) {
    window.onpageshow = (event) => {
      if (event.persisted) {
        window.location.reload();
      }
    };
  }
};

export const parseDomain = (hostname) => {
  const domain = hostname.split('.');
  return {
    subdomain: domain.shift(),
    domain: domain.join('.'),
  };
};
