/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */

'use strict';

import React from 'react';
import { Link, APPEARANCES } from '@uxpin/design-system';
import autobind from 'autobind-decorator';

export default class GroupNew extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      time: 0,
    };

    this.timeout = null;
  }

  @autobind
  undo(e) {
    const { undoGroup, index } = this.props;
    e.preventDefault();
    undoGroup(index);
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  @autobind
  count() {
    const {
      countTime, deleteGroup, index, time,
    } = this.props;

    this.setState({
      time,
    }, () => {
      if (time === 0) {
        clearTimeout(this.timeout);
        deleteGroup(index);
      } else {
        this.timeout = setTimeout(() => {
          countTime(index);
          this.count();
        }, 1000);
      }
    });
  }

  @autobind
  removeGroup(e) {
    e.preventDefault();
    const { deleteGroup, index } = this.props;

    deleteGroup(index);
    this.setState({
      time: 0,
    });
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.shouldStart && nextProps.shouldStart && nextProps.time) {
      this.timeout = setTimeout(this.count);
    } else if ((this.props.shouldStart && !nextProps.shouldStart) || nextProps.time === 0) {
      clearTimeout(this.timeout);
    }
  }

  render() {
    const { groupName } = this.props;
    return (
      <section className="deleted-item-info">
        <p className="remaining-time hidden-info">{this.state.time}s</p>
        <h5 className="info">Group "<span className="item-name">{groupName}</span>" has been deleted.&nbsp;
          <Link appearance={APPEARANCES.CONTEXTUAL} href="#undo-delete" onClick={this.undo}>Undo.</Link>
          <a
            onClick={this.removeGroup}
            href="#execute"
            title="Execute"
            className="execute icon-general-close only-icon-font">
            Execute
          </a>
        </h5>
      </section>
    );
  }
}
