/* eslint-disable max-len */

'use strict';

import { connect } from 'react-redux';
import ProjectsGroups from '../components/GroupsSettings/GroupsSettings';
import * as projectGroupsManagement from '../../controllers/actions/projectsGroupsManagement.actions';

export const mapStateToProps = (state) => {
  const {
    isLoading, isError, isSaving, addedProjectsGroups, groupsAccess,
  } = state.projectsGroupsManagement;
  const { permissions } = state.permissions;
  const { data, data: { idActiveAccount } } = state.currentUser;
  const { membersData } = state.members;
  const { items } = state.projects;
  const { groupsExpandedMode, groupsOrder } = state.settings.data;
  const accountGroupsOrder = idActiveAccount && state.settings.data[`groupsOrder_${idActiveAccount}`] ? state.settings.data[`groupsOrder_${idActiveAccount}`] : groupsOrder;

  return {
    projectsGroups: items,
    groupsExpandedMode,
    groupsOrder: accountGroupsOrder,
    isLoading,
    isSaving,
    isError,
    membersData,
    groupsAccess,
    addedProjectsGroups,
    canAddEditDeleteProjectGroups: permissions && permissions.can_add_edit_delete_project_groups && permissions.can_add_edit_delete_project_groups.selected === 'yes',
    currentUserId: data.id,
    currentAccountId: idActiveAccount,
  };
};

export const mapDispatchToProps = (dispatch) => ({
  fetchProjectsGroups: () => {
    dispatch(projectGroupsManagement.fetchProjectsGroups());
  },

  saveProjectsGroups: (groups, newGroups, updatedGroups, deletedGroups, canAddEditDeleteProjectGroups, groupsOrder, groupsExpandedMode, idAccount) => {
    dispatch(projectGroupsManagement.saveProjectsGroups(groups, newGroups, updatedGroups, deletedGroups, canAddEditDeleteProjectGroups, groupsOrder, groupsExpandedMode, idAccount));
  },
});

export const ProjectsGroupsManagement = connect(mapStateToProps, mapDispatchToProps)(ProjectsGroups);
