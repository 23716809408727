'use strict';

import _ from 'lodash';
import { projectsSortingTypes } from '../constants/projects.constants';

// eslint-disable-next-line complexity
const getCompareFn = (fieldName, desc, secondFieldName) => (a, b) => {
  if (!_.isString(a[fieldName]) || !_.isString(b[fieldName])) {
    return 0;
  }

  if (a[fieldName].toLowerCase() < b[fieldName].toLowerCase()) {
    return desc ? 1 : -1;
  }

  if (a[fieldName].toLowerCase() > b[fieldName].toLowerCase()) {
    return desc ? -1 : 1;
  }

  if (secondFieldName) {
    if (a[secondFieldName].toLowerCase() < b[secondFieldName].toLowerCase()) {
      return desc ? 1 : -1;
    }

    if (a[secondFieldName].toLowerCase() > b[secondFieldName].toLowerCase()) {
      return desc ? -1 : 1;
    }
  }

  return 0;
};

export const compareGroupPosition = (a, b) => {
  if (a.position < b.position) {
    return -1;
  }

  if (a.position > b.position) {
    return 1;
  }

  return 0;
};

export const sortItems = (items, sortingType) => {
  switch (sortingType) {
    case projectsSortingTypes.NAME_ASC:
      return items.sort(getCompareFn('name', false, 'insertDate'));
    case projectsSortingTypes.NAME_DESC:
      return items.sort(getCompareFn('name', true, 'insertDate'));
    case projectsSortingTypes.UPDATE_DATE_ASC:
      return items.sort(getCompareFn('lastUpdate', false, 'name'));
    case projectsSortingTypes.UPDATE_DATE_DESC:
      return items.sort(getCompareFn('lastUpdate', true, 'name'));
    case projectsSortingTypes.CREATE_DATE_ASC:
      return items.sort(getCompareFn('insertDate', false, 'name'));
    case projectsSortingTypes.CREATE_DATE_DESC:
      return items.sort(getCompareFn('insertDate', true, 'name'));

    // no default
  }
};
