import { isEmpty } from 'lodash';
import { passwordMinCharCount } from '../../../../../constants/onboarding.constants';

export const isPasswordValid = (password) => (
  !isEmpty(password) && password.length >= passwordMinCharCount
);

export const isTeamSizeValid = (teamSize) => !isEmpty(teamSize);

export const isFormValid = (fieldFlags) => (
  Object.values(fieldFlags).every((field) => (field === true))
);
