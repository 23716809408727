import { connect } from 'react-redux';
import DesignLimitsInfoComponent from '../components/Topbar/components/DesignLimitsInfo';
import { openUpgradeModal } from '../../controllers/actions/upgradeModal.actions';
import { isAccountParked, isTrialPlanType } from '../../utils/account';

export const mapStateToProps = (state) => {
  const { totalDocumentsCount } = state.insideProject;
  const { accountPlan } = state.accountSettings;
  const { data } = state.currentUser;
  const isTrialPlan = !!(accountPlan && isTrialPlanType(accountPlan));
  const isParked = isAccountParked(data.idActiveAccount, data.accounts);

  return {
    designCount: totalDocumentsCount,
    allowDesignLimitsInfo: !isTrialPlan && !isParked,
    accountPlan,
  };
};

export const mapDispatchToProps = (dispatch) => ({
  openUpgradeModal: () => {
    dispatch(openUpgradeModal());
  },
});

export const DesignLimitsInfo = connect(mapStateToProps, mapDispatchToProps)(DesignLimitsInfoComponent);
