'use strict';

import { isEmpty, isUndefined } from 'lodash';

// eslint-disable-next-line complexity
export const validatorMessages = (validateMethod, options) => {
  let message = '';

  switch (validateMethod) {
    case 'ltrim':
      message = 'This field is required!';
      break;
    case 'isEmail':
      message = "This isn't a valid email!";
      break;
    case 'isMobilePhone':
      message = "This isn't a valid phone number!";
      break;
    case 'isLength':
      if (!isEmpty(options)) {
        if (options[0] === 1 && isUndefined(options[1])) {
          message = 'This field is required!';
        } else if (!isUndefined(options[0]) && !isUndefined(options[1])) {
          message = `This field must have between ${options[0]} and ${options[1]} characters!`;
        } else if (!isUndefined(options[0]) && isUndefined(options[1])) {
          message = `This field must have at least ${options[0]} characters!`;
        }
      }
      break;

    // no default
  }

  return message;
};
