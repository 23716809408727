/* global window, process, console */

'use strict';

import _ from 'lodash';
import { save, load } from './localStorage';

window.switchOnLogs = () => {
  if (process.env.NODE_ENV !== 'production') {
    save('areLogsSwitchedOn', true);
  }
};

window.switchOffLogs = () => {
  if (process.env.NODE_ENV !== 'production') {
    save('areLogsSwitchedOn', false);
  }
};

const execute = (type, ...args) => {
  /* eslint-disable no-console */
  if (process.env.NODE_ENV !== 'production' && console && _.isFunction(console[type])) {
    if (type === 'error' || (type !== 'error' && load('areLogsSwitchedOn'))) {
      console[type](...args);
      return true;
    }
  }
  /* eslint-enable */

  return false;
};

export default {
  log: (...args) => execute.apply(this, ['log', ...args]),
  error: (...args) => execute.apply(this, ['error', ...args]),
  warn: (...args) => execute.apply(this, ['warn', ...args]),
  info: (...args) => execute.apply(this, ['info', ...args]),
};
