import React from 'react';
import { Route } from 'react-router';
import config from 'react-global-configuration';
import { Layout } from './views/containers/Layout';
import NotFound from './views/components/Error/NotFound';
import { Projects } from './views/containers/Projects';
import { InsideProject } from './views/containers/InsideProject';
import { InactiveAccount } from './views/containers/InactiveAccount';
import { ModalboxRemoteHelper } from './views/containers/ModalboxRemoteHelper';
import { ModalboxLimitsHelper } from './views/containers/ModalboxLimitsHelper';
import { DesignSystems } from './views/containers/DesignSystems';
import { DesignSystemsCreate } from './views/containers/DesignSystemsCreate';
import { MergeTabContent } from './views/containers/MergeTabContent';
import ModalboxAddOnsHelper from './views/components/Modalboxes/ModalboxAddOnsHelper';
import routes from './constants/routes.constants';

export default (
  <Route>
    <Route path={config.get('BASE_URL')} component={Layout}>
      <Route path={routes.MERGE} component={MergeTabContent} />
      <Route path={routes.DESIGN_SYSTEMS_CREATE} component={DesignSystemsCreate} />
      <Route component={DesignSystems}>
        <Route path={routes.DESIGN_SYSTEMS} />
        <Route path={`${routes.DESIGN_SYSTEMS}/:limit`} />
      </Route>
      <Route path={routes.PROJECTS} component={Projects} />
      <Route path={routes.INACTIVE_ACCOUNT} component={InactiveAccount} />
      <Route path={routes.USER_LOCKED_OUT} component={InactiveAccount} />
      <Route path={`${routes.PROJECT}/:projectId`} component={InsideProject} />
      <Route component={Projects}>
        <Route path={`${routes.MODAL}/:modalId`} component={ModalboxRemoteHelper} />
        <Route path={`${routes.LIMITS_EXCEEDED}/:planType/:limit`} component={ModalboxLimitsHelper} />
        <Route path={`${routes.GET_ADD_ON}/:addOn`} component={ModalboxAddOnsHelper} />
      </Route>
    </Route>
    <Route status={404} path="*" component={NotFound} />
  </Route>
);
