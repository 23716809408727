'use strict';

import { currentUserActions } from '../../constants/currentUser.constants';

export function currentUserReducer(state = {
  data: {},
  isError: false,
  isFetchUserDataSuccess: false,
  isSwitchAccountSuccess: false,
  showTermsOfServiceModal: false,
  previousTabUri: '',
}, action) {
  switch (action.type) {
    case currentUserActions.FETCH_CURRENT_USER_DATA:
      return Object.assign({}, state, {
        data: action.data,
        isFetchUserDataSuccess: true,
      });
    case currentUserActions.SWITCH_USER_ACCOUNT:
      return Object.assign({}, state, {
        isSwitchAccountSuccess: true,
        previousTabUri: action.activeTabUri,
      });
    case currentUserActions.SWITCH_USER_ACCOUNT_ERROR:
      return Object.assign({}, state, {
        isError: true,
      });
    case currentUserActions.FETCH_TOS_MODAL_VISIBILITY:
      return Object.assign({}, state, {
        showTermsOfServiceModal: action.showTermsOfServiceModal,
      });
    default:
      return state;
  }
}
