import { connect } from 'react-redux';
import FileBoxComponent from '../components/Search/components/FileBox';
import { modalsIds } from '../../constants/modal.constants';
import * as modal from '../../controllers/actions/modal.actions';
import * as documents from '../../controllers/actions/document.actions';
import { search } from '../../controllers/actions/search.actions';
import * as slack from '../../controllers/actions/slack.actions';
import { openFeatureUpgrade } from '../../controllers/actions/pricing.actions';
import { projectsStatuses } from '../../constants/projects.constants';
import { FEATURES, FEATURE_STATUS } from '../../constants/features.constants';
import { isAccountParked } from '../../utils/account';

function getProjects(items) {
  let projects = [];

  items.forEach((group) => {
    projects = projects.concat(group.projects.slice(0));
  });

  return projects.filter((project) => project.status === projectsStatuses.ACTIVE);
}

export const mapStateToProps = (state) => {
  const { currentDocumentsActions, removingCounters } = state.documentActions;
  const { features } = state.features;
  const { items } = state.projects;
  const { commentsCount } = state.insideProject;
  const { channels } = state.slack;
  const { data } = state.currentUser;
  const { permissions: { has_access_to_editor: hasAccessToEditor } } = state.permissions;
  const { features: { approval_process: approvalProcess } } = state.features; // eslint-disable-line camelcase

  return {
    currentDocumentsActions,
    removingCounters,
    commentsCount,
    channels,
    features,
    isAccountParked: isAccountParked(data.idActiveAccount, data.accounts),
    isApprovalProcessEnabled: approvalProcess === FEATURE_STATUS.ENABLED,
    hasAccessToEditor: hasAccessToEditor && hasAccessToEditor.selected !== 'no',
    projectsList: getProjects(items),
  };
};

export const mapDispatchToProps = (dispatch) => ({
  openModalboxDocumentDelete: (document) => {
    dispatch(modal.openModal(modalsIds.MODALBOX_DOCUMENT_DELETE, document));
  },

  openModalboxDocumentChangeName: (document) => {
    dispatch(modal.openModal(modalsIds.MODALBOX_DOCUMENT_CHANGE_NAME, document));
  },

  openModalboxDocumentChangeProject: (document) => {
    dispatch(modal.openModal(modalsIds.MODALBOX_DOCUMENT_CHANGE_PROJECT, document));
  },

  openApprovalProcess: (document) => {
    dispatch(modal.openModal(modalsIds.APPROVAL_PROCESS, document));
  },

  getChannelsList: (idProject) => {
    dispatch(slack.getChannelsList(idProject));
  },

  openModalboxShare: (data) => {
    dispatch(modal.openModal(modalsIds.MODALBOX_SHARE, data));
  },

  removeDocumentPermanently: (idDocument) => {
    dispatch(documents.removeDocumentPermanently(idDocument));
    dispatch(search.removeDocumentFromSearchData(idDocument));
  },

  restoreDocument: (idDocument) => {
    dispatch(documents.restoreDocument(idDocument));
  },

  stopActionOnDocument: (idDocument) => {
    dispatch(documents.stopActionOnDocument(idDocument));
  },

  duplicateDocument: (idDocument) => {
    dispatch(documents.duplicateDocument(idDocument));
  },

  requestCheckingDuplicationStatus: (idDocument) => {
    dispatch(documents.requestCheckingDuplicationStatus(idDocument));
  },

  openFeatureUpgrade: () => {
    dispatch(openFeatureUpgrade(FEATURES.APPROVAL_PROCESS));
  },
});

export const FileBox = connect(mapStateToProps, mapDispatchToProps)(FileBoxComponent);
