/* eslint-disable max-len */
/* eslint-disable react/no-find-dom-node */
// Fix scrolling during dnd for safari and FF
/* global document */

'use strict';

import ReactDOM from 'react-dom';
import { isFirefox, isSafari } from '@uxpin/shared-components';

let container;
let containerBoundingRect;
let oldYPos;
let direction;
const SCROLL_AREA_HEIGHT = 60;
const UP = -1;
const DOWN = 1;

export const getBoxTarget = (scrollContainerSelector = null, scrollAreaHeight = SCROLL_AREA_HEIGHT) => ({
  drop() {
    container = null;
  },

  // eslint-disable-next-line complexity
  hover(props, monitor, component) {
    if (isFirefox || isSafari) {
      if (monitor.isOver({ shallow: true })) {
        container = null;
        return;
      }

      if (!container) {
        if (scrollContainerSelector) {
          container = document.querySelector(scrollContainerSelector);
        } else {
          container = ReactDOM.findDOMNode(component);
        }

        containerBoundingRect = container.getBoundingClientRect();
      } else {
        const clientOffset = monitor.getClientOffset();

        if (oldYPos > clientOffset.y) {
          direction = UP;
        } else if (oldYPos < clientOffset.y) {
          direction = DOWN;
        }

        const maxScrollPosition = container.scrollHeight - containerBoundingRect.height;
        if (clientOffset.y < containerBoundingRect.top + scrollAreaHeight && container.scrollTop > 0 && direction === UP) {
          container.scrollTop -= Math.abs(clientOffset.y - containerBoundingRect.top - scrollAreaHeight) / 4;
        } else if (clientOffset.y > containerBoundingRect.bottom - scrollAreaHeight && container.scrollTop <= maxScrollPosition && direction === DOWN) {
          container.scrollTop += Math.abs(containerBoundingRect.bottom - clientOffset.y - scrollAreaHeight) / 4;
        }

        oldYPos = clientOffset.y;
      }
    }
  },
});
