/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */

'use strict';

import React from 'react';
import autobind from 'autobind-decorator';

export default class StepDeleted extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      time: 0,
    };

    this.timeout = null;
  }

  @autobind
  undo(e) {
    const { undoStage, index } = this.props;
    e.preventDefault();
    e.stopPropagation();
    undoStage(index);
  }

  @autobind
  count() {
    const {
      countTime, deleteStage, index, time,
    } = this.props;

    this.setState({
      time,
    }, () => {
      if (time === 0) {
        clearTimeout(this.timeout);
        deleteStage(index);
      } else {
        this.timeout = setTimeout(() => {
          countTime(index);
          this.count();
        }, 1000);
      }
    });
  }

  @autobind
  removeStage(e) {
    e.preventDefault();
    const { deleteStage, index } = this.props;

    deleteStage(index);
    this.setState({
      time: 0,
    });
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.shouldStart && nextProps.shouldStart && nextProps.time) {
      this.timeout = setTimeout(this.count);
    } else if ((this.props.shouldStart && !nextProps.shouldStart) || nextProps.time === 0) {
      clearTimeout(this.timeout);
    }
  }

  render() {
    const { stageName } = this.props;
    return (
      <section className="deleted-item-info">
        <p className="remaining-time hidden-info">
          {this.state.time}
s
        </p>
        <h5 className="info">
Step "
          <span className="item-name">{stageName}</span>
" has been deleted.&nbsp;
          <a onClick={this.undo} href="#undo-delete" className="blue-link underline-link">Undo.</a>
          <a
            onClick={this.removeStage}
            href="#execute"
            title="Execute"
            className="execute icon-general-close only-icon-font">
            Execute
          </a>
        </h5>
      </section>
    );
  }
}
