/* eslint-disable max-len */

import React from 'react';
import PropTypes from 'prop-types';
import autobind from 'autobind-decorator';
import { noop } from 'lodash';
import { Modal } from '@uxpin/shared-components';
import { modalsIds } from '../../../../constants/modal.constants';
import { preventDefaultEvent } from '../../../../utils/common';

export default class ModalboxAddOnsFollowUpSuccessComponent extends React.Component {
  constructor(props) {
    super(props);

    this.closeHelper = noop;
    this.MODAL = {
      WRAPPER_ID: modalsIds.MODALBOX_ADD_ONS_FOLLOW_UP_SUCCESS,
      CLASS_NAMES: 'marketing-promo-modal-box no-image',
    };
  }

  @autobind
  onClose(e) {
    preventDefaultEvent(e);
    this.closeHelper.hideModal();
    // eslint-disable-next-line react/destructuring-assignment
    this.props.onClose();
  }

  renderContent() {
    const { addOnData, isMonthly } = this.props;
    const { addOnName, name } = addOnData;

    if (isMonthly) {
      const newPrice = addOnData.totalPeriodPrice;
      const newTotalPrice = addOnData.totalPeriodPrice * addOnData.quantity;

      return (
        <div>
          <p>
            Congrats! You&#39;re now subscribed to {name} with {addOnName}.
          </p>
          <p>
            You&#39;ll be charged ${newPrice} (+ VAT if applicable) per user each month. Your new monthly price is ${newTotalPrice}.
          </p>
        </div>
      );
    }

    return (
      <p>
        Congrats! You&#39;re now subscribed to {name} with {addOnName}.
      </p>
    );
  }

  render() {
    const { isOpen } = this.props;

    if (!isOpen) {
      return null;
    }

    return (
      <Modal
        ref={(c) => { this.closeHelper = c; }}
        wrapperId={this.MODAL.WRAPPER_ID}
        classNames={this.MODAL.CLASS_NAMES}
        title="Your plan is updated!"
        isOpen={isOpen}
        onClose={this.onClose}>
        <section className="modal-box-content">
          {this.renderContent()}
        </section>
        <footer>
          <ul className="btns-group">
            <li>
              <button
                type="button"
                className="btn-solid btn blue"
                onClick={this.onClose}>
Ok
              </button>
            </li>
          </ul>
        </footer>
      </Modal>
    );
  }
}

ModalboxAddOnsFollowUpSuccessComponent.propTypes = {
  addOnData: PropTypes.object.isRequired,
  isMonthly: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
};

ModalboxAddOnsFollowUpSuccessComponent.defaultProps = {
  isMonthly: true,
  onClose: noop,
};
