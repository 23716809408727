'use strict';

import React from 'react';
import { Avatar, UserName } from '@uxpin/shared-components';
import moment from 'moment';
import DateTime from '../../DateTime/DateTime';

export default class ActivityEvent extends UserName {
  renderAvatar() {
    const { data } = this.props;

    if (data.user.type === 'approval_request') {
      const approvalStatus = data.user.approvalStatus === 'approved' ? 'approved' : 'rejected';

      return (
        <figure className={`approval-status ${approvalStatus}`}>
          Approval status
        </figure>
      );
    }

    if (data.user.initials) {
      const initialsLetters = data.user.initials.split(' ');

      if (!data.user.firstname) {
        data.user.firstname = initialsLetters[0];
      }

      if (!data.user.lastname) {
        data.user.lastname = initialsLetters[1];
      }
    }

    return <Avatar user={data.user} />;
  }

  render() {
    const { data } = this.props;
    // remove all tags except for anchor tag from previous entries to prevent XSS
    const sanitizeDataTemplate = data.template.replace(/<(?!\/?a(?=>|\s.*>))\/?.*?>/g, '');

    return (
      <li>
        {this.renderAvatar()}
        <aside className="event-description">
          <div className="overflow-helper" dangerouslySetInnerHTML={{ __html: sanitizeDataTemplate }} />
          <DateTime
            className="timestamp"
            date={moment(data.insertDate).local().toDate()}
            showFromNow
            fromNowDaysLimit={14}
          />
        </aside>
      </li>
    );
  }
}
