import { connect } from 'react-redux';
import SortComponent from '../components/ProjectBar/components/Sort';
import { setSortType } from '../../controllers/actions/insideProject.actions';

export const mapStateToProps = (state) => {
  const { sortType } = state.insideProject;

  return {
    sortType,
  };
};

export const mapDispatchToProps = (dispatch) => ({
  setSortType: (sortType) => {
    dispatch(setSortType(sortType));
  },
});

export const Sort = connect(mapStateToProps, mapDispatchToProps)(SortComponent);
