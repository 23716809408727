import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { SCALE, Button } from '@uxpin/design-system';
import SelectBox from './components/SelectBox';
import { preventDefaultEvent } from '../../../utils/common';
import { getMixpanel } from '../../../../../shared/mixpanel';
import MultiSelectBox from './components/MultiSelectBox';

const roleDataComponentData = {
  label: 'What best describes your role?',
  name: 'onboard-role',
};

const useCasesComponentData = {
  label: 'What is your primary purpose for using UXPin? You can select more than 1 option.',
  name: 'onboard-primary-use-cases',
};

const designSysComponentData = {
  label: 'Do you have a Design System?',
  name: 'onboard-design-sys',
};

const intendedUsesRoleData = [
  {
    key: 'developer',
    text: 'Developer',
    iconFilename: 'intended-uses/developer_colored.svg',
  },
  {
    key: 'designer',
    text: 'Designer',
    iconFilename: 'intended-uses/designer_colored.svg',
  },
  {
    key: 'r_other',
    text: 'Other',
    iconFilename: 'intended-uses/other_colored.svg',
  },
];

const intendedUsesDesignSysData = [
  {
    key: 'ds-yes',
    text: 'Yes',
    iconFilename: 'intended-uses/yes_colored.svg',
  },
  {
    key: 'ds-no',
    text: 'No',
    iconFilename: 'intended-uses/no_colored.svg',
  },
  {
    key: 'ds-opensrc',
    text: 'I use open-source',
    iconFilename: 'intended-uses/open_src_colored.svg',
  },
];

const intendedUsesPrimaryData = [
  {
    key: 'prototyping-ds',
    text: 'Prototyping & Design Systems',
  },
  {
    key: 'templates-patterns',
    text: 'Built-in templates & patterns',
  },
  {
    key: 'drag-and-drop-ui',
    text: 'Drag-and-drop UI coded components',
  },
  {
    key: 'code-to-design',
    text: 'Code to Design workflow',
  },
  {
    key: 'u_other',
    text: 'Other',
  },
];

export class OnboardingIntendedUsesComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedRole: '',
      selectedPrimaryUses: [],
      selectedDesignSystem: '',
      inputRoleOther: '',
      inputPrimaryUseOther: '',
      isSaving: false,
    };
  }

  handleRoleSelection = (roleKey) => {
    this.setState({ selectedRole: roleKey });
  }

  handlePrimaryUseSelection = (useKey) => {
    this.setState((prevState) => {
      const { selectedPrimaryUses } = prevState;
      if (selectedPrimaryUses.includes(useKey)) {
        return { selectedPrimaryUses: selectedPrimaryUses.filter((key) => key !== useKey) };
      }
      return { selectedPrimaryUses: [...selectedPrimaryUses, useKey] };
    });
  }

  handleDesignSystemSelection = (systemKey) => {
    const {setShowTypeformSurvey} = this.props

    if(systemKey === 'ds-yes' || systemKey === 'ds-opensrc'){
      setShowTypeformSurvey(true);
    }else{
      setShowTypeformSurvey(false);
    }
    this.setState({ selectedDesignSystem: systemKey });
  }

  handleInputChange = (event, inputType) => {
    this.setState({ [inputType]: event.target.value });
  }

  handleSubmit = async (event) => {
    preventDefaultEvent(event);
    const { saveSelectedIntendedUses, setNextStep } = this.props;
    const {
      selectedRole,
      selectedPrimaryUses,
      selectedDesignSystem,
      inputRoleOther,
      inputPrimaryUseOther,
    } = this.state;

    this.setState({ isSaving: true });

    // Prepare the data to be saved, including "Other" inputs if necessary
    const roleToSave = selectedRole === 'r_other' ? (`other - ${inputRoleOther}`) : selectedRole;
    const primaryUsesToSave = selectedPrimaryUses.includes('u_other')
      ? selectedPrimaryUses.filter((use) => use !== 'u_other').concat(`other - ${inputPrimaryUseOther}`)
      : selectedPrimaryUses;

    // Dispatch the save action
    await saveSelectedIntendedUses(roleToSave, primaryUsesToSave, selectedDesignSystem);

    // Tracking and user profile updates
    this.trackEvent('onboarding_completed', {
      trial_user_role: roleToSave,
      intended_uses: primaryUsesToSave,
      design_system: selectedDesignSystem,
    });

    this.setUserProfileProperty({
      trial_user_role: roleToSave,
      intended_uses: primaryUsesToSave,
      design_system: selectedDesignSystem,
    });

    this.setState({ isSaving: false });

    setNextStep();
  }

  isNextButtonEnabled = () => {
    const {
      selectedRole,
      selectedPrimaryUses,
      selectedDesignSystem,
      inputRoleOther,
      inputPrimaryUseOther,
    } = this.state;
    const isRoleSelected = selectedRole && (selectedRole !== 'r_other' || inputRoleOther);
    const isPrimaryUsesSelected = selectedPrimaryUses.length > 0
      && (!selectedPrimaryUses.includes('u_other') || inputPrimaryUseOther);
    const isDesignSystemSelected = selectedDesignSystem !== '';

    return isRoleSelected && isPrimaryUsesSelected && isDesignSystemSelected;
  }

  renderRoleOptions(componentData) {
    const { selectedRole, inputRoleOther } = this.state;
    return (
      <div className="options-box">
        <p className="text">{componentData.label}</p>
        <section className="option-list">
          {intendedUsesRoleData.map((role) => (
            <SelectBox
              key={role.key}
              value={role.key}
              text={role.text}
              selected={selectedRole === role.key}
              onClick={this.handleRoleSelection}
              iconFilename={role.iconFilename}
              optionName={componentData.name}
            />
          ))}
        </section>
        {selectedRole === 'r_other' && (
          <input
            type="text"
            value={inputRoleOther}
            onChange={(e) => this.handleInputChange(e, 'inputRoleOther')}
            placeholder="Tell us more"
          />
        )}
      </div>
    );
  }


  renderPrimaryUseOptions(componentData) {
    const { selectedPrimaryUses, inputPrimaryUseOther } = this.state;
    return (
      <div className="options-box">
        <p className="text">{componentData.label}</p>
        <section className="option-list-multiselect">
          {intendedUsesPrimaryData.map((use) => (
            <MultiSelectBox
              key={use.key}
              value={use.key}
              text={use.text}
              selected={selectedPrimaryUses.includes(use.key)}
              onClick={this.handlePrimaryUseSelection}
            />
          ))}
        </section>
        {selectedPrimaryUses.includes('u_other') && (
          <input
            type="text"
            value={inputPrimaryUseOther}
            onChange={(e) => this.handleInputChange(e, 'inputPrimaryUseOther')}
            placeholder="Tell us more"
          />
        )}
      </div>
    );
  }

  renderDesignSystemOptions(componentData) {
    const { selectedDesignSystem } = this.state;
    return (
      <div className="options-box">
        <p className="text">{componentData.label}</p>
        <section className="option-list">
          {intendedUsesDesignSysData.map((system) => (
            <SelectBox
              key={system.key}
              value={system.key}
              text={system.text}
              selected={selectedDesignSystem === system.key}
              onClick={this.handleDesignSystemSelection}
              iconFilename={system.iconFilename}
              optionName={componentData.name}
            />
          ))}
        </section>
      </div>
    );
  }

  render() {
    const { isSaving } = this.state;
    return (
      <div className="onboarding-content onboarding-options" data-test="on-boarding-tools">
        <h2 className="header">Let’s get started!</h2>
        {this.renderRoleOptions(roleDataComponentData)}
        {this.renderPrimaryUseOptions(useCasesComponentData)}
        {this.renderDesignSystemOptions(designSysComponentData)}
        <section className="action-container">
          <Button
            className="btn-next"
            onClick={this.handleSubmit}
            isWorking={isSaving}
            isDisabled={!this.isNextButtonEnabled()}
            size={SCALE.L}>Next
          </Button>
        </section>
      </div>
    );
  }

  // TODO: this method should be extracted and shared between components?
  trackEvent(eventName, eventData) {
    getMixpanel((mixpanel) => {
      mixpanel.track(eventName, eventData);
    });
  }

  // TODO: this method should be extracted and shared between components?
  setUserProfileProperty(profileProperties) {
    getMixpanel((mixpanel) => {
      mixpanel.people.set(profileProperties);
    });
  }
}

OnboardingIntendedUsesComponent.propTypes = {
  setNextStep: PropTypes.func,
  saveSelectedIntendedUses: PropTypes.func.isRequired,
  setShowTypeformSurvey: PropTypes.func,
};

OnboardingIntendedUsesComponent.defaultProps = {
  setNextStep: noop,
  setShowTypeformSurvey: noop,
};
