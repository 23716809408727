
'use strict';

import { activityFeedActions } from '../../constants/activityFeed.constants';
import { request } from '../../utils/request';

let currentPage = 1;

const transformFeed = (data, page) => {
  if (data && data.feed) {
    data.feed.forEach((feed) => {
      Object.assign(feed, { page });
    });
  }

  return data;
};

const requestFetchingActivityFeed = () => ({
  type: activityFeedActions.REQUEST_FETCHING_ACTIVITY_FEED_DATA,
});

export const resetActivityFeedData = () => {
  currentPage = 1;
  return {
    type: activityFeedActions.RESET_ACTIVITY_FEED_DATA,
  };
};

export const refreshActivityFeed = (idProject, stopAutoRefresingOnError = false) => (dispatch) => {
  request.get(`/project/${idProject}/feed/1`)
    .then((data) => {
      dispatch({
        type: activityFeedActions.REFRESH_ACTIVITY_FEED_DATA,
        data: transformFeed(data, 1),
        currentPage,
      });
      return data;
    })
    .catch(() => {
      if (stopAutoRefresingOnError) {
        dispatch({
          type: activityFeedActions.STOP_REFRESHING_ACTIVITY_FEED_DATA,
        });
      }
    });
};

export const getActivityFeed = (idProject) => (dispatch) => {
  dispatch(requestFetchingActivityFeed());
  const oldPage = currentPage;
  request.get(`/project/${idProject}/feed/${currentPage}`)
    .then((data) => {
      currentPage += 1;
      dispatch({
        type: activityFeedActions.FETCH_ACTIVITY_FEED_DATA,
        data: transformFeed(data, oldPage),
      });
      return data;
    })
    .catch(() => {
      // TODO HANDLE ERROR
    });
};
