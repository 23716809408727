'use strict';

import { request } from '../../utils/request';
import { marketingActions } from '../../constants/marketing.constants';

export const openAddOnUpgradeModal = () => ({
  type: marketingActions.OPEN_ADD_ON_SPEC_MODE_UPGRADE_MODAL,
});

export const closeAddOnUpgradeModal = () => ({
  type: marketingActions.CLOSE_ADD_ON_SPEC_MODE_UPGRADE_MODAL,
});

export const getAddOnInfo = (addOnCode) => (dispatch) => request.get(`/plans/add_on/${addOnCode}`)
  .then((data) => {
    dispatch({
      type: marketingActions.FETCH_ADD_ON_DATA,
      data,
    });
    return data;
  })
  .catch((e) => {
    console.log(e); // eslint-disable-line no-console
  });

export const applyAddOn = (addOnCode) => (dispatch) => request.post('/plans/add_on/apply', {
  body: {
    addOnCode,
  },
})
  .then((data) => {
    dispatch({
      type: marketingActions.APPLY_ADD_ON,
      data,
    });
    return data;
  })
  .catch((e) => {
    console.log(e); // eslint-disable-line no-console
  });
