import { connect } from 'react-redux';
import StakeholderViewComponent from '../../../components/ApprovalProcess/components/StakeholdersView';
import * as stakeholders from '../../../../controllers/actions/stakeholders.actions';

export const mapStateToProps = () => ({});

export const mapDispatchToProps = (dispatch) => ({
  countTimeToRemoveStakeholder: (id) => {
    dispatch(stakeholders.countTimeToRemoveStakeholder(id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(StakeholderViewComponent);
