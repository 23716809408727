/* eslint-disable react/destructuring-assignment */

'use strict';

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Cloud, GLOBAL_ELEMENTS } from '@uxpin/shared-components';
import { InlineEdit } from '@uxpin/design-system';
import autobind from 'autobind-decorator';
import SelectStatus from '../../SelectClouds/SelectStatus';
import { statusIdToClassMap, projectStatuses } from '../../../../constants/projects.constants';
import * as localStorage from '../../../../utils/localStorage';
import { StarButton } from '../../StarButton/StarButton';
import { insideProjectViewModes } from '../../../../constants/insideProject.constants';

const DOUBLE_CLICK_DELAY = 250;

export class ProjectBoxHeader extends React.Component {
  @autobind
  componentDidUpdate() {
    this.props.setOpen(this.props.isOpen);
  }

  @autobind
  setCurrentStatus(currentStatus) {
    const { setDocumentStatus, document } = this.props;
    setDocumentStatus(document, currentStatus);
  }

  @autobind
  saveDocumentName(documentName) {
    const { renameDocument, updateDocumentName, document } = this.props;

    if (document.isChromeExtensionUpload) {
      const savedFilesNames = localStorage.load('files') || {};
      savedFilesNames[document.fileId] = documentName;
      localStorage.save('files', savedFilesNames);
      updateDocumentName(document.idDocument, documentName);
    } else {
      renameDocument(document.idDocument, documentName);
    }
  }

  @autobind
  handleTitleClick(e) {
    if (e) {
      e.preventDefault();
    }

    if (this.titleClickTimer) {
      clearTimeout(this.titleClickTimer);
    }

    if (!this.editingTitile) {
      this.titleClickTimer = setTimeout(() => this.goToEditor(), DOUBLE_CLICK_DELAY);
    }
  }

  @autobind
  handleTitleDoubleClick() {
    clearTimeout(this.titleClickTimer);
    this.editingTitile = true;
  }

  @autobind
  handleTitleEditBlur(...args) {
    this.editingTitile = false;
    this.props.handleInlineEditBlur(...args);
  }

  goToEditor() {
    const {
      document, editUrl, setDocumentWorkingState, toggleButtonsWorkingStates,
    } = this.props;

    setDocumentWorkingState(document.idDocument);
    toggleButtonsWorkingStates(true, false);
    window.location.href = editUrl;
  }

  renderDocumentStatus() {
    const {
      project,
      document,
      canAddEditDeleteProjectStatus,
      isProjectStatusEnabled,
      insideProjectViewMode,
      toggleStar,
      isStarred,
    } = this.props;

    if (!project.hasProgressInfo || !isProjectStatusEnabled) {
      return insideProjectViewMode !== insideProjectViewModes.LIST
        && (
          <div className="star-button-container">
            <StarButton toggleStar={toggleStar} isStarred={isStarred} />
          </div>
        );
    }

    if (!canAddEditDeleteProjectStatus) {
      return (
        <div className="project-status">
          <p
            title={document.statusName}
            className="status only-icon"
            data-status={statusIdToClassMap[document.idStatus]}>
            {document.statusName}
          </p>
        </div>
      );
    }

    return (
      // eslint-disable-next-line react/prop-types
      <Fragment>
        <div {...this.props.trigger} className="project-status">
          <a
            href="#status"
            className="status only-icon"
            data-status={statusIdToClassMap[document.idStatus]}
            data-show-node="cloud">
            {document.statusName}
          </a>
          <SelectStatus
            {...this.props.cloud}
            setCurrentStatus={this.setCurrentStatus}
            currentStatusId={document.idStatus}
            projectStatuses={projectStatuses}
          />
        </div>
        {insideProjectViewMode !== insideProjectViewModes.LIST
          && (
            <div className="star-button-container">
              <StarButton toggleStar={toggleStar} isStarred={isStarred} />
            </div>
          )
        }
      </Fragment>
    );
  }

  renderHeader() {
    const {
      document,
      editUrl,
      handleInlineEditFocus,
      hasAccessToEditor,
      isProjectStatusEnabled,
      project,
      insideProjectViewMode,
      toggleStar,
      isStarred,
    } = this.props;

    const headingClasses = classnames('name', GLOBAL_ELEMENTS.HEADING_3);
    const linkClasses = classnames('box-header-link', {
      'with-document-status-trigger': !(!project.hasProgressInfo || !isProjectStatusEnabled),
    });
    const style = !hasAccessToEditor ? { pointerEvents: 'none' } : null;

    return (
      // eslint-disable-next-line max-len
      <Fragment>
        {insideProjectViewMode === insideProjectViewModes.LIST
          && (
            <div className="star-button-container">
              <StarButton toggleStar={toggleStar} isStarred={isStarred} />
            </div>
          )
        }
        <a
          className={linkClasses}
          href={editUrl}
          onClick={this.handleTitleClick}
          onDoubleClick={this.handleTitleDoubleClick}>
          <h3 className={headingClasses} style={style}>
            <InlineEdit
              title={document.name}
              onChange={this.saveDocumentName}
              onEditStart={handleInlineEditFocus}
              onEditFinish={this.handleTitleEditBlur}>
              {document.name}
            </InlineEdit>
          </h3>
        </a>
      </Fragment>
    );
  }

  render() {
    return (
      <div className="box-header-content">
        {this.renderHeader()}
        {this.renderDocumentStatus()}
      </div>
    );
  }
}

export default Cloud()(ProjectBoxHeader);

ProjectBoxHeader.propTypes = {
  // eslint-disable-next-line react/require-default-props
  cloud: PropTypes.object,
  canAddEditDeleteProjectStatus: PropTypes.bool,
  document: PropTypes.shape({
    fileId: PropTypes.number,
    idDocument: PropTypes.number.isRequired,
    idStatus: PropTypes.number,
    isChromeExtensionUpload: PropTypes.bool,
    name: PropTypes.string.isRequired,
    statusName: PropTypes.string,
  }).isRequired,
  editUrl: PropTypes.string.isRequired,
  handleInlineEditFocus: PropTypes.func.isRequired,
  handleInlineEditBlur: PropTypes.func.isRequired,
  hasAccessToEditor: PropTypes.bool.isRequired,
  insideProjectViewMode: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isProjectStatusEnabled: PropTypes.bool,
  isStarred: PropTypes.bool.isRequired,
  project: PropTypes.object.isRequired,
  renameDocument: PropTypes.func.isRequired,
  setDocumentStatus: PropTypes.func.isRequired,
  setDocumentWorkingState: PropTypes.func.isRequired,
  setOpen: PropTypes.func.isRequired,
  toggleButtonsWorkingStates: PropTypes.func.isRequired,
  toggleStar: PropTypes.func.isRequired,
  updateDocumentName: PropTypes.func.isRequired,
};

ProjectBoxHeader.defaultProps = {
  canAddEditDeleteProjectStatus: false,
  isProjectStatusEnabled: false,
};
