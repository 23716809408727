'use strict';

export default (() => ({
  SEARCH_NAVIGATION_EVENT: 'search_navigation_event',
  NEXT_SEARCH_SECTION: 'next_search_section',
  PREV_SEARCH_SECTION: 'prev_search_section',
  INACTIVE_SEARCH_SECTION_EVENT: 'inactive_search_section_event',
  ACTIVE_SEARCH_SECTION_EVENT: 'active_search_section_event',
  DELETE_GROUP: 'DELETE_GROUP',
}))();
