/* eslint-disable react/destructuring-assignment,no-shadow */
/* eslint-disable react/prop-types */

'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import autobind from 'autobind-decorator';
import classnames from 'classnames';
import _ from 'lodash';
import { GLOBAL_ELEMENTS } from '@uxpin/shared-components';
import { DropTarget } from 'react-dnd';

import { ProjectOnList } from '../../../containers/ProjectOnList';
import ProjectEmpty from './ProjectEmpty';
import GroupColor from '../../Helpers/GroupColor';
import { NewProjectBtn } from '../../../containers/NewProjectBtn';
import { groupTypes } from '../../../../constants/projectsGroupsManagement.constants';
import { sortableItemsTypes } from '../../../../constants/sortableItems.constants';
import { isItemIncluded } from '../../../../controllers/actions/settings.actions';

let hoveredGroupId;
const boxTarget = {
  drop(props, monitor) {
    const {
      // eslint-disable-next-line max-len
      moveProjectsByDnd, moveProjectByDnd, id, projectGroupId, type, name, selectedProjects, totalDocumentsCount, isFreePlan, clearSelectedProjects,
    } = props;
    const draggedProject = monitor.getItem();

    if (!_.isEmpty(selectedProjects) && _.size(selectedProjects) > 1) {
      moveProjectsByDnd(projectGroupId, type, name, selectedProjects, totalDocumentsCount, isFreePlan);
    } else if (id !== draggedProject.projectGroupIndex) {
      moveProjectByDnd(projectGroupId, type, name, draggedProject, totalDocumentsCount, isFreePlan);
    }

    clearSelectedProjects();

    hoveredGroupId = null;
  },

  hover(props, monitor) {
    if (props.id !== hoveredGroupId && props.id !== monitor.getItem().projectGroupIndex) {
      hoveredGroupId = props.id;
    } else if (props.id === monitor.getItem().projectGroupIndex) {
      hoveredGroupId = null;
    }
  },
};

export class ProjectsGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isExpanded: true,
    };

    this.CLASSES = {
      EMPTY: 'empty',
      ARCHIVED: 'archived',
      EXPANDED: 'expanded',
      UNGROUPED: 'ungrouped',
    };
  }

  componentWillMount() {
    this.setState({
      isExpanded: this.props.isExpanded,
    });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.isExpanded !== nextProps.isExpanded) {
      this.setState({
        isExpanded: nextProps.isExpanded,
      });
    }
  }

  @autobind
  onChange() {
    const {
      id, type, projectGroupId, onCollapsing, onExpanding, saveProjectsGroupsExpandedMode,
    } = this.props;
    // eslint-disable-next-line react/no-access-state-in-setstate
    const isExpanded = !this.state.isExpanded;

    this.setState({
      isExpanded,
    });

    if (isExpanded) {
      onExpanding(id);
    } else {
      onCollapsing(id);
    }

    saveProjectsGroupsExpandedMode(type || projectGroupId, isExpanded);
  }

  getProjectGroupClasses() {
    const {
      type, id, allProjects, isOver, refreshedLayout,
    } = this.props;
    const classes = [];
    if (!allProjects[id].projects.length) {
      classes.push(this.CLASSES.EMPTY);
    }

    if (type === groupTypes.ARCHIVED) {
      classes.push(this.CLASSES.ARCHIVED);
    }

    if (this.state.isExpanded) {
      classes.push(this.CLASSES.EXPANDED);
    }

    if (type === groupTypes.UNGROUPED) {
      classes.push(this.CLASSES.UNGROUPED);
    }

    if (refreshedLayout) {
      classes.push('projects-group-left-name');
    }

    return classnames(classes, { 'dragging-target': hoveredGroupId === id && isOver });
  }

  getCollapseListHeaderClasses() {
    return classnames('collapse-list-header', GLOBAL_ELEMENTS.HEADING_2);
  }

  getGroupColor() {
    const { type, color } = this.props;

    if ([groupTypes.ARCHIVED, groupTypes.UNGROUPED].indexOf(type) !== -1) {
      return;
    }

    return `#${color}`;
  }

  shouldComponentUpdate(nextProps, nextState) {
    return Object.keys(this.props).some((propName) => !_.isEqual(this.props[propName], nextProps[propName]))
      || Object.keys(this.state).some((propName) => !_.isEqual(this.state[propName], nextState[propName]));
  }

  renderDraggingTargetText() {
    const { name, id, isOver } = this.props;

    if (hoveredGroupId === id && isOver) {
      return (
        <span className="dragging-target-name">
          Move project into <strong>{name}</strong>
        </span>
      );
    }
  }

  renderBody() {
    const {
      id,
      type,
      projects,
      allProjects,
      handleInlineEditFocus,
      favsProjects,
      filterType,
    } = this.props;
    const emptyProject = [];

    if (!this.state.isExpanded) {
      return;
    }

    if (!allProjects[id].projects.length) {
      emptyProject.push(<ProjectEmpty key={0} isArchived={(type === groupTypes.ARCHIVED)} />);
      if (type !== groupTypes.ARCHIVED) {
        emptyProject.push(<NewProjectBtn
          key={1}
          idProjectGroup={id}
          isUngroupedProjectsGroup={type === groupTypes.UNGROUPED}
          containerClassName="add-first-project"
          classes={['add-first-project', 'btn-wide']}
          label="Start by creating your first project"
        />);
      }
    }

    const projectGroupIndex = id;
    const idProjectGroup = allProjects[id].id;

    const filteredProjects = projects.filter((project) => isItemIncluded(project.idProject, favsProjects, filterType));

    return (
      <div className="collapse-list-body">
        {filteredProjects.map((project, id) => (
          <ProjectOnList
            // eslint-disable-next-line react/no-array-index-key
            key={id}
            projectGroupIndex={projectGroupIndex}
            idProjectGroup={idProjectGroup}
            groupType={type}
            data={project}
            handleInlineEditFocus={handleInlineEditFocus}
            color={this.getGroupColor()}
          />
        ))}
        {emptyProject}
      </div>
    );
  }

  renderName(name) {
    return (
      <span className="ellipsis-helper">{name}</span>
    );
  }

  renderNameWithLine(name) {
    return (
      <span className="vertical-line-helper">
        <span className="ellipsis-helper">{name}</span>
      </span>
    );
  }

  render() {
    const { id, name, connectDropTarget, refreshedLayout } = this.props;
    const collapseListHeaderClasses = classnames('collapse-list-header', GLOBAL_ELEMENTS.HEADING_2,
      { 'collapse-list-header-left': refreshedLayout });
    const labelClasses = classnames('icon-helper', 'icon-general-chevron', { 'icon-helper-left': refreshedLayout });

    return connectDropTarget(
      <div className={`projects-group tab-wrapper ${this.getProjectGroupClasses()}`}>
        <input type="checkbox" id={`group-${id}`} checked={this.state.isExpanded} onChange={this.onChange} />
        {refreshedLayout ? null : <GroupColor color={this.getGroupColor()} /> }
        <h2 className={collapseListHeaderClasses}>
          <label htmlFor={`group-${id}`} className={labelClasses}>
            {refreshedLayout ? this.renderName(name) : this.renderNameWithLine(name)}
          </label>
        </h2>
        {this.renderBody()}
        {this.renderDraggingTargetText()}
      </div>
    );
  }
}

ProjectsGroup.propTypes = {
  allProjects: PropTypes.array.isRequired,
  onCollapsing: PropTypes.func,
  onExpanding: PropTypes.func,
};

ProjectsGroup.defaultProps = {
  onCollapsing: _.noop,
  onExpanding: _.noop,
};

export default DropTarget(sortableItemsTypes.PROJECT, boxTarget, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  hoveredGroupId,
  isOver: monitor.isOver({ shallow: true }),
}))(ProjectsGroup);
