import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, SCALE } from '@uxpin/design-system';

const OnboardingAddUsersConfirmation = ({
  addedUsersCount,
  addUsersError,
  setOnboardingFlowAsSeen,
  setPreviousStep,
  sendTrackingEvent,
}) => {
  const textMap = {
    fail: {
      title: 'Failed to add users',
      // eslint-disable-next-line max-len
      messageText: 'Oops! Something went wrong while trying to add users. Please try again or contact support for assistance.',
      backText: 'Invite users',
    },
    success: {
      title: `You added ${addedUsersCount} user${addedUsersCount !== 1 ? 's' : ''}`,
      messageText: 'A newly added user has access to your team account.',
      backText: 'Invite more users',
    },
    none: {
      title: '',
      messageText: '',
      backText: 'Invite users',
    },
  };

  const handleClose = () => {
    sendTrackingEvent();
    setOnboardingFlowAsSeen(true);
  };

  const handleBack = () => {
    setPreviousStep();
  };

  const renderMessageModal = (title, messageText, backText) => (
    <>
      <div className="onboarding-background" />
      <div className="onboarding-add-confirm">
        <h2>{title}</h2>
        <p>{messageText}</p>
        <section className="action-container-add">
          <button className="underline-link btn-skip" type="button" onClick={handleBack}>{backText}</button>
          <Button
            className="btn-close"
            onClick={handleClose}
            isWorking={false}
            isDisabled={false}
            size={SCALE.L}>Close
          </Button>
        </section>
      </div>
    </>
  );

  if (addUsersError) {
    return renderMessageModal(textMap.fail.title, textMap.fail.messageText, textMap.fail.backText);
  }

  return renderMessageModal(textMap.success.title, textMap.success.messageText, textMap.success.backText);
};

const mapStateToProps = (state) => ({
  addedUsersCount: state.accountSettings.addedUsersCount,
  addUsersError: state.accountSettings.addUsersError,
});

OnboardingAddUsersConfirmation.propTypes = {
  addedUsersCount: PropTypes.number,
  addUsersError: PropTypes.bool,
  setOnboardingFlowAsSeen: PropTypes.func.isRequired,
  setPreviousStep: PropTypes.func.isRequired,
  sendTrackingEvent: PropTypes.func.isRequired,
};

OnboardingAddUsersConfirmation.defaultProps = {
  addedUsersCount: 0,
  addUsersError: false,
};

export default connect(mapStateToProps)(OnboardingAddUsersConfirmation);
