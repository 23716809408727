'use strict';

import { permissionsActions } from '../../constants/permissions.constants';

export function permissionsReducer(state = {
  permissions: {},
}, action) {
  switch (action.type) {
    case permissionsActions.FETCH_PERMISSIONS:
      return Object.assign({}, state, {
        permissions: action.permissions,
      });
    default:
      return state;
  }
}
