/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable react/require-default-props */

'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import autobind from 'autobind-decorator';
import _ from 'lodash';
// eslint-disable-next-line import/no-named-as-default
import Sort from './Sort';
// eslint-disable-next-line import/no-named-as-default
import StarFilter from './StarFilter';
import { ProjectsGroupsManagement } from '../../../containers/ProjectsGroupsManagement';

export default class ProjectActions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isGroupsSettingsOpen: false,
      isModalboxGroupsSettingsOpen: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isModalboxGroupsSettingsOpen !== this.state.isModalboxGroupsSettingsOpen) {
      this.setState({
        isModalboxGroupsSettingsOpen: nextProps.isModalboxGroupsSettingsOpen,
      });

      if (nextProps.isModalboxGroupsSettingsOpen === true) {
        this.openGroupsSettings();
      }
    }
  }

  openGroupsSettings(e) {
    if (!_.isEmpty(e)) {
      e.preventDefault();
    }

    const { areProjectGroupsEnabled, openFeatureUpgradeModal } = this.props;

    if (!areProjectGroupsEnabled) {
      openFeatureUpgradeModal();
      return;
    }

    this.setState({
      isGroupsSettingsOpen: true,
    });
  }

  @autobind
  closeGroupsSettings() {
    this.setState({
      isGroupsSettingsOpen: false,
    });

    if (this.state.isModalboxGroupsSettingsOpen) {
      this.props.closeModalboxGroupsSettings();
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return Object.keys(this.props).some((propName) => !_.isEqual(this.props[propName], nextProps[propName]))
      || Object.keys(this.state).some((stateName) => !_.isEqual(this.state[stateName], nextState[stateName]));
  }

  rednerProjectGroups() {
    const { isAccountParked } = this.props;

    if (isAccountParked) { return null; }

    return (
      <li className="option-element">
        <a
          href="#projects-groups"
          className="icon-general-settings underline-link gray-link"
          onClick={(e) => this.openGroupsSettings(e)}>
          Projects groups
        </a>
        <ProjectsGroupsManagement
          isOpen={this.state.isGroupsSettingsOpen}
          onClose={this.closeGroupsSettings}
          isModalboxGroupsSettingsOpen={this.state.isModalboxGroupsSettingsOpen}
        />
      </li>
    );
  }

  render() {
    const {
      sortType,
      setSortType,
      changeFilterType,
      filterType,
    } = this.props;

    return (
      <ul className="options-group">
        <li className="option-element">
          <StarFilter
            changeFilterType={changeFilterType}
            filterType={filterType}
          />
        </li>
        <li className="option-element with-separator">
          <Sort
            setSortType={setSortType}
            sortType={sortType}
          />
        </li>
        {this.rednerProjectGroups()}
      </ul>
    );
  }
}

ProjectActions.propTypes = {
  closeModalboxGroupsSettings: PropTypes.func.isRequired,
  sortType: PropTypes.string.isRequired,
  setSortType: PropTypes.func.isRequired,
  areProjectGroupsEnabled: PropTypes.bool.isRequired,
  openFeatureUpgradeModal: PropTypes.func.isRequired,
};

ProjectActions.defaultProps = {
  closeModalboxGroupsSettings: _.noop,
  setSortType: _.noop,
};
