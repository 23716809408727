/* eslint-disable max-len,react/button-has-type */
/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable react/require-default-props */

'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@uxpin/shared-components';
import { Link } from '@uxpin/design-system';
import autobind from 'autobind-decorator';
import classnames from 'classnames';
import _ from 'lodash';
import keys from '../../../constants/keys.constants';
import { apiErrors } from '../../../constants/errors.constants';
import { goTo } from '../../../utils/router';
import routes from '../../../constants/routes.constants';

export default class ModalboxProjectNew extends React.Component {
  constructor(props) {
    super(props);
    this.MODAL = {
      WRAPPER_ID: 'project-new-modal-box',
      CLASS_NAMES: ['modal-box-medium-width'],
      TITLE: 'New Project',
    };

    this.inputId = _.uniqueId();

    this.state = {
      projectName: '',
      selectedGroup: 0,
      isSelectDisabled: true,
    };
  }

  renderProjectsGroups() {
    const { projectsGroups } = this.props;

    if (!projectsGroups) {
      return;
    }

    // eslint-disable-next-line array-callback-return
    const options = projectsGroups.map((projectsGroup, id) => {
      if (!projectsGroup.type) {
        // eslint-disable-next-line react/no-array-index-key
        return <option key={id} value={id}>{projectsGroup.name}</option>;
      }
    });

    return (
      // eslint-disable-next-line jsx-a11y/no-onchange
      <select
        name="select"
        disabled={this.state.isSelectDisabled}
        value={this.state.selectedGroup}
        onChange={this.changeSelectedGroup}>
        {options}
      </select>
    );
  }

  @autobind
  changeSelectedGroup(e) {
    this.setState({
      // eslint-disable-next-line radix
      selectedGroup: parseInt(e.target.value),
    });
  }

  @autobind
  changeProjectName(e) {
    this.setState({
      projectName: e.target.value,
    });
  }

  @autobind
  onOpen() {
    setTimeout(() => {
      this.refs.input.focus();
    }, 50);

    if (!_.isUndefined(this.props.idProjectGroup) && !this.props.isUngroupedProjectsGroup) {
      this.setState({
        selectedGroup: this.props.idProjectGroup,
        isSelectDisabled: false,
      });
    } else {
      this.setState({
        selectedGroup: _.first(this.props.projectsGroups.map((projectsGroup, id) => {
          if (!projectsGroup.type) {
            return id;
          }

          return null;
        }).filter((id) => id !== null)),
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { currentAddedProjectId, isAdding, isError } = nextProps;

    if (this.props.isAdding && !isAdding && !isError) {
      goTo(`${routes.PROJECT}/${currentAddedProjectId}`);
    }
  }

  isEmpty() {
    return !this.state.projectName.trim().length;
  }

  @autobind
  onKeyDown(e) {
    if (e.keyCode === keys.ENTER && !this.isEmpty()) {
      this.createNewProject(e);
    }
  }

  @autobind
  createNewProject(e) {
    e.preventDefault();
    const { projectsGroups, saveProject } = this.props;
    if (!this.state.isSelectDisabled) {
      saveProject(this.state.projectName, projectsGroups[this.state.selectedGroup].id);
    } else {
      saveProject(this.state.projectName);
    }
  }

  getChooseGroupClasses() {
    return classnames('choose-group');
  }

  getBtnClasses() {
    return classnames('btn-solid', { working: this.props.isAdding });
  }

  getGroupInfoClasses() {
    return classnames('group-info', { 'invisible-helper': this.state.isSelectDisabled });
  }

  @autobind
  handleInputChange() {
    this.setState({
      // eslint-disable-next-line react/no-access-state-in-setstate
      isSelectDisabled: !this.state.isSelectDisabled,
    });
  }

  renderError() {
    const { isError, errorType } = this.props;

    if (isError) {
      switch (errorType) {
        case apiErrors.LIMIT_EXCEEDED:
          return (
            <li>
              <p className="error">This account reached its project limit.</p>
            </li>
          );
        default:
          return (
            <li>
              <p className="error">Something went wrong!</p>
            </li>
          );
      }
    }
  }

  renderProjectsGroupsSection() {
    const { projectsGroups } = this.props;

    if (!projectsGroups || (projectsGroups && projectsGroups.length <= 2)) {
      return;
    }

    return (
      <section className={this.getChooseGroupClasses()}>
        <div>
          <input id={`add-to-group-${this.inputId}`} className="state-helper" checked={!this.state.isSelectDisabled} type="checkbox" onChange={this.handleInputChange} />
          <label htmlFor={`add-to-group-${this.inputId}`} className="fake-choose-label fake-checkbox">Add to a project group:</label>
          {/* Temporary solution - will be replaced with hulkselect: */}
          {this.renderProjectsGroups()}
          <small className={this.getGroupInfoClasses()}>
          All users from selected group will be added to this project
          </small>
        </div>
      </section>
    );
  }

  shouldComponentUpdate(nextProps, nextState) {
    return Object.keys(this.props).some((propName) => !_.isEqual(this.props[propName], nextProps[propName]))
      || Object.keys(this.state).some((propName) => !_.isEqual(this.state[propName], nextState[propName]));
  }

  render() {
    return (
      <Modal
        wrapperId={this.MODAL.WRAPPER_ID}
        classNames={this.MODAL.CLASS_NAMES}
        title={this.MODAL.TITLE}
        isOpen={this.props.isOpen}
        onOpen={this.onOpen}
        onClose={this.props.onClose}>
        <section className="modal-box-content">
          <fieldset className="full-width">
            <label htmlFor="project-name" className="block-label">Project Name</label>
            <input
              id="project-name"
              type="text"
              ref="input"
              value={this.state.projectName}
              onChange={this.changeProjectName}
              onKeyDown={this.onKeyDown}
            />
          </fieldset>
          {this.renderProjectsGroupsSection()}
        </section>
        <footer>
          <ul className="btns-group">
            {this.renderError()}
            <li>
              <Link
                appearance="neutral"
                href="#cancel"
                onClick={this.props.onClose}>
                Cancel
              </Link>
            </li>
            <li>
              <button disabled={this.isEmpty()} onClick={this.createNewProject} className={this.getBtnClasses()}>
                Create New Project
              </button>
            </li>
          </ul>
        </footer>
      </Modal>
    );
  }
}

ModalboxProjectNew.defaultProps = {
  isOpen: false,
  onClose: _.noop,
  projectsGroups: [],
  isAddding: false,
  isUngroupedProjectsGroup: false,
  saveProject: _.noop,
};

ModalboxProjectNew.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  projectsGroups: PropTypes.array.isRequired,
  isAdding: PropTypes.bool.isRequired,
  currentAddedProjectId: PropTypes.number,
  idProjectGroup: PropTypes.number,
  saveProject: PropTypes.func.isRequired,
};
