import { connect } from 'react-redux';
import ModalboxDocumentDelete from '../components/Modalboxes/ModalboxDocumentDelete';
import { modalsIds } from '../../constants/modal.constants';
import * as modal from '../../controllers/actions/modal.actions';
import * as documents from '../../controllers/actions/document.actions';

export const mapStateToProps = (state) => {
  const { data: modalData, openedModalsIds } = state.modal;

  return {
    isOpen: openedModalsIds[modalsIds.MODALBOX_DOCUMENT_DELETE] || false,
    data: modalData[modalsIds.MODALBOX_DOCUMENT_DELETE] || {},
  };
};

export const mapDispatchToProps = (dispatch) => ({
  removeDocument: (idDocument) => {
    dispatch(documents.removeDocument(idDocument));
  },

  onClose: () => {
    dispatch(modal.closeModal(modalsIds.MODALBOX_DOCUMENT_DELETE));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalboxDocumentDelete);
