'use strict';

export const save = (name, value) => localStorage.setItem(name, JSON.stringify(value));

export const load = (name) => {
  const retrievedObject = localStorage.getItem(name);
  return JSON.parse(retrievedObject);
};

export const remove = (name) => localStorage.removeItem(name);

export const removeAll = () => localStorage.clear();
