/* eslint-disable react/destructuring-assignment,react/no-unescaped-entities,max-len */
/* eslint-disable react/prop-types */

'use strict';

import React from 'react';
import _ from 'lodash';
import moment from 'moment';

export default class MaintenanceBar extends React.Component {
  close(e) {
    e.preventDefault();
    this.props.onClose();
  }

  getTz(time) {
    const matches = (new Date(time)).toString().match(/\(.*\)$/);

    return matches ? matches[0] : '';
  }

  render() {
    const startTime = moment(this.props.settings.startMaintenanceTime).local();
    const endTime = moment(this.props.settings.endMaintenanceTime).local();

    const day = startTime.format('dddd, MMMM D, YYYY');
    const start = startTime.format('h:mm A');
    const tz = this.getTz(this.props.settings.startMaintenanceTime);
    const timeTaken = endTime.from(startTime, true);

    return (
      <section id="top-info-bar" className="with-close">
        <div>
          We're having scheduled maintenance on {day}, starting at {start} {tz}. The downtime should take up to {timeTaken}. Thank you for your patience!
        </div>
        <div>
          Questions? We’re at:
          {' '}
          <a href="mailto:hello@uxpin.com" className="underline-link gray-link">hello@uxpin.com</a>.
          <a
            href="#close"
            title="Close"
            className="icon-general-close only-icon-font"
            onClick={this.close.bind(this)}>
            close
          </a>
        </div>
      </section>
    );
  }
}

MaintenanceBar.defaultProps = {
  onClose: _.noop,
  isVisible: false,
  settings: {
    id: null,
    isActive: false,
    startMaintenanceTime: null,
    endMaintenanceTime: null,
  },
};
