/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */

'use strict';

import React from 'react';
import autobind from 'autobind-decorator';
import classnames from 'classnames';
import { Cloud, cloudPositions, GLOBAL_ELEMENTS } from '@uxpin/shared-components';
import { DragSource, DropTarget } from 'react-dnd';
import StepActions from './StepActions';
import StepDeleted from './StepDeleted';
import * as SortableList from '../../../../utils/sortableList';
import { sortableItemsTypes } from '../../../../constants/sortableItems.constants';
import { statusIdToClassMap } from '../../../../constants/projects.constants';
import SelectStatus from '../../SelectClouds/SelectStatus';

@Cloud({
  availablePositions: [cloudPositions.BOTTOM, cloudPositions.TOP],
  offset: {
    top: { x: 0, y: -5 },
    bottom: { x: 0, y: 5 },
  },
})
class Step extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      // eslint-disable-next-line react/no-unused-state
      isActive: false,
    };
  }

  getStyles() {
    const { data } = this.props;

    if (data.isDeleted) {
      return {
        display: 'none',
      };
    }
  }

  @autobind
  onEdit() {
    const { index, onEdit } = this.props;
    onEdit(index);
  }

  @autobind
  handleClick(e) {
    e.preventDefault();
    const {
      data, index, isActive, setCurrentStageAndStatus, projectStatuses,
    } = this.props;
    if (!isActive && !data.isBeingDeleted) {
      setCurrentStageAndStatus(data, index, projectStatuses[0]);
    }
  }

  getClasses() {
    const { data, isDragging, isActive } = this.props;
    return classnames('item-wrapper editable-item', {
      active: isActive,
      deleting: data.isBeingDeleted,
      dragging: isDragging,
    });
  }

  getStatusName() {
    const { currentStatus } = this.props;
    return currentStatus.name;
  }

  render() {
    const {
      deleteStage,
      countTime,
      undoStage,
      connectDragSource,
      connectDragPreview,
      connectDropTarget,
      currentStatus,
      data,
      index,
      isCustomizationEnabled,
      isDeletingActionHidden,
      startDeleteStage,
    } = this.props;
    const headingClasses = classnames('name', GLOBAL_ELEMENTS.HEADING_5);
    const linkClasses = classnames('status', GLOBAL_ELEMENTS.LINK);

    return connectDropTarget(connectDragPreview(
      <section style={this.getStyles()} onClick={this.handleClick} className={this.getClasses()}>
        {connectDragSource(
          <i className="drag icon-general-dots-vertical only-icon-font">Drag&amp;Drop</i>,
          'move'
        )}
        <article className="item-data">
          <h5 className={headingClasses}>{data.name}</h5>
        </article>
        <aside className="item-actions">
          <StepActions
            index={index}
            isCustomizationEnabled={isCustomizationEnabled}
            isDeletingActionHidden={isDeletingActionHidden}
            startDeleteStage={startDeleteStage}
            onEdit={this.onEdit}
          />
        </aside>
        <div className="project-status">
          <span {...this.props.trigger}>
            <a
              href="#status"
              className={linkClasses}
              data-status={statusIdToClassMap[currentStatus.idStatus]}
              data-show-node="cloud">
              <span className="icon-general-chevron">{this.getStatusName()}</span>
            </a>
            <SelectStatus
              {...this.props.cloud}
              projectStatuses={this.props.projectStatuses}
              setCurrentStatus={this.props.setCurrentStatus}
              isOpen={this.props.isOpen}
            />
          </span>
        </div>
        <StepDeleted
          shouldStart={data.isBeingDeleted}
          deleteGroup={deleteStage}
          countTime={countTime}
          undoStage={undoStage}
          deleteStage={deleteStage}
          stageName={data.name}
          time={data.time}
          index={index}
        />
      </section>
    ));
  }
}

export default DropTarget(sortableItemsTypes.STEP, SortableList.itemTarget, SortableList.collectTarget)(
  DragSource(sortableItemsTypes.STEP, SortableList.itemSource, SortableList.collectSource)(Step)
);
