/* global window, process */

'use strict';

import logger from './logger';

const actions = [];
const maxSavedActions = 10;
export default ({ getState }) => (next) => (action) => {
  let returnValue;
  if (actions.length === maxSavedActions) {
    actions.shift();
  }

  actions.push(action.type);

  if (window.Rollbar) {
    window.Rollbar.configure({
      payload: {
        lastActions: actions,
        currentState: JSON.stringify(getState()),
      },
    });
  }

  try {
    returnValue = next(action);
  } catch (e) {
    if (process.env.NODE_ENV === 'production' && window.Rollbar) {
      window.Rollbar.error(e);
    } else {
      logger.error(e);
    }
  }

  return returnValue;
};
