import React, { Fragment } from 'react';
import autobind from 'autobind-decorator';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Button, InputText, Label } from '@uxpin/design-system';
import { Modal } from '@uxpin/shared-components';

const MODAL_CLASSNAME = 'modalbox-fill-name';
const MODAL_QUESTION_SECTION_CLASS = 'modalbox-fill-name-question';

export default class ModalboxFillNameComponent extends React.Component {
  constructor(props) {
    super(props);

    this.MODAL = {
      CLASS_NAMES: MODAL_CLASSNAME,
    };

    this.state = {
      firstName: props.startFirstName,
      lastName: props.startLastName,
      firstNameError: false,
      lastNameError: false,
      firstNameValid: this.isValidName(props.startFirstName),
      lastNameValid: this.isValidName(props.startLastName),
      isWorking: false,
    };
  }

  getIntroCopy() {
    return (
      <p>
        Your admins are requesting you to fill in your Name and Surname information.
        Providing this information is required and necessary for account owner to administrate users.
      </p>
    );
  }

  @autobind
  setFirstName(event) {
    const firstName = event.target.value;
    const isValid = this.isValidName(firstName);

    this.setState({
      firstName,
      firstNameError: !isValid,
      firstNameValid: isValid,
    });
  }

  @autobind
  setLastName(event) {
    const lastName = event.target.value;
    const isValid = this.isValidName(lastName);

    this.setState({
      lastName,
      lastNameError: !isValid,
      lastNameValid: isValid,
    });
  }

  isValidName(name) {
    return name.length > 0;
  }

  getInputs() {
    const { lastNameError, firstNameError, firstName, lastName } = this.state;
    const appearanceLast = lastNameError ? 'error' : 'default';
    const appearanceFirst = firstNameError ? 'error' : 'default';

    return (
      <Fragment>
        <div className={MODAL_QUESTION_SECTION_CLASS}>
          <Label forId="first-name-input">First name:</Label>
          <InputText
            id="first-name-input"
            name="modal-first-name"
            onChange={this.setFirstName}
            appearance={appearanceFirst}
            value={firstName}
          />
        </div>
        <div className={MODAL_QUESTION_SECTION_CLASS}>
          <Label forId="last-name-input">Last name:</Label>
          <InputText
            id="last-name-input"
            name="modal-last-name"
            onChange={this.setLastName}
            appearance={appearanceLast}
            value={lastName}
          />
        </div>
      </Fragment>
    );
  }

  getContent() {
    return (
      <div>
        <div>{ this.getIntroCopy() }</div>
        { this.getInputs() }
      </div>
    );
  }

  @autobind
  submitClick() {
    const { firstName, lastName, firstNameValid, lastNameValid } = this.state;
    const { submit } = this.props;

    if (!firstNameValid || !lastNameValid) {
      this.setState({
        firstNameError: !firstNameValid,
        lastNameError: !lastNameValid,
      });
      return;
    }

    this.setState({
      isWorking: true,
    });

    submit(firstName, lastName);
  }

  render() {
    const { isWorking } = this.state;

    return (
      <Modal
        classNames={this.MODAL.CLASS_NAMES}
        isOpen
        title="Complete your account's setup"
        isCloseBlocked>
        <section className="modal-box-content">
          {this.getContent()}
        </section>
        <footer>
          <ul className="btns-group">
            <li>
              <Button
                onClick={this.submitClick}
                isWorking={isWorking}>
                Save
              </Button>
            </li>
          </ul>
        </footer>
      </Modal>
    );
  }
}

ModalboxFillNameComponent.propTypes = {
  submit: PropTypes.func,
};

ModalboxFillNameComponent.defaultProps = {
  submit: noop,
};
