'use strict';

import { connect } from 'react-redux';
import AccountSetupFormComponent from '../components/Modalboxes/components/AccountSetupForm';
import { savePartialLeadForm } from '../../controllers/actions/onboarding.actions';

export const mapDispatchToProps = (dispatch) => ({
  savePartialLeadForm: (data) => dispatch(savePartialLeadForm(data)),
});

export const AccountSetupForm = connect(null, mapDispatchToProps)(AccountSetupFormComponent);
