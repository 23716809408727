/* eslint-disable react/destructuring-assignment */

'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import autobind from 'autobind-decorator';
import { noop } from 'lodash';
import { Modal } from '@uxpin/shared-components';
import { modalsIds } from '../../../constants/modal.constants';
import { extendTrialFormAllowedFields } from '../../../constants/extendTrial.constants';
import { ExtendTrialForm } from '../../containers/ExtendTrialForm';
import { preventDefaultEvent } from '../../../utils/common';

export default class ModalboxExtendTrialFormComponent extends React.Component {
  constructor(props) {
    super(props);

    this.closeHelper = noop;

    this.MODAL = {
      WRAPPER_ID: modalsIds.EXTEND_TRIAL_FORM,
      CLASS_NAMES: 'extend-trial-form',
    };

    this.fields = extendTrialFormAllowedFields; // all fields by default
  }

  @autobind
  onClose(e) {
    preventDefaultEvent(e);
    this.props.onClose(e);
    this.closeHelper.hideModal();
  }

  @autobind
  onSubmit(e) {
    this.onClose(e, true);
  }

  renderHeader() {
    return <h2 className="header">Extend your trial</h2>;
  }

  renderSubtitle() {
    return (
      <p className="desc">Answer all the questions and get an extra <strong>7 days</strong> of trial.</p>
    );
  }

  render() {
    return (
      <Modal
        ref={(c) => { this.closeHelper = c; }}
        wrapperId={this.MODAL.WRAPPER_ID}
        isOpen={this.props.isOpen}
        onClose={this.onClose}>
        <div className={this.MODAL.CLASS_NAMES}>
          {this.renderHeader()}
          {this.renderSubtitle()}
          <ExtendTrialForm
            onSubmit={this.onSubmit}
            onCancel={this.onClose}
            fields={this.fields}
          />
        </div>
      </Modal>
    );
  }
}

ModalboxExtendTrialFormComponent.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
};

ModalboxExtendTrialFormComponent.defaultProps = {
  onClose: noop,
};
