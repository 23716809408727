/* eslint-disable react/destructuring-assignment */

'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import autobind from 'autobind-decorator';
import _ from 'lodash';
import { ModalboxGeneralMessage } from '@uxpin/shared-components';
import ModalboxPatternsInfo from './ModalboxPatternsInfo';
import { goTo, redirectToNotFoundView } from '../../../utils/router';
import { ModalboxExtendTrialForm } from '../../containers/ModalboxExtendTrialForm';
import ModalboxSpecModePromoUpgrade from './ModalboxSpecModePromoUpgrade';
import { modalsIds, extendModalSource } from '../../../constants/modal.constants';
import routes from '../../../constants/routes.constants';
import ModalboxTeamUpsell from '../../containers/Modalboxes/Promo/ModalboxTeamUpsell';
import ModalboxProUpsell from '../../containers/Modalboxes/Promo/ModalboxProUpsell';

export default class ModalboxRemoteHelper extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isModalboxPatternsInfoOpen: false,
      isModalboxGeneralMessageOpen: false,
      isModalboxSpecModePromoUpgradeOpen: false,
      isModalboxExtendTrialFormOpen: false,
      generalMessageTitle: '',
      generalMessageContent: '',
      generalMessageBtnLabel: '',
      generalMessageIsCancelHidden: false,
      isModalTeamUpsellOpen: false,
      isModalProUpsellOpen: false,
    };
  }

  componentWillMount() {
    this.manageOpening();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.isUpgradeModalOpen && !nextProps.isUpgradeModalOpen) {
      this.onUpgradeClose();
    }
  }

  manageOpening() {
    const { openUpgradeModal, params: { modalId } } = this.props;

    switch (modalId) {
      case modalsIds.PATTERN_READY:
        this.setState({
          isModalboxPatternsInfoOpen: true,
        });
        break;
      case modalsIds.PATTERN_NOT_AVAILABLE:
        this.setState({
          isModalboxGeneralMessageOpen: true,
          generalMessageTitle: 'Not available',
          generalMessageContent: 'Patterns are not available to Basic accounts.'
                                + 'Upgrade now to unlock this and many more awesome features '
                                + 'or Photoshop and Sketch integration, Custom Libraries, or Advanced Exports!',
          generalMessageBtnLabel: 'Upgrade now',
        });
        break;
      case modalsIds.PATTERN_ERROR:
        this.setState({
          isModalboxGeneralMessageOpen: true,
          generalMessageTitle: 'Something went wrong!',
          generalMessageContent: 'Please try again and if problem reoccurs, please let us know at hello@uxpin.com',
          generalMessageBtnLabel: 'Close',
          generalMessageIsCancelHidden: true,
        });
        break;
      case modalsIds.UPGRADE:
        openUpgradeModal();
        break;
      case modalsIds.EXTEND_TRIAL_FORM:
        this.setState({
          isModalboxExtendTrialFormOpen: true,
        });
        break;
      case modalsIds.SPECMODE_PROMO_UPGRADE:
        this.setState({
          isModalboxSpecModePromoUpgradeOpen: true,
        });
        break;
      case modalsIds.TEAM_UPSELL:
        this.setState({
          isModalTeamUpsellOpen: true,
        });
        break;
      case modalsIds.PRO_UPSELL:
        this.setState({
          isModalProUpsellOpen: true,
        });
        break;
      default:
        redirectToNotFoundView();
        break;
    }
  }

  @autobind
  onTeamUpsellClose() {
    this.setState({
      isModalTeamUpsellOpen: false,
    });

    goTo(routes.PROJECTS);
  }

  @autobind
  onProUpsellClose() {
    this.setState({
      isModalProUpsellOpen: false,
    });

    goTo(routes.PROJECTS);
  }

  @autobind
  onPatternsClose(e) {
    if (_.isObject(e)) {
      e.preventDefault();
    }

    this.setState({
      isModalboxPatternsInfoOpen: false,
      isModalboxGeneralMessageOpen: false,
    });

    goTo(routes.PROJECTS);
  }

  @autobind
  onSpecmodePromoUpgradeClose() {
    this.setState({
      isModalboxSpecModePromoUpgradeOpen: false,
    });

    goTo(routes.PROJECTS);
  }

  @autobind
  onUpgradeClose() {
    goTo(routes.PROJECTS);
  }

  @autobind
  onExtendTrialFormClose() {
    this.setState({
      isModalboxExtendTrialFormOpen: false,
    });

    goTo(routes.PROJECTS);
  }

  @autobind
  onPatternsAccept() {
    const { params: { modalId }, openUpgradeModal } = this.props;

    switch (modalId) {
      case modalsIds.PATTERN_NOT_AVAILABLE:
        this.onPatternsClose();
        openUpgradeModal();
        break;
      case modalsIds.PATTERN_ERROR:
        this.onPatternsClose();
        break;
      default:
        break;
    }
  }

  render() {
    return (
      <div>
        <ModalboxPatternsInfo
          isOpen={this.state.isModalboxPatternsInfoOpen}
          onClose={this.onPatternsClose}
        />
        <ModalboxGeneralMessage
          isOpen={this.state.isModalboxGeneralMessageOpen}
          onClose={this.onPatternsClose}
          onAccept={this.onPatternsAccept}
          isCancelHidden={this.state.generalMessageIsCancelHidden}
          title={this.state.generalMessageTitle}
          message={this.state.generalMessageContent}
          btnLabel={this.state.generalMessageBtnLabel}
        />
        <ModalboxSpecModePromoUpgrade
          isOpen={this.state.isModalboxSpecModePromoUpgradeOpen}
          onClose={this.onSpecmodePromoUpgradeClose}
        />
        <ModalboxExtendTrialForm
          isOpen={this.state.isModalboxExtendTrialFormOpen}
          onClose={this.onExtendTrialFormClose}
          openedFrom={extendModalSource.EMAIL}
        />
        <ModalboxTeamUpsell
          isOpenFromUrl={this.state.isModalTeamUpsellOpen}
          onCloseFromUrl={this.onTeamUpsellClose}
        />
        <ModalboxProUpsell
          isOpenFromUrl={this.state.isModalProUpsellOpen}
          onCloseFromUrl={this.onProUpsellClose}
        />
      </div>
    );
  }
}

ModalboxRemoteHelper.propTypes = {
  isUpgradeModalOpen: PropTypes.bool.isRequired,
  openUpgradeModal: PropTypes.func.isRequired,
  params: PropTypes.shape({
    modalId: PropTypes.string,
  }),
};

ModalboxRemoteHelper.defaultProps = {
  params: PropTypes.shape({
    modalId: '',
  }),
};
