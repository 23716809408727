'use strict';

import config from 'react-global-configuration';
import { documentsTypes } from '../constants/documents.constants';

export const isDocumentLimitReached = (allowed, inUse) => (allowed === null ? false : inUse >= allowed);

export const isDocumentLimitNearLimit = (allowed, inUse) => (allowed === null ? false : inUse >= (allowed * (2 / 3)));

export const getEditUrl = (document, features) => {
  const { APP_URL, EDITOR_NEW_ENGINE_URL } = config.get();
  let link;

  if (document.type === documentsTypes.DOCUMENT) {
    link = `${APP_URL}/dms/Documents/Default/${document.idOriginalDocument}`;
  } else {
    // eslint-disable-next-line max-len
    link = `${features && features.uxpin30_engine === 'enabled' ? EDITOR_NEW_ENGINE_URL : APP_URL}/edit/${document.idDocument}`;
  }

  return link;
};
