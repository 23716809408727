'use strict';

import _ from 'lodash';
import { shareActions } from '../../constants/share.constants';

const {
  CHECK_SHARE_EMAIL_RECAPTCHA,
  DISCARD_EXPORT,
  FETCH_EXPORTS_STATUSES,
  FETCH_SHARE_DATA,
  FETCH_TYPEKIT_FONTS_IN_PROJECT_INFO,
  FINISH_EXPORT_DOCUMENT,
  REQUEST_EXPORT_DOCUMENT,
  REQUEST_SHARING_DOCUMENT_BY_EMAIL,
  SHARE_DOCUMENT_BY_EMAIL,
  SHARE_DOCUMENT_BY_EMAIL_ERROR,
} = shareActions;

const actionFetchShareData = (state, action) => (
  Object.assign({}, state, {
    phonePrefixes: action.data.phone_codes,
    pages: action.data.pages,
  })
);

const actionRequestSharingDocumentByEmail = (state) => (
  Object.assign({}, state, {
    isDocumentSharingByEmail: true,
    isEmailError: false,
  })
);

const actionShareDocumentByEmail = (state) => (
  Object.assign({}, state, {
    isDocumentSharingByEmail: false,
  })
);

const actionCheckShareEmailReCaptcha = (state, action) => (
  Object.assign({}, state, {
    shouldRenderReCaptcha: action.data.render_recaptcha,
  })
);

const actionFetchExportsStatuses = (state, action) => (
  Object.assign({}, state, {
    exports: action.exports,
  })
);

const actionDiscardExport = (state, action) => {
  const exports = _.cloneDeep(state.exports);

  delete exports[action.index];
  return Object.assign({}, state, {
    exports,
  });
};

const actionShareDocumentByEmailError = (state) => (
  Object.assign({}, state, {
    isEmailError: true,
  })
);

const actionRequestExportDocument = (state, action) => {
  const pendingExports = _.cloneDeep(state.pendingExports);

  pendingExports[action.exportType] = true;
  return Object.assign({}, state, {
    pendingExports,
  });
};

const actionFinishExportDocument = (state, action) => {
  const pendingExports = _.cloneDeep(state.pendingExports);

  delete pendingExports[action.exportType];
  return Object.assign({}, state, {
    pendingExports,
  });
};

const actionFetchTypekitFontsInProjectInfo = (state, action) => (
  Object.assign({}, state, {
    hasTypekitFontsInProject: action.hasTypekitFontsInProject,
  })
);

const shareHandler = {
  [CHECK_SHARE_EMAIL_RECAPTCHA]: actionCheckShareEmailReCaptcha,
  [DISCARD_EXPORT]: actionDiscardExport,
  [FETCH_EXPORTS_STATUSES]: actionFetchExportsStatuses,
  [FETCH_SHARE_DATA]: actionFetchShareData,
  [FETCH_TYPEKIT_FONTS_IN_PROJECT_INFO]: actionFetchTypekitFontsInProjectInfo,
  [FINISH_EXPORT_DOCUMENT]: actionFinishExportDocument,
  [REQUEST_EXPORT_DOCUMENT]: actionRequestExportDocument,
  [REQUEST_SHARING_DOCUMENT_BY_EMAIL]: actionRequestSharingDocumentByEmail,
  [SHARE_DOCUMENT_BY_EMAIL]: actionShareDocumentByEmail,
  [SHARE_DOCUMENT_BY_EMAIL_ERROR]: actionShareDocumentByEmailError,
};

const handleShareAction = (state, action) => {
  if (!_.has(shareHandler, action.type)) {
    return state;
  }

  return shareHandler[action.type](state, action);
};


export function shareReducer(state = {
  exports: {},
  hasTypekitFontsInProject: {},
  isDocumentSharingByEmail: false,
  isEmailError: false,
  pages: [],
  pendingExports: {},
  phonePrefixes: [],
  shouldRenderReCaptcha: false,
}, action) {
  return handleShareAction(state, action);
}
