import { connect } from 'react-redux';
import ModalboxContactRequestComponent from '../components/Modalboxes/ModalboxContactRequest';
import { modalsIds } from '../../constants/modal.constants';
import { closeModal } from '../../controllers/actions/modal.actions';
import { sendContactRequest } from '../../controllers/actions/accountSettings.actions';

export const mapStateToProps = (state) => {
  const { data: modalData, openedModalsIds } = state.modal;
  const { data } = state.currentUser;
  const { email } = data;

  const isOpen = !!openedModalsIds[modalsIds.MODALBOX_CONTACT_REQUEST];

  return {
    isOpen,
    email,
    data: modalData[modalsIds.MODALBOX_CONTACT_REQUEST] || {},
  };
};

export const mapDispatchToProps = (dispatch) => ({
  onClose: () => {
    dispatch(closeModal(modalsIds.MODALBOX_CONTACT_REQUEST));
  },
  sendContactRequest: (formData) => dispatch(sendContactRequest(formData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalboxContactRequestComponent);
