/* eslint-disable react/destructuring-assignment */

'use strict';

import React from 'react';
import PropTypes from 'prop-types';

// eslint-disable-next-line react/prefer-stateless-function
export default class Color extends React.Component {
  render() {
    return (
      <li>
        <a
          href={`#color-#${this.props.color}`}
          className="color-square"
          style={{ backgroundColor: `#${this.props.color}` }}
          onClick={this.props.setColor}
        />
      </li>
    );
  }
}

Color.propTypes = {
  setColor: PropTypes.func.isRequired,
  color: PropTypes.string.isRequired,
};
