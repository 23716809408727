import { connect } from 'react-redux';
import { OnboardingIntendedUsesComponent } from '../../components/Onboarding/OnboardingIntendedUses';
import { savePartialLeadForm } from '../../../controllers/actions/onboarding.actions';

export const mapDispatchToProps = (dispatch) => ({
  saveSelectedIntendedUses: (role, primaryUseCases, designSystem) => {
    dispatch(savePartialLeadForm({ role, primaryUseCases, designSystem }));
  },
});

export const OnboardingIntendedUses = connect(null, mapDispatchToProps)(OnboardingIntendedUsesComponent);
