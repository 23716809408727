/* eslint-disable max-len,react/no-array-index-key */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */

'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import { Cloud, cloudPositions } from '@uxpin/shared-components';
import autobind from 'autobind-decorator';
import _ from 'lodash';
import Color from './components/Color';
import { projectsConstants } from '../../../constants/projects.constants';
import events from '../../../constants/event.constants';

export class ColorPicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedColor: projectsConstants.DEFAULT_COLOR,
    };
  }

  componentDidMount() {
    const { currentColor } = this.props;
    this.setColor(currentColor);
    if (this.props.emitter) {
      this.props.emitter.on(events.DELETE_GROUP, this.onGroupDelete);
    }
  }

  componentWillUnmount() {
    if (this.props.emitter) {
      this.props.emitter.off(events.DELETE_GROUP, this.onGroupDelete);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.currentColor) {
      return;
    }

    this.setColor(nextProps.currentColor);
  }

  @autobind
  onGroupDelete() {
    if (this.props.isOpen) {
      setTimeout(() => this.props.refreshCloud());
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return Object.keys(this.props).some((propName) => !_.isEqual(this.props[propName], nextProps[propName]))
      || Object.keys(this.state).some((propName) => !_.isEqual(this.state[propName], nextState[propName]));
  }

  setColor(color) {
    this.setState({
      selectedColor: color,
    });
  }

  handleSettingColor(color, e) {
    e.preventDefault();
    const { setColor } = this.props;
    this.setColor(color);

    if (_.isFunction(setColor)) {
      setColor(color);
    }
  }

  renderColorPickerBtn() {
    const { isClickActionBlocked } = this.props;

    if (isClickActionBlocked) {
      return <span className="group-color-box" style={{ backgroundColor: `#${this.state.selectedColor}` }}>Pick Color</span>;
    }

    return <a {...this.props.trigger} href="#pick-color" className="group-color-box" style={{ backgroundColor: `#${this.state.selectedColor}` }}>Pick Color</a>;
  }

  render() {
    return (
      <div className="groups-color-wrapper">
        {this.renderColorPickerBtn()}
        <ul {...this.props.cloud} className="groups-color-list cloud options-cloud big-arrow">
          {this.props.colors.map((color, id) => <Color color={color} key={id} setColor={(e) => this.handleSettingColor(color, e)} />)}
        </ul>
      </div>
    );
  }
}

ColorPicker.propTypes = {
  colors: PropTypes.array.isRequired,
};

export default Cloud({
  availablePositions: [cloudPositions.LEFT_BOTTOM],
  offset: {
    leftBottom: { x: -8, y: -8 },
  },
})(ColorPicker);
