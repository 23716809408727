'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import autobind from 'autobind-decorator';
import { noop } from 'lodash';
import FieldElement from './FieldElement';

export default class Select extends FieldElement {
  @autobind
  renderOptions() {
    const { options } = this.props;
    return options.map((option) => <option key={option.label} value={option.value}>{option.label}</option>);
  }

  @autobind
  handleSelectChange(e) {
    const { onChange } = this.props;
    this.handleChange(e, onChange);
  }

  render() {
    const { name, id } = this.props;

    return (
      <div>
        <select
          type={this.inputType}
          ref={this.getRef}
          className={this.renderClassNames()}
          name={name}
          id={id}
          onChange={this.handleSelectChange}
          onBlur={this.handleBlur}
          onKeyDown={this.handleKeyDown}>
          {this.renderOptions()}
        </select>
        {this.renderErrorMessage()}
      </div>
    );
  }
}

Select.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  options: PropTypes.array,
  onChange: PropTypes.func,
};

Select.defaultProps = {
  options: [],
  onChange: noop,
};
