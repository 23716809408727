/* eslint-disable react/destructuring-assignment,jsx-a11y/iframe-has-title */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Modal } from '@uxpin/shared-components';
import autobind from 'autobind-decorator';
import classNames from 'classnames';
import config from 'react-global-configuration';


export default class ModalboxUpgrade extends Component {
  constructor(props) {
    super(props);

    this.MODAL = {
      WRAPPER_ID: 'upgrade-plan-modal-box',
      CLASS_NAMES: ['iframe-modal-box'],
      URL: `${config.get('APP_URL')}/dms/DashboardModals/Upgrade/?source=dashboard&withoutCloseIcon=true`,
      // eslint-disable-next-line max-len
      URL_SPECPLANS: `${config.get('APP_URL')}/dms/DashboardModals/SpecModePlansUpgrade/?source=dashboard&withoutCloseIcon=true&modalClass=two-plans`,
    };

    this.state = {
      isLoaded: false,
    };
  }

  getClassNames() {
    const { designViewsPlans, specModePlans, fullscreen } = this.props;
    const { isLoaded } = this.state;

    return classNames(this.MODAL.CLASS_NAMES, {
      freemium: designViewsPlans,
      'full-screen-modal-box': fullscreen,
      'no-spinner': isLoaded,
      'two-plans': specModePlans || designViewsPlans,
    });
  }

  @autobind
  onClose() {
    const { onClose } = this.props;

    this.setState({
      isLoaded: false,
    });

    onClose();
  }

  @autobind
  onLoad() {
    this.setState({
      isLoaded: true,
    });
  }

  render() {
    if (!this.props.isOpen) {
      return null;
    }

    const { isCloseBlocked, isOpen, specModePlans } = this.props;
    const {
      TITLE,
      URL_SPECPLANS,
      URL,
      WRAPPER_ID,
    } = this.MODAL;

    return (
      <Modal
        wrapperId={WRAPPER_ID}
        classNames={this.getClassNames()}
        title={TITLE}
        isOpen={isOpen}
        isCloseBlocked={isCloseBlocked}
        onClose={this.onClose}>
        <section className="modal-box-content" onClick={this.checkIframe}>
          <iframe
            src={specModePlans ? URL_SPECPLANS : URL}
            onLoad={this.onLoad}
            frameBorder="0"
          />
        </section>
      </Modal>
    );
  }
}


ModalboxUpgrade.propTypes = {
  designViewsPlans: PropTypes.bool,
  fullscreen: PropTypes.bool,
  isCloseBlocked: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  specModePlans: PropTypes.bool,
};

ModalboxUpgrade.defaultProps = {
  designViewsPlans: false,
  fullscreen: false,
  isCloseBlocked: false,
  onClose: noop,
  specModePlans: false,
};
