import { projectsConstants } from '../../constants/projects.constants';

export const countProjects = (projectsGroup, index) => {
  let projectCount = 0;
  const projectsCounts = projectsGroup.map((projectGroup, currentIndex) => {
    const length = projectGroup.projects.length;

    if (currentIndex < index) {
      return length;
    }

    if (currentIndex === index) {
      return projectsConstants.PROJECTS_ON_PAGE < length ? projectsConstants.PROJECTS_ON_PAGE : length;
    }

    return 0;
  });

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < projectsCounts.length; i++) {
    projectCount += projectsCounts[i];
  }

  return projectCount;
};
