'use strict';

import _ from 'lodash';
import { searchActions } from '../../constants/search.constants';
import { insideProjectActions } from '../../constants/insideProject.constants';
import { request } from '../../utils/request';

const ROW_LIMIT = 500;

function getSearchData(data) {
  return {
    type: searchActions.FETCHING_SEARCH_DATA,
    searchData: data,
  };
}

function requestFetchingSearchData() {
  return {
    type: searchActions.REQUEST_FETCHING_SEARCH_DATA,
  };
}

function enterSearchMode() {
  return {
    type: searchActions.SEARCH_PROJECTS_ON,
  };
}

function exitSearchMode() {
  return {
    type: searchActions.SEARCH_PROJECTS_OFF,
  };
}

function fetchSearchDataWithPagination(baseUrl) {
  const currentPage = 1;
  let data;
  return request.get(`${baseUrl}?page=${currentPage}&pageSize=${ROW_LIMIT}`)
    .then(({ count, rows }) => {
      data = rows;
      const totalPages = Math.ceil(count / ROW_LIMIT);

      if (totalPages > 1) {
        const promises = [];
        for (let i = 2; i <= totalPages; i += 1) {
          promises.push(request.get(`${baseUrl}?page=${i}&pageSize=${ROW_LIMIT}`));
        }

        return Promise.all(promises);
      }

      return [];
    }).then((result) => {
      result.forEach(({ rows: projects }) => {
        data = [...data, ...projects];
      });

      return data;
    });
}

function fetchSearchData() {
  return function (dispatch) {
    dispatch(requestFetchingSearchData());
    Promise.all([
      fetchSearchDataWithPagination('/search/projects/index'),
      fetchSearchDataWithPagination('/search/documents/index'),
    ])
      .then(([projects, documents]) => {
        dispatch(getSearchData([...projects, ...documents]));
        return _.without(documents.map((searchData) => searchData.hash), undefined);
      })
      .then((documentHashes) => {
        if (documentHashes.length) {
          return request.post('/documents/comments/count', {
            body: documentHashes,
          });
        }

        return {};
      })
      .then((commentsCount) => {
        dispatch({
          type: insideProjectActions.SET_DOCUMENTS_COMMENTS_COUNT,
          commentsCount,
        });
      }, () => {
        dispatch({
          type: insideProjectActions.SET_DOCUMENTS_COMMENTS_COUNT,
          commentsCount: {},
        });
      });
  };
}

function removeProjectFromSearchData(idProject) {
  return {
    type: searchActions.SEARCH_DATA_REMOVE_PROJECT,
    idProject,
  };
}

function removeDocumentFromSearchData(idDocument) {
  return {
    type: searchActions.SEARCH_DATA_REMOVE_DOCUMENT,
    idDocument,
  };
}

export function startActionOnSearchData() {
  return {
    type: searchActions.START_SEARCH_DATA_ACTION,
  };
}

export const search = {
  enterSearchMode,
  exitSearchMode,
  fetchSearchData,
  removeProjectFromSearchData,
  removeDocumentFromSearchData,
  startActionOnSearchData,
};
