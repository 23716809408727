/* eslint-disable max-len,react/button-has-type */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */

'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import autobind from 'autobind-decorator';
import { Modal, ModalboxGeneralMessage } from '@uxpin/shared-components';
import { APPEARANCES, Button, Link } from '@uxpin/design-system';
import _ from 'lodash';
import eventEmitter from 'event-emitter';
import update from 'react-addons-update';
import { DropTarget } from 'react-dnd';
import classnames from 'classnames';
// eslint-disable-next-line import/no-named-as-default
import Group from './components/Group';
import GroupNew from './components/GroupNew';
import GroupsDelete from './GroupsDelete';
import events from '../../../constants/event.constants';
import { sortableItemsTypes } from '../../../constants/sortableItems.constants';
import { getBoxTarget } from '../../../utils/dndScrollFix';
import { getMixpanel } from '../../../../../shared/mixpanel';
import { removingGroupsModes } from '../../../constants/projectsGroupsManagement.constants';

export class GroupsSettings extends React.Component {
  constructor(props) {
    super(props);
    this.MODAL = {
      WRAPPER_ID: 'groups-settings-modal-box',
      TITLE: 'Project Groups Management',
    };

    this.state = {
      isGroupsDeleteModalOpen: false,
      isModalboxGeneralMessageOpen: false,
      modalboxGeneralBtnAction: _.noop,
      message: '',
      isInAddingMode: false,
      projectsGroups: [],
    };

    this.currentAddedGroupData = {};
    this.currentRemovedGroupData = {};

    this.classes = {
      LOADING: 'loading',
      WORKING: 'working',
      NEW_GROUP_INPUT: 'new-item-input',
    };

    this.emitter = eventEmitter();
  }

  renderDescription() {
    return (<p>Project groups let you organize project across your team&#39;s account</p>);
  }

  @autobind
  moveGroup(dragIndex, hoverIndex) {
    const { projectsGroups } = this.state;
    const dragGroup = Object.assign({}, projectsGroups[dragIndex]);

    this.setState({
      projectsGroups: update(projectsGroups, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragGroup],
        ],
      }),
    });
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.isOpen) {
      return;
    }

    if (this.props.isLoading && !nextProps.isLoading && !this.props.isSaving && !nextProps.isSaving && !nextProps.isError) {
      let projectsGroups = nextProps.projectsGroups.slice(0);
      const groupsAccess = nextProps.groupsAccess.slice(0);

      projectsGroups = projectsGroups.map((projectsGroup) => {
        const group = Object.assign({}, projectsGroup);
        // eslint-disable-next-line no-shadow
        const access = _.find(groupsAccess, (access) => access.idProjectGroup === group.id);

        if (access) {
          group.selectedUsers = access.userIds.slice(0);
          group.savedSelectedUsers = access.userIds.slice(0);
        } else {
          group.selectedUsers = [];
          group.savedSelectedUsers = [];
        }

        return group;
      });

      this.setState({
        projectsGroups,
      });
    }

    if (!nextProps.isError && this.props.isSaving && !nextProps.isSaving) {
      this.props.onClose();
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (!this.props.isOpen && !nextProps.isOpen) {
      return false;
    }

    return Object.keys(this.props).some((propName) => !_.isEqual(this.props[propName], nextProps[propName]))
      || Object.keys(this.state).some((propName) => !_.isEqual(this.state[propName], nextState[propName]));
  }

  @autobind
  saveGroup(name, color, index = null) {
    const { currentUserId } = this.props;

    if (index !== null) {
      const projectsGroup = Object.assign({}, this.state.projectsGroups[index]);
      projectsGroup.name = name;
      projectsGroup.isUpdated = true;
      delete projectsGroup.isBeingUpdated;
      this.setState({
        isInAddingMode: false,
        projectsGroups: [
          ...this.state.projectsGroups.slice(0, index),
          projectsGroup,
          ...this.state.projectsGroups.slice(index + 1),
        ],
      });
    } else {
      const projectsGroups = this.state.projectsGroups.map((projectsGroup) => {
        const group = Object.assign({}, projectsGroup);
        if (group.isBeingAdded) {
          group.name = name;
          group.temporaryId = _.uniqueId();
          group.color = color;
          group.selectedUsers = [currentUserId];
          delete group.isBeingAdded;
        }

        return group;
      });

      this.setState({
        isInAddingMode: false,
        projectsGroups,
      });
    }
  }

  @autobind
  addSelectedUsersToGroup(selectedUsers, index) {
    const projectsGroup = Object.assign({}, this.state.projectsGroups[index]);
    projectsGroup.selectedUsers = selectedUsers;

    this.setState({
      projectsGroups: [
        ...this.state.projectsGroups.slice(0, index),
        projectsGroup,
        ...this.state.projectsGroups.slice(index + 1),
      ],
    });
  }

  @autobind
  startEditingGroup(index) {
    const projectsGroup = Object.assign({}, this.state.projectsGroups[index]);
    projectsGroup.isBeingUpdated = true;

    this.setState({
      projectsGroups: [
        ...this.state.projectsGroups.slice(0, index),
        projectsGroup,
        ...this.state.projectsGroups.slice(index + 1),
      ],
    }, this.focusGroupInput);
  }

  @autobind
  startAddingNewGroup(e) {
    e.preventDefault();

    if (this.state.isInAddingMode) {
      this.focusGroupInput();
    } else {
      this.currentAddedGroupData = {
        isBeingAdded: true,
      };
      this.setState({
        isInAddingMode: true,
        projectsGroups: [...this.state.projectsGroups, this.currentAddedGroupData],
      }, this.focusGroupInput);
    }
  }

  focusGroupInput() {
    const input = this.node.querySelector(`input.${this.classes.NEW_GROUP_INPUT}`);
    input.focus();
    input.select();
  }

  @autobind
  setColor(index, color) {
    const projectsGroup = Object.assign({}, this.state.projectsGroups[index]);
    projectsGroup.color = color;
    projectsGroup.isUpdated = true;

    this.setState({
      projectsGroups: [
        ...this.state.projectsGroups.slice(0, index),
        projectsGroup,
        ...this.state.projectsGroups.slice(index + 1),
      ],
    });
  }

  getGroups() {
    return this.state.projectsGroups.filter((projectsGroup) => !projectsGroup.isBeingAdded);
  }

  getDeletedGroups() {
    return this.state.projectsGroups.filter((projectsGroup) => (
      projectsGroup.id && (projectsGroup.isBeingDeleted || projectsGroup.isDeleted)
    )).map((projectsGroup) => {
      const selectedGroupData = this.state.projectsGroups[projectsGroup.selectedGroup];
      return {
        mode: projectsGroup.deleteOption,
        id: projectsGroup.id,
        temporaryId: selectedGroupData ? selectedGroupData.temporaryId : 0,
        projectGroupIndex: projectsGroup.selectedGroup,
        projectsCount: projectsGroup.projects.length,
      };
    });
  }

  getUpdatedGroups() {
    return this.state.projectsGroups.filter((projectsGroup) => (
      projectsGroup.isUpdated && projectsGroup.id && !projectsGroup.isBeingDeleted && !projectsGroup.isDeleted
    )).map((projectsGroup) => ({
      id: projectsGroup.id,
      name: projectsGroup.name,
      color: projectsGroup.color,
    }));
  }

  getAddedGroups() {
    // eslint-disable-next-line array-callback-return
    return this.state.projectsGroups.map((projectsGroup) => {
      if (!projectsGroup.id && !projectsGroup.isBeingAdded
        && !projectsGroup.isBeingDeleted && !projectsGroup.isDeleted && !projectsGroup.type) {
        return {
          name: projectsGroup.name,
          color: projectsGroup.color,
          temporaryId: projectsGroup.temporaryId,
        };
      }
    }).filter((projectsGroup) => !!projectsGroup);
  }

  getLoadingClass() {
    const { isLoading } = this.props;
    return isLoading ? this.classes.LOADING : '';
  }

  getWorkingClass() {
    const { isSaving } = this.props;
    return isSaving;
  }

  @autobind
  onOpen() {
    const { fetchProjectsGroups } = this.props;
    fetchProjectsGroups();
  }

  getGroupsWord(groupCount, withDemonstrativePronoun = false) {
    const noun = groupCount > 1 ? 'groups' : 'group';

    if (withDemonstrativePronoun) {
      const pronoun = groupCount > 1 ? 'these' : 'this';

      return `${pronoun} ${noun}`;
    }

    return noun;
  }

  @autobind
  save() {
    const groups = this.getGroups();
    let isUsersModified = false;
    let isUserAdded = false;
    let isUserRemoved = false;
    const groupsNames = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < groups.length; i++) {
      const group = groups[i];

      if (!(group.isBeingAdded || group.isBeingDeleted || group.temporaryId)) {
        if (group.selectedUsers.length > group.savedSelectedUsers.length) {
          isUserAdded = true;
          groupsNames.push(group.name);
        } else if (group.selectedUsers.length < group.savedSelectedUsers.length) {
          isUserRemoved = true;
          groupsNames.push(group.name);
        } else if (!_.isEqual(group.selectedUsers, group.savedSelectedUsers)) {
          isUsersModified = true;
          groupsNames.push(group.name);
        }
      }
    }

    this.handleModalboxSave(groupsNames, isUserAdded, isUserRemoved, isUsersModified);
  }

  handleModalboxSave(groupsNames, isUserAdded, isUserRemoved, isUsersModified) {
    const groupTxt = this.getGroupsWord(groupsNames.length);
    const groupWithPronounTxt = this.getGroupsWord(groupsNames.length, true);

    if ((isUserAdded && isUserRemoved) || isUsersModified) {
      this.openModalboxGeneralMessage(
        <span>
          You have modified users in <strong>{groupsNames.join(', ')}</strong> {groupTxt}.
          These users will be added or removed from all projects in {groupWithPronounTxt}.
        </span>, 'Project Groups Management'
      );
    } else if (isUserAdded) {
      this.openModalboxGeneralMessage(
        <span>
          You have added new users to <strong>{groupsNames.join(', ')}</strong> {groupTxt}.
          They will now see all projects associated with {groupWithPronounTxt}.
        </span>, 'New Users Added'
      );
    } else if (isUserRemoved) {
      this.openModalboxGeneralMessage(
        <span>
          You have removed users from <strong>{groupsNames.join(', ')}</strong> {groupTxt}.
          These users will be removed from all projects in {groupWithPronounTxt}.
        </span>, 'Project Groups Management'
      );
    } else {
      this.onSave();
    }
  }

  trackMixpanelProjectDeleted(numberOfDeletedProjects) {
    getMixpanel((mixpanel) => {
      [...Array(numberOfDeletedProjects)].map(() => mixpanel.track('project_deleted'));
    });
  }

  @autobind
  modalboxGeneralAction() {
    this.onSave();
    this.closeModalboxGeneralMessage();
  }

  @autobind
  onSave() {
    const {
      saveProjectsGroups, canAddEditDeleteProjectGroups, groupsOrder, groupsExpandedMode, currentAccountId,
    } = this.props;
    this.resetTimers();
    const addedGroups = this.getAddedGroups();
    const updatedGroups = this.getUpdatedGroups();
    const deletedGroups = this.getDeletedGroups();
    const groups = this.getGroups();

    const numberOfDeletedProjects = deletedGroups
      .filter((deletedGroup) => deletedGroup.mode === removingGroupsModes.REMOVE)
      .reduce((acc, next) => acc + next.projectsCount, 0);

    this.trackMixpanelProjectDeleted(numberOfDeletedProjects);

    if (this.state.isInAddingMode) {
      this.setState({
        isInAddingMode: false,
      }, () => saveProjectsGroups(groups, addedGroups, updatedGroups, deletedGroups, canAddEditDeleteProjectGroups, groupsOrder, groupsExpandedMode, currentAccountId));
    } else {
      saveProjectsGroups(groups, addedGroups, updatedGroups, deletedGroups, canAddEditDeleteProjectGroups, groupsOrder, groupsExpandedMode, currentAccountId);
    }
  }

  renderError() {
    const { isError } = this.props;

    if (isError) {
      return (
        <li>
          <p className="error">Something went wrong!</p>
        </li>
      );
    }
  }

  @autobind
  openGroupsDeleteModal(groupData, index) {
    this.currentRemovedGroupData = groupData;
    this.currentRemovedGroupIndex = index;
    this.setState({
      isGroupsDeleteModalOpen: true,
      isHidden: true,
    });
  }

  @autobind
  closeGroupsDeleteModal() {
    this.setState({
      isGroupsDeleteModalOpen: false,
      isHidden: false,
    }, () => {
      this.currentRemovedGroupData = null;
      this.currentRemovedGroupIndex = null;
    });
  }

  @autobind
  openModalboxGeneralMessage(message, title) {
    this.setState({
      isModalboxGeneralMessageOpen: true,
      isHidden: true,
      message,
      title,
    });
  }

  @autobind
  closeModalboxGeneralMessage() {
    this.setState({
      isModalboxGeneralMessageOpen: false,
      isHidden: false,
    });
  }

  renderAddGroupBtn() {
    const { canAddEditDeleteProjectGroups } = this.props;

    if (canAddEditDeleteProjectGroups) {
      return (
        <nav>
          <a
            className="g-link add-group btn-blue-simple-add"
            href="#add-new-group"
            onClick={this.startAddingNewGroup}>
            Add new group
          </a>
        </nav>
      );
    }
  }

  @autobind
  onClose(e) {
    const { onClose } = this.props;
    e.preventDefault();
    _.delay(() => {
      this.setState({
        isInAddingMode: false,
        projectsGroups: [],
      });
    }, 500);

    onClose();
  }

  @autobind
  startDeleteGroup(currentGroupIndex, deleteOption, selectedGroup) {
    const projectsGroup = Object.assign({}, this.state.projectsGroups[currentGroupIndex]);
    projectsGroup.isBeingDeleted = true;
    projectsGroup.time = 20;
    projectsGroup.deleteOption = deleteOption;
    projectsGroup.selectedGroup = selectedGroup;

    this.setState({
      projectsGroups: [
        ...this.state.projectsGroups.slice(0, currentGroupIndex),
        projectsGroup,
        ...this.state.projectsGroups.slice(currentGroupIndex + 1),
      ],
    });
  }

  @autobind
  countTime(currentGroupIndex) {
    const projectsGroup = Object.assign({}, this.state.projectsGroups[currentGroupIndex]);
    if (projectsGroup) {
      projectsGroup.time -= 1;

      this.setState({
        projectsGroups: [
          ...this.state.projectsGroups.slice(0, currentGroupIndex),
          projectsGroup,
          ...this.state.projectsGroups.slice(currentGroupIndex + 1),
        ],
      });
    }
  }

  resetTimers() {
    this.setState({
      projectsGroups: this.state.projectsGroups.map((projectGroups) => {
        const group = Object.assign({}, projectGroups);
        if (projectGroups.isBeingDeleted) {
          group.time = 0;
        }

        return group;
      }),
    });
  }

  @autobind
  deleteGroup(currentGroupIndex) {
    const projectsGroup = Object.assign({}, this.state.projectsGroups[currentGroupIndex]);
    projectsGroup.isDeleted = true;
    delete projectsGroup.isBeingDeleted;
    delete projectsGroup.time;
    this.emitter.emit(events.DELETE_GROUP);

    this.setState({
      projectsGroups: [
        ...this.state.projectsGroups.slice(0, currentGroupIndex),
        projectsGroup,
        ...this.state.projectsGroups.slice(currentGroupIndex + 1),
      ],
    });
  }

  @autobind
  undoGroup(currentGroupIndex) {
    const projectsGroup = Object.assign({}, this.state.projectsGroups[currentGroupIndex]);
    delete projectsGroup.isBeingDeleted;
    delete projectsGroup.deleteOption;
    delete projectsGroup.selectedGroup;
    delete projectsGroup.time;

    this.setState({
      projectsGroups: [
        ...this.state.projectsGroups.slice(0, currentGroupIndex),
        projectsGroup,
        ...this.state.projectsGroups.slice(currentGroupIndex + 1),
      ],
    });
  }

  getOccupiedColors() {
    return this.state.projectsGroups.filter((group) => !!group.color).map((group) => group.color);
  }

  renderProjectsGroups() {
    const { canAddEditDeleteProjectGroups, currentUserId, membersData } = this.props;

    if (!this.state.projectsGroups.length) {
      return;
    }

    const parent = this.refs.groupsSettings ? this.refs.groupsSettings.parentNode : null;
    return this.state.projectsGroups.map((projectGroup, id) => {
      if (projectGroup.isBeingAdded || projectGroup.isBeingUpdated) {
        return (
          <GroupNew
            ref="groupNew"
            key={id}
            index={id}
            data={projectGroup}
            occupiedColors={this.getOccupiedColors()}
            saveGroup={this.saveGroup}
            className={(projectGroup.isBeingAdded ? 'new-group' : '')}
            parent={parent}
          />
        );
      } if (projectGroup.isDeleted) {
        return;
      }

      return (
        <Group
          data={projectGroup}
          index={id}
          emitter={this.emitter}
          membersData={membersData}
          currentUserId={currentUserId}
          canAddEditDeleteProjectGroups={canAddEditDeleteProjectGroups}
          addSelectedUsersToGroup={this.addSelectedUsersToGroup}
          openGroupsDeleteModal={this.openGroupsDeleteModal}
          startDeleteGroup={this.startDeleteGroup}
          deleteGroup={this.deleteGroup}
          undoGroup={this.undoGroup}
          countTime={this.countTime}
          setColor={this.setColor}
          onEdit={this.startEditingGroup}
          parent={parent}
          moveItem={this.moveGroup}
          key={id}
        />
      );
    });
  }

  render() {
    const classNames = classnames({ 'no-transition': this.props.isModalboxGroupsSettingsOpen });

    return (
      <Modal
        wrapperId={this.MODAL.WRAPPER_ID}
        title={this.MODAL.TITLE}
        classNames={classNames}
        renderDescription={this.renderDescription}
        isOpen={this.props.isOpen}
        isHidden={this.state.isHidden}
        onOpen={this.onOpen}
        onClose={this.onClose}>
        {this.props.connectDropTarget(
          <section className={`modal-box-content ${this.getLoadingClass()}`}>
            <section ref={(node) => { this.node = node; }}>
              {this.renderProjectsGroups()}
            </section>
          </section>
        )}
        {this.renderAddGroupBtn()}
        <footer>
          <ul className="btns-group">
            {this.renderError()}
            <li>
              <Link appearance={APPEARANCES.NEUTRAL} href="#cancel" onClick={this.onClose}>Cancel</Link>
            </li>
            <li>
              <Button onClick={this.save} isWorking={this.getWorkingClass()}>Save</Button>
            </li>
          </ul>
        </footer>
        <ModalboxGeneralMessage
          isOpen={this.state.isModalboxGeneralMessageOpen}
          onClose={this.closeModalboxGeneralMessage}
          onAccept={this.modalboxGeneralAction}
          title={this.state.title || 'Project Groups Management'}
          message={this.state.message}
        />
        <GroupsDelete
          isOpen={this.state.isGroupsDeleteModalOpen}
          currentGroup={this.currentRemovedGroupData}
          currentGroupIndex={this.currentRemovedGroupIndex}
          projectsGroups={this.state.projectsGroups}
          deleteGroup={this.startDeleteGroup}
          onClose={this.closeGroupsDeleteModal}
        />
      </Modal>
    );
  }
}

export default DropTarget(sortableItemsTypes.GROUP, getBoxTarget('#groups-settings-modal-box .modal-box-content', 30), (connect) => ({
  connectDropTarget: connect.dropTarget(),
}))(GroupsSettings);

GroupsSettings.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
