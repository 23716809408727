/* eslint-disable react/no-unescaped-entities */

'use strict';

import React from 'react';
import classnames from 'classnames';
import { GLOBAL_ELEMENTS } from '@uxpin/shared-components';
import { isEqual } from 'lodash';

export default class ProjectEmpty extends React.Component {
  shouldComponentUpdate(nextProps) {
    // eslint-disable-next-line react/destructuring-assignment
    return Object.keys(this.props).some((propName) => !isEqual(this.props[propName], nextProps[propName]));
  }

  render() {
    // eslint-disable-next-line react/prop-types
    const { isArchived } = this.props;
    const headingClasses = classnames('name', GLOBAL_ELEMENTS.HEADING_3);
    let archived;

    if (isArchived) {
      archived = (
        <p className="archive-info">
          Archive projects you don't edit or review anymore.
          <br />
          Choose
          "archive" in the "more menu" when you hover over project. You'll be able to restore them to the
          dashboard at any time.
        </p>
      );
    }

    // When its used, '.project-group' must have class 'empty'
    // Should be as the only one Project in the Projects Group
    return (
      <section className="project-box project-empty">
        <header className="header">
          <h3 className={headingClasses}>
            Nothing here just yet
          </h3>
          {archived}
        </header>
      </section>
    );
  }
}
