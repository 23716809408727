/* eslint-disable react/destructuring-assignment,react/prop-types */
/* eslint-disable react/require-default-props */

'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import autobind from 'autobind-decorator';
import validator from 'validator';
import _ from 'lodash';
import { StringUtils } from '@uxpin/shared-components';
import Input from './Input';
import Select from './Select';
import keys from '../../../../constants/keys.constants';
import { validatorMessages } from '../../../../utils/validatorMessages';

export default class Form extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isValid: true,
    };

    this.fields = {};
    this.requiredMethod = 'isLength:1';
    this.MODES = {
      ON_BLUR: 'onBlur',
      ON_CHANGE: 'onChange',
    };
  }

  componentDidMount() {
    if (this.props.autofocus) {
      if (!_.isEmpty(this.fields)) {
        this.fields[Object.keys(this.fields)[0]].setState({
          focus: true,
        });
      }
    }
  }

  renderChildrenWithProps(children) {
    return React.Children.map(children, (child) => {
      let newProps = {};

      if ([Input, Select].indexOf(child.type) !== -1) {
        const validations = this.getValidations(child);

        newProps = {
          attachToForm: this.attachToForm,
          detachFromForm: this.detachFromForm,
          MODES: this.MODES,
          validate: this.validate,
          isFieldsValue: this.isFieldsValue,
          validations,
        };

        if (child.type === Input && this.props.checkMode === this.MODES.ON_BLUR) {
          newProps = Object.assign({}, newProps, {
            checkMode: this.MODES.ON_BLUR,
          });
        }

        if (child.type === Select && this.props.checkMode === this.MODES.ON_BLUR) {
          newProps = Object.assign({}, newProps, {
            checkMode: this.MODES.ON_CHANGE,
          });
        }
      }

      if (!_.isObject(child)) {
        return child;
      }

      if (child.props && _.isObject(child.props.children)) {
        newProps.children = this.renderChildrenWithProps(child.props.children);
      }

      return React.cloneElement(child, newProps);
    });
  }

  getValidations(child) {
    let validations = '';
    let additionalValidations = '';

    if ([Input, Select].indexOf(child.type) === -1) { return ''; }

    if (!_.isUndefined(child.props.required) && child.props.required) {
      additionalValidations = this.requiredMethod;
    }

    if (child.props.validations && additionalValidations) {
      validations = `${child.props.validations},${additionalValidations}`;
    } else if (child.props.validations) {
      validations = child.props.validations;
    } else {
      validations = additionalValidations;
    }

    return validations;
  }

  @autobind
  validate(component) {
    if (!component.props.validations) {
      return;
    }

    let isValid = true;
    let errorMessage = '';

    if (component.state.value || component.props.required) {
      component.props.validations.split(',').forEach((validation) => {
        let args = validation.split(':');
        const validateMethod = args.shift();
        let options = [];

        options = args.map((arg) => JSON.parse(arg));
        args = [component.state.value].concat(options);

        if (!validator[validateMethod](...args)) {
          isValid = false;
          errorMessage = validatorMessages(validateMethod, options);
        }
      });
      if (_.has(component.props, 'customValidation')
          && component.props.customValidation === false) {
        isValid = false;

        if (_.has(component.props, 'customValidationMessage')) {
          errorMessage = component.props.customValidationMessage;
        }
      }
    }

    component.setState({
      isValid,
      errorMessage,
    }, this.validateForm);
  }

  @autobind
  validateForm() {
    let isValid = true;
    const fields = this.fields;
    const { onValidateForm } = this.props;

    Object.keys(fields).forEach((name) => {
      if (!fields[name].state.isValid) {
        isValid = false;
      }
    });

    this.setState({
      isValid,
    }, onValidateForm);
  }

  @autobind
  attachToForm(component) {
    this.fields[component.props.name] = component;
  }

  @autobind
  detachFromForm(component) {
    delete this.fields[component.props.name];
  }

  @autobind
  updateModel() {
    Object.keys(this.fields).forEach((name) => {
      this.validate(this.fields[name]);
    });

    setTimeout(() => {
      this.handleResult();
    }, 0);
  }

  @autobind
  isFieldsValue() {
    let isValue = false;

    Object.keys(this.fields).forEach((name) => {
      const value = this.fields[name].state.value;

      if (value) {
        isValue = true;
      }
    });

    return isValue;
  }

  @autobind
  handleSubmit(e) {
    if (!_.isUndefined(e)) {
      e.preventDefault();
    }

    this.updateModel();
  }

  @autobind
  handleKeyDown(e) {
    if (e.keyCode === keys.ENTER && !this.props.disableSubmitOnEnter) {
      this.handleSubmit();
    }
  }

  handleResult() {
    if (this.state.isValid && _.isFunction(this.props.onSuccess)) {
      const valueTrimmed = (input) => StringUtils.reduceWhiteSpaces(input.state.value).trim();

      this.props.onSuccess(_.mapValues(this.fields, valueTrimmed));
    } else if (!this.state.isValid && _.isFunction(this.props.onError)) {
      this.props.onError();
    }
  }

  renderSubmitBtn() {
    const { customSubmitBtn, submitBtnCopy } = this.props;

    if (!customSubmitBtn) {
      return <button type="submit" className="btn-solid btn-wide">{submitBtnCopy}</button>;
    }
  }

  render() {
    return (
      <form
        autoComplete={this.props.autoComplete}
        onSubmit={this.handleSubmit}
        onKeyDown={this.handleKeyDown}>
        {this.renderChildrenWithProps(this.props.children)}
        {this.renderSubmitBtn()}
      </form>
    );
  }
}

Form.propTypes = {
  autoComplete: PropTypes.string,
  submitBtnCopy: PropTypes.string,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
  checkMode: PropTypes.string,
  autofocus: PropTypes.bool,
  customSubmitBtn: PropTypes.bool,
  onValidateForm: PropTypes.func,
  disableSubmitOnEnter: PropTypes.bool,
};

Form.defaultProps = {
  autoComplete: 'on',
  submitBtnCopy: 'Submit',
  checkMode: '',
  onValidateForm: _.noop,
  disableSubmitOnEnter: false,
};
