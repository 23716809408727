/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */

import React from 'react';
import PropTypes from 'prop-types';
import autobind from 'autobind-decorator';
import classNames from 'classnames';
import { noop, toNumber, map } from 'lodash';
import { Cloud, cloudPositions } from '@uxpin/shared-components';

const SELECT_PLACEHOLDER = 'Select template';

export class DesignSystemsTemplatesSelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedName: '',
      selectedElementId: '',
    };
  }

  @autobind
  handleSelect(e) {
    const data = e.target.dataset;
    const selectedName = data.name;
    const selectedElementId = data.id;

    if (!selectedName) {
      return;
    }

    this.setState({
      selectedName,
      selectedElementId,
    });
    this.props.onTemplateSelect(data.templateName);
  }

  @autobind
  renderLibraryItemAvailable(template) {
    const {
      id, libraryHash, name, templateName,
    } = template;
    const selectedElementId = toNumber(this.state.selectedElementId);
    const elementClassName = classNames({ selected: selectedElementId === id });

    return (
      <li
        className={elementClassName}
        data-hash={libraryHash}
        data-id={id}
        data-template-name={templateName}
        key={id}
        onClick={this.handleSelect}
        data-name={name}>
        {name}
      </li>
    );
  }

  renderTemplatesList(templates) {
    return (
      <ul {...this.props.cloud} className="cloud select-style-list without-arrow scroll-ready options-cloud">
        {map(templates, this.renderLibraryItemAvailable)}
      </ul>
    );
  }

  render() {
    const { templates, isDisabled } = this.props;
    const selectedName = this.state.selectedName || SELECT_PLACEHOLDER;
    const isEmptyList = !templates.length;
    const wrapperClassNames = classNames('select-wrapper', {
      'empty-list': isEmptyList || isDisabled,
    });

    if (isEmptyList || isDisabled) {
      return (
        <div className={wrapperClassNames}>
          <div className="value-placeholder">{selectedName}</div>
        </div>
      );
    }

    return (
      <div className={wrapperClassNames} {...this.props.trigger}>
        {this.renderTemplatesList(templates)}
        <div className="value-placeholder">{selectedName}</div>
      </div>
    );
  }
}

DesignSystemsTemplatesSelect.propTypes = {
  templates: PropTypes.array,
  onTemplateSelect: PropTypes.func,
};

DesignSystemsTemplatesSelect.defaultProps = {
  templates: [],
  onTemplateSelect: noop,
};

export default Cloud({
  availablePositions: [cloudPositions.TOP, cloudPositions.BOTTOM],
  offset: {
    bottom: { x: 0, y: -50 },
    top: { x: 0, y: 50 },
  },
})(DesignSystemsTemplatesSelect);
