import { connect } from 'react-redux';
import { modalsIds } from '../../constants/modal.constants';
import * as modal from '../../controllers/actions/modal.actions';
import { openFeatureUpgrade } from '../../controllers/actions/pricing.actions';
import AccountSettingsComponent from '../components/Topbar/components/AccountSettings';
import { FEATURE_STATUS } from '../../constants/features.constants';

export const mapStateToProps = (state) => {
  const { data, isFetchUserDataSuccess } = state.currentUser;
  const { isLoading } = state.projects;
  const { permissions } = state.permissions;
  const {
    features: {
      drift_support: intercom,
      project_status: projectStatus,
    },
  } = state.features; // eslint-disable-line camelcase
  const { data: settings, areSettingsFetched } = state.settings;
  const { changelog: changelogItems } = state.prismicContent;

  return {
    areSettingsFetched,
    changelogItems,
    currentUserData: data,
    isFetchUserDataSuccess,
    isIntercomSupported: intercom === FEATURE_STATUS.ENABLED,
    isLoading,
    isProjectStatusEnabled: projectStatus === FEATURE_STATUS.ENABLED,
    permissions,
    settings,
  };
};

export const mapDispatchToProps = (dispatch) => ({
  openModal: () => dispatch(modal.openModal(modalsIds.MODALBOX_CHANGELOG)),
  openFeatureUpgrade: (feature) => dispatch(openFeatureUpgrade(feature)),
});

export const AccountSettings = connect(mapStateToProps, mapDispatchToProps)(AccountSettingsComponent);
