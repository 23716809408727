'use strict';

import { modalActions } from '../../constants/modal.constants';

export function modalReducer(state = {
  data: {},
  openedModalsIds: {},
  gdprClosed: false,
}, action) {
  let openedModalsIds;
  let data;
  switch (action.type) {
    case modalActions.OPEN_MODAL:
      openedModalsIds = Object.assign({}, state.openedModalsIds);
      openedModalsIds[action.idModal] = true;
      data = Object.assign({}, state.data);
      data[action.idModal] = action.data;
      return Object.assign({}, state, {
        data,
        openedModalsIds,
      });
    case modalActions.CLOSE_GDPR:
      return Object.assign({}, state, {
        gdprClosed: true,
      });
    case modalActions.CLOSE_MODAL:
      openedModalsIds = Object.assign({}, state.openedModalsIds);
      delete openedModalsIds[action.idModal];
      return Object.assign({}, state, {
        openedModalsIds,
      });
    case modalActions.CLOSE_OPENED_MODAL:
      return Object.assign({}, state, {
        openedModalsIds: {},
      });
    case modalActions.REQUEST_SUBMITTING_WELCOME_FORM:
      return Object.assign({}, state, {
        formSubmitted: true,
      });
    default:
      return state;
  }
}
