'use strict';

import { connect } from 'react-redux';
import { ExportInfoContainer as ExportInfoContainerComponent } from '@uxpin/shared-components';
import * as shareActions from '../../controllers/actions/share.actions';

export const mapStateToProps = (state) => {
  const { exports } = state.share;

  return {
    exports,
  };
};

export const mapDispatchToProps = (dispatch) => ({
  getExportStatus: () => {
    dispatch(shareActions.getExportStatus());
  },

  discardExportInfo: (index) => {
    dispatch(shareActions.discardExportInfo(index));
  },
});

export const ExportInfoContainer = connect(mapStateToProps, mapDispatchToProps)(ExportInfoContainerComponent);
