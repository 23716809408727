import { connect } from 'react-redux';
import { setInsideProjectViewMode } from '../../controllers/actions/insideProject.actions';
import ViewModesComponent from '../components/InsideProjectBar/components/ViewModes';

export const mapStateToProps = (state) => {
  const { insideProjectViewMode } = state.insideProject;

  return {
    insideProjectViewMode,
  };
};

export const mapDispatchToProps = (dispatch) => ({
  setInsideProjectViewMode: (mode) => {
    dispatch(setInsideProjectViewMode(mode));
  },
});

export const ViewModes = connect(mapStateToProps, mapDispatchToProps)(ViewModesComponent);
