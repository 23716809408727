/* eslint-disable react/no-unused-state */

'use strict';

import React from 'react';
import autobind from 'autobind-decorator';
import { cloudPositions } from '@uxpin/shared-components';
import { AccountSettings } from '../../../containers/AccountSettings';

export default class Settings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isGlobalHelpOpen: false,
    };
  }

  @autobind
  openGlobalHelp(e) {
    e.preventDefault();
    this.setState({
      isGlobalHelpOpen: true,
    });
  }

  @autobind
  closeGlobalHelp() {
    this.setState({
      isGlobalHelpOpen: false,
    });
  }

  render() {
    return (
      <ul className="options-group">
        <li className="with-separator">
          <AccountSettings
            autoClose
            defaultPosition={cloudPositions.BOTTOM_LEFT}
          />
        </li>
        {/*
        <li>
          <a href="#help"
             className="btn-icon icon-general-help"
             onClick={this.openGlobalHelp}
             data-show-node="tooltip">
            <em className="float-right tooltip">Help &amp; Tutorials</em>
          </a>
        </li>
        <GlobalHelp
          isOpen={this.state.isGlobalHelpOpen}
          onClose={this.closeGlobalHelp} />
        */}
        <li>
          <h2 className="icon-general-uxpin-logo only-icon-font">UXPin</h2>
        </li>
      </ul>
    );
  }
}
