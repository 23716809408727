/* eslint-disable max-len */
/* eslint-disable react/destructuring-assignment */

'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import autobind from 'autobind-decorator';
import classnames from 'classnames';
import key from 'keymaster';
import _ from 'lodash';
import { GLOBAL_ELEMENTS } from '@uxpin/shared-components';
import config from 'react-global-configuration';
import { hasAccessToDocument } from '../../../../utils/user';

const LEFT_MOUSE_BTN = 0;
const INTERACTIONS_HIGHLIGHT_DISABLED_MODE = 'i';

export default class ProjectBoxActionsCenter extends React.Component {
  getPreviewLink() {
    const { PREVIEW_URL } = config.get();
    const { document } = this.props;

    return `${PREVIEW_URL}/${document.hash}`;
  }

  @autobind
  handleEditorLinkClick(e) {
    const { document, editUrl } = this.props;

    if (!key.ctrl && !key.command && !key.shift && e.button === LEFT_MOUSE_BTN) {
      e.preventDefault();
      this.props.setDocumentWorkingState(document.idDocument);
      this.props.toggleButtonsWorkingStates(true, false);
      setTimeout(() => { window.location.href = editUrl; }, 50);
    }
  }

  @autobind
  handlePreviewLinkClick() {
    this.props.setDocumentWorkingState(document.idDocument);
    this.props.toggleButtonsWorkingStates(false, true);
  }

  getEditBtnClasses() {
    return classnames('edit', 'btn-ghost', 'gray', 'icon-general-pencil', 'custom-color', { working: this.props.isWorkingEdit && this.props.isDocumentWorking });
  }

  getPreviewBtnClasses() {
    return classnames('preview', 'btn-ghost', 'gray', 'icon-general-play', 'custom-color', { working: this.props.isWorkingPreview && this.props.isDocumentWorking });
  }

  renderPreviewLink() {
    const { document } = this.props;

    if (document.isChromeExtensionUpload) {
      return null;
    }

    return (<li><a target="_blank" href={this.getPreviewLink() + INTERACTIONS_HIGHLIGHT_DISABLED_MODE} onClick={this.handlePreviewLinkClick} className={this.getPreviewBtnClasses()}>Preview</a></li>);
  }

  renderEditorLink() {
    const { document, editUrl } = this.props;

    if (!hasAccessToDocument(this.props) || document.isChromeExtensionUpload || document.isPreviewOnly) {
      return null;
    }

    return (<li><a href={editUrl} onClick={this.handleEditorLinkClick} className={this.getEditBtnClasses()}>Edit design</a></li>);
  }

  render() {
    const listClassNames = classnames('btn-group', GLOBAL_ELEMENTS.UNORDERED_LIST);

    return (
      <section className="actions-center">
        <ul className={listClassNames}>
          {this.renderEditorLink()}
          {this.renderPreviewLink()}
        </ul>
      </section>
    );
  }
}

ProjectBoxActionsCenter.propTypes = {
  document: PropTypes.object.isRequired,
  editUrl: PropTypes.string.isRequired,
  isDocumentWorking: PropTypes.bool,
  isWorkingEdit: PropTypes.bool,
  isWorkingPreview: PropTypes.bool,
  setDocumentWorkingState: PropTypes.func,
  toggleButtonsWorkingStates: PropTypes.func,
};

ProjectBoxActionsCenter.defaultProps = {
  isDocumentWorking: false,
  isWorkingEdit: false,
  isWorkingPreview: false,
  setDocumentWorkingState: _.noop,
  toggleButtonsWorkingStates: _.noop,
};
