'use strict';

import config from 'react-global-configuration';
import Cookies from 'js-cookie';
import moment from 'moment';
import {
  isArray, isEqual, pickBy, size,
} from 'lodash';
import {
  excludedFromSpecPromo, isSpecModePlan, specmodeAgainPromoSegments, isDesignViewsModelPlan, isFree,
} from '../constants/accountSettings.constants';
import {
  validationRules, addOnPromoTopBarVersions, addOns, promotions, gtmDataCookieName, timeIntervals, missingAccountType,
} from '../constants/marketing.constants';
import { settingsName } from '../constants/settings.constants';
import {
  getSettingSeenName, getSettingVersionSeenName, getSettingPurchasedName,
  getMixedTypeSetting, getBoolSetting
} from './settings';
import { isFeatureEnabled } from './account';
import { googleAnalytics } from './analyticsManager';
import { FEATURES, FEATURE_STATUS } from '../constants/features.constants';

const { API_URL } = config.get();
const AUTOPILOT_TRACKING_EVENT_PROXY = `${API_URL}/data/events`;

export const countdown = (endTime) => {
  const addLeadingZero = (val) => {
    const value = parseInt(val, 10);
    const leadingZeroString = value < 10 ? '0' : '';
    return `${leadingZeroString}${value}`;
  };

  const getMilisecondsUntilDate = (endDate) => Math.floor(moment(Date.parse(endDate)).diff(moment()));

  const getHoursLeft = (miliseconds) => addLeadingZero(Math.floor(miliseconds / 1000 / 3600));

  const getMinutesLeft = (miliseconds) => addLeadingZero(moment.duration(miliseconds).minutes());

  const getSecondsLeft = (miliseconds) => addLeadingZero(moment.duration(miliseconds).seconds());

  const getTimeLeftObject = () => {
    let diffMiliseconds = getMilisecondsUntilDate(endTime);

    if (diffMiliseconds <= 0) {
      diffMiliseconds = 0;
    }

    return {
      hours: getHoursLeft(diffMiliseconds),
      minutes: getMinutesLeft(diffMiliseconds),
      seconds: getSecondsLeft(diffMiliseconds),
    };
  };

  const isTimeLeft = (timeObject) => size(pickBy(timeObject, (value) => value !== 0)) > 0;

  return {
    getTimeLeft() {
      const timeLeft = getTimeLeftObject();

      if (!isTimeLeft(timeLeft)) {
        return 0;
      }

      const { hours, minutes, seconds } = timeLeft;

      return `${hours}:${minutes}:${seconds}`;
    },

    getFormattedHoursAndMinutesLeft() {
      const timeLeft = getTimeLeftObject();

      if (!isTimeLeft(timeLeft)) {
        return 0;
      }

      const { hours, minutes } = timeLeft;

      return `${hours}hrs:${minutes}min`;
    },

    getFormattedDaysLeft() {
      const timeLeft = getTimeLeftObject();

      if (!isTimeLeft(timeLeft)) {
        return 0;
      }

      const { hours } = timeLeft;
      const days = Math.floor(hours / 24);

      return `${days} days`;
    },
  };
};

export const isPromoTimeLeft = (endTime) => (
  Math.floor(moment(Date.parse(endTime)).diff(moment())) > 0
);

export const isTimeLowerThanLimit = (endTime, limit) => (
  Math.floor(moment(Date.parse(endTime)).diff(moment())) < limit
);

export const isValidForOnlineSalesSpecPromo = (accountPlan) => accountPlan.is_trial === false;

export const isValidForInsideSalesSpecPromo = (membersCount, accountPlan) => (
  !isSpecModePlan(accountPlan.name) && membersCount > 3
  && excludedFromSpecPromo.indexOf(accountPlan.name) === -1
  && accountPlan.is_subscription === true && accountPlan.is_trial === false
);

export const isValidReactivatedForSpecPromo = (accountPlan) => accountPlan.is_trial === true;

export const isPlanWithSpecMode = (accountPlan) => (
  excludedFromSpecPromo.indexOf(accountPlan.name) > -1 || isSpecModePlan(accountPlan.name)
);

export const getSpecmodeAgainPromoSegment = (accountPlan) => {
  if (!accountPlan || isDesignViewsModelPlan(accountPlan.name)) {
    return false;
  }

  if (isValidForOnlineSalesSpecPromo(accountPlan)) {
    return isPlanWithSpecMode(accountPlan) ? specmodeAgainPromoSegments.INFORMATIVE : specmodeAgainPromoSegments.UPSELL;
  } if (isValidReactivatedForSpecPromo(accountPlan)) {
    return specmodeAgainPromoSegments.WINBACK;
  }

  return specmodeAgainPromoSegments.INFORMATIVE;
};


export const isPhoneNumberValid = (phoneNumber) => {
  const phoneNumberDigitsCount = phoneNumber.replace(/[^0-9]/g, '').length;

  return (phoneNumberDigitsCount >= validationRules.PHONE_MIN_LENGTH
    && phoneNumber.match(validationRules.PHONE_REGEX) !== null);
};

export const getPromotionByName = (name, promotionsArray) => {
  if (!isArray(promotionsArray) || promotionsArray.length === 0) {
    return null;
  }

  const promos = promotionsArray.slice();
  const promo = promos.shift();

  if (promo['promotion.uniqueName'] === name) {
    return promo;
  }

  return getPromotionByName(name, promos);
};

export const hasOnboardingTest = (promos) => getPromotionByName(promotions.ONBOARDING_STEPS_Q4, promos);
export const hasOnboardingEduPath = (promos) => getPromotionByName(promotions.ONBOARDING_EDU_PATH, promos);

export const isInGoogleSignInTest = (promos) => !!getPromotionByName(promotions.GOOGLE_SIGN_IN_TEST, promos);

// eslint-disable-next-line max-len
export const userHasCouponCode = (couponCode, accountList) => size(pickBy(accountList, (account) => isEqual(account.couponCode, couponCode))) > 0;

export const isAddOnUpsellTopBarOpen = (props, currentPromoIds) => {
  const { isFetchUserDataSuccess, settings } = props;
  const promoSeenKey = getSettingSeenName(currentPromoIds.promo);
  const addOnPurchasedKey = getSettingPurchasedName(currentPromoIds.promo);
  const topBarVersionSeenKey = getSettingVersionSeenName(currentPromoIds.topbar);

  if (isFetchUserDataSuccess) {
    if (settings
      && settings[promoSeenKey]
      && settings[addOnPurchasedKey] === false
      && settings[topBarVersionSeenKey] < addOnPromoTopBarVersions.SECOND_MESSAGE) {
      return true;
    }
  }

  return false;
};

export const isAddOnUpsellPromotionOpen = (props, currentAddOnPromoIds) => {
  const { isFetchUserDataSuccess, settings, marketing } = props;
  const promoSeenKey = getSettingSeenName(currentAddOnPromoIds.promo);
  const addOnPurchasedKey = getSettingPurchasedName(currentAddOnPromoIds.promo);

  if (isFetchUserDataSuccess) {
    if (settings
      && settings[promoSeenKey] === false
      && settings[addOnPurchasedKey] === false
      && marketing && !marketing.isAddOnUpgradeModalOpen) {
      return true;
    }
  }

  return false;
};

export const isAddOnUpsellUpgradeOpen = (props) => {
  const { isFetchUserDataSuccess, marketing } = props;

  if (isFetchUserDataSuccess
    && marketing && marketing.isAddOnUpgradeModalOpen) {
    return true;
  }

  return false;
};

export const getAddonPromoId = (addOnName) => `ADD_ON_${addOnName}_PROMO`;
export const getAddonFeatureName = (addOnName) => `${addOnName}_ADDON`;

export const getEnabledPromoAddOn = (features) => {
  const addOnNames = Object.values(addOns);
  let enabledAddOn;

  addOnNames.some((addOnName) => {
    const featureEnabled = isFeatureEnabled(features, getAddonFeatureName(addOnName));
    enabledAddOn = featureEnabled ? addOnName : undefined;

    return featureEnabled;
  });

  return enabledAddOn;
};

export const setDataCookie = (gtmData) => {
  const { COOKIE_DOMAIN } = config.get();
  const expirationDays = 31;

  Cookies.set(gtmDataCookieName, btoa(JSON.stringify(gtmData)), {
    path: '/',
    domain: COOKIE_DOMAIN,
    expires: expirationDays,
  });
};

export const handleLoginEvents = (loginCookie, registerCookie, projectCount, accountLevel, userIds) => {
  const { ownerId, userId, accountId } = userIds;

  try {
    if (loginCookie !== undefined && registerCookie !== undefined) {
      googleAnalytics.dataLayerRegisterEvent(userId, ownerId, accountId)
        .then(() => {
          const firstLogin = !!parseInt(loginCookie, 10);
          googleAnalytics.dataLayerLoginEvent(
            userId,
            ownerId,
            accountId,
            accountLevel,
            projectCount,
            firstLogin
          );
        });
    } else if (registerCookie !== undefined) {
      googleAnalytics.dataLayerRegisterEvent(userId, ownerId, accountId);
    } else if (loginCookie !== undefined) {
      const firstLogin = !!parseInt(loginCookie, 10);
      googleAnalytics.dataLayerLoginEvent(
        userId,
        ownerId,
        accountId,
        accountLevel,
        projectCount,
        firstLogin
      );
    }
  } catch (e) {
    if (window.Rollbar) {
      window.Rollbar.error(e);
    }
    console.error(e);
  }
};

export const shouldOpenNamesModal = (props) => {
  const { firstName, lastName } = props.currentUserData;
  const { enforceUserNames } = props.accountSettings;
  const { features } = props;
  const enforceNamesOption = features[FEATURES.ENFORCE_NAMES_OPTION] === FEATURE_STATUS.ENABLED;

  return enforceNamesOption && enforceUserNames && (!firstName || !lastName);
};

export const sendToAutopilotProxy = (userId, eventName) => {
  const fetchHeader = { 'Content-type': 'application/json' };
  const fetchBody = JSON.stringify({ eventname: eventName });

  fetch(`${AUTOPILOT_TRACKING_EVENT_PROXY}/${userId}`, {
    method: 'POST',
    headers: fetchHeader,
    body: fetchBody,
  });
};
