'use strict';

import { projectsGroupsActions } from '../../constants/projectsGroupsManagement.constants';

export function projectsGroupsManagementReducer(state = {
  isLoading: false,
  isError: false,
  isSaving: false,
  projectsGroups: [],
  groupsAccess: [],
}, action) {
  switch (action.type) {
    case projectsGroupsActions.FETCH_PROJECTS_GROUPS:
      return Object.assign({}, state, {
        isLoading: false,
        projectsGroups: action.projectsGroups,
      });
    case projectsGroupsActions.FETCH_PROJECTS_GROUPS_ACCESS:
      return Object.assign({}, state, {
        groupsAccess: action.access,
        isLoading: false,
      });
    case projectsGroupsActions.REQUEST_FETCHING_PROJECTS_GROUPS:
      return Object.assign({}, state, {
        isLoading: true,
        isError: false,
        projectsGroups: [],
      });
    case projectsGroupsActions.REQUEST_SAVING_PROJECTS_GROUPS:
      return Object.assign({}, state, {
        isError: false,
        isSaving: true,
      });
    case projectsGroupsActions.SAVE_PROJECTS_GROUPS:
      return Object.assign({}, state, {
        isError: false,
        isSaving: false,
      });
    case projectsGroupsActions.PROJECTS_GROUPS_ERROR:
      return Object.assign({}, state, {
        isError: true,
        isSaving: false,
        isLoading: false,
      });
    default:
      return state;
  }
}
