/* eslint-disable max-len */

import { omit, reduce } from 'lodash';
import { request } from '../../utils/request';
import { settingsAction, settingsName, favsFiltersDefault, starFilteringTypes } from '../../constants/settings.constants';
import { projectsSortingTypes } from '../../constants/projects.constants';
import { extendTrialSettings } from '../../constants/extendTrial.constants';
import { infobarIds } from '../../constants/infobar.constants';
import { modalsIds } from '../../constants/modal.constants';
import { onboardingStartSteps, onboardingFlowSettings, projectListLoadCompleted } from '../../constants/onboarding.constants';
import { promotions, marketingEvents, upsellCampaign2019 } from '../../constants/marketing.constants';
import {
  getBoolSetting,
  getIntegerSetting,
  getObjectSetting,
  getMixedTypeSetting,
  getSettingSeenName,
  getSettingVersionSeenName,
  getSettingPurchasedName,
  getSettingEventSentName,
} from '../../utils/settings';

const defaultSettings = {
  groupsOrder: {},
};

const getGroupsOrders = (data) => (
  reduce(data, (result, value, key) => {
    if (key.indexOf('groupsOrder') >= 0) {
      result[key] = getObjectSetting(value); // eslint-disable-line no-param-reassign
    }

    return result;
  }, {})
);

export const fetchSettings = () => (
  (dispatch) => (
    request.get('/settings')
      .then((data) => {
        const { PRIVACY_POLICY_0620_SEEN, MERGE_FROM_SUBMITTED, STORYBOOK_FROM_SUBMITTED } = settingsName;

        const AddOnSpecModeUpsellPromoBarVersionSeen = getSettingVersionSeenName(infobarIds.ADD_ON_SPEC_MODE_PROMO);
        const AddOnSpecModeUpsellPromoModalSeen = getSettingSeenName(modalsIds.MODALBOX_ADD_ON_SPEC_MODE_PROMO);
        const AddOnSpecModeUpsellPromotionSeen = getSettingSeenName(promotions.ADD_ON_SPEC_MODE_PROMO);
        const AddOnSpecModeUpsellPurchased = getSettingPurchasedName(promotions.ADD_ON_SPEC_MODE_PROMO);
        const AddOnCustomFontsUpsellPromoBarVersionSeen = getSettingVersionSeenName(infobarIds.ADD_ON_CUSTOM_FONTS_PROMO);
        const AddOnCustomFontsUpsellPromoModalSeen = getSettingSeenName(modalsIds.MODALBOX_ADD_ON_CUSTOM_FONTS_PROMO);
        const AddOnCustomFontsUpsellPromotionSeen = getSettingSeenName(promotions.ADD_ON_CUSTOM_FONTS_PROMO);
        const AddOnCustomFontsUpsellPurchased = getSettingPurchasedName(promotions.ADD_ON_CUSTOM_FONTS_PROMO);
        const prototypingUpsellVersionSeen = getSettingVersionSeenName(infobarIds.PROTOTYPING_UPSELL);
        const onboardingUrlFormSeen = getSettingSeenName(onboardingStartSteps.URL_FORM);
        const onboardingPathChoiceSeen = getSettingSeenName(onboardingStartSteps.PATH_CHOICE);
        const signUpEventSent = getSettingEventSentName(marketingEvents.SIGNED_UP);
        const firstIdentificationEventSent = getSettingEventSentName(marketingEvents.FIRST_IDENTIFICATION);
        const adwordsConversionSent = getSettingEventSentName(marketingEvents.ADWORDS_TRIAL_CONVERSION);
        const onboardingFlowStepSeen = getSettingSeenName(onboardingFlowSettings.ONBOARDING_STEP);
        const onboardingFlowSeen = getSettingSeenName(onboardingFlowSettings.ONBOARDING_FLOW);

        const settings = {
          showWelcomeModal: getMixedTypeSetting(data.showWelcomeModal, true),
          modalboxFreemiumTestIntroSeen: getBoolSetting(data.modalboxFreemiumTestIntroSeen),
          sketchPluginPromoModalboxVersionSeen: getIntegerSetting(data.sketchPluginPromoModalboxVersionSeen),
          showBillingSystemChangeModal: getMixedTypeSetting(data.showBillingSystemChangeModal),
          groupsExpandedMode: getObjectSetting(data.groupsExpandedMode),
          projectSortingType: data.projectSortingType || projectsSortingTypes.CREATE_DATE_DESC,
          documentSortingType: data.documentSortingType || projectsSortingTypes.CREATE_DATE_DESC,
          forcedExtendTrialFormSeen: getBoolSetting(data[extendTrialSettings.FORCED_EXTEND_TRIAL_FORM_SEEN]),
          onboardingWelcomeScreenSeen: getBoolSetting(data.onboardingWelcomeScreenSeen),
          accountSetupFormFilled: getBoolSetting(data.accountSetupFormFilled),
          [AddOnSpecModeUpsellPromoBarVersionSeen]: getIntegerSetting(data[AddOnSpecModeUpsellPromoBarVersionSeen]),
          [AddOnSpecModeUpsellPromotionSeen]: getBoolSetting(data[AddOnSpecModeUpsellPromotionSeen]),
          [AddOnSpecModeUpsellPurchased]: getBoolSetting(data[AddOnSpecModeUpsellPurchased]),
          [AddOnSpecModeUpsellPromoModalSeen]: getBoolSetting(data[AddOnSpecModeUpsellPromoModalSeen]),
          [AddOnCustomFontsUpsellPromoBarVersionSeen]: getIntegerSetting(data[AddOnCustomFontsUpsellPromoBarVersionSeen]),
          [AddOnCustomFontsUpsellPromotionSeen]: getBoolSetting(data[AddOnCustomFontsUpsellPromotionSeen]),
          [AddOnCustomFontsUpsellPurchased]: getBoolSetting(data[AddOnCustomFontsUpsellPurchased]),
          [AddOnCustomFontsUpsellPromoModalSeen]: getBoolSetting(data[AddOnCustomFontsUpsellPromoModalSeen]),
          [prototypingUpsellVersionSeen]: getIntegerSetting(data[prototypingUpsellVersionSeen]),
          [onboardingUrlFormSeen]: getBoolSetting(data[onboardingUrlFormSeen]),
          [onboardingPathChoiceSeen]: getBoolSetting(data[onboardingPathChoiceSeen]),
          [signUpEventSent]: getBoolSetting(data[signUpEventSent]),
          [firstIdentificationEventSent]: getBoolSetting(data[firstIdentificationEventSent]),
          changelogLastSeenDate: getIntegerSetting(data.changelogLastSeenDate),
          [adwordsConversionSent]: getBoolSetting(data[adwordsConversionSent]),
          [onboardingFlowStepSeen]: getIntegerSetting(data[onboardingFlowStepSeen]),
          [onboardingFlowSeen]: getBoolSetting(data[onboardingFlowSeen]),
          [PRIVACY_POLICY_0620_SEEN]: getBoolSetting(data[PRIVACY_POLICY_0620_SEEN]),
          [upsellCampaign2019.TEAM_UPSELL_MODAL_SEEN]: getBoolSetting(data[upsellCampaign2019.TEAM_UPSELL_MODAL_SEEN]),
          [upsellCampaign2019.PRO_UPSELL_MODAL_SEEN]: getBoolSetting(data[upsellCampaign2019.PRO_UPSELL_MODAL_SEEN]),
          [MERGE_FROM_SUBMITTED]: getBoolSetting(data[MERGE_FROM_SUBMITTED]),
          [STORYBOOK_FROM_SUBMITTED]: getBoolSetting(data[STORYBOOK_FROM_SUBMITTED]),
          designLibraries: getMixedTypeSetting(data.designLibraries),
          favsProjects: getObjectSetting(data.favsProjects, {}),
          favsPrototypes: getObjectSetting(data.favsPrototypes, {}),
          favsFilters: getObjectSetting(data.favsFilters, favsFiltersDefault),
          projectListLoadCompleted: false,
        };

        dispatch({
          type: settingsAction.SET_SETTINGS,
          data: Object.assign({}, defaultSettings, settings, getGroupsOrders(data)),
        });
      })
  )
);

export const putRequest = (name, value, isJson) => request.put('/settings', {
  body: {
    [name]: isJson ? JSON.stringify(value) : value,
  },
});

export const updateSettings = (name, value, isJson) => (
  (dispatch) => {
    dispatch({
      type: settingsAction.UPDATE_SETTING,
      name,
      value,
    });

    return putRequest(name, value, isJson);
  }
);

// Saves data but does not propagate change live
export const saveSettings = (name, value, isJson) => putRequest.bind(this, name, value, isJson);

export const toggleProjectStar = (idProject) => (dispatch, getState) => {
  const state = getState();
  const isStarred = isStarredProject(state, idProject);
  const favsProjects = getFavsProjects(state);
  const updatedFavsProjects = updateFavsItems(favsProjects, idProject, isStarred);

  return dispatch(updateSettings('favsProjects', updatedFavsProjects, true));
};

export function isStarredProject(state, idProject) {
  const favsProjects = getFavsProjects(state);
  const isStarred = Boolean(favsProjects[idProject]);

  return isStarred;
}

function getFavsProjects(state) {
  return state.settings.data.favsProjects || {};
}

export const togglePrototypeStar = (idPrototype) => (dispatch, getState) => {
  const state = getState();
  const isStarred = isStarredPrototype(state, idPrototype);
  const favsPrototypes = getFavsPrototypes(state);
  const updatedFavsPrototypes = updateFavsItems(favsPrototypes, idPrototype, isStarred);

  return dispatch(updateSettings('favsPrototypes', updatedFavsPrototypes, true));
};


export function isStarredPrototype(state, idPrototype) {
  const favsPrototypes = getFavsPrototypes(state);
  const isStarred = Boolean(favsPrototypes[idPrototype]);

  return isStarred;
}

function getFavsPrototypes(state) {
  return state.settings.data.favsPrototypes || {};
}

// Returns an array of updated projects or prototypes, depending on the context
// to update the Redux store and to be saved in the settings
function updateFavsItems(items, id, isStarred) {
  const updatedItems = isStarred
    ? omit(items, id)
    : { ...items, [id]: Date.now() };
  return updatedItems;
}

export const changeProjectFilterType = (newFilterType) => (dispatch, getState) => {
  const state = getState();
  const updatedFavsProjectsFilter = updateFavsFilters(state, 'favsProjects', newFilterType);

  return dispatch(updateSettings('favsFilters', updatedFavsProjectsFilter, true));
};

export const getProjectFilterType = (state) => state.settings.data.favsFilters.favsProjects;

export const changePrototypeFilterType = (newFilterType) => (dispatch, getState) => {
  const state = getState();
  const updatedFavsPrototypesFilter = updateFavsFilters(state, 'favsPrototypes', newFilterType);

  return dispatch(updateSettings('favsFilters', updatedFavsPrototypesFilter, true));
};

export const getPrototypeFilterType = (state) => state.settings.data.favsFilters.favsPrototypes;

function updateFavsFilters(state, filter, newFilterType) {
  const favsFilters = state.settings.data.favsFilters;
  const updatedFilter = { ...favsFilters, [filter]: newFilterType };
  return updatedFilter;
}

export function isItemIncluded(id, favsItems, filterType) {
  const isStarred = favsItems[id];
  if (filterType === starFilteringTypes.STARRED && !isStarred) {
    return false;
  }
  if (filterType === starFilteringTypes.UNSTARRED && isStarred) {
    return false;
  }

  return true;
}

export const setProjectListLoadCompleted = () => (dispatch) => {
  dispatch({
    type: projectListLoadCompleted,
    data: true,
  });
};
