'use strict';

import config from 'react-global-configuration';
import Cookies from 'js-cookie';
import { request } from '../../utils/request';
import { experimentsCookieNames } from '../../constants/experiments.constants';

export const pairIds = () => {
  if (!Cookies.get(experimentsCookieNames.USER_ID)) {
    return null;
  }

  return request.get('/experiments/pair_ids');
};

export const tagAccount = (timestamp) => {
  const accountTagName = experimentsCookieNames.EXISTING_ACCOUNT_TAG_FOR_PRICING_SWITCH;

  Cookies.set(accountTagName, timestamp, {
    domain: config.get('TOP_DOMAIN'),
    expires: 14,
  });
};
