import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { LibraryPermissions } from '@uxpin/shared-components';

import * as modal from '../../controllers/actions/modal.actions';
import { modalsIds } from '../../constants/modal.constants';

import {
  actionAddLibrary,
  actionChangeEditedLibrary,
  actionClearEditedLibrary,
  actionShowDesignLibrariesSettingsModal,
  actionDeleteLibrary,
  actionUpdateLibrary,
} from '../../controllers/actions/designSystems.actions';
import { settingsName } from '../../constants/settings.constants';
import { FEATURE_STATUS } from '../../constants/features.constants';
import { getMixpanel as mixpanel } from '../../../../shared/mixpanel';
import { onMergeRequestSubmit } from './MergeLibrarySetting';

const decoratedAddLibraryAction = (...args) => (
  (dispatch, getState) => {
    const modalData = getState().modal.data[modalsIds.MODALBOX_ADD_MERGE_LIBRARY_BY_GIT_AND_NPM];
    const shouldCreateDocument = modalData && modalData.shouldCreateDocument;
    return dispatch(actionAddLibrary(...args, shouldCreateDocument));
  }
);

const isMergeEnabledInFeatures = (isMergeEnabled) => isMergeEnabled === FEATURE_STATUS.ENABLED;

const isGitOnlyEnabledInFeatures = (isGitOnlyEnabled) => isGitOnlyEnabled === FEATURE_STATUS.ENABLED;

const isPackageIntegrationOnlyEnabledInFeatures = (isPackageIntegrationOnlyEnabled) => (
  isPackageIntegrationOnlyEnabled === FEATURE_STATUS.ENABLED
);

const ModalboxAddMergeLibrary = connect(
  (state) => ({
    accessScope: state.libraryPermissions.accessScope,
    allowAddUXPinLibrary: false,
    currentUserId: state.currentUser.data.id,
    idActiveAccount: state.currentUser.data.idActiveAccount,
    currentUserEmail: state.currentUser.data.email,
    editScope: state.libraryPermissions.editScope,
    error: state.libraryPermissions.errorLibrary,
    isLibraryDeleting: state.libraryPermissions.isLibraryDeleting,
    isLibraryProcessing: state.libraryPermissions.isLibraryProcessing,
    membersData: state.members.membersData,
    editedLibrary: {},
    features: state.features.features,
    isOpen: state.modal.openedModalsIds[modalsIds.MODALBOX_ADD_MERGE_LIBRARY_BY_GIT_AND_NPM] || false,
    defaultType: 'code-sync',
    className: 'design-library-modalbox',
    isMergeRequestSubmitted: !!state.settings.data[settingsName.MERGE_FROM_SUBMITTED],
    isMergeEnabled: isMergeEnabledInFeatures(state.features.features.merge),
    isGitOnlyEnabled: isGitOnlyEnabledInFeatures(state.features.features.merge_git_only),
    isPackageIntegrationOnlyEnabled: isPackageIntegrationOnlyEnabledInFeatures(
      state.features.features.merge_package_integration_only
    ),
    getMixpanel: mixpanel,
    usePortal: true,
  }),

  (dispatch) => ({
    actions: bindActionCreators({
      addLibrary: decoratedAddLibraryAction,
      changeEditedLibrary: actionChangeEditedLibrary,
      clearEditedLibrary: actionClearEditedLibrary,
      clearLibraryError: LibraryPermissions.actions.clearLibraryError,
      hideDesignLibrariesAddUpdateModal: () => modal.closeModal(modalsIds.MODALBOX_ADD_MERGE_LIBRARY_BY_GIT_AND_NPM),
      requestSetEditPermissions: LibraryPermissions.actions.requestSetEditPermissions,
      requestSetViewPermissions: LibraryPermissions.actions.requestSetViewPermissions,
      showDesignLibrariesSettingsModal: actionShowDesignLibrariesSettingsModal,
      startDeletingLibrary: actionDeleteLibrary,
      updateLibrary: actionUpdateLibrary,
      onMergeRequestSubmit,
    }, dispatch),
  })
)(LibraryPermissions.PanelModalbox);

export default ModalboxAddMergeLibrary;
