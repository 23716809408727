import { connect } from 'react-redux';
import ModalboxDesignLimitReachedComponent from '../components/Modalboxes/ModalboxDesignLimitReached';
import * as upgradeModal from '../../controllers/actions/upgradeModal.actions';

export const mapDispatchToProps = (dispatch) => ({
  openUpgradeModal: () => {
    dispatch(upgradeModal.openUpgradeModal());
  },
});

export const ModalboxDesignLimitReached = connect(null, mapDispatchToProps)(ModalboxDesignLimitReachedComponent);
