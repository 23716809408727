import { connect } from 'react-redux';
import { filter } from 'lodash';
import TopBarComponent from '../components/Topbar/Topbar';
import { openFeatureUpgrade, openPricing } from '../../controllers/actions/pricing.actions';
import { isFreePlanType, isAccountParked, isTrialPlanType, getAccountStatus } from '../../utils/account';
import { fetchLibraries } from '../../controllers/actions/designSystems.actions';

export const mapStateToProps = (state) => {
  const { data } = state.currentUser;
  const { isLoading } = state.projects;
  const { features } = state.features;
  const { areSettingsFetched, data: settings } = state.settings;
  const { accountPlan } = state.accountSettings;
  const { totalDocumentsCount } = state.insideProject;
  const { libraries } = state.designSystems;

  const isTrialPlan = !!(accountPlan && isTrialPlanType(accountPlan));
  const isFree = isFreePlanType(accountPlan) && !isTrialPlan;
  const isParked = isAccountParked(data.idActiveAccount, data.accounts);
  const ownedLibraries = filter(libraries, ({ idAccount }) => idAccount === data.idActiveAccount);

  return {
    currentUserData: data,
    isLoading,
    features,
    areSettingsFetched,
    settings,
    isTrialPlan,
    isFree,
    designCount: totalDocumentsCount,
    allowDesignLimitsInfo: !isTrialPlan && !isParked,
    accountPlan,
    libraries: ownedLibraries,
    accountStatus: getAccountStatus(data.idActiveAccount, data.accounts),
  };
};

export const mapDispatchToProps = (dispatch) => ({
  fetchLibraries: () => {
    dispatch(fetchLibraries());
  },

  openUpgradeModal: () => {
    dispatch(openPricing());
  },
  openFeatureUpgrade: (feature) => {
    dispatch(openFeatureUpgrade(feature));
  },
});

export const TopBar = connect(mapStateToProps, mapDispatchToProps)(TopBarComponent);
