/* eslint-disable max-len */
/* eslint-disable react/destructuring-assignment */

'use strict';

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import config from 'react-global-configuration';
import _ from 'lodash';
import classNames from 'classnames';
import autobind from 'autobind-decorator';
import { Badge, APPEARANCES } from '@uxpin/design-system';
import { isDesktopApp } from '@uxpin/shared-components/lib/utils/userAgentUtils';
import { UserTeam } from '../../containers/UserTeam';
import { TrialInfo } from '../../containers/TrialInfo';
import { DesignLimitsInfo } from '../../containers/DesignLimitsInfo';
import Settings from './components/Settings';
import { goTo } from '../../../utils/router';
import routes from '../../../constants/routes.constants';
import { FEATURES, FEATURE_STATUS } from '../../../constants/features.constants';
import { preventDefaultEvent } from '../../../utils/common';
import { getPromotionByName } from '../../../utils/marketing';
import { topbarPromoName } from '../../../constants/marketing.constants';
import { getMixpanel } from '../../../../../shared/mixpanel';
import { accountStatuses } from '../../../constants/accountSettings.constants';

export default class TopBar extends React.Component {
  constructor(props) {
    super(props);

    this.classes = {
      INSIDE_PROJECT: 'inside-project',
    };

    this.TRIAL = {
      SHOW_INFO: true,
    };

    this.state = {
      isSystemsPromo: false,
      isTrialPlan: false,
      isNewOnboarding: false,
      promoName: null,
      showDesignLimits: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    const {
      isInsideProjectPage,
      features,
      isTrialPlan,
      designCount,
      allowDesignLimitsInfo,
    } = nextProps;
    const isNewOnboarding = features && features.returning_unified_onboarding === FEATURE_STATUS.ENABLED;
    const isSystemsPromo = features && features.ds_plan_two_seats_limit === FEATURE_STATUS.ENABLED;
    const promoName = this.getPromoName(nextProps);

    if (designCount !== this.props.designCount && allowDesignLimitsInfo) {
      const showDesignLimits = designCount.in_plan < designCount.used;

      this.setState({
        showDesignLimits,
      });
    }

    if (isNewOnboarding !== this.state.isNewOnboarding) {
      this.setState({ isNewOnboarding });
    }

    if (isTrialPlan !== this.state.isTrialPlan) {
      this.setState({ isTrialPlan });
    }

    if (promoName !== this.state.promoName) {
      this.setState({ promoName });
    }

    if (isSystemsPromo !== this.state.isSystemsPromo) {
      this.setState({ isSystemsPromo });
    }

    this.toggleProjectsBodyClass(isInsideProjectPage);
  }

  componentDidMount() {
    const { fetchLibraries, libraries } = this.props;

    if (!_.isEmpty(libraries)) {
      return;
    }

    fetchLibraries();
    this.toggleProjectsBodyClass(this.props.isInsideProjectPage);
  }

  shouldComponentUpdate(nextProps) {
    return Object.keys(this.props).some((propName) => !_.isEqual(this.props[propName], nextProps[propName]));
  }

  toggleProjectsBodyClass(isInsideProjectPage) {
    if (isInsideProjectPage && !document.body.classList.contains(this.classes.INSIDE_PROJECT)) {
      document.body.classList.add(this.classes.INSIDE_PROJECT);
    } else if (!isInsideProjectPage) {
      document.body.classList.remove(this.classes.INSIDE_PROJECT);
    }
  }

  getPromoName(props) {
    // modify this function accordingly to get relevant promo names
    const { features, currentUserData: { promotions }, isTrialPlan } = props;
    let promoName = null;

    const buyOneGetOne = features && (
      features.promo_buy_one_get_one === FEATURE_STATUS.ENABLED
      || features.promo_buy_one_get_one_new_trial === FEATURE_STATUS.ENABLED
    );

    const batchPlansWinback = isTrialPlan && promotions && getPromotionByName('pricing_batch_winback_0918', promotions);

    if (buyOneGetOne) {
      promoName = topbarPromoName.BUY_ONE_GET_ONE;
    }

    if (batchPlansWinback) {
      promoName = topbarPromoName.BATCH_PLANS_WINBACK;
    }

    return promoName;
  }

  @autobind
  goToMainPage(e) {
    const { isMainPage } = this.props;

    getMixpanel(async (mixpanel) => {
      mixpanel.track('dashboard_loaded', {
        app_type: isDesktopApp ? 'desktop_app' : 'web_app',
      });
    });

    e.preventDefault();

    if (!isMainPage) {
      goTo(routes.PROJECTS);
    }
  }

  @autobind
  openUpgradeModal(e) {
    preventDefaultEvent(e);
    this.props.openUpgradeModal();
  }

  @autobind
  goToMergePage(e) {
    preventDefaultEvent(e);
    goTo(routes.MERGE);
  }

  @autobind
  goToDesignSystemsPage(e) {
    const { features, openFeatureUpgrade } = this.props;
    const { DESIGN_SYSTEMS } = FEATURES;

    e.preventDefault();

    if (features[DESIGN_SYSTEMS] !== FEATURE_STATUS.ENABLED) {
      openFeatureUpgrade(DESIGN_SYSTEMS);
      return;
    }

    goTo(routes.DESIGN_SYSTEMS);
  }

  renderTooltip() {
    const { isInsideProjectPage } = this.props;
    const tabName = isInsideProjectPage ? 'projects' : 'Design Systems';
    const tooltipText = `Back to ${tabName}`;

    return (<em className="float-left tooltip">{tooltipText}</em>);
  }

  renderDesignSystemsLink() {
    const tabClassName = classNames('tab', {
      active: this.props.isDesignSystemsPage,
    });

    return (
      <li className={tabClassName}>
        <a onClick={this.goToDesignSystemsPage} href="#design-systems-link" className="tab-link">
          Design Systems
        </a>
      </li>
    );
  }

  renderBadge() {
    return (<Badge appearance={APPEARANCES.WARNING}>New</Badge>);
  }

  renderMergeLink() {
    const tabLabel = 'Import your library';

    const tabClassName = classNames('tab', {
      active: this.props.isMergePage,
    });

    return (
      <li className={tabClassName}>
        <a onClick={this.goToMergePage} href="#merge-info-link" className="tab-link">
          {tabLabel} {this.renderBadge()}
        </a>
      </li>
    );
  }

  renderProjectsListLink() {
    const tabClassName = classNames('tab', {
      active: this.props.isMainPage,
    });

    return (
      <li className={tabClassName}>
        <a onClick={this.goToMainPage} href="#project-lists-link" className="tab-link">Projects</a>
      </li>
    );
  }

  renderCreateAccountLink() {
    const { currentUserData, isLoading, features } = this.props;

    if (
      (currentUserData.hasOwnedAccounts && currentUserData.accounts)
      || features[FEATURES.CREATE_OWN_ACCOUNT] !== FEATURE_STATUS.ENABLED
      || isLoading
    ) {
      return null;
    }

    return (
      <ul className="options-group">
        <li>
          <a href={`${config.get('APP_URL')}/dms/DPAccountSettings/CreateAccountForNonOwner`} className="underline-link gray-link">Create your own UXPin account</a>
        </li>
      </ul>
    );
  }

  renderUserTeam() {
    const { isLoading } = this.props;

    if (isLoading) {
      return null;
    }

    return <UserTeam />;
  }

  renderTrialInfo() {
    const { SHOW_INFO } = this.TRIAL;
    const { isLoading } = this.props;

    if (isLoading || !SHOW_INFO) {
      return null;
    }

    return (
      <TrialInfo
        isNewOnboarding={this.state.isNewOnboarding}
      />
    );
  }

  renderFreeInfo() {
    const { isLoading, isFree } = this.props;

    if (isLoading || !isFree || this.state.showDesignLimits) {
      return null;
    }

    return (
      <span>
        <a href="#upgrade-now" className="blue-link underline-link gray-link" onClick={this.openUpgradeModal}>Upgrade now</a>
      </span>
    );
  }

  renderDesignLimits() {
    if (!this.state.showDesignLimits) {
      return null;
    }

    return (
      <DesignLimitsInfo />
    );
  }

  renderOneYear50OffCampaignInfo() {
    const { isLoading, isFree, isTrialPlan, accountStatus } = this.props;
    const prefix = this.state.showDesignLimits ? null : 'and get';
    const highlighted = this.state.showDesignLimits ? 'Save 50% today!' : '50% off';
    const suffix = this.state.showDesignLimits ? null : '!';

    if (
      isLoading
      || (!isFree && !isTrialPlan)
      || accountStatus !== accountStatuses.ACTIVE
      || UXPinConfig.ONE_YEAR_50_OFF_CAMPAIGN_ENABLED !== 'true'
    ) {
      return null;
    }

    return (
      <Fragment>
        &nbsp;{prefix}&nbsp;<div className="highlight-discount-percentage">{highlighted}</div>{suffix}
      </Fragment>
    );
  }

  render() {
    return (
      <header id="top-bar">
        <div className="left-panel">
          <div className="nav-tabbed">
            <ul className="tabs-wrapper">
              {this.renderProjectsListLink()}
              {this.renderDesignSystemsLink()}
              {this.renderMergeLink()}
              {this.renderUserTeam()}
            </ul>
          </div>
          {this.renderCreateAccountLink()}
        </div>
        <div className="right-panel">
          {this.renderDesignLimits()}
          {this.renderTrialInfo()}
          {this.renderFreeInfo()}
          {this.renderOneYear50OffCampaignInfo()}
          <Settings />
        </div>
      </header>
    );
  }
}

TopBar.propTypes = {
  accountStatus: PropTypes.string.isRequired,
  currentUserData: PropTypes.shape({
    hasOwnedAccounts: PropTypes.bool,
    accounts: PropTypes.array,
  }).isRequired,
  features: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  openUpgradeModal: PropTypes.func.isRequired,
  isInsideProjectPage: PropTypes.bool.isRequired,
  isTrialPlan: PropTypes.bool.isRequired,
  isFree: PropTypes.bool.isRequired,
  isMainPage: PropTypes.bool.isRequired,
  isMergePage: PropTypes.bool.isRequired,
  isDesignSystemsPage: PropTypes.bool.isRequired,
  designCount: PropTypes.object,
  allowDesignLimitsInfo: PropTypes.bool,
  openFeatureUpgrade: PropTypes.func.isRequired,
  fetchLibraries: PropTypes.func.isRequired,
  libraries: PropTypes.array,
};

TopBar.defaultProps = {
  libraries: [],
  designCount: {},
  allowDesignLimitsInfo: false,
};
