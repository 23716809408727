/* eslint-disable react/destructuring-assignment,react/no-unescaped-entities,max-len */
/* eslint-disable react/require-default-props */

'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autobind from 'autobind-decorator';
import config from 'react-global-configuration';
import Tooltip from '../Helpers/Tooltip';

export default class Freemium extends Component {
  @autobind
  openPromoUpgrade(event) {
    event.preventDefault();
    this.props.openUpgradeModal();
  }

  getUsage() {
    if (this.props.viewsNearLimit && this.props.docsNearLimit) {
      return (
        <div className="info-bar-content">
          You have <strong>used {this.props.usedViews}%</strong> of {this.renderCollaboratorsInfo()}{' '}
          and <strong>{this.props.docsCount.used} of {this.props.docsCount.in_plan}</strong> design documents.{' '}
          {this.renderUpgradeCopy()}
          {this.renderButton()}
        </div>
      );
    } if (this.props.docsNearLimit) {
      return (
        <div className="info-bar-content">
          You have <strong>used {this.props.docsCount.used} of {this.props.docsCount.in_plan}</strong> of design documents.{' '}
          {this.renderUpgradeCopy()}
          {this.renderButton()}
        </div>
      );
    } if (this.props.viewsNearLimit) {
      return (
        <div className="info-bar-content">
          You have <strong>used {this.props.usedViews}%</strong> of {this.renderCollaboratorsInfo()}. {this.renderUpgradeCopy()}
          {this.renderButton()}
        </div>
      );
    }

    return null;
  }

  render() {
    if (this.props.viewsNearLimit || this.props.docsNearLimit) {
      return (
        <section id="top-info-bar" className="info-bar dark">
          {this.getUsage()}
        </section>
      );
    }

    return null;
  }

  renderButton() {
    return (<button type="button" className="btn btn-solid blue" onClick={this.openPromoUpgrade}>Upgrade now</button>);
  }

  renderTooltip() {
    return (
      <Tooltip className="multi-line">
        A <strong>collaborator view</strong> is a unique visit to a page in a preview link.
        Collaborator views are counted every 24 hours. Multiple visits to the same page won't use up collaborator views.
      </Tooltip>
    );
  }

  renderUpgradeCopy() {
    return ('Upgrade to increase the limit');
  }

  renderCollaboratorsInfo() {
    return (
      <a href={`${config.get('APP_URL')}/dms/Dashboard/Settings/?show_account`}>
        collaborator views
        {this.renderTooltip()}
      </a>
    );
  }
}

Freemium.propTypes = {
  openUpgradeModal: PropTypes.func,
  usedViews: PropTypes.number,
  docsNearLimit: PropTypes.bool,
  viewsNearLimit: PropTypes.bool,
  docsCount: PropTypes.object,
};
