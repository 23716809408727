import { filter } from 'lodash';
import { connect } from 'react-redux';
import {
  getTemplates, duplicateTemplate, checkDesignSystemStatus, isLimitReached,
} from '../../controllers/actions/designSystems.actions';
import { openModal } from '../../controllers/actions/modal.actions';
import { modalsIds } from '../../constants/modal.constants';
import DesignSystemsCreateComponent from '../components/DesignSystems/DesignSystemsCreate';
import { COPY_TYPE } from '../../constants/designSystems.constants';

// eslint-disable-next-line complexity
export const mapStateToProps = (state) => {
  const {
    libraries, isLibraryListFetched, idDuplicatedSystem, status, isTemplatesListFetched, libraryHash,
  } = state.designSystems;
  const plan = state.accountSettings.accountPlan;
  const limit = plan ? plan.design_systems_limit : undefined;
  const usedDesignSystems = plan ? plan.used_design_systems : undefined;
  const usedLibraries = libraries ? libraries.length : undefined;
  const { data: currentUserData } = state.currentUser;
  const { features } = state.features;
  // filter only owned libraries (we're not letting users create DSes for global libraries)
  const ownedLibraries = filter(libraries, ({ idAccount }) => idAccount === currentUserData.idActiveAccount);

  return {
    plan,
    libraries: ownedLibraries,
    limit,
    usedDesignSystems,
    usedLibraries,
    isDataFetched: plan && isTemplatesListFetched && isLibraryListFetched,
    idDuplicatedSystem,
    currentUserData,
    status,
    features,
    duplicatedLibraryHash: libraryHash,
  };
};

export const mapDispatchToProps = (dispatch) => ({
  showDesignLimitModal: () => dispatch(openModal(modalsIds.MODALBOX_DESIGN_LIMIT_REACHED, {
    type: COPY_TYPE.DESIGN_SYSTEM,
  })),
  copyTemplate: (templateName) => dispatch(duplicateTemplate(templateName)),
  checkDuplicationStatus: (idDesignSystem) => {
    dispatch(checkDesignSystemStatus(idDesignSystem));
  },
  getTemplates: () => {
    dispatch(getTemplates());
  },
  isDesignSystemsLimitReached: () => (
    dispatch(isLimitReached())
  ),
});

export const DesignSystemsCreate = connect(mapStateToProps, mapDispatchToProps)(DesignSystemsCreateComponent);
