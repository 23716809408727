import { countProjects } from '../helpers/projectsGroup.helper';
import { projectsActions, projectsConstants } from '../../constants/projects.constants';

export const initProjectGroups = () => ({
  type: projectsActions.INIT_PROJECTS_GROUPS,
});

export const getNextProjects = (currentPage) => ({
  type: projectsActions.NEXT_PROJECTS,
  currentPage,
});

export const expandProjectsGroup = (projectsGroup, index) => {
  const projectsCount = countProjects(projectsGroup, index);

  return {
    type: projectsActions.EXPAND_PROJECTS_GROUP,
    page: projectsCount ? Math.ceil(projectsCount / projectsConstants.PROJECTS_ON_PAGE) : 1,
    expandedGroup: index,
  };
};

export const collapseProjectsGroup = (projectsGroup, index) => {
  const projectsCount = countProjects(projectsGroup, index + 1);

  return {
    type: projectsActions.COLLAPSE_PROJECTS_GROUP,
    page: projectsCount ? Math.ceil(projectsCount / projectsConstants.PROJECTS_ON_PAGE) : 1,
    collapsedGroup: index,
  };
};
