'use strict';

import { slackActions } from '../../constants/slack.constants';
import { request } from '../../utils/request';
import { refreshActivityFeed } from './activityFeed.actions';

export const getChannelsList = (idProject) => (dispatch) => {
  dispatch({ type: slackActions.REQUEST_FETCHING_SLACK_CHANNELS });
  request.get(`/slack/${idProject}/list/`)
    .then((channels) => {
      dispatch({
        type: slackActions.FETCH_SLACK_CHANNELS,
        data: channels,
      });
    })
    .catch(() => {
      dispatch({
        type: slackActions.SLACK_ERROR,
      });
    });
};

export const getState = () => (dispatch) => {
  request.get('/slack/state/')
    .then((data) => {
      dispatch({
        type: slackActions.FETCH_SLACK_STATE,
        state: data,
      });
    })
    .catch(() => {
      dispatch({
        type: slackActions.SLACK_ERROR,
      });
    });
};

export const sendMessageToChannel = (channel, message) => (dispatch) => {
  dispatch({
    type: slackActions.REQUEST_SLACK_MESSAGE_SENDING,
  });
  request.post('/slack/message/channel/', {
    body: {
      channel,
      message,
    },
  })
    .then(() => {
      dispatch({
        type: slackActions.SLACK_MESSAGE_HAVE_BEEN_SENT,
      });
    })
    .catch(() => {
      dispatch({
        type: slackActions.SLACK_SENDING_ERROR,
      });
    });
};

export const setChannel = (idProject, channel) => (dispatch) => {
  request.put(`/slack/${idProject}/list/`, {
    body: {
      channel,
    },
  }, true)
    .then(() => {
      refreshActivityFeed(idProject)(dispatch);
      dispatch({
        type: slackActions.SET_SLACK_CHANNEL,
        channel,
      });
    })
    .catch(() => {
      // TODO handle error
    });
};
