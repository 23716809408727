'use strict';

import { findDOMNode } from 'react-dom';

export const itemSource = {
  beginDrag(props) {
    return {
      index: props.index,
    };
  },

  isDragging(props, monitor) {
    return props.index === monitor.getItem().index;
  },
};

export const itemTarget = {
  hover(props, monitor, component) {
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;

    if (dragIndex === hoverIndex) {
      return;
    }

    // eslint-disable-next-line react/no-find-dom-node
    const hoverBoundingRect = findDOMNode(component).getBoundingClientRect();
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
    const clientOffset = monitor.getClientOffset();
    const hoverClientY = clientOffset.y - hoverBoundingRect.top;

    if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
      return;
    }

    if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
      return;
    }

    // eslint-disable-next-line no-param-reassign
    monitor.getItem().index = hoverIndex;
    props.moveItem(dragIndex, hoverIndex);
  },
};

export const collectSource = (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  connectDragPreview: connect.dragPreview(),
  isDragging: monitor.isDragging(),
});

export const collectTarget = (connect) => ({
  connectDropTarget: connect.dropTarget(),
});
