'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import autobind from 'autobind-decorator';
import { map } from 'lodash';
import { triggerTypes } from '@uxpin/shared-components';
import { MergeItem } from '../../containers/MergeItem';
import MergeCreateItem from './components/MergeCreateItem';
import { getEditableAndVisibleMergeLibraries } from '../../../controllers/helpers/designSystems.helper';
import { getMixpanel } from '../../../../../shared/mixpanel';

export default class MergeLibraryList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openedItemId: null,
    };
  }

  @autobind
  onItemActionCloudOpen(id) {
    this.setState({
      openedItemId: id,
    });
  }

  @autobind
  onItemActionCloudClose() {
    this.setState({
      openedItemId: null,
    });
  }

  @autobind
  handleCreateNewLibrary(e) {
    const { openModalboxCreateMergeLibraryItem } = this.props;
    e.preventDefault();
    getMixpanel((mixpanel) => {
      mixpanel.track('add_library_modal_viewed');
    });
    openModalboxCreateMergeLibraryItem();
  }

  renderItems(libraries) {
    const { currentUserData, hiddenLibraries, isMergeEnabled, isStorybookEnabled } = this.props;
    const { openedItemId } = this.state;
    const editableAndVisibleMergeLibraries = getEditableAndVisibleMergeLibraries(
      libraries,
      hiddenLibraries,
      currentUserData.id,
      isMergeEnabled,
      isStorybookEnabled
    );

    return (
      <section id="design-system-screen" className="design-system-screen">
        <div id="design-systems-list">
          <ul className="list">
            <MergeCreateItem
              handleCreateNewLibrary={this.handleCreateNewLibrary}
            />
            {map(editableAndVisibleMergeLibraries, (item) => {
              const isItemHovered = openedItemId === item.id;
              return (
                <MergeItem
                  isItemHovered={isItemHovered}
                  currentUserData={currentUserData}
                  triggerType={triggerTypes.HOVER}
                  key={item.id}
                  item={item}
                  onOpen={() => this.onItemActionCloudOpen(item.id)}
                  onClose={this.onItemActionCloudClose}
                  autoClose
                />
              );
            })}
          </ul>
        </div>
      </section>
    );
  }

  render() {
    const { libraries } = this.props;
    return this.renderItems(libraries);
  }
}

MergeLibraryList.propTypes = {
  currentUserData: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  libraries: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
    })
  ).isRequired,
  openModalboxCreateMergeLibraryItem: PropTypes.func.isRequired,
  hiddenLibraries: PropTypes.array.isRequired,
  isMergeEnabled: PropTypes.bool.isRequired,
  isStorybookEnabled: PropTypes.bool.isRequired,
};
