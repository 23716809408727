'use strict';

import { marketingActions } from '../../constants/marketing.constants';

export function marketingReducer(state = {
  isAddOnUpgradeModalOpen: false,
  addOnData: {},
  isNamesModalFormSubmitted: false,
}, action) {
  switch (action.type) {
    case marketingActions.CLOSE_NAMES_MODAL:
      return Object.assign({}, state, {
        isNamesModalFormSubmitted: true,
      });
    case marketingActions.OPEN_ADD_ON_SPEC_MODE_UPGRADE_MODAL:
      return Object.assign({}, state, {
        isAddOnUpgradeModalOpen: true,
      });
    case marketingActions.CLOSE_ADD_ON_SPEC_MODE_UPGRADE_MODAL:
      return Object.assign({}, state, {
        isAddOnUpgradeModalOpen: false,
      });
    case marketingActions.FETCH_ADD_ON_DATA:
      return Object.assign({}, state, {
        addOnData: action.data,
      });
    default:
      return state;
  }
}
