import { connect } from 'react-redux';
import ActivityFeedComponent from '../components/InsideProject/components/ActivityFeed';
import { getActivityFeed, refreshActivityFeed } from '../../controllers/actions/activityFeed.actions';

export const mapStateToProps = (state) => {
  const {
    feed, isNextPage, isFeedLoading, isActivityFeedRefreshing,
  } = state.activityFeed;

  return {
    feed,
    isActivityFeedRefreshing,
    isNextPage,
    isFeedLoading,
  };
};

export const mapDispatchToProps = (dispatch) => ({
  refreshActivityFeed: (idProject) => {
    dispatch(refreshActivityFeed(idProject, true));
  },

  getActivityFeed: (idProject) => {
    dispatch(getActivityFeed(idProject));
  },
});

export const ActivityFeed = connect(mapStateToProps, mapDispatchToProps)(ActivityFeedComponent);
