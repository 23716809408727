/* eslint-disable react/destructuring-assignment */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default function DesignSystemsCreateOption(props) {
  const renderNotification = (notification) => (
    <p className="notification">{notification}</p>
  );

  const getClassNames = classNames('option', props.className, {
    'is-disabled': props.isDisabled,
  });

  return (
    <section className={getClassNames}>
      <header className="heading">
        <h2 className="title">{props.title}</h2>
        <strong className="subtitle">{props.description}</strong>
      </header>
      <footer>
        {props.isDisabled ? renderNotification(props.notification) : props.children}
      </footer>
    </section>
  );
}

DesignSystemsCreateOption.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  notification: PropTypes.object.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  className: PropTypes.string,
  children: PropTypes.element,
};

DesignSystemsCreateOption.defaultProps = {
  className: '',
  children: null,
};
