/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const StarredProjectsEmptyPage = () => (
  <EmptyPage
    className="empty-projects-list"
    title={<>There are no starred projects</>}
    description={<>Adjust the filtering to see your projects</>}
  />
);

export const UnstarredProjectsEmptyPage = () => (
  <EmptyPage
    className="empty-projects-list"
    title={<>There are no unstarred projects</>}
    description={<>Adjust the filtering to see your projects</>}
  />
);

export const StarredPrototypesEmptyPage = () => (
  <EmptyPage
    className="empty-prototypes-list"
    title={<>There are no starred documents</>}
    description={<>Adjust the filtering to see your documents</>}
  />
);

export const UnstarredPrototypesEmptyPage = () => (
  <EmptyPage
    className="empty-prototypes-list"
    title={<>There are no unstarred documents</>}
    description={<>Adjust the filtering to see your documents</>}
  />
);


const EmptyPage = ({ className, title, description }) => (
  <section className={classNames(['empty-page-container', className])}>
    <div
      className={classNames(['empty-page', className])}>
      <div className="title">
        {title}
      </div>
      <div className="description">
        {description}
      </div>
    </div>
  </section>
);

EmptyPage.propTypes = {
  className: PropTypes.string.isRequired,
  title: PropTypes.node.isRequired,
  description: PropTypes.node.isRequired,
};
