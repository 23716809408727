/* eslint-disable max-len */
/* eslint-disable no-restricted-globals */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/prop-types */

'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import key from 'keymaster';
import eventEmitter from 'event-emitter';
import _ from 'lodash';
import autobind from 'autobind-decorator';
import keys from '../../../constants/keys.constants';
import events from '../../../constants/event.constants';
import SearchResultsSection from './components/SearchResultsSection';
import routes from '../../../constants/routes.constants';

export default class Search extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      isSearchDataLoading: false,
      navigationMode: false,
      selectedList: -1,
      searchString: '',
    };

    this.SCROLL_MARGIN = 10;
    this.MIN_LENGTH = 1;
    this.searchSections = [];

    this.shortcuts = {
      OPEN: 'command+f ctrl+f',
    };

    this.classes = {
      VISIBLE: 'visible',
      LOADING: 'loading',
    };

    this.filteredElements = {};
    this.activeLists = [];
    this.cache = {};

    key.filter = () => true;

    this.eventRefs = {
      handleKeyDown: (e) => this.handleKeyDown(e),
      activeSearchSection: (id) => this.activeSearchSection(id),
      goToNextSection: () => this.goToNextSection(),
      goToPrevSection: () => this.goToPrevSection(),
    };

    this.emitter = eventEmitter();
  }

  componentDidMount() {
    key(this.shortcuts.OPEN, (event) => {
      event.preventDefault();
      this.toggleSearch();
    });
    this.emitter.on(events.NEXT_SEARCH_SECTION, this.eventRefs.goToNextSection);
    this.emitter.on(events.PREV_SEARCH_SECTION, this.eventRefs.goToPrevSection);
    this.emitter.on(events.ACTIVE_SEARCH_SECTION_EVENT, this.eventRefs.activeSearchSection);
  }

  componentWillUnmount() {
    key.unbind(this.shortcuts.OPEN);
    this.emitter.off(events.NEXT_SEARCH_SECTION, this.eventRefs.goToNextSection);
    this.emitter.off(events.PREV_SEARCH_SECTION, this.eventRefs.goToPrevSection);
    this.emitter.off(events.ACTIVE_SEARCH_SECTION_EVENT, this.eventRefs.activeSearchSection);
  }

  componentWillReceiveProps(nextProps) {
    const isOpen = nextProps.isSearchMode;
    const isSearchDataLoading = nextProps.isSearchDataLoading;
    if (isOpen !== this.state.isOpen) {
      this.setState({
        isOpen,
      }, () => {
        if (isOpen) {
          this.onOpen();
        } else {
          this.onClose();
        }
      });
    }

    this.searchSections = nextProps.prepareSearchSections(nextProps.searchData);

    if (this.props.isSearchDataLoading && isSearchDataLoading === false) {
      this.setState({
        isSearchDataLoading,
      }, () => {
        if (isOpen) {
          this.searchElements();
        }
      });
    }

    if (this.props.isActionOnSearchData && !nextProps.isActionOnSearchData || this.props.searchData.length !== nextProps.searchData.length) {
      this.cache = {};
      this.searchElements();
    }
  }

  enterNavigationMode() {
    this.setState({
      navigationMode: true,
      selectedList: 0,
    }, () => {
      this.refs.searchInput.blur();
    });
  }

  exitNavigationMode() {
    this.setState({
      navigationMode: false,
      selectedList: -1,
    });
  }

  toggleSection() {
    if (!this.state.navigationMode) {
      this.enterNavigationMode();
      return this.emitter.emit(events.SEARCH_NAVIGATION_EVENT, this.state.selectedList);
    }

    if (this.state.selectedList === this.activeLists.length - 1) {
      this.emitter.emit(events.INACTIVE_SEARCH_SECTION_EVENT, this.state.selectedList);
      this.refs.searchInput.focus();
      return this.scrollToViewport(this.refs.searchInput);
    }

    this.goToNextSection();
    this.emitter.emit(events.INACTIVE_SEARCH_SECTION_EVENT, this.state.selectedList - 1);
  }

  // eslint-disable-next-line complexity
  handleKeyDown(event) {
    const inputActive = (document.activeElement === this.refs.searchInput);
    let navigationMode = this.state.navigationMode;
    const navigationKeys = [keys.ENTER, keys.ARROW_DOWN, keys.ARROW_LEFT, keys.ARROW_UP, keys.ARROW_RIGHT];

    if (event.keyCode === keys.ESC) {
      this.toggleSearch();
      return;
    }

    if (!this.activeLists.length) {
      return false;
    }

    if (event.keyCode === keys.TAB) {
      event.preventDefault();
      this.toggleSection();
    }

    if (inputActive && navigationKeys.indexOf(event.keyCode) !== -1 && this.state.selectedList !== -1) {
      event.preventDefault();
      this.setState({
        navigationMode: true,
      }, () => {
        this.refs.searchInput.blur();
      });
      navigationMode = this.state.navigationMode;
    }

    if (inputActive && event.keyCode === keys.ARROW_DOWN) {
      event.preventDefault();
      this.enterNavigationMode();
      this.emitter.emit(events.SEARCH_NAVIGATION_EVENT, this.state.selectedList, event.keyCode);
    }

    if (navigationMode && navigationKeys.indexOf(event.keyCode) !== -1) {
      this.emitter.emit(events.SEARCH_NAVIGATION_EVENT, this.state.selectedList, event.keyCode);
    }
  }

  toggleSearch(callback) {
    const isOpen = !this.state.isOpen;
    this.setState({
      isOpen,
    }, () => {
      if (isOpen) {
        this.onOpen();
      } else {
        this.onClose();
      }

      if (_.isFunction(callback)) {
        callback(isOpen);
      }
    });
  }

  scrollToViewport(highlightedNode) {
    const nodeTop = highlightedNode.offsetTop;
    const nodeHeight = highlightedNode.offsetHeight;
    const containerScrollTop = this.refs.searchNode.scrollTop;
    const containerHeight = this.refs.searchNode.offsetHeight;

    if (nodeTop + nodeHeight > containerScrollTop + containerHeight) {
      this.refs.searchNode.scrollTop = nodeTop - containerHeight + nodeHeight + this.SCROLL_MARGIN;
    }

    if (nodeTop < containerScrollTop) {
      this.refs.searchNode.scrollTop = nodeTop - this.SCROLL_MARGIN;
    }
  }

  quote(str) {
    return (`${str}`).replace(/([.?*+^$[\]\\(){}|-])/g, "\\$1"); // eslint-disable-line quotes
  }

  filterElements(searchSection, searchString) {
    return searchSection.allElements.filter((element) => {
      const regex = new RegExp(this.quote(searchString), 'i');
      return !!searchSection.filterBy.filter((filter) => regex.test(element[filter])).length;
    });
  }

  searchElements() {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      const { isSearchDataLoading } = this.props;
      const searchString = this.refs.searchInput.value.trim();

      if (isSearchDataLoading) {
        this.setState({
          isSearchDataLoading,
        });

        return false;
      }

      this.filteredElements = {};

      this.setState({
        selectedList: -1,
      });

      if (this.cache[searchString]) {
        this.filteredElements = this.cache[searchString];
      } else if (searchString.length > this.MIN_LENGTH) {
        this.searchSections.forEach((searchSection, id) => {
          this.filteredElements[id] = this.filterElements(searchSection, searchString);
        });
      }

      this.cache[searchString] = this.filteredElements;
      this.setState({
        searchString,
      });
    }, 20);
  }

  getLoadingClass() {
    return this.state.isSearchDataLoading ? this.classes.LOADING : '';
  }

  getVisibleClass() {
    return this.state.isOpen ? this.classes.VISIBLE : '';
  }

  activeSearchSection(id) {
    if (this.state.selectedList !== id && this.state.selectedList !== -1) {
      this.emitter.emit(events.INACTIVE_SEARCH_SECTION_EVENT, this.state.selectedList);
    }

    this.setState({
      selectedList: id,
    });
  }

  goToPrevSection() {
    let selectedList = this.state.selectedList;
    selectedList -= 1;

    if (selectedList >= 0) {
      this.setState({
        selectedList,
      }, () => {
        this.emitter.emit(events.SEARCH_NAVIGATION_EVENT, this.state.selectedList, event.keyCode, this.filteredElements[this.activeLists[selectedList]].length - 1);
      });
    } else {
      this.refs.searchInput.focus();
    }
  }

  goToNextSection() {
    let selectedList = this.state.selectedList;
    selectedList += 1;

    if (selectedList > this.activeLists.length - 1) {
      selectedList = this.activeLists.length - 1;
    }

    this.setState({
      selectedList,
    }, () => {
      this.emitter.emit(events.SEARCH_NAVIGATION_EVENT, this.state.selectedList, event.keyCode);
    });
  }

  onOpen() {
    const { isSearchMode, enterSearchMode, fetchSearchData } = this.props;

    if (_.isFunction(fetchSearchData)) {
      fetchSearchData();
    }

    this.refs.searchInput.focus();
    document.addEventListener('keydown', this.eventRefs.handleKeyDown);
    document.addEventListener('click', this.handleClickOutsideComponent);

    if (!isSearchMode) {
      enterSearchMode();
    }
  }

  findElWithClass(el, cls) {
    while (el && !el.classList.contains(cls)) {
      // eslint-disable-next-line no-param-reassign
      el = el.parentElement;
    }

    return el;
  }

  @autobind
  handleClickOutsideComponent(e) {
    const modal = this.findElWithClass(e.target, 'modal-box');

    if (!this.refs.searchNode.contains(e.target) && !modal) {
      this.toggleSearch();
    }
  }

  onClose() {
    const { isSearchMode, exitSearchMode } = this.props;
    this.cache = {};
    this.refs.searchInput.blur();
    document.removeEventListener('keydown', this.eventRefs.handleKeyDown);
    document.removeEventListener('click', this.handleClickOutsideComponent);

    this.filteredElements = [];
    this.refs.searchInput.value = '';
    this.exitNavigationMode();
    this.setState({
      navigationMode: false,
      selectedList: -1,
      searchString: '',
    });
    if (isSearchMode) {
      exitSearchMode();
    }
  }

  onFocus() {
    this.emitter.emit(events.INACTIVE_SEARCH_SECTION_EVENT, this.state.selectedList);
    this.exitNavigationMode();
  }

  preventSubmitForm(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  render() {
    const children = [];
    let i = 0;
    this.activeLists = [];
    this.searchSections.forEach((searchSection, id) => {
      if (this.filteredElements[id] && this.filteredElements[id].length) {
        let searchSectionAction;

        if (searchSection.action) {
          searchSectionAction = (data, currentActions) => {
            this.toggleSearch(() => searchSection.action(data, currentActions));
          };
        }

        children.push(<SearchResultsSection
          // eslint-disable-next-line react/no-array-index-key
          key={id}
          id={i}
          activeLists={this.activeLists}
          emitter={this.emitter}
          currentProjectsActions={this.props.currentProjectsActions}
          currentDocumentsActions={this.props.currentDocumentsActions}
          filteredElements={this.filteredElements[id]}
          // eslint-disable-next-line react/jsx-no-bind
          scrollToViewport={this.scrollToViewport.bind(this)}
          searchSectionAction={searchSectionAction}
          searchSection={searchSection}
          canAddEditDeleteApprovals={this.props.canAddEditDeleteApprovals}
        />);
        this.activeLists.push(id);
        // eslint-disable-next-line no-plusplus
        i++;
      }
    });

    return (
      <section id="search-layer" className={`${this.getVisibleClass()} ${this.getLoadingClass()}`} ref="searchNode">
        <div className="search-container">
          <h2><label htmlFor="global-search">Search for:</label></h2>
          <form className="input-wrapper" onSubmit={this.preventSubmitForm} noValidate>
            <input
              ref="searchInput"
              id="global-search"
              className="invisible-input"
              placeholder="Start typing name"
              type="text"
              onFocus={this.onFocus.bind(this)}
              onChange={this.searchElements.bind(this)}
              autoComplete="off"
              required
            />
            <figure className="fake-pipe" />
          </form>
          {children}
        </div>
      </section>
    );
  }
}

Search.propTypes = {
  searchData: PropTypes.array.isRequired,
  isSearchMode: PropTypes.bool.isRequired,
  exitSearchMode: PropTypes.func.isRequired,
  enterSearchMode: PropTypes.func.isRequired,
};
