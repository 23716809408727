/* eslint-disable max-len */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable react/require-default-props */

'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import config from 'react-global-configuration';
import autobind from 'autobind-decorator';
import _ from 'lodash';
import ProjectBoxActionsTop from '../../InsideProjectList/components/ProjectBoxActionsTop';
import ProjectBoxActionsCenter from '../../InsideProjectList/components/ProjectBoxActionsCenter';
import ProjectBoxActionsBottom from '../../InsideProjectList/components/ProjectBoxActionsBottom';
import ProjectBoxPending from '../../InsideProjectList/components/ProjectBoxPending';
import { projectsStatuses } from '../../../../constants/projects.constants';
import { documentsActionsOnHover } from '../../../../constants/documents.constants';
import { getEditUrl } from '../../../../utils/documents';
import EmptyPlaceholder from 'dashboard/images/empty-placeholder.png';

export default class FileBox extends React.Component {
  constructor(props) {
    super(props);

    this.eventRefs = {
      handleMouseMove: () => props.onMouseMove(this.refs.fileBox),
    };

    this.classes = {
      SELECTED: 'selected',
      NO_IMAGE: 'no-image',
    };

    this.state = {
      shouldLoadImage: false,
    };

    this.image = new Image();
  }

  sendCheckingDuplicationRequest(props) {
    const { requestCheckingDuplicationStatus, data } = props;

    if (data.status === projectsStatuses.DUPLICATED && !data.hasTemporaryId && !this.hasDuplicationStatusRequestBeenSent(props) && !this.hasDuplicationStatusRequestBeenSent(this.props)) {
      requestCheckingDuplicationStatus(data.id);
    }
  }

  componentDidMount() {
    this.sendCheckingDuplicationRequest(this.props);
    this.isComponentMounted = true;
  }

  componentWillUnmount() {
    this.isComponentMounted = false;
  }

  componentWillReceiveProps(nextProps) {
    this.sendCheckingDuplicationRequest(nextProps);

    if (this.props.data.cover !== nextProps.data.cover) {
      this.setState({
        shouldLoadImage: false,
      });
    }
  }

  renderCover() {
    const { data } = this.props;
    const coverUrl = data.cover || EmptyPlaceholder;

    if (this.state.shouldLoadImage) {
      return (<img src={coverUrl} alt={data.name} />);
    }

    this.image.onload = () => {
      if (!this.isComponentMounted) { return false; }

      this.setState({
        shouldLoadImage: true,
      });
    };

    this.image.src = `${coverUrl}?${new Date().getTime()}`;

    return <span className="no-image">No image available</span>;
  }

  getClasses() {
    const { data, isSelected } = this.props;
    const classes = {};
    classes[this.classes.SELECTED] = isSelected;
    classes[this.classes.NO_IMAGE] = !!data.cover;

    return classnames(classes, this.getDocumentActionsClassNames());
  }

  getParentNodeEl() {
    return document.getElementById('search-layer');
  }

  @autobind
  openModalboxShare(e) {
    e.preventDefault();
    const { data } = this.props;
    const document = Object.assign({}, data, { idDocument: data.id });
    if (!this.props.channels.length) {
      this.props.getChannelsList(data.idProject);
    }

    this.props.openModalboxShare(document);
  }

  getDocumentActionsClassNames() {
    const { currentDocumentsActions, data } = this.props;
    return classnames({
      renaming: currentDocumentsActions[data.id] === documentsActionsOnHover.RENAMING,
      deleting: currentDocumentsActions[data.id] === documentsActionsOnHover.REMOVING,
      restoring: currentDocumentsActions[data.id] === documentsActionsOnHover.RESTORING,
      moving: currentDocumentsActions[data.id] === documentsActionsOnHover.MOVING,
      'parked-account': this.props.isAccountParked,
      duplicating: data.status === projectsStatuses.DUPLICATED,
      'deleting-counter': currentDocumentsActions[data.id] === documentsActionsOnHover.COUNTING,
      'no-hover': currentDocumentsActions[data.id],
    });
  }

  @autobind
  openModalboxDocumentChangeName(e) {
    e.preventDefault();
    const document = Object.assign({}, this.props.data, { idDocument: this.props.data.id });
    this.props.openModalboxDocumentChangeName(document);
  }

  @autobind
  openModalboxDocumentChangeProject(e) {
    e.preventDefault();
    const document = Object.assign({}, this.props.data, { idDocument: this.props.data.id });
    this.props.openModalboxDocumentChangeProject(document);
  }

  @autobind
  openApprovalProcess(e) {
    e.preventDefault();

    const { data, isApprovalProcessEnabled, openApprovalProcess, openFeatureUpgrade } = this.props;

    if (!isApprovalProcessEnabled) {
      openFeatureUpgrade();
      return;
    }

    const document = Object.assign({}, data, { idDocument: data.id });
    openApprovalProcess(document);
  }

  hasDuplicationStatusRequestBeenSent(props) {
    const { data, currentDocumentsActions } = props;
    return (currentDocumentsActions[data.id] === documentsActionsOnHover.CHECKING_DUPLICATION_STATUS);
  }

  shouldComponentUpdate(nextProps, nextState) {
    const {
      currentDocumentsActions, data, commentsCount, removingCounters, isAccountParked,
    } = this.props;

    if (nextProps.currentDocumentsActions[nextProps.data.id]
      || nextProps.currentDocumentsActions[nextProps.data.id] !== currentDocumentsActions[data.id]) {
      return true;
    }

    if (!_.isEqual(nextProps.removingCounters[nextProps.data.id], removingCounters[data.id])) {
      return true;
    }

    if (!_.isEqual(nextProps.data, data)) {
      return true;
    }

    if (!_.isEqual(nextProps.commentsCount, commentsCount)) {
      return true;
    }

    if (!_.isEqual(nextProps.isAccountParked, isAccountParked)) {
      return true;
    }

    if (!_.isEqual(this.state, nextState)) {
      return true;
    }

    if (this.props.isSelected !== nextProps.isSelected) {
      return true;
    }

    return false;
  }

  @autobind
  duplicateDocument() {
    const { data, duplicateDocument } = this.props;
    duplicateDocument(data.id);
  }

  @autobind
  openModalboxDocumentDelete(e) {
    e.preventDefault();
    const document = Object.assign({}, this.props.data, { idDocument: this.props.data.id });
    this.props.openModalboxDocumentDelete(document);
  }

  render() {
    const {
      data, features, removeDocumentPermanently, projectsList, commentsCount, restoreDocument, stopActionOnDocument, removingCounters, canAddEditDeleteApprovals,
    } = this.props;
    const document = Object.assign({}, data, { idDocument: data.id });
    const offset = { rightTop: { x: 15, y: 12 } };
    const editUrl = getEditUrl(document, features);

    return (
      <li
        ref="fileBox"
        onMouseMove={this.eventRefs.handleMouseMove}
        className={`file-box ${this.getClasses()}`}>
        <figure className="thumbnail small">
          {this.renderCover()}
          <div className="hover-actions">
            <ProjectBoxActionsTop
              openModalboxShare={this.openModalboxShare}
            />
            <ProjectBoxActionsCenter
              document={document}
              features={features}
              editUrl={editUrl}
              isAccountParked={this.props.isAccountParked}
              hasAccessToEditor={this.props.hasAccessToEditor}
            />
            <ProjectBoxActionsBottom
              document={document}
              projectsList={projectsList}
              features={features}
              commentsCount={commentsCount}
              offset={offset}
              duplicateDocument={this.duplicateDocument}
              isAccountParked={this.props.isAccountParked}
              openModalboxDocumentChangeName={this.openModalboxDocumentChangeName}
              openModalboxDocumentChangeProject={this.openModalboxDocumentChangeProject}
              openModalboxDocumentDelete={this.openModalboxDocumentDelete}
              openApprovalProcess={this.openApprovalProcess}
              getParentNodeEl={this.getParentNodeEl}
              canAddEditDeleteApprovals={canAddEditDeleteApprovals}
              hasAccessToEditor={this.props.hasAccessToEditor}
            />

          </div>
          <ProjectBoxPending
            document={document}
            stopActionOnDocument={stopActionOnDocument}
            restoreDocument={restoreDocument}
            removeDocumentPermanently={removeDocumentPermanently}
            counter={removingCounters[document.idDocument]}
          />
        </figure>
        <h5 className="name" title={data.name}>{data.name}</h5>
      </li>
    );
  }
}

FileBox.propTypes = {
  onMouseMove: PropTypes.func,
  data: PropTypes.object,
  isSelected: PropTypes.bool,
  openFeatureUpgrade: PropTypes.func.isRequired,
};
