'use strict';

import React from 'react';
import { Cloud, FilterListCloud, SelectUser } from '@uxpin/shared-components';
import autobind from 'autobind-decorator';

@Cloud()
export default class Notify extends FilterListCloud {
  componentWillMount() {
    this.setState({
      items: this.props.itemsList,
      selectedUsers: this.props.selectedUsers,
    });
    this.cache = {};
  }

  @autobind
  updateSelectedUser(selectedUsers) {
    const { updateSelectedUser } = this.props;
    this.setState({
      selectedUsers,
    });
    updateSelectedUser(selectedUsers);
  }

  renderLabel() {
    switch (this.state.selectedUsers.length) {
      case 1:
        return 'Nobody';
      case this.props.itemsList.length:
        return 'Entire team';
      case 2:
        return '1 user';
      default:
        return `${this.state.selectedUsers.length - 1} users`;
    }
  }

  render() {
    const { itemsList, currentUserId } = this.props;
    return (
      <nav className="cloud-wrapper">
        <span>Notify:&nbsp;</span>
        <a
          {...this.props.trigger}
          href="#change-recipients"
          className="blue-link underline-link">
          {this.renderLabel()}
        </a>
        <SelectUser
          {...this.props.cloud}
          filterElements={this.filterItems}
          selectedUsers={this.state.selectedUsers}
          visibleUsers={this.state.items}
          updateSelectedUser={this.updateSelectedUser}
          allUsers={itemsList}
          currentUserId={currentUserId}
          className="permissions-cloud auto-width"
        />
      </nav>
    );
  }
}
