/* eslint-disable max-len,react/no-unescaped-entities */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/prop-types */

'use strict';

import React from 'react';
import config from 'react-global-configuration';
import autobind from 'autobind-decorator';
import classNames from 'classnames';
import { isEmpty, noop } from 'lodash';
import {
  Avatar, Changelog, Cloud, getDesktopDownloadLink, GLOBAL_ELEMENTS, isDesktopApp, isMac, isWindows,
} from '@uxpin/shared-components';
import Cookies from 'js-cookie';
import { IntercomAPI } from 'react-intercom';
import UserSettingsManagement from '../../UserSettings/UserSettings';
import { getLogoutUrl } from '../../../../utils/router';
import { EVENT_SHOW_CHANGELOG } from '../../../../constants/desktopAppEvents.constants';
import { cookieStrings } from '../../../../constants/settings.constants';
import { FEATURES } from '../../../../constants/features.constants';
import MenuLink, { MENU_LINK_TYPES } from './MenuLink';
import { getMixpanel } from '../../../../../../shared/mixpanel';

export class AccountSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalboxChangelogOpen: false,
      isUserSettingsOpen: false,
      openedTabId: 0,
      chatUnreadCount: 0,
    };
    this.data = {
      accountSettings: {
        action: () => {
          getMixpanel((mixpanel) => mixpanel.track('account_settings_loaded'));
        },
        externalLink: true,
        url: `${config.get('APP_URL')}/dms/Dashboard/Settings/?show_account`,
      },
      integrations: {
        action: noop,
        externalLink: true,
        url: `${config.get('APP_URL')}/dms/Dashboard/Settings/?show_integrations`,
      },
      desktopApp: {
        action: () => {
          getMixpanel((mixpanel) => mixpanel.track('desktop_app_downloaded'));
        },
        url: getDesktopDownloadLink(),
      },
      changelog: {
        action: this.openChangelogModal,
        className: 'action-changelog-trigger',
        url: '#open-changelog',
      },
      logOut: {
        action: (event) => {
          event.preventDefault();
          // set in the properties_controller in uxpin-rails; unset it on logout
          Cookies.remove(cookieStrings.GTM_UXPIN_USER_ID_COOKIE, { path: '/', domain: '.uxpin.com' });
          window.location.href = getLogoutUrl();
        },
        url: '#',
      },
      notificationsSettings: {
        action: noop,
        externalLink: true,
        url: `${config.get('APP_URL')}/dms/Dashboard/Settings/?show_notifications`,
      },
      projectStatusSettings: {
        action: (e) => this.handleProjectStatus(e),
        externalLink: true,
        url: `${config.get('APP_URL')}/dms/Dashboard/Settings/?show_project_status`,
      },
      team: {
        action: noop,
        externalLink: false,
        url: `${config.get('APP_URL')}/dms/UserManagement/Default`,
      },
      yourProfile: {
        action: noop,
        externalLink: true,
        url: `${config.get('APP_URL')}/dms/Dashboard/Settings/?show_profile`,
      },
    };
  }

  componentDidMount() {
    if (isDesktopApp) {
      window.addEventListener(EVENT_SHOW_CHANGELOG, this.openChangelogModal);
    }
  }

  componentWillUnmount() {
    if (isDesktopApp) {
      window.removeEventListener(EVENT_SHOW_CHANGELOG, this.openChangelogModal);
    }
  }

  @autobind
  closeUserSettings() {
    this.setState({
      isUserSettingsOpen: false,
    });
  }

  getListHeaderClassNames() {
    return classNames('account-settings-header', GLOBAL_ELEMENTS.HEADING_2);
  }

  renderAccounts() {
    const { currentUserData } = this.props;

    if (!currentUserData.accounts) {
      return null;
    }

    return currentUserData.accounts
      .filter((account) => account.idAccount === currentUserData.idActiveAccount)
      .map((account) => this.renderAccountView(account.name, account.idAccount));
  }

  renderAccountSettingsLink() {
    const { permissions } = this.props;

    if (isEmpty(permissions) || (permissions.can_access_to_billing && permissions.can_access_to_billing.selected === 'no')) {
      return null;
    }

    return (
      <MenuLink testHandler="menu-account-settings" {...this.data.accountSettings}>Account settings</MenuLink>
    );
  }

  renderTeamLink() {
    const { permissions } = this.props;

    if (isEmpty(permissions) || (permissions.can_choose_role_for_users && permissions.can_choose_role_for_users.selected !== 'yes')) {
      return null;
    }

    return (
      <MenuLink testHandler="menu-team" {...this.data.team}>Team</MenuLink>
    );
  }

  handleProjectStatus(e) {
    const { isProjectStatusEnabled, openFeatureUpgrade } = this.props;

    if (!isProjectStatusEnabled) {
      if (e) {
        e.preventDefault();
      }

      openFeatureUpgrade(FEATURES.PROJECT_STATUS);
    }
  }

  renderProjectStatus() {
    return (
      <MenuLink testHandler="menu-project-status-settings" {...this.data.projectStatusSettings}>
        Project status settings
      </MenuLink>
    );
  }

  renderAccountView(name, id) {
    return [
      <div key={`account-view${id}`} className="tab-wrapper">
        <h2 className={this.getListHeaderClassNames()}>
          <span className="vertical-line-helper">{name}</span>
        </h2>
        <ul className="options-list">
          {this.renderAccountSettingsLink()}
          {this.renderProjectStatus()}
          {this.renderTeamLink()}
        </ul>
      </div>,
      <ul key={`option-list${id}`} className="options-list separator">
        <MenuLink testHandler="menu-integrations" {...this.data.integrations}>Integrations</MenuLink>
      </ul>,
    ];
  }

  renderTeamsActions() {
    const { currentUserData } = this.props;

    if (!currentUserData.accounts) {
      return null;
    }

    return (
      <div className="tab-wrapper">
        <h2 className={this.getListHeaderClassNames()}>
          <span className="vertical-line-helper">Your teams</span>
        </h2>
        <ul className="collapse-list-body">
          {currentUserData.accounts.map((account, id) => (
            // eslint-disable-next-line react/no-array-index-key
            <li key={`team-${id}`}>
              <a href={`#sign-out-${account.name}`}>
                Sign out of <strong>{account.name}</strong>
              </a>
            </li>
          ))}
          <li><a href="#sign-in-another-team">Sign in to another team</a></li>
        </ul>
      </div>
    );
  }

  @autobind
  openChangelogModal(e) {
    e.preventDefault();

    this.props.openModal();
  }

  renderChatTrigger(render) {
    const { closeCloud } = this.props;

    if (!render) {
      return null;
    }

    return (
      <ul className="separator options-list">
        <MenuLink
          action={this.openIntercom}
          closeCloud={closeCloud}
          testHandler="menu-chat"
          type={MENU_LINK_TYPES.BUTTON}
          url="mailto:hello@uxpin.com">
          Chat with us
          {this.state.chatUnreadCount > 0 && <small className="promo-label info-label">You’ve got a message</small>}
        </MenuLink>
      </ul>
    );
  }

  renderDownloadDesktopApp() {
    if (!isDesktopApp && (isMac || isWindows)) {
      return (
        <MenuLink testHandler="menu-get-desktop-app" {...this.data.desktopApp}>
          Get Desktop App
        </MenuLink>
      );
    }

    return null;
  }

  renderChangelogTrigger() {
    return (
      <MenuLink testHandler="menu-changelog" {...this.data.changelog}>
        <div className="changelog-indicator">Changelog</div>
        What's new?
      </MenuLink>
    );
  }

  @autobind
  openIntercom() {
    if (window && window.Intercom) {
      IntercomAPI('trackEvent', '[CS] APP - Dashboard/Editor - Conversation Flow - $$ customers [LIVE by Dominika]');
      IntercomAPI('show');
    } else {
      window.open('mailto:sales@uxpin.com?subject=Contact Request');
    }
  }

  checkChangelogUnreadState() {
    const {
      settings, changelogItems, currentUserData, areSettingsFetched, isFetchUserDataSuccess,
    } = this.props;
    const { isChangelogUnread } = Changelog.helpers;
    const { createdAt: userCreateDate } = currentUserData;

    if (!areSettingsFetched || !isFetchUserDataSuccess) {
      return false;
    }

    return isChangelogUnread(changelogItems, settings.changelogLastSeenDate, userCreateDate);
  }

  render() {
    const { currentUserData, isIntercomSupported, isLoading } = this.props;
    const isChangelogUnread = this.checkChangelogUnreadState();

    const user = isLoading ? {} : {
      firstname: currentUserData.firstName,
      lastname: currentUserData.lastName,
      email: currentUserData.email,
      avatar: currentUserData.avatar,
    };

    const componentClassName = classNames({
      'with-changelog': isChangelogUnread,
    });
    const cloudClassName = classNames('cloud big account-settings options-cloud', {
      'with-changelog': isChangelogUnread,
    });

    return (
      <section className={componentClassName}>
        <a
          data-test="account-settings"
          {...this.props.trigger}
          href="#account-settings"
          className="main-avatar"
          data-show-node="cloud">
          <Avatar user={user} />
        </a>
        <div className={cloudClassName} {...this.props.cloud}>
          <p className="account-name"><strong>{user.email}</strong></p>
          <div className="tab-wrapper">
            <h2 className={this.getListHeaderClassNames()}>
              <span className="vertical-line-helper">Your account</span>
            </h2>
            <ul className="options-list">
              <MenuLink testHandler="menu-your-profile" {...this.data.yourProfile}>Your profile</MenuLink>
              <MenuLink testHandler="menu-notifications" {...this.data.notificationsSettings}>Notifications settings</MenuLink>
            </ul>
          </div>
          {this.renderAccounts()}
          <ul className="separator options-list">
            {this.renderDownloadDesktopApp()}
            {this.renderChangelogTrigger()}
          </ul>
          {this.renderChatTrigger(isIntercomSupported)}
          <ul className="separator options-list">
            <MenuLink testHandler="menu-logout" {...this.data.logOut}>Log out</MenuLink>
          </ul>
        </div>
        <div className="changelog-indicator inside-topbar" onClick={this.openChangelogModal}>Changelog</div>

        <UserSettingsManagement
          isOpen={this.state.isUserSettingsOpen}
          onClose={this.closeUserSettings}
        />
      </section>
    );
  }
}

export default Cloud()(AccountSettings);
