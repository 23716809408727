'use strict';

import _ from 'lodash';
import { activityFeedActions } from '../../constants/activityFeed.constants';

export function activityFeedReducer(state = {
  feed: [],
  isNextPage: false,
  isFeedLoading: false,
  isActivityFeedRefreshing: true,
}, action) {
  const feed = _.cloneDeep(state.feed).filter((filteredFeed) => filteredFeed.page !== 1);

  switch (action.type) {
    case activityFeedActions.REQUEST_FETCHING_ACTIVITY_FEED_DATA:
      return Object.assign({}, state, {
        isFeedLoading: true,
      });
    case activityFeedActions.RESET_ACTIVITY_FEED_DATA:
      return Object.assign({}, state, {
        feed: [],
      });
    case activityFeedActions.REFRESH_ACTIVITY_FEED_DATA:
      return Object.assign({}, state, {
        feed: [...action.data.feed, ...feed],
        isNextPage: action.currentPage === 1 ? action.data.isNextPage : false,
      });
    case activityFeedActions.STOP_REFRESHING_ACTIVITY_FEED_DATA:
      return Object.assign({}, state, {
        isActivityFeedRefreshing: false,
      });
    case activityFeedActions.FETCH_ACTIVITY_FEED_DATA:
      return Object.assign({}, state, {
        feed: [...state.feed, ...action.data.feed],
        isNextPage: action.data.isNextPage,
        isFeedLoading: false,
        isActivityFeedRefreshing: true,
      });
    default:
      return state;
  }
}
