/* eslint-disable max-len */
/* eslint-disable no-prototype-builtins */
/* eslint-disable radix */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */

'use strict';

import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { projectsActionsOnHover, projectsConstants } from '../../constants/projects.constants';
import * as projectActions from '../../controllers/actions/projectActions.actions';
import * as projectsGroups from '../../controllers/actions/projectsGroups.actions';
import { projects } from '../../controllers/actions/projects.actions';
import { search } from '../../controllers/actions/search.actions';
import { getMixpanel } from '../../../../shared/mixpanel';

export const getDuplicatedProjectsIds = (currentProjectsActions) => {
  const duplicatedProjectsIds = [];
  for (const i in currentProjectsActions) {
    if (currentProjectsActions.hasOwnProperty(i)
      && currentProjectsActions[i] === projectsActionsOnHover.CHECKING_DUPLICATION_STATUS) {
      duplicatedProjectsIds.push(parseInt(i));
    }
  }

  return duplicatedProjectsIds;
};

export const getRemovedProjectsIds = (currentProjectsActions) => {
  const removedProjectsIds = [];
  for (const i in currentProjectsActions) {
    if (currentProjectsActions.hasOwnProperty(i)
      && currentProjectsActions[i] === projectsActionsOnHover.COUNTING) {
      removedProjectsIds.push(parseInt(i));
    }
  }

  return removedProjectsIds;
};

export const mapStateToProps = (state) => {
  const { currentProjectsActions, removingCounters } = state.projectActions;
  const { items: groups, groupsExpandedMode } = state.projects;
  const duplicatedProjectsIds = getDuplicatedProjectsIds(currentProjectsActions);
  const removedProjectsIds = getRemovedProjectsIds(currentProjectsActions);

  return {
    removingCounters,
    removedProjectsIds,
    duplicatedProjectsIds,
    groupsExpandedMode,
    groups,
  };
};

export const mapDispatchToProps = (dispatch) => ({
  stopActionOnProject: (idProject) => {
    dispatch(projectActions.stopActionOnProject(idProject));
  },

  removeProjectPermanently: (idProject) => {
    dispatch(projects.removeProjectPermanently(idProject));
  },

  removeProjectFromSearchData: (idProject) => {
    dispatch(search.removeProjectFromSearchData(idProject));
  },

  checkDuplicationStatuses: (projectsIds) => {
    dispatch(projects.checkDuplicationStatuses(projectsIds));
  },

  countTimeToRemoveProject: (ids) => {
    dispatch(projectActions.countTimeToRemoveProject(ids));
  },

  clearProjectsList: () => {
    dispatch(projects.clearProjectsList());
  },

  clearProjectsActions: () => {
    dispatch(projects.clearProjectsActions());
  },

  getProjectCountToExpandedGroup: (groups, groupsExpandedMode) => projects.getProjectCountToExpandedGroup(groups, groupsExpandedMode),

  setPage: (page) => {
    dispatch(projectsGroups.getNextProjects(page));
  },

  clearRemovedProjects: (removedProjectsIds) => {
    dispatch(projects.clearRemovedProjects(removedProjectsIds));
  },
});

export default class ProjectsActionsListenerComponent extends React.Component {
  componentWillUnmount() {
    const {
      setPage, removedProjectsIds, clearRemovedProjects, clearProjectsActions, getProjectCountToExpandedGroup, groups, groupsExpandedMode,
    } = this.props;
    clearTimeout(this.timeout);
    clearTimeout(this.duplicatedProjectsTimeout);
    const projectCount = getProjectCountToExpandedGroup(groups, groupsExpandedMode) + projectsConstants.PROJECTS_ON_PAGE;
    setPage(Math.ceil(projectCount / projectsConstants.PROJECTS_ON_PAGE));
    clearProjectsActions();
    clearRemovedProjects(removedProjectsIds);
  }

  shouldComponentUpdate(nextProps) {
    return Object.keys(this.props).some((propName) => !_.isEqual(this.props[propName], nextProps[propName]));
  }

  componentWillReceiveProps(nextProps) {
    const hasDuplicatedProjectIds = nextProps.duplicatedProjectsIds && nextProps.duplicatedProjectsIds.length;
    const alreadyProcessed = _.isEqual(this.props.duplicatedProjectsIds, nextProps.duplicatedProjectsIds);

    if (hasDuplicatedProjectIds && !alreadyProcessed) {
      clearTimeout(this.duplicatedProjectsTimeout);
      this.duplicatedProjectsTimeout = setTimeout(() => { nextProps.checkDuplicationStatuses(nextProps.duplicatedProjectsIds); }, 1000);
    }

    if (!_.isEqual(this.props.removingCounters, nextProps.removingCounters)) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        let idProject;
        const toCounting = [];
        // eslint-disable-next-line guard-for-in
        for (const i in nextProps.removingCounters) {
          idProject = parseInt(i);
          if (nextProps.removingCounters[idProject] > 0) {
            toCounting.push(idProject);
          } else if (nextProps.removingCounters[idProject] === 0) {
            nextProps.stopActionOnProject(idProject);
            nextProps.removeProjectPermanently(idProject);
            getMixpanel((mixpanel) => {
              mixpanel.track('project_deleted');
            });
            nextProps.removeProjectFromSearchData(idProject);
          }
        }

        if (toCounting.length) {
          nextProps.countTimeToRemoveProject(toCounting);
        }
      }, 1000);
    }
  }

  render() {
    return null;
  }
}

export const ProjectsActionsListener = connect(mapStateToProps, mapDispatchToProps)(ProjectsActionsListenerComponent);
