/* eslint-disable max-len,react/no-array-index-key,jsx-a11y/alt-text,jsx-a11y/no-onchange */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */

'use strict';

import React from 'react';
import autobind from 'autobind-decorator';
import classnames from 'classnames';
import config from 'react-global-configuration';
import { PlanInfo } from '../../../containers/PlanInfo';
import { localStorage } from '../../../../constants/localStorage.constants';
import * as LocalStorage from '../../../../utils/localStorage';
import SlackLogoColor from 'dashboard/images/slack-logo-color.svg';

export default class SlackNotificationPanel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedChannel: '',
      isPlanInfoHidden: LocalStorage.load(localStorage.IS_SLACK_INFO_HIDDEN) || false,
    };
  }

  componentWillMount() {
    this.setState({
      selectedChannel: this.props.selectedChannel,
    });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.selectedChannel !== nextProps.selectedChannel) {
      this.setState({
        selectedChannel: nextProps.selectedChannel,
      });
    }
  }

  @autobind
  setSelectedChannel(e) {
    const { setChannel, idProject } = this.props;
    this.setState({
      selectedChannel: e.target.value,
    }, () => setChannel(idProject, this.state.selectedChannel));
  }

  @autobind
  hidePlanInfo(e) {
    e.preventDefault();
    this.setState({
      isPlanInfoHidden: true,
    });

    LocalStorage.save(localStorage.IS_SLACK_INFO_HIDDEN, true);
  }

  renderChannelsList() {
    const {
      slackState, isError, channels, isSlackEnabled,
    } = this.props;

    if (!isSlackEnabled) {
      return (
        <PlanInfo>
          <strong>Slack notifications</strong>
          {' '}
          are available in higher plans.
          <a href="#close" className="icon-general-close only-icon-font" onClick={this.hidePlanInfo} title="Hide plan's info">Close</a>
        </PlanInfo>
      );
    }

    if (!slackState.authorized || isError || !channels.length) {
      return (
        <aside>
          <small className="help-info">
            Slack unavailable. Contact
            {' '}
            <br />
            <a className="underline-link gray-link" href="mailto:hello@uxpin.com">hello@uxpin.com</a>
            {' '}
            for more help.
          </small>
        </aside>
      );
    }

    return (
      <aside>
        <p>Notifications integrated with</p>
        <div>
          <select value={this.state.selectedChannel} onChange={this.setSelectedChannel} name="select">
            <option value="">no channel</option>
            {channels.map((channel, id) => <option key={id} value={channel}>{channel}</option>)}
          </select>
        </div>
      </aside>
    );
  }

  getClasses() {
    const {
      slackState, isError, channels, isSlackEnabled,
    } = this.props;

    return classnames('slack-panel', { 'slack-hidden': (!slackState.authorized || isError || !channels.length) && isSlackEnabled || this.state.isPlanInfoHidden });
  }

  render() {
    return (
      <footer className={this.getClasses()}>
        <figure className="slack-logo">
          <img src={SlackLogoColor} width="30" height="30" />
        </figure>
        {this.renderChannelsList()}
      </footer>
    );
  }
}
