'use strict';

import { connect } from 'react-redux';
import * as projectStatus from '../../controllers/actions/projectStatus.actions';
import * as documents from '../../controllers/actions/document.actions';
import * as settings from '../../controllers/actions/settings.actions';
// eslint-disable-next-line import/no-named-as-default
import ProjectBoxHeader from '../components/InsideProjectList/components/ProjectBoxHeader';

export const mapStateToProps = (state, props) => {
  const { project, insideProjectViewMode } = state.insideProject;
  // eslint-disable-next-line max-len
  const { permissions: { can_add_edit_delete_project_status: canAddEditDeleteProjectStatus } } = state.permissions; // eslint-disable-line camelcase
  // eslint-disable-next-line no-shadow
  const { features: { project_status: projectStatus } } = state.features;
  const { permissions: { has_access_to_editor: hasAccessToEditor } } = state.permissions;
  const isStarred = settings.isStarredPrototype(state, props.document.idDocument);

  return {
    project,
    hasAccessToEditor: hasAccessToEditor && hasAccessToEditor.selected !== 'no',
    canAddEditDeleteProjectStatus: canAddEditDeleteProjectStatus && canAddEditDeleteProjectStatus.selected === 'yes',
    isProjectStatusEnabled: projectStatus === 'enabled',
    insideProjectViewMode,
    isStarred,
  };
};

export const mapDispatchToProps = (dispatch, props) => ({
  setDocumentStatus: (document, status) => {
    dispatch(projectStatus.setDocumentStatus(document, status));
  },

  updateDocumentName: (idDocument, name) => {
    dispatch(documents.updateDocumentName(idDocument, name));
  },

  toggleStar: () => {
    const { document: { idDocument } } = props;
    dispatch(settings.togglePrototypeStar(idDocument));
  },
});

export const DocumentHeader = connect(mapStateToProps, mapDispatchToProps)(ProjectBoxHeader);
