import { connect } from 'react-redux';
import MergeItemComponents from '../components/Merge/components/MergeItem';
import * as modal from '../../controllers/actions/modal.actions';
import { modalsIds } from '../../constants/modal.constants';
import { actionFetchCodeSyncData, updateLibraryName } from '../../controllers/actions/designSystems.actions';
import { FEATURE_STATUS } from '../../constants/features.constants';

export const mapStateToProps = (state) => ({
  isGitOnlyEnabled: state.features.features.merge_git_only === FEATURE_STATUS.ENABLED,
  isPackageIntegrationOnlyEnabled: state.features.features.merge_package_integration_only === FEATURE_STATUS.ENABLED
})

export const mapDispatchToProps = (dispatch) => ({
  openModalboxMergeLibraryDelete: (idLibrary) => {
    dispatch(modal.openModal(modalsIds.MODALBOX_DESIGN_SYSTEM_DELETE, idLibrary));
  },

  openModalboxMergeSetting: async (idLibrary) => {
    await dispatch(actionFetchCodeSyncData(idLibrary));
    dispatch(modal.openModal(modalsIds.MODALBOX_MERGE_SETTING, idLibrary));
  },

  updateLibraryName: (idLibrary, name) => {
    dispatch(updateLibraryName(idLibrary, name));
  },
});

export const MergeItem = connect(mapStateToProps, mapDispatchToProps)(MergeItemComponents);
