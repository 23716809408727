/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */

'use strict';

import React from 'react';
import classnames from 'classnames';
import { isUndefined } from 'lodash';
import autobind from 'autobind-decorator';
import { GLOBAL_ELEMENTS } from '@uxpin/shared-components';

export default class CurrentGroup extends React.Component {
  getClasses() {
    const { groupsExpandedMode, projectGroupId } = this.props;
    let isExpandedMode = true;

    if (!isUndefined(groupsExpandedMode[projectGroupId])) {
      isExpandedMode = groupsExpandedMode[projectGroupId];
    }

    return classnames('tab-wrapper', 'current-group', { expanded: isExpandedMode });
  }

  @autobind
  toggleGroupsExpandedMode(e) {
    e.preventDefault();
    const {
      groupsExpandedMode, projectGroupId, onCollapsing, onExpanding, currentGroupIndex,
    } = this.props;
    const newValue = !groupsExpandedMode[projectGroupId];
    this.props.saveProjectsGroupsExpandedMode(projectGroupId, newValue, () => {
      if (newValue) {
        onExpanding(currentGroupIndex);
      } else {
        onCollapsing();
      }
    });
  }

  render() {
    const { name, id } = this.props;
    const collapseListHeaderClasses = classnames('collapse-list-header', GLOBAL_ELEMENTS.HEADING_2);

    return (
      <div style={this.props.style} className={this.getClasses()}>
        <h2 onClick={this.toggleGroupsExpandedMode} className={collapseListHeaderClasses}>
          <label htmlFor={id} className="icon-helper icon-general-chevron">
            <span className="vertical-line-helper">
              <span className="ellipsis-helper">{name}</span>
            </span>
          </label>
        </h2>
      </div>
    );
  }
}
