'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import { Avatar, UserName } from '@uxpin/shared-components';

export default class RoleItem extends UserName {
  renderRoleName() {
    const { user, defaultRole } = this.props;
    let roleName = defaultRole;

    if (user.roleName) {
      roleName = user.roleName;
    }

    return (
      <em className="role">{roleName}</em>
    );
  }

  render() {
    const { user } = this.props;
    const userName = this.getUserName(user);

    return (
      <li>
        <Avatar user={user}>
          <div className="description" title={userName}>
            <strong className="name">{userName}</strong>
            {this.renderRoleName()}
          </div>
        </Avatar>
      </li>
    );
  }
}

RoleItem.propTypes = {
  user: PropTypes.object.isRequired,
  defaultRole: PropTypes.string,
};

RoleItem.defaultProps = {
  defaultRole: 'Role is not set',
};
