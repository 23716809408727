/* eslint-disable max-len */

'use strict';

import _ from 'lodash';
import { projectsGroupsActions } from '../../constants/projectsGroupsManagement.constants';
import { projects } from './projects.actions';
import { updateSettings } from './settings.actions';
import { request } from '../../utils/request';

const requestFetchingProjectGroups = () => ({
  type: projectsGroupsActions.REQUEST_FETCHING_PROJECTS_GROUPS,
});

const requestSavingProjectGroups = () => ({
  type: projectsGroupsActions.REQUEST_SAVING_PROJECTS_GROUPS,
});

const addProjectsGroups = (groups, newGroups) => {
  if (!newGroups.length) { return Promise.resolve([]); }

  return request.post('/project_groups', {
    body: newGroups,
  });
};

const removeProjectsGroups = (deletedGroups) => {
  if (!deletedGroups.length) { return Promise.resolve([]); }

  return request.delete('/project_groups', {
    body: deletedGroups,
  }, true);
};

const updateProjectsGroups = (editedGroups) => {
  if (!editedGroups.length) { return Promise.resolve([]); }

  return request.post('/project_groups/update', {
    body: editedGroups,
  });
};

const setProjectsGroupsAccess = (groups, canAddEditDeleteProjectGroups) => {
  if (!canAddEditDeleteProjectGroups) { return Promise.resolve([]); }

  const groupsAccess = groups.filter((projectsGroup) => !projectsGroup.isBeingDeleted && !projectsGroup.isDeleted && projectsGroup.id).map((group) => (
    {
      idProjectGroup: group.id,
      userIds: group.selectedUsers,
    }));

  return request.put('/project_groups/access', {
    body: groupsAccess,
  });
};

export const saveProjectsGroupsExpandedMode = (groupExpandedMode) => (dispatch) => updateSettings('groupsExpandedMode', groupExpandedMode, true)(dispatch);


const updateProjectsGroupsExpandedMode = (groups, dispatch, groupsExpandedMode) => {
  const newGroupsExpandedMode = {};

  groups.forEach((group) => {
    if (!group.isBeingDeleted && !group.isDeleted && !_.isUndefined(groupsExpandedMode[group.id || group.type])) {
      newGroupsExpandedMode[group.id || group.type] = groupsExpandedMode[group.id || group.type];
    }
  });

  return saveProjectsGroupsExpandedMode(newGroupsExpandedMode)(dispatch);
};

const setOrderProjectsGroups = (groups, oldGroupsOrder, idAccount, dispatch) => {
  const groupsOrder = {};
  const groupsOrderSettingName = idAccount ? `groupsOrder_${idAccount}` : 'groupsOrder';
  groups
    .filter((projectsGroup) => !projectsGroup.isBeingDeleted && !projectsGroup.isDeleted)
    .forEach((group, index) => { groupsOrder[group.id || group.type] = index; });

  return updateSettings(groupsOrderSettingName, groupsOrder, true)(dispatch).then(() => groupsOrder);
};

const updateDeletedGroups = (groups, newGroups, addedGroups, deletedGroups) => {
  newGroups.forEach((group, id) => {
    Object.assign(group, { id: addedGroups[id].id });
  });

  return deletedGroups.map((group) => {
    if (group.temporaryId) {
      const tmpGroup = _.find(newGroups, (newGroup) => newGroup.temporaryId === group.temporaryId);
      return {
        mode: group.mode,
        id: group.id,
        idProjectGroup: tmpGroup.id,
      };
    }

    return {
      mode: group.mode,
      id: group.id,
      idProjectGroup: groups[group.projectGroupIndex] ? groups[group.projectGroupIndex].id : 0,
    };
  });
};

export const saveProjectsGroups = (groups, newGroups, updatedGroups, deletedGroups, canAddEditDeleteProjectGroups, groupsOrder, groupsExpandedMode, idAccount) => (dispatch) => {
  let addedGroups;
  let newGroupsOrder;

  dispatch(requestSavingProjectGroups());
  addProjectsGroups(groups, _.cloneDeep(newGroups).map((group) => {
    delete group.temporaryId; // eslint-disable-line no-param-reassign
    return group;
  }))
    .then((data) => {
      addedGroups = data;

      let i = 0;
      _.map(groups, (group) => {
        if (!group.isBeingDeleted && !group.isDeleted && !group.id && !group.type) {
          Object.assign(group, { id: addedGroups[i].id });
          i += 1;
        }

        return group;
      });

      return updateDeletedGroups(groups, newGroups, addedGroups, deletedGroups);
    })
    .then((updatedDeletedGroups) => removeProjectsGroups(updatedDeletedGroups))
    .then(() => updateProjectsGroups(updatedGroups))
    .then(() => setOrderProjectsGroups(groups, groupsOrder, idAccount, dispatch))
    .then((data) => {
      newGroupsOrder = data;
      return updateProjectsGroupsExpandedMode(groups, dispatch, groupsExpandedMode);
    })
    .then(() => setProjectsGroupsAccess(groups, canAddEditDeleteProjectGroups))
    .then((newGroupsExpandedMode) => {
      dispatch({
        type: projectsGroupsActions.SAVE_PROJECTS_GROUPS,
      });
      projects.fetchProjects(newGroupsOrder, newGroupsExpandedMode)(dispatch);
    })
    .catch(() => {
      dispatch({
        type: projectsGroupsActions.PROJECTS_GROUPS_ERROR,
      });
    });
};

export const fetchProjectsGroups = () => (dispatch) => {
  dispatch(requestFetchingProjectGroups());
  request.get('/project_groups/access')
    .then((data) => {
      dispatch({
        type: projectsGroupsActions.FETCH_PROJECTS_GROUPS_ACCESS,
        access: data,
      });
    })
    .catch(() => {
      dispatch({
        type: projectsGroupsActions.PROJECTS_GROUPS_ERROR,
      });
    });
};
