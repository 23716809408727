import React from 'react';
import { Tooltip } from '@uxpin/design-system';

// eslint-disable-next-line react/prop-types
const TooltipWithShortcut = ({ children }) => (
  <Tooltip>
    <div className="tooltip-with-shortcut">
      {children}
    </div>
  </Tooltip>
);

export default TooltipWithShortcut;
