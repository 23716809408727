/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */

import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
// eslint-disable-next-line import/no-named-as-default
import ProjectsList from '../../ProjectsList/ProjectsList';
import ProjectBar from '../../ProjectBar/ProjectBar';
import {
  FEATURES,
  FEATURE_STATUS,
} from '../../../../constants/features.constants';
import { UnstarredProjectsEmptyPage, StarredProjectsEmptyPage } from '../../EmptyPage/EmptyPage';
import { isItemIncluded } from '../../../../controllers/actions/settings.actions';
import { starFilteringTypes } from '../../../../constants/settings.constants';

export default class ProjectsRoute extends React.Component {
  componentDidMount() {
    if (!this.props.groupsOrder) {
      return;
    }
    this.loadProjects(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.groupsOrder && nextProps.groupsOrder) {
      this.loadProjects(nextProps);
    }
  }

  componentWillUnmount() {
    this.props.closeOpenedModals();
  }

  loadProjects(props) {
    const {
      getProjects, groupsOrder, items, refreshProjects, groupsExpandedMode,
    } = props;
    if (items.length) {
      refreshProjects(groupsOrder);
    } else {
      getProjects(groupsOrder, groupsExpandedMode);
    }
  }

  shouldComponentUpdate(nextProps) {
    return Object.keys(this.props).some((propName) => !_.isEqual(this.props[propName], nextProps[propName]));
  }

  renderProjectList() {
    const {
      collapsedGroups,
      expandProjectsGroup,
      collapseProjectsGroup,
      saveProjectsGroupsExpandedMode,
      items,
      favsProjects,
      isLoading,
      getNextProjects,
      getProjects,
      sortType,
      filterType,
      page,
      getMembers,
      fetchProjectMembers,
      isProjectMembersLoading,
      groupsExpandedMode,
      clearSelectedProjects,
      features,
    } = this.props;

    const projects = getAllProjects(items);

    const filteredProjects = projects.filter((project) => isItemIncluded(project.idProject, favsProjects, filterType));

    if (filteredProjects.length === 0 && projects.length !== 0) {
      if (filterType === starFilteringTypes.STARRED) {
        return (
          <StarredProjectsEmptyPage />
        );
      }

      return <UnstarredProjectsEmptyPage />;
    }

    return (
      <>
        <ProjectsList
          items={items}
          getProjects={getProjects}
          sortType={sortType}
          clearSelectedProjects={clearSelectedProjects}
          collapseProjectsGroup={collapseProjectsGroup}
          saveProjectsGroupsExpandedMode={saveProjectsGroupsExpandedMode}
          expandProjectsGroup={expandProjectsGroup}
          getNextProjects={getNextProjects}
          isLoading={isLoading}
          collapsedGroups={collapsedGroups}
          page={page}
          getMembers={getMembers}
          fetchProjectMembers={fetchProjectMembers}
          isProjectMembersLoading={isProjectMembersLoading}
          groupsExpandedMode={groupsExpandedMode}
          refreshedLayout={
            features[FEATURES.REFRESHED_LAYOUT] === FEATURE_STATUS.ENABLED
          }
        />
        <div className="baseline" />
      </>
    );
  }

  render() {
    const {
      isLoading,
      sortType,
      changeFilterType,
      setSortType,
      filterType,
    } = this.props;

    return (
      <main className="main-content">
        <ProjectBar
          sortType={sortType}
          isLoading={isLoading}
          setSortType={setSortType}
          changeFilterType={changeFilterType}
          filterType={filterType}
        />
        {this.renderProjectList()}
        {this.props.children}
      </main>
    );
  }
}

ProjectsRoute.propTypes = {
  items: PropTypes.array.isRequired,
  collapsedGroups: PropTypes.array.isRequired,
  getNextProjects: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  page: PropTypes.number.isRequired,
  expandProjectsGroup: PropTypes.func.isRequired,
  collapseProjectsGroup: PropTypes.func.isRequired,
};

function getAllProjects(items) {
  return _.flatten(items.map((item) => item.projects));
}
