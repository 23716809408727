import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, SCALE } from '@uxpin/design-system';

const OnboardingAddUsers = ({
  setNextStep,
  setOnboardingFlowAsSeen,
  dispatchAddUsersOnboarding,
  sendTrackingEvent,
}) => {
  const [emails, setEmails] = useState([]);
  const [emailInput, setEmailInput] = useState('');

  const isValidEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  const [emailError, setEmailError] = useState(false);
  const [emailDuplicate, setEmailDuplicate] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const handleEmailChange = (event) => {
    setEmailInput(event.target.value);
  };

  const handleEmailAddition = (email) => {
    if (isValidEmail(email)) {
      if (emails.includes(email)) {
        setEmailError(false);
        setEmailDuplicate(true);
      } else {
        setEmails([...emails, email]);
        setEmailError(false);
        setEmailDuplicate(false);
      }
    } else {
      setEmailError(true);
      setEmailDuplicate(false);
    }
    setEmailInput('');
  };

  const handleEmailRemove = (emailToRemove) => {
    setEmails(emails.filter((email) => email !== emailToRemove));
  };

  const handleKeyDown = (event) => {
    if (['Enter', ',', ' ', 'Tab'].includes(event.key)) {
      event.preventDefault();
      handleEmailAddition(emailInput.trim());
    }
  };

  const handleSubmit = () => {
    setSubmitting(true);
    dispatchAddUsersOnboarding(emails)
      .then(() => setNextStep())
      .catch(() => setNextStep());
  };

  const handleSkip = () => {
    sendTrackingEvent();
    setOnboardingFlowAsSeen(true);
  };

  const renderEmailBox = (email, index) => (
    <div key={index} className="email-box">
      {email}
      <button type="button" onClick={() => handleEmailRemove(email)}>X</button>
    </div>
  );

  const isAddButtonDisabled = () => emails.length === 0 || emails.some((email) => !isValidEmail(email));

  return (
    <div className="onboarding-content">
      <h2 className="header">Add your teammates</h2>
      <span className="text">
        Enter multiple email addresses (separate by comma &quot;,&quot;).<br/>
        Tell your teammates to check their inbox for an invite.
      </span>
      <div className="email-boxes-container">
        {emails.map(renderEmailBox)}
        <input
          type="text"
          className="email-input"
          value={emailInput}
          onChange={handleEmailChange}
          onKeyDown={handleKeyDown}
          placeholder="Enter email address"
        />
      </div>
      {emailError && <div className="error-message">Incorrect email format</div>}
      {emailDuplicate && <div className="error-message">Hey, you are trying to add the same email twice</div>}
      <section className="action-container-add">
        <button className="gray-link underline-link btn-skip" type="button" onClick={handleSkip}>Skip</button>
        <Button
          className="btn-add"
          onClick={handleSubmit}
          isWorking={submitting}
          isDisabled={isAddButtonDisabled()}
          size={SCALE.L}>Add {emails.length} new user{emails.length !== 1 ? 's' : ''}
        </Button>
      </section>
    </div>
  );
};

OnboardingAddUsers.propTypes = {
  setOnboardingFlowAsSeen: PropTypes.func.isRequired,
  dispatchAddUsersOnboarding: PropTypes.func.isRequired,
  setNextStep: PropTypes.func.isRequired,
  sendTrackingEvent: PropTypes.func.isRequired,
};

export default OnboardingAddUsers;
