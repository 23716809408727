/* eslint-disable react/require-default-props */

'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';
import { dateConvert } from '../../../utils/dateConvert';

export default class DateTime extends React.Component {
  static get defaultProps() {
    return {
      // eslint-disable-next-line react/default-props-match-prop-types
      date: new Date(),
      dateFormat: 'D MMMM YYYY',
      titleFormat: 'D MMMM YYYY [at] h:mm:ss a',
      className: '',
    };
  }

  shouldComponentUpdate(nextProps) {
    /* istanbul ignore next */
    // eslint-disable-next-line react/destructuring-assignment
    return Object.keys(this.props).some((propName) => !_.isEqual(this.props[propName], nextProps[propName]));
  }

  renderDate() {
    const {
      date, dateFormat, showFromNow, fromNowDaysLimit,
    } = this.props;
    let dateOutput = moment(date).format(dateFormat);

    if (showFromNow) {
      const dateNow = new Date();
      const dateInput = new Date(date);
      const diff = Math.abs(dateNow - dateInput);
      const { days } = dateConvert(diff);

      if (!fromNowDaysLimit || (fromNowDaysLimit && days < fromNowDaysLimit)) {
        dateOutput = moment(date).fromNow();
      }
    }

    return dateOutput;
  }

  renderTitle() {
    const { date, titleFormat } = this.props;

    return moment(date).format(titleFormat);
  }

  render() {
    const { date, className } = this.props;

    return (
      <time className={className} title={this.renderTitle()} dateTime={moment(date).toISOString()}>
        {this.renderDate()}
      </time>
    );
  }
}

DateTime.propTypes = {
  date: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]).isRequired,
  dateFormat: PropTypes.string,
  titleFormat: PropTypes.string,
  showFromNow: PropTypes.bool,
  fromNowDaysLimit: PropTypes.number,
  className: PropTypes.string,
};
