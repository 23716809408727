import config from 'react-global-configuration';
import { DesignSystemUtils } from '@uxpin/shared-components';
import { isEmpty, includes, filter, get } from 'lodash';
import { PERMISSION_ACCESS_SCOPE } from '../../constants/designSystems.constants';
import { LIBRARY_TYPES } from '../../../enums/designLibraries';
import { request } from '../../utils/request';
import routes from '../../constants/routes.constants';

const DESIGN_SYSTEM_URI = `${config.get('APP_URL')}/${routes.DESIGN_SYSTEM}`;

const MERGE_URI = `${config.get('APP_URL')}/${routes.MERGE_LIBRARY_MANAGER}`;

const withDesignSystem = (library) => library.hasDesignSystem || DesignSystemUtils.isCodeSyncLibrary(library);

const isStorybookLibrary = (library) => library.type === LIBRARY_TYPES.STORYBOOK;

export const getViewUri = (libraryHash) => `${DESIGN_SYSTEM_URI}/${libraryHash}`;

export const getMergeViewUri = (libraryId) => `${MERGE_URI}/${libraryId}`;

export const getEditUri = (libraryHash) => `${DESIGN_SYSTEM_URI}/edit/${libraryHash}`;

export const getCreateUri = () => routes.DESIGN_SYSTEMS_CREATE;

export const getCreateFromScratchUri = () => `${DESIGN_SYSTEM_URI}/create/new`;

export const isLibraryGlobal = (library) => {
  const scopeGroup = get(library, 'accessScope.scopeGroup', null);

  if (!scopeGroup) {
    return false;
  }

  return scopeGroup === PERMISSION_ACCESS_SCOPE.GLOBAL;
};

export const canEditSystem = (system, idUser) => {
  if (isEmpty(system)) {
    return false;
  }

  const { scopeGroup, selectedUsers } = system.editScope;

  if (isLibraryGlobal(system)) {
    return false;
  }

  return scopeGroup === PERMISSION_ACCESS_SCOPE.ACCOUNT
    || (scopeGroup === PERMISSION_ACCESS_SCOPE.USER && system.idUser === idUser)
    || (scopeGroup === PERMISSION_ACCESS_SCOPE.CUSTOM && includes(selectedUsers, idUser));
};

export const canViewSystem = (system, idUser) => {
  if (isEmpty(system)) {
    return false;
  }

  const { scopeGroup, selectedUsers } = system.accessScope;

  return canEditSystem(system, idUser)
    || scopeGroup === PERMISSION_ACCESS_SCOPE.ACCOUNT
    || (scopeGroup === PERMISSION_ACCESS_SCOPE.USER && system.idUser === idUser)
    || (scopeGroup === PERMISSION_ACCESS_SCOPE.CUSTOM && includes(selectedUsers, idUser));
};

export const hasUserBlockedAccessToEditSystem = (system, idUser) => !canEditSystem(system, idUser);

export const hasUserBlockedAccessToViewSystem = (system, idUser) => !canViewSystem(system, idUser);

export const isLimitHitCurrently = () => (
  request.get('/account/plan/')
    .then((planData) => DesignSystemUtils.isDesignSystemsLimitReached(planData))
);

export const getUserAvailableLibraries = (libraries, idUser) => filter(libraries, (library) => (
  !withDesignSystem(library) && !isStorybookLibrary(library) && !hasUserBlockedAccessToEditSystem(library, idUser)
));

export const getLibrariesWithDesignSystem = (libraries) => filter(libraries, withDesignSystem);

export const getEditableAndVisibleMergeLibraries = (
    libraries,
    hiddenLibraries,
    currentUserId,
    isMergeEnabled,
    isStorybookEnabled
) => {
  const hiddenLibrariesSet = new Set(hiddenLibraries);
  return libraries.filter((library) => ((
     (library.type === LIBRARY_TYPES.CODE_SYNC && isMergeEnabled)
     || (library.type === LIBRARY_TYPES.STORYBOOK && isStorybookEnabled)
    )
    && !hiddenLibrariesSet.has(library.id)
    && canEditSystem(library, currentUserId)
  ));
};
