/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/prop-types */
/* eslint-disable react/require-default-props */

'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import autobind from 'autobind-decorator';
import classnames from 'classnames';
import { noop, isFunction } from 'lodash';

export default class FieldElement extends React.Component {
  constructor(props) {
    super(props);

    this.inputRef = {};

    this.state = {
      value: this.props.defaultValue || this.props.value || '',
      isValid: true,
      focus: false,
      errorMessage: '',
    };
  }

  @autobind
  getRef(el) {
    this.inputRef = el;
  }

  componentWillMount() {
    this.props.attachToForm(this);
  }

  componentWillUnmount() {
    this.props.detachFromForm(this);
  }

  componentDidUpdate(nextProps, nextState) {
    if (this.state.focus !== nextState.focus) {
      setTimeout(() => {
        this.inputRef.focus();
      }, 100);
    }
  }

  componentWillReceiveProps(nextProps) {
    const { isValid, errorMessage } = nextProps;

    if (isValid !== this.props.isValid) {
      this.setState({
        isValid,
        errorMessage,
      });
    }
  }

  @autobind
  handleBlur(e, customOnBlur) {
    const {
      checkMode, validate, MODES, isFieldsValue,
    } = this.props;
    const isValue = isFieldsValue();

    if (isFunction(customOnBlur)) {
      customOnBlur(e);
    }

    if (checkMode === MODES.ON_BLUR && isValue) {
      validate(this);
    }
  }

  @autobind
  handleKeyDown(e, customOnKeyDown) {
    const { checkMode, MODES } = this.props;

    if (checkMode === MODES.ON_BLUR && !this.state.isValid) {
      this.setState({
        isValid: true,
      });
    }

    if (isFunction(customOnKeyDown)) {
      customOnKeyDown();
    }
  }

  @autobind
  handleChange(e, customOnChange) {
    const { checkMode, MODES, validate } = this.props;

    this.setState({
      value: e.currentTarget.value,
    }, () => {
      if (isFunction(customOnChange)) {
        customOnChange(e);
      }

      if (checkMode === MODES.ON_CHANGE) {
        validate(this);
      }
    });
  }

  renderErrorMessage() {
    if (!this.state.isValid) {
      return <span className="error-message">{this.state.errorMessage}</span>;
    }
  }

  renderClassNames() {
    return classnames({ error: !this.state.isValid });
  }
}

FieldElement.propTypes = {
  attachToForm: PropTypes.func.isRequired,
  detachFromForm: PropTypes.func.isRequired,
  validate: PropTypes.func,
  MODES: PropTypes.object,
  isValid: PropTypes.bool,
  errorMessage: PropTypes.string,
  isFieldsValue: PropTypes.func,
};

FieldElement.defaultProps = {
  attachToForm: noop,
  detachFromForm: noop,
  isFieldsValue: noop,
  errorMessage: '',
  isValid: null,
};
