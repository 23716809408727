/* eslint-disable react/destructuring-assignment */

'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import autobind from 'autobind-decorator';
import { noop } from 'lodash';
import Tooltip from '../../Helpers/Tooltip';
import { preventDefaultEvent } from '../../../../utils/common';
import { getPlanTypeByName } from '../../../../utils/account';
import { accountPlanTypes } from '../../../../constants/accountSettings.constants';

export default class DesignLimitsInfoComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      // eslint-disable-next-line react/no-unused-state
      isWarning: false,
      accountPlanName: '',
    };

    // Tooltip copy:
    const { PROTOTYPING, SYSTEMS } = accountPlanTypes;
    this.tooltipText = {
      [PROTOTYPING]: 'To increase your limit to 50 prototypes, upgrade to Systems.',
      [SYSTEMS]: 'To get unlimited prototypes, upgrade to Enterprise.',
    };
  }

  componentWillReceiveProps(nextProps) {
    const { accountPlan } = nextProps;

    if (accountPlan !== this.props.accountPlan) {
      this.setState({
        accountPlanName: getPlanTypeByName(accountPlan.name),
      });
    }
  }

  @autobind
  openUpgradeModal(e) {
    preventDefaultEvent(e);
    this.props.openUpgradeModal();
  }

  renderInfoIcon() {
    return (
      <span className="icon-general-info">
        <Tooltip className="long-text">
          {this.tooltipText[this.state.accountPlanName]}
        </Tooltip>
      </span>
    );
  }

  renderUpgradeLink() {
    return (
      <a
        href="#upgrade-now"
        onClick={this.openUpgradeModal}
        className="blue-link underline-link">
        Upgrade
      </a>
    );
  }

  render() {
    return (
      <div id="design-limits-info">
        You’re over your prototype limits.
        {' '}
        {this.renderUpgradeLink()}
        {' '}
        to get unlimited prototypes.
      </div>
    );
  }
}

DesignLimitsInfoComponent.propTypes = {
  openUpgradeModal: PropTypes.func,
  accountPlan: PropTypes.object,
};

DesignLimitsInfoComponent.defaultProps = {
  openUpgradeModal: noop,
  accountPlan: {},
};
