import { connect } from 'react-redux';
import * as slack from '../../controllers/actions/slack.actions';
import SlackNotificationPanel from '../components/InsideProject/components/SlackNotificationPanel';

export const mapStateToProps = (state) => {
  const {
    isError, channels, slackState, selectedChannel,
  } = state.slack;
  const { features: { slack: slackFeature } } = state.features;

  return {
    isSlackEnabled: slackFeature === 'enabled',
    isError,
    channels,
    slackState,
    selectedChannel,
  };
};

export const mapDispatchToProps = (dispatch) => ({
  getChannelsList: (idProject) => {
    dispatch(slack.getChannelsList(idProject));
  },

  setChannel: (idProject, channel) => {
    dispatch(slack.setChannel(idProject, channel));
  },

  getState: () => {
    dispatch(slack.getState());
  },
});

export const SlackChannelList = connect(mapStateToProps, mapDispatchToProps)(SlackNotificationPanel);
