/* eslint-disable max-len */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */

'use strict';

import React, { Fragment } from 'react';
import autobind from 'autobind-decorator';
import { Cloud } from '@uxpin/shared-components';
import classnames from 'classnames';
import { GLOBAL_LIBRARIES } from '../../../../../enums/designLibraries';
import { BASIC_FILE_TYPES } from '../../../../utils/uploadFiles';
import { getMixpanel } from '../../../../../../shared/mixpanel';

const documentTypesMap = {
  Persona: 'persona',
  BMC: 'business-model-canvas',
  ProjectCanvas: 'project-canvas',
};

@Cloud()
export default class ActionDocuments extends React.Component {
  @autobind
  createUXDocument(documentType, e) {
    e.preventDefault();
    const { createUXDocument, showAddPrototypesLimitsModal, isLimitReached, closeCloud } = this.props;

    if (isLimitReached) {
      closeCloud();
      showAddPrototypesLimitsModal();
      return;
    }

    createUXDocument(documentTypesMap[documentType]);
  }

  @autobind
  getChevronClass() {
    return classnames('icon-general-chevron', { active: this.props.isOpen });
  }

  @autobind
  getTriggerClass(isDisabled) {
    return classnames(
      this.getChevronClass(),
      'underline-link',
      'blue-link',
      {
        disabled: isDisabled,
      }
    );
  }

  handleAddNewLibrary = (e) => {
    e.preventDefault();
    const { openModalboxAddMergeLibrary, isLimitReached, designLimitsModalTrigger } = this.props;
    if (isLimitReached) {
      designLimitsModalTrigger();
      return;
    }

    openModalboxAddMergeLibrary();

    getMixpanel((mixpanel) => {
      mixpanel.track('add_library_modal_viewed');
    });
  }

  handleCreateMergeDocument = (e, name) => {
    const { createMergeDocument, isLimitReached, designLimitsModalTrigger } = this.props;
    e.preventDefault();
    if (isLimitReached) {
      designLimitsModalTrigger();
      return;
    }

    createMergeDocument(e, name);
  }

  openBrowserFilePicker = (e) => {
    e.preventDefault();
    const { openBrowserFilePicker } = this.props;
    openBrowserFilePicker(e);
  }

  renderUploadAction() {
    const { isDocumentUploadEnabled, isLimitReached, designLimitsModalTrigger } = this.props;
    const linkAction = isLimitReached ? designLimitsModalTrigger : this.openBrowserFilePicker;
    const copy = 'Import Sketch, images or other';

    if (isDocumentUploadEnabled) {
      return (
        <li>
          <a
            href="#upload-design"
            onClick={linkAction}
            title={`Sketch, ${BASIC_FILE_TYPES}`}>
            {copy}
          </a>
        </li>
      );
    }

    return (
      <li>
        <a
          href="#upload-design"
          className="disabled">
          {copy}
        </a>
      </li>
    );
  }

  renderCloud() {
    const { isUXDocumentsEnabled, isMergeDisabled } = this.props;
    return (
      <ul {...this.props.cloud} className="cloud options-cloud document-actions-cloud">
        {!isMergeDisabled && (
        <Fragment>
          <li className="section-header"><em className="section-name">Library based</em></li>
          {GLOBAL_LIBRARIES.map((lib) => {
            if (!this.props.isMergeLimitedLibrariesEnabled && lib.isLimitedLibrary) {
              return null;
            }

            return (<li key={lib.name} onClick={(e) => this.handleCreateMergeDocument(e, lib.name)} className="with-icon">
              <a href={`#${lib.label}`}>{lib.label}</a>
              <i className={lib.icon}/>
            </li>);
          })}
          <li>
            <a className="ds-icon--font__plus" onClick={this.handleAddNewLibrary} href="#new-lib">Add new Library</a>
          </li>
        </Fragment>
        )}
        {isUXDocumentsEnabled && (
        <Fragment>
          <li className="section-header"><em className="section-name">UX Documents</em></li>
          <li><a href="#add-persona" onClick={this.createUXDocument.bind(this, 'Persona')}>Persona</a></li>
          <li><a href="#add-business-model-canvas" onClick={this.createUXDocument.bind(this, 'BMC')}>Business Model Canvas</a></li>
          <li><a href="#add-project-canvas" onClick={this.createUXDocument.bind(this, 'ProjectCanvas')}>Project Canvas</a></li>
        </Fragment>
        )}
        <li className="separator" />
        {this.renderUploadAction()}
      </ul>
    );
  }

  render() {
    const { trigger } = this.props;

    return (
      <menu>
        <a
          {...trigger}
          href="#add-persona-or-other-ux-document"
          className={this.getTriggerClass()}>
          Start with import or template
        </a>
        {this.renderCloud()}
      </menu>
    );
  }
}
