import { connect } from 'react-redux';
import InsideProjectEmptyComponent from '../components/InsideProjectList/components/InsideProjectEmpty';
import * as modal from '../../controllers/actions/modal.actions';
import { modalsIds, limitsContentTypes } from '../../constants/modal.constants';
import { isFreePlanType } from '../../utils/account';
import { isSketchDirectUploadAllowed } from '../../utils/uploadFiles';
import { isMergeDisabled } from '../../controllers/helpers/features.helper';
import {FEATURE_STATUS, FEATURES} from "../../constants/features.constants";

export const mapStateToProps = (state) => {
  const { totalDocumentsCount } = state.insideProject;
  const { accountPlan } = state.accountSettings;
  const { features } = state.features;
  const isFreePlan = isFreePlanType(accountPlan);
  const isSketchUploadAllowed = isSketchDirectUploadAllowed(features.sketch_direct_upload);

  return {
    totalDocumentsCount,
    triggerType: 'hover',
    isFreePlan,
    isSketchUploadAllowed,
    isTrial: accountPlan.is_trial,
    isMergeDisabled: isMergeDisabled(features),
    isMergeLimitedLibrariesEnabled: features[FEATURES.MERGE_LIMITED_LIBRARIES] === FEATURE_STATUS.ENABLED,
  };
};

export const mapDispatchToProps = (dispatch) => ({
  showAddPrototypesLimitsModal: () => {
    dispatch(modal.openModal(modalsIds.LIMITS_EXCEEDED, {
      forcedContentType: limitsContentTypes.PROTOTYPE_ADD_LIMIT,
    }));
  },
  openModalboxAddMergeLibrary: () => {
    dispatch(modal.openModal(modalsIds.MODALBOX_ADD_MERGE_LIBRARY_BY_GIT_AND_NPM, { shouldCreateDocument: true }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(InsideProjectEmptyComponent);
