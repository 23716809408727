'use strict';

export const modalActions = {
  CLOSE_MODAL: 'CLOSE_MODAL',
  CLOSE_OPENED_MODAL: 'CLOSE_OPENED_MODAL',
  CLOSE_GDPR: 'CLOSE_GDPR',
  OPEN_MODAL: 'OPEN_MODAL',
  REQUEST_SUBMITTING_WELCOME_FORM: 'REQUEST_SUBMITTING_WELCOME_FORM',
  SAVE_WELCOME_MODAL_VISIBILITY: 'SAVE_WELCOME_MODAL_VISIBILITY',
  SUBMIT_LEAD_FORM: 'SUBMIT_LEAD_FORM',
  SUBMIT_WELCOME_FORM: 'SUBMIT_WELCOME_FORM',
  SUBMITTING_LEAD_FORM_ERROR: 'SUBMITTING_LEAD_FORM_ERROR',
  SUBMITTING_WELCOME_FORM_ERROR: 'SUBMITTING_WELCOME_FORM_ERROR',
};

export const modalsIds = {
  APPROVAL_PROCESS: 'APPROVAL_PROCESS',
  CUSTOM_CSS_INSIDE_SALES: 'CUSTOM_CSS_INSIDE_SALES',
  CUSTOM_CSS_ONLINE_SALES: 'CUSTOM_CSS_ONLINE_SALES',
  EXTEND_TRIAL_FORM: 'extend-trial-form',
  LIMITS_EXCEEDED: 'limits-exceeded',
  MODALBOX_ADD_MERGE_LIBRARY_BY_GIT_AND_NPM: 'MODALBOX_ADD_MERGE_LIBRARY_BY_GIT_AND_NPM',
  MODALBOX_ADD_MERGE_LIBRARY_BY_STORYBOOK: 'MODALBOX_ADD_MERGE_LIBRARY_BY_STORYBOOK',
  MODALBOX_ADD_USER: 'MODALBOX_ADD_USER',
  MODALBOX_ADD_ON_OFFER_URL: 'addon-special-offer',
  MODALBOX_ADD_ON_SPEC_MODE_PROMO: 'MODALBOX_ADD_ON_SPEC_MODE_PROMO',
  MODALBOX_ADD_ON_SPEC_MODE_UPGRADE: 'MODALBOX_ADD_ON_SPEC_MODE_UPGRADE',
  MODALBOX_ADD_ON_SPEC_MODE_SUCCESS: 'MODALBOX_ADD_ON_SPEC_MODE_SUCCESS',
  MODALBOX_ADD_ON_CUSTOM_FONTS_PROMO: 'MODALBOX_ADD_ON_CUSTOM_FONTS_PROMO',
  MODALBOX_ADD_ON_CUSTOM_FONTS_UPGRADE: 'MODALBOX_ADD_ON_CUSTOM_FONTS_UPGRADE',
  MODALBOX_ADD_ON_CUSTOM_FONTS_SUCCESS: 'MODALBOX_ADD_ON_CUSTOM_FONTS_SUCCESS',
  MODALBOX_ADD_ON_FREE_FLOW_DOCS_PROMO: 'MODALBOX_ADD_ON_FREE_FLOW_DOCS_PROMO',
  MODALBOX_ADD_ON_FREE_FLOW_DOCS_UPGRADE: 'MODALBOX_ADD_ON_FREE_FLOW_DOCS_UPGRADE',
  MODALBOX_ADD_ON_FREE_FLOW_DOCS_SUCCESS: 'MODALBOX_ADD_ON_FREE_FLOW_DOCS_SUCCESS',
  MODALBOX_ADD_ONS_FOLLOW_UP_UPGRADE: 'MODALBOX_ADD_ONS_FOLLOW_UP_UPGRADE',
  MODALBOX_ADD_ONS_FOLLOW_UP_SUCCESS: 'MODALBOX_ADD_ONS_FOLLOW_UP_SUCCESS',
  MODALBOX_CHANGELOG: 'MODALBOX_CHANGELOG',
  MODALBOX_CHOOSE_TYPE_AND_CREATE_MERGE_LIBRARY: 'MODALBOX_CHOOSE_TYPE_AND_CREATE_MERGE_LIBRARY',
  MODALBOX_CONTACT_REQUEST: 'MODALBOX_CONTACT_REQUEST',
  MODALBOX_DESIGN_LIMIT_REACHED: 'design-limit-reached-modal-box',
  MODALBOX_DESIGN_SYSTEM_DELETE: 'MODALBOX_DESIGN_SYSTEM_DELETE',
  MODALBOX_DESIGN_SYSTEM_EARLY_NOTIFICATION: 'MODALBOX_DESIGN_SYSTEM_EARLY_NOTIFICATION',
  MODALBOX_DESIGN_SYSTEM_PERMISSIONS: 'MODALBOX_DESIGN_SYSTEM_PERMISSIONS',
  MODALBOX_DOCUMENT_CHANGE_NAME: 'MODALBOX_DOCUMENT_CHANGE_NAME',
  MODALBOX_DOCUMENT_CHANGE_PROJECT: 'MODALBOX_DOCUMENT_CHANGE_PROJECT',
  MODALBOX_DOCUMENT_DELETE: 'MODALBOX_DOCUMENT_DELETE',
  MODALBOX_GENERAL_MESSAGE: 'MODALBOX_GENERAL_MESSAGE',
  MODALBOX_GROUPS_SETTINGS: 'MODALBOX_GROUPS_SETTINGS',
  MODALBOX_MERGE_SETTING: 'MODALBOX_MERGE_SETTING',
  MODALBOX_PRIVACY_POLICY: 'MODALBOX_PRIVACY_POLICY',
  MODALBOX_PRODUCT_NEWS: 'MODALBOX_PRODUCT_NEWS',
  MODALBOX_PROJECT_CHANGE_GROUP_INFO: 'MODALBOX_PROJECT_CHANGE_GROUP_INFO',
  MODALBOX_PROJECT_CHANGE_GROUP: 'MODALBOX_PROJECT_CHANGE_GROUP',
  MODALBOX_PROJECT_CHANGE_NAME: 'MODALBOX_PROJECT_CHANGE_NAME',
  MODALBOX_PROJECT_DELETE: 'MODALBOX_PROJECT_DELETE',
  MODALBOX_SHARE: 'MODALBOX_SHARE',
  ONBOARDING_MODAL: 'onboarding-modal',
  PATTERN_ERROR: 'pattern-error',
  PATTERN_NOT_AVAILABLE: 'pattern-not-available',
  PATTERN_READY: 'pattern-ready',
  PROJECT_SETTINGS: 'PROJECT_SETTINGS',
  UPGRADE: 'upgrade',
  TEAM_UPSELL: 'team-offer',
  PRO_UPSELL: 'pro-offer',
  PHONE_PERSONA: 'phone-persona',
  MODALBOX_ADD_COMPONENT: 'MODALBOX_ADD_COMPONENT',
};

export const extendModalSource = {
  DASHBOARD: 'dashboard',
  EMAIL: 'email',
  SYSTEM: 'system',
};

export const limitsExceededTypes = {
  PROTOTYPES: 'prototypes',
  USERS: 'users',
  USERS_PROTOTYPES: 'users-prototypes',
};

export const limitsContentTypes = {
  SINGLE_SEAT_USERS: 'SINGLE_SEAT_USERS',
  TEAM_USERS: 'TEAM_USERS',
  SMALL_TEAM_USERS: 'SMALL_TEAM_USERS',
  PROTOTYPE_ADD_LIMIT: 'PROTOTYPE_ADD_LIMIT',
  PROTOTYPE_UNARCHIVE_LIMIT: 'PROTOTYPE_UNARCHIVE_LIMIT',
};

export const limitsButtonTypes = {
  MANAGE_USERS: 'MANAGE_USERS',
  SEND_CONTACT_REQUEST: 'SEND_CONTACT_REQUEST',
  UPGRADE: 'UPGRADE',
  CANCEL: 'CANCEL',
};

export const DESIGN_SYSTEMS_LIMIT_TYPE = 'design-systems';
