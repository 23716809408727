/* eslint-disable react/destructuring-assignment,max-len */

'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import autobind from 'autobind-decorator';
import classnames from 'classnames';
import { isBoolean, isEmpty, noop } from 'lodash';
import { Modal } from '@uxpin/shared-components';
import { modalsIds } from '../../../../constants/modal.constants';
import { addOnCodes } from '../../../../constants/marketing.constants';
import { preventDefaultEvent } from '../../../../utils/common';

export default class ModalboxAddOnUpsellUpgradeComponent extends React.Component {
  constructor(props) {
    super(props);

    this.closeHelper = noop;
    this.MODAL = {
      WRAPPER_ID: modalsIds.MODALBOX_ADD_ONS_FOLLOW_UP_UPGRADE,
      CLASS_NAMES: 'marketing-promo-modal-box no-image',
    };
    this.state = {
      applyingAddOnInProgress: false,
      isOpen: false,
    };
  }

  componentDidMount() {
    this.updateState(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.updateState(nextProps);
  }

  updateState(props) {
    const { isMonthly, planName } = props;
    const { addOnData, isOpen } = this.props;

    if (isOpen && !this.state.isOpen && isBoolean(isMonthly) && planName && !isEmpty(addOnData)) {
      this.setState({
        isOpen: true,
      });
    }
  }

  @autobind
  onClose(e) {
    const { closeAddOnUpgradeModal, onClose } = this.props;

    preventDefaultEvent(e);
    closeAddOnUpgradeModal();
    onClose();
    this.closeHelper.hideModal();
  }

  @autobind
  primaryAction(e) {
    const { addOn, applyAddOn, onUpgradeClick } = this.props;

    preventDefaultEvent(e);
    this.setState({
      applyingAddOnInProgress: true,
    });

    applyAddOn(addOnCodes[addOn])
      .then((data) => {
        if (data.success) {
          onUpgradeClick();
        }
      });
  }

  @autobind
  secondaryAction(e) {
    preventDefaultEvent(e);
    this.onClose(e);
  }

  @autobind
  renderTitle() {
    const { addOnData, planName } = this.props;

    return (<h2>Upgrade to {planName} with {addOnData.addOnName}</h2>);
  }

  renderContent() {
    const { addOnData, isMonthly } = this.props;
    const {
      addOnName, quantity, remainingDays, remainingDaysPrice, totalPeriodPrice,
    } = addOnData;

    if (isMonthly) {
      return (
        <div>
          <p>
            Your new monthly price will be ${totalPeriodPrice * quantity}.
          </p>
          <br />
          <p>
            Your card will be charged ${remainingDaysPrice} to activate {addOnName} for the remaining {remainingDays} days of your current subscription period.
          </p>
        </div>
      );
    }

    return (
      <p>
        Your card will be charged ${remainingDaysPrice} to activate {addOnName} for the remaining {remainingDays} days of your current subscription period.
      </p>
    );
  }

  render() {
    const { isOpen } = this.state;

    if (!isOpen) {
      return null;
    }

    return (
      <Modal
        ref={(c) => { this.closeHelper = c; }}
        wrapperId={this.MODAL.WRAPPER_ID}
        classNames={this.MODAL.CLASS_NAMES}
        renderTitle={this.renderTitle}
        isOpen={isOpen}
        onClose={this.onClose}>
        <section className="modal-box-content">
          {this.renderContent()}
        </section>
        <footer>
          <ul className="btns-group">
            <li>
              <a
                href="#close"
                className="underline-link gray-link cancel-button"
                onClick={this.secondaryAction}>
                  Cancel
              </a>
            </li>
            <li>
              <button
                type="button"
                className={classnames('btn-solid', 'blue', { working: this.state.applyingAddOnInProgress })}
                onClick={this.primaryAction}>
                  Charge my credit card
              </button>
            </li>
          </ul>
        </footer>
      </Modal>
    );
  }
}

ModalboxAddOnUpsellUpgradeComponent.propTypes = {
  addOn: PropTypes.string.isRequired,
  addOnData: PropTypes.object.isRequired,
  applyAddOn: PropTypes.func,
  closeAddOnUpgradeModal: PropTypes.func,
  isMonthly: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onUpgradeClick: PropTypes.func,
  planName: PropTypes.string,
};

ModalboxAddOnUpsellUpgradeComponent.defaultProps = {
  applyAddOn: noop,
  closeAddOnUpgradeModal: noop,
  isMonthly: true,
  onClose: noop,
  onUpgradeClick: noop,
  planName: '',
};
