import { getMixpanel } from '../../../shared/mixpanel';
import { googleAnalytics } from './analyticsManager';

export function marketingTrackPageViewed({ browserHistory, userId }) {
  // track the first page loaded
  trackPageViewed(userId);

  browserHistory.listen(() => {
    trackPageViewed();
  });
}

const trackFirstUrl = (mixpanel) => {
  const parts = window.location.hostname.split('.');
  const subdomain = parts.shift();

  // Don't add first_visit_url under app. subdomain
  if (subdomain !== 'app') {
    mixpanel.people.set_once({
      first_visit_url: window.location.href,
    });
  }
};

function trackPageViewed(userId) {
  getMixpanel((mixpanel) => {
    trackFirstUrl(mixpanel);
    mixpanel.track('page_viewed', {
      page_title: document.title,
      app_part: 'uxpin-app-dashboard',
      // only en-US for dashboard
      page_language: 'en-US',
    });

    googleAnalytics.dataLayerPush({
      event: 'page_viewed',
      // only en-US for dashboard
      page_language: 'en-US',
      page_title: document.title,
      page_url: window.location.href,
      // userId is fetched and it is not available immediately
      // this happens on the first load; in that case fallback to the mixpanel super prop
      user_id: userId || mixpanel.persistence.props.$user_id,
    });
  });
}
