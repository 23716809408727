/* eslint-disable max-len */
/* eslint-disable radix */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */

'use strict';

import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { documentsActionsOnHover } from '../../constants/documents.constants';
import { search } from '../../controllers/actions/search.actions';
import * as documents from '../../controllers/actions/document.actions';

export const getDuplicatedDocumentsIds = (currentDocumentsActions) => {
  const duplicatedDocumentsIds = [];
  for (const i in currentDocumentsActions) {
    // eslint-disable-next-line no-prototype-builtins
    if (currentDocumentsActions.hasOwnProperty(i) && currentDocumentsActions[i] === documentsActionsOnHover.CHECKING_DUPLICATION_STATUS) {
      duplicatedDocumentsIds.push(parseInt(i));
    }
  }

  return duplicatedDocumentsIds.sort();
};

export const mapStateToProps = (state) => {
  const { currentDocumentsActions } = state.documentActions;
  const duplicatedDocumentsIds = getDuplicatedDocumentsIds(currentDocumentsActions);

  return {
    removingCounters: state.documentActions.removingCounters,
    duplicatedDocumentsIds,
  };
};

export const mapDispatchToProps = (dispatch) => ({
  stopActionOnDocument: (idDocument) => {
    dispatch(documents.stopActionOnDocument(idDocument));
  },

  removeDocumentPermanently: (idDocument) => {
    dispatch(documents.removeDocumentPermanently(idDocument));
  },

  checkDuplicationStatuses: (documentsIds) => {
    dispatch(documents.checkDuplicationStatuses(documentsIds));
  },

  countTimeToRemoveDocument: (idDocument) => {
    dispatch(documents.countTimeToRemoveDocument(idDocument));
  },

  removeDocumentFromSearchData: (idDocument) => {
    dispatch(search.removeDocumentFromSearchData(idDocument));
  },

  clearDocumentsActions: () => {
    dispatch(documents.clearDocumentsActions());
  },
});

export default class DocumentsActionsListenerComponent extends React.Component {
  componentWillUnmount() {
    const { clearDocumentsActions } = this.props;
    clearTimeout(this.timeout);
    clearTimeout(this.duplicatedDocumentsTimeout);
    clearDocumentsActions();
  }

  shouldComponentUpdate(nextProps) {
    if (!_.isEqual(this.props.duplicatedDocumentsIds, nextProps.duplicatedDocumentsIds) || !_.isEqual(this.props.removingCounters, nextProps.removingCounters)) {
      return true;
    }

    return false;
  }

  componentDidUpdate(prevProps) {
    const {
      duplicatedDocumentsIds,
      checkDuplicationStatuses,
      removingCounters,
      removeDocumentPermanently,
      removeDocumentFromSearchData,
      countTimeToRemoveDocument,
      stopActionOnDocument,
    } = this.props;

    if (!_.isEqual(this.props.duplicatedDocumentsIds, prevProps.duplicatedDocumentsIds)) {
      clearTimeout(this.duplicatedDocumentsTimeout);
      this.duplicatedDocumentsTimeout = setTimeout(() => { checkDuplicationStatuses(duplicatedDocumentsIds); }, 1000);
    }

    if (!_.isEqual(this.props.removingCounters, prevProps.removingCounters)) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        let idDocument;
        // eslint-disable-next-line guard-for-in
        for (const i in removingCounters) {
          idDocument = parseInt(i);
          if (removingCounters[idDocument] > 0) {
            countTimeToRemoveDocument(idDocument);
          } else if (removingCounters[idDocument] === 0) {
            stopActionOnDocument(idDocument);
            removeDocumentPermanently(idDocument);
            removeDocumentFromSearchData(idDocument);
          }
        }
      }, 1000);
    }
  }

  render() {
    return null;
  }
}

export const DocumentsActionsListener = connect(mapStateToProps, mapDispatchToProps)(DocumentsActionsListenerComponent);
