/* eslint-disable react/destructuring-assignment,max-len */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */

'use strict';

import React from 'react';
import { Modal } from '@uxpin/shared-components';
import autobind from 'autobind-decorator';

export default class ModalboxBillingSystemChange extends React.Component {
  constructor(props) {
    super(props);

    this.MODAL = {
      WRAPPER_ID: 'billing-system-change-modal-box',
      CLASS_NAMES: 'center-buttons modal-box-text-content',
    };
  }

  @autobind
  onClose(e) {
    if (e) {
      e.preventDefault();
    }

    this.props.onClose();
  }

  render() {
    return (
      <Modal
        wrapperId={this.MODAL.WRAPPER_ID}
        classNames={this.MODAL.CLASS_NAMES}
        title="UXPin has moved to the United States"
        isOpen={this.props.isOpen}
        isCloseBlocked
        onClose={this.onClose}>
        <section className="modal-box-content">
          <div className="update-info">
            <p>
              Starting Nov. 25, 2016, UXPin Inc. will replace UXPin Sp.z o.o as your service provider. UXPin Inc.,
              is registered in the United States of America and is the parent company for Polish UXPin Sp.z o.o.
            </p>
            <p>
              Due to this change, you will be switched to the new billing system and your next invoices will be issued
              by UXPin Inc. All other terms remain unchanged and does not affect your rights.
            </p>
            <p>
              Please visit our <a className="blue-link" href="https://www.uxpin.com/community/tutorials/billing-system/" target="_blank">"Community center"</a> for details or contact us at
              <a className="blue-link" href="mailto:hello@uxpin.com">hello@uxpin.com</a> if you have any questions.
            </p>
          </div>
        </section>
        <footer>
          <ul className="btns-group">
            <li>
              <a href="#close" className="btn-solid big" onClick={this.onClose}>Got it! Take me to the app</a>
            </li>
          </ul>
        </footer>
      </Modal>
    );
  }
}
