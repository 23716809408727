/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/require-default-props */

'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@uxpin/shared-components';
import autobind from 'autobind-decorator';
import _ from 'lodash';
import PluginDownloadButtons from '../Helpers/PluginDownloadButtons';

export default class ModalboxDownloadPlugin extends React.Component {
  constructor(props) {
    super(props);
    this.MODAL = {
      WRAPPER_ID: 'download-plugin-modal-box',
    };
  }

  @autobind
  renderTitle() {
    return (
      <h2>
        Take your Photoshop or
        <br />
        Sketch designs to the next level!
      </h2>
    );
  }

  @autobind
  renderDescription() {
    return (
      <p>
        Easily export your designs to UXPin. Build awesome prototypes with
        <br />
        advanced interactions, share and get feedback on your designs
      </p>
    );
  }

  shouldComponentUpdate(nextProps, nextState) {
    const propsNames = Object.keys(ModalboxDownloadPlugin.propTypes);
    return propsNames.some((propsName) => !_.isEqual(this.props[propsName], nextProps[propsName]))
      || !_.isEqual(this.state, nextState);
  }

  render() {
    return (
      <Modal
        wrapperId={this.MODAL.WRAPPER_ID}
        classNames={this.MODAL.CLASS_NAMES}
        renderTitle={this.renderTitle}
        renderDescription={this.renderDescription}
        isOpen={this.props.isOpen}
        onClose={this.props.onClose}>
        <section className="modal-box-content">
          <h3>Download plugin for:</h3>
        </section>
        <footer>
          <PluginDownloadButtons onClose={this.props.onClose} btnClass="big" />
        </footer>
      </Modal>
    );
  }
}

ModalboxDownloadPlugin.defaultProps = {
  isOpen: false,
  onClose: _.noop,
};

ModalboxDownloadPlugin.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
