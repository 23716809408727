/* eslint-disable max-len,react/button-has-type */
/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/require-default-props */

'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '@uxpin/design-system';
import autobind from 'autobind-decorator';
import _ from 'lodash';

import Truncate from '../../Helpers/Truncate';
import { DOCUMENT_STATUS_TO_MSG_MAP, ERROR_TYPES } from '../../../../constants/projects.constants';

export default class ProjectBoxPending extends React.Component {
  constructor(props) {
    super(props);

    this.NAME_MAX_LENGTH = 100;
  }

  renderValue() {
    const { value, error, document } = this.props;

    if (_.isNumber(value) && !error && (document.isFilesUploading || !document.isConverted)) {
      return (<h5 className="value">{value}</h5>);
    }

    return false;
  }

  @autobind
  cancelAction(e) {
    e.preventDefault();
    const { cancelConversion, cancelDuplication, document: { isConverted, idDocument } } = this.props;
    if (!isConverted) {
      return cancelConversion(idDocument);
    }

    return cancelDuplication(idDocument);
  }

  @autobind
  discardFailedReimport(e) {
    const { discardFailedReimport, document: { idDocument }, stopActionOnDocument } = this.props;
    e.preventDefault();
    stopActionOnDocument(idDocument);
    discardFailedReimport(idDocument);
  }

  @autobind
  discardFailedSketchImport(e) {
    const { discardFailedSketchImport, document: { idDocument }, stopActionOnDocument } = this.props;
    e.preventDefault();
    stopActionOnDocument(idDocument);
    discardFailedSketchImport(idDocument);
  }

  @autobind
  deleteAction(e) {
    const { deleteDocument, document: doc } = this.props;
    e.preventDefault();
    deleteDocument(doc.idDocument);
  }

  @autobind
  restoreDocument(e) {
    const { restoreDocument, document: { idDocument }, stopActionOnDocument } = this.props;
    e.preventDefault();
    stopActionOnDocument(idDocument);
    restoreDocument(idDocument);
  }

  @autobind
  tryAgain(e) {
    e.preventDefault();
    if (!this.props.error) { return; }

    this.props.error.tryAgain();
  }

  @autobind
  cancel(e) {
    e.preventDefault();
    if (!this.props.error) { return; }

    this.props.error.cancel();
  }

  @autobind
  removeDocument(e) {
    // eslint-disable-next-line react/prop-types
    const { removeDocumentPermanently, document, stopActionOnDocument } = this.props;
    e.preventDefault();
    removeDocumentPermanently(document.idDocument);
    stopActionOnDocument(document.idDocument);
  }

  renderLink(action, copy = 'Cancel') {
    return (
      <Link
        onClick={action}
        appearance="chameleon"
        href="#"
        iconName="action-close"
        iconSize="xs"
        modifierName="cancel-action">
        {copy}
      </Link>
    );
  }

  renderCancelButton() {
    const { document } = this.props;
    if (document.hasTemporaryId) {
      return null;
    }

    return (
      <p className="cancel-info hidden-info">
        {this.renderLink(this.cancelAction)}
      </p>
    );
  }

  @autobind
  renderErrorMessage(action, error, customMessage) {
    const { className, message, tryAgain } = error;

    return (
      <section className={`error-message hidden-info ${className}`}>
        <h5 className="info">{customMessage || message}</h5>
        {tryAgain && (
          <div>
            <button onClick={this.tryAgain} className="btn-ghost white btn-wide icon-general-revert with-icon">Try again</button>
          </div>
        )}
        <p className="cancel">
          {this.renderLink(action, 'Hide')}
        </p>
      </section>
    );
  }

  render() {
    const { document, counter } = this.props;
    const customMessage = DOCUMENT_STATUS_TO_MSG_MAP[document.status];

    return (
      <div className="inside-project-pending-info">
        {this.renderValue()}
        {this.renderErrorMessage(this.cancel, ERROR_TYPES.COMMON)}
        {this.renderErrorMessage(this.discardFailedReimport, ERROR_TYPES.REIMPORT)}
        {this.renderErrorMessage(this.discardFailedSketchImport, ERROR_TYPES.SKETCH_IMPORT, customMessage)}
        {this.renderErrorMessage(this.deleteAction, ERROR_TYPES.UXPFILE_IMPORT)}
        { this.renderCancelButton() }
        <p className="pending-info">
          <span>
            <span className="delete-info">
              &#34;<Truncate maxLength={this.NAME_MAX_LENGTH}>{document.name}</Truncate>&#34;
              {' '} has been deleted. You can still {' '}
            </span>
            <a
              href="#undo-delete"
              onClick={this.restoreDocument}
              className="blue-link underline-link">
              Undo.
            </a>
            <a
              href="#execute"
              onClick={this.removeDocument}
              title="Execute"
              className="execute icon-general-close only-icon-font">
              Execute
            </a>
            {' '}
            <em className="counter">{counter}s</em>
          </span>
        </p>
      </div>
    );
  }
}

ProjectBoxPending.propTypes = {
  cancelConversion: PropTypes.func,
  cancelDuplication: PropTypes.func.isRequired,
  counter: PropTypes.number,
  deleteDocument: PropTypes.func.isRequired,
  discardFailedReimport: PropTypes.func.isRequired,
  discardFailedSketchImport: PropTypes.func.isRequired,
  document: PropTypes.object.isRequired,
  error: PropTypes.object,
  restoreDocument: PropTypes.func.isRequired,
  stopActionOnDocument: PropTypes.func.isRequired,
  value: PropTypes.number,
};

ProjectBoxPending.defaultProps = {
  cancelConversion: _.noop,
  cancelDuplication: _.noop,
  counter: 0,
  document: { name: '' },
  restoreDocument: _.noop,
  stopActionOnDocument: _.noop,
};
