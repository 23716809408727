import { connect } from 'react-redux';
import ApprovalProcessComponent from '../../components/ApprovalProcess/ApprovalProcess';
import * as stakeholders from '../../../controllers/actions/stakeholders.actions';

export const mapStateToProps = (state) => {
  const {
    // eslint-disable-next-line no-shadow
    stakeholders, isLoading, areStakeholdersBeingModified, isRequestBeingModified, isRequestSendingError,
  } = state.stakeholders;

  return {
    stakeholders,
    isLoading,
    areStakeholdersBeingModified,
    isRequestBeingModified,
    isRequestSendingError,
  };
};

export const mapDispatchToProps = (dispatch) => ({
  fetchStakeholders: (idDocument, idApproval) => {
    dispatch(stakeholders.fetchStakeholders(idDocument, idApproval));
  },

  clearStakeholders: () => {
    dispatch(stakeholders.clearStakeholders());
  },

  restartApproval: (idDocument, idApproval) => {
    dispatch(stakeholders.restartApproval(idDocument, idApproval));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ApprovalProcessComponent);
