'use strict';

import { feedbackActions } from '../../constants/feedback.constants';

export function feedbackReducer(state = {
  isFeedbackSending: false,
}, action) {
  switch (action.type) {
    case feedbackActions.REQUEST_SENDING_FEEDBACK:
      return Object.assign({}, state, {
        isFeedbackSending: true,
      });
    case feedbackActions.SEND_FEEDBACK:
      return Object.assign({}, state, {
        isFeedbackSending: false,
      });
    default:
      return state;
  }
}
