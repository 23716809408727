/* globals window, navigator */

'use strict';

import { accountSettingsActions } from '../../constants/accountSettings.constants';
import { request, appRequest } from '../../utils/request';

const revertToOldDashboardError = () => ({
  type: accountSettingsActions.REVERT_TO_OLD_DASHBOARD_ERROR,
  isSaving: false,
  isError: true,
});

export const revertToOldDashboard = (isEnabled, feedback) => (dispatch) => {
  dispatch({
    type: accountSettingsActions.REQUEST_REVERT_TO_OLD_DASHBOARD,
    isSaving: true,
  });
  const promises = [];

  promises.push(request.put('/account/settings', {
    body: {
      isNewDashboardEnabled: isEnabled,
    },
  }, true));

  if (feedback) {
    promises.push(request.post('/feedback/dashboard', {
      body: {
        text: feedback,
        referrer: window.location.href,
        userAgent: navigator.userAgent,
      },
    }, true));
  }

  Promise.all(promises)
    .then((data) => {
      if (data[0].status === 200 && !isEnabled) {
        dispatch({
          type: accountSettingsActions.REVERT_TO_OLD_DASHBOARD,
          isSaving: false,
          isRevertSuccess: true,
        });
      } else if (data[0].status === 200 && isEnabled) {
        dispatch({
          type: accountSettingsActions.NOT_REVERT_TO_OLD_DASHBOARD,
          isSaving: false,
          isRevertSuccess: false,
        });
      } else {
        dispatch(revertToOldDashboardError());
      }
    })
    .catch(() => {
      dispatch(revertToOldDashboardError());
    });
};

export const toggleFetchingAccountPlanStatus = (isDuringFetchingAccountPlan) => ({
  type: accountSettingsActions.TOGGLE_FETCH_STATUS,
  state: isDuringFetchingAccountPlan,
});

export const fetchAccountPlan = () => (dispatch) => {
  dispatch(toggleFetchingAccountPlanStatus(true));
  return request.get('/account/plan/')
    .then((data) => {
      dispatch({
        type: accountSettingsActions.FETCH_ACCOUNT_PLAN,
        accountPlan: data,
      });
      dispatch(toggleFetchingAccountPlanStatus(false));

      return data;
    })
    .catch(() => {
      dispatch(toggleFetchingAccountPlanStatus(false));
    });
};

export const fetchAccountSettings = (data) => ({
  type: accountSettingsActions.FETCH_ACCOUNT_SETTINGS,
  data,
});

export const getAccountSettings = () => (dispatch) => {
  request.get('/account/settings')
    .then((data) => {
      dispatch(fetchAccountSettings(data));
      return data;
    })
    .catch(() => {
      dispatch(fetchAccountSettings());
    });
};

export const revertFromFreemiumTest = () => request.post('/account/rollbackToTrial')
  .then(() => {
    window.location.reload();
  })
  .catch(() => {
    // TODO Handle error
  });

export const getAccountProspectData = () => (dispatch) => {
  request.get('/me/prospectData')
    .then((data) => {
      dispatch({
        type: accountSettingsActions.FETCH_ACCOUNT_PROSPECT_DATA,
        data,
      });
      return data;
    })
    .catch(() => {
      // TODO handle error
    });
};

export const sendContactRequest = (formData) => () => (
  request.post('/me/sendContactRequest', {
    body: formData,
  }, true)
    .then((result) => result)
    .catch((e) => {
      console.log(e); // eslint-disable-line no-console
    })
);

export const saveFirstLastName = (firstname, lastname) => (
  appRequest.put(`/user`, {
    body: {
      user: {
        firstname,
        lastname,
      }
    },
  })
);
