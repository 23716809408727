import autobind from 'autobind-decorator';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { noop } from 'lodash';

export const MENU_LINK_TYPES = {
  BUTTON: 'button',
  LINK: 'link',
};

class MenuLink extends Component {
  @autobind
  handleClick() {
    const { action, closeCloud } = this.props;

    action();
    closeCloud();
  }

  @autobind
  handleMouseDown(event) {
    event.nativeEvent.stopImmediatePropagation();
  }

  renderChildren() {
    const {
      action, className, children, externalLink, url, testHandler, type,
    } = this.props;
    const basicProps = {
      className,
      'data-url-browser': externalLink,
      'data-test': testHandler,
      onMouseDown: this.handleMouseDown,
    };

    if (type === MENU_LINK_TYPES.BUTTON) {
      return (
        <button
          {...basicProps}
          className={classnames(basicProps.className, 'options-list-link')}
          onClick={this.handleClick}
          type="button">
          {children}
        </button>
      );
    }

    return (
      <a {...basicProps} onClick={action} href={url}>
        {children}
      </a>
    );
  }

  render() {
    return (
      <li>
        {this.renderChildren()}
      </li>
    );
  }
}

MenuLink.propTypes = {
  action: PropTypes.func,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  closeCloud: PropTypes.func,
  externalLink: PropTypes.bool,
  url: PropTypes.string.isRequired,
  testHandler: PropTypes.string,
  type: PropTypes.oneOf(Object.values(MENU_LINK_TYPES)),
};

MenuLink.defaultProps = {
  action: null,
  className: null,
  closeCloud: noop,
  externalLink: false,
  testHandler: '',
  type: MENU_LINK_TYPES.LINK,
};

export default MenuLink;
