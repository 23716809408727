import { connect } from 'react-redux';
import TeamManagementComponent from '../components/InsideProject/components/TeamManagement';
import { modalsIds } from '../../constants/modal.constants';
import * as modal from '../../controllers/actions/modal.actions';

export const mapStateToProps = (state) => {
  const { membersData, projectMembers, isProjectMembersLoading } = state.members;
  const { permissions } = state.permissions;
  const { data: currentUser } = state.currentUser;
  const { project } = state.insideProject;
  const { data: modalData, openedModalsIds } = state.modal;

  return {
    membersData,
    projectMembers,
    currentUser,
    project,
    permissions,
    isProjectMembersLoading,
    addUserModalData: modalData[modalsIds.MODALBOX_ADD_USER] || {},
    isAddUserModalOpen: openedModalsIds[modalsIds.MODALBOX_ADD_USER] || false,
  };
};

export const mapDispatchToProps = (dispatch) => ({
  openModalboxAddUser: (data) => {
    dispatch(modal.openModal(modalsIds.MODALBOX_ADD_USER, data));
  },
});

export const TeamManagement = connect(mapStateToProps, mapDispatchToProps)(TeamManagementComponent);
