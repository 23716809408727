import { connect } from 'react-redux';
import StarFilterComponent from '../components/ProjectBar/components/StarFilter';
import {
  changePrototypeFilterType,
  getPrototypeFilterType,
} from '../../controllers/actions/settings.actions';

export const mapStateToProps = (state) => {
  const filterType = getPrototypeFilterType(state);

  return {
    filterType,
  };
};

export const mapDispatchToProps = (dispatch) => ({
  changeFilterType: (newFilterType) => {
    dispatch(changePrototypeFilterType(newFilterType));
  },
});

export const StarFilter = connect(mapStateToProps, mapDispatchToProps)(StarFilterComponent);
