'use strict';

import { rolesActions } from '../../constants/roles.constants';

export function rolesReducer(state = {
  data: {},
}, action) {
  switch (action.type) {
    case rolesActions.FETCH_ROLES:
      return Object.assign({}, state, {
        data: action.data,
      });
    default:
      return state;
  }
}
