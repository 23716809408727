import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Button, Link } from '@uxpin/design-system';
import { Modal } from '@uxpin/shared-components';

export default class ModalboxPrivacyPolicy extends React.Component {
  constructor(props) {
    super(props);

    this.MODAL = {
      CLASS_NAMES: 'modalbox-privacy-policy',
    };
  }

  getContent() {
    /* eslint-disable max-len */
    return (
      <div>
        <p className="text">In order to comply with the Privacy Shield Framework, we’ve removed all references to the Swiss-U.S. Privacy Shield as our participation in the Privacy Shield does not apply to Swiss-U.S. Data transfers.</p>
        <p className="text">
          The effective date of the new
          {' '}
          <Link href="https://www.uxpin.com/privacy" target="_blank">Privacy Policy</Link>
          {' '}
          is June 16, 2020.<br />The previous Privacy Policy is available
          {' '}
          <Link href="https://www.uxpin.com/privacy-prior" target="_blank">here</Link>
          .
        </p>
      </div>
    );
    /* eslint-enable max-len */
  }

  render() {
    const { onClose } = this.props;

    return (
      <Modal
        classNames={this.MODAL.CLASS_NAMES}
        isOpen
        title="Privacy Policy Updates"
        isCancelHidden
        isCloseBlocked>
        <section className="modal-box-content">
          {this.getContent()}
        </section>
        <footer>
          <ul className="btns-group">
            <li>
              <Button
                onClick={onClose}>
                I understand &amp; wish to proceed
              </Button>
            </li>
          </ul>
        </footer>
      </Modal>
    );
  }
}

ModalboxPrivacyPolicy.propTypes = {
  onClose: PropTypes.func,
};

ModalboxPrivacyPolicy.defaultProps = {
  onClose: noop,
};
