/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */

'use strict';

import React from 'react';
import classnames from 'classnames';
import { GLOBAL_ELEMENTS } from '@uxpin/shared-components';
import { statusIdToClassMap } from '../../../../constants/projects.constants';

export default class ProjectStatus extends React.Component {
  renderHeading(name) {
    const headingClassNames = classnames('select-label', GLOBAL_ELEMENTS.HEADING_5);

    return (
      <h5 className={headingClassNames}>{name}</h5>
    );
  }

  render() {
    const { currentStage, currentStatus, currentStatusId } = this.props;

    if (!this.props.canAddEditDeleteProjectStatus) {
      return (
        <section className="project-status">
          {this.renderHeading('Project status:')}
          <p
            className="status"
            data-status={statusIdToClassMap[currentStatusId]}>
            <span className="stage-name" title={currentStage}>{ currentStage }</span>
            <span className="status-name">{ currentStatus }</span>
          </p>
        </section>
      );
    }

    return (
      <section className="project-status">
        {this.renderHeading('Project status:')}
        <a
          href="#open-project-settings"
          className="gray-link status"
          data-status={statusIdToClassMap[currentStatusId]}
          onClick={this.props.openProjectSettings}>
          <span className="underline-helper">
            <span className="stage-name" title={currentStage}>{ currentStage }</span>
            <span className="status-name">{ currentStatus }</span>
          </span>
        </a>
      </section>
    );
  }
}
