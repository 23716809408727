import { connect } from 'react-redux';
import { projects } from '../../controllers/actions/projects.actions';
import { getMembers, fetchProjectMembers } from '../../controllers/actions/members.actions';
import ProjectsRoute from '../components/Layout/routes/ProjectsRoute';
import * as projectsGroups from '../../controllers/actions/projectsGroups.actions';
import * as projectGroupsManagement from '../../controllers/actions/projectsGroupsManagement.actions';
import * as modal from '../../controllers/actions/modal.actions';
import * as projectSelector from '../../controllers/actions/projectSelector.actions';
import * as settings from '../../controllers/actions/settings.actions';

export const mapStateToProps = (state) => {
  const { items, isLoading } = state.projects;
  const { collapsedGroups, page } = state.projectsGroups;
  const { favsProjects, groupsExpandedMode, groupsOrder, projectSortingType } = state.settings.data;
  const { features } = state.features;
  const { accountPlan } = state.accountSettings;
  const { accounts, idActiveAccount } = state.currentUser.data;
  let accountGroupsOrder = null;
  if (idActiveAccount) {
    // eslint-disable-next-line max-len
    accountGroupsOrder = idActiveAccount && state.settings.data[`groupsOrder_${idActiveAccount}`] ? state.settings.data[`groupsOrder_${idActiveAccount}`] : groupsOrder;
  }
  const filterType = settings.getProjectFilterType(state);

  return {
    favsProjects,
    groupsOrder: accountGroupsOrder,
    items,
    isLoading,
    collapsedGroups,
    page,
    sortType: projectSortingType,
    groupsExpandedMode,
    features,
    isTrial: accountPlan && accountPlan.is_trial,
    accounts,
    filterType,
  };
};

export const mapDispatchToProps = (dispatch) => ({
  getProjects: (groupsOrder, groupsExpandedMode) => {
    dispatch(projects.fetchProjects(groupsOrder, groupsExpandedMode));
  },

  getNextProjects: (currentPage) => {
    dispatch(projectsGroups.getNextProjects(currentPage));
  },

  closeOpenedModals: () => {
    dispatch(modal.closeOpenedModals());
  },

  clearSelectedProjects: () => {
    dispatch(projectSelector.clearSelectedProjects());
  },

  setSortType: (sortType) => {
    dispatch(projects.setSortType(sortType));
  },

  collapseProjectsGroup: (allProjects, index) => {
    dispatch(projectsGroups.collapseProjectsGroup(allProjects, index));
  },

  expandProjectsGroup: (allProjects, index) => {
    dispatch(projectsGroups.expandProjectsGroup(allProjects, index));
  },

  saveProjectsGroupsExpandedMode: (groupExpandedMode) => {
    dispatch(projectGroupsManagement.saveProjectsGroupsExpandedMode(groupExpandedMode));
  },

  getMembers: () => {
    dispatch(getMembers());
  },

  fetchProjectMembers: (idProject) => {
    dispatch(fetchProjectMembers(idProject));
  },

  refreshProjects: (groupsOrder) => {
    dispatch(projects.refreshProjects(groupsOrder));
  },

  changeFilterType: (newFilterType) => {
    dispatch(settings.changeProjectFilterType(newFilterType));
  },
});

export const Projects = connect(mapStateToProps, mapDispatchToProps)(ProjectsRoute);
