/* eslint-disable react/prop-types */

'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import autobind from 'autobind-decorator';
import config from 'react-global-configuration';
import Account from './components/Account';
import CreateAccount from './components/CreateAccount';
import routes from '../../../constants/routes.constants';
import { FEATURES, FEATURE_STATUS } from '../../../constants/features.constants';

export class MultipleAccountsPanel extends React.Component {
  constructor(props) {
    super(props);

    this.CLASS_NAMES = {
      PANEL_VISIBLE: 'multiple-accounts',
      ACTIVE_ACCOUNT: 'active',
    };
  }

  renderAccounts() {
    const { currentUserData } = this.props;
    const activeAccountId = currentUserData.idActiveAccount || 0;

    if (!currentUserData.accounts) {
      return null;
    } if (currentUserData.accounts.length > 1) {
      document.body.classList.add(this.CLASS_NAMES.PANEL_VISIBLE);
      return currentUserData.accounts.map((account) => this.renderAccountView(account, activeAccountId));
    }
  }

  renderAccountView(account, activeAccountId) {
    const className = (account.idAccount === activeAccountId) ? this.CLASS_NAMES.ACTIVE_ACCOUNT : '';

    return (
      <Account
        className={className}
        key={`account-${account.idAccount}`}
        data={account}
        switchAccount={this.switchAccount}
      />
    );
  }

  @autobind
  switchAccount(idAccount) {
    const { activeTabUri, currentUserData, switchUserAccount } = this.props;
    const activeAccountId = currentUserData.idActiveAccount || 0;

    if (idAccount !== activeAccountId) {
      switchUserAccount(idAccount, activeTabUri);
    }
  }

  componentDidUpdate() {
    const { isSwitchAccountSuccess, previousTabUri } = this.props;

    // TODO manage behaviour when 'isSwitchAccountError' is true
    if (isSwitchAccountSuccess) {
      if (previousTabUri && previousTabUri !== `/dashboard/${routes.INACTIVE_ACCOUNT}`) {
        window.location = config.get('APP_URL') + previousTabUri;
        return false;
      }

      window.location = config.get('APP_URL');
    }
  }

  // here
  renderCreateAccount() {
    const { currentUserData, features } = this.props;

    if (currentUserData.hasOwnedAccounts || features[FEATURES.CREATE_OWN_ACCOUNT] !== FEATURE_STATUS.ENABLED) {
      return null;
    }

    return <CreateAccount />;
  }

  render() {
    return (
      <aside className="accounts-panel">
        {this.renderAccounts()}
        {this.renderCreateAccount()}
      </aside>
    );
  }
}

MultipleAccountsPanel.propTypes = {
  // eslint-disable-next-line react/require-default-props
  currentUserData: PropTypes.object.isRequired,
  features: PropTypes.object.isRequired,
  isSwitchAccountSuccess: PropTypes.bool,
  previousTabUri: PropTypes.string,
};

MultipleAccountsPanel.defaultProps = {
  // eslint-disable-next-line react/default-props-match-prop-types
  currentUserData: {},
  isSwitchAccountSuccess: false,
  previousTabUri: '',
};
