'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import { noop, includes } from 'lodash';
import { goTo } from '../../../utils/router';
import { accountPlanTypes } from '../../../constants/accountSettings.constants';
import { limitsExceededTypes, DESIGN_SYSTEMS_LIMIT_TYPE } from '../../../constants/modal.constants';

export default class ModalboxLimitsHelperComponent extends React.Component {
  componentWillMount() {
    this.manageOpening();
  }

  manageOpening() {
    const { params: { planType, limit }, openLimitsModal, openDesignSystemsLimitsModal } = this.props;
    const planTypeName = planType.toUpperCase();

    if (includes(accountPlanTypes, planTypeName)) {
      if (limit === DESIGN_SYSTEMS_LIMIT_TYPE.toLowerCase()) {
        openDesignSystemsLimitsModal();
      } else if (includes(limitsExceededTypes, limit)) {
        openLimitsModal(planTypeName, limit);
      }
    } else {
      goTo('not-found');
    }
  }

  render() {
    return null;
  }
}

ModalboxLimitsHelperComponent.propTypes = {
  params: PropTypes.object,
  openLimitsModal: PropTypes.func,
  openDesignSystemsLimitsModal: PropTypes.func,
};

ModalboxLimitsHelperComponent.defaultProps = {
  params: {},
  openLimitsModal: noop,
  openDesignSystemsLimitsModal: noop,
};
