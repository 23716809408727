import { membersActions } from '../../constants/members.constants';
import { request } from '../../utils/request';

export const getMembers = () => (
  (dispatch) => {
    request.get('/members/')
      .then((data) => {
        dispatch({
          type: membersActions.FETCH_ACCOUNT_MEMBERS,
          data,
        });
      })
      .catch(() => {
        // TODO HANDLE ERROR
      });
  }
);

export const fetchProjectMembers = (idProject) => (
  (dispatch) => {
    dispatch({
      type: membersActions.REQUEST_FETCH_PROJECT_MEMBERS,
    });

    Promise.all([
      request.get('/members/'),
      request.get(`/project/${idProject}/members`),
    ])
      .then((data) => {
        dispatch({
          type: membersActions.FETCH_ACCOUNT_MEMBERS,
          data: data[0],
        });

        dispatch({
          type: membersActions.FETCH_PROJECT_MEMBERS,
          data: data[1],
        });
      })
      .catch(() => {
        // TODO HANDLE ERROR
      });
  }
);
