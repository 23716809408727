/* eslint-disable max-len */

import { connect } from 'react-redux';
import _ from 'lodash';
import ModalboxProjectChangeGroupComponent from '../components/Modalboxes/ModalboxProjectChangeGroup';
import { modalsIds } from '../../constants/modal.constants';
import * as modal from '../../controllers/actions/modal.actions';

export const mapStateToProps = (state) => {
  const {
    changeGroupAlertActions, isUserToAdd, hasProjectGroup, newProjectGroupName, isCheckingPossibilityOfChangingGroup, isChangeGroupAlertVisible, isLoading, items,
  } = state.projects;
  const { openedModalsIds } = state.modal;

  return {
    isCheckingPossibilityOfChangingGroup,
    isChangeGroupAlertVisible,
    changeGroupAlertActions,
    newProjectGroupName,
    isUserToAdd,
    hasProjectGroup,
    isLoading,
    items,
    isModalboxGroupsSettingsOpen: openedModalsIds[modalsIds.MODALBOX_GROUPS_SETTINGS] || false,
  };
};

export const mapDispatchToProps = (dispatch) => ({
  openModalboxGeneralMessage(title, message, onAccept, onClose) {
    const onCloseCallback = () => {
      dispatch(modal.closeModal(modalsIds.MODALBOX_GENERAL_MESSAGE));
      if (_.isFunction(onClose)) {
        onClose();
      }
    };

    const onAcceptCallback = () => {
      dispatch(modal.closeModal(modalsIds.MODALBOX_GENERAL_MESSAGE));
      if (_.isFunction(onAccept)) {
        onAccept();
      }
    };

    dispatch(modal.openModal(modalsIds.MODALBOX_GENERAL_MESSAGE, {
      title,
      message,
      onAccept: onAcceptCallback,
      onClose: onCloseCallback,
    }));
  },

  openModalboxGroupsSettings() {
    dispatch(modal.openModal(modalsIds.MODALBOX_GROUPS_SETTINGS));
  },
});

export const ModalboxProjectChangeGroup = connect(mapStateToProps, mapDispatchToProps)(ModalboxProjectChangeGroupComponent);
