import moment from 'moment';
import { save, load } from './localStorage';

/**
 * TEMPORARY SOLUTION FOR PREVENTING GENERATING REDUNDANT COVERS FOR THE SAME DOCUMENT
 * It should be done on backend side but it requires much more work. At this moment we have different priorities.
 * In this solution Im using local storage to keep information about last cover updating.
 * With this solution user can add tasks to queue once every 8 hours.
 * Without this user adds tasks to queue every time when he opens page with list of projects
 */

const PREFIX = 'last-update-';

const getKey = (documentId) => `${PREFIX}${documentId}`;

export const saveLastUpdate = (documentId) => {
  save(getKey(documentId), Date.now());
};

export const shouldForceGenerateCover = (documentId) => {
  const HOURS_8 = 1000 * 60 * 60 * 8;
  let lastUpdate;
  try {
    lastUpdate = load(getKey(documentId));
  } catch(e) {
    lastUpdate = null;
  }

  const shouldForceGenerateCover =  !lastUpdate || moment().diff(moment(lastUpdate)) > HOURS_8;

  if (shouldForceGenerateCover) {
    saveLastUpdate(documentId);
    return true;
  }

  return false;
};
