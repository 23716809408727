import { connect } from 'react-redux';
import MergeLibraryListComponent from '../components/Merge/MergeLibraryList';
import { openModal } from '../../controllers/actions/modal.actions';
import { modalsIds } from '../../constants/modal.constants';

export const mapStateToProps = (state) => {
  const { data: currentUserData } = state.currentUser;
  const { libraries } = state.designSystems;
  const { hiddenLibraries } = state.settings.data.designLibraries;

  return {
    currentUserData,
    libraries,
    hiddenLibraries: hiddenLibraries || [],
    isMergeEnabled: state.features.features.merge === 'enabled',
    isStorybookEnabled: state.features.features.storybook_libraries === 'enabled',
  };
};

export const mapDispatchToProps = (dispatch) => ({
  openModalboxCreateMergeLibraryItem: () => {
    dispatch(openModal(modalsIds.MODALBOX_ADD_MERGE_LIBRARY_BY_GIT_AND_NPM));
  },
});

export const MergeLibraryList = connect(mapStateToProps, mapDispatchToProps)(MergeLibraryListComponent);
