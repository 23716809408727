/* eslint-disable no-param-reassign */

'use strict';

export const countPercent = (dividend, divider) => {
  dividend = parseInt(dividend, 10);
  divider = parseInt(divider, 10);

  if (!dividend || !divider) {
    return 0;
  }

  return parseInt(dividend / divider * 100, 10);
};
