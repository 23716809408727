import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { noop } from 'lodash';
import { LibraryPermissions } from '@uxpin/shared-components';

import { modalsIds } from '../../constants/modal.constants';

import {
  actionAddLibrary,
  actionChangeEditedLibrary,
  actionClearEditedLibrary,
  actionShowDesignLibrariesSettingsModal,
  actionDeleteLibrary,
  actionUpdateLibrary,
  actionAddNpmComponents,
  actionAddCategory,
  actionHideDesignLibrariesAddComponentModal,
} from '../../controllers/actions/designSystems.actions';
import { settingsName } from '../../constants/settings.constants';
import { onMergeRequestSubmit } from './MergeLibrarySetting';

const getCategories = (editedLibrary) => {
  if (!editedLibrary || !editedLibrary.components) {
    return [];
  }

  return Object.keys(editedLibrary.components).map((categoryId) => (
    {
      id: categoryId,
      name: editedLibrary.components[categoryId].name,
    }
  ));
};

const ModalboxAddComponent = connect(
  (state) => ({
    accessScope: state.libraryPermissions.accessScope,
    currentUserId: state.currentUser.data.id,
    currentUserEmail: state.currentUser.data.email,
    editScope: state.libraryPermissions.editScope,
    error: state.libraryPermissions.errorLibrary,
    idActiveAccount: state.currentUser.data.idActiveAccount,
    isLibraryDeleting: state.libraryPermissions.isLibraryDeleting,
    isLibraryProcessing: state.libraryPermissions.isLibraryProcessing,
    isMergeRequestSubmitted: !!state.settings.data[settingsName.MERGE_FROM_SUBMITTED],
    membersData: state.members.membersData,
    editedLibrary: state.designSystems.editedLibrary,
    features: state.features.features,
    isOpen: state.modal.openedModalsIds[modalsIds.MODALBOX_ADD_COMPONENT] || false,
    className: 'design-library-modalbox',
    isNpmAddNewComponentOnly: true,
    idLibrary: (state.designSystems.editedLibrary || {}).id,
    categories: getCategories(state.designSystems.editedLibrary),
    isSkippable: true,
  }),

  (dispatch) => ({
    actions: bindActionCreators({
      addLibrary: actionAddLibrary,
      changeEditedLibrary: actionChangeEditedLibrary,
      clearEditedLibrary: actionClearEditedLibrary,
      clearLibraryError: LibraryPermissions.actions.clearLibraryError,
      hideDesignLibrariesAddComponentModal: actionHideDesignLibrariesAddComponentModal,
      requestSetEditPermissions: LibraryPermissions.actions.requestSetEditPermissions,
      requestSetViewPermissions: LibraryPermissions.actions.requestSetViewPermissions,
      showDesignLibrariesSettingsModal: actionShowDesignLibrariesSettingsModal,
      startDeletingLibrary: actionDeleteLibrary,
      updateLibrary: actionUpdateLibrary,
      addNpmComponents: actionAddNpmComponents,
      addCategory: actionAddCategory,
      selectCategory: () => noop,
      deselectCategory: () => noop,
      onMergeRequestSubmit,
    }, dispatch),
  })
)(LibraryPermissions.PanelModalbox);

export default ModalboxAddComponent;
