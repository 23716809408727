/* eslint-disable no-plusplus */

import _ from 'lodash';
import { projectsConstants, projectsStatuses } from '../../constants/projects.constants';
import { groupTypes } from '../../constants/projectsGroupsManagement.constants';
import { compareGroupPosition } from '../../utils/projectsSorting';

export const getProjectCountToExpandedGroup = (groups, groupsExpandedMode) => {
  let projectCount = 0;
  let expandedGroupProjectCount = 0;

  for (let i = 0; i < groups.length; i++) {
    if (groupsExpandedMode[groups[i].type || groups[i].id]) {
      expandedGroupProjectCount += groups[i].projects.length;
    }

    // eslint-disable-next-line max-len
    if (groupsExpandedMode[groups[i].type || groups[i].id] && expandedGroupProjectCount > projectsConstants.PROJECTS_ON_PAGE) {
      return projectCount;
    }

    projectCount += groups[i].projects.length;
  }

  return projectCount;
};

export const updateGroupsOrder = (groups, groupsOrder) => {
  groups.forEach((group, index) => {
    if (!_.isUndefined(groupsOrder[group.id])) {
      Object.assign(group, { position: groupsOrder[group.id] });
      return;
    }

    if (!_.isUndefined(groupsOrder[group.type])) {
      Object.assign(group, { position: groupsOrder[group.type] });
      return;
    }

    if (!_.isUndefined(group.position)) {
      Object.assign(group, { position: index });
    }
  });

  return groups;
};

// eslint-disable-next-line complexity
export const transformProjectsList = (data, groupsOrder) => {
  const archivedGroup = { name: 'Archived projects', type: groupTypes.ARCHIVED, projects: [] };
  const ungroupedProjects = { name: 'Ungrouped projects', type: groupTypes.UNGROUPED, projects: [] };
  const groups = [];
  const mappedIndex = {};

  Object.keys(data.groups).forEach((key) => {
    if ({}.hasOwnProperty.call(data.groups, key)) {
      const group = data.groups[key];
      group.projects = [];
      groups.push(group);
      mappedIndex[key] = groups.length - 1;
    }
  });

  for (let i = 0; i < data.projects.length; i++) {
    const project = data.projects[i];
    if (project.user && project.user.conf) {
      delete project.user.conf;
    }

    delete project.settings;

    switch (project.status) {
      case projectsStatuses.ACTIVE:
      case projectsStatuses.DUPLICATED:
        if (project.idProjectGroup && groups[mappedIndex[project.idProjectGroup]]) {
          groups[mappedIndex[project.idProjectGroup]].projects.push(project);
        } else if (!project.idProjectGroup) {
          ungroupedProjects.projects.push(project);
        }

        break;
      case projectsStatuses.ARCHIVED:
      case projectsStatuses.ARCHIVED_DUPLICATED:
        archivedGroup.projects.push(project);
        break;

      // no default
    }
  }

  groups.push(ungroupedProjects);
  groups.push(archivedGroup);
  updateGroupsOrder(groups, groupsOrder);
  groups.sort(compareGroupPosition);

  return groups;
};
