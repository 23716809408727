'use strict';

export const extendTrialTypes = {
  DEFAULT: 'default',
};

export const extendTrialFieldNames = {
  ROLE: 'role',
  TEAM_SIZE: 'teamSize',
  PROBLEM: 'problem',
  COMPANY_NAME: 'companyName',
};

export const partialExtendTrial = {
  ID: 'partialExtendTrialBlock',
  DELAY: 3600 * 12,
};

export const delayedExtendTrialModal = {
  ID: 'extendTrialFormModal',
  DELAY: 3600 * 12,
};

export const extendTrialFormAllowedFields = [
  extendTrialFieldNames.ROLE,
  extendTrialFieldNames.TEAM_SIZE,
  extendTrialFieldNames.PROBLEM,
  extendTrialFieldNames.COMPANY_NAME,
];

export const extendTrialSettings = {
  FORCED_EXTEND_TRIAL_FORM_SEEN: 'forcedExtendTrialFormSeen',
  PARTIAL_EXTEND_TRIAL_FORM_SEEN: 'partialExtendTrialFormSeen', // serves only as a prefix for now
  PARTIAL_EXTEND_TRIAL_FORM_SEEN_SECOND_VISIT: `partialExtendTrialFormSeen_${extendTrialTypes.SECOND_VISIT}`,
  PARTIAL_EXTEND_TRIAL_FORM_SEEN_THIRD_VISIT: `partialExtendTrialFormSeen_${extendTrialTypes.THIRD_VISIT}`,
  PARTIAL_EXTEND_TRIAL_FORM_SEEN_FOURTH_VISIT: `partialExtendTrialFormSeen_${extendTrialTypes.FOURTH_VISIT}`,
};

export const extendTrialFormFieldsCount = extendTrialFormAllowedFields.length;

export const showExtendTrialLinkDelay = 3; // days
