/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-one-expression-per-line */

'use strict';

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { PlanInfo } from '../../../containers/PlanInfo';
import { getImagePath } from '../../../../utils/url';
import { BASIC_FILE_TYPES } from '../../../../utils/uploadFiles';

export default class DraggingArea extends Component {
  getClassNames() {
    const { isDocumentUploadEnabled } = this.props;

    return classnames('option-tile', { disabled: !isDocumentUploadEnabled });
  }

  getSketchCopy() {
    const { isSketchUploadAllowed } = this.props;
    return isSketchUploadAllowed ? 'Sketch,' : '';
  }

  renderAdditionalInfo() {
    const { isDocumentUploadEnabled } = this.props;
    if (isDocumentUploadEnabled) {
      return <figcaption className="supported-files">{this.getSketchCopy()} {BASIC_FILE_TYPES}</figcaption>;
    }

    return (
      <PlanInfo>
        Importing files is available in higher plans.
        <br />
      </PlanInfo>
    );
  }

  renderCopy() {
    const { dropCopy } = this.props;

    if (dropCopy) {
      return dropCopy;
    }

    return (
      <Fragment>
        Import {this.getSketchCopy()} images
        <br />
        or documents
      </Fragment>
    );
  }

  render() {
    const { htmlTag: TagName, openBrowserFilePicker, isSketchUploadAllowed } = this.props;
    const suffix = isSketchUploadAllowed ? '' : '-no-sketch';
    const image = `use-your-design-files${suffix}.svg`;

    return (
      <TagName className="start-option upload-files drag-and-drop-area ">
        <div onClick={openBrowserFilePicker} className={this.getClassNames()}>
          <img
            src={getImagePath(image)}
            className="tile-image"
            alt="Use your design files"
            width="62"
            height="46"
          />
          {this.renderCopy()}
        </div>

        {this.renderAdditionalInfo()}
      </TagName>
    );
  }
}

DraggingArea.propTypes = {
  htmlTag: PropTypes.string,
  isSketchUploadAllowed: PropTypes.bool,
};

DraggingArea.defaultProps = {
  htmlTag: 'li',
  isSketchUploadAllowed: false,
};
