import _ from 'lodash';

export const chromeExtension = {
  ports: {},
  postMessage(msg) {
    _.each(this.ports, (port) => {
      port.postMessage(msg);
    });
  },

  onMessage(cb) {
    if (!_.isFunction(cb)) { return false; }

    _.each(this.ports, (port) => {
      port.onMessage.addListener(cb);
    });
  },
};

export const ids = {
  dev: 'mmeijimgabbpbgpdklnllpncmdofkcpn',
  crx: 'abakbpjdipaljidfhjljmfpoddgboeem',
  prod: 'bgibmbhbcdfckbcbdkihlclfildbaehc',
};

export const disconnect = (env) => {
  delete chromeExtension.ports[env];
};

if (window.chrome && window.chrome.runtime) {
  _.each(ids, (value, env) => {
    const port = window.chrome.runtime.connect(value);
    port.onDisconnect.addListener(disconnect.bind(chromeExtension, env));
    chromeExtension.ports[env] = port;
  });

  if (!window.chromeExtension) {
    window.chromeExtension = chromeExtension;
  }
}
