/* eslint-disable react/button-has-type */

import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Tabs } from '@uxpin/shared-components';
import autobind from 'autobind-decorator';
import _ from 'lodash';
import TabProfile from './components/TabProfile';
import TabSecurity from './components/TabSecurity';
import TabGeneral from './components/TabGeneral';
import TabNotifications from './components/TabNotifications';
import TabProjectStatus from './components/TabProjectStatus';
import TabIntegrations from './components/TabIntegrations';

export default class UserSettings extends Tabs {
  constructor(props) {
    super(props);
    this.MODAL = {
      WRAPPER_ID: 'user-settings-modal-box',
      CLASS_NAMES: ['tabbed', 'full-screen'],
      TITLE: 'Settings',
    };

    this.TABS = {
      PROFILE: 'PROFILE',
      SECURITY: 'SECURITY',
      NOTIFICATIONS: 'NOTIFICATIONS',
      GENERAL: 'GENERAL',
      STATUS: 'STATUS',
      APPROVAL: 'APPROVAL',
      INTEGRATIONS: 'INTEGRATIONS',
    };

    this.state = {
      activeTab: this.TABS.PROFILE,
    };
  }

  renderDescription() {
    return (<p>Personalize your UXPin experience.</p>);
  }

  @autobind
  onClose(e) {
    e.preventDefault();
    this.props.onClose();
  }

  shouldComponentUpdate(nextProps, nextState) {
    return Object.keys(this.props).some((propName) => !_.isEqual(this.props[propName], nextProps[propName]))
      || Object.keys(this.state).some((stateName) => !_.isEqual(this.state[stateName], nextState[stateName]));
  }

  render() {
    return (
      <Modal
        wrapperId={this.MODAL.WRAPPER_ID}
        classNames={this.MODAL.CLASS_NAMES}
        title={this.MODAL.TITLE}
        renderDescription={this.renderDescription}
        isOpen={this.props.isOpen}
        onClose={this.props.onClose}>
        <section ref="userSettings" className="modal-box-content">
          <nav className="nav-tabbed">
            <ul className="tabs-wrapper">
              <li className={this.tabClass(this.TABS.PROFILE, 'tab')}>
                <a
                  href="#your-profile"
                  className="tab-link"
                  onClick={this.changeTab.bind(this, this.TABS.PROFILE)}>
                  Your profile
                </a>
              </li>
              <li className={this.tabClass(this.TABS.SECURITY, 'tab')}>
                <a
                  href="#security"
                  className="tab-link"
                  onClick={this.changeTab.bind(this, this.TABS.SECURITY)}>
                  Security
                </a>
              </li>
              <li className={this.tabClass(this.TABS.NOTIFICATIONS, 'tab')}>
                <a
                  href="#email-notifications"
                  className="tab-link"
                  onClick={this.changeTab.bind(this, this.TABS.NOTIFICATIONS)}>
                  E-mail notifications
                </a>
              </li>
              <li className={this.tabClass(this.TABS.GENERAL, 'tab')}>
                <a
                  href="#general-billing"
                  className="tab-link"
                  onClick={this.changeTab.bind(this, this.TABS.GENERAL)}>
                  General &amp; Billing
                </a>
              </li>
              <li className={this.tabClass(this.TABS.STATUS, 'tab')}>
                <a
                  href="#project-status"
                  className="tab-link"
                  onClick={this.changeTab.bind(this, this.TABS.STATUS)}>
                  Project status
                </a>
              </li>
              <li className={this.tabClass(this.TABS.INTEGRATIONS, 'tab')}>
                <a
                  href="#integrations"
                  className="tab-link"
                  onClick={this.changeTab.bind(this, this.TABS.INTEGRATIONS)}>
                  Integrations
                </a>
              </li>
            </ul>
          </nav>
          <article>
            <TabProfile classNames={this.tabClass(this.TABS.PROFILE, 'tab-content')} />
            <TabSecurity classNames={this.tabClass(this.TABS.SECURITY, 'tab-content')} />
            <TabNotifications classNames={this.tabClass(this.TABS.NOTIFICATIONS, 'tab-content')} />
            <TabGeneral classNames={this.tabClass(this.TABS.GENERAL, 'tab-content')} />
            <TabProjectStatus classNames={this.tabClass(this.TABS.STATUS, 'tab-content')} />
            <TabIntegrations classNames={this.tabClass(this.TABS.INTEGRATIONS, 'tab-content')} />
          </article>
        </section>
        <footer>
          <ul className="btns-group">
            <li>
              <button className="btn-solid blue btn-wide">Save</button>
            </li>
            <li>
              <a href="#cancel" className="gray-style cancel-button action-close" onClick={this.onClose}>Cancel</a>
            </li>
          </ul>
        </footer>
      </Modal>
    );
  }
}

UserSettings.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
