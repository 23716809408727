import { connect } from 'react-redux';
import ModalboxExtendTrialFormComponent from '../components/Modalboxes/ModalboxExtendTrialForm';
import { saveLeadForm } from '../../controllers/actions/modal.actions';
import { saveSettings } from '../../controllers/actions/settings.actions';
import { extendTrialSettings } from '../../constants/extendTrial.constants';

export const mapStateToProps = (state) => ({
  settings: state.settings,
  features: state.features.features,
});

export const mapDispatchToProps = (dispatch) => ({
  saveLeadForm: (formData) => {
    dispatch(saveLeadForm(formData));
  },
  setForcedExtendTrialFormAsSeen: () => {
    dispatch(saveSettings(extendTrialSettings.FORCED_EXTEND_TRIAL_FORM_SEEN, 'true'));
  },
  setPartialExtendTrialFromAsSeen: (type) => {
    dispatch(saveSettings(`${extendTrialSettings.PARTIAL_EXTEND_TRIAL_FORM_SEEN}_${type}`, 'true'));
  },
});

export const ModalboxExtendTrialForm = connect(mapStateToProps, mapDispatchToProps)(ModalboxExtendTrialFormComponent);
