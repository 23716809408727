'use strict';

import config from 'react-global-configuration';
import { isDesktopApp } from '@uxpin/shared-components';

const rollbarConfig = {
  accessToken: config.get('ROLLBAR_CLIENT_ACCESS_TOKEN'),
  captureUncaught: true,
  ignoredMessages: [
    'Script error',
    'Script error.',
  ],
  captureUnhandledRejections: false,
  payload: {
    environment: config.get('ROLLBAR_ENV'),
    host: config.get('ROLLBAR_ENDPOINT'),
  },
};

export default rollbarConfig;
