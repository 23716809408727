'use strict';

export const upgradeModalActions = {
  OPEN_UPGRADE_MODAL: 'OPEN_UPGRADE_MODAL',
  CLOSE_UPGRADE_MODAL: 'CLOSE_UPGRADE_MODAL',
};

export const upgradeModalSpecModePromoActions = {
  OPEN_SPECMODE_PROMO_UPGRADE_MODAL: 'OPEN_SPECMODE_PROMO_UPGRADE_MODAL',
  CLOSE_SPECMODE_PROMO_UPGRADE_MODAL: 'CLOSE_SPECMODE_PROMO_UPGRADE_MODAL',
};
