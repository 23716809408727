import { connect } from 'react-redux';
import { Changelog } from '@uxpin/shared-components';

import { modalsIds } from '../../constants/modal.constants';
import { fetchSettings, updateSettings, saveSettings } from '../../controllers/actions/settings.actions';
import * as modal from '../../controllers/actions/modal.actions';

export const mapStateToProps = (state) => {
  const { data } = state.settings;

  return {
    settings: data,
  };
};

export const mapDispatchToProps = (dispatch) => ({
  onClose: () => dispatch(modal.closeModal(modalsIds.MODALBOX_CHANGELOG)),
  fetchSettings: () => dispatch(fetchSettings),
  updateSettings: (settingName, value) => dispatch(updateSettings(settingName, value)),
  saveSettings: (settingName, value) => dispatch(saveSettings(settingName, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Changelog.ModalboxChangelog);
