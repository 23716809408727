import { connect } from 'react-redux';
import MergeCreateLibraryComponent from '../components/Merge/MergeCreateLibrary';
import * as modal from '../../controllers/actions/modal.actions';
import { modalsIds } from '../../constants/modal.constants';

export const mapDispatchToProps = (dispatch) => ({
  openModalboxAddMergeLibrary: () => {
    dispatch(modal.openModal(modalsIds.MODALBOX_ADD_MERGE_LIBRARY_BY_GIT_AND_NPM));
  },
});

export const MergeCreateLibrary = connect(null, mapDispatchToProps)(MergeCreateLibraryComponent);
