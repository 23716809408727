'use strict';

import React from 'react';
import _ from 'lodash';
import { SearchBtn } from '../../../containers/SearchBtn';
import { NewProjectBtn } from '../../../containers/NewProjectBtn';

export class QuickActions extends React.Component {
  shouldComponentUpdate(nextProps) {
    // eslint-disable-next-line react/destructuring-assignment
    return Object.keys(this.props).some((propName) => !_.isEqual(this.props[propName], nextProps[propName]));
  }

  render() {
    return (
      <ul className="options-group">
        <NewProjectBtn
          shouldAddDesktopAppListener
        />
        <li>
          <SearchBtn />
        </li>
      </ul>
    );
  }
}
