/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */

'use strict';

import React from 'react';
import autobind from 'autobind-decorator';
import _ from 'lodash';
import { StringUtils } from '@uxpin/shared-components';
// eslint-disable-next-line import/no-named-as-default
import ColorPicker from '../../ColorPicker/ColorPicker';
import { projectsConstants } from '../../../../constants/projects.constants';
import keys from '../../../../constants/keys.constants';

export default class GroupNew extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      color: projectsConstants.DEFAULT_COLOR,
    };
  }

  componentWillMount() {
    const { data: { color }, occupiedColors } = this.props;
    this.setColor(color || this.getAvailableColor(occupiedColors));
  }

  getAvailableColor(occupiedColors) {
    if (_.uniq(occupiedColors).length === projectsConstants.GROUPS_COLORS.length) {
      const colors = _.difference(projectsConstants.GROUPS_COLORS, [_.last(occupiedColors)]);
      // eslint-disable-next-line radix
      return colors.length ? colors[parseInt(_.uniqueId()) % colors.length] : projectsConstants.DEFAULT_COLOR;
    }

    return _.first(_.difference(projectsConstants.GROUPS_COLORS, occupiedColors));
  }

  @autobind
  saveGroup(e) {
    const { saveGroup, index, data } = this.props;
    const value = this.refs.input.value;
    const valueTrimmed = StringUtils.reduceWhiteSpaces(value).trim();
    if (e.keyCode === keys.ENTER && valueTrimmed.length > 0) {
      saveGroup(valueTrimmed, this.state.color, data.isBeingUpdated ? index : null);
    }
  }

  @autobind
  onBlur() {
    const { saveGroup, index, data } = this.props;
    const value = this.refs.input.value;
    const valueTrimmed = StringUtils.reduceWhiteSpaces(value).trim();
    if ((data.isBeingAdded || data.isBeingUpdated) && valueTrimmed.length) {
      saveGroup(valueTrimmed, this.state.color, data.isBeingUpdated ? index : null);
    } else if (data.isBeingUpdated) {
      this.refs.input.focus();
    }
  }

  @autobind
  setColor(color) {
    this.setState({
      color,
    });
  }

  render() {
    const { parent, data } = this.props;
    const groupsColors = projectsConstants.GROUPS_COLORS;

    return (
      <section className={`item-wrapper item-new ${this.props.className}`}>
        <article className="item-data">
          <input
            className="new-item-input focus-helper"
            ref="input"
            type="text"
            onBlur={this.onBlur}
            onKeyDown={this.saveGroup}
            defaultValue={data.name}
            placeholder="Enter group name"
          />
          <label>Enter to save</label>
          <ColorPicker setColor={this.setColor} currentColor={this.state.color} colors={groupsColors} parent={parent} />
        </article>
      </section>
    );
  }
}
