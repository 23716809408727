'use strict';

import moment from 'moment';

const ONE_HOUR = 1000 * 3600;

export const marketingActions = {
  OPEN_ADD_ON_SPEC_MODE_UPGRADE_MODAL: 'OPEN_ADD_ON_SPEC_MODE_UPGRADE_MODAL',
  CLOSE_ADD_ON_SPEC_MODE_UPGRADE_MODAL: 'CLOSE_ADD_ON_SPEC_MODE_UPGRADE_MODAL',
  FETCH_ADD_ON_DATA: 'FETCH_ADD_ON_DATA',
  APPLY_ADD_ON: 'APPLY_ADD_ON',
  CLOSE_NAMES_MODAL: 'CLOSE_NAMES_MODAL',
};

export const marketingEvents = {
  SIGNED_UP: 'SIGNED_UP',
  FIRST_IDENTIFICATION: 'FIRST_IDENTIFICATION',
  ADWORDS_TRIAL_CONVERSION: 'ADWORDS_TRIAL_CONVERSION',
};

export const promoDate = {
  PROTOTYPING_UPSELL_END: moment('2017-09-01T06:59:00.000Z'), // 2017-08-31 23:59 Los Angeles time
  ADD_ON_SPEC_MODE_END: moment('2018-06-22T06:59:00.000Z'), // 2018-06-21 23:59 Los Angeles time
  ADD_ON_CUSTOM_FONTS_END: moment('2018-06-22T06:59:00.000Z'), // 2018-06-21 23:59 Los Angeles time
  CYBERWEEK_CTA_END: moment('2017-12-01T00:00:00-08:00'),
  DS_TWO_SEATS_CTA_END: moment('2017-12-17T23:59:59-08:00'),
  ADD_ON_FREE_FLOW_DOCS_END: moment('2018-02-01T07:59:00.000Z'), // 2018-01-31 23:59 Los Angeles time
};

export const timeIntervals = {
  COUNTER_FORMAT_SWITCH: ONE_HOUR * 24 * 3, // 3 days
  TOP_BAR_SECOND_VERSION: ONE_HOUR * 24 * 2, // 2 days
  PHONE_PERSONA_ASK_LATER: ONE_HOUR * 24 * 3, // 3 days
};

export const validationRules = {
  PHONE_REGEX: /^[0-9+\-(). ]+$/g,
  PHONE_MIN_LENGTH: 8,
};

export const actionDelayType = {
  MODAL: 'mod',
  PROMO: 'promo',
};

export const couponCodes = {
  SYSTEMS_ANNUAL: 'system_annual_promo',
};

export const promotions = {
  JULY_DS_TRIAL: 'july_ds_trial',
  ADD_ON_SPEC_MODE_PROMO: 'ADD_ON_SPEC_MODE_PROMO',
  ADD_ON_CUSTOM_FONTS_PROMO: 'ADD_ON_CUSTOM_FONTS_PROMO',
  ADD_ON_FREE_FLOW_DOCS_PROMO: 'ADD_ON_FREE_FLOW_DOCS_PROMO',
  PROTOTYPING_UPSELL: 'prototyping_upsell',
  PRICING_FEB_2018_TEST: 'pricing_feb_2018_test',
  ONBOARDING_TEAM_SIZE0418_TEST: 'onboarding_team_size0418_test',
  ONBOARDING_BUTTON_ACTIVE: 'onboarding_button_active',
  GOOGLE_SIGN_IN_CONTROL: 'google_sign_in_control',
  GOOGLE_SIGN_IN_TEST: 'google_sign_in_test',
  ONBOARDING_STEPS_Q4: 'onboarding_steps_q4',
  ONBOARDING_EDU_PATH: 'onboarding_edu_path',
  SIGNED_UP_FROM_URL: 'signed_up_from_url',
};

export const addOnPromoTopBarVersions = {
  NONE: 0,
  FIRST_MESSAGE: 1,
  SECOND_MESSAGE: 2,
};

export const prototypingUpsellVersions = {
  NONE: 0,
  FIRST_MESSAGE: 1,
  SECOND_MESSAGE: 2,
};

export const addOns = {
  SPEC_MODE: 'SPEC_MODE',
  SPEC_MODE_PROTO: 'SPEC_MODE_PROTO',
  CUSTOM_FONTS: 'CUSTOM_FONTS',
};

export const addOnCodes = {
  SPEC_MODE: 'spec_mode',
  CUSTOM_FONTS: 'custom_fonts',
  FREE_FLOW_DOCS: 'free_flow_docs',
  SPEC_MODE_PROTO: 'specmode',
};

export const buyOneGetOneEnd = 'August 24th';

export const buyOneGetOneNewTrialEnd = 'August 31st';

export const adwordsTrackingStartDate = '2018-04-27';

export const gdprDate = 'May 24, 2018 15:00:00 GMT+02:00';

export const topbarPromoName = {
  BUY_ONE_GET_ONE: 'BUY_ONE_GET_ONE',
  BATCH_PLANS_WINBACK: 'BATCH_PLANS_WINBACK',
};

export const batchPlansWinbackEnd = 'September 28th';

export const onboardingFlowStartDate = '2019-08-26';

export const gaAccountStatuses = {
  TRIAL: 'trial',
  SUSPENDED: 'suspended',
  CUSTOMER: 'customer',
  FREE: 'free',
};

export const upsellCampaign2019 = {
  TEAM_UPSELL_MODAL_SEEN: 'TEAM_UPSELL_MODAL_SEEN',
  PRO_UPSELL_MODAL_SEEN: 'PRO_UPSELL_MODAL_SEEN',
};

export const loginEventCookieName = 'loginEventCookie';
export const registerEventCookieName = 'registerEventCookie';
export const gtmDataCookieName = 'gtminit';
export const missingAccountType = 'N/A';

export const modalPersonasOptions = [
  {
    label: 'I work for a company',
    value: 'business',
  }, {
    label: 'I`m a freelancer',
    value: 'freelancer',
  }, {
    label: 'I`m a student/EDU',
    value: 'student',
  }, {
    label: 'Other',
    value: 'other',
  },
];

export const SIGNUP_AUTO_REDIRECT_COOKIE_NAME = 'autoOpenPrototype';
