'use strict';

export const insideProjectActions = {
  REQUEST_FETCHING_PROJECT_DATA: 'REQUEST_FETCHING_PROJECT_DATA',
  FETCH_PROJECT_DATA: 'FETCH_PROJECT_DATA',
  FETCH_PROJECTS_LIST: 'FETCH_PROJECTS_LIST',
  FETCH_DOCUMENTS: 'FETCH_DOCUMENTS',
  SET_DOCUMENTS_COMMENTS_COUNT: 'SET_DOCUMENTS_COMMENTS_COUNT',
  STOP_LOADER: 'STOP_LOADER',
  UPDATE_PROGRESS: 'UPDATE_PROGRESS',
  UPDATE_DOCUMENT_STATUS: 'UPDATE_DOCUMENT_STATUS',
  UPDATE_DOCUMENT_COVER: 'UPDATE_DOCUMENT_COVER',
  REQUEST_CREATING_DOCUMENT: 'REQUEST_CREATING_DOCUMENT',
  CREATE_DOCUMENT: 'CREATE_DOCUMENT',
  UPDATE_DOCUMENT_NAME: 'UPDATE_DOCUMENT_NAME',
  REMOVE_DOCUMENT: 'REMOVE_DOCUMENT',
  UPDATE_DUPLICATED_DOCUMENT_STATUS: 'UPDATE_DUPLICATED_DOCUMENT_STATUS',
  SET_DOCUMENT_APPROVAL: 'SET_DOCUMENT_APPROVAL',
  DUPLICATE_DOCUMENT: 'DUPLICATE_DOCUMENT',
  UPDATE_DUPLICATED_DOCUMENT: 'UPDATE_DUPLICATED_DOCUMENT',
  CREATE_DOCUMENT_BY_UPLOAD_FILE: 'CREATE_DOCUMENT_BY_UPLOAD_FILE',
  CREATE_DOCUMENT_BY_CHROME_EXTENSION: 'CREATE_DOCUMENT_BY_CHROME_EXTENSION',
  START_CHROME_EXTENSION_UPLOAD: 'START_CHROME_EXTENSION_UPLOAD',
  UPDATE_DOCUMENT_AFTER_CHROME_EXTENSION_UPLOAD: 'UPDATE_DOCUMENT_AFTER_CHROME_EXTENSION_UPLOAD',
  UPDATE_UPLOAD_DOCUMENT_PROGRESS: 'UPDATE_UPLOAD_DOCUMENT_PROGRESS',
  UPDATE_UPLOAD_DOCUMENT_DATA: 'UPDATE_UPLOAD_DOCUMENT_DATA',
  REMOVE_UPLOAD_DOCUMENT: 'REMOVE_UPLOAD_DOCUMENT',
  UPDATE_DOCUMENT_CONVERSION_STATUS: 'UPDATE_DOCUMENT_CONVERSION_STATUS',
  REQUEST_SAVING_PROJECT_MEMBERS: 'REQUEST_SAVING_PROJECT_MEMBERS',
  SAVE_PROJECT_MEMBERS: 'SAVE_PROJECT_MEMBERS',
  PROJECT_MEMBERS_SAVING_ERROR: 'PROJECT_MEMBERS_SAVING_ERROR',
  UPDATE_DOCUMENT_PASSWORD: 'UPDATE_DOCUMENT_PASSWORD',
  SET_DOCUMENT_WORKING_STATE: 'SET_DOCUMENT_WORKING_STATE',
  SET_VIEW_MODE: 'SET_VIEW_MODE',
  SET_SORT_TYPE: 'SET_DOCUMENT_SORT_TYPE',
  FETCH_DOCUMENTS_COUNT: 'FETCH_DOCUMENTS_COUNT',
  UPDATE_FAILED_REIMPORT_STATUS: 'UPDATE_FAILED_REIMPORT_STATUS',
  UPDATE_FAILED_SKETCH_IMPORT: 'UPDATE_FAILED_SKETCH_IMPORT',
};

export const insideProjectViewModes = {
  BIG: 'big',
  SMALL: 'small',
  LIST: 'list',
};
