/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */

'use strict';

import React, { Fragment } from 'react';
import { Cloud, GLOBAL_ELEMENTS } from '@uxpin/shared-components';
import { Badge, APPEARANCES, WEIGHTS } from '@uxpin/design-system';
import autobind from 'autobind-decorator';
import _ from 'lodash';
import classnames from 'classnames';
import { projectsSortingTypes } from '../../../../constants/projects.constants';

export class Sort extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sortType: projectsSortingTypes.CREATE_DATE_DESC,
      isOpen: false,
      cloudPositionClass: this.initialCloudPositionClass,
      maxHeight: null,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return Object.keys(this.props).some((propName) => !_.isEqual(this.props[propName], nextProps[propName]))
      || Object.keys(this.state).some((stateName) => !_.isEqual(this.state[stateName], nextState[stateName]));
  }

  renderSelectedSortingType() {
    switch (this.state.sortType) {
      case projectsSortingTypes.NAME_ASC:
        return (
          <Fragment>
            Name
            <Badge
              appearance={APPEARANCES.NEUTRAL}
              modifierName="sort-order-visual"
              weight={WEIGHTS.LIGHT}>
              A - Z
            </Badge>
          </Fragment>
        );
      case projectsSortingTypes.NAME_DESC:
        return (
          <Fragment>
            Name
            <Badge
              appearance={APPEARANCES.NEUTRAL}
              modifierName="sort-order-visual"
              weight={WEIGHTS.LIGHT}>
              Z - A
            </Badge>
          </Fragment>
        );
      case projectsSortingTypes.CREATE_DATE_DESC:
        return (
          <Fragment>
            Date created
            <Badge
              appearance={APPEARANCES.NEUTRAL}
              modifierName="sort-order-visual"
              weight={WEIGHTS.LIGHT}>
              New first
            </Badge>
          </Fragment>
        );
      case projectsSortingTypes.CREATE_DATE_ASC:
        return (
          <Fragment>
            Date created
            <Badge
              appearance={APPEARANCES.NEUTRAL}
              modifierName="sort-order-visual"
              weight={WEIGHTS.LIGHT}>
              Old first
            </Badge>
          </Fragment>
        );
      case projectsSortingTypes.UPDATE_DATE_DESC:
        return (
          <Fragment>
            Date modified
            <Badge
              appearance={APPEARANCES.NEUTRAL}
              modifierName="sort-order-visual"
              weight={WEIGHTS.LIGHT}>
              New first
            </Badge>
          </Fragment>
        );
      case projectsSortingTypes.UPDATE_DATE_ASC:
        return (
          <Fragment>
            Date modified
            <Badge
              appearance={APPEARANCES.NEUTRAL}
              modifierName="sort-order-visual"
              weight={WEIGHTS.LIGHT}>
              Old first
            </Badge>
          </Fragment>
        );

      // no default
    }
  }

  componentWillMount() {
    this.setState({
      sortType: this.props.sortType,
    });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.sortType !== nextProps.sortType) {
      this.setState({
        sortType: nextProps.sortType,
      });
    }
  }

  setSortType(e, sortType) {
    e.preventDefault();

    if (sortType !== this.state.sortType) {
      this.setState({
        sortType,
      });

      this.props.setSortType(sortType);
    }
  }

  isSelected(sortType) {
    return this.state.sortType === sortType;
  }

  getSelectedClass(sortType) {
    return classnames({ selected: this.isSelected(sortType) });
  }

  @autobind
  getChevronClass() {
    return classnames('gray-link icon-general-chevron', { active: this.props.isOpen });
  }

  getHeadingClass() {
    return classnames('select-label', GLOBAL_ELEMENTS.HEADING_5);
  }

  render() {
    return (
      <div className="cloud-wrapper">
        <h5 className={this.getHeadingClass()}>Sort by:</h5>
        <a className={this.getChevronClass()} href="#sort-by" {...this.props.trigger}>
          {this.renderSelectedSortingType()}
        </a>
        <ul className="cloud options-cloud" {...this.props.cloud}>
          {/* <li><a href="#your-own">Your own order</a></li> */}
          <li>
            <a
              className={this.getSelectedClass(projectsSortingTypes.NAME_ASC)}
              onClick={(e) => this.setSortType(e, projectsSortingTypes.NAME_ASC)}
              href="#name-a-z">
Name A - Z
            </a>
          </li>
          <li>
            <a
              className={this.getSelectedClass(projectsSortingTypes.NAME_DESC)}
              onClick={(e) => this.setSortType(e, projectsSortingTypes.NAME_DESC)}
              href="#name-a-z">
Name Z - A
            </a>
          </li>
          <li className="separator">
            <a
              className={this.getSelectedClass(projectsSortingTypes.CREATE_DATE_DESC)}
              onClick={(e) => this.setSortType(e, projectsSortingTypes.CREATE_DATE_DESC)}
              href="#name-a-z">
Date created (New first)
            </a>
          </li>
          <li>
            <a
              className={this.getSelectedClass(projectsSortingTypes.CREATE_DATE_ASC)}
              onClick={(e) => this.setSortType(e, projectsSortingTypes.CREATE_DATE_ASC)}
              href="#name-a-z">
Date created (Old first)
            </a>
          </li>
          <li className="separator">
            <a
              className={this.getSelectedClass(projectsSortingTypes.UPDATE_DATE_DESC)}
              onClick={(e) => this.setSortType(e, projectsSortingTypes.UPDATE_DATE_DESC)}
              href="#name-a-z">
Date modified (New first)
            </a>
          </li>
          <li>
            <a
              className={this.getSelectedClass(projectsSortingTypes.UPDATE_DATE_ASC)}
              onClick={(e) => this.setSortType(e, projectsSortingTypes.UPDATE_DATE_ASC)}
              href="#name-a-z">
Date modified (Old first)
            </a>
          </li>
        </ul>
      </div>
    );
  }
}

export default Cloud()(Sort);
