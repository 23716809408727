import { connect } from 'react-redux';
import ModalboxLimitsHelperComponent from '../components/Modalboxes/ModalboxLimitsHelper';
import { openModal } from '../../controllers/actions/modal.actions';
import { modalsIds } from '../../constants/modal.constants';
import { COPY_TYPE } from '../../constants/designSystems.constants';

export const mapDispatchToProps = (dispatch) => ({
  openLimitsModal: (planType, limitType) => {
    dispatch(openModal(modalsIds.LIMITS_EXCEEDED, {
      chosenPlan: planType,
      limitType,
    }));
  },
  openDesignSystemsLimitsModal: () => {
    dispatch(openModal(modalsIds.MODALBOX_DESIGN_LIMIT_REACHED, {
      type: COPY_TYPE.DESIGN_SYSTEM,
    }));
  },
});

export const ModalboxLimitsHelper = connect(null, mapDispatchToProps)(ModalboxLimitsHelperComponent);
