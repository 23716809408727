/* eslint-disable max-len */

'use strict';

import {
  first, includes, findKey, keys,
} from 'lodash';
import {
  accountStatuses, accountPlans, accountPlansUnique, designSystemNotificationSegmentsPlans,
  designSystemNotificationSegments, accountPlanNames, accountPlanTypes, datalayerDateFormat,
} from '../constants/accountSettings.constants';
import { FEATURES, FEATURE_STATUS } from '../constants/features.constants';
import { apiErrors } from '../constants/errors.constants';
import { request } from './request';
import { getLogoutUrl } from './router';

export const getCurrentAccount = (currentAccountId, accounts = []) => first(accounts.filter((account) => account.idAccount === currentAccountId));

export const getAccountCoupon = (currentAccountId, accounts = []) => {
  const currentAccount = getCurrentAccount(currentAccountId, accounts);
  return currentAccount ? currentAccount.couponCode : '';
};

export const getAccountStatus = (currentAccountId, accounts = []) => {
  const currentAccount = getCurrentAccount(currentAccountId, accounts);
  return currentAccount ? currentAccount.status : '';
};

export const isAccountActive = (currentAccountId, accounts = []) => {
  const currentAccount = getCurrentAccount(currentAccountId, accounts);
  return currentAccount && includes([
    accountStatuses.ACTIVE,
    accountStatuses.PARKED,
    accountStatuses.OVERDUE_NOTICED,
    accountStatuses.OVERDUE_BLOCKED,
  ], currentAccount.status);
};

export const isAccountOverdue = (currentAccountId, accounts = []) => {
  const currentAccount = getCurrentAccount(currentAccountId, accounts);
  return currentAccount && includes([accountStatuses.OVERDUE_NOTICED, accountStatuses.OVERDUE_BLOCKED], currentAccount.status);
};

export const isAccountParked = (currentAccountId, accounts = []) => {
  const currentAccount = getCurrentAccount(currentAccountId, accounts);
  return currentAccount && accountStatuses.PARKED === currentAccount.status;
};

export const getCurrentAccountTrialDates = (currentAccountId, accounts = []) => {
  const currentAccount = getCurrentAccount(currentAccountId, accounts);
  const createDate = new Date(currentAccount.createDate);
  const trialExpirationDate = new Date(currentAccount.trialExpirationDate);
  const dayOfTrial = Math.floor((new Date() - createDate) / (1000 * 60 * 60 * 24));

  return {
    trialExpirationDate: trialExpirationDate.toLocaleDateString(datalayerDateFormat),
    trialSignupDate: createDate.toLocaleDateString(datalayerDateFormat),
    trialCurrentDay: dayOfTrial,
  };
};

export const isPlanType = (planName, planType) => includes(keys(accountPlans), planType) && includes(accountPlans[planType], planName);

export const isPlanTypeUnique = (planName, planType) => includes(keys(accountPlansUnique), planType) && includes(accountPlansUnique[planType], planName);

export const isFreePlanType = (accountPlan) => (
  accountPlan ? isPlanType(accountPlan.name, accountPlanTypes.FREE) : false
);

export const isTrialPlanType = (accountPlan) => (
  accountPlan ? isPlanType(accountPlan.name, accountPlanTypes.TRIAL) : false
);

export const getPlanTypeByName = (planName) => findKey(accountPlans, (planNames) => includes(planNames, planName)) || '';

export const getDesignSystemsNotificationSegment = (planName, seatCount, isInsideSales) => {
  const { PROPLUS_ANNUAL, PROPLUS_MONTHLY } = accountPlanNames;
  const { SWITCH_SYSTEMS, NO_SWITCH } = designSystemNotificationSegments;
  const planType = getPlanTypeByName(planName);
  let segment = null;

  if (planType === accountPlanTypes.PROTOTYPING || planType === accountPlanTypes.SYSTEMS) {
    return segment;
  } if (isInsideSales && planType !== accountPlanTypes.ENTERPRISE) {
    segment = NO_SWITCH;
  } else if (planName === PROPLUS_ANNUAL || planName === PROPLUS_MONTHLY) {
    // segment depends on number of users on Pro+ plans
    segment = seatCount < 10 ? SWITCH_SYSTEMS : NO_SWITCH;
  } else {
    segment = findKey(designSystemNotificationSegmentsPlans, (plans) => includes(plans, planName)) || null;
  }

  return segment;
};

export const isFeatureEnabled = (features, featureKey) => {
  const featureName = FEATURES[featureKey];
  return features && features[featureName] === FEATURE_STATUS.ENABLED;
};

export const logOut = () => {
  window.location.replace(getLogoutUrl());
};

export const isUserLogged = () => (
  request.get('/me').then((data) => {
    if (data.status !== apiErrors.UNAUTHORIZED) {
      Promise.resolve(true);
    } else {
      // eslint-disable-next-line prefer-promise-reject-errors
      Promise.reject(false);
    }
  })
);

export const getUserNameFromMemberData = (member) => (member.firstname || member.lastname ? `${member.firstname} ${member.lastname}` : member.email);
