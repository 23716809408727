/* eslint-disable react/prop-types */

'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _ from 'lodash';
import { statusIdToClassMap } from '../../../../constants/projects.constants';

export default class ProjectPreview extends React.Component {
  renderStatusInfo() {
    const { data } = this.props;
    if (data.hasProgressInfo) {
      return (
        <section className="project-status">
          <p className="status" data-status={statusIdToClassMap[data.statusId]}>
            <span className="stage-name">{ data.stageName }</span>
            <span className="status-name">{ data.statusName }</span>
          </p>
        </section>
      );
    }
  }

  getClassNames() {
    const { selectedProjects } = this.props;

    return classnames(
      'project-box',
      'project-single',
      'dragging',
      { multiple: !_.isEmpty(selectedProjects) && _.size(selectedProjects) > 1 }
    );
  }

  renderName() {
    const { data, selectedProjects } = this.props;
    let name = data.name;

    if (!_.isEmpty(selectedProjects)) {
      const counter = _.size(selectedProjects);
      name = counter > 1 ? `${counter} projects` : name;
    }

    return name;
  }

  render() {
    return (
      <section className={this.getClassNames()}>
        <header className="header">
          { this.renderStatusInfo() }
          <h3 className="name">
            <span className="original-name">{this.renderName()}</span>
          </h3>
        </header>
        <section className="details" />
      </section>
    );
  }
}

ProjectPreview.propTypes = {
  data: PropTypes.object.isRequired,
};
