import { LibraryPermissions, DesignSystemUtils, isDesktopApp } from '@uxpin/shared-components';

import { request } from '../../utils/request';
import { closeModal, openModal } from './modal.actions';
import { fetchAccountPlan } from './accountSettings.actions';

import { designSystemsActions, ERROR_TYPES } from '../../constants/designSystems.constants';
import { modalsIds } from '../../constants/modal.constants';
import { EVENT_DESIGN_SYSTEM_RENAMED, EVENT_DESIGN_SYSTEM_REMOVED } from '../../constants/desktopAppEvents.constants';
import { getStorybookBaseUrl, isStorybookValidated } from './storybook.actions';
import { MERGE_ERROR_TYPES, LIBRARY_TYPES } from '../../../enums/designLibraries';
import {
  addComponents,
  addCategory,
  addLibrary,
  updateLibrary,
  deleteLibrary,
} from './mergeLibraryManager';
import { getMergeViewUri } from '../helpers/designSystems.helper';
import { createDocument } from './insideProject.actions';
import { getMixpanel } from '../../../../shared/mixpanel';

function getDesignSystems(data) {
  return {
    type: designSystemsActions.FETCH_DESIGN_SYSTEMS,
    systems: data,
  };
}

function getLibraries(data) {
  return {
    type: designSystemsActions.FETCH_LIBRARIES,
    libraries: data,
  };
}

function requestDesignSystemDeleting() {
  return {
    type: designSystemsActions.DESIGN_SYSTEMS_REQUEST_DELETING,
  };
}

export const libraryNameUpdated = (idLibrary, name) => ({
  type: designSystemsActions.LIBRARY_NAME_UPDATED,
  idLibrary,
  name,
});

export const fetchDesignSystems = () => (
  (dispatch) => request.get('/designSystems')
    .catch(() => [])
    .then((data) => {
      dispatch(getDesignSystems(data));
    })
);

export const deleteDesignSystem = (idDesignSystem, idLibrary) => async (dispatch, getState) => {
  const { designSystems: { libraries } } = getState();
  const library = libraries.find((lib) => (lib.id === idLibrary));
  const connectedMergeLibrary = library.connectingLibraries
    ? libraries.find((lib) => (lib.id === Number(library.connectingLibraries[0])))
    : null;

  if (connectedMergeLibrary) {
    const idConnectingLibrary = connectedMergeLibrary.id;
    await request.delete('/designSystems/connect', {
      body: {idLibrary, idConnectingLibrary},
    });
  }

  dispatch(requestDesignSystemDeleting());
  request.delete(`/designSystems/${idDesignSystem}`)
    .then((data) => {
      dispatch({
        type: designSystemsActions.DESIGN_SYSTEMS_DELETED,
        id: data.id,
      });

      if (isDesktopApp) {
        window.dispatchEvent(new CustomEvent(EVENT_DESIGN_SYSTEM_REMOVED, {
          detail: idLibrary,
        }));
      }
    })
    .then(() => {
      dispatch(fetchAccountPlan());
    });
};

export const fetchLibraries = () => (
  (dispatch) => request.get('/libraries/')
    .then((data) => {
      dispatch(getLibraries(data));
    })
);

export const libraryPermissionsUpdated = (idLibrary, accessScope, editScope) => ({
  type: designSystemsActions.LIBRARY_PERMISSIONS_UPDATED,
  idLibrary,
  accessScope,
  editScope,
});

export const updateLibraryPermissions = (idLibrary, libraryData) => (dispatch) => {
  const {
    clearLibraryError,
    setLibraryError,
    requestStartProcessingLibrary,
    requestStopProcessingLibrary,
  } = LibraryPermissions.actions;
  dispatch(clearLibraryError());
  dispatch(requestStartProcessingLibrary());
  request.put(`/libraries/${idLibrary}/`, { body: libraryData }).then((updatedLibrary) => {
    dispatch(requestStopProcessingLibrary());
    dispatch(closeModal(modalsIds.MODALBOX_DESIGN_SYSTEM_PERMISSIONS));
    dispatch(libraryPermissionsUpdated(updatedLibrary.id, updatedLibrary.accessScope, updatedLibrary.editScope));
  }).catch(() => {
    dispatch(setLibraryError(ERROR_TYPES.LIBRARY_UPDATING));
  });
};

export const updateLibraryName = (idLibrary, data) => (dispatch) => {
  request.patch(`/libraries/${idLibrary}/`, { body: data }).then(({ id, name, hash }) => {
    dispatch(libraryNameUpdated(id, name));

    if (isDesktopApp) {
      window.dispatchEvent(new CustomEvent(EVENT_DESIGN_SYSTEM_RENAMED, {
        detail: { id, name, libraryHash: hash },
      }));
    }
  }).catch((e) => {
    console.log(e); // eslint-disable-line no-console
  });
};

export const deleteDesignSystemWithLibrary = (idLibrary) => async (dispatch, getState) => {
  const { designSystems: { libraries } } = getState();
  const library = libraries.find((lib) => (lib.id === idLibrary));
  const connectedMergeLibrary = library.connectingLibraries
    ? libraries.find((lib) => (lib.id === Number(library.connectingLibraries[0])))
    : null;

  if (connectedMergeLibrary) {
    const idConnectingLibrary = connectedMergeLibrary.id;
    await request.delete('/designSystems/connect', {
      body: {idLibrary, idConnectingLibrary},
    });
  }

  dispatch(requestDesignSystemDeleting());
  request.delete(`/libraries/${idLibrary}/`)
    .then((data) => {
      dispatch({
        type: designSystemsActions.DESIGN_SYSTEMS_DELETED,
        id: data.idDesignSystem,
      });
      dispatch({
        type: designSystemsActions.LIBRARY_DELETED,
        id: data.id,
      });

      if (isDesktopApp) {
        window.dispatchEvent(new CustomEvent(EVENT_DESIGN_SYSTEM_REMOVED, {
          detail: idLibrary,
        }));
      }
    })
    .then(() => {
      dispatch(fetchAccountPlan());
    })
    .catch((e) => {
      console.log(e); // eslint-disable-line no-console
    });
};

export const duplicateTemplate = (templateName) => (dispatch) => request.put(`/designSystems/duplicate/${templateName}`)
  .then((data) => {
    dispatch({
      type: designSystemsActions.DESIGN_SYSTEMS_DUPLICATION_STARTED,
      data,
    });

    return data.libraryHash || null;
  })
  .catch((e) => {
    console.log(e); // eslint-disable-line no-console
  });

export const checkDesignSystemStatus = (idDesignSystem) => (dispatch) => {
  request.get(`/designSystems/status/${idDesignSystem}`)
    .then((data) => {
      dispatch({
        type: designSystemsActions.DESIGN_SYSTEMS_CHECK_STATUS,
        status: data.status,
      });
    })
    .catch((e) => {
      console.log(e); // eslint-disable-line no-console
    });
};

export const getTemplateSystem = (templateName) => (dispatch) => {
  request.get(`/designSystems/template/${templateName}`)
    .then((templateData) => {
      dispatch({
        type: designSystemsActions.DESIGN_SYSTEMS_GET_TEMPLATE,
        templateData,
      });
    })
    .catch((e) => {
      console.log(e); // eslint-disable-line no-console
    });
};

export const getTemplates = () => (dispatch) => {
  request.get('/designSystems/templates')
    .then((templates) => {
      dispatch({
        type: designSystemsActions.DESIGN_SYSTEMS_GET_TEMPLATES,
        templates,
      });
    })
    .catch((e) => {
      console.log(e); // eslint-disable-line no-console
    });
};

export const isLimitReached = () => (dispatch) => (
  dispatch(fetchAccountPlan())
    .then((planData) => DesignSystemUtils.isDesignSystemsLimitReached(planData))
);

const actionShowModal = (modal) => ({
  type: 'ACTION_LIBRARIES_SHOW_MODAL',
  modal,
});

export const actionChangeEditedLibrary = (library) => ({
  type: designSystemsActions.LIBRARIES_CHANGED_EDITED_LIBRARY,
  library,
});

export const actionShowDesignLibrariesSettingsModal = () => actionShowModal('MODAL_DESIGN_LIBRARIES_SETTINGS');
export const actionClearEditedLibrary = () => actionChangeEditedLibrary({});

export const actionAddLibrary = (
  name,
  editScope,
  accessScope,
  type,
  url,
  isPrivate,
  isPackageIntegration,
  packageNameVersions,
  externalCssResources,
  npmrc,
  shouldCreateDocument
) => (
  async (dispatch, getState) => {
    let library;
    dispatch(LibraryPermissions.actions.clearLibraryError());
    dispatch(LibraryPermissions.actions.requestStartProcessingLibrary());
    const storybookUrl = getStorybookBaseUrl(url);
    if (type === LIBRARY_TYPES.STORYBOOK && !isPrivate && !storybookUrl) {
      return dispatch(LibraryPermissions.actions.setLibraryError(ERROR_TYPES.INVALID_URL));
    }

    if (storybookUrl) {
      const isValidated = await isStorybookValidated(storybookUrl);
      if (!isValidated) {
        return dispatch(LibraryPermissions.actions.setLibraryError(ERROR_TYPES.LIBRARY_ADDING));
      }
    }

    return addLibrary(
      name,
      editScope,
      accessScope,
      type,
      storybookUrl,
      isPrivate,
      isPackageIntegration,
      packageNameVersions,
      externalCssResources,
      npmrc
    ).then((data) => {
      library = {
        accessScope,
        editScope,
        hash: data.hash,
        id: data.id,
        idUser: data.idUser,
        name,
        type,
        _links: data._links,
        url: storybookUrl,
        isPrivate,
        token: data.token,
        isPackageIntegration,
        pointer: data.pointer,
        packageNameVersions: data.packageNameVersions,
        externalCssResources: data.externalCssResources,
        npmrc: data.npmrc,
      };
      if (!shouldCreateDocument) {
        dispatch(LibraryPermissions.actions.requestStopProcessingLibrary());
        dispatch({
          type: designSystemsActions.LIBRARY_ADDED,
          library,
        });
      }
    }).then(async () => {
      if (isPackageIntegration && !shouldCreateDocument) {
        dispatch(actionChangeEditedLibrary(library));
        dispatch(openModal(modalsIds.MODALBOX_ADD_COMPONENT));
      }

      if (shouldCreateDocument) {
        const { idProject } = getState().insideProject.project;
        await dispatch(createDocument(idProject, null, library.id));
        dispatch(LibraryPermissions.actions.requestStopProcessingLibrary());
        dispatch({
          type: designSystemsActions.LIBRARY_ADDED,
          library,
        });
      }

      getMixpanel((mixpanel) => {
        mixpanel.track('library_added');
      });
    })
      .catch((e) => {
        if (e && e.message === MERGE_ERROR_TYPES.LIBRARY_LIMIT_REACHED) {
          dispatch(LibraryPermissions.actions.setLibraryError(MERGE_ERROR_TYPES.LIBRARY_LIMIT_REACHED));
        }

        return dispatch(LibraryPermissions.actions.setLibraryError(MERGE_ERROR_TYPES.LIBRARY_ADDING));
      });
  }
);

export const actionUpdateLibrary = (
  id,
  name,
  editScope,
  accessScope,
  idUser,
  repositoryPointer,
  packageNameVersions,
  externalCssResources,
  npmrc,
  isPackageIntegration
) => (
  (dispatch) => {
    dispatch(LibraryPermissions.actions.clearLibraryError());
    dispatch(LibraryPermissions.actions.requestStartProcessingLibrary());
    return updateLibrary(
      id,
      name,
      editScope,
      accessScope,
      repositoryPointer,
      packageNameVersions,
      externalCssResources,
      npmrc,
      isPackageIntegration
    )
      .then((data) => {
        dispatch({
          type: designSystemsActions.LIBRARY_STATE_UPDATED,
          idLibrary: id,
          data,
        });
        dispatch(LibraryPermissions.actions.requestStopProcessingLibrary());

        if (isDesktopApp) {
          window.dispatchEvent(new CustomEvent(EVENT_DESIGN_SYSTEM_RENAMED, {
            detail: data,
          }));
        }
      })
      .catch(() => {
        dispatch(LibraryPermissions.actions.setLibraryError(ERROR_TYPES.LIBRARY_UPDATING));
      });
  }
);

export const actionDeleteLibrary = (idLibrary) => (
  async (dispatch, getState) => {
    const { designSystems: { libraries } } = getState();
    const library = libraries.find((lib) => (lib.id === idLibrary));
    const connectedClassicLibrary = library.connectedLibraries
      ? libraries.find((lib) => (lib.id === library.connectedLibraries[0]))
      : null;

    if (connectedClassicLibrary) {
      await request.delete('/designSystems/connect', {
        body: {idLibrary: connectedClassicLibrary.id, idConnectingLibrary: idLibrary},
      });
    }

    dispatch(requestDesignSystemDeleting());
    dispatch(LibraryPermissions.actions.clearLibraryError());
    dispatch(LibraryPermissions.actions.requestStartDeletingLibrary());
    return deleteLibrary(idLibrary)
      .then((data) => {
        dispatch({
          type: designSystemsActions.LIBRARY_DELETED,
          id: data.id,
        });
        dispatch(LibraryPermissions.actions.requestStopDeletingLibrary());

        if (isDesktopApp) {
          window.dispatchEvent(new CustomEvent(EVENT_DESIGN_SYSTEM_REMOVED, {
            detail: data.id,
          }));
        }
      })
      .catch(() => {
        dispatch(LibraryPermissions.actions.setLibraryError(ERROR_TYPES.LIBRARY_DELETING));
      });
  }
);

export const actionFetchCodeSyncData = (idLibrary) => (
  async (dispatch, getState) => {
    const { designSystems: { libraries } } = getState();
    const library = libraries.find((lib) => (lib.id === idLibrary));

    if (library.type !== LIBRARY_TYPES.CODE_SYNC || !!library.isPackageIntegration) {
      return;
    }

    const { authToken, pointer: { allPointer, latestPointer } } = library;

    // Avoid duplicate call
    if (!!authToken && !!allPointer && !!latestPointer) {
      return;
    }

    const token = await fetchAuthToken(idLibrary)
      .catch((e) => {
        // for shared library.
        console.log(e); // eslint-disable-line no-console
      });
    if (token && token.authToken) {
      library.authToken = token.authToken;
    }

    const pointers = await fetchPointers(idLibrary);
    library.pointer.all = pointers;
    library.pointer.latest = pointers.find((p) => p.repositoryPointerType === 'branch');
    return dispatch({
      type: designSystemsActions.LIBRARY_STATE_UPDATED,
      idLibrary,
      data: library,
    });
  }
);

export const actionAddCategory = (idLibrary, name) => (
  async (dispatch, getState) => {
    const components = await addCategory(idLibrary, name);
    const { designSystems: { libraries } } = getState();
    const library = libraries.find((lib) => (lib.id === idLibrary));
    dispatch({
      type: designSystemsActions.LIBRARIES_CHANGED_EDITED_LIBRARY,
      library: Object.assign({}, library, { components }),
    });
  }
);

export const actionAddNpmComponents = (id, componentsToImport, categories) => (
  async (dispatch) => {
    dispatch(LibraryPermissions.actions.requestStartProcessingLibrary());
    dispatch(LibraryPermissions.actions.clearLibraryError());

    if (categories) {
      for (const category of categories) {
        if (category.isAdded) {
          // eslint-disable-next-line no-await-in-loop
          await addCategory(id, category.name, category.id);
        }
      }
    }

    const componentPayload = componentsToImport.map((componentImport) => ({
      categoryId: componentImport.categoryId,
      imports: [{
        name: componentImport.name,
        package: componentImport.package,
      }],
    }));

    addComponents(id, componentPayload)
      .then(() => {
        dispatch(LibraryPermissions.actions.requestStopProcessingLibrary());
        dispatch(actionClearEditedLibrary());
        window.location.replace(getMergeViewUri(id));
      })
      .catch(() => (
        dispatch(LibraryPermissions.actions.setLibraryError(ERROR_TYPES.LIBRARY_ADDING))
      ));
  }
);

export const actionHideDesignLibrariesAddComponentModal = (idLibrary) => (
  async (dispatch) => {
    dispatch(closeModal(modalsIds.MODALBOX_ADD_COMPONENT));
    window.location.replace(getMergeViewUri(idLibrary));
  }
);

export async function fetchAuthToken(idLibrary) {
  if (!idLibrary) {
    return Promise.reject(new Error('idLibrary is not available'));
  }

  return request.get(`/code/${idLibrary}/authToken`);
}

export async function fetchPointers(idLibrary) {
  if (!idLibrary) {
    return Promise.reject(new Error('idLibrary is not available'));
  }

  return request.get(`/code/${idLibrary}/repositoryPointers`);
}
