/* eslint-disable no-plusplus */


'use strict';

import _ from 'lodash';
import { projectsActions, projectsStatuses, projectsSortingTypes } from '../../constants/projects.constants';
import { groupTypes } from '../../constants/projectsGroupsManagement.constants';
import { insideProjectActions } from '../../constants/insideProject.constants';

const {
  // project handler:
  ADDING_PROJECT_ERROR,
  ARCHIVE_PROJECT,
  CHANGE_GROUP,
  CLEAR_ADDING_PROJECT_ERROR,
  CLEAR_REMOVED_PROJECTS,
  CREATE_PROJECT,
  DUPLICATE_PROJECT,
  REMOVE_PROJECT_PERMANENTLY,
  RENAME_PROJECT,
  REQUEST_CREATING_PROJECT,
  UNARCHIVE_PROJECT,
  UPDATE_DUPLICATED_PROJECT,
  UPDATE_DUPLICATED_PROJECT_STATUS,
  UPDATE_PROJECT_STATUS,

  // projects list handler:
  CLEAR_PROJECTS_LIST,
  FETCH_BILLING_SYSTEM_CHANGE_MODAL_VISIBILITY,
  FETCH_GROUPS_EXPANDED_MODE,
  FETCH_PROJECTS,
  FETCH_WELCOME_MODAL_VISIBILITY,
  MODALBOX_FOR_PAYING_USERS_VERSION_SEEN,
  MODALBOX_FOR_TRIAL_USERS_VERSION_SEEN,
  MODALBOX_FREEMIUM_TEST_INTRO_SEEN,
  MODALBOX_SKETCH_PLUGIN_PROMO_VERSION_SEEN,
  PRODUCT_NEWS_UPDATE_SEEN,
  REQUEST_FETCHING_PROJECTS,
  SET_SORT_TYPE,
  START_CHECKING_POSSIBILITY_OF_CHANGING_GROUP,
  STOP_CHECKING_POSSIBILITY_OF_CHANGING_GROUP,
} = projectsActions;

function findProject(projectsGroups, idProject) {
  for (let i = 0; i < projectsGroups.length; i++) {
    const projects = projectsGroups[i].projects;
    for (let j = 0; j < projects.length; j++) {
      if (projects[j].idProject === idProject) {
        return {
          project: Object.assign({}, projects[j]),
          groupIndex: i,
          projectIndex: j,
        };
      }
    }
  }

  return {};
}

function addProject(items, project) {
  return items.map((item) => {
    const newGroup = Object.assign({}, item);
    switch (project.status) {
      case projectsStatuses.ACTIVE:
      case projectsStatuses.DUPLICATED:
        // eslint-disable-next-line max-len
        if (project.idProjectGroup === newGroup.id || (!project.idProjectGroup && newGroup.type === groupTypes.UNGROUPED)) {
          newGroup.projects.push(project);
        }

        break;
      case projectsStatuses.ARCHIVED:
      case projectsStatuses.ARCHIVED_DUPLICATED:
        if (newGroup.type === groupTypes.ARCHIVED) {
          newGroup.projects.push(project);
        }

        break;

      // no default
    }

    return newGroup;
  });
}

function assignDataToProject(items, idProject, data) {
  return items.map((item) => {
    let projects = item.projects;
    for (let j = 0; j < projects.length; j++) {
      if (projects[j].idProject === idProject) {
        const project = Object.assign({}, projects[j]);
        const newGroup = Object.assign({}, item);
        _.assign(project, data);
        projects = [
          ...projects.slice(0, j),
          project,
          ...projects.slice(j + 1),
        ];
        newGroup.projects = projects;
        return newGroup;
      }
    }

    return item;
  });
}

function removeProjects(items, ids) {
  return items.map((item) => {
    const projects = item.projects;
    const newGroup = Object.assign({}, item);
    const newProjects = projects.filter((project) => ids.indexOf(project.idProject) === -1);

    newGroup.projects = newProjects;
    return newGroup;
  });
}

function removeProject(items, idProject) {
  return items.map((item) => {
    let projects = item.projects;
    for (let j = 0; j < projects.length; j++) {
      if (projects[j].idProject === idProject) {
        const newGroup = Object.assign({}, item);
        projects = [
          ...projects.slice(0, j),
          ...projects.slice(j + 1),
        ];
        newGroup.projects = projects;
        return newGroup;
      }
    }

    return item;
  });
}

function changeGroupAndStatus(items, ids, idGroup, status) {
  let modifiedProject;
  const modifiedProjects = [];
  const projectGroups = items.map((item) => {
    const newGroup = Object.assign({}, item);
    newGroup.projects = newGroup.projects.filter((project) => {
      if (ids.indexOf(project.idProject) !== -1) {
        modifiedProject = Object.assign({}, project);
        if (status !== false) {
          modifiedProject.status = status;
        }

        if (idGroup !== false) {
          modifiedProject.idProjectGroup = idGroup;
        }

        if (status === projectsStatuses.ARCHIVED) {
          modifiedProject.idProjectGroup = null;
        }

        modifiedProjects.push(modifiedProject);
        return false;
      }

      return true;
    });

    return newGroup;
  });

  return projectGroups.map((projectGroup) => {
    let group;
    if (idGroup && projectGroup.id === idGroup) {
      group = Object.assign({}, projectGroup);
      group.projects = [...group.projects, ...modifiedProjects];
      return group;
    } if (!idGroup && projectGroup.type === groupTypes.UNGROUPED && status === projectsStatuses.ACTIVE) {
      group = Object.assign({}, projectGroup);
      group.projects = [...group.projects, ...modifiedProjects];
      return group;
    } if (!idGroup && projectGroup.type === groupTypes.ARCHIVED && status === projectsStatuses.ARCHIVED) {
      group = Object.assign({}, projectGroup);
      group.projects = [...group.projects, ...modifiedProjects];
      return group;
    }

    return projectGroup;
  });
}


const actionRenameProject = (state, action) => {
  const projectsGroups = assignDataToProject(state.items, action.idProject, action.data);

  return Object.assign({}, state, {
    items: projectsGroups,
  });
};

const actionUpdateProjectStatus = (state, action) => {
  const projectsGroups = assignDataToProject(state.items, action.idProject, action.data);

  return Object.assign({}, state, {
    items: projectsGroups,
  });
};

const actionUnarchiveProject = (state, action) => {
  const projectsGroups = changeGroupAndStatus(state.items, action.ids, action.idProjectGroup, projectsStatuses.ACTIVE);

  return Object.assign({}, state, {
    items: projectsGroups,
  });
};

const actionArchiveProject = (state, action) => {
  const projectsGroups = changeGroupAndStatus(state.items, action.ids, false, projectsStatuses.ARCHIVED);

  return Object.assign({}, state, {
    items: projectsGroups,
  });
};

const actionChangeGroup = (state, action) => {
  const projectsGroups = changeGroupAndStatus(state.items, action.ids, action.idProjectGroup, projectsStatuses.ACTIVE);

  return Object.assign({}, state, {
    items: projectsGroups,
  });
};

const actionDuplicateProject = (state, action) => {
  const projectData = findProject(state.items, action.idProject);
  const projectCopy = projectData.project;
  projectCopy.status = projectsStatuses.DUPLICATED;
  projectCopy.name = `${projectCopy.name} - Copy`;
  projectCopy.hasTemporaryId = true;
  projectCopy.insertDate = new Date().toISOString();
  projectCopy.idProject = action.temporaryId;

  return Object.assign({}, state, {
    items: state.items.map((item, index) => {
      if (index === projectData.groupIndex) {
        const group = Object.assign({}, item);
        group.projects = [...group.projects, projectCopy];
        return group;
      }

      return item;
    }),
  });
};

const actionUpdateDuplicatedProjectStatus = (state, action) => {
  const projectsGroups = assignDataToProject(state.items, action.idProject, {
    status: projectsStatuses.ACTIVE,
  });

  return Object.assign({}, state, {
    items: projectsGroups,
  });
};

const actionUpdateDuplicatedProject = (state, action) => {
  const projectsGroups = assignDataToProject(state.items, action.temporaryId, {
    idProject: action.idProject,
    name: action.projectName,
    hasTemporaryId: null,
  });

  return Object.assign({}, state, {
    items: projectsGroups,
  });
};

const actionRemoveProjectPermanently = (state, action) => {
  const projectsGroups = removeProject(state.items, action.idProject);

  return Object.assign({}, state, {
    items: projectsGroups,
  });
};

const actionClearRemovedProjects = (state, action) => {
  const projectsGroups = removeProjects(state.items, action.ids);

  return Object.assign({}, state, {
    items: projectsGroups,
  });
};

const actionRequestCreatingProject = (state) => (
  Object.assign({}, state, {
    isAdding: true,
    isAddingError: false,
    errorType: null,
    currentAddedProjectId: 0,
  })
);

const actionCreateProject = (state, action) => {
  const projectsGroups = addProject(state.items, action.data);

  return Object.assign({}, state, {
    isAdding: false,
    items: projectsGroups,
    currentAddedProjectId: action.data.idProject,
  });
};

const actionAddingProjectError = (state, action) => (
  Object.assign({}, state, {
    isAddingError: true,
    isAdding: false,
    errorType: action.errorType,
  })
);

const actionClearAddingProjectError = (state) => (
  Object.assign({}, state, {
    isAddingError: false,
    errorType: null,
  })
);

const projectHandler = {
  [ADDING_PROJECT_ERROR]: actionAddingProjectError,
  [ARCHIVE_PROJECT]: actionArchiveProject,
  [CHANGE_GROUP]: actionChangeGroup,
  [CLEAR_ADDING_PROJECT_ERROR]: actionClearAddingProjectError,
  [CLEAR_REMOVED_PROJECTS]: actionClearRemovedProjects,
  [CREATE_PROJECT]: actionCreateProject,
  [DUPLICATE_PROJECT]: actionDuplicateProject,
  [REMOVE_PROJECT_PERMANENTLY]: actionRemoveProjectPermanently,
  [RENAME_PROJECT]: actionRenameProject,
  [REQUEST_CREATING_PROJECT]: actionRequestCreatingProject,
  [UNARCHIVE_PROJECT]: actionUnarchiveProject,
  [UPDATE_DUPLICATED_PROJECT]: actionUpdateDuplicatedProject,
  [UPDATE_DUPLICATED_PROJECT_STATUS]: actionUpdateDuplicatedProjectStatus,
  [UPDATE_PROJECT_STATUS]: actionUpdateProjectStatus,
};

function handleProject(state, action) {
  if (!_.has(projectHandler, action.type)) {
    return false;
  }

  return projectHandler[action.type](state, action);
}


const actionFetchProjects = (state, action) => (
  Object.assign({}, state, {
    items: action.projects,
  })
);

const actionRequestFetchingProjects = (state) => (
  Object.assign({}, state, {
    isLoading: true,
  })
);

const actionClearProjectsList = (state) => (
  Object.assign({}, state, {
    items: [],
  })
);

const actionSetSortType = (state, action) => (
  Object.assign({}, state, {
    sortType: action.sortType,
  })
);

const actionFetchGroupsExpandedMode = (state, action) => (
  Object.assign({}, state, {
    groupsExpandedMode: action.groupsExpandedMode,
    isLoading: false,
  })
);

const actionFetchWelcomeModalVisibility = (state, action) => (
  Object.assign({}, state, {
    showWelcomeModal: action.showWelcomeModal,
  })
);

const actionFetchBillinSystemChangeModalVisibility = (state, action) => (
  Object.assign({}, state, {
    showBillingSystemChangeModal: action.showBillingSystemChangeModal,
  })
);

const actionProductNewsUpdateSeen = (state, action) => (
  Object.assign({}, state, {
    productNewsUpdateSeen: action.productNewsUpdateSeen,
  })
);

const actionModalboxSketchPluginPromoVersionSeen = (state, action) => (
  Object.assign({}, state, {
    sketchPluginPromoModalboxVersionSeen: action.sketchPluginPromoModalboxVersionSeen,
  })
);

const actionModalboxForPayingUsersVersionSeen = (state, action) => (
  Object.assign({}, state, {
    modalboxForPayingUsersSeen: action.modalboxForPayingUsersSeen,
  })
);

const actionModalboxForTrialUsersVersionSeen = (state, action) => (
  Object.assign({}, state, {
    modalboxForTrialUsersSeen: action.modalboxForTrialUsersSeen,
  })
);

const actionModalboxFreemiumTestIntroSeen = (state, action) => (
  Object.assign({}, state, {
    modalboxFreemiumTestIntroSeen: action.modalboxFreemiumTestIntroSeen,
  })
);

const actionStartCheckingPossibilityOfChangingGroup = (state) => (
  Object.assign({}, state, {
    isCheckingPossibilityOfChangingGroup: true,
  })
);

const actionStopCheckingPossibilityOfChangingGroup = (state, action) => (
  Object.assign({}, state, {
    isCheckingPossibilityOfChangingGroup: false,
    isChangeGroupAlertVisible: action.showModal,
    hasProjectGroup: action.hasProjectGroup,
    isUserToAdd: action.isUserToAdd,
    newProjectGroupName: action.projectGroupName,
    changeGroupAlertActions: action.alertActions,
  })
);

const actionFetchDocumentsCount = (state, action) => (
  Object.assign({}, state, {
    totalDocumentsCount: action.data,
  })
);

const projectsListHandler = {
  [CLEAR_PROJECTS_LIST]: actionClearProjectsList,
  [FETCH_BILLING_SYSTEM_CHANGE_MODAL_VISIBILITY]: actionFetchBillinSystemChangeModalVisibility,
  [FETCH_GROUPS_EXPANDED_MODE]: actionFetchGroupsExpandedMode,
  [FETCH_PROJECTS]: actionFetchProjects,
  [FETCH_WELCOME_MODAL_VISIBILITY]: actionFetchWelcomeModalVisibility,
  [MODALBOX_FOR_PAYING_USERS_VERSION_SEEN]: actionModalboxForPayingUsersVersionSeen,
  [MODALBOX_FOR_TRIAL_USERS_VERSION_SEEN]: actionModalboxForTrialUsersVersionSeen,
  [MODALBOX_FREEMIUM_TEST_INTRO_SEEN]: actionModalboxFreemiumTestIntroSeen,
  [MODALBOX_SKETCH_PLUGIN_PROMO_VERSION_SEEN]: actionModalboxSketchPluginPromoVersionSeen,
  [PRODUCT_NEWS_UPDATE_SEEN]: actionProductNewsUpdateSeen,
  [REQUEST_FETCHING_PROJECTS]: actionRequestFetchingProjects,
  [SET_SORT_TYPE]: actionSetSortType,
  [START_CHECKING_POSSIBILITY_OF_CHANGING_GROUP]: actionStartCheckingPossibilityOfChangingGroup,
  [STOP_CHECKING_POSSIBILITY_OF_CHANGING_GROUP]: actionStopCheckingPossibilityOfChangingGroup,
  [insideProjectActions.FETCH_DOCUMENTS_COUNT]: actionFetchDocumentsCount,
};

function handleProjectsList(state, action) {
  if (!_.has(projectsListHandler, action.type)) {
    return state;
  }

  return projectsListHandler[action.type](state, action);
}

export function projectsReducer(state = {
  isLoading: false,
  isAdding: false,
  isAddingError: false,
  items: [],
  errorType: null,
  isCheckingPossibilityOfChangingGroup: false,
  isChangeGroupAlertVisible: false,
  sortType: projectsSortingTypes.CREATE_DATE_DESC,
  groupsExpandedMode: {},
}, action) {
  return handleProject(state, action) || handleProjectsList(state, action);
}
