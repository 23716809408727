'use strict';

export const stakeholdersActions = {
  FETCH_STAKEHOLDERS: 'FETCH_STAKEHOLDERS',
  REQUEST_FETCHING_STAKEHOLDERS: 'REQUEST_FETCHING_STAKEHOLDERS',
  REQUEST_MODIFYING_STAKEHOLDERS: 'REQUEST_MODIFYING_STAKEHOLDERS',
  ASSIGN_STAKEHOLDER_DATA: 'ASSIGN_STAKEHOLDER_DATA',
  ADD_STAKEHOLDER: 'ADD_STAKEHOLDER',
  SET_ERROR: 'SET_ERROR',
  CLEAR_ERROR: 'CLEAR_ERROR',
  MARK_STAKEHOLDER_AS_REMOVED: 'MARK_STAKEHOLDER_AS_REMOVED',
  COUNT_TIME_TO_REMOVE_STAKEHOLDER: 'COUNT_TIME_TO_REMOVE_STAKEHOLDER',
  CLEAR_STAKEHOLDERS: 'CLEAR_STAKEHOLDERS',
  RESTORE_STAKEHOLDER: 'RESTORE_STAKEHOLDER',
  REMOVE_STAKEHOLDER: 'REMOVE_STAKEHOLDER',
  CLOSE_EDIT_MODAL: 'CLOSE_EDIT_MODAL',
  SENDING_REQUEST: 'SENDING_REQUEST',
  SEND_REQUEST: 'SEND_REQUEST',
  SET_REQUEST_ERROR: 'SET_REQUEST_ERROR',
  CLEAR_REQUEST_ERROR: 'CLEAR_REQUEST_ERROR',
};
