import { connect } from 'react-redux';
import { PlanInfo as PlanInfoComponent } from '@uxpin/shared-components';
import * as upgradeModal from '../../controllers/actions/upgradeModal.actions';

export const mapStateToProps = () => ({});

export const mapDispatchToProps = (dispatch) => ({
  openUpgradeModal: () => {
    dispatch(upgradeModal.openUpgradeModal());
  },
});

export const PlanInfo = connect(mapStateToProps, mapDispatchToProps)(PlanInfoComponent);
