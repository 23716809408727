/* eslint-disable react/destructuring-assignment */

'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import autobind from 'autobind-decorator';
import { has, isUndefined, size } from 'lodash';
import { ModalboxAddOnsFollowUpUpgrade } from '../../../containers/Modalboxes/Promo/ModalboxAddOnsFollowUpUpgrade';
import { ModalboxAddOnsFollowUpSuccess } from '../../../containers/Modalboxes/Promo/ModalboxAddOnsFollowUpSuccess';
import { accountPlanTypes } from '../../../../constants/accountSettings.constants';
import { addOns, addOnCodes } from '../../../../constants/marketing.constants';
import routes from '../../../../constants/routes.constants';
import { isPlanType } from '../../../../utils/account';
import { preventDefaultEvent } from '../../../../utils/common';
import { goTo } from '../../../../utils/router';

export default class AddOnsFollowUpModalsComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isAddOnUpsellUpgradeModalOpen: false,
      isAddOnUpsellSuccessModalOpen: false,
      // eslint-disable-next-line react/no-unused-state
      addon: null,
    };
  }

  componentWillMount() {
    const { addOn, getAddOnInfo } = this.props;

    if (!isUndefined(getAddOnInfo)) {
      getAddOnInfo(addOnCodes[addOn]);
    }
  }

  componentDidMount() {
    this.updateState(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.updateState(nextProps);
  }

  isAddOnValidForPlan(addOn, planName) {
    const { ADVANCED, PROTOTYPING, SYSTEMS } = accountPlanTypes;
    const specModeAdvanced = isPlanType(planName, ADVANCED) && addOn === addOns.SPEC_MODE;
    // eslint-disable-next-line max-len
    const specModePrototyping = isPlanType(planName, PROTOTYPING) && (addOn === addOns.SPEC_MODE_PROTO || addOn === addOns.SPEC_MODE);
    const customFontsSystems = isPlanType(planName, SYSTEMS) && addOn === addOns.CUSTOM_FONTS;

    return specModeAdvanced || specModePrototyping || customFontsSystems;
  }

  updateState(props) {
    const { addOn, addOnData, planName } = props;
    const { isAddOnUpsellUpgradeModalOpen, isAddOnUpsellSuccessModalOpen } = this.state;

    if (has(addOnData, 'error') || (planName !== null && !this.isAddOnValidForPlan(addOn, planName))) {
      goTo(routes.PROJECTS);
    }

    if (addOn && !isAddOnUpsellUpgradeModalOpen && !isAddOnUpsellSuccessModalOpen && size(addOnData) !== 0) {
      this.setState({
        isAddOnUpsellUpgradeModalOpen: true,
      });
    }
  }

  @autobind
  handleAddOnPurchased(e) {
    preventDefaultEvent(e);
    this.setState({
      isAddOnUpsellUpgradeModalOpen: false,
      isAddOnUpsellSuccessModalOpen: true,
    });
  }

  handleModalClose(e) {
    preventDefaultEvent(e);
    goTo(routes.PROJECTS);
  }

  renderUpgradeModal() {
    return (
      <ModalboxAddOnsFollowUpUpgrade
        isOpen={this.state.isAddOnUpsellUpgradeModalOpen}
        onUpgradeClick={this.handleAddOnPurchased}
        onClose={this.handleModalClose}
        addOnData={this.props.addOnData}
        addOn={this.props.addOn}
      />
    );
  }

  renderSuccessModal() {
    return (
      <ModalboxAddOnsFollowUpSuccess
        isOpen={this.state.isAddOnUpsellSuccessModalOpen}
        onClose={this.handleModalClose}
        addOnData={this.props.addOnData}
      />
    );
  }

  render() {
    if (this.state.isAddOnUpsellUpgradeModalOpen) {
      return this.renderUpgradeModal();
    } if (this.state.isAddOnUpsellSuccessModalOpen) {
      return this.renderSuccessModal();
    }

    return null;
  }
}

AddOnsFollowUpModalsComponent.propTypes = {
  addOn: PropTypes.string.isRequired,
  addOnData: PropTypes.object.isRequired,
  getAddOnInfo: PropTypes.func.isRequired,
};
