import { connect } from 'react-redux';
import ModalboxDocumentChangeProject from '../components/Modalboxes/ModalboxDocumentChangeProject';
import { modalsIds } from '../../constants/modal.constants';
import { projectsStatuses } from '../../constants/projects.constants';
import * as modal from '../../controllers/actions/modal.actions';
import * as documents from '../../controllers/actions/document.actions';

function getProjects(items, document) {
  let projects = [];

  items.forEach((group) => {
    projects = projects.concat(group.projects.slice(0));
  });

  return projects.filter((project) => (
    project.idProject !== document.idProject && project.status === projectsStatuses.ACTIVE
  ));
}

export const mapStateToProps = (state) => {
  const { data: modalData, openedModalsIds } = state.modal;
  const { items } = state.projects;
  const document = modalData[modalsIds.MODALBOX_DOCUMENT_CHANGE_PROJECT] || {};

  return {
    isOpen: openedModalsIds[modalsIds.MODALBOX_DOCUMENT_CHANGE_PROJECT] || false,
    data: document,
    projects: getProjects(items, document),
  };
};

export const mapDispatchToProps = (dispatch) => ({
  moveDocument: (idDocument, idProject) => {
    dispatch(documents.moveDocument(idDocument, idProject));
  },

  onClose: () => {
    dispatch(modal.closeModal(modalsIds.MODALBOX_DOCUMENT_CHANGE_PROJECT));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalboxDocumentChangeProject);
