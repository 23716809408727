/* eslint-disable react/prop-types */

'use strict';

import React from 'react';
import autobind from 'autobind-decorator';

export default class StepActions extends React.Component {
  @autobind
  onEdit(e) {
    e.preventDefault();
    e.stopPropagation();
    const { onEdit } = this.props;
    onEdit();
  }

  @autobind
  onDelete(e) {
    e.preventDefault();
    e.stopPropagation();
    const { startDeleteStage, index } = this.props;
    startDeleteStage(index);
  }

  renderDeleteStepElement() {
    const { isDeletingActionHidden } = this.props;
    if (!isDeletingActionHidden) {
      return (
        <li>
          <a
            onClick={this.onDelete}
            href="#delete-step"
            className="icon-general-trash only-icon-font red-hover">
            Delete step
          </a>
        </li>
      );
    }
  }

  render() {
    const { isCustomizationEnabled } = this.props;
    if (isCustomizationEnabled) {
      return (
        <ul>
          <li>
            <a onClick={this.onEdit} href="#edit-step" className="icon-general-pencil only-icon-font blue-hover">
Edit
              step name
            </a>
          </li>
          {this.renderDeleteStepElement()}
        </ul>
      );
    }

    return null;
  }
}
