import { connect } from 'react-redux';
import ModalboxRemoteHelperComponent from '../components/Modalboxes/ModalboxRemoteHelper';
import * as upgradeModal from '../../controllers/actions/upgradeModal.actions';

export const mapStateToProps = (state) => ({
  isUpgradeModalOpen: state.upgradeModal.isUpgradeModalOpen,
});

export const mapDispatchToProps = (dispatch) => ({
  openUpgradeModal: () => {
    dispatch(upgradeModal.openUpgradeModal());
  },
});

export const ModalboxRemoteHelper = connect(mapStateToProps, mapDispatchToProps)(ModalboxRemoteHelperComponent);
