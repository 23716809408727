'use strict';

import { getMixpanel } from '../../../../shared/mixpanel';
import { shareActions } from '../../constants/share.constants';
import { request } from '../../utils/request';

export const fetchShareData = (idDocument) => (dispatch) => {
  request.get(`/share/document/${idDocument}`)
    .then((data) => {
      dispatch({
        type: shareActions.FETCH_SHARE_DATA,
        data,
      });
    })
    .catch(() => {
      // TODO handle error
    });
};

export const checkShareDocumentByEmailReCaptcha = () => (dispatch) => {
  request.get('/share/document/email/checkrecaptcha')
    .then((data) => {
      dispatch({
        type: shareActions.CHECK_SHARE_EMAIL_RECAPTCHA,
        data,
      });
    });
};

export const shareDocumentByEmail = (idDocument, documentHash, recipients, message, reCaptchaValue) => (dispatch) => {
  dispatch({
    type: shareActions.REQUEST_SHARING_DOCUMENT_BY_EMAIL,
  });
  request.post(`/share/document/${idDocument}/email`, {
    body: {
      documentHash,
      recipients,
      message,
      reCaptchaValue,
    },
  }, true)
    .then(() => {
      dispatch({
        type: shareActions.SHARE_DOCUMENT_BY_EMAIL,
      });
      dispatch(checkShareDocumentByEmailReCaptcha());
    })
    .catch(() => {
      dispatch({
        type: shareActions.SHARE_DOCUMENT_BY_EMAIL_ERROR,
      });
    });
};

export const getExportStatus = () => (dispatch) => {
  request.get('/share/export/status')
    .then((data) => {
      dispatch({
        type: shareActions.FETCH_EXPORTS_STATUSES,
        exports: data.exports || {},
      });
    });
};

export const discardExportInfo = (index) => (dispatch) => {
  dispatch({
    type: shareActions.DISCARD_EXPORT,
    index,
  });
  request.delete(`/share/export/${index}`);
};

const requestExportDocument = (exportType) => ({
  type: shareActions.REQUEST_EXPORT_DOCUMENT,
  exportType,
});

export const exportDocument = (idDocument, data) => (dispatch) => {
  dispatch(requestExportDocument(data.type));
  request.post(`/share/document/${idDocument}/export`, {
    body: data,
  }).then(() => {
    dispatch({
      type: shareActions.FINISH_EXPORT_DOCUMENT,
      exportType: data.type,
    });
    getExportStatus()(dispatch);
    getMixpanel((mixpanel) => {
      mixpanel.track('prototype_exported');
    });
  });
};

export const fetchTypekitFontsInProjectInfo = (idProject) => (dispatch) => {
  request.get(`/fonts/typekit/project_fonts?id=${idProject}`)
    .then((hasTypekitFontsInProject) => {
      dispatch({
        type: shareActions.FETCH_TYPEKIT_FONTS_IN_PROJECT_INFO,
        hasTypekitFontsInProject,
      });
    })
    .catch(() => {
      dispatch({
        type: shareActions.FETCH_TYPEKIT_FONTS_IN_PROJECT_INFO,
        hasTypekitFontsInProject: {},
      });
    });
};
