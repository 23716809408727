'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import autobind from 'autobind-decorator';
import { noop } from 'lodash';
import { Modal } from '@uxpin/shared-components';
import { modalsIds } from '../../../constants/modal.constants';
import { preventDefaultEvent } from '../../../utils/common';
import ContactRequestForm from './components/ContactRequestForm';


export default class ModalboxContactRequestComponent extends React.Component {
  constructor(props) {
    super(props);

    this.closeHelper = noop;

    this.MODAL = {
      WRAPPER_ID: modalsIds.LIMITS_EXCEEDED,
      CLASS_NAMES: 'contact-request-modal-box',
      TITLE: 'Send contact request',
    };

    this.contentType = null;
    this.showModal = false;
  }

  @autobind
  closeModal(e) {
    preventDefaultEvent(e);
    // eslint-disable-next-line react/destructuring-assignment
    this.props.onClose(e);
    this.closeHelper.hideModal();
  }

  renderContactForm() {
    const { email, sendContactRequest } = this.props;

    return (
      <ContactRequestForm
        email={email}
        sendContactRequest={sendContactRequest}
        closeModal={this.closeModal}
      />
    );
  }

  render() {
    const { isOpen } = this.props;

    return (
      <Modal
        ref={(c) => { this.closeHelper = c; }}
        wrapperId={this.MODAL.WRAPPER_ID}
        classNames={this.MODAL.CLASS_NAMES}
        title={this.MODAL.TITLE}
        isOpen={isOpen}
        onClose={this.closeModal}>
        <div>
          {this.renderContactForm()}
        </div>
      </Modal>
    );
  }
}

ModalboxContactRequestComponent.propTypes = {
  isOpen: PropTypes.bool,
  email: PropTypes.string,
  sendContactRequest: PropTypes.func,
  onClose: PropTypes.func,
};

ModalboxContactRequestComponent.defaultProps = {
  isOpen: false,
  email: '',
  sendContactRequest: noop,
  onClose: noop,
};
