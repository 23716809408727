import { connect } from 'react-redux';
import ProjectActionsComponent from '../components/ProjectBar/components/ProjectActions';
import * as modal from '../../controllers/actions/modal.actions';
import { modalsIds } from '../../constants/modal.constants';
import { FEATURE_STATUS, FEATURES } from '../../constants/features.constants';
import { isAccountParked } from '../../utils/account';
import { openFeatureUpgrade } from '../../controllers/actions/pricing.actions';

export const mapStateToProps = (state) => {
  const { openedModalsIds } = state.modal;
  const { data } = state.currentUser;
  const { features } = state.features;

  const areProjectGroupsEnabled = features[FEATURES.PROJECT_GROUPS] === FEATURE_STATUS.ENABLED;

  return {
    isAccountParked: isAccountParked(data.idActiveAccount, data.accounts),
    isModalboxGroupsSettingsOpen: openedModalsIds[modalsIds.MODALBOX_GROUPS_SETTINGS] || false,
    areProjectGroupsEnabled,
  };
};

export const mapDispatchToProps = (dispatch) => ({
  closeModalboxGroupsSettings() {
    dispatch(modal.closeModal(modalsIds.MODALBOX_GROUPS_SETTINGS));
  },
  openFeatureUpgradeModal() {
    dispatch(openFeatureUpgrade(FEATURES.PROJECT_GROUPS));
  },
});

export const ProjectActions = connect(mapStateToProps, mapDispatchToProps)(ProjectActionsComponent);
