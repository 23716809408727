/* eslint-disable react/default-props-match-prop-types,react/button-has-type */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/require-default-props */

'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@uxpin/shared-components';
import _ from 'lodash';
import autobind from 'autobind-decorator';

export default class ModalboxDocumentDelete extends React.Component {
  constructor(props) {
    super(props);
    this.MODAL = {
      WRAPPER_ID: 'project-delete-modal-box',
      CLASS_NAMES: ['modal-box-medium-width'],
    };
  }

  @autobind
  renderTitle() {
    const { data } = this.props;
    if (data) {
      return (
        <h2>
          Are you sure, you want to delete prototype&nbsp;"
          { data.name }
          "?
        </h2>
      );
    }
  }

  @autobind
  removeDocument() {
    const { removeDocument, data, onClose } = this.props;
    removeDocument(data.idDocument);
    onClose();
  }

  @autobind
  onClose(e) {
    const { onClose } = this.props;
    e.preventDefault();
    onClose();
  }

  shouldComponentUpdate(nextProps, nextState) {
    const propsNames = Object.keys(ModalboxDocumentDelete.propTypes);
    return propsNames.some((propsName) => !_.isEqual(this.props[propsName], nextProps[propsName]))
      || !_.isEqual(this.state, nextState);
  }

  render() {
    return (
      <Modal
        wrapperId={this.MODAL.WRAPPER_ID}
        classNames={this.MODAL.CLASS_NAMES}
        renderTitle={this.renderTitle}
        isOpen={this.props.isOpen}
        // eslint-disable-next-line react/jsx-no-bind
        onClose={this.onClose.bind(this)}>
        <footer>
          <ul className="btns-group">
            <li>
              <a href="#cancel" onClick={this.onClose} className="gray-style cancel-button action-close">Cancel</a>
            </li>
            <li>
              <button
                onClick={this.removeDocument}
                className="btn-solid red btn-wide with-icon icon-general-trash">
                Delete prototype
              </button>
            </li>
          </ul>
        </footer>
      </Modal>
    );
  }
}

ModalboxDocumentDelete.defaultProps = {
  data: {},
  isOpen: false,
  removeDocument: _.noop,
  onClose: _.noop,
};

ModalboxDocumentDelete.propTypes = {
  data: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  removeDocument: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};
