import { findIndex, filter } from 'lodash';
import { designSystemsActions } from '../../constants/designSystems.constants';

const deleteDesignSystem = (state, action) => {
  const systemsReduced = filter(state.systems, (system) => system.id !== action.id);

  return Object.assign({}, state, {
    systems: systemsReduced,
    isDesignSystemDeleting: false,
  });
};

const deleteLibrary = (state, action) => {
  const librariesReduced = filter(state.libraries, (library) => library.id !== action.id);

  return Object.assign({}, state, {
    libraries: librariesReduced,
    isDesignSystemDeleting: false,
  });
};

const addLibrary = (state, action) => Object.assign({}, state, {
  libraries: [...state.libraries, action.library],
});

const updateLibraryPermission = (systems, idLibrary, accessScope, editScope) => {
  const index = findIndex(systems, (system) => system.idLibrary === idLibrary);
  const newSystems = [...systems];
  newSystems[index] = Object.assign({}, systems[index], { accessScope, editScope });
  return newSystems;
};

const updateLibraryName = (systems, idLibrary, name) => {
  const index = findIndex(systems, (system) => system.idLibrary === idLibrary);
  const newSystems = [...systems];
  newSystems[index] = Object.assign({}, systems[index], { name });
  return newSystems;
};

const updateLibrary = (libraries, idLibrary, newData) => {
  const index = findIndex(libraries, (library) => library.id === idLibrary);
  const newLibraries = [...libraries];
  newLibraries[index] = Object.assign({}, libraries[index], { ...newData });
  return newLibraries;
};

const intialState = {
  systems: [],
  libraries: [],
  isDesignSystemDeleting: false,
  isLibraryListFetched: false,
  idDuplicatedSystem: 0,
  status: '',
  libraryHash: '',
  templateData: {},
  templates: [],
  isTemplatesListFetched: false,
  editedLibrary: {},
};

// eslint-disable-next-line complexity
export default function designSystemsReducer(state = intialState, action) {
  switch (action.type) {
    case designSystemsActions.FETCH_DESIGN_SYSTEMS:
      return Object.assign({}, state, {
        systems: action.systems,
      });
    case designSystemsActions.FETCH_LIBRARIES:
      return Object.assign({}, state, {
        libraries: action.libraries,
        isLibraryListFetched: true,
      });
    case designSystemsActions.LIBRARY_ADDED:
      return addLibrary(state, action);
    case designSystemsActions.DESIGN_SYSTEMS_REQUEST_DELETING:
      return Object.assign({}, state, {
        isDesignSystemDeleting: true,
      });
    case designSystemsActions.DESIGN_SYSTEMS_DELETED:
      return deleteDesignSystem(state, action);
    case designSystemsActions.LIBRARY_DELETED:
      return deleteLibrary(state, action);
    case designSystemsActions.LIBRARY_STATE_UPDATED:
      return Object.assign({}, state, {
        libraries: updateLibrary(
          state.libraries,
          action.idLibrary,
          action.data
        ),
      });
    case designSystemsActions.LIBRARY_PERMISSIONS_UPDATED:
      return Object.assign({}, state, {
        systems: updateLibraryPermission(
          state.systems,
          action.idLibrary,
          action.accessScope,
          action.editScope
        ),
      });
    case designSystemsActions.LIBRARY_NAME_UPDATED:
      return Object.assign({}, state, {
        systems: updateLibraryName(
          state.systems,
          action.idLibrary,
          action.name
        ),
      });
    case designSystemsActions.LIBRARIES_CHANGED_EDITED_LIBRARY:
      return Object.assign({}, state, {
        editedLibrary: action.library,
      });
    case designSystemsActions.DESIGN_SYSTEMS_DUPLICATION_STARTED:
      return Object.assign({}, state, {
        idDuplicatedSystem: action.data.idDesignSystem,
        libraryHash: action.data.libraryHash,
      });
    case designSystemsActions.DESIGN_SYSTEMS_CHECK_STATUS:
      return Object.assign({}, state, {
        status: action.status,
      });
    case designSystemsActions.DESIGN_SYSTEMS_GET_TEMPLATE:
      return Object.assign({}, state, {
        templateData: action.templateData,
      });
    case designSystemsActions.DESIGN_SYSTEMS_GET_TEMPLATES:
      return Object.assign({}, state, {
        templates: action.templates,
        isTemplatesListFetched: true,
      });
    default:
      return state;
  }
}
