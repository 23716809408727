/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/require-default-props */

'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import { isEqual, isUndefined, delay } from 'lodash';
import classnames from 'classnames';

export default class GroupColor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isVisible: true,
      isFaded: false,
    };
  }

  getStyles() {
    const width = !isUndefined(this.state.progress) ? `${this.state.progress * 100}%` : undefined;
    const styles = {
      backgroundColor: this.props.color,
      width,
    };

    if (!this.state.isVisible) {
      styles.display = 'none';
    } else {
      delete styles.display;
    }

    return styles;
  }

  hide() {
    this.setState({
      isVisible: false,
    });
  }

  setFade(fade) {
    this.setState({
      isFaded: fade,
    });
  }

  setProgress(progress) {
    this.setState({
      progress,
    });

    if (progress === 1) {
      this.setFade(true);
    }
  }

  show() {
    delay(() => {
      this.setState({
        isVisible: true,
      });
    });
  }

  componentWillMount() {
    if (!isUndefined(this.props.progress)) {
      this.setFade(false);
      this.hide();
      this.setProgress(this.props.progress);
      this.show();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!isUndefined(nextProps.progress)) {
      if (this.props.progress > nextProps.progress) {
        this.setFade(false);
        this.hide();
        this.setProgress(nextProps.progress);
        this.show();
      } else {
        this.setProgress(nextProps.progress);
      }
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return Object.keys(this.props).some((propName) => !isEqual(this.props[propName], nextProps[propName]))
    || Object.keys(this.state).some((stateName) => !isEqual(this.state[stateName], nextState[stateName]));
  }

  getClassNames() {
    return classnames('group-color', {
      'is-faded': this.state.isFaded,
    });
  }

  render() {
    return (
      <span className={this.getClassNames()} style={this.getStyles()} />
    );
  }
}

GroupColor.propTypes = {
  color: PropTypes.string,
  progress: PropTypes.number,
};

GroupColor.defaultTypes = {
  color: '',
};
