'use strict';

import { connect } from 'react-redux';
import PaymentFailedComponent from '../../components/WarningBars/PaymentFailedBar';
import * as upgradeModal from '../../../controllers/actions/upgradeModal.actions';

export const mapStateToProps = () => ({});

export const mapDispatchToProps = (dispatch) => ({
  openUpgradeModal: () => {
    dispatch(upgradeModal.openUpgradeModal());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentFailedComponent);
