'use strict';

export const dateConvert = (ms) => {
  let days;
  let hours;
  let minutes;
  let seconds;

  seconds = Math.floor(ms / 1000);
  minutes = Math.floor(seconds / 60);
  seconds = seconds % 60;
  hours = Math.floor(minutes / 60);
  minutes = minutes % 60;
  // eslint-disable-next-line prefer-const
  days = Math.floor(hours / 24);
  hours = hours % 24;

  return {
    days,
    hours,
    minutes,
    seconds,
  };
};
