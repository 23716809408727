/* eslint-disable max-len */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */

'use strict';

import React from 'react';
import autobind from 'autobind-decorator';
import classnames from 'classnames';
import config from 'react-global-configuration';
import Cookies from 'js-cookie';
import _ from 'lodash';
import ProjectEmpty from 'dashboard/images/project-empty.svg';
import { DocumentBox } from '../../containers/DocumentBox';
import InsideProjectEmpty from '../../containers/InsideProjectEmpty';
import DraggingArea from './components/DraggingArea';
import { chromeExtension } from '../../../utils/chromeExtension';
import * as localStorage from '../../../utils/localStorage';
import logger from '../../../utils/logger';
import { hasAccessToDocument } from '../../../utils/user';
import { fixSafariCacheReload, parseDomain } from '../../../utils/browser';
import { isItemIncluded } from '../../../controllers/actions/settings.actions';
import { SIGNUP_AUTO_REDIRECT_COOKIE_NAME } from '../../../constants/marketing.constants';
import { autoOpenDemoEditorGetCodeMode } from '../../../constants/onboarding.constants';
import routes from '../../../constants/routes.constants';
import { goToExternal } from '../../../utils/router';
import { projectsStatuses } from '../../../constants/projects.constants';

export default class InsideProjectList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // eslint-disable-next-line react/no-unused-state
      currentDocument: {},
      projectListLoadCompleted: false,
    };

    this.conversionStatusTimeout = null;
  }

  @autobind
  openModalboxShare(e, document) {
    e.preventDefault();
    this.props.openModalboxShare(document);
  }

  @autobind
  renameDocument(idDocument, name) {
    const { renameDocument } = this.props;
    renameDocument(idDocument, name);
  }

  @autobind
  moveDocument(idDocument, idProject) {
    const { moveDocument } = this.props;
    moveDocument(idDocument, idProject);
  }

  @autobind
  removeDocument(idDocument) {
    const { removeDocument } = this.props;
    removeDocument(idDocument);
  }

  forceGenerateDocumentCovers(documents) {
    Promise.all(this.getDocumentsWithWrongCovers(documents)).then((data) => {
      const dataToSend = _.without(data, null).map((doc) => ({
        idDocument: doc.idDocument,
        idMainPage: doc.idMainPage,
      }));

      if (dataToSend.length) {
        this.props.forceGenerateDocumentCovers(dataToSend);
      }
    });
  }

  getDocumentsWithWrongCovers(documents = []) {
    return documents.map((doc) => new Promise((resolve) => {
      if (!doc.cover && _.isNumber(doc.idDocument) && _.isNumber(doc.idMainPage)) {
        resolve(doc);
        return;
      }

      if (!doc.idMainPage) {
        resolve(null);
        return;
      }

      let img = new Image();
      img.onload = () => {
        resolve(null);
        img = null;
      };

      img.onerror = () => {
        resolve(doc);
        img = null;
      };

      img.src = doc.cover;
    }));
  }

  getUnconvertedDocumentsIds(documents = []) {
    return documents
      .filter((doc) => doc.isConverted === false)
      .map((doc) => doc.idDocument)
      .sort();
  }

  autoOpenDemoPrototype(documents) {
    documents.forEach((document) => {
      if (document?.autoOpenPrototype && document?.status === projectsStatuses.ACTIVE) {
        const {
          domain,
        } = parseDomain(window.location.hostname);
        const url = `${config.get('APP_URL')}/${routes.EDITOR}/${document.idDocument}${autoOpenDemoEditorGetCodeMode}`;

        Cookies.remove(SIGNUP_AUTO_REDIRECT_COOKIE_NAME, { path: '/', domain });
        goToExternal(url);
      }
    });
  }

  checkConversionStatus(unconvertedDocuments) {
    const { checkConversionStatus } = this.props;
    clearTimeout(this.conversionStatusTimeout);
    this.conversionStatusTimeout = setTimeout(() => checkConversionStatus(unconvertedDocuments), 1000);
  }

  componentWillMount() {
    this.getDocumentsFromChromeExtension();
  }

  componentDidMount() {
    const { documents } = this.props;
    const unconvertedDocuments = this.getUnconvertedDocumentsIds(documents);

    if (unconvertedDocuments.length) {
      this.checkConversionStatus(unconvertedDocuments);
    }

    this.forceGenerateDocumentCovers(documents);
  }

  componentDidUpdate(prevProps) {
    const { documents } = this.props;
    const prevUnconvertedDocuments = this.getUnconvertedDocumentsIds(prevProps.documents);
    const unconvertedDocuments = this.getUnconvertedDocumentsIds(documents);

    if (Cookies.get(SIGNUP_AUTO_REDIRECT_COOKIE_NAME)) {
      this.autoOpenDemoPrototype(documents);
    }

    if (unconvertedDocuments.length && !_.isEqual(unconvertedDocuments, prevUnconvertedDocuments)) {
      this.checkConversionStatus(unconvertedDocuments);
    }

    const prevUploadingFilesLength = prevProps.documents.filter((item) => item.isFilesUploading).length;
    const currentUploadingFilesLength = this.props.documents.filter((item) => item.isFilesUploading).length;

    const prevConvertedFilesLength = prevProps.documents.filter((item) => item.isConverted).length;
    const currentConvertedFilesLength = this.props.documents.filter((item) => item.isConverted).length;

    if (
      prevUploadingFilesLength !== currentUploadingFilesLength
      || prevConvertedFilesLength !== currentConvertedFilesLength
    ) {
      this.forceGenerateDocumentCovers(documents);
    }
  }

  @autobind
  setDocumentWorkingState(idDocument) {
    this.props.setDocumentWorkingState(idDocument);
  }

  @autobind
  onChromeExtensionMessage(data, port) {
    const { createDocumentByChromeExtension } = this.props;
    logger.log('[CHROME EXTENSION] onMessage', data, port);
    switch (data.action) {
      case 'uploadComplete':
        return this.onChromeExtensionUploadCompleted(data.data);

      case 'removed':
        return this.onChromeExtensionUploadRemoved(data.file);

      // no default
    }

    if (!data.files) {
      return;
    }

    _.each(data.files, (file, fileId) => {
      try {
        const savedFilesNames = localStorage.load('files') || {};
        createDocumentByChromeExtension(fileId, savedFilesNames[fileId] || file.$localDesc.title, file.$localDesc.createdAt, port);
      } catch (e) {
        if (window.Rollbar) {
          window.Rollbar.error(e);
        }
      }
    });
  }

  @autobind
  onChromeExtensionUploadCompleted(data) {
    const { updateDocumentAfterChromeExtensionUpload } = this.props;
    const savedFilesNames = localStorage.load('files') || {};
    const fileId = data.fileData.desc.$localDesc.id;
    logger.log('[CHROME EXTENSION] upload completed', fileId, savedFilesNames[fileId], data.uxpinData.item);
    updateDocumentAfterChromeExtensionUpload(fileId, savedFilesNames[fileId], data.uxpinData.item);
  }

  @autobind
  onChromeExtensionUploadRemoved(file) {
    const { removeDocumentPermanently } = this.props;
    logger.log('[CHROME EXTENSION] discard completed', file);
    removeDocumentPermanently(file);
  }

  discardChromeExtensionUpload(port, fileId) {
    logger.log('[CHROME EXTENSION] post discard', { action: 'discard', file: fileId });
    port.postMessage({ action: 'discard', file: fileId });
  }

  @autobind
  startChromeExtensionUpload(port, fileId) {
    logger.log('[CHROME EXTENSION] start upload', { action: 'upload', file: fileId });
    port.postMessage({ action: 'upload', file: fileId });
    const { startChromeExtensionUpload } = this.props;
    startChromeExtensionUpload(fileId);
  }

  getDocumentsFromChromeExtension() {
    const { project } = this.props;
    chromeExtension.onMessage(this.onChromeExtensionMessage);
    logger.log('[CHROME EXTENSION] get files', { action: 'getFiles', id_collection: project.idProject });
    chromeExtension.postMessage({ action: 'getFiles', id_collection: project.idProject });
  }

  renderProjectsList() {
    const {
      documents,
      createDocument,
      project,
      isDocumentAdding,
      currentAddedDocument,
      openBrowserFilePicker,
      workingDocumentId,
      isDocumentUploadEnabled,
      favsPrototypes,
      filterType,
    } = this.props;

    if (!documents.length && !hasAccessToDocument(this.props)) {
      return (
        <div className="parked-account-view">
          <figure>

            <img src={ProjectEmpty} alt="Project empty" width="110" height="77" />
          </figure>
          <h2 className="inside-list-header">This project is empty.</h2>
        </div>
      );
    }

    if (!documents.length) {
      return (
        <InsideProjectEmpty
          isDocumentAdding={isDocumentAdding}
          currentAddedDocument={currentAddedDocument}
          openBrowserFilePicker={openBrowserFilePicker}
          project={project}
          createDocument={createDocument}
          isDocumentUploadEnabled={isDocumentUploadEnabled}
        />
      );
    }

    const filteredPrototypes = documents.filter((document) => isItemIncluded(document.idDocument, favsPrototypes, filterType));

    return (
      <div className={this.getDocumentsListClasses()}>
        {filteredPrototypes.map((document, id) => {
          const isDocumentWorking = workingDocumentId === document.idDocument;

          return (
            <DocumentBox
              document={document}
              // eslint-disable-next-line react/no-array-index-key
              key={id}
              projectsList={this.props.projectsList}
              openModalboxShare={(e) => this.openModalboxShare(e, document)}
              startChromeExtensionUpload={this.startChromeExtensionUpload}
              discardChromeExtensionUpload={this.discardChromeExtensionUpload}
              setDocumentWorkingState={this.setDocumentWorkingState}
              isDocumentWorking={isDocumentWorking}
              renameDocument={this.renameDocument}
            />
          );
        })}
      </div>
    );
  }

  getDocumentsListClasses() {
    return classnames('projects-list', {
      'move-up': this.props.documents.length > 2,
    });
  }

  render() {
    const {
      isDocumentUploadEnabled,
      isSketchUploadAllowed,
      setProjectListLoadCompleted,
    } = this.props;
    const { projectListLoadCompleted } = this.state;

    if (!projectListLoadCompleted) {
      setProjectListLoadCompleted();
      this.setState({ projectListLoadCompleted: true });
    }
    fixSafariCacheReload();

    return (
      <section id="inside-project-list">
        {this.renderProjectsList()}
        <div className="dragging-overlay">
          <DraggingArea
            dropCopy="Drop files here to upload"
            htmlTag="span"
            isDocumentUploadEnabled={isDocumentUploadEnabled}
            isSketchUploadAllowed={isSketchUploadAllowed}
          />
        </div>
      </section>
    );
  }
}
