/* eslint-disable react/destructuring-assignment */

'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import autobind from 'autobind-decorator';
import _ from 'lodash';
import { isMac } from '@uxpin/shared-components';

export default class PluginDownloadButtons extends React.Component {
  closeModal() {
    const { onClose } = this.props;

    if (_.isFunction(onClose)) {
      onClose();
    }
  }

  @autobind
  downloadPhotoshopPlugin() {
    if (isMac) {
      window.location.href = 'https://files.uxpin.com/plugins/photoshop/current/UXPinGenerator.pkg';
    } else {
      window.location.href = 'https://files.uxpin.com/plugins/photoshop/current/UXPinGenerator.zip';
    }

    this.closeModal();
  }

  @autobind
  downloadSketchPlugin() {
    window.location.href = 'https://files.uxpin.com/plugins/sketch/current/UXPin.sketchplugin.zip';

    this.closeModal();
  }

  render() {
    return (
      <ul className="btns-group">
        <li>
          <button
            onClick={this.downloadPhotoshopPlugin}
            className={`btn-ghost blue ${this.props.btnClass}`}
            type="button">
            {this.props.btnCopy.photoshop}
          </button>
        </li>
        <li>
          <button
            onClick={this.downloadSketchPlugin}
            className={`btn-ghost blue ${this.props.btnClass}`}
            type="button">
            {this.props.btnCopy.sketch}
          </button>
        </li>
      </ul>
    );
  }
}

PluginDownloadButtons.defaultProps = {
  btnClass: '',
  btnCopy: {
    photoshop: 'Photoshop',
    sketch: 'Sketch',
  },
};

PluginDownloadButtons.propTypes = {
  btnClass: PropTypes.string,
  btnCopy: PropTypes.object,
  // eslint-disable-next-line react/require-default-props
  onClose: PropTypes.func,
};
