import { Button, SCALE, WEIGHTS } from '@uxpin/design-system';
import PropTypes from 'prop-types';
import React from 'react';
import config from 'react-global-configuration';
import * as typeformEmbed from '@typeform/embed';
import { onMergeRequestSubmit } from '../../containers/MergeLibrarySetting';
import { getImagePath } from '../../../utils/url';

export class OnboardingTypeformSurvey extends React.Component {

  handleSubmission = (responseId) => {
    const { setOnboardingFlowAsSeen } = this.props;

    onMergeRequestSubmit(responseId);
    setOnboardingFlowAsSeen();
  }

  openForm = () => {
    const { currentUserData } = this.props;
    const ONBOARDING_TYPEFORM_ID = config.get('ONBOARDING_TYPEFORM_ID');
    const typeform = this.setupTypeForm(
      currentUserData,
      ONBOARDING_TYPEFORM_ID,
      (response) => this.handleSubmission(response.response_id)
    );

    typeform.open();
  }

  setupTypeForm = (currentUserData, formId, onSubmit) => {
    const { email, idActiveAccount, id } = currentUserData;
    const typeformData = `${formId}#email=${email}&id_account=${idActiveAccount}&id_user=${id}`;

    return typeformEmbed.createPopup(typeformData, { onSubmit });
  }

  render() {
    const { setOnboardingFlowAsSeen } = this.props;

    return (
      <section id="onboarding-merge">
        <h1 className="title">👋 Using a component library?</h1>
        <p className="text">
          How about bringing it into UXPin? Fill out a survey and <strong>book a walkthrough</strong> on<br />
          <strong>integrating your React or Storybook</strong> component library.
        </p>
        <img
          className="splash-image"
          src={getImagePath('onboarding/merge/splash.png')}
          alt="Merge Splash Survey"
          width={695}
          height={170}
        />
        <div className="buttons-wrapper">
          <Button
            className="button"
            onClick={setOnboardingFlowAsSeen}
            weight={WEIGHTS.LIGHT}
            size={SCALE.L}>Skip
          </Button>
          <Button
            className="button"
            onClick={this.openForm}
            size={SCALE.L}>Take the survey
          </Button>
        </div>
      </section>
    );
  }
}

OnboardingTypeformSurvey.propTypes = {
  showTypeformSurvey: PropTypes.bool.isRequired,
  setOnboardingFlowAsSeen: PropTypes.func.isRequired,
  currentUserData: PropTypes.object.isRequired,
};
