/* eslint-disable react/prop-types */

'use strict';

import React from 'react';
import ProjectSelect from './components/ProjectSelect';
import ProjectStatus from './components/ProjectStatus';
import { ViewModes } from '../../containers/ViewModes';
import { SearchBtn } from '../../containers/SearchBtn';
import { Sort } from '../../containers/DocumentSort';
import { StarFilter } from '../../containers/StarFilter';

export default class InsideProjectBar extends React.Component {
  renderProjectStatus() {
    const {
      project, currentStatus, currentStage, canAddEditDeleteProjectStatus,
    } = this.props;

    if (project.hasProgressInfo) {
      return (
        <li className="option-element status">
          <ProjectStatus
            project={project}
            canAddEditDeleteProjectStatus={canAddEditDeleteProjectStatus}
            currentStatus={currentStatus.name}
            currentStage={currentStage.name}
            currentStatusId={currentStatus.idStatus}
            // eslint-disable-next-line react/destructuring-assignment
            openProjectSettings={this.props.openProjectSettings}
          />
        </li>
      );
    }
  }

  renderSort() {
    const { documentsCount } = this.props;

    if (documentsCount > 1) {
      return (
        <li className="option-element">
          <Sort />
        </li>
      );
    }
  }

  renderViewMode() {
    const { documentsCount } = this.props;

    if (documentsCount) {
      return (
        <li className="option-element">
          <ViewModes />
        </li>
      );
    }
  }

  renderStarFilter = () => (
    <li className="option-element">
      <StarFilter />
    </li>
  )

  render() {
    const { project, projectsList } = this.props;

    return (
      <div id="inside-project-bar">
        <div className="left-panel">
          <ProjectSelect
            defaultPosition="bottomRight"
            triggerType="click"
            focusFirstInput
            autoClose
            closeCloudOnDocumentClick
            project={project}
            itemsList={projectsList}
          />
          <div className="quick-actions">
            <SearchBtn />
          </div>
        </div>
        <div className="right-panel">
          <ul className="options-group">
            {this.renderStarFilter()}
            {this.renderSort()}
            {this.renderViewMode()}
            {this.renderProjectStatus()}
          </ul>
        </div>
      </div>
    );
  }
}
