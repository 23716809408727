/* eslint-disable react/destructuring-assignment */

'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

export default class Truncate extends React.Component {
  truncateText(maxLength) {
    let text = this.props.children;

    if (text.length > maxLength) {
      text = text.substring(0, maxLength);
      return `${text}...`;
    }

    return text;
  }

  shouldComponentUpdate(nextProps) {
    return Object.keys(this.props).some((propName) => !_.isEqual(this.props[propName], nextProps[propName]));
  }

  render() {
    const { maxLength } = this.props;

    return (
      <span>
        {this.truncateText(maxLength)}
      </span>
    );
  }
}

Truncate.propTypes = {
  maxLength: PropTypes.number.isRequired,
  children: PropTypes.string.isRequired,
};
