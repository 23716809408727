'use strict';

import { documentsActions } from '../../constants/documents.constants';

export function documentActionsReducer(state = {
  removingCounters: {},
  currentDocumentsActions: {},
  firstProject: {},
  errors: {},
}, action) {
  let documentActions;
  let removingCounters;
  let errors;
  switch (action.type) {
    case documentsActions.INIT_REMOVING_COUNTER:
      removingCounters = Object.assign({}, state.removingCounters);
      removingCounters[action.idDocument] = 20;
      return Object.assign({}, state, { removingCounters });
    case documentsActions.CLEAR_DOCUMENTS_ACTIONS:
      return {
        removingCounters: {},
        currentDocumentsActions: {},
        errors: {},
      };
    case documentsActions.COUNT_TIME_TO_REMOVE_DOCUMENT:
      removingCounters = Object.assign({}, state.removingCounters);
      removingCounters[action.idDocument] = state.removingCounters[action.idDocument] - 1;
      return Object.assign({}, state, { removingCounters });
    case documentsActions.REQUEST_ACTION_ON_DOCUMENT:
      documentActions = Object.assign({}, state.currentDocumentsActions);
      documentActions[action.idDocument] = action.actionOnDocument;
      return Object.assign({}, state, { currentDocumentsActions: documentActions });
    case documentsActions.SET_DOCUMENT_ERROR:
      errors = Object.assign({}, state.errors);
      errors[action.idDocument] = {
        tryAgain: action.tryAgain,
        cancel: action.cancel,
      };
      return Object.assign({}, state, { errors });
    case documentsActions.STOP_ACTION_ON_DOCUMENT:
      documentActions = Object.assign({}, state.currentDocumentsActions);
      delete documentActions[action.idDocument];
      removingCounters = Object.assign({}, state.removingCounters);
      delete removingCounters[action.idDocument];
      return Object.assign({}, state, {
        removingCounters,
        currentDocumentsActions: documentActions,
      });
    case documentsActions.FETCH_FIRST_PROJECT:
      return Object.assign({}, state, {
        firstProject: action.data,
      });
    default:
      return state;
  }
}
