'use strict';

import moment from 'moment';
import { save, load } from './localStorage';

export default {
  hoursSinceDate(dateTimeString) {
    if (dateTimeString === null) {
      return true;
    }

    const now = moment();
    const duration = moment.duration(now.diff(dateTimeString));

    return duration.asHours();
  },

  getBlockKey(actionId, userId) {
    return `uxp-${actionId}-${userId}-block`;
  },

  getBlockadeDate(actionId, userId) {
    return load(this.getBlockKey(actionId, userId));
  },

  setBlockadeDate(actionId, userId) {
    const date = moment().unix();

    save(this.getBlockKey(actionId, userId), date);
    return date;
  },

  isBlocked(actionId, userId, blockDelay) {
    const timestamp = this.getBlockadeDate(actionId, userId);

    if (timestamp === null) {
      return false;
    }

    const secondsSinceBlockade = Math.floor(moment().diff(moment.unix(timestamp)) / 1000);

    return secondsSinceBlockade < blockDelay;
  },
};
