/* eslint-disable import/no-named-as-default */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */

'use strict';

import React from 'react';
import autobind from 'autobind-decorator';
import { isEqual } from 'lodash';
import classnames from 'classnames';
import { cloudPositions, GLOBAL_ELEMENTS } from '@uxpin/shared-components';
import { DragSource, DropTarget } from 'react-dnd';

import UsersAccess from './UsersAccess';
import GroupActions from './GroupActions';
import GroupDeleted from './GroupDeleted';
import { projectsConstants } from '../../../../constants/projects.constants';
import ColorPicker from '../../ColorPicker/ColorPicker';
import * as SortableList from '../../../../utils/sortableList';
import { getUserNameFromMemberData } from '../../../../utils/account';
import { sortableItemsTypes } from '../../../../constants/sortableItems.constants';
import { groupTypes } from '../../../../constants/projectsGroupsManagement.constants';

export class Group extends React.Component {
  @autobind
  setColor(color) {
    const { index, setColor } = this.props;
    setColor(index, color);
  }

  @autobind
  onEdit() {
    const { index, onEdit } = this.props;
    onEdit(index);
  }

  getStyles() {
    const { data } = this.props;
    if (data.isDeleted) {
      return {
        display: 'none',
      };
    }
  }

  getClasses() {
    const { data, isDragging } = this.props;
    return classnames('item-wrapper editable-item', {
      smaller: (data.type === groupTypes.ARCHIVED || data.type === groupTypes.UNGROUPED),
      deleting: data.isBeingDeleted,
      dragging: isDragging,
      ungrouped: data.type === groupTypes.UNGROUPED,
      archived: data.type === groupTypes.ARCHIVED,
    });
  }

  renderGroupActions() {
    const {
      index, data, openGroupsDeleteModal, startDeleteGroup, canAddEditDeleteProjectGroups,
    } = this.props;

    if (data.type === groupTypes.ARCHIVED
        || data.type === groupTypes.UNGROUPED
      || !canAddEditDeleteProjectGroups) {
      return;
    }

    return (
      <aside className="item-actions">
        <GroupActions
          onEdit={this.onEdit}
          data={data}
          index={index}
          startDeleteGroup={startDeleteGroup}
          openGroupsDeleteModal={openGroupsDeleteModal}
        />
      </aside>
    );
  }

  renderColorPicker() {
    const { data, parent, canAddEditDeleteProjectGroups } = this.props;

    if (data.type === groupTypes.ARCHIVED || data.type === groupTypes.UNGROUPED) {
      return (
        <div className="groups-color-wrapper">
          <figure className="group-color-box">Archived/Ungrouped</figure>
        </div>
      );
    }

    return (
      <ColorPicker
        defaultPosition={cloudPositions.LEFT_BOTTOM}
        setColor={this.setColor}
        emitter={this.props.emitter}
        isClickActionBlocked={!canAddEditDeleteProjectGroups}
        colors={projectsConstants.GROUPS_COLORS}
        currentColor={data.color || projectsConstants.DEFAULT_COLOR}
        parent={parent}
      />
    );
  }

  getAccountMembers() {
    const { membersData } = this.props;

    return membersData
      .map((member) => {
        const name = getUserNameFromMemberData(member);

        return {
          id: member.id_user,
          name: name.trim(),
          avatar: member.avatar,
          firstname: member.firstname,
          lastname: member.lastname,
          email: member.email,
          role: member.role,
        };
      });
  }

  shouldComponentUpdate(nextProps) {
    return Object.keys(this.props).some((propName) => !isEqual(this.props[propName], nextProps[propName]));
  }

  render() {
    const {
      addSelectedUsersToGroup,
      countTime,
      index,
      deleteGroup,
      undoGroup,
      connectDragSource,
      connectDragPreview,
      connectDropTarget,
      data,
      currentUserId,
      canAddEditDeleteProjectGroups,
      parent,
    } = this.props;
    const groupNameClassNames = classnames('name', GLOBAL_ELEMENTS.HEADING_5);

    return connectDropTarget(connectDragPreview(
      <section style={this.getStyles()} className={this.getClasses()}>
        {connectDragSource(
          <i className="drag icon-general-dots-vertical only-icon-font">Drag&amp;Drop</i>,
          'move'
        )}
        <article className="item-data">
          <h5 className={groupNameClassNames}>{data.name}</h5>
          <UsersAccess
            autoFit
            focusFirstInput
            itemsList={this.getAccountMembers()}
            currentUserId={currentUserId}
            parent={parent}
            canAddEditDeleteProjectGroups={canAddEditDeleteProjectGroups}
            selectedUsers={data.selectedUsers}
            index={index}
            addSelectedUsersToGroup={addSelectedUsersToGroup}
          />
          {this.renderColorPicker()}
        </article>
        {this.renderGroupActions()}
        <GroupDeleted
          shouldStart={data.isBeingDeleted}
          deleteGroup={deleteGroup}
          countTime={countTime}
          undoGroup={undoGroup}
          groupName={data.name}
          time={data.time}
          index={index}
        />
      </section>
    ));
  }
}

export default DropTarget(sortableItemsTypes.GROUP, SortableList.itemTarget, SortableList.collectTarget)(
  DragSource(sortableItemsTypes.GROUP, SortableList.itemSource, SortableList.collectSource)(Group)
);
