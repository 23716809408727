import { connect } from 'react-redux';
import { MergeOnboardingWidget } from '../components/Marketing/MergeOnboardingWidget.react';

export const mapStateToProps = (state) => {
  const { enableMergeTutorial, projectListLoadCompleted } = state.settings;
  const { currentUser } = state;
  const mergeOnboardingVisible = enableMergeTutorial;

  return {
    projectListLoadCompleted,
    isMergeOnBoardingEnabled: mergeOnboardingVisible,
    userId: currentUser.data.id,
  };
};

export const WidgetMergeOnboardingTutorial = connect(mapStateToProps)(MergeOnboardingWidget);
