/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';

const ColorCover = (props) => {
  const { colors } = props;

  return (
    <figure className="colors-wrapper">
      {colors.map((color, i) => (<div key={i} className="color-item" style={{ backgroundColor: color }} />))}
    </figure>
  );
};

ColorCover.propTypes = {
  colors: PropTypes.array.isRequired,
};

export default ColorCover;
