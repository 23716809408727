/* eslint-disable no-param-reassign */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */

'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import { isFunction } from 'lodash';
import classNames from 'classnames';
import { GLOBAL_ELEMENTS } from '@uxpin/shared-components';
import events from '../../../../constants/event.constants';
import keys from '../../../../constants/keys.constants';

export default class SearchResultsSection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedItem: -1,
    };
    this.columnLength = props.searchSection.columnLength || 4;
    this.itemsLength = props.filteredElements.length;
    this.emitter = props.emitter;
    this.isNavigationOnKeyDown = false;
    this.lastSelectedElement = null;

    this.eventRefs = {
      // eslint-disable-next-line max-len
      handleNavigation: (selectedList, keyCode, lastItemIndex) => this.handleNavigation(selectedList, keyCode, lastItemIndex),
      handleMouseMove: (e) => this.onMouseMove(e),
      inactiveSection: (selectedList) => this.inactiveSection(selectedList),
    };
  }

  componentDidMount() {
    this.emitter.on(events.SEARCH_NAVIGATION_EVENT, this.eventRefs.handleNavigation);
    this.emitter.on(events.INACTIVE_SEARCH_SECTION_EVENT, this.eventRefs.inactiveSection);
  }

  componentWillUnmount() {
    this.emitter.off(events.SEARCH_NAVIGATION_EVENT, this.eventRefs.handleNavigation);
    this.emitter.off(events.INACTIVE_SEARCH_SECTION_EVENT, this.eventRefs.inactiveSection);
  }

  navigate(itemsLength, selectedItem) {
    const { activeLists, scrollToViewport, id } = this.props;
    const previousSelectedItem = selectedItem;
    const isLast = id === (activeLists.length - 1);
    selectedItem += itemsLength;

    if (selectedItem < 0) {
      selectedItem = -1;
      this.emitter.emit(events.PREV_SEARCH_SECTION);
    } else if (previousSelectedItem === this.itemsLength - 1 && selectedItem >= this.itemsLength && !isLast) {
      selectedItem = -1;
      this.emitter.emit(events.NEXT_SEARCH_SECTION);
    } else if ((selectedItem >= this.itemsLength && isLast) || (selectedItem >= this.itemsLength && !isLast)) {
      selectedItem = this.itemsLength - 1;
    }

    this.setState({
      selectedItem,
    }, () => {
      const highlightedNode = this.getHighlightedNode();

      if (highlightedNode) {
        scrollToViewport(highlightedNode);
      }
    });
  }

  onMouseMove(element) {
    if (!this.isNavigationOnKeyDown) {
      if (this.lastSelectedElement !== element) {
        const { id } = this.props;
        this.setState({
          selectedItem: Array.prototype.indexOf.call(this.refs.elementsList.children, element),
        });
        this.lastSelectedElement = element;
        this.emitter.emit(events.ACTIVE_SEARCH_SECTION_EVENT, id);
      }
    } else {
      this.lastSelectedElement = null;
    }

    this.isNavigationOnKeyDown = false;
  }

  getHighlightedNode() {
    return this.refs.elementsList.children[this.state.selectedItem];
  }

  inactiveSection(selectedList) {
    const { id } = this.props;
    if (id === selectedList) {
      this.setState({
        selectedItem: -1,
      });
    }

    this.lastSelectedElement = null;
  }

  handleNavigation(selectedList, keyCode, itemIndex) {
    const { searchSectionAction, filteredElements, id } = this.props;
    const selectedItem = this.state.selectedItem;
    this.isNavigationOnKeyDown = true;

    if (id === selectedList) {
      if (itemIndex) {
        this.navigate(0, itemIndex);
        return;
      }

      if (selectedItem < 0) {
        this.navigate(1, selectedItem);
        return;
      }

      switch (keyCode) {
        case keys.ARROW_RIGHT:
          this.navigate(1, selectedItem);
          return;

        case keys.ARROW_LEFT:
          this.navigate(-1, selectedItem);
          return;

        case keys.ARROW_DOWN:
          this.navigate(this.columnLength, selectedItem);
          return;

        case keys.ARROW_UP:
          this.navigate(-this.columnLength, selectedItem);
          return;
        case keys.ENTER:
          if (isFunction(searchSectionAction)) {
            searchSectionAction(filteredElements[this.state.selectedItem], {
              currentDocumentsActions: this.props.currentDocumentsActions,
              currentProjectsActions: this.props.currentProjectsActions,
            });
          }


        // no default
      }
    }
  }

  render() {
    const {
      searchSection, filteredElements, searchSectionAction, canAddEditDeleteApprovals,
    } = this.props;
    const children = [];
    const projectsListClassNames = classNames('projects-list', GLOBAL_ELEMENTS.UNORDERED_LIST);
    this.itemsLength = filteredElements.length;
    filteredElements.forEach((element, id) => {
      if (searchSection.component) {
        children.push(React.cloneElement(searchSection.component, {
          // eslint-disable-next-line react/no-array-index-key
          key: id,
          onMouseMove: this.eventRefs.handleMouseMove,
          action: searchSectionAction,
          data: element,
          isSelected: (this.state.selectedItem === id),
          canAddEditDeleteApprovals,
        }));
      }
    });

    return (
      <section className="search-results section-with-boxes">
        <h3>{searchSection.name}</h3>
        <ul ref="elementsList" className={projectsListClassNames}>
          {children}
        </ul>
      </section>
    );
  }
}

SearchResultsSection.propTypes = {
  id: PropTypes.number.isRequired,
  activeLists: PropTypes.array.isRequired,
  emitter: PropTypes.object.isRequired,
  filteredElements: PropTypes.array.isRequired,
  scrollToViewport: PropTypes.func.isRequired,
  // eslint-disable-next-line react/require-default-props
  searchSectionAction: PropTypes.func,
  searchSection: PropTypes.object.isRequired,
};
