/* eslint-disable prefer-promise-reject-errors */
/* global ga */
/* eslint-disable no-underscore-dangle */

'use strict';

import config from 'react-global-configuration';
import { isEmpty, merge } from 'lodash';
import logger from './logger';
import { request } from './request';


/**
 * Creates async script tag and attaches it to DOM
 *
 * @param {string} u uri of script src
 */
const attachScript = (u) => {
  setTimeout(() => {
    const d = document;
    const f = d.getElementsByTagName('script')[0];
    const s = d.createElement('script');

    s.type = 'text/javascript';
    s.defer = true;
    s.src = u;
    f.parentNode.insertBefore(s, f);
  }, 1);
};

let isClarityInitialized = false;

export const microsoftClarity = {

  data: {},

  initialize() {
    if (isClarityInitialized) {
      return;
    }

    const isProduction = window.location.host === 'app.uxpin.com';

    if (isProduction) {
      (function (c, l, a, r, i, t, y) {
        c[a] = c[a] || function () {
          (c[a].q = c[a].q || []).push(arguments)
        };
        t = l.createElement(r);
        t.async = 1;
        t.src = "https://www.clarity.ms/tag/" + i;
        y = l.getElementsByTagName(r)[0];
        y.parentNode.insertBefore(t, y);
      })(window, document, "clarity", "script", "e2d8hdo2dl");
    }

    isClarityInitialized = true;
  },

  identify(key, value) {
    if (window.clarity && !microsoftClarity.data['identify']) {
      window.clarity('identify', value);
      microsoftClarity.data['identify'] = value;
    }
  },

  set(key, value) {
    if (window.clarity && !microsoftClarity.data[key]) {
      window.clarity('set', key, value);
      microsoftClarity.data[key] = value;
    }
  }
}

export const adwords = {
  initialize() {
    return new Promise((resolve, reject) => {
      try {
        const adwordsId = config.get('ADWORDS_ID');

        if (!isEmpty(adwordsId)) {
          attachScript(`https://www.googletagmanager.com/gtag/js?id=AW-${adwordsId}`);

          window.dataLayer = window.dataLayer || [];
          window.gtag = function () {
            window.dataLayer.push(arguments); // eslint-disable-line prefer-rest-params
          };

          window.gtag('js', new Date());
          window.gtag('config', `AW-${adwordsId}`);
        }
        resolve();
      } catch (e) {
        reject(e);
      }
    });
  },
};

export const googleAnalytics = {
  /**
   * Loads Google Analytics scripts
   */
  initialize(...args) {
    const { GOOGLE_ANALYTICS_KEY } = config.get();

    window.ga = window.ga || function () {
      (ga.q = ga.q || []).push(args);
    };
    // there is no analytics for dev envs
    if (!GOOGLE_ANALYTICS_KEY) {
      return;
    }

    window.ga.l = +new Date();
    window.ga('create', GOOGLE_ANALYTICS_KEY, 'auto', { allowLinker: true });
    window.ga('require', 'linker');

    attachScript('//www.google-analytics.com/analytics.js');
  },

  dataLayerPush(eventData) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(eventData);
  },

  gtmInitialize(initData) {
    return new Promise((resolve, reject) => {
      try {
        const { GTM_ID } = config.get();

        if (initData) {
          googleAnalytics.dataLayerPush(initData);
        }

        if (!GTM_ID) {
          resolve();
          return;
        }
        /* eslint-disable */
        (function (w, d, s, l, i) {
          w[l] = w[l] || [];
          w[l].push({
            'gtm.start': new Date().getTime(), event: 'gtm.js'
          });

          var f = d.getElementsByTagName(s)[0], j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
          j.defer = true;
          j.src = '//www.googletagmanager.com/gtm.js?id=' + i + dl;
          j.onload = resolve;
          f.parentNode.insertBefore(j, f);
        })(window, document, 'script', 'dataLayer', GTM_ID);
        /* eslint-enable */
      } catch (e) {
        reject(e);
      }
    });
  },

  /**
   * Sends Google Analytics event
   *
   * @param {string} category
   * @param {string} event
   * @param {string} label
   * @param {string} value
   * @returns {Promise}
   */
  gaEvent(category, event, label, value) {
    return new Promise((resolve, reject) => {
      try {
        window.ga('send', 'event', category, event, label, value, {
          hitCallback: resolve,
        });
        logger.info('[GA]', category, event, label, value);
      } catch (e) {
        reject(e);
      }
    });
  },

  /**
   * Sets GA userId and account status
   * dimension4 and 5 is const based on GA settings
   *
   * @param {int} id
   * @param {string} accountStatus
   * @returns {Promise}
   */
  customDimensionsPush(id, accountStatus) {
    return new Promise((resolve, reject) => {
      try {
        window.dataLayer = window.dataLayer || [];
        const customDimensionData = {
          userId: id,
          dimension4: accountStatus,
          dimension5: accountStatus,
          dimension6: id,
        };
        window.dataLayer.push(customDimensionData);
        logger.info('[GTM datalayer push]', customDimensionData);
        resolve();
      } catch (e) {
        reject(e);
      }
    });
  },

  getGtmIdsObject(userId, ownerId, accountId) {
    return {
      visitorId: userId,
      ownerId,
      orgId: accountId,
    };
  },

  getGtmAccountDataObject(currentAccountLevel, numberOfProjects, firstTimeLogin, accountType) {
    return {
      currentAccountLevel,
      numberOfProjects,
      firstTimeLogin,
      accountType,
    };
  },

  dataLayerLoginEvent(userId, ownerId, accountId, accountLevel, projectsCount, firstTime) {
    return new Promise((resolve, reject) => {
      try {
        const accountInfo = googleAnalytics.getGtmAccountDataObject(accountLevel, projectsCount, firstTime);
        const accountIds = googleAnalytics.getGtmIdsObject(userId, ownerId, accountId);
        const eventData = merge({
          event: 'login',
          eventCallback: resolve.bind(this),
          eventTimeout: 3000,
        }, accountIds, accountInfo);

        googleAnalytics.dataLayerPush(eventData);
      } catch (e) {
        reject(e);
      }
    });
  },

  dataLayerRegisterEvent(userId, ownerId, accountId) {
    return new Promise((resolve, reject) => {
      try {
        const accountIds = googleAnalytics.getGtmIdsObject(userId, ownerId, accountId);
        const eventData = merge({
          event: 'trial_signups',
          eventCategory: 'trial',
          eventAction: 'signups',
          eventLabel: 'direct',
          eventCallback: resolve,
          eventTimeout: 3000,
        }, accountIds);

        googleAnalytics.dataLayerPush(eventData);
      } catch (e) {
        reject(e);
      }
    });
  },
};
