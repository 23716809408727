'use strict';

import _ from 'lodash';
import { connect } from 'react-redux';
import { projects } from '../../controllers/actions/projects.actions';
// eslint-disable-next-line import/no-named-as-default
import ProjectActionsCloud from '../components/ProjectsList/components/ProjectActionsCloud';
import * as modal from '../../controllers/actions/modal.actions';
import { modalsIds, limitsContentTypes } from '../../constants/modal.constants';
import { getActiveProjectsCount } from '../../utils/projects';
import { isAccountParked, isFreePlanType } from '../../utils/account';

export const mapStateToProps = (state) => {
  const { items } = state.projects;
  const { accountPlan } = state.accountSettings;
  const { data } = state.currentUser;
  const { totalDocumentsCount } = state.insideProject;

  const isFreePlan = isFreePlanType(accountPlan);

  return {
    isAccountParked: isAccountParked(data.idActiveAccount, data.accounts),
    isLimitExceeded: !!(!accountPlan || (accountPlan && accountPlan.projects <= getActiveProjectsCount(items))),
    allProjects: items.map((item) => {
      const newItem = Object.assign({}, item);
      delete newItem.projects;
      return newItem;
    }),
    features: state.features,
    totalDocumentsCount,
    isFreePlan,
  };
};

export const mapDispatchToProps = (dispatch) => ({
  archiveProject: (idProject) => {
    dispatch(projects.archiveProject(idProject));
  },

  changeGroup: (idProject, idProjectGroup, hasProjectGroup, groupColor, forceChangingGroup) => {
    dispatch(projects.changeGroup(idProject, idProjectGroup, hasProjectGroup, groupColor, forceChangingGroup));
  },

  unarchiveAndChangeGroup: (idProject, idProjectGroup) => {
    dispatch(projects.unarchiveAndChangeGroup(idProject, idProjectGroup));
  },

  unarchiveProject: (idProject, idProjectGroup) => {
    dispatch(projects.unarchiveProject(idProject, idProjectGroup));
  },

  removeProject: (idProject) => {
    dispatch(projects.removeProject(idProject));
  },

  renameProject: (idProject, name) => {
    dispatch(projects.renameProject(idProject, name));
  },

  duplicateProject: (idProject) => {
    dispatch(projects.duplicateProject(idProject));
  },

  unarchiveAndRemoveProjectFromGroup: (idProject) => {
    dispatch(projects.unarchiveAndRemoveProjectFromGroup(idProject));
  },

  removeProjectFromGroup: (idProject) => {
    dispatch(projects.removeProjectFromGroup(idProject));
  },

  openModalboxProjectChangeGroup: (projectData, actions) => {
    const data = {
      projectData,
      actions,
    };

    dispatch(modal.openModal(modalsIds.MODALBOX_PROJECT_CHANGE_GROUP, data));
  },

  openModalboxProjectDelete: (projectData, actions, allowArchive) => {
    const data = {
      projectData,
      actions,
      allowArchive,
    };
    dispatch(modal.openModal(modalsIds.MODALBOX_PROJECT_DELETE, data));
  },

  openModalboxProjectChangeName: (project, actions) => {
    const data = {
      project,
      actions,
    };

    dispatch(modal.openModal(modalsIds.MODALBOX_PROJECT_CHANGE_NAME, data));
  },

  openModalboxGeneralMessage: (title, message, onAccept, onClose) => {
    const onCloseCallback = () => {
      dispatch(modal.closeModal(modalsIds.MODALBOX_GENERAL_MESSAGE));
      if (_.isFunction(onClose)) {
        onClose();
      }
    };

    const onAcceptCallback = () => {
      dispatch(modal.closeModal(modalsIds.MODALBOX_GENERAL_MESSAGE));
      if (_.isFunction(onAccept)) {
        onAccept();
      }
    };

    dispatch(modal.openModal(modalsIds.MODALBOX_GENERAL_MESSAGE, {
      title,
      message,
      onAccept: onAcceptCallback,
      onClose: onCloseCallback,
    }));
  },

  showUnarchivePrototypesLimitsModal: () => {
    dispatch(modal.openModal(modalsIds.LIMITS_EXCEEDED, {
      forcedContentType: limitsContentTypes.PROTOTYPE_UNARCHIVE_LIMIT,
    }));
  },

  showAddPrototypesLimitsModal: () => {
    dispatch(modal.openModal(modalsIds.LIMITS_EXCEEDED, {
      forcedContentType: limitsContentTypes.PROTOTYPE_ADD_LIMIT,
    }));
  },
});

export const ProjectActions = connect(mapStateToProps, mapDispatchToProps)(ProjectActionsCloud);
