'use strict';

export const passwordMinCharCount = 6;

export const onboardingAddUsersActions = {
  ONBOARDING_ADD_USERS_REQUEST: 'ONBOARDING_ADD_USERS_REQUEST',
  ONBOARDING_ADD_USERS_FAILURE: 'ONBOARDING_ADD_USERS_FAILURE',
  ONBOARDING_ADD_USERS_SUCCESS: 'ONBOARDING_ADD_USERS_SUCCESS',
};

export const onboardingStartSteps = {
  URL_FORM: 'URL_FORM',
  PATH_CHOICE: 'PATH_CHOICE',
};

export const onboardingSteps = {
  PASSWORD: 'PASSWORD',
  TOOLS: 'TOOLS',
  POSITION: 'POSITION',
  ADD_USERS: 'ADD_USERS',
  TYPEFORM_SURVEY: 'TYPEFORM_SURVEY',
};

const { PASSWORD, TOOLS, POSITION, ADD_USERS, TYPEFORM_SURVEY } = onboardingSteps;

export const onboardingFlowSteps = {
  [PASSWORD]: 0,
  [TOOLS]: 0,
  [POSITION]: 0,
  [ADD_USERS]: 0,
  [TYPEFORM_SURVEY]: 0,
};

export const onboardingFlowSettings = {
  ONBOARDING_STEP: 'onboardingStep', // used to mark seen steps
  ONBOARDING_FLOW: 'onboardingFlow', // used to mark completed onboarding
};

export const mergeTutorialPopup = 'MERGE_TUTORIAL_POPUP';
export const projectListLoadCompleted = 'PROJECT_LIST_LOAD_COMPLETED';

export const autoOpenDemoEditorGetCodeMode = '#?mode=l';
