/* eslint-disable max-len */

import React from 'react';
import PropTypes from 'prop-types';
import config from 'react-global-configuration';
import { Link } from '@uxpin/design-system';
import { isDesktopApp } from '@uxpin/shared-components';
import { getViewUri } from '../../../../controllers/helpers/designSystems.helper';
import FooterDsIcon from 'dashboard/images/design-systems/footer-ds-icon.svg';
import FooterDsTutorial from 'dashboard/images/design-systems/footer-ds-tutorial.svg';

const DesignSystemsFooter = (props) => {
  const { BASE_URL } = config.get();
  // eslint-disable-next-line react/destructuring-assignment
  const { libraryHash } = props.templateData;
  const demoLibraryHash = 'cd2e98128c0abaf92135'; // Bootstrap library on production
  const demoLink = libraryHash ? getViewUri(libraryHash) : getViewUri(demoLibraryHash);

  return (
    <ul className="design-system-footer">
      <li>
        <figure>
          <figcaption>
            <h3>Design System Demo</h3>
            <p>Check out the example of a complete<br /> Design System built solely in UXPin.</p>
            <Link
              href={demoLink}
              rel="noopener noreferrer"
              target="_blank"
              openInBrowser={isDesktopApp}>
              See the demo
            </Link>
          </figcaption>
          <img className="item-icon" src={FooterDsIcon} width="110" alt="Design Systems" />
        </figure>
      </li>
      <li>
        <figure>
          <figcaption>
            <h3>How to create a design system</h3>
            <p>A step-by-step guide for building<br />a design system in UXPin.</p>
            <Link
              href="https://www.uxpin.com/community/tutorials/design-systems/"
              rel="noopener noreferrer"
              target="_blank">
              Read tutorial
            </Link>
          </figcaption>
          <img className="item-icon" src={FooterDsTutorial} width="109" alt="Design Systems Tutorial" />
        </figure>
      </li>
    </ul>
  );
};

DesignSystemsFooter.propTypes = {
  templateData: PropTypes.object.isRequired,
};

export default DesignSystemsFooter;
