import { connect } from 'react-redux';
// eslint-disable-next-line import/no-named-as-default
import ProjectBoxActionsCloud from '../components/InsideProjectList/components/ProjectBoxActionsCloud';
import { isFreePlanType } from '../../utils/account';
import { openModal } from '../../controllers/actions/modal.actions';
import { modalsIds, limitsContentTypes } from '../../constants/modal.constants';

export const mapStateToProps = (state) => {
  const { totalDocumentsCount } = state.insideProject;
  const { accountPlan } = state.accountSettings;

  const isFreePlan = isFreePlanType(accountPlan);

  return {
    totalDocumentsCount,
    isFreePlan,
  };
};

export const mapDispatchToProps = (dispatch) => ({
  showAddPrototypesLimitsModal: () => {
    dispatch(openModal(modalsIds.LIMITS_EXCEEDED, {
      forcedContentType: limitsContentTypes.PROTOTYPE_ADD_LIMIT,
    }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectBoxActionsCloud);
