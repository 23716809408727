/* eslint-disable max-len */

'use strict';

import React from 'react';
import {
  Cloud, cloudPositions, FilterListCloud, GLOBAL_ELEMENTS, SearchForm,
} from '@uxpin/shared-components';
import _ from 'lodash';
import autobind from 'autobind-decorator';
import classnames from 'classnames';
import { goTo } from '../../../../utils/router';
import routes from '../../../../constants/routes.constants';

@Cloud({
  availablePositions: [cloudPositions.BOTTOM_RIGHT],
  offset: {
    bottomRight: { x: 0, y: 10 },
  },
})
export default class ProjectSelect extends FilterListCloud {
  constructor(props) {
    super(props);
    this.isMaxHeightSet = true;
    this.MARGIN = 60;
    this.isSearchUsed = false;
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.itemsList.length && nextProps.itemsList.length || this.props.project.idProject !== nextProps.project.idProject) {
      this.setState({
        items: nextProps.itemsList.filter((item) => {
          if (item.idProject !== this.props.project.idProject) {
            return true;
          }

          return false;
        }),
      });
    }
  }

  @autobind
  handleMouseDown(event) {
    event.nativeEvent.stopImmediatePropagation();
  }

  @autobind
  openProject(e, project) {
    e.preventDefault();
    e.nativeEvent.stopImmediatePropagation();
    goTo(`${routes.PROJECT}/${project.idProject}`);
  }

  getProjectNameClasses() {
    return classnames(
      'name',
      'icon-general-chevron',
      GLOBAL_ELEMENTS.HEADING_2,
      {
        active: this.props.isOpen,
        'single-project': this.props.itemsList.length < 2,
      }
    );
  }

  renderGroupColor(project) {
    const color = project.groupColor ? `#${project.groupColor}` : '';
    const groupName = project.groupName || 'Ungrouped';

    return (<span className="group-color-box" style={this.getStyles(color)} title={groupName} />);
  }

  getStyles(color) {
    return color ? { backgroundColor: color } : null;
  }

  renderProjectList() {
    const items = this.state.items.length;

    if (!items) {
      return (
        <p className="no-found custom-color"><em>No projects found</em></p>
      );
    }

    const itemsSorted = _.sortBy(this.state.items, (item) => item.name.toLowerCase());

    return (
      // eslint-disable-next-line no-return-assign
      <ul ref={(ref) => this.listEl = ref} className="options-list scroll-ready">
        {itemsSorted.map((project) => this.renderListItem(project))}
      </ul>
    );
  }

  renderListItem(project) {
    const className = classnames({ ungrouped: !project.groupColor });

    return (
      <li key={project.idProject} data-test={`project-list-item-${project.name}`}>
        <a
          className={className}
          href={`#project-${project.idProject}`}
          onClick={(e) => this.openProject(e, project)}
          onMouseDown={this.handleMouseDown}>
          {this.renderGroupColor(project)}
          <span title={project.name}>{project.name}</span>
        </a>
      </li>
    );
  }

  @autobind
  onSearchBlur() {
    this.isSearchUsed = false;
  }

  @autobind
  filterItems() {
    this.isSearchUsed = true;
    super.filterItems();
  }

  render() {
    const { project } = this.props;

    return (
      <div className="project-name-select" data-test="project-name-select">
        <h2 {...this.props.trigger} className={this.getProjectNameClasses()} data-show-node="cloud">{project.name}</h2>
        <div {...this.props.cloud} className="cloud options-cloud with-icons project-name-select-cloud permissions-cloud auto-width">
          <SearchForm
            ref={this.setSearchForm}
            onBlur={this.onSearchBlur}
            label="Search for the project..."
            filterElements={this.filterItems}
          />
          {this.renderProjectList()}
        </div>
      </div>
    );
  }
}

ProjectSelect.defaultProps = {
  itemsList: [],
  project: {},
};
