/* eslint-disable max-len,react/button-has-type */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */

'use strict';

import React from 'react';
import { Modal, StringUtils } from '@uxpin/shared-components';
import { APPEARANCES, Button, Link } from '@uxpin/design-system';
import autobind from 'autobind-decorator';
import _ from 'lodash';
import keys from '../../../constants/keys.constants';

export default class ModalboxProjectChangeName extends React.Component {
  constructor(props) {
    super(props);
    this.MODAL = {
      WRAPPER_ID: 'project-change-name-modal-box',
      CLASS_NAMES: ['modal-box-medium-width'],
      TITLE: 'Change Project Name',
    };
    this.state = {
      value: '',
    };
  }

  @autobind
  onOpen() {
    const { data: { name } } = this.props;
    this.setState({
      value: name,
    });

    setTimeout(() => {
      this.refs.input.focus();
    }, 50);
  }

  @autobind
  onClose(e) {
    const { onClose } = this.props;
    e.preventDefault();
    onClose();
    this.isChanged = false;
  }

  save() {
    const { actions, onClose, data } = this.props;
    const valueTrimmed = StringUtils.reduceWhiteSpaces(this.state.value).trim();

    if (_.isFunction(actions.handleRenameProject) && valueTrimmed !== data.name) {
      actions.handleRenameProject(valueTrimmed);
    }

    onClose();
    this.isChanged = false;
  }

  onKeyDown(e) {
    if (e.keyCode === keys.ENTER && !this.isEmpty()) {
      this.save();
    }
  }

  handleChange(e) {
    this.isChanged = true;

    this.setState({ value: e.target.value });
  }

  isEmpty() {
    return !this.state.value.trim().length;
  }

  shouldComponentUpdate(nextProps, nextState) {
    return Object.keys(this.props).some((propName) => !_.isEqual(this.props[propName], nextProps[propName]))
      || Object.keys(this.state).some((stateName) => !_.isEqual(this.state[stateName], nextState[stateName]));
  }

  render() {
    return (
      <Modal
        wrapperId={this.MODAL.WRAPPER_ID}
        classNames={this.MODAL.CLASS_NAMES}
        title={this.MODAL.TITLE}
        isOpen={this.props.isOpen}
        onOpen={this.onOpen}
        onClose={this.onClose}>
        <section className="modal-box-content">
          <fieldset>
            <label htmlFor="project-name" className="block-label">Project Name</label>
            <input ref="input" id="project-name" type="text" onKeyDown={this.onKeyDown.bind(this)} value={this.state.value} onChange={this.handleChange.bind(this)} />
          </fieldset>
        </section>
        <footer>
          <ul className="btns-group">
            <li>
              {/* eslint-disable-next-line react/jsx-no-bind */}
              <Link appearance={APPEARANCES.NEUTRAL} href="#cancel" onClick={this.onClose.bind(this)}>Cancel</Link>
            </li>
            <li>
              {/* eslint-disable-next-line react/jsx-no-bind */}
              <Button onClick={this.save.bind(this)} isDisabled={this.isEmpty()}>Save</Button>
            </li>
          </ul>
        </footer>
      </Modal>
    );
  }
}
