'use strict';

import { connect } from 'react-redux';
import FreemiumBar from '../../components/InfoBars/FreemiumBar';
import * as upgradeModal from '../../../controllers/actions/upgradeModal.actions';

export const mapStateToProps = (state) => {
  const { accountPlan } = state.accountSettings;

  return {
    accountPlan,
  };
};

export const mapDispatchToProps = (dispatch) => ({
  openUpgradeModal: () => {
    dispatch(upgradeModal.openUpgradeModal());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(FreemiumBar);
