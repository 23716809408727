import { connect } from 'react-redux';
import ProjectSearchComponent from '../components/Search/components/ProjectSearch';
import { modalsIds } from '../../constants/modal.constants';
import * as projectActions from '../../controllers/actions/projectActions.actions';
import * as modal from '../../controllers/actions/modal.actions';
import { projects } from '../../controllers/actions/projects.actions';
import { search } from '../../controllers/actions/search.actions';

export const mapStateToProps = (state) => {
  const { permissions } = state.permissions;
  const { features: { project_status: projectStatus } } = state.features;

  return {
    currentProjectsActions: state.projectActions.currentProjectsActions,
    removingCounters: state.projectActions.removingCounters,
    permissions,
    isProjectStatusEnabled: projectStatus === 'enabled',
  };
};

export const mapDispatchToProps = (dispatch) => ({
  openModalboxAddUser: (data) => {
    dispatch(modal.openModal(modalsIds.MODALBOX_ADD_USER, data));
  },

  restoreProject: (idProject, isArchived) => {
    dispatch(projects.restoreProject(idProject, isArchived));
  },

  removeProjectPermanently: (idProject) => {
    dispatch(projects.removeProjectPermanently(idProject));
    dispatch(search.removeProjectFromSearchData(idProject));
  },

  stopActionOnProject: (idProject) => {
    dispatch(projectActions.stopActionOnProject(idProject));
  },

  requestCheckingDuplicationStatus: (idProject) => {
    dispatch(projects.requestCheckingDuplicationStatus(idProject));
  },
});

export const ProjectSearch = connect(mapStateToProps, mapDispatchToProps)(ProjectSearchComponent);
