'use strict';

import { accountSettingsActions } from '../../constants/accountSettings.constants';
import { modalActions } from '../../constants/modal.constants';
import { onboardingAddUsersActions } from '../../constants/onboarding.constants';

const initialState = {
  isError: false,
  isSaving: false,
  isRevertSuccess: false,
  accountPlan: null,
  accountProspectData: {},
  isFetchAccountProspectDataSuccess: false,
  isDuringFetchingAccountPlan: false,
  extendTrialFormResponse: {},
  accountSettings: {},
  accountPlanFetched: false,
  addUsersError: null,
  addedUsersCount: 0,
};

// eslint-disable-next-line complexity
export function accountSettingsReducer(state = initialState, action) {
  switch (action.type) {
    case accountSettingsActions.REQUEST_REVERT_TO_OLD_DASHBOARD:
      return Object.assign({}, state, {
        isSaving: true,
      });
    case accountSettingsActions.FETCH_ACCOUNT_PLAN:
      return Object.assign({}, state, {
        accountPlan: action.accountPlan,
        accountPlanFetched: true,
      });
    case accountSettingsActions.REVERT_TO_OLD_DASHBOARD:
      return Object.assign({}, state, {
        isSaving: false,
        isRevertSuccess: true,
      });
    case accountSettingsActions.REVERT_TO_OLD_DASHBOARD_ERROR:
      return Object.assign({}, state, {
        isSaving: false,
        isError: true,
      });
    case accountSettingsActions.NOT_REVERT_TO_OLD_DASHBOARD:
      return Object.assign({}, state, {
        isSaving: false,
        isRevertSuccess: false,
      });
    case accountSettingsActions.TOGGLE_FETCH_STATUS:
      return Object.assign({}, state, {
        isDuringFetchingAccountPlan: action.state,
      });
    case accountSettingsActions.FETCH_ACCOUNT_SETTINGS:
      return Object.assign({}, state, {
        accountSettings: action.data,
      });
    case accountSettingsActions.FETCH_ACCOUNT_PROSPECT_DATA:
      return Object.assign({}, state, {
        accountProspectData: action.data,
        isFetchAccountProspectDataSuccess: true,
      });
    case modalActions.SUBMIT_LEAD_FORM:
      return Object.assign({}, state, {
        extendTrialFormResponse: action.data,
      });
    case onboardingAddUsersActions.ONBOARDING_ADD_USERS_REQUEST:
      return { ...state, isSubmitting: true };
    case onboardingAddUsersActions.ONBOARDING_ADD_USERS_SUCCESS:
      return {
        ...state,
        addUsersError: false,
        addedUsersCount: state.addedUsersCount + Object.keys(action.payload.newUserIds).length,
      };
    case onboardingAddUsersActions.ONBOARDING_ADD_USERS_FAILURE:
      return {
        ...state,
        addUsersError: true,
      };
    default:
      return state;
  }
}
