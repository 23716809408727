/* eslint-disable max-len,react/button-has-type */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */

'use strict';

import React from 'react';
import { Modal } from '@uxpin/shared-components';
import { APPEARANCES, Link } from '@uxpin/design-system';
import autobind from 'autobind-decorator';
import _ from 'lodash';
import { removingGroupsModes, groupTypes } from '../../../constants/projectsGroupsManagement.constants';

export default class ProjectsGroups extends React.Component {
  constructor(props) {
    super(props);
    this.MODAL = {
      WRAPPER_ID: 'groups-delete-modal-box',
      CLASS_NAMES: ['no-transition'],
    };

    this.state = {
      currentGroup: {},
      currentGroupIndex: -1,
      deleteOption: removingGroupsModes.ARCHIVE,
      selectedGroup: 0,
      groups: [],
    };
  }

  @autobind
  onOpen() {
    const { projectsGroups, currentGroup } = this.props;

    this.setState({
      groups: projectsGroups.map((projectsGroup, id) => ({
        data: projectsGroup,
        index: id,
      })).filter((projectsGroup) => {
        if ([groupTypes.ARCHIVED, groupTypes.UNGROUPED].indexOf(projectsGroup.data.type) === -1
            && !projectsGroup.data.isDeleted && !projectsGroup.data.isBeingDeleted
            && currentGroup.id !== projectsGroup.data.id) {
          return true;
        }

        return false;
      }),
    }, () => {
      if (this.state.groups.length) {
        this.setState({
          // eslint-disable-next-line react/no-access-state-in-setstate
          selectedGroup: this.state.groups[0].index,
        });
      }
    });
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.currentGroup) {
      return;
    }

    this.setState({
      currentGroup: nextProps.currentGroup,
      currentGroupIndex: nextProps.currentGroupIndex,
    });
  }

  @autobind
  renderTitle() {
    return (<h2>Are you sure, you want to <strong>delete</strong> project&nbsp;group "{this.state.currentGroup.name}"?</h2>);
  }

  @autobind
  renderDescription() {
    const { currentGroup } = this.props;
    if (!currentGroup) {
      return;
    }

    const projectsCountLabel = currentGroup.projects.length > 1 ? 'projects' : 'project';
    return (<p>This group has <strong>{currentGroup.projects.length}</strong> {projectsCountLabel}. Here are your options:</p>);
  }

  @autobind
  onClose(e) {
    e.preventDefault();
    const { onClose } = this.props;
    onClose();
  }

  @autobind
  deleteGroup(e) {
    e.preventDefault();
    const { deleteGroup } = this.props;
    const { currentGroupIndex, deleteOption, selectedGroup } = this.state;
    deleteGroup(currentGroupIndex, deleteOption, selectedGroup);
    this.onClose(e);
  }

  @autobind
  changeDeleteOption(e) {
    this.setState({
      deleteOption: e.target.value,
    });
  }

  @autobind
  changeSelectedGroup(e) {
    this.setState({
      // eslint-disable-next-line radix
      selectedGroup: parseInt(e.target.value),
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    return Object.keys(this.props).some((propName) => !_.isEqual(this.props[propName], nextProps[propName]))
      || Object.keys(this.state).some((propName) => !_.isEqual(this.state[propName], nextState[propName]));
  }

  render() {
    const { isOpen } = this.props;
    return (
      <Modal
        wrapperId={this.MODAL.WRAPPER_ID}
        classNames={this.MODAL.CLASS_NAMES}
        renderTitle={this.renderTitle}
        renderDescription={this.renderDescription}
        onOpen={this.onOpen}
        onClose={this.onClose}
        isOpen={isOpen}>
        <section className="modal-box-content">
          <dl className="delete-options">
            <dd>
              <input
                defaultValue={removingGroupsModes.ARCHIVE}
                onChange={this.changeDeleteOption}
                id="delete-option-archive"
                type="radio"
                name="delete-options"
                className="state-helper"
                defaultChecked
              />
              <label className="fake-choose-label fake-radio" htmlFor="delete-option-archive">
                <span className="option-name">Delete group &amp; archive projects</span>
                <small className="option-description">Projects will be still accessible as "archived projects" on your dashboard.</small>
              </label>
            </dd>
            <dd>
              <input
                defaultValue={removingGroupsModes.UNGROUP}
                onChange={this.changeDeleteOption}
                id="delete-option-save"
                type="radio"
                name="delete-options"
                className="state-helper"
              />
              <label className="fake-choose-label fake-radio" htmlFor="delete-option-save">
                <span className="option-name">Delete group. Don&#39;t delete projects</span>
                <small className="option-description">
                  Projects will remain on the dashboard as "ungrouped"
                </small>
              </label>
            </dd>
            <dd>
              <input
                defaultValue={removingGroupsModes.REMOVE}
                onChange={this.changeDeleteOption}
                id="delete-option-delete"
                type="radio"
                name="delete-options"
                className="state-helper"
              />
              <label className="fake-choose-label fake-radio" htmlFor="delete-option-delete">
                <span className="option-name danger">Delete group &amp; delete projects</span>
                <small className="option-description">Both group and projects will be completely deleted.</small>
              </label>
            </dd>
          </dl>
        </section>
        <footer>
          <ul className="btns-group">
            <li>
              <Link appearance={APPEARANCES.NEUTRAL} href="#cancel" onClick={this.onClose}>Cancel</Link>
            </li>
            <li>
              <button onClick={this.deleteGroup} className="btn-solid red btn-wide with-icon icon-general-trash">Delete the project group</button>
            </li>
          </ul>
        </footer>
      </Modal>
    );
  }
}
