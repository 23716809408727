import { request } from '../../utils/request';
import { LIBRARY_TYPES } from '../../../enums/designLibraries';

export const addComponents = (idLibrary, components) => (
  request.post(`/code/integrate-package/${idLibrary}/components/bulk`, {
    body: { components },
  })
);

export const addCategory = (idLibrary, name, categoryId) => (
  request.post(`/code/integrate-package/${idLibrary}/categories`, {
    body: { name, ...(categoryId ? { categoryId } : {}) },
  })
);

export const addLibrary = (
  name,
  editScope,
  accessScope,
  type,
  url,
  isPrivate,
  isPackageIntegration,
  packageNameVersions,
  externalCssResources,
  npmrc
) => {
  const body = {
    name,
    accessScope,
    editScope,
    type,
    isPrivate,
    isPackageIntegration,
    npmrc,
  };

  if (isPackageIntegration) {
    body.packageNameVersions = packageNameVersions.map((packageNameVersion) => ({
      ...packageNameVersion,
      ...{ packageVersion: packageNameVersion.packageVersion || 'latest' },
    }));
    body.externalCssResources = externalCssResources || [];
  }

  if (type === LIBRARY_TYPES.STORYBOOK && !isPrivate) {
    body.url = url;
    body.isPrivate = false;
  }

  return request.post('/libraries/', { body });
};

export const updateLibrary = (
  idLibrary,
  name,
  editScope,
  accessScope,
  repositoryPointer,
  packageNameVersions,
  externalCssResources,
  npmrc,
  isPackageIntegration
) => {

  const body = {
    name,
    accessScope,
    editScope,
    repositoryPointer,
    npmrc,
    isPackageIntegration,
  };

  if (isPackageIntegration) {
    body.packageNameVersions = packageNameVersions.map((packageNameVersion) => ({
      ...packageNameVersion,
      ...{ packageVersion: packageNameVersion.packageVersion || 'latest' },
    }));
    body.externalCssResources = externalCssResources || [];
  }

  return request.put(`/libraries/${idLibrary}/`, {
    body,
  });
}

export const deleteLibrary = (idLibrary) => request.delete(`/libraries/${idLibrary}/`);
