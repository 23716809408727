'use strict';

import React from 'react';
import { extendTrialFieldNames } from '../../../../../constants/extendTrial.constants';
import Select from '../../../Helpers/validation/Select';
import Input from '../../../Helpers/validation/Input';

const getFormField = (id, header, field) => (
  <fieldset className="full-width" key={id}>
    <h4><label htmlFor={id}>{header}</label></h4>
    {field}
  </fieldset>
);

const getValueElement = (value) => (
  <span type="" className="field-value">
    {value}
    <span className="extend-days-info">
      <strong>Done</strong> - 2 days added
    </span>
  </span>
);

export const roleField = (onChange, data) => {
  const value = data ? data.role : null;
  const valueElement = getValueElement(value);
  const options = [
    { label: 'Choose..', value: '' },
    { label: 'UX/UI Designer', value: 'UX/UI Designer' },
    { label: 'Developer', value: 'Developer' },
    { label: 'PM/Business Analyst', value: 'PM/Business Analyst' },
    { label: 'Design Manager', value: 'Design Manager' },
    { label: 'C-level Executive', value: 'C-level Executive' },
    { label: 'Other', value: 'Other' },
  ];
  const selectElement = (
    <Select
      id={extendTrialFieldNames.ROLE}
      name={extendTrialFieldNames.ROLE}
      onChange={onChange}
      options={options}
    />
  );
  const formField = value ? valueElement : selectElement;

  return getFormField(extendTrialFieldNames.ROLE, 'What is your role?', formField);
};

export const teamSizeField = (onChange, data) => {
  const value = data ? data.teamSize : null;
  const valueElement = getValueElement(value);
  const options = [
    { label: 'Choose...', value: '' },
    { label: '1-3', value: '1-3' },
    { label: '4-6', value: '4-6' },
    { label: '7-9', value: '7-9' },
    { label: '10+', value: '10+' },
  ];
  const selectElement = (
    <Select
      id={extendTrialFieldNames.TEAM_SIZE}
      name={extendTrialFieldNames.TEAM_SIZE}
      onChange={onChange}
      options={options}
    />
  );
  const formField = value ? valueElement : selectElement;

  return getFormField(extendTrialFieldNames.TEAM_SIZE, 'How many designers work at your company?', formField);
};


export const problemField = (onChange, data) => {
  const value = data ? data.problem : null;
  const valueElement = getValueElement(value);
  const options = [
    { label: 'Choose...', value: '' },
    { label: 'Just browsing', value: 'Just browsing' },
    { label: 'Evaluating design solutions', value: 'Evaluating design solutions' },
    { label: 'Other', value: 'Other' },
  ];
  const selectElement = (
    <Select
      id={extendTrialFieldNames.PROBLEM}
      name={extendTrialFieldNames.PROBLEM}
      onChange={onChange}
      options={options}
    />
  );
  const formField = value ? valueElement : selectElement;

  return getFormField(extendTrialFieldNames.PROBLEM, 'What brought you to UXPin?', formField);
};

export const companyNameField = (onChange, data) => {
  let value = data ? data.companyName : null;
  if (value && value.length > 18) {
    value = `${value.substring(0, 17)}...`;
  }

  const valueElement = getValueElement(value);
  const inputElement = (
    <Input
      defaultValue={value}
      id={extendTrialFieldNames.COMPANY_NAME}
      name={extendTrialFieldNames.COMPANY_NAME}
      onKeyDown={onChange}
    />
  );
  const formField = value ? valueElement : inputElement;

  return getFormField(extendTrialFieldNames.COMPANY_NAME, 'What is the name of your company?', formField);
};
