/* eslint-disable complexity */
/* eslint-disable react/destructuring-assignment */

'use strict';

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import config from 'react-global-configuration';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { isEmpty, noop, some, merge } from 'lodash';
import autobind from 'autobind-decorator';
import classnames from 'classnames';
import {
  DESKTOP_APP_LOADED, ModalboxFeatureUpgrade, getBrowserName, getOS, isDesktopApp,
  ModalboxGeneralMessage, ModalboxGdpr, PricingModal, ModalboxInvoiceOverdue,
} from '@uxpin/shared-components';
import Cookies from 'js-cookie';
import Intercom, { IntercomAPI } from 'react-intercom';

import { isAccountActive, isAccountOverdue, getCurrentAccount, getAccountStatus } from '../../../utils/account';
import MaintenanceBar from '../MaintenanceBar/MaintenanceBar';
import { TopBar } from '../../containers/TopBar';
import { Search } from '../../containers/Search';
import { searchElementType } from '../../../constants/search.constants';
import { settingsName, cookieStrings } from '../../../constants/settings.constants';
import { ProjectSearch } from '../../containers/ProjectSearch';
import { FileBox } from '../../containers/FileBox';
import { ModalboxBetaWelcome } from '../../containers/ModalboxBetaWelcome';
import ModalboxTermsOfServiceComponent from '../Modalboxes/ModalboxTermsOfService';
import ModalboxBillingSystemChangeComponent from '../Modalboxes/ModalboxBillingSystemChange';
import { getLogoutUrl, goTo, isPage } from '../../../utils/router';
import { ExportInfoContainer } from '../../containers/ExportInfoContainer';
import { MultipleAccounts } from '../../containers/MultipleAccounts';
import DragLayer from '../CustomDragLayer/CustomDragLayer';
import ModalboxUpgrade from '../Modalboxes/ModalboxUpgrade';
import { ModalboxAddUser } from '../../containers/ModalboxAddUser';
import ModalboxPrivacyPolicy from '../../containers/ModalboxPrivacyPolicy';
import ModalboxChangelog from '../../containers/ModalboxChangelog';
import ModalboxDocumentChangeName from '../../containers/ModalboxDocumentChangeName';
import ModalboxDocumentChangeProject from '../../containers/ModalboxDocumentChangeProject';
import ModalboxDocumentDelete from '../../containers/ModalboxDocumentDelete';
import ModalboxDesignSystemDelete from '../../containers/ModalboxDesignSystemDelete';
import ModalboxDesignSystemPermission from '../../containers/ModalboxDesignSystemPermission';
import ModalboxProjectChangeName from '../Modalboxes/ModalboxProjectChangeName';

import { ModalboxProjectChangeGroup } from '../../containers/ModalboxProjectChangeGroup';
import ModalboxProjectChangeGroupInfo from '../Modalboxes/ModalboxProjectChangeGroupInfo';
import { ProjectSettings } from '../../containers/ProjectSettings';
import ModalboxProjectDelete from '../Modalboxes/ModalboxProjectDelete';
import { ProjectsActionsListener } from '../../containers/ProjectsActionsListener';
import { DocumentsActionsListener } from '../../containers/DocumentsActionsListener';
import ApprovalProcess from '../../containers/ApprovalProcess/ApprovalProcess';
import { ShareModal } from '../../containers/ShareModal';
import { googleAnalytics, microsoftClarity } from '../../../utils/analyticsManager';
import {
  isDesignViewsModelPlan, accountStatuses, oneUserOnlyPlans, PRIVACY_POLICY_0620_DATE,
} from '../../../constants/accountSettings.constants';
import * as maintenanceHelper from '../../../utils/maintenanceHelper';
import PaymentFailedBar from '../../containers/WarningBars/PaymentFailedBar';
import { InvoiceOverdueBar } from '../../containers/WarningBars/InvoiceOverdueBar';
import {
  actionDelayType, gdprDate, onboardingFlowStartDate, loginEventCookieName, registerEventCookieName,
} from '../../../constants/marketing.constants';
import actionDelay from '../../../utils/actionDelay';
import { countPercent } from '../../../utils/countPercent';
import FreemiumBar from '../../containers/InfoBars/FreemiumBar';
import {
  handleLoginEvents, setDataCookie, shouldOpenNamesModal,
} from '../../../utils/marketing';
import { isDocumentLimitNearLimit } from '../../../utils/documents';
import routes from '../../../constants/routes.constants';
import { ModalboxOnboarding } from '../../containers/ModalboxOnboarding';
import { ModalboxLimitsExceeded } from '../../containers/ModalboxLimitsExceeded';
import ModalboxContactRequest from '../../containers/ModalboxContactRequest';
import { ModalboxDesignLimitReached } from '../../containers/ModalboxDesignLimitReached';
import { getSettingSeenName } from '../../../utils/settings';
import { FEATURE_STATUS } from '../../../constants/features.constants';
import { onboardingFlowSettings, onboardingStartSteps } from '../../../constants/onboarding.constants';
import { OnboardingFlow } from '../../containers/Onboarding/OnboardingFlow';
import ModalboxFillName from '../../containers/ModalboxFillName';
import ModalboxAddMergeLibrary from '../../containers/ModalboxAddMergeLibrary';
import ModalboxMergeLibrarySetting from '../../containers/ModalboxMergeLibrarySetting';
import ModalboxAddComponent from '../../containers/ModalboxAddComponent';
import { getMixpanel, handleMixpanelProperties } from '../../../../../shared/mixpanel';
import { WidgetMergeOnboardingTutorial } from '../../containers/WidgetMergeOnboardingTutorial';

const TEN_YEARS_IN_DAYS = 36500;

class Layout extends React.Component {
  constructor(props) {
    super(props);

    this.CLASS_NAMES = {
      MAC: 'mac',
      CHROME: 'chrome',
      SAFARI: 'safari',
      FIREFOX: 'firefox',
      LOADING: 'loading',
      SEARCH_MODE: 'search-mode',
      PROMO_TOP_BAR: 'promo-top-bar',
      INFO_BAR_VISIBLE: 'is-top-info-bar-visible',
    };

    this.INTERCOM_APP_ID = config.get().INTERCOM_KEY;

    this.state = {
      allowIntroModals: false,
      isModalboxBetaWelcomeOpen: false,
      isModalboxTermsOfServiceOpen: false,
      isModalboxBillingSystemChangeOpen: false,
      isMaintenanceVisible: false,
      isFreemiumBarVisible: false,
      showDesignViewsPlans: false,
      isModalboxOnboardingOpen: false,
      isReturningOldTrial: undefined,
      isMixpanelSet: false,
      isInactive: false,
      isGdprModalOpen: false,
      isOnboarding: false,
      isPrivacyPolicyModalOpen: false,
      gtmHandled: false,
      shouldOpenNamesModal: false,
      isOverdueNoticed: false,
      isOverdueBlocked: false,
      hasMultipleAccounts: false,
    };
  }

  componentWillMount() {
    const {
      checkShareDocumentByEmailReCaptcha,
      fetchSettings,
      getUserData,
      getFeatures,
      getMembers,
      getSlackState,
      getRoles,
      getAccountSettings,
      getStatus,
      experimentsPairIds,
      loadChangelogItems,
    } = this.props;

    fetchSettings();
    getAccountSettings();
    getUserData();
    getFeatures();
    getSlackState();
    getMembers();
    getRoles();
    getStatus();
    experimentsPairIds();
    loadChangelogItems();
    checkShareDocumentByEmailReCaptcha();
  }

  componentDidMount() {
    const { isTrial } = this.props;

    if (isTrial) {
      microsoftClarity.initialize();
    }
  }

  componentWillReceiveProps(nextProps) {
    const {
      location,
      isTrial,
      currentUserData,
      features,
      maintenanceSettings,
      accountPlan,
      settings,
      isFetchUserDataSuccess,
    } = nextProps;

    const {
      showBillingSystemChangeModal,
    } = settings;

    const { ENABLED } = FEATURE_STATUS;

    this.handleMixpanel(nextProps);
    this.trackGtmTrialStarted(nextProps);

    this.handleGtm(nextProps);
    this.handleOverdueAccount(currentUserData);
    this.handleInactiveAccount(location.pathname, currentUserData);
    this.handleOneSeatPlanAccount(location.pathname, currentUserData, accountPlan);

    this.checkForOldReturningTrial(nextProps);
    this.handleOnboarding(nextProps);
    this.handleIntroModals(nextProps);
    this.handleGdprModal(nextProps);
    this.handlePrivacyPolicyModal(nextProps);
    this.handleNameModal(nextProps);

    if (features && features.billing_recurly === ENABLED
      && isTrial === false && showBillingSystemChangeModal
      && (accountPlan && !accountPlan.is_in_recurly)) {
      this.setState({
        isModalboxBillingSystemChangeOpen: true,
      });
    }

    if (maintenanceHelper.isVisible(maintenanceSettings)) {
      this.setState({
        isMaintenanceVisible: true,
      });
    }

    if (features.hide_design_views_data && features.hide_design_views_data !== ENABLED) {
      this.setState({
        isFreemiumBarVisible: true,
      });

      if (features.free_on_signup && features.free_on_signup !== ENABLED) {
        this.setState({
          showDesignViewsPlans: true,
        });
      }
    }
    if (isFetchUserDataSuccess && currentUserData.accounts && currentUserData.accounts.length > 1) {
      this.setState({
        hasMultipleAccounts: true,
      });
    }

    if (isTrial) {
      microsoftClarity.initialize();
    }
  }

  handleGtm(props) {
    const { gtmHandled } = this.state;

    if (gtmHandled) {
      return;
    }

    const { gtmDataReady, gtmData, isTrial, accountTrialDates } = props;
    const loginCookie = Cookies.get(loginEventCookieName);
    const registerCookie = Cookies.get(registerEventCookieName);

    if (gtmDataReady && !isEmpty(gtmData) && !isEmpty(accountTrialDates)) {
      this.setState({
        gtmHandled: true,
      });

      const { userId, ownerId, accountId, projectsCount, accountLevel, accountType } = gtmData;

      // Event login cookie carries info 0/1 info about first login
      const firstTimeLogin = !!parseInt(loginCookie, 10);

      const gtmInitData = merge(
        googleAnalytics.getGtmIdsObject(userId, ownerId, accountId),
        googleAnalytics.getGtmAccountDataObject(accountLevel, projectsCount, firstTimeLogin, accountType),
        isTrial ? accountTrialDates : {}
      );

      setDataCookie(gtmInitData);

      googleAnalytics.gtmInitialize(gtmInitData).then(() => {
        if (loginCookie === undefined && registerCookie === undefined) {
          return;
        }

        const { COOKIE_DOMAIN } = config.get();
        const userIds = {
          userId,
          ownerId,
          accountId,
        };

        handleLoginEvents(loginCookie, registerCookie, projectsCount, accountLevel, userIds);

        Cookies.remove(registerEventCookieName, {
          path: '/',
          domain: COOKIE_DOMAIN,
        });
        Cookies.remove(loginEventCookieName, {
          path: '/',
          domain: COOKIE_DOMAIN,
        });
      });
    }
  }

  checkForOldReturningTrial(props) {
    const {
      isTrial,
      currentUserData,
      isFetchUserDataSuccess,
    } = props;

    // Check if account was created before a given date (start of registering createdAt)
    // and classify it as a returning trial
    if (isFetchUserDataSuccess) {
      const createDate = currentUserData.createdAt ? new Date(currentUserData.createdAt).getTime() : 0;
      const onboardingStartDate = new Date(onboardingFlowStartDate).getTime();
      const createdAfterOnboardingStarted = onboardingStartDate < createDate;

      const isOldAccount = currentUserData.createdAt === null || !createdAfterOnboardingStarted;
      const isReturningOldTrial = isTrial && isOldAccount;
      this.setState({ isReturningOldTrial });
    }

    if (currentUserData && currentUserData.idActiveAccount
      && !isAccountActive(currentUserData.idActiveAccount, currentUserData.accounts)) {
      this.setState({
        isInactive: true,
      });
    }
  }

  handleNameModal(props) {
    const { areSettingsFetched, isFetchUserDataSuccess } = props;

    if (!areSettingsFetched || !isFetchUserDataSuccess) {
      return;
    }

    this.setState({
      shouldOpenNamesModal: shouldOpenNamesModal(props),
    });
  }

  handlePrivacyPolicyModal(props) {
    const {
      isFetchUserDataSuccess,
      currentUserData,
      settings,
    } = props;
    const { isPrivacyPolicyModalOpen } = this.state;

    if (!isFetchUserDataSuccess) {
      return;
    }

    const account = getCurrentAccount(currentUserData.idActiveAccount, currentUserData.accounts);
    const isAccountSuspended = account.status === accountStatuses.SUSPENDED;

    const isPrivacyPolicyModalSeen = settings[settingsName.PRIVACY_POLICY_0620_SEEN];

    const createDate = new Date(currentUserData.createdAt).getTime();
    const currentDate = new Date().getTime();
    const privacyPolicyChangeDate = new Date(PRIVACY_POLICY_0620_DATE).getTime();
    const createdBeforePrivacyPolicyChange = createDate < privacyPolicyChangeDate;
    const todayAfterPolicyEffectiveDate = currentDate > privacyPolicyChangeDate;

    const shouldShowModal = !isAccountSuspended && !isPrivacyPolicyModalSeen && todayAfterPolicyEffectiveDate
      && (currentUserData.createdAt === null || createdBeforePrivacyPolicyChange);

    if (shouldShowModal !== isPrivacyPolicyModalOpen) {
      this.setState({
        allowIntroModals: shouldShowModal,
        isPrivacyPolicyModalOpen: shouldShowModal,
      });
    }
  }

  handleGdprModal(props) {
    const {
      isFetchUserDataSuccess,
      currentUserData,
      gdprClosed,
    } = props;

    const loaded = isFetchUserDataSuccess;
    const confirmed = currentUserData.gdprModalClosed !== null;
    const subbedBeforeGdpr = some(currentUserData.accounts, (account) => {
      const isSub = !account.isTrial && account.status === accountStatuses.ACTIVE;
      const notNullUpgrade = account.firstUpgradeAt !== null;
      const upgradeDate = new Date(account.firstUpgradeAt).getTime();
      const gdprTime = new Date(gdprDate).getTime();
      const beforeGdpr = notNullUpgrade && upgradeDate < gdprTime;

      return isSub && beforeGdpr;
    });

    if (loaded && !confirmed && subbedBeforeGdpr) {
      this.setState({
        isGdprModalOpen: !gdprClosed,
      });
    }
  }

  @autobind
  closeGdprModal() {
    this.props.closeGdprModal();
  }

  trackGtmTrialStarted({ currentUserData }) {
    const cookieValue = Cookies.get(cookieStrings.GTM_TRIAL_STARTED_TRACKED_COOKIE);
    const currentUrl = window.location.href;
    const urlObject = new URL(currentUrl);
    const parts = urlObject.hostname.split('.');
    const domain = parts.slice(-2).join('.');

    if (currentUserData.id) {
      if ((!cookieValue)) {
        const dataLayer = window.dataLayer || [];

        dataLayer.push({
          event: 'trial_started',
          user_id: currentUserData.id,
        });

        Cookies.set(cookieStrings.GTM_TRIAL_STARTED_TRACKED_COOKIE, 1, { expires: TEN_YEARS_IN_DAYS, domain });
      }
    }
  }

  handleMixpanel({ currentUserData }) {
    if (!this.state.isMixpanelSet && Object.keys(currentUserData).length > 0) {
      this.setState({
        isMixpanelSet: true,
      }, () => {
        getMixpanel(async (mixpanel) => {
          const idDmsUser = currentUserData.id;
          mixpanel.identify(idDmsUser);

          await handleMixpanelProperties(mixpanel);

          mixpanel.track('dashboard_loaded', {
            app_type: isDesktopApp ? 'desktop_app' : 'web_app',
          });
        });
      });
    }
  }

  handleInactiveAccount(pathname, currentUserData) {
    if (pathname !== `${config.get('BASE_URL')}${routes.INACTIVE_ACCOUNT}`
      && currentUserData.idActiveAccount
      && !isAccountActive(currentUserData.idActiveAccount, currentUserData.accounts)) {
      goTo(routes.INACTIVE_ACCOUNT);
    }
  }

  handleOverdueAccount(currentUserData) {
    if (currentUserData.idActiveAccount
      && isAccountOverdue(currentUserData.idActiveAccount, currentUserData.accounts)) {
      const accountStatus = getAccountStatus(currentUserData.idActiveAccount, currentUserData.accounts);

      if (accountStatus === accountStatuses.OVERDUE_NOTICED) {
        this.setState({
          isOverdueNoticed: true,
        });
      }
      if (accountStatus === accountStatuses.OVERDUE_BLOCKED) {
        this.setState({
          isOverdueBlocked: true,
        });
      }
    }
  }


  handleOneSeatPlanAccount(pathname, currentUserData, accountPlan) {
    if (pathname !== `${config.get('BASE_URL')}${routes.USER_LOCKED_OUT}` && accountPlan && currentUserData
      && oneUserOnlyPlans.includes(accountPlan.unique_name) && currentUserData.id
      && !currentUserData.isActiveAccountOwner) {
      goTo(routes.USER_LOCKED_OUT);
    }
  }

  handleOnboarding(props) {
    const { isTrial, isFetchUserDataSuccess, settings } = props;
    const { isReturningOldTrial, isInactive } = this.state;

    const oldOnboardingSeen = settings[getSettingSeenName(onboardingStartSteps.PATH_CHOICE)];
    const isActiveTrial = isTrial && !isInactive;
    const isNewTrial = isReturningOldTrial !== undefined && !isReturningOldTrial;

    if (isFetchUserDataSuccess && !oldOnboardingSeen && isActiveTrial && isNewTrial) {
      microsoftClarity.set('isNewUser', 'true');
      this.setState({
        isOnboarding: true,
      });
    }
  }

  handleIntroModals(props) {
    const {
      isTrial,
      accountPlan,
      showTermsOfServiceModal,
    } = props;

    if (showTermsOfServiceModal && isTrial === false && accountPlan && !isDesignViewsModelPlan(accountPlan.name)) {
      this.setState({
        allowIntroModals: true,
        isModalboxTermsOfServiceOpen: true,
      });
    }
  }

  @autobind
  getPreviewBaseUrl() {
    const { PREVIEW_URL } = config.get();

    if (PREVIEW_URL.startsWith('//')) {
      return window.location.protocol + PREVIEW_URL;
    }

    return PREVIEW_URL;
  }

  @autobind
  prepareSearchSections(searchData) {
    const { features } = this.props;
    const searchSections = [
      {
        id: 1,
        name: 'Projects',
        columnLength: 4,
        filterBy: ['name'],
        action: (project, data) => {
          if (!data.currentProjectsActions[project.id]) {
            goTo(`${routes.PROJECT}/${project.id}`);
          }
        },

        component: <ProjectSearch />,
        allElements: [],
      },
      {
        id: 2,
        name: 'Design files',
        columnLength: 8,
        filterBy: ['name'],
        action: (document, data) => {
          const { APP_URL, EDITOR_NEW_ENGINE_URL } = config.get();
          const uxpin30EngineEnabled = features && features.uxpin30_engine === FEATURE_STATUS.ENABLED;
          const baseUrl = (uxpin30EngineEnabled ? EDITOR_NEW_ENGINE_URL : APP_URL);

          if (data.currentDocumentsActions[document.id]) {
            return;
          }

          switch (document.type) {
            case searchElementType.DOCUMENT:
              window.location.href = `${APP_URL}/dms/Documents/Default/${document.id}`;
              return;
            case searchElementType.PROTOTYPE:
              window.location.href = `${baseUrl}/edit/${document.id}`;

            // no default
          }
        },

        component: <FileBox />,
        allElements: [],
      },
    ];
    const projects = [];
    const designFiles = [];

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < searchData.length; i++) {
      switch (searchData[i].type) {
        case searchElementType.PROJECT:
          projects.push(searchData[i]);
          break;
        default:
          designFiles.push(searchData[i]);
          break;
      }
    }

    searchSections[0].allElements = projects;
    searchSections[1].allElements = designFiles;

    return searchSections;
  }

  isMainPage() {
    return this.isProjectsPage();
  }

  isDesignSystemsPage() {
    return isPage(this.props, routes.DESIGN_SYSTEMS);
  }

  isMergePage() {
    return isPage(this.props, routes.MERGE);
  }

  isInsideDesignSystemsPage() {
    return isPage(this.props, routes.DESIGN_SYSTEMS_CREATE);
  }

  isProjectsPage() {
    return isPage(this.props, routes.PROJECTS);
  }

  isInsideProjectPage() {
    return isPage(this.props, routes.PROJECT, true);
  }

  isRemoteModalPage() {
    return isPage(this.props, routes.MODAL, true);
  }

  isLimitsExceededPage() {
    return isPage(this.props, routes.LIMITS_EXCEEDED, true);
  }

  isGetAddOnPage() {
    return isPage(this.props, routes.GET_ADD_ON);
  }

  @autobind
  closeModalboxBetaWelcome(data) {
    this.setState({
      isModalboxBetaWelcomeOpen: false,
    });

    this.props.closeWelcomeModal(data);
  }

  @autobind
  closeModalboxTermsOfService(data) {
    this.setState({
      isModalboxTermsOfServiceOpen: false,
    });
    actionDelay.setBlockadeDate(actionDelayType.MODAL, actionDelayType.MODAL);

    this.props.closeTermsOfServiceModal(data);
  }

  @autobind
  closeModalboxBillingSystemChange() {
    this.setState({
      isModalboxBillingSystemChangeOpen: false,
    });

    this.props.closeBillingSystemChangeModal({ showBillingSystemChangeModal: 'false' });
  }

  @autobind
  onCloseMaintenance() {
    this.setState({
      isMaintenanceVisible: false,
    });

    maintenanceHelper.setInvisible(this.props.maintenanceSettings.id);
  }

  getMainContainerClassNames() {
    const { userAgent } = navigator;
    const {
      LOADING,
      SEARCH_MODE,
      INFO_BAR_VISIBLE,
    } = this.CLASS_NAMES;

    return classnames(
      getBrowserName(userAgent),
      getOS(userAgent),
      {
        [LOADING]: !this.props.isFetchUserDataSuccess,
        [SEARCH_MODE]: this.props.isSearchMode,
        [INFO_BAR_VISIBLE]: this.isInfoBarVisible() && isDesktopApp,
        [DESKTOP_APP_LOADED]: this.props.isFetchUserDataSuccess && isDesktopApp,
      }
    );
  }

  renderMultipleAccounts() {
    const activeTabUri = this.props.location.pathname || '';

    if (this.state.hasMultipleAccounts) {
      return (<MultipleAccounts activeTabUri={activeTabUri} />);
    }

    return null;
  }

  renderBetaWelcome() {
    return (
      <ModalboxBetaWelcome
        isOpen={this.state.isModalboxBetaWelcomeOpen}
        onClose={this.closeModalboxBetaWelcome}
      />
    );
  }

  renderTermsOfServiceModal() {
    const { isFetchUserDataSuccess } = this.props;

    if (!isFetchUserDataSuccess) {
      return null;
    }

    return (
      <ModalboxTermsOfServiceComponent
        isOpen={this.state.isModalboxTermsOfServiceOpen}
        onClose={this.closeModalboxTermsOfService}
      />
    );
  }

  renderBillingSystemChangeModal() {
    const { isFetchUserDataSuccess } = this.props;

    if (!isFetchUserDataSuccess) {
      return null;
    }

    return (
      <ModalboxBillingSystemChangeComponent
        isOpen={this.state.isModalboxBillingSystemChangeOpen}
        onClose={this.closeModalboxBillingSystemChange}
      />
    );
  }

  renderOnboardingModal() {
    const { isFetchUserDataSuccess } = this.props;

    if (!isFetchUserDataSuccess) {
      return null;
    }

    return (
      <ModalboxOnboarding
        isOpen={this.state.isModalboxOnboardingOpen}
      />
    );
  }

  renderMaintenanceBar() {
    if (!this.state.isMaintenanceVisible) {
      return null;
    }

    return (
      <MaintenanceBar
        isVisible={this.state.isMaintenanceVisible}
        settings={this.props.maintenanceSettings}
        onClose={this.onCloseMaintenance}
      />
    );
  }

  renderOverdueNoticeBar() {
    if (!this.state.isOverdueNoticed) {
      return null;
    }

    return (
      <InvoiceOverdueBar />
    );
  }

  renderPaymentFailedBar() {
    if (!isEmpty(this.props.accountPlan) && this.props.accountPlan.recurring_fails > 1) {
      return (
        <PaymentFailedBar />
      );
    }

    return null;
  }

  renderIntroModals() {
    if (this.state.allowIntroModals
      && this.state.isPrivacyPolicyModalOpen) {
      return (
        <ModalboxPrivacyPolicy />
      );
    }

    const { isNamesModalFormSubmitted } = this.props.marketing;

    if (this.state.shouldOpenNamesModal && !isNamesModalFormSubmitted) {
      return (
        <ModalboxFillName />
      );
    }

    if (!this.state.allowIntroModals
      || this.state.isReturningOldTrial
      || this.isRemoteModalPage()
      || this.isLimitsExceededPage()) {
      return null;
    }

    if (this.state.isModalboxOnboardingOpen) {
      return this.renderOnboardingModal();
    }

    if (this.state.isModalboxTermsOfServiceOpen) {
      return this.renderTermsOfServiceModal();
    }

    return null;
  }

  @autobind
  renderFreemiumBar() {
    const accountPlan = this.props.accountPlan;
    const docsCount = this.props.documentsCount;

    if (!this.state.isFreemiumBarVisible
      || docsCount === undefined
      || accountPlan === null) {
      return null;
    }

    const {
      used_design_views: usedDesignViews,
      available_design_views: availableDesignViews,
    } = accountPlan;
    const viewsNearLimitPercent = 80;

    const viewsNearLimit = (countPercent(usedDesignViews, availableDesignViews) >= viewsNearLimitPercent);
    const docsNearLimit = isDocumentLimitNearLimit(docsCount.in_plan, docsCount.used);

    return (
      <FreemiumBar
        usedViews={countPercent(accountPlan.used_design_views, accountPlan.available_design_views)}
        viewsNearLimit={viewsNearLimit}
        docsCount={docsCount}
        docsNearLimit={docsNearLimit}
      />
    );
  }

  handleInactiveFeatureRedirect(props) {
    const { features } = props;

    if (this.isDesignSystemsPage()
      && features.design_systems !== FEATURE_STATUS.ENABLED) {
      goTo(routes.PROJECTS);
    }
  }

  @autobind
  handleStandardRedirectToProjects(props) {
    const { location, features, currentUserData } = props;

    const featuresAllowRedirectToProjects = features
      && (features.returning_unified_onboarding !== FEATURE_STATUS.ENABLED
        || features.onboarding_ds_scraper === FEATURE_STATUS.ENABLED);

    // isReturningOldTrial cannot be set without currentUserData
    if (!isEmpty(currentUserData)
      && location.pathname === config.get('BASE_URL')
      && (featuresAllowRedirectToProjects || this.state.isReturningOldTrial)) {
      goTo(routes.PROJECTS);
    }
  }

  componentWillUpdate(nextProps) {
    const {
      settings, features, currentUserData, accountPlan,
    } = nextProps;

    if (isEmpty(settings) || isEmpty(features)
      || isEmpty(currentUserData) || isEmpty(accountPlan)) {
      return;
    }

    this.handleStandardRedirectToProjects(nextProps);
    this.handleInactiveFeatureRedirect(nextProps);
  }

  isInfoBarVisible() {
    const { accountPlan } = this.props;
    const {
      isFreemiumBarVisible,
      isMaintenanceVisible,
      isOverdueNoticed,
    } = this.state;

    return isFreemiumBarVisible
      || isMaintenanceVisible
      || isOverdueNoticed
      || (!isEmpty(accountPlan) && accountPlan.recurring_fails > 1);
  }

  @autobind
  renderInfoBars() {
    if (!this.isInfoBarVisible()) {
      return null;
    }

    return (
      <section className="info-bars">
        {this.renderFreemiumBar()}
        {this.renderMaintenanceBar()}
        {this.renderOverdueNoticeBar()}
        {this.renderPaymentFailedBar()}
      </section>
    );
  }

  @autobind
  renderTopBars() {
    const classNames = classnames({ [this.CLASS_NAMES.INFO_BAR_VISIBLE]: this.isInfoBarVisible() && !isDesktopApp });

    return (
      <section className={classNames}>
        {!isDesktopApp && this.renderInfoBars()}
        {this.renderMultipleAccounts()}
        <TopBar
          isDesignSystemsPage={this.isDesignSystemsPage()}
          isMergePage={this.isMergePage()}
          isInsideDesignSystemsPage={this.isInsideDesignSystemsPage()}
          isMainPage={this.isMainPage()}
          isInsideProjectPage={this.isInsideProjectPage()}
        />
        <Search
          prepareSearchSections={this.prepareSearchSections}
        />
      </section>
    );
  }

  handlePricingOpenTracking = () => {
    const { currentUserData } = this.props;

    getMixpanel((mixpanel) => {
      mixpanel.track('pricing_viewed');
    });

    googleAnalytics.dataLayerPush({
      event: 'pricing_viewed',
      user_id: currentUserData.id,
      page_title: document.title,
      page_url: window.location.href,
      // we have only en-US for app
      page_language: 'en-US',
    });
  }

  renderPricing() {
    const { isPricingOpen, closePricing, accountPlan } = this.props;

    if (accountPlan?.is_suspended) {
      return null;
    }

    return (
      <PricingModal
        isOpen={isPricingOpen}
        onClose={closePricing}
        intercom={IntercomAPI}
        handlePricingOpenTracking={this.handlePricingOpenTracking}
      />
    );
  }

  renderFeatureUpgrade() {
    const { closeFeatureUpgrade, isFeatureUpgradeOpen, featureUpgradeName, openPricing } = this.props;

    if (!isFeatureUpgradeOpen || !featureUpgradeName) {
      return null;
    }

    return (
      <section className="visible modal-box">
        <ModalboxFeatureUpgrade
          isOpen={isFeatureUpgradeOpen}
          onClose={closeFeatureUpgrade}
          openPricing={openPricing}
          feature={featureUpgradeName}
        />
      </section>
    );
  }

  getIntercomDataForActiveAccount() {
    const { accountPlan, currentUserData: { email, id, idActiveAccount, intercomHash } } = this.props;

    return {
      account_id: idActiveAccount,
      appID: this.INTERCOM_APP_ID,
      email,
      hide_default_launcher: true,
      source: isDesktopApp ? 'desktop-app' : 'browser',
      user_id: id,
      uxpinIsCustomer: accountPlan.is_subscription && !accountPlan.is_suspended,
      uxpinPlan: accountPlan.name,
      uxpinPlanUnique: accountPlan.unique_name,
      uxpinUsers: accountPlan.usedSeats,
      user_hash: intercomHash,
    };
  }

  getIntercomDataForInactiveAccount() {
    const { currentUserData: { email, id, intercomHash } } = this.props;

    return {
      appID: this.INTERCOM_APP_ID,
      email,
      hide_default_launcher: true,
      user_id: id,
      uxpinIsCustomer: false,
      user_hash: intercomHash,
    };
  }

  handleIntercom() {
    const { currentUserData: { accounts, idActiveAccount }, documentsCountFetched } = this.props;
    const account = getCurrentAccount(idActiveAccount, accounts);

    if (!account) {
      return null;
    }

    const { isSubscription, status } = account;
    const isActiveAccount = status === 'active';
    const props = isActiveAccount ? this.getIntercomDataForActiveAccount()
      : this.getIntercomDataForInactiveAccount(isSubscription);

    if (isActiveAccount && !documentsCountFetched) {
      return null;
    }

    return (
      <Intercom {...props} />
    );
  }

  renderMergeOnboardingTutorial() {
    return (
      <WidgetMergeOnboardingTutorial />
    );
  }

  render() {
    const {
      accountPlan,
      areSettingsFetched,
      changelogItems,
      closeModalboxChangelog,
      currentUserData,
      isModalboxChangelogOpen,
      settings,
      showOnboardingPasswordField,
    } = this.props;
    const finishedOnboarding = settings[getSettingSeenName(onboardingFlowSettings.ONBOARDING_FLOW)];
    const isDataFetched = !!(currentUserData && accountPlan);

    // TODO: add a spinner
    if (!areSettingsFetched) {
      return (
        <div id="main-container" className="loading" />
      );
    }

    if (this.state.isOnboarding && !finishedOnboarding) {
      return (
        <OnboardingFlow
          showPassword={showOnboardingPasswordField}
        />
      );
    }

    return (
      <Fragment>
        {isDataFetched && this.handleIntercom()}
        <div id="main-container" className={this.getMainContainerClassNames()}>
          {this.renderTopBars()}
          {this.props.children}
          {isDesktopApp && this.renderInfoBars()}

          {this.renderBetaWelcome()}
          {this.renderBillingSystemChangeModal()}
          {this.renderIntroModals()}
          <DragLayer />
          {isDataFetched && this.renderMergeOnboardingTutorial()}
          <ModalboxUpgrade
            isOpen={this.props.isUpgradeModalOpen && !isEmpty(currentUserData)}
            isCloseBlocked={this.props.upgradeModalData.isCloseBlocked}
            onClose={this.props.closeUpgradeModal}
            designViewsPlans={this.state.showDesignViewsPlans}
            fullscreen
          />
          <ModalboxAddUser
            project={this.props.addUserModalData}
            isOpen={this.props.isAddUserModalOpen}
            onClose={this.props.closeModalboxAddUser}
          />
          <ModalboxProjectChangeName
            isOpen={this.props.isModalboxProjectChangeNameOpen}
            data={this.props.modalboxProjectChangeNameData.project}
            actions={this.props.modalboxProjectChangeNameData.actions}
            onClose={this.props.closeModalboxProjectChangeName}
          />
          <ModalboxProjectChangeGroup
            isOpen={this.props.isModalboxProjectChangeGroupOpen}
            data={this.props.modalboxProjectChangeGroupData.projectData}
            actions={this.props.modalboxProjectChangeGroupData.actions}
            onClose={this.props.closeModalboxProjectChangeGroup}
          />
          <ModalboxProjectDelete
            isOpen={this.props.isModalboxProjectDeleteOpen}
            data={this.props.modalboxProjectDeleteData.projectData}
            actions={this.props.modalboxProjectDeleteData.actions}
            onClose={this.props.closeModalboxProjectDelete}
          />
          <ProjectSettings
            idProject={this.props.projectSettingsData}
            isOpen={this.props.isProjectSettingsOpen}
            onClose={this.props.closeProjectSettings}
          />
          <ApprovalProcess
            data={this.props.approvalProcessData}
            isOpen={this.props.isApprovalProcessOpen}
            onClose={this.props.closeApprovalProcess}
          />
          <ModalboxGeneralMessage
            isOpen={this.props.isModalboxGeneralMessageOpen}
            onClose={this.props.modalboxGeneralMessage.onClose}
            onAccept={this.props.modalboxGeneralMessage.onAccept}
            title={this.props.modalboxGeneralMessage.title}
            message={this.props.modalboxGeneralMessage.message}
            btnLabel={this.props.modalboxGeneralMessage.btnLabel}
          />

          <ModalboxProjectChangeGroupInfo
            isOpen={this.props.isModalboxProjectChangeGroupInfoOpen}
            onClose={this.props.modalboxProjectChangeGroupInfo.onClose}
            onAccept={this.props.modalboxProjectChangeGroupInfo.onAccept}
            title={this.props.modalboxProjectChangeGroupInfo.title}
            projects={this.props.modalboxProjectChangeGroupInfo.projects}
            projectsWithGroups={this.props.modalboxProjectChangeGroupInfo.projectsWithGroups}
            projectGroupName={this.props.modalboxProjectChangeGroupInfo.projectGroupName}
            btnLabel={this.props.modalboxProjectChangeGroupInfo.btnLabel}
          />
          <ShareModal
            apiUrl={config.get('API_URL')}
            basePreviewUrl={this.getPreviewBaseUrl()}
            keepInOriginalContainer
            kissEvent={this.trackEvent}
            imageBasePath="/p/share/"
            reCaptchaKey={this.props.shouldRenderReCaptcha ? config.get('RECAPTCHA_SITE_KEY') : ''}
          />
          <ExportInfoContainer />
          <ModalboxDocumentChangeName />
          <ModalboxDocumentChangeProject />
          <ModalboxDocumentDelete />
          <ProjectsActionsListener />
          <DocumentsActionsListener />
          <ModalboxDesignLimitReached
            isOpen={this.props.isDesignLimitReachedModalOpen}
            onClose={this.props.closeDesignLimitReachedModal}
            showSystemsListOnClose={this.isLimitsExceededPage()}
            type={this.props.modalboxDesignLimitReached.type}
          />
          <ModalboxDesignSystemPermission />
          <ModalboxAddMergeLibrary />
          <ModalboxMergeLibrarySetting />
          <ModalboxDesignSystemDelete />
          <ModalboxAddComponent />
          <ModalboxLimitsExceeded />
          <ModalboxContactRequest />
          <ModalboxChangelog
            isOpen={isModalboxChangelogOpen}
            onClose={closeModalboxChangelog}
            changelogItems={changelogItems}
          />

          <ModalboxGdpr
            isOpen={this.state.isGdprModalOpen}
            onAccept={this.closeGdprModal}
          />

          <ModalboxInvoiceOverdue
            isOpen={this.state.isOverdueBlocked}
            payNowUrl="/stripe/customer_portal"
            hasMultipleAccounts={this.state.hasMultipleAccounts}
            logoutUrl={getLogoutUrl()}
            jsCookie={Cookies}
            gtmUXPinUserIdCookie={cookieStrings.GTM_UXPIN_USER_ID_COOKIE}
          />

          {this.renderPricing()}
          {this.renderFeatureUpgrade()}

          <div className="baseline" />
        </div>
      </Fragment>
    );
  }
}

export default DragDropContext(HTML5Backend)(Layout);

Layout.propTypes = {
  changelogItems: PropTypes.array,
  loadChangelogItems: PropTypes.func,
  fetchSettings: PropTypes.func,
  getAccountSettings: PropTypes.func,
  getUserData: PropTypes.func,
  getFeatures: PropTypes.func,
  getSlackState: PropTypes.func,
  getMembers: PropTypes.func,
  getRoles: PropTypes.func,
  getStatus: PropTypes.func,
  experimentsPairIds: PropTypes.func,
  isFetchUserDataSuccess: PropTypes.bool,
  isPricingOpen: PropTypes.bool.isRequired,
  openPricing: PropTypes.func.isRequired,
  closePricing: PropTypes.func.isRequired,
  isFeatureUpgradeOpen: PropTypes.bool.isRequired,
  closeFeatureUpgrade: PropTypes.func.isRequired,
  featureUpgradeName: PropTypes.string.isRequired,
  isSearchMode: PropTypes.bool,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
  isTrial: PropTypes.bool,
  currentUserData: PropTypes.object,
  features: PropTypes.object,
  maintenanceSettings: PropTypes.object,
  accountPlan: PropTypes.object,
  settings: PropTypes.object,
  areSettingsFetched: PropTypes.bool,
  closeWelcomeModal: PropTypes.func,
  closeTermsOfServiceModal: PropTypes.func,
  closeBillingSystemChangeModal: PropTypes.func,
  documentsCount: PropTypes.shape({
    in_plan: PropTypes.number,
    used: PropTypes.number,
  }),
  children: PropTypes.any,
  isUpgradeModalOpen: PropTypes.bool,
  upgradeModalData: PropTypes.shape({
    isCloseBlocked: PropTypes.bool,
  }),
  closeUpgradeModal: PropTypes.func,
  documentsCountFetched: PropTypes.bool,
  addUserModalData: PropTypes.object,
  isAddUserModalOpen: PropTypes.bool,
  closeModalboxAddUser: PropTypes.func,
  isModalboxChangelogOpen: PropTypes.bool,
  closeModalboxChangelog: PropTypes.func,
  isModalboxProjectChangeNameOpen: PropTypes.bool,
  modalboxProjectChangeNameData: PropTypes.object,
  closeModalboxProjectChangeName: PropTypes.func,
  isModalboxProjectChangeGroupOpen: PropTypes.bool,
  modalboxProjectChangeGroupData: PropTypes.object,
  closeModalboxProjectChangeGroup: PropTypes.func,
  isModalboxProjectDeleteOpen: PropTypes.bool,
  modalboxProjectDeleteData: PropTypes.object,
  closeModalboxProjectDelete: PropTypes.func,
  projectSettingsData: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  isProjectSettingsOpen: PropTypes.bool,
  closeProjectSettings: PropTypes.func,
  approvalProcessData: PropTypes.object,
  isApprovalProcessOpen: PropTypes.bool,
  closeApprovalProcess: PropTypes.func,
  isModalboxGeneralMessageOpen: PropTypes.bool,
  modalboxGeneralMessage: PropTypes.object,
  isModalboxProjectChangeGroupInfoOpen: PropTypes.bool,
  modalboxProjectChangeGroupInfo: PropTypes.object,
  isDesignLimitReachedModalOpen: PropTypes.bool,
  modalboxDesignLimitReached: PropTypes.object,
  closeDesignLimitReachedModal: PropTypes.func,
  closeGdprModal: PropTypes.func,
  checkShareDocumentByEmailReCaptcha: PropTypes.func,
  shouldRenderReCaptcha: PropTypes.bool,
  showOnboardingPasswordField: PropTypes.bool.isRequired,
  marketing: PropTypes.object,
};

Layout.defaultProps = {
  changelogItems: [],
  loadChangelogItems: noop,
  fetchSettings: noop,
  getAccountSettings: noop,
  getUserData: noop,
  getFeatures: noop,
  getSlackState: noop,
  getMembers: noop,
  getRoles: noop,
  getStatus: noop,
  experimentsPairIds: noop,
  isFetchUserDataSuccess: false,
  isSearchMode: false,
  location: {
    pathname: '',
  },
  isTrial: false,
  currentUserData: {},
  features: {},
  maintenanceSettings: {
    isActive: false,
    id: null,
    startTime: null,
    endTime: null,
  },
  accountPlan: null,
  settings: {},
  areSettingsFetched: false,
  closeWelcomeModal: noop,
  closeTermsOfServiceModal: noop,
  closeBillingSystemChangeModal: noop,
  documentsCount: {
    in_plan: 0,
    used: 0,
  },
  documentsCountFetched: false,
  children: null,
  isUpgradeModalOpen: false,
  upgradeModalData: {
    isCloseBlocked: false,
  },
  closeUpgradeModal: noop,
  addUserModalData: {},
  isAddUserModalOpen: false,
  closeModalboxAddUser: noop,
  isModalboxChangelogOpen: false,
  closeModalboxChangelog: noop,
  isModalboxProjectChangeNameOpen: false,
  modalboxProjectChangeNameData: {},
  closeModalboxProjectChangeName: noop,
  isModalboxProjectChangeGroupOpen: false,
  modalboxProjectChangeGroupData: {},
  closeModalboxProjectChangeGroup: noop,
  isModalboxProjectDeleteOpen: false,
  modalboxProjectDeleteData: {},
  closeModalboxProjectDelete: noop,
  projectSettingsData: {},
  isProjectSettingsOpen: false,
  closeProjectSettings: noop,
  approvalProcessData: {},
  isApprovalProcessOpen: false,
  closeApprovalProcess: noop,
  isModalboxGeneralMessageOpen: false,
  modalboxGeneralMessage: {
    title: '',
    message: '',
  },
  isModalboxProjectChangeGroupInfoOpen: false,
  modalboxProjectChangeGroupInfo: {},
  isDesignLimitReachedModalOpen: false,
  modalboxDesignLimitReached: {},
  closeDesignLimitReachedModal: noop,
  closeGdprModal: noop,
  checkShareDocumentByEmailReCaptcha: noop,
  shouldRenderReCaptcha: false,
  marketing: {},
};
