/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unused-state */

'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import autobind from 'autobind-decorator';
import { isEmpty, map, noop } from 'lodash';
import { triggerTypes } from '@uxpin/shared-components';
import { goTo } from '../../../utils/router';
import DesignSystemsIntroView from './DesignSystemsIntroView';
import { DesignSystemItem } from '../../containers/DesignSystemItem';
import DesignSystemCreateItem from './components/DesignSystemCreateItem';
import { getCreateUri } from '../../../controllers/helpers/designSystems.helper';
import { FEATURES, FEATURE_STATUS } from '../../../constants/features.constants';
import DesignSystemsFooter from './components/DesignSystemsFooter';
import routes from '../../../constants/routes.constants';

const INTRO_VIEW_DATA = {
  FULL_VERSION: {
    wrapperClassNames: 'design-system-screen create-system-screen',
    isEarlyAccessInfoVisible: false,
    buttonText: 'Create a Design System',
  },
  LIMIT_REACHED: {
    wrapperClassNames: 'design-system-screen create-system-screen',
    isEarlyAccessInfoVisible: false,
    buttonText: '',
  },
  EARLY_ACCESS: {
    isEarlyAccessInfoVisible: true,
    buttonClassNames: 'send-event-button',
    buttonText: 'Apply for early access',
  },
};

const FULL_VERSION_FEATURE = FEATURES.DESIGN_SYSTEMS;

export default class DesignSystems extends React.Component {
  constructor(props) {
    super(props);
    let shouldLoadItems = true;

    if (this.isFeatureSet(props) && !this.isFullVersionEnabledInProps(props)) {
      shouldLoadItems = false;
    } else {
      shouldLoadItems = !props.systems.length;
    }

    this.duplicationInProgressTimeout = null;
    this.state = {
      earlyAccessRequested: false,
      systems: isEmpty(props.systems) ? null : props.systems,
      isLoading: shouldLoadItems,
      isFullVersionEnabled: this.isFullVersionEnabledInProps(props),
      openedItemId: null,
      limitHit: this.isLimitHit(props),
      isDuplicationChecking: false,
      isDuringFetchingAccountPlan: false,
    };

    if (shouldLoadItems) {
      props.getDesignSystems();
    }
    props.getDemoTemplate();
  }

  componentWillReceiveProps(nextProps) {
    const newState = {};

    if (this.props.systems !== nextProps.systems) {
      newState.systems = [...nextProps.systems];
      newState.isLoading = false;
    }

    if (this.props.isDuringFetchingAccountPlan !== nextProps.isDuringFetchingAccountPlan) {
      newState.isDuringFetchingAccountPlan = nextProps.isDuringFetchingAccountPlan;
    }

    if (this.props.features[FULL_VERSION_FEATURE] !== nextProps.features[FULL_VERSION_FEATURE]) {
      newState.isFullVersionEnabled = this.isFullVersionEnabledInProps(nextProps);
    }

    newState.limitHit = this.isLimitHit(nextProps);

    if (!isEmpty(newState)) {
      this.setState(newState);
    }

    this.handleDuplication(nextProps);
  }

  componentDidMount() {
    const { params: { limit }, showDesignLimitModal } = this.props;

    this.handleDuplication(this.props);

    if (limit === routes.DESIGN_LIMITS_REACHED) {
      showDesignLimitModal();
      goTo(routes.DESIGN_SYSTEMS);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.duplicationInProgressTimeout);
  }

  isLimitHit(props) {
    if (props.limit === undefined || !props.usedDesignSystems) {
      return false;
    }

    return props.usedDesignSystems >= props.limit;
  }

  isFeatureSet(props) {
    return typeof props.features[FULL_VERSION_FEATURE] !== 'undefined';
  }

  isFullVersionEnabledInProps(props) {
    return props.features[FULL_VERSION_FEATURE] === FEATURE_STATUS.ENABLED;
  }

  @autobind
  handleDuplication(props) {
    const { isDuplicationInProgress } = props;

    if (isDuplicationInProgress && !this.state.isDuplicationChecking) {
      this.setState({
        isDuplicationChecking: true,
      }, this.checkDuplicationStatus);
    }

    if (!isDuplicationInProgress && this.state.isDuplicationChecking) {
      this.setState({
        isDuplicationChecking: false,
      }, () => {
        clearTimeout(this.duplicationInProgressTimeout);
      });
    }
  }

  @autobind
  checkDuplicationStatus() {
    clearTimeout(this.duplicationInProgressTimeout);

    this.duplicationInProgressTimeout = setTimeout(() => {
      this.props.getDesignSystems();
      this.checkDuplicationStatus();
    }, 1500);
  }

  @autobind
  sendDesignSystemsEvent() {
    this.setState({ earlyAccessRequested: true });
  }

  @autobind
  goToCreatePage() {
    this.props.isDesignSystemsLimitReached()
      .then((isHit) => {
        if (isHit) {
          return this.props.showDesignLimitModal();
        }

        goTo(getCreateUri());
      });
  }

  @autobind
  onItemActionCloudOpen(id) {
    this.setState({
      openedItemId: id,
    });
  }

  @autobind
  onItemActionCloudClose() {
    this.setState({
      openedItemId: null,
    });
  }

  renderNoAccess() {
    const { FULL_VERSION } = INTRO_VIEW_DATA;

    return (
      <DesignSystemsIntroView
        wrapperClassNames={FULL_VERSION.wrapperClassNames}
        isEarlyAccessInfoVisible={FULL_VERSION.isEarlyAccessInfoVisible}
        onButtonClick={this.props.showDesignLimitModal}
        buttonClassNames={FULL_VERSION.buttonClassNames}
        buttonText={FULL_VERSION.buttonText}
      />
    );
  }

  @autobind
  renderLimitReached() {
    const { LIMIT_REACHED } = INTRO_VIEW_DATA;
    const isLimitHit = this.isLimitHit(this.props);

    return (
      <DesignSystemsIntroView
        wrapperClassNames={LIMIT_REACHED.wrapperClassNames}
        isEarlyAccessInfoVisible={LIMIT_REACHED.isEarlyAccessInfoVisible}
        onButtonClick={noop}
        buttonClassNames={LIMIT_REACHED.buttonClassNames}
        buttonText={LIMIT_REACHED.buttonText}
        isLimitHit={isLimitHit}
      />
    );
  }

  renderIntro() {
    const { FULL_VERSION } = INTRO_VIEW_DATA;

    return (
      <DesignSystemsIntroView
        wrapperClassNames={FULL_VERSION.wrapperClassNames}
        isEarlyAccessInfoVisible={FULL_VERSION.isEarlyAccessInfoVisible}
        buttonClassNames={FULL_VERSION.buttonClassNames}
        buttonText={FULL_VERSION.buttonText}
        onButtonClick={this.goToCreatePage}>
        <DesignSystemsFooter
          templateData={this.props.templateData}
        />
      </DesignSystemsIntroView>
    );
  }

  renderLoading() {
    return (<section id="design-system-screen" className="loading" />);
  }

  renderItems(systems) {
    const { currentUserData } = this.props;

    return (
      <section id="design-system-screen" className="design-system-screen">
        <div id="design-systems-list">
          <ul className="list">
            <DesignSystemCreateItem
              showDesignLimitModal={this.props.showDesignLimitModal}
              createUri={getCreateUri()}
            />
            {map(systems, (item) => {
              const isItemHovered = this.state.openedItemId === item.id;

              return (
                <DesignSystemItem
                  isItemHovered={isItemHovered}
                  currentUserData={currentUserData}
                  triggerType={triggerTypes.HOVER}
                  key={item.id}
                  item={item}
                  onOpen={() => this.onItemActionCloudOpen(item.id)}
                  onClose={this.onItemActionCloudClose}
                  autoClose
                />
              );
            })}
          </ul>
        </div>
        <DesignSystemsFooter
          templateData={this.props.templateData}
        />
      </section>
    );
  }

  render() {
    const {
      isLoading, systems, isFullVersionEnabled, isDuringFetchingAccountPlan,
    } = this.state;
    const isLimitHit = this.isLimitHit(this.props);

    if (isLoading || isDuringFetchingAccountPlan) {
      return this.renderLoading();
    }

    if (!isFullVersionEnabled) {
      return this.renderNoAccess();
    }

    if (isEmpty(systems) && !isLimitHit) {
      return this.renderIntro();
    }

    if (isEmpty(systems) && isLimitHit) {
      return this.renderLimitReached();
    }

    return this.renderItems(systems);
  }
}

DesignSystems.propTypes = {
  currentUserData: PropTypes.object.isRequired,
  features: PropTypes.object.isRequired,
  getDemoTemplate: PropTypes.func.isRequired,
  getDesignSystems: PropTypes.func.isRequired,
  isDuringFetchingAccountPlan: PropTypes.bool,
  params: PropTypes.object,
  showDesignLimitModal: PropTypes.func.isRequired,
  systems: PropTypes.array.isRequired,
  templateData: PropTypes.object.isRequired,
  isDesignSystemsLimitReached: PropTypes.func.isRequired,
};

DesignSystems.defaultProps = {
  isDuringFetchingAccountPlan: false,
  params: false,
};
