'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '../../Helpers/Tooltip';

export default class Account extends React.Component {
  getAccountInitials() {
    const { data } = this.props;

    if (data && data.name) {
      return data.name.charAt(0);
    }
  }

  render() {
    const { data, className } = this.props;
    const { idAccount, name, avatar } = data;

    return (
      <figure
        className={`account-avatar ${className}`}
        // eslint-disable-next-line react/destructuring-assignment,react/prop-types
        onClick={() => this.props.switchAccount(idAccount)}>
        <img src={avatar} alt={`account-${name}`} />
        <strong className="initials">{this.getAccountInitials()}</strong>
        <Tooltip className="on-right-side">
          {name}
        </Tooltip>
      </figure>
    );
  }
}

Account.propTypes = {
  className: PropTypes.string.isRequired,
  // eslint-disable-next-line react/require-default-props
  data: PropTypes.object.isRequired,
};

Account.defaultProps = {
  // eslint-disable-next-line react/default-props-match-prop-types
  data: {},
};
