/* eslint-disable react/destructuring-assignment */

'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import autobind from 'autobind-decorator';
import { isEmpty, noop, reduce } from 'lodash';
import { Button, DeprecatedInputText, Textarea } from '@uxpin/design-system';
import { GLOBAL_ELEMENTS } from '@uxpin/shared-components';
import { preventDefaultEvent } from '../../../../utils/common';
import { isValidEmail } from '../../../../utils/form';

const FIELD_NAMES = {
  NAME: 'name',
  EMAIL: 'email',
  PHONE: 'phone',
  NOTES: 'notes',
};

const {
  NAME, EMAIL, PHONE, NOTES,
} = FIELD_NAMES;

const FIELDS = {
  [NAME]: {
    id: NAME,
    name: NAME,
    label: 'Name',
    type: 'text',
    required: true,
  },
  [EMAIL]: {
    id: EMAIL,
    name: EMAIL,
    label: 'E-mail',
    type: 'email',
    required: true,
  },
  [PHONE]: {
    id: PHONE,
    name: PHONE,
    label: 'Phone number',
    type: 'text',
    required: true,
  },
  [NOTES]: {
    id: NOTES,
    name: NOTES,
    label: 'Notes',
    required: true,
  },
};

const getFieldsIds = (fieldsData, defaultValue) => (
  reduce(fieldsData, (fields, field) => {
    Object.assign(fields, { [field.id]: defaultValue });
    return fields;
  }, {})
);

const isValidRequired = (value) => !isEmpty(value);
const isFormValid = (fieldFlags) => (
  Object.values(fieldFlags).every((field) => (field === true))
);

export default class ContactRequestFormComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      formValid: false,
      // eslint-disable-next-line react/no-unused-state
      formFilled: false,
      formSubmitted: false,
      formSent: false,
      fieldValues: getFieldsIds(FIELDS, ''),
      fieldValid: getFieldsIds(FIELDS, false),
    };
  }

  componentDidUpdate(prevProps) {
    const { email } = this.props;

    if (prevProps.email !== email) {
      this.updateFieldValue(EMAIL, email);
    }
  }

  isValidRequired(fieldName) {
    return isValidRequired(this.state.fieldValues[fieldName]);
  }

  isValidEmail(fieldName) {
    return isValidEmail(this.state.fieldValues[fieldName]);
  }

  getFormValidation() {
    const { fieldValid } = this.state;

    fieldValid[NAME] = this.isValidRequired(NAME);
    fieldValid[EMAIL] = this.isValidRequired(EMAIL) && this.isValidEmail(EMAIL);
    fieldValid[PHONE] = this.isValidRequired(PHONE);
    fieldValid[NOTES] = this.isValidRequired(NOTES);

    const formValid = isFormValid(fieldValid);

    return {
      fieldValid,
      formValid,
    };
  }

  @autobind
  updateFieldValue(name, value) {
    const { fieldValues, formSubmitted } = this.state;
    let { fieldValid, formValid } = this.state;

    fieldValues[name] = value;

    if (formSubmitted) {
      ({ fieldValid, formValid } = this.getFormValidation());
    }

    this.setState({
      fieldValues,
      fieldValid,
      formValid,
    });
  }

  @autobind
  fieldChange(e) {
    const { name, value } = e.target;

    this.updateFieldValue(name, value);
  }

  @autobind
  handleSuccess() {
    const { sendContactRequest } = this.props;

    sendContactRequest(this.state.fieldValues)
      .then(() => {
        this.setState({
          formSent: true,
        });
      });
  }

  @autobind
  handleSubmit(e) {
    preventDefaultEvent(e);

    const formSubmitted = true;
    const { formValid, fieldValid } = this.getFormValidation();

    this.setState({
      formSubmitted,
      formValid,
      fieldValid,
    });

    if (!formValid) {
      return false;
    }

    return this.handleSuccess();
  }

  renderErrorMessage(fieldName, fieldType = null) {
    const { formSubmitted, fieldValid } = this.state;
    let errorMessage = 'This field is required.';

    if (!formSubmitted || fieldValid[fieldName]) {
      return null;
    }

    if (fieldType === 'email' && !this.isValidEmail(fieldName)) {
      errorMessage = 'Please enter a valid email address.';
    }

    return (
      <span className="error-message">{errorMessage}</span>
    );
  }


  @autobind
  renderInput(field) {
    const { fieldValid, formSubmitted } = this.state;

    return (
      <fieldset className="field" key={`input-${field.id}`}>
        <h4 className={GLOBAL_ELEMENTS.HEADING_4}>
          <label
            className={GLOBAL_ELEMENTS.LABEL}
            htmlFor={field.id}>
            {field.label}
:
          </label>
          <DeprecatedInputText
            id={field.id}
            isError={!fieldValid[field.id] && formSubmitted}
            name={field.name}
            type={field.type}
            required={field.required}
            onChange={this.fieldChange}
            value={this.state.fieldValues[field.id]}
          />
        </h4>
        {this.renderErrorMessage(field.id, field.type)}
      </fieldset>
    );
  }

  renderTextarea(field) {
    const { fieldValid, formSubmitted } = this.state;

    return (
      <fieldset className="field large-style" key={`textarea-${field.id}`}>
        <h4 className={GLOBAL_ELEMENTS.HEADING_4}>
          <label
            className={GLOBAL_ELEMENTS.LABEL}
            htmlFor={field.id}>
            {field.label}
:
          </label>
          <Textarea
            id={field.id}
            isError={!fieldValid[field.id] && formSubmitted}
            name={field.name}
            required={field.required}
            onChange={this.fieldChange}
            value={this.state.fieldValues[field.id]}
          />
        </h4>
        {this.renderErrorMessage(field.id)}
      </fieldset>
    );
  }

  @autobind
  renderFormFields() {
    const fields = [
      this.renderInput(FIELDS.name),
      this.renderInput(FIELDS.email),
      this.renderInput(FIELDS.phone),
      this.renderTextarea(FIELDS.notes),
    ];

    return fields;
  }

  renderButton() {
    const { formValid, formSubmitted, formSent } = this.state;
    const isWorking = formValid && formSubmitted && !formSent;

    return (
      <ul className="buttons">
        <li className="button">
          <Button
            isWorking={isWorking}
            type="submit">
            Send contact request
          </Button>
        </li>
      </ul>
    );
  }

  @autobind
  renderForm() {
    return (
      <form
        className="contact-request-form"
        onSubmit={this.handleSubmit}
        autoComplete="off"
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus
        noValidate>
        {this.renderFormFields()}
        {this.renderButton()}
      </form>
    );
  }

  renderConfirmation() {
    return (
      <div>
        <p>Your request was sent.</p>
        <ul className="buttons">
          <li className="button">
            <Button
              onClick={this.props.closeModal}>
              Close
            </Button>
          </li>
        </ul>
      </div>
    );
  }

  render() {
    const { formSent } = this.state;

    return formSent ? this.renderConfirmation() : this.renderForm();
  }
}

ContactRequestFormComponent.propTypes = {
  email: PropTypes.string,
  sendContactRequest: PropTypes.func,
  closeModal: PropTypes.func,
};

ContactRequestFormComponent.defaultProps = {
  email: '',
  sendContactRequest: noop,
  closeModal: noop,
};
