'use strict';

export const settingsAction = {
  SET_SETTINGS: 'SET_SETTINGS',
  UPDATE_SETTING: 'UPDATE_SETTING',
};

export const settingsSuffix = {
  VERSION: '_version',
  SEEN: '_seen',
  PURCHASED: '_purchased',
  EVENT_SENT: '_event-sent',
};

export const settingsName = {
  PRIVACY_POLICY_0519_SEEN: 'PRIVACY_POLICY_0519_SEEN',
  PRIVACY_POLICY_0620_SEEN: 'PRIVACY_POLICY_0620_SEEN',
  // PHONE_PERSONA_FILLED and PHONE_PERSONA_LATER_AT are not used anymore in our codebase.
  // I left them for reference since we have records in the boarding_answers table in the key
  // column with their value. They were used to handle a modal that requested phone and occupation.
  // https://github.com/UXPin/uxpin-rails/pull/824
  PHONE_PERSONA_FILLED: 'PHONE_PERSONA_FILLED', // not used
  PHONE_PERSONA_LATER_AT: 'PHONE_PERSONA_LATER_AT', // not used
  MERGE_FROM_SUBMITTED: 'MERGE_FROM_SUBMITTED',
  MERGE_TYPEFORM_RESPONSE: 'MERGE_TYPEFORM_RESPONSE',
  STORYBOOK_FROM_SUBMITTED: 'STORYBOOK_FROM_SUBMITTED',
  STORYBOOK_TYPEFORM_RESPONSE: 'STORYBOOK_TYPEFORM_RESPONSE',
};

export const favsFiltersDefault = {
  favsProjects: 'all',
  favsPrototypes: 'all',
};

export const starFilteringTypes = {
  ALL: 'all',
  STARRED: 'starred',
  UNSTARRED: 'unstarred',
};

export const cookieStrings = {
  GTM_TRIAL_STARTED_TRACKED_COOKIE: 'gtm_trial_started_tracked',
  GTM_UXPIN_USER_ID_COOKIE: 'gtmUXPinUserId',
};
