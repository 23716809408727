/* eslint-disable import/prefer-default-export */

import { pricingActions } from '../../constants/pricing.constants';

export function pricingReducer(state = {
  isFeatureUpgradeOpen: false,
  isPricingOpen: false,
  featureUpgradeName: '',
}, action) {
  switch (action.type) {
    case pricingActions.OPEN_PRICING:
      return Object.assign({}, state, {
        isPricingOpen: true,
      });
    case pricingActions.CLOSE_PRICING:
      return Object.assign({}, state, {
        isPricingOpen: false,
      });
    case pricingActions.OPEN_FEATURE_UPGRADE:
      return Object.assign({}, state, {
        isFeatureUpgradeOpen: true,
        featureUpgradeName: action.feature,
      });
    case pricingActions.CLOSE_FEATURE_UPGRADE:
      return Object.assign({}, state, {
        isFeatureUpgradeOpen: false,
        featureUpgradeName: '',
      });
    default:
      return state;
  }
}
