'use strict';

import { connect } from 'react-redux';
import * as currentUser from '../../controllers/actions/currentUser.actions';
import { MultipleAccountsPanel } from '../components/MultipleAccountsPanel/MultipleAccountsPanel';

export const mapStateToProps = (state) => {
  const {
    data, isSwitchAccountSuccess, isError, previousTabUri,
  } = state.currentUser;
  const { features } = state.features;

  return {
    currentUserData: data,
    features,
    isSwitchAccountSuccess,
    isSwitchAccountError: isError,
    previousTabUri,
  };
};

export const mapDispatchToProps = (dispatch) => ({
  switchUserAccount: (idAccount, activeTabUri) => {
    dispatch(currentUser.switchUserAccount(idAccount, activeTabUri));
  },
});

export const MultipleAccounts = connect(mapStateToProps, mapDispatchToProps)(MultipleAccountsPanel);
