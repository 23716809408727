/* eslint-disable react/destructuring-assignment */

import React from 'react';
import PropTypes from 'prop-types';
import autobind from 'autobind-decorator';
import { noop, isEmpty } from 'lodash';
import { Modal } from '@uxpin/shared-components';
import LimitsExceededButtons from './components/ModalboxLimitsExceededButtons';
import {
  modalsIds, limitsExceededTypes, limitsButtonTypes, limitsContentTypes,
} from '../../../constants/modal.constants';
import { accountPlanTypes } from '../../../constants/accountSettings.constants';
import { goTo } from '../../../utils/router';
import { preventDefaultEvent } from '../../../utils/common';
import routes from '../../../constants/routes.constants';


const {
  SINGLE_SEAT_USERS,
  TEAM_USERS,
  SMALL_TEAM_USERS,
  PROTOTYPE_ADD_LIMIT,
  PROTOTYPE_UNARCHIVE_LIMIT,
} = limitsContentTypes;

const {
  MANAGE_USERS,
  SEND_CONTACT_REQUEST,
  UPGRADE,
  CANCEL,
} = limitsButtonTypes;

const { PROTOTYPES, USERS, USERS_PROTOTYPES } = limitsExceededTypes;
const { FREE, PROFESSIONAL, TEAM, SMALL_TEAM, INDIVIDUAL } = accountPlanTypes;

const ESSENTIALS_PLANS_DOCS_LIMIT = 20;

const shouldDisplayModal = (contentType, planType) => {
  const isValidContentType = typeof limitsContentTypes[contentType] !== 'undefined';
  const isValidPlanType = [FREE, PROFESSIONAL, TEAM, INDIVIDUAL, SMALL_TEAM].indexOf(planType.toUpperCase()) > -1;

  return isValidContentType && isValidPlanType;
};


export class ModalboxLimitsExceededComponent extends React.Component {
  constructor(props) {
    super(props);

    this.closeHelper = null;

    this.MODAL = {
      WRAPPER_ID: modalsIds.LIMITS_EXCEEDED,
      CLASS_NAMES: 'ds-limits-modal-box limits-exceeded',
    };

    this.contentType = null;
    this.showModal = false;

    // Modal contents:
    this.header = {
      [SINGLE_SEAT_USERS]: (<h2>Too many users</h2>),
      [TEAM_USERS]: (<h2>Too many users</h2>),
      [SMALL_TEAM_USERS]: (<h2>Too many users</h2>),
      [PROTOTYPE_ADD_LIMIT]: (<h2>Too many prototypes</h2>),
      [PROTOTYPE_UNARCHIVE_LIMIT]: (<h2>Too many active prototypes</h2>),
    };

    /* eslint-disable max-len */
    this.content = {
      [SINGLE_SEAT_USERS]: [
        <p key="text1">The selected plan allows you to have only 1 user.</p>,
        <p key="text2">Need a plan for a bigger team? We’ll help you pick the best option for you. Or manage your users in the user management panel.</p>,
      ],
      [SMALL_TEAM_USERS]: [
        <p key="text1">The selected plan allows you to have only 3 users.</p>,
        <p key="text2">Need a plan for a bigger team? We’ll help you pick the best option for you. You can also manage users in the user management panel.</p>,
      ],
      [TEAM_USERS]: [
        <p key="text1">The selected plan allows you to have only 5 users.</p>,
        <p key="text2">Need a plan for a bigger team? We’ll help you pick the best option for you. You can also manage users in the user management panel.</p>,
      ],
      [PROTOTYPE_ADD_LIMIT]: [
        <p key="text1">You can only have 2 prototypes on your current plan. Upgrade your account now and enjoy access to unlimited prototypes.</p>,
      ],
      PROTOTYPE_ADD_LIMIT_20: [
        <p key="text1">You can only have 20 prototypes on your current plan. Upgrade your account now and enjoy access to unlimited prototypes.</p>,
      ],
      [PROTOTYPE_UNARCHIVE_LIMIT]: [
        <p key="text1">To unarchive this project, please remove or archive an active prototype first.</p>,
        <p key="text2">Or upgrade your account to design without any prototype limits.</p>,
      ],
    };
    /* eslint-enable max-len */

    this.buttons = {
      [SINGLE_SEAT_USERS]: {
        primary: SEND_CONTACT_REQUEST,
        secondary: MANAGE_USERS,
      },
      [TEAM_USERS]: {
        primary: SEND_CONTACT_REQUEST,
        secondary: MANAGE_USERS,
      },
      [SMALL_TEAM_USERS]: {
        primary: SEND_CONTACT_REQUEST,
        secondary: MANAGE_USERS,
      },
      [PROTOTYPE_ADD_LIMIT]: {
        primary: UPGRADE,
        secondary: CANCEL,
      },
      [PROTOTYPE_UNARCHIVE_LIMIT]: {
        primary: UPGRADE,
        secondary: CANCEL,
      },
    };

    this.events = {
      [PROTOTYPES]: 'Prototypes',
      [USERS]: 'Users',
      [USERS_PROTOTYPES]: 'Both',
    };
  }

  componentDidUpdate() {
    const { isOpen, isDataFetched, data } = this.props;
    const { chosenPlan, forcedContentType } = data;
    const canBeUpdated = isDataFetched && !this.showModal;

    if (!isOpen) {
      return;
    }

    if (canBeUpdated) {
      // forcedContentType (used internally in dashboard) should
      // be handled even if other data is not provided
      this.contentType = forcedContentType || this.getContentType();
      this.showModal = !isEmpty(forcedContentType) || shouldDisplayModal(this.contentType, chosenPlan);

      if (!this.showModal) {
        this.closeModal();
      }

      this.forceUpdate();
    }
  }

  getContentType() {
    const { data, usedSeats } = this.props;
    const { limitType, chosenPlan, forcedContentType } = data;

    if (!isEmpty(forcedContentType)) {
      return forcedContentType;
    }

    let contentType = null;

    if ([USERS_PROTOTYPES, USERS].indexOf(limitType) > -1) {
      const targetPlan = chosenPlan.toUpperCase();
      const oneSeatPlans = [PROFESSIONAL, INDIVIDUAL];
      const targetOneSeatPlan = oneSeatPlans.indexOf(targetPlan) > -1;
      const targetTeamPlan = targetPlan === TEAM;
      const targetSmallTeamPlan = targetPlan === SMALL_TEAM;

      // subscribing to single seat plan with multiple users:
      if (usedSeats > 1 && targetOneSeatPlan) {
        contentType = SINGLE_SEAT_USERS; // users > 1, target one seat

        // subscribing to team with more than 5 users:
      } else if (usedSeats > 5 && targetTeamPlan) {
        contentType = TEAM_USERS; // users > 5, target team

        // subscribing to small team with more than 3 users:
      } else if (usedSeats > 3 && targetSmallTeamPlan) {
        contentType = SMALL_TEAM_USERS; // users > 3, target small team
      }
    }

    return contentType;
  }

  @autobind
  primaryAction(e) {
    preventDefaultEvent(e);
    this.props.openUpgradeModal();
    this.closeModal();
  }

  @autobind
  goToProjects(e) {
    preventDefaultEvent(e);
    goTo(routes.PROJECTS);
    this.closeModal();
  }

  @autobind
  closeModal(e) {
    preventDefaultEvent(e);
    this.props.onClose(e);

    this.contentType = null;
    this.showModal = false;

    if (window.location.href.match(`/${routes.LIMITS_EXCEEDED}/`)) {
      goTo(routes.PROJECTS);
    }

    if (this.closeHelper) {
      this.closeHelper.hideModal();
    }
  }

  @autobind
  renderHeader() {
    return this.header[this.contentType] || null;
  }

  renderContent(contentType) {
    if (contentType === PROTOTYPE_ADD_LIMIT && this.props.planDocumentLimit === ESSENTIALS_PLANS_DOCS_LIMIT) {
      return this.content.PROTOTYPE_ADD_LIMIT_20 || null;
    }
    return this.content[contentType] || null;
  }

  @autobind
  renderButtons(contentType) {
    if (isEmpty(contentType)) {
      return null;
    }

    const { primary, secondary } = this.buttons[contentType];
    const { openUpgradeModal, openContactRequestModal } = this.props;

    return (
      <LimitsExceededButtons
        closeModal={this.closeModal}
        openUpgradeModal={openUpgradeModal}
        openContactRequestModal={openContactRequestModal}
        primaryButton={primary}
        secondaryButton={secondary}
      />
    );
  }

  render() {
    const { isOpen } = this.props;
    const showModal = isOpen && this.showModal;

    if (!showModal) {
      return null;
    }

    return (
      <Modal
        ref={(c) => { this.closeHelper = c; }}
        wrapperId={this.MODAL.WRAPPER_ID}
        classNames={this.MODAL.CLASS_NAMES}
        renderTitle={this.renderHeader}
        isOpen={showModal}
        onClose={this.closeModal}>
        <div className="limits-content">
          {this.renderContent(this.contentType)}
          {this.renderButtons(this.contentType)}
        </div>
      </Modal>
    );
  }
}

ModalboxLimitsExceededComponent.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  data: PropTypes.shape({
    limitType: PropTypes.string,
    chosenPlan: PropTypes.string,
    forcedContentType: PropTypes.string,
  }),
  openUpgradeModal: PropTypes.func,
  openContactRequestModal: PropTypes.func,
  isDataFetched: PropTypes.bool,
  usedSeats: PropTypes.number,
  planDocumentLimit: PropTypes.number,
};

ModalboxLimitsExceededComponent.defaultProps = {
  isOpen: false,
  onClose: noop,
  data: {
    limitType: '',
    chosenPlan: '',
    forcedContentType: '',
  },
  openUpgradeModal: noop,
  openContactRequestModal: noop,
  isDataFetched: false,
  planDocumentLimit: 2,
  usedSeats: 0,
};
