/* eslint-disable max-len */

'use strict';

import React from 'react';

// eslint-disable-next-line react/prefer-stateless-function
export default class TabNotifications extends React.Component {
  render() {
    return (
      // eslint-disable-next-line react/destructuring-assignment,react/prop-types
      <section className={this.props.classNames} id="e-mail-notifications">
        <h3>E-Mail Notifications</h3>
        <fieldset>
          <input id="unique-id-x-01" className="state-helper" type="checkbox" />
          <label htmlFor="unique-id-x-01" className="fake-choose-label fake-checkbox">Send me realtime notifications about new comments for:</label>
          <select>
            <option>All Projects</option>
            <option>1 Project</option>
            <option>3 Projects</option>
            <option>5 Projects</option>
          </select>
        </fieldset>
        <fieldset>
          <input id="unique-id-x-02" className="state-helper" type="checkbox" />
          <label htmlFor="unique-id-x-02" className="fake-choose-label fake-checkbox">Send me realtime notifications about changes of status for:</label>
          <select>
            <option>All Projects</option>
            <option>1 Project</option>
            <option>3 Projects</option>
            <option>5 Projects</option>
          </select>
        </fieldset>
        <fieldset>
          <input id="unique-id-x-03" className="state-helper" type="checkbox" />
          <label htmlFor="unique-id-x-03" className="fake-choose-label fake-checkbox">Send me realtime notifications about changes in approval process:</label>
          <select>
            <option>All Projects</option>
            <option>1 Project</option>
            <option>3 Projects</option>
            <option>5 Projects</option>
          </select>
        </fieldset>
        <fieldset>
          <input id="unique-id-x-04" className="state-helper" type="checkbox" />
          <label htmlFor="unique-id-x-04" className="fake-choose-label fake-checkbox">Send me a daily digest email about activity across all projects I am involved in.</label>
        </fieldset>
      </section>
    );
  }
}
