/* eslint-disable react/prop-types */

'use strict';

import React from 'react';
import autobind from 'autobind-decorator';
import keys from '../../../../constants/keys.constants';

export default class StepNew extends React.Component {
  @autobind
  saveStage(e) {
    const { saveStage, index, data } = this.props;
    const value = this.refs.input.value.trim();
    if (e.keyCode === keys.ENTER && value.length > 0) {
      saveStage(value, data.isBeingUpdated ? index : null);
    }
  }

  @autobind
  onBlur() {
    const { saveStage, index, data } = this.props;
    const value = this.refs.input.value.trim();
    if ((data.isBeingAdded || data.isBeingUpdated) && value.length) {
      saveStage(value, data.isBeingUpdated ? index : null);
    } else if (data.isBeingUpdated) {
      this.refs.input.focus();
    }
  }

  render() {
    const { data } = this.props;
    return (
      <section className="item-wrapper item-new">
        <article className="item-data">
          <input
            className="new-item-input focus-helper"
            type="text"
            ref="input"
            onBlur={this.onBlur}
            defaultValue={data.name}
            onKeyDown={this.saveStage}
            placeholder="Enter step name"
          />
          <label>Enter to save</label>
        </article>
      </section>
    );
  }
}
