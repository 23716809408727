import { connect } from 'react-redux';
import * as currentUser from '../../controllers/actions/currentUser.actions';
import InactiveAccountComponent from '../components/Layout/routes/InactiveAccount';

export const mapStateToProps = (state) => ({
  currentUserData: state.currentUser.data,
});

export const mapDispatchToProps = (dispatch) => ({
  getUserData: () => {
    dispatch(currentUser.getUserData());
  },
});

export const InactiveAccount = connect(mapStateToProps, mapDispatchToProps)(InactiveAccountComponent);
