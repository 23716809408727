/* eslint-disable react/button-has-type,jsx-a11y/alt-text */

'use strict';

import React from 'react';

// eslint-disable-next-line react/prefer-stateless-function
export default class TabGeneral extends React.Component {
  render() {
    return (
      // eslint-disable-next-line react/destructuring-assignment,react/prop-types
      <section className={this.props.classNames} id="general-billing">
        <div className="column">
          <h3>General</h3>
          <h4>Organization Name</h4>
          <div className="row">
            <input type="text" required />
            <button className="btn-ghost">Save</button>
          </div>
          <a href="#assign-admins" className="underline-link gray-link assign-link">Assign admins in user management</a>
          <h4>Your plan</h4>
          <p className="plan-info">
            <strong>Pro Monthly</strong>
            {' '}
            for 3 users ($29 x 3. Total: 87/m)
          </p>
          <div className="row">
            <button className="btn-ghost gray">Change plan</button>
            <em>
              Team plan offers free flow documentation, Typekit integration and more...
            </em>
          </div>
        </div>
        <div className="column">
          <h3>Billing</h3>
          <h4>Information</h4>
          <div className="links-group">
            <a href="#change-billing" className="blue-link underline-link">Change billing information</a>
            <a href="#update-payment" className="blue-link underline-link">Update payment information</a>
            <a href="#set-billing-e-mail" className="blue-link underline-link">Set billing e-mail</a>
          </div>
          <div className="company-logo">
            <figure className="avatar">
              <img src="" />
              <figcaption>IMG</figcaption>
            </figure>
            <div className="links-group">
              <a href="#upload-avatar" className="underline-link gray-link">Upload avatar</a>
              <em>1.5mb max. PNG, JPG - accepted</em>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
