/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import autobind from 'autobind-decorator';
import {
  Cloud, cloudPositions, DesignSystemUtils, isChrome, isSafari,
} from '@uxpin/shared-components';
import { InlineEdit } from '@uxpin/design-system';
import { getViewUri, getEditUri, canEditSystem } from '../../../../controllers/helpers/designSystems.helper';
import { STATUS } from '../../../../constants/designSystems.constants';
import ColorCover from './ColorCover';

const shouldWrap = isChrome || isSafari;
const DOUBLE_CLICK_DELAY = 250;
let timer;

export class DesignSystemItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      canEdit: false,
      isEditing: false,
      isWorking: false,
    };
  }

  componentWillMount() {
    const { currentUserData: { id }, item } = this.props;
    const canEdit = canEditSystem(item, id);

    this.handleWorkingState(this.props);
    this.setState({ canEdit });
  }

  componentWillReceiveProps(nextProps) {
    this.handleWorkingState(nextProps);
  }

  getHref() {
    const { item: { libraryHash } } = this.props;

    return getViewUri(libraryHash);
  }

  handleWorkingState(props) {
    const { status } = props.item;
    const isWorking = status === STATUS.DUPLICATING;

    if (isWorking !== this.state.isWorking) {
      this.setState({
        isWorking,
      });
    }
  }

  @autobind
  handleDelete(e) {
    const { item, openModalboxDesignSystemDelete } = this.props;
    e.preventDefault();
    openModalboxDesignSystemDelete(item);
  }

  @autobind
  handleEditPermissions(e) {
    const { item, openModalboxDesignSystemPermissions } = this.props;
    e.preventDefault();
    openModalboxDesignSystemPermissions(item);
  }

  @autobind
  handleClick(event) {
    event.preventDefault();

    if (timer) {
      clearTimeout(timer);
    }

    timer = setTimeout(() => {
      window.location.href = this.getHref();
    }, DOUBLE_CLICK_DELAY);
  }

  @autobind
  handleDoubleClick() {
    if (timer) {
      clearTimeout(timer);
    }
  }

  @autobind
  handleEditStart() {
    this.setState({
      isEditing: true,
    });
  }

  @autobind
  handleEditFinish() {
    this.setState({
      isEditing: false,
    });
  }

  @autobind
  saveName(name) {
    const { item, updateLibraryName } = this.props;

    updateLibraryName(item.idLibrary, { name });
  }

  render() {
    const {
      cloud,
      isItemHovered,
      item,
      trigger,
    } = this.props;
    const { name, libraryHash, libraryColors } = item;
    const { canEdit } = this.state;
    const itemClassNames = classNames('list-item', {
      selected: isItemHovered,
      'is-working': this.state.isWorking,
    });
    const linkClassName = classNames('gray-link', {
      inactive: this.state.isEditing,
    });

    return (
      <li className={itemClassNames}>
        { !this.state.isWorking && (
          <a
            className={linkClassName}
            href={this.getHref()}
            onClick={this.handleClick}
            onDoubleClick={this.handleDoubleClick}>
            <ColorCover colors={libraryColors} />
            <h3 className="design-system-name">
              <InlineEdit
                title={name}
                isDisabled={!canEdit}
                modifierName="with-clamp"
                onChange={this.saveName}
                onEditStart={this.handleEditStart}
                onEditFinish={this.handleEditFinish}
                contentWrapped={shouldWrap}>
                {name}
              </InlineEdit>
            </h3>
          </a>
        )}

        { canEdit && !this.state.isWorking && (
          <div>
            <span className="more-options icon-general-dots only-icon-font" {...trigger}>More options</span>
            <ul {...cloud} className="cloud options-cloud auto-width">
              <li>
                <a href={getEditUri(libraryHash)}>Edit</a>
              </li>
              <li>
                <a href="#design-system-permissions" onClick={this.handleEditPermissions}>Permissions</a>
              </li>
              {!DesignSystemUtils.isCodeSyncLibrary(item) && (
                <li>
                  <a href="#delete" className="danger custom-color" onClick={this.handleDelete}>Delete</a>
                </li>
              )}
            </ul>
          </div>
        )}

        { this.state.isWorking && (
          <span className="pending-info">Creating...</span>
        )}
      </li>
    );
  }
}

DesignSystemItem.propTypes = {
  currentUserData: PropTypes.object.isRequired,
  isItemHovered: PropTypes.bool.isRequired,
  item: PropTypes.shape({
    editScope: PropTypes.shape({
      scopeGroup: PropTypes.string.isRequired,
      selectedUsers: PropTypes.array.isRequired,
    }).isRequired,
    id: PropTypes.number.isRequired,
    idUser: PropTypes.number.isRequired,
    libraryColors: PropTypes.array.isRequired,
    libraryHash: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
  }).isRequired,
  openModalboxDesignSystemDelete: PropTypes.func.isRequired,
  openModalboxDesignSystemPermissions: PropTypes.func.isRequired,
  updateLibraryName: PropTypes.func.isRequired,
};

export default Cloud({
  availablePositions: [cloudPositions.TOP, cloudPositions.BOTTOM],
  offset: {
    bottom: { x: 0, y: 10 },
    top: { x: 0, y: -10 },
  },
})(DesignSystemItem);
