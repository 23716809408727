/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */

'use strict';

import React from 'react';
import { Modal } from '@uxpin/shared-components';
import autobind from 'autobind-decorator';
import classnames from 'classnames';
import _ from 'lodash';
import Form from '../../Helpers/validation/Form';
import Input from '../../Helpers/validation/Input';

export default class ModalboxAddStakeholder extends React.Component {
  constructor(props) {
    super(props);
    this.MODAL = {
      WRAPPER_ID: 'add-stakeholder-modal-box',
      CLASS_NAMES: 'no-transition',
    };
  }

  @autobind
  onOpen() {
    this.customForm = {};
  }

  componentWillReceiveProps(nextProps) {
    // eslint-disable-next-line max-len
    if (this.props.isStakeholderBeingModified && !nextProps.isStakeholderBeingModified && !nextProps.isStakeholderModificationError) {
      this.onClose();
    }
  }

  renderError() {
    const { isStakeholderModificationError } = this.props;

    if (isStakeholderModificationError) {
      return (
        <li>
          <p className="error">Something went wrong!</p>
        </li>
      );
    }
  }

  getBtnClasses() {
    return classnames('btn-solid', 'btn-wide', { working: this.props.isStakeholderBeingModified });
  }

  renderSubmitBtn() {
    const submitButtonLabel = this.props.stakeholder ? 'Update stakeholder' : 'Add stakeholder';

    // eslint-disable-next-line react/button-has-type
    return <button onClick={this.submitForm} className={this.getBtnClasses()}>{ submitButtonLabel }</button>;
  }

  @autobind
  submitForm() {
    this.customForm.handleSubmit();
  }

  @autobind
  handleSuccess(data) {
    const idApproval = this.props.document.idApproval;
    if (this.props.stakeholder) {
      this.props.updateStakeholder(idApproval, this.props.stakeholder.id, data);
    } else {
      this.props.addStakeholder(idApproval, data);
    }
  }

  @autobind
  onClose(e) {
    if (_.isObject(e) && _.isFunction(e.preventDefault)) {
      e.preventDefault();
    }

    const { clearError, onClose } = this.props;
    clearError();
    onClose();
  }

  render() {
    const { stakeholder } = this.props;
    const data = stakeholder || {};
    const title = !stakeholder ? 'Add a stakeholder' : 'Update a stakeholder';
    return (
      <Modal
        wrapperId={this.MODAL.WRAPPER_ID}
        classNames={this.MODAL.CLASS_NAMES}
        title={title}
        isOpen={this.props.isOpen}
        onClose={this.onClose}>
        <section className="modal-box-content">
          <Form
            // eslint-disable-next-line no-return-assign
            ref={(el) => this.customForm = el}
            checkMode="onBlur"
            customSubmitBtn
            onSuccess={this.handleSuccess}
            autoComplete="off"
            autofocus>
            <fieldset className="half-width">
              <h4><label htmlFor="firstname">First name</label></h4>
              <Input defaultValue={data.firstname} id="firstname" name="firstname" validations="ltrim" required />
            </fieldset>
            <fieldset className="half-width">
              <h4><label htmlFor="lastname">Last name</label></h4>
              <Input defaultValue={data.lastname} id="lastname" name="lastname" validations="ltrim" required />
            </fieldset>
            <fieldset>
              <h4><label htmlFor="email">Email</label></h4>
              <Input defaultValue={data.email} id="email" name="email" validations="isEmail" required />
            </fieldset>
            <fieldset className="half-width">
              <h4>
                <label htmlFor="title">
Title
                  <em>(optional)</em>
                </label>
              </h4>
              <Input defaultValue={data.title} id="title" name="title" validations="ltrim" />
            </fieldset>
            <fieldset className="half-width">
              <h4>
                <label htmlFor="company">
Company
                  <em>(optional)</em>
                </label>
              </h4>
              <Input defaultValue={data.company} id="company" name="company" validations="ltrim" />
            </fieldset>
          </Form>
        </section>
        <footer>
          <ul className="btns-group">
            {this.renderError()}
            <li>
              <a href="#cancel" onClick={this.onClose} className="gray-style cancel-button action-close">
                Cancel
              </a>
            </li>
            <li>
              {this.renderSubmitBtn()}
            </li>
          </ul>
        </footer>
      </Modal>
    );
  }
}
