'use strict';

import { slackActions } from '../../constants/slack.constants';

export function slackReducer(state = {
  channels: [],
  selectedChannel: '',
  slackState: {},
  isError: false,
  isMessageSending: false,
  isMessageSendingError: false,
  isChannelsListLoading: false,
}, action) {
  switch (action.type) {
    case slackActions.SLACK_MESSAGE_HAVE_BEEN_SENT:
      return Object.assign({}, state, {
        isMessageSending: false,
      });
    case slackActions.REQUEST_SLACK_MESSAGE_SENDING:
      return Object.assign({}, state, {
        isMessageSending: true,
        isMessageSendingError: false,
      });
    case slackActions.SET_SLACK_CHANNEL:
      return Object.assign({}, state, {
        selectedChannel: action.channel,
      });
    case slackActions.FETCH_SLACK_STATE:
      return Object.assign({}, state, {
        slackState: action.state,
      });
    case slackActions.SLACK_ERROR:
      return Object.assign({}, state, {
        isError: true,
      });
    case slackActions.SLACK_SENDING_ERROR:
      return Object.assign({}, state, {
        isMessageSendingError: true,
      });
    case slackActions.REQUEST_FETCHING_SLACK_CHANNELS:
      return Object.assign({}, state, {
        isChannelsListLoading: true,
        isError: false,
      });
    case slackActions.FETCH_SLACK_CHANNELS:
      return Object.assign({}, state, {
        channels: action.data.channels,
        selectedChannel: action.data.selected,
        isChannelsListLoading: false,
      });
    default:
      return state;
  }
}
