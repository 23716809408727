/* eslint-disable max-len,react/no-unescaped-entities */

'use strict';

import React from 'react';

// eslint-disable-next-line react/prefer-stateless-function
export default class TabProjectStatus extends React.Component {
  render() {
    return (
      // eslint-disable-next-line react/destructuring-assignment,react/prop-types
      <section className={this.props.classNames} id="project-status">
        <h3>Project status</h3>
        <p>
          Turn off/on project status for your projects.
        </p>
        <div className="column">
          <div className="overflow-helper">
            <div className="row">
              <span>Dashboard MPP</span>
              <fieldset>
                <input id="unique-id-1" className="state-helper" type="checkbox" />
                <label htmlFor="unique-id-1" className="fake-switch">Turn on/off</label>
              </fieldset>
            </div>
            <div className="row">
              <span>Editor MPP</span>
              <fieldset>
                <input id="unique-id-2" className="state-helper" type="checkbox" />
                <label htmlFor="unique-id-2" className="fake-switch">Turn on/off</label>
              </fieldset>
            </div>
            <div className="row">
              <span>Landing Page</span>
              <fieldset>
                <input id="unique-id-3" className="state-helper" type="checkbox" />
                <label htmlFor="unique-id-3" className="fake-switch">Turn on/off</label>
              </fieldset>
            </div>
            <div className="row">
              <span>eBook</span>
              <fieldset>
                <input id="unique-id-4" className="state-helper" type="checkbox" />
                <label htmlFor="unique-id-4" className="fake-switch">Turn on/off</label>
              </fieldset>
            </div>
            <div className="row">
              <span>Mailing Demo</span>
              <fieldset>
                <input id="unique-id-5" className="state-helper" type="checkbox" />
                <label htmlFor="unique-id-5" className="fake-switch">Turn on/off</label>
              </fieldset>
            </div>
            <div className="row">
              <span>Enterprise LP</span>
              <fieldset>
                <input id="unique-id-6" className="state-helper" type="checkbox" />
                <label htmlFor="unique-id-6" className="fake-switch">Turn on/off</label>
              </fieldset>
            </div>
            <div className="row">
              <span>Enterprise</span>
              <fieldset>
                <input id="unique-id-7" className="state-helper" type="checkbox" />
                <label htmlFor="unique-id-7" className="fake-switch">Turn on/off</label>
              </fieldset>
            </div>
          </div>
        </div>
        <div className="column">
          <fieldset>
            <input id="dont-show-for-private" className="state-helper" type="checkbox" />
            <label htmlFor="dont-show-for-private" className="fake-choose-label fake-checkbox">Don't show project status for my private projects</label>
          </fieldset>
          <fieldset>
            <input id="dont-show-for-new" className="state-helper" type="checkbox" />
            <label htmlFor="dont-show-for-new" className="fake-choose-label fake-checkbox">Turn off Project status for all new projects</label>
          </fieldset>
        </div>
      </section>
    );
  }
}
