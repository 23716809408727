'use strict';

import { connect } from 'react-redux';
import { InvoiceOverdueComponent } from '../../components/WarningBars/InvoiceOverdueBar';

export const mapStateToProps = () => ({});

export const mapDispatchToProps = () => ({});

export const InvoiceOverdueBar = connect(mapStateToProps, mapDispatchToProps)(InvoiceOverdueComponent);
