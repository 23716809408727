import React from 'react';
import PropTypes from 'prop-types';
import MergeAddNewLibrary from '../../../../../images/merge-info/MergeAddNewLibrary.svg';

const MergeCreateItem = (props) => {
  const { handleCreateNewLibrary } = props;

  return (
    <li className="list-item create-design-system-item">
      <a className="gray-link" onClick={handleCreateNewLibrary}>
        <img
          className="merge-create-icon"
          src={MergeAddNewLibrary}
          alt="Create merge library"
        />
        <span className="create-text">Add new library</span>
      </a>
    </li>
  );
};

MergeCreateItem.propTypes = {
  handleCreateNewLibrary: PropTypes.func.isRequired,
};

export default MergeCreateItem;
