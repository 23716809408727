/* eslint-disable jsx-a11y/iframe-has-title */

'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@uxpin/shared-components';
import autobind from 'autobind-decorator';
import classNames from 'classnames';
import config from 'react-global-configuration';
import { noop } from 'lodash';
import { modalsIds } from '../../../../constants/modal.constants';

const APP_URL = config.get('APP_URL');

export default class ModalboxProUpsell extends React.Component {
  constructor(props) {
    super(props);

    this.MODAL = {
      WRAPPER_ID: modalsIds.PRO_UPSELL,
      CLASS_NAMES: ['iframe-modal-box', 'campaign-splash'],
      URL: `${APP_URL}/dms/DashboardModals/ProUpsellCampaignSplash/?source=dashboard&withoutCloseIcon=true`,
    };

    this.state = {
      isLoading: false,
    };
  }

  componentDidUpdate() {
    const {
      modalSeen, areSettingsFetched, isOpen, isOpenFromUrl, saveAsSeen,
    } = this.props;
    const isModalOpen = isOpen || isOpenFromUrl;

    if (areSettingsFetched && !modalSeen && isModalOpen) {
      saveAsSeen();
    }
  }

  getModalClasses() {
    // eslint-disable-next-line react/destructuring-assignment
    return classNames('modal-box-content', { loading: this.state.isLoading });
  }

  getClassNames() {
    return classNames(this.MODAL.CLASS_NAMES, 'full-screen-modal-box');
  }

  @autobind
  onOpen() {
    this.setState({
      isLoading: true,
    });
  }

  @autobind
  onLoad() {
    this.setState({
      isLoading: false,
    });
  }

  @autobind
  onModalClose() {
    const { onClose, onCloseFromUrl } = this.props;

    onClose();
    onCloseFromUrl();
  }

  render() {
    const { isOpen, isOpenFromUrl } = this.props;
    const isModalOpen = isOpen || isOpenFromUrl;

    if (!isModalOpen) {
      return null;
    }

    return (
      <Modal
        wrapperId={this.MODAL.WRAPPER_ID}
        classNames={this.getClassNames()}
        title={this.MODAL.TITLE}
        isOpen={isModalOpen}
        onOpen={this.onOpen}
        onClose={this.onModalClose}>
        <section className={this.getModalClasses()}>
          <iframe
            src={this.MODAL.URL}
            onLoad={this.onLoad}
            frameBorder="0"
          />
        </section>
      </Modal>
    );
  }
}

ModalboxProUpsell.propTypes = {
  isOpen: PropTypes.bool,
  isOpenFromUrl: PropTypes.bool,
  modalSeen: PropTypes.bool.isRequired,
  areSettingsFetched: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onCloseFromUrl: PropTypes.func,
  saveAsSeen: PropTypes.func.isRequired,
};

ModalboxProUpsell.defaultProps = {
  isOpen: false,
  isOpenFromUrl: false,
  onClose: noop,
  onCloseFromUrl: noop,
};
