import { connect } from 'react-redux';
import { ModalboxLimitsExceededComponent } from '../components/Modalboxes/ModalboxLimitsExceeded';
import { openUpgradeModal } from '../../controllers/actions/upgradeModal.actions';
import { modalsIds } from '../../constants/modal.constants';
import { openModal, closeModal } from '../../controllers/actions/modal.actions';

export const mapStateToProps = (state) => {
  const { data: modalData, openedModalsIds } = state.modal;
  const { accountPlan } = state.accountSettings;

  const planName = (accountPlan && accountPlan.name) || null;
  const planDocumentLimit = accountPlan?.documents_limit || null;
  const usedSeats = accountPlan ? accountPlan.usedSeats : null;
  const isOpen = !!openedModalsIds[modalsIds.LIMITS_EXCEEDED];
  const isDataFetched = accountPlan !== null;

  return {
    isOpen,
    data: modalData[modalsIds.LIMITS_EXCEEDED] || {},
    planName,
    usedSeats,
    isTrial: accountPlan && accountPlan.is_trial,
    isDataFetched,
    planDocumentLimit,
  };
};

export const mapDispatchToProps = (dispatch) => ({
  openUpgradeModal: () => {
    dispatch(openUpgradeModal());
  },
  openContactRequestModal: () => {
    dispatch(openModal(modalsIds.MODALBOX_CONTACT_REQUEST));
  },
  onClose: () => {
    dispatch(closeModal(modalsIds.LIMITS_EXCEEDED));
  },
});

export const ModalboxLimitsExceeded = connect(mapStateToProps, mapDispatchToProps)(ModalboxLimitsExceededComponent);
