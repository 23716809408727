'use strict';

import { upgradeModalActions, upgradeModalSpecModePromoActions } from '../../constants/upgradeModal.constants';
import { pricingActions } from '../../constants/pricing.constants';

export const openUpgradeModal = () => ({
  type: pricingActions.OPEN_PRICING,
});

export const closeUpgradeModal = () => ({
  type: upgradeModalActions.CLOSE_UPGRADE_MODAL,
});

export const openSpecModePromoUpgradeModal = () => ({
  type: upgradeModalSpecModePromoActions.OPEN_SPECMODE_PROMO_UPGRADE_MODAL,
});

export const closeSpecModePromoUpgradeModal = () => ({
  type: upgradeModalSpecModePromoActions.CLOSE_SPECMODE_PROMO_UPGRADE_MODAL,
});
