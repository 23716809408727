'use strict';

import { connect } from 'react-redux';
import { search } from '../../controllers/actions/search.actions';
import SearchBtnComponent from '../components/Search/components/SearchBtn';

export const mapStateToProps = (state) => {
  const { isSearchMode } = state.search;

  return {
    isSearchMode,
  };
};

export const mapDispatchToProps = (dispatch) => ({
  enterSearchMode: () => {
    dispatch(search.enterSearchMode());
  },

  exitSearchMode: () => {
    dispatch(search.exitSearchMode());
  },
});

export const SearchBtn = connect(mapStateToProps, mapDispatchToProps)(SearchBtnComponent);
