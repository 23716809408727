'use strict';

import { accountSettingsActions } from '../../constants/accountSettings.constants';
import { modalActions } from '../../constants/modal.constants';
import { appRequest, request } from '../../utils/request';
import { onboardingAddUsersActions } from '../../constants/onboarding.constants';

const UXPIN_API_TOKEN_CLIENT = '7815696ecbf1c96e6894b779456d330e';

export const savePartialLeadForm = (formData) => (dispatch) => request.post('/account/savePartialLeadForm', {
  body: formData,
})
  .then((data) => {
    if (data.result) {
      dispatch({
        type: accountSettingsActions.FETCH_ACCOUNT_PROSPECT_DATA,
        data: formData,
      });
      dispatch({
        type: modalActions.SUBMIT_LEAD_FORM,
        data,
      });
    }

    return data;
  })
  .catch((e) => {
    console.log(e); // eslint-disable-line no-console
  });

// transform data to match API requirements
function prepareMemberFormData(formData) {
  return formData.map((email, index) => ({
    id: index,
    firstname: '',
    lastname: '',
    email,
    role: 2,
  }));
}

export const addUsersOnboarding = (formData) => (dispatch) => {
  dispatch({ type: onboardingAddUsersActions.ONBOARDING_ADD_USERS_REQUEST });

  return appRequest.put(`/mod/API/Members/?token=${UXPIN_API_TOKEN_CLIENT}`, {
    body: prepareMemberFormData(formData),
  })
    .then((data) => {
      dispatch({
        type: onboardingAddUsersActions.ONBOARDING_ADD_USERS_SUCCESS,
        payload: data,
      });
      return data;
    })
    .catch((error) => {
      dispatch({
        type: onboardingAddUsersActions.ONBOARDING_ADD_USERS_FAILURE,
        payload: error.message || 'An error occurred while adding users.',
      });
      console.log(error); // eslint-disable-line no-console
    });
};
