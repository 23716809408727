'use strict';

import { projectsActions } from '../../constants/projects.constants';

export function projectActionsReducer(state = {
  removingCounters: {},
  currentProjectsActions: {},
  errors: {},
}, action) {
  let removingCounters;
  let projectActions;
  let errors;
  switch (action.type) {
    case projectsActions.INIT_REMOVING_COUNTER:
      removingCounters = Object.assign({}, state.removingCounters);
      removingCounters[action.idProject] = 20;
      return Object.assign({}, state, { removingCounters });
    case projectsActions.CLEAR_PROJECTS_ACTIONS:
      return {
        removingCounters: {},
        currentProjectsActions: {},
        errors: {},
      };
    case projectsActions.COUNT_TIME_TO_REMOVE_PROJECT:
      removingCounters = Object.assign({}, state.removingCounters);
      action.ids.forEach((idProject) => {
        removingCounters[idProject] = state.removingCounters[idProject] - 1;
      });
      return Object.assign({}, state, { removingCounters });
    case projectsActions.REMOVE_PROJECT_ERROR:
      errors = Object.assign({}, state.errors);
      delete errors[action.idProject];
      return Object.assign({}, state, { errors });
    case projectsActions.SET_PROJECT_ERROR:
      errors = Object.assign({}, state.errors);
      errors[action.idProject] = {
        tryAgain: action.tryAgain,
        cancel: action.cancel,
      };
      return Object.assign({}, state, { errors });
    case projectsActions.REQUEST_ACTION_ON_PROJECT:
      projectActions = Object.assign({}, state.currentProjectsActions);
      projectActions[action.idProject] = action.actionOnProject;
      errors = Object.assign({}, state.errors);
      delete errors[action.idProject];
      return Object.assign({}, state, { currentProjectsActions: projectActions, errors });
    case projectsActions.STOP_ACTION_ON_PROJECT:
      projectActions = Object.assign({}, state.currentProjectsActions);
      delete projectActions[action.idProject];
      removingCounters = Object.assign({}, state.removingCounters);
      delete removingCounters[action.idProject];
      return Object.assign({}, state, {
        currentProjectsActions: projectActions,
        removingCounters,
      });
    default:
      return state;
  }
}
