/* eslint-disable react/require-default-props,react/no-unescaped-entities,max-len,jsx-a11y/alt-text */

import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import config from 'react-global-configuration';
import classNames from 'classnames';
import IntroScreenIcon from 'dashboard/images/design-systems/intro-screen-icon.svg';

const DesignSystemsIntroView = (props) => {
  const {
    wrapperClassNames,
    // eslint-disable-next-line react/prop-types
    children,
    isEarlyAccessInfoVisible,
    buttonText,
    buttonClassNames,
    onButtonClick,
    isLimitHit,
  } = props;
  const buttonsClassNames = classNames('btn-solid big', buttonClassNames);
  const handleClick = (e) => {
    e.preventDefault();
    onButtonClick();
  };

  const renderButton = () => {
    if (isLimitHit) {
      return null;
    }

    return (
      <a
        onClick={handleClick}
        href="#create-design-system"
        className={buttonsClassNames}>
        {buttonText}
      </a>
    );
  };

  const renderCopy = () => {
    if (isLimitHit) {
      return (
        <p>
          A team member on your account has already created a Design System.
          <br />
          Due to current permissions, you don't have access to view or use it. To get access, contact the owner of the Design System to change your permissions.
        </p>
      );
    }

    return (
      <p>
        Provide an unified set of design rules and patterns, share it with your team - colors, typography,
        <br />
        icons and UI patterns documented in one place and available for use in UXPin and Sketch.
      </p>
    );
  };

  return (
    <div id="design-system-screen" className={wrapperClassNames}>
      <section className="design-system-intro">
        <figure className="design-system-icon">
          <img src={IntroScreenIcon} width="142" />
        </figure>
        <h2>Design Systems</h2>
        {renderCopy()}
        {renderButton()}

        {isEarlyAccessInfoVisible && (
          <p className="sent-event-info">Thanks! We&#39;ll be in touch soon.</p>
        )}
      </section>
      {children}
    </div>
  );
};

DesignSystemsIntroView.propTypes = {
  wrapperClassNames: PropTypes.string,
  isEarlyAccessInfoVisible: PropTypes.bool,
  onButtonClick: PropTypes.func,
  buttonClassNames: PropTypes.string,
  buttonText: PropTypes.string,
  isLimitHit: PropTypes.bool,
};

DesignSystemsIntroView.defaultProps = {
  onButtonClick: noop,
  buttonClassNames: '',
  wrapperClassNames: '',
  isLimitHit: false,
};

export default DesignSystemsIntroView;
