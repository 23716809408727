import { connect } from 'react-redux';
import { ModalboxAddUser as ModalboxAddUserComponent } from '@uxpin/shared-components';
import { saveMembers } from '../../controllers/actions/insideProject.actions';
import { fetchProjectMembers } from '../../controllers/actions/members.actions';

export const mapStateToProps = (state) => {
  const { membersData, projectMembers, isProjectMembersLoading } = state.members;
  const { isProjectMembersSaving, isError } = state.insideProject;
  const { data } = state.currentUser;
  const { data: roles } = state.roles;

  return {
    membersData,
    projectMembers,
    currentUserData: data,
    isError,
    roles,
    isProjectMembersLoading,
    isProjectMembersSaving,
  };
};

export const mapDispatchToProps = (dispatch) => ({
  saveMembers: (idProject, userIds) => {
    dispatch(saveMembers(idProject, userIds));
  },

  fetchProjectMembers: (idProject) => {
    dispatch(fetchProjectMembers(idProject));
  },
});

export const ModalboxAddUser = connect(mapStateToProps, mapDispatchToProps)(ModalboxAddUserComponent);
