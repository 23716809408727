'use strict';

import React from 'react';
import autobind from 'autobind-decorator';

export default class ProjectsOverLimitBar extends React.Component {
  @autobind
  openUpgradeModal(e) {
    e.preventDefault();
    // eslint-disable-next-line react/destructuring-assignment,react/prop-types
    this.props.openUpgradeModal();
  }

  render() {
    return (
      <section id="top-info-bar" className="payment-failed-bar">
        <div>
          Ops! We had some troubles processing your payment. Please contact us at
          {' '}
          <a href="mailto:hello@uxpin.com">hello@uxpin.com</a>.
        </div>
      </section>
    );
  }
}
