import { connect } from 'react-redux';
import ModalboxFillNameComponent from '../components/Modalboxes/ModalboxFillName';
import { saveFirstLastName } from '../../controllers/actions/accountSettings.actions';
import { marketingActions } from '../../constants/marketing.constants';

export const mapStateToProps = (state) => {
  const { firstName, lastName } = state.currentUser.data;

  return {
    startFirstName: firstName,
    startLastName: lastName,
  }
};

export const mapDispatchToProps = (dispatch) => ({
  submit: (firstmame, lastname) => {
    saveFirstLastName(firstmame, lastname)
      .catch((e) => {
        console.error(e);
        if (window.Rollbar) {
          window.Rollbar.error(e);
        }
      })
      .finally(()=> {
        dispatch({
          type: marketingActions.CLOSE_NAMES_MODAL,
        });
      });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalboxFillNameComponent);
