/* eslint-disable react/destructuring-assignment,react/no-array-index-key */
/* eslint-disable react/prop-types */

'use strict';

import React from 'react';
import autobind from 'autobind-decorator';
import classnames from 'classnames';
import _ from 'lodash';
import { GLOBAL_ELEMENTS } from '@uxpin/shared-components';
import { SlackChannelList } from '../../../containers/SlackChannelList';
import ActivityEvent from './ActivityEvent';

export default class ActivityFeed extends React.Component {
  constructor(props) {
    super(props);

    this.classes = {
      ACTIVITY_FEED_VISIBLE: 'activity-feed-visible',
    };
  }

  componentWillMount() {
    const { refreshActivityFeed, idProject } = this.props;
    this.interval = setInterval(() => refreshActivityFeed(idProject), 30000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  componentDidMount() {
    const {
      isNextPage, getActivityFeed, isFeedLoading, idProject,
    } = this.props;
    this.toggleActivityFeedVisibility(this.props.feed);

    if (isNextPage && !isFeedLoading && this.refs.eventsList.scrollHeight === this.refs.eventsList.offsetHeight) {
      getActivityFeed(idProject);
    }
  }

  componentDidUpdate() {
    const {
      isNextPage, getActivityFeed, isFeedLoading, idProject,
    } = this.props;

    if (isNextPage && !isFeedLoading && this.refs.eventsList.scrollHeight === this.refs.eventsList.offsetHeight) {
      getActivityFeed(idProject);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.feed.length <= 1 && nextProps.feed.length > 1) {
      this.toggleActivityFeedVisibility(nextProps.feed);
    }

    if (this.props.isActivityFeedRefreshing && !nextProps.isActivityFeedRefreshing) {
      clearInterval(this.interval);
    }
  }

  shouldComponentUpdate(nextProps) {
    return Object.keys(this.props).some((propName) => !_.isEqual(this.props[propName], nextProps[propName]));
  }

  toggleActivityFeedVisibility(feed) {
    // First log is created automatically when adding a new project
    // We want to show feed if user has taken other actions but there are no prototypes left
    // Example : User created prototype and deleted it

    if (feed.length > 1) {
      document.body.classList.add(this.classes.ACTIVITY_FEED_VISIBLE);
    } else {
      document.body.classList.remove(this.classes.ACTIVITY_FEED_VISIBLE);
    }
  }

  @autobind
  onScroll() {
    const {
      getActivityFeed, isNextPage, idProject, isFeedLoading,
    } = this.props;

    if (!isNextPage || isFeedLoading) {
      return;
    }

    const calibrationFactor = 0.5;
    const nodeOffsetTop = this.refs.eventsList.scrollTop;
    const maxScrollPosition = this.refs.eventsList.scrollHeight - this.refs.eventsList.offsetHeight;

    if (maxScrollPosition > 0 && maxScrollPosition * calibrationFactor <= nodeOffsetTop) {
      getActivityFeed(idProject);
    }
  }

  getLoadingClass() {
    const { isFeedLoading } = this.props;
    return classnames({ loading: isFeedLoading });
  }

  render() {
    const { idProject, feed } = this.props;
    const sectionTitleClasses = classnames('section-title', GLOBAL_ELEMENTS.HEADING_5);

    return (
      <section className={this.getLoadingClass()} id="activity-feed">
        <h5 className={sectionTitleClasses}>Project Activity</h5>
        <ul onScroll={this.onScroll} ref="eventsList" className="events-list">
          {feed.map((activityEvent, id) => <ActivityEvent key={id} data={activityEvent} />)}
        </ul>
        <SlackChannelList idProject={idProject} />
      </section>
    );
  }
}
