/* eslint-disable react/prop-types */

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import autobind from 'autobind-decorator';
import {
  Cloud, cloudPositions, isChrome, isSafari,
} from '@uxpin/shared-components';
import { InlineEdit } from '@uxpin/design-system';
import { canEditSystem, getMergeViewUri } from '../../../../controllers/helpers/designSystems.helper';
import { STATUS } from '../../../../constants/designSystems.constants';
import { LIBRARY_TYPES } from '../../../../../enums/designLibraries';

const shouldWrap = isChrome || isSafari;
const DOUBLE_CLICK_DELAY = 250;
let timer;

export class MergeItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      canEdit: false,
      isEditing: false,
      isWorking: false,
    };
  }

  componentWillMount() {
    const { currentUserData: { id }, item } = this.props;
    const canEdit = canEditSystem(item, id);

    this.handleWorkingState(this.props);
    this.setState({ canEdit });
  }

  componentWillReceiveProps(nextProps) {
    this.handleWorkingState(nextProps);
  }

  getHref() {
    const { item: { id } } = this.props;

    if (this.isGitEnabled() || this.isNpmEnabled()) {
      return getMergeViewUri(id);
    }

    return '#id';
  }

  handleWorkingState(props) {
    const { status } = props.item;
    const isWorking = status === STATUS.DUPLICATING;

    // eslint-disable-next-line react/destructuring-assignment
    if (isWorking !== this.state.isWorking) {
      this.setState({
        isWorking,
      });
    }
  }

  @autobind
  handleMergeLibraryDelete(e) {
    const { item, openModalboxMergeLibraryDelete } = this.props;
    e.preventDefault();
    openModalboxMergeLibraryDelete(item);
  }

  @autobind
  handleMergeLibrarySetting(e) {
    const { item, openModalboxMergeSetting } = this.props;
    e.preventDefault();
    openModalboxMergeSetting(item.id);
  }

  isGitEnabled() {
    const { item: { type, isPackageIntegration }, isGitOnlyEnabled } = this.props;
    return (type === LIBRARY_TYPES.CODE_SYNC && !isPackageIntegration && isGitOnlyEnabled);
  }

  isNpmEnabled() {
    const { item: { type, isPackageIntegration }, isPackageIntegrationOnlyEnabled } = this.props;
    return (type === LIBRARY_TYPES.CODE_SYNC && isPackageIntegration && isPackageIntegrationOnlyEnabled);
  }

  @autobind
  handleClick(event) {
    event.preventDefault();

    if (timer) {
      clearTimeout(timer);
    }

    timer = setTimeout(() => {
      if (this.isNpmEnabled() || this.isGitEnabled()) {
        window.location.href = this.getHref();
      }
    }, DOUBLE_CLICK_DELAY);
  }

  @autobind
  handleDoubleClick() {
    if (timer) {
      clearTimeout(timer);
    }
  }

  @autobind
  handleEditStart() {
    this.setState({
      isEditing: true,
    });
  }

  @autobind
  handleEditFinish() {
    this.setState({
      isEditing: false,
    });
  }

  @autobind
  saveName(name) {
    const { item, updateLibraryName } = this.props;

    updateLibraryName(item.id, { name });
  }

  @autobind
  handleLibraryVersion() {
    const { item: { type, isPackageIntegration, packageVersion, pointer } } = this.props;

    if (type === LIBRARY_TYPES.CODE_SYNC && isPackageIntegration) {
      return packageVersion;
    }
    if (type === LIBRARY_TYPES.CODE_SYNC && !isPackageIntegration) {
      if (!pointer) {
        return null;
      }
      const { item: { pointer: { default: { repositoryPointerName } } } } = this.props;

      return repositoryPointerName;
    }
  }

  @autobind
  handleDotBetweenIconAndVersion() {
    const { item: { type, pointer, isPackageIntegration } } = this.props;
    if (type === LIBRARY_TYPES.CODE_SYNC) {
      if (!pointer && !isPackageIntegration) {
        return null;
      }
      return <span className="dot" />;
    }
  }

  renderEditLink = () => {
    const { item: { type } } = this.props;

    if (type === LIBRARY_TYPES.STORYBOOK) {
      return (<Fragment>
        <li className="merge-library-disabled-editing-info">
          <span>This type of library can't be edit <br /> in Merge Component Manager</span>
        </li>
        <li className="separator" />
      </Fragment>);
    }

    if (this.isGitEnabled() || this.isNpmEnabled()) {
      return (
        <li>
          <a href="#edit" className="icon-general-pencil" onClick={this.handleClick}>Edit</a>
        </li>
      );
    }

    return (<Fragment>
      <li className="merge-library-disabled-editing-info">
        <span>You don't have access <br />to edit this library</span>
      </li>
      <li className="separator" />
    </Fragment>);
  }

  render() {
    const {
      cloud,
      isItemHovered,
      item,
      trigger,
    } = this.props;
    const { name } = item;
    const { canEdit, isWorking, isEditing } = this.state;
    const { item: { type, isPackageIntegration } } = this.props;
    const itemClassNames = classNames('list-item', {
      selected: isItemHovered,
      'is-working': isWorking,
    });
    const linkClassName = classNames('gray-link', {
      inactive: isEditing,
    });
    const iconClassNames = classNames(
      {
        'brand-npm': type === LIBRARY_TYPES.CODE_SYNC && isPackageIntegration,
        git: type === LIBRARY_TYPES.CODE_SYNC && !isPackageIntegration,
        'brand-storybook': type === LIBRARY_TYPES.STORYBOOK,
      }
    );

    return (
      <li className={itemClassNames}>
        {!isWorking && (
          <a
            className={linkClassName}
            href={this.getHref()}
            onClick={this.handleClick}
            onDoubleClick={this.handleDoubleClick}>
            <h3 className="design-system-name">
              <InlineEdit
                title={name}
                isDisabled={!canEdit}
                modifierName="with-clamp"
                onChange={this.saveName}
                onEditStart={this.handleEditStart}
                onEditFinish={this.handleEditFinish}
                contentWrapped={shouldWrap}>
                {name}
              </InlineEdit>
            </h3>
            <div className="merge-library-icon-and-version">
              <span className={`ds-icon--font__${iconClassNames} merge-library-type-icon`} />
              {this.handleDotBetweenIconAndVersion()}
              <div className="merge-library-version">{this.handleLibraryVersion()}</div>
            </div>
          </a>
        )}

        {canEdit && !isWorking && (
          <div>
            <span className="more-options icon-general-dots only-icon-font" {...trigger}>More options</span>
            <ul {...cloud} className="cloud options-cloud auto-width  with-icons merge-library-cloud">
              {this.renderEditLink()}
              {(this.isGitEnabled() || this.isNpmEnabled()) && (<Fragment><li>
                <a href="#design-system-permissions"
                   className="icon-general-gear"
                   onClick={this.handleMergeLibrarySetting}>
                  Settings
                </a>
              </li>
              <li className="separator" />
              </Fragment>)}
              <li>
                <a href="#delete"
                   className="icon-general-trash danger custom-color"
                   onClick={this.handleMergeLibraryDelete}>
                  Delete
                </a>
              </li>
            </ul>
          </div>
        )}

        { isWorking && (
          <span className="pending-info">Creating...</span>
        )}
      </li>
    );
  }
}

MergeItem.propTypes = {
  currentUserData: PropTypes.object.isRequired,
  isItemHovered: PropTypes.bool.isRequired,
  item: PropTypes.shape({
    editScope: PropTypes.shape({
      scopeGroup: PropTypes.string.isRequired,
      selectedUsers: PropTypes.array.isRequired,
    }).isRequired,
    pointer: PropTypes.shape({
      default: PropTypes.shape({
        repositoryPointerName: PropTypes.string.isRequired,
      }).isRequired,
    }),
    id: PropTypes.number.isRequired,
    idUser: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    isPackageIntegration: PropTypes.bool,
    packageVersion: PropTypes.string,
  }).isRequired,
  openModalboxMergeLibraryDelete: PropTypes.func.isRequired,
  openModalboxMergeSetting: PropTypes.func.isRequired,
  updateLibraryName: PropTypes.func.isRequired,
};

export default Cloud({
  availablePositions: [cloudPositions.TOP, cloudPositions.BOTTOM],
  offset: {
    bottom: { x: 0, y: 10 },
    top: { x: 0, y: -10 },
  },
})(MergeItem);
