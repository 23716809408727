'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

// eslint-disable-next-line react/prefer-stateless-function
export default class ProjectBoxActionsTop extends React.Component {
  render() {
    return (
      <header className="actions-top">
        <a
          href="#share"
          title="Share"
          className="share icon-general-share only-icon-font blue-hover"
          // eslint-disable-next-line react/destructuring-assignment
          onClick={this.props.openModalboxShare}>
          Share Project
        </a>
      </header>
    );
  }
}

ProjectBoxActionsTop.propTypes = {
  openModalboxShare: PropTypes.func,
};

ProjectBoxActionsTop.defaultProps = {
  openModalboxShare: _.noop,
};
