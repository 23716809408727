/* eslint-disable react/destructuring-assignment */

'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import autobind from 'autobind-decorator';
import moment from 'moment';
import { ModalboxExtendTrialForm } from '../../containers/ModalboxExtendTrialForm';
import { accountStatuses } from '../../../constants/accountSettings.constants';
import { preventDefaultEvent } from '../../../utils/common';
import { getMixpanel } from '../../../../../shared/mixpanel';

export default class TrialInfo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isExtendTrialFormModalOpen: false,
      showExtendTrialOption: false,
    };
  }

  @autobind
  openExtendModalbox(e) {
    preventDefaultEvent(e);
    this.setState({
      isExtendTrialFormModalOpen: true,
    });
  }

  @autobind
  closeExtendModal(e) {
    preventDefaultEvent(e);
    this.setState({
      isExtendTrialFormModalOpen: false,
    });
  }

  @autobind
  openUpgradeModal(e) {
    const { openUpgradeModal } = this.props;

    preventDefaultEvent(e);
    getMixpanel((mixpanel) => {
      mixpanel.track('upgrade_button_clicked', {
        app_part: 'uxpin-app-dashboard',
      });
    });
    openUpgradeModal();
  }

  renderExtendLink() {
    if (!this.state.showExtendTrialOption) {
      return null;
    }

    return (
      <a href="#extend-trial" className="underline-link gray-link" onClick={this.openExtendModalbox}>Extend trial</a>
    );
  }

  renderExtendTrialFormModal() {
    return (
      <ModalboxExtendTrialForm
        isOpen={this.state.isExtendTrialFormModalOpen}
        onClose={this.closeExtendModal}
      />
    );
  }

  renderUpgradeInfo() {
    return (
      <span>
        <a
          href="#upgrade-now"
          className="blue-link underline-link gray-link"
          onClick={this.openUpgradeModal}>
          Upgrade now
        </a>
      </span>
    );
  }

  renderExpirationInfo() {
    const { accountPlan } = this.props;
    if (!accountPlan) return null;

    const expirationDate = moment.unix(accountPlan.expiration_date);
    const startOfToday = moment().startOf('day');
    const trialDays = expirationDate.diff(startOfToday, 'days');

    if (trialDays <= 0) {
      return (
        <span className="days-left">
          Your trial ends <strong>today</strong>.
        </span>
      );
    }

    const trialLabel = trialDays === 1 ? 'day' : 'days';

    return (
      <span className="days-left">
        Your trial ends in <strong>{trialDays} {trialLabel}</strong>.
      </span>
    );
  }

  render() {
    const { accountPlan, accountStatus, isTrialPlan } = this.props;
    const isActiveTrial = accountPlan && isTrialPlan && accountStatus === accountStatuses.ACTIVE;

    if (isActiveTrial) {
      return (
        <div className="trial-info">
          {this.renderExpirationInfo()}
          {this.renderUpgradeInfo()}
        </div>
      );
    }

    return null;
  }
}

TrialInfo.propTypes = {
  accountPlan: PropTypes.object,
  accountStatus: PropTypes.string,
  isTrialPlan: PropTypes.bool.isRequired,
  openUpgradeModal: PropTypes.func.isRequired,
};

TrialInfo.defaultProps = {
  accountPlan: {},
  accountStatus: '',
  // eslint-disable-next-line react/default-props-match-prop-types
  isNewOnboarding: false,
};
