import config from 'react-global-configuration';

const protocol = window.location.protocol;
let baseDomainParts = window.location.host.split(".").slice(1);

const apiUrl = protocol + "//" + ["api"].concat(baseDomainParts).join(".");
const appUrl = protocol + "//" + ["app"].concat(baseDomainParts).join(".");
const previewUrl = protocol + "//" + ["preview"].concat(baseDomainParts).join(".");
const approvalUrl = previewUrl + "/a";

config.set(Object.assign({}, UXPinConfig, {
  API_URL: apiUrl,
  APP_URL: appUrl,
  APPROVAL_URL: approvalUrl,
  BASE_URL: '/dashboard/',
  EDITOR_NEW_ENGINE_URL: appUrl,
  PREVIEW_URL: previewUrl,
}));
