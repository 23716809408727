'use strict';

import { featuresActions } from '../../constants/features.constants';

export function featuresReducer(state = {
  features: {},
}, action) {
  switch (action.type) {
    case featuresActions.FETCH_FEATURES:
      return Object.assign({}, state, {
        features: action.features,
      });
    default:
      return state;
  }
}
