/* eslint-disable max-len,react/button-has-type */
/* eslint-disable no-param-reassign */
/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
/* eslint-disable react/require-default-props */

'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { DropTarget } from 'react-dnd';
import autobind from 'autobind-decorator';
import classnames from 'classnames';
import { Modal, triggerTypes } from '@uxpin/shared-components';
import { APPEARANCES, Button, Link } from '@uxpin/design-system';
import Step from './components/Step';
import StepNew from './components/StepNew';
import Notify from './components/Notify';
import { PlanInfo } from '../../containers/PlanInfo';
import { sortableItemsTypes } from '../../../constants/sortableItems.constants';
import { getBoxTarget } from '../../../utils/dndScrollFix';
import { preventDefaultEvent } from '../../../utils/common';
import { getUserNameFromMemberData } from '../../../utils/account';

export class ProjectsSettings extends React.Component {
  constructor(props) {
    super(props);
    this.MODAL = {
      WRAPPER_ID: 'projects-settings-modal-box',
      TITLE: 'Project status',
    };

    this.state = {
      projectStages: [],
      projectStatuses: [],
      currentStage: {},
      currentStatus: {},
      selectedUsers: [],
      idStatusOld: 0,
      idStageOld: 0,
      isInAddingMode: false,
    };

    this.classes = {
      LOADING: 'loading',
      WORKING: 'working',
      NEW_ITEM_INPUT: 'new-item-input',
    };
  }

  @autobind
  onOpen() {
    const { fetchProjectSettings, idProject } = this.props;
    this.setState({
      projectStages: [],
      projectStatuses: [],
      currentStage: {},
      currentStatus: {},
    });
    fetchProjectSettings(idProject);
  }

  @autobind
  onClose(e) {
    const { onClose } = this.props;
    e.preventDefault();
    this.setState({
      isInAddingMode: false,
    });
    onClose();
  }

  renderDescription() {
    return (<p>Keep your team informed about the stage of the design process.<br /> Choose current stage and status below.</p>);
  }

  // eslint-disable-next-line complexity
  componentWillReceiveProps(nextProps) {
    if (!nextProps.isOpen) {
      return;
    }

    if (this.props.isLoading && !nextProps.isLoading && !this.props.isSaving && !nextProps.isSaving && !nextProps.isError) {
      const projectStages = _.cloneDeep(nextProps.projectStages);
      this.setState({
        projectStages: projectStages.stages,
        projectStatuses: projectStages.statuses,
        currentStage: _.clone(nextProps.currentStage),
        currentStatus: _.clone(nextProps.currentStatus),
        idStageOld: nextProps.currentStage.idStage,
        idStatusOld: nextProps.currentStatus.idStatus,
        selectedUsers: !nextProps.selectedUsers.length ? [nextProps.currentUserId] : nextProps.selectedUsers,
      });
    }

    if (!nextProps.isError && this.props.isSaving && !nextProps.isSaving) {
      this.props.onClose();
    }
  }

  @autobind
  startAddingNewStage(e) {
    e.preventDefault();

    if (this.state.isInAddingMode) {
      this.focusStageInput();
    } else {
      this.currentAddedStageData = {
        isBeingAdded: true,
        idStage: _.uniqueId('temporary_'),
        hasTemporaryId: true,
      };
      this.setState({
        isInAddingMode: true,
        projectStages: [...this.state.projectStages, this.currentAddedStageData],
      }, this.focusStageInput);
    }
  }

  @autobind
  saveStage(name, index = null) {
    let projectStages = _.cloneDeep(this.state.projectStages);

    if (index !== null) {
      const projectStage = projectStages[index];
      projectStage.name = name;
      projectStage.isUpdated = true;
      delete projectStage.isBeingUpdated;
      this.setState({
        projectStages,
      });
    } else {
      projectStages = projectStages.map((projectStage) => {
        if (projectStage.isBeingAdded) {
          projectStage.name = name;
          delete projectStage.isBeingAdded;
        }

        return projectStage;
      });

      this.setState({
        isInAddingMode: false,
        projectStages,
      });
    }
  }

  focusStageInput() {
    const input = this.projectsSettings.querySelector(`input.${this.classes.NEW_ITEM_INPUT}`);
    input.focus();
    input.select();
  }

  @autobind
  moveStage(dragIndex, hoverIndex) {
    const { projectStages } = this.state;
    const newProjectStages = _.cloneDeep(projectStages);
    const projectStage = newProjectStages[dragIndex];
    newProjectStages.splice(dragIndex, 1);
    newProjectStages.splice(hoverIndex, 0, projectStage);

    this.setState({
      projectStages: newProjectStages,
    });
  }

  @autobind
  deleteStage(currentStageIndex) {
    const { projectStages } = this.state;
    const newProjectStages = _.cloneDeep(projectStages);
    newProjectStages[currentStageIndex].isDeleted = true;
    delete newProjectStages[currentStageIndex].isBeingDeleted;
    delete newProjectStages[currentStageIndex].time;

    this.setState({
      projectStages: newProjectStages,
    });
  }

  @autobind
  startDeleteStage(currentStageIndex) {
    const projectStages = _.cloneDeep(this.state.projectStages);
    projectStages[currentStageIndex].isBeingDeleted = true;
    projectStages[currentStageIndex].time = 20;

    if (this.isActive(projectStages[currentStageIndex].idStage)) {
      const index = _.findIndex(projectStages, (stage) => !stage.isBeingDeleted && !stage.isDeleted);
      this.setCurrentStageAndStatus(projectStages[index], index, this.state.projectStatuses[0]);
    }

    this.setState({
      projectStages,
    });
  }

  @autobind
  startEditingStage(index) {
    const projectStages = _.cloneDeep(this.state.projectStages);
    projectStages[index].isBeingUpdated = true;

    this.setState({
      projectStages,
    }, this.focusStageInput);
  }

  @autobind
  undoStage(currentStageIndex) {
    const projectStages = _.cloneDeep(this.state.projectStages);
    delete projectStages[currentStageIndex].isBeingDeleted;
    delete projectStages[currentStageIndex].time;

    this.setState({
      projectStages,
    });
  }

  @autobind
  countTime(currentStageIndex) {
    const projectStages = _.cloneDeep(this.state.projectStages);
    if (projectStages[currentStageIndex]) {
      projectStages[currentStageIndex].time -= 1;

      this.setState({
        projectStages,
      });
    }
  }

  @autobind
  setCurrentStageAndStatus(data, index, status) {
    const stage = _.cloneDeep(data);
    stage.position = index;
    this.setState({
      currentStage: stage,
      currentStatus: status,
    });
  }

  @autobind
  setCurrentStatus(data) {
    this.setState({
      currentStatus: data,
    });
  }

  isActive(id) {
    return (id === this.state.currentStage.idStage);
  }

  resetTimers() {
    const projectStages = _.cloneDeep(this.state.projectStages);

    this.setState({
      projectStages: projectStages.map((projectStage) => {
        if (projectStage.isBeingDeleted) {
          projectStage.time = 0;
        }

        return projectStage;
      }),
    });
  }

  @autobind
  save() {
    const { saveProjectSettings, idProject } = this.props;
    this.resetTimers();
    const notificationData = {
      idsUser: this.state.selectedUsers,
      idStatusOld: this.state.idStatusOld,
      idStageOld: this.state.idStageOld,
    };

    if (this.state.isInAddingMode) {
      this.setState({
        isInAddingMode: false,
      }, () => saveProjectSettings(idProject, this.state.projectStages, this.state.currentStage, this.state.currentStatus, notificationData));
    } else {
      saveProjectSettings(idProject, this.state.projectStages, this.state.currentStage, this.state.currentStatus, notificationData);
    }
  }

  @autobind
  openUpgradeModal(e) {
    const { openUpgradeModal } = this.props;
    preventDefaultEvent(e);
    this.onClose(e);
    openUpgradeModal();
  }

  getModalBoxContentClasses() {
    const { isLoading } = this.props;
    return classnames('modal-box-content', { loading: isLoading });
  }

  getWorkingClass() {
    const { isSaving } = this.props;
    return isSaving;
  }

  isDeletingActionHidden() {
    return this.state.projectStages.filter((stage) => !stage.isDeleted && !stage.isBeingDeleted).length === 1;
  }

  @autobind
  updateSelectedUser(selectedUsers) {
    this.setState({
      selectedUsers,
    });
  }

  getTeamMembers() {
    const { membersData, projectMembers } = this.props;

    return membersData
      .filter((member) => projectMembers.indexOf(member.id_user) !== -1)
      .map((member) => {
        const name = getUserNameFromMemberData(member);

        return {
          id: member.id_user,
          name: name.trim(),
          avatar: member.avatar,
          firstname: member.firstname,
          lastname: member.lastname,
          email: member.email,
        };
      });
  }

  renderNotify() {
    const { projectMembers, isLoading, currentUserId } = this.props;
    if (!isLoading && projectMembers.length > 1) {
      return (
        <Notify
          selectedUsers={this.state.selectedUsers}
          currentUserId={currentUserId}
          focusFirstInput
          itemsList={this.getTeamMembers()}
          updateSelectedUser={this.updateSelectedUser}
        />
      );
    }
  }

  @autobind
  renderAddStepBtn() {
    if (this.props.isCustomizationEnabled) {
      return (
        <nav>
          <Link
            href="#add-new-step"
            iconName="plus-outline"
            iconSize="m"
            onClick={this.startAddingNewStage}>
            Add a step
          </Link>
        </nav>
      );
    }

    return (
      <PlanInfo label="Upgrade" hideLink>
        Higher plans allow you to add and modify particular stages of your project.&nbsp;
        <a href="#upgrade" onClick={this.openUpgradeModal} className="underline-link blue-link">Contact our Sales team and upgrade now!</a>
      </PlanInfo>
    );
  }

  renderError() {
    const { isError } = this.props;

    if (isError) {
      return (
        <li>
          <p className="error">Something went wrong!</p>
        </li>
      );
    }
  }

  render() {
    const { isCustomizationEnabled } = this.props;

    return (
      <Modal
        wrapperId={this.MODAL.WRAPPER_ID}
        title={this.MODAL.TITLE}
        renderDescription={this.renderDescription}
        isOpen={this.props.isOpen}
        onOpen={this.onOpen}
        onClose={this.onClose}>
        {this.props.connectDropTarget(
          <section className={this.getModalBoxContentClasses()}>
            <section ref={(node) => { this.projectsSettings = node; }}>
              {this.state.projectStages.map((projectStage, id) => {
                if (projectStage.isBeingAdded || projectStage.isBeingUpdated) {
                  return (
                    <StepNew
                      key={id}
                      index={id}
                      data={projectStage}
                      saveStage={this.saveStage}
                    />
                  );
                }

                return (
                  <Step
                    key={id}
                    index={id}
                    triggerType={triggerTypes.HOVER}
                    autoClose
                    isActive={this.isActive(projectStage.idStage)}
                    undoStage={this.undoStage}
                    isDeletingActionHidden={this.isDeletingActionHidden()}
                    setCurrentStageAndStatus={this.setCurrentStageAndStatus}
                    deleteStage={this.deleteStage}
                    countTime={this.countTime}
                    isCustomizationEnabled={isCustomizationEnabled}
                    projectStatuses={this.state.projectStatuses}
                    currentStatus={this.state.currentStatus}
                    setCurrentStatus={this.setCurrentStatus}
                    moveItem={this.moveStage}
                    startDeleteStage={this.startDeleteStage}
                    onEdit={this.startEditingStage}
                    data={projectStage}
                    saveStage={this.saveStage}
                  />
                );
              })}

            </section>
          </section>
        )}
        {this.renderAddStepBtn()}

        <footer>
          {this.renderNotify()}
          <ul className="btns-group">
            {this.renderError()}
            <li>
              <Link appearance={APPEARANCES.NEUTRAL} href="#cancel" onClick={this.onClose}>Cancel</Link>
            </li>
            <li>
              <Button onClick={this.save} isWorking={this.getWorkingClass()}>Save</Button>
            </li>
          </ul>
        </footer>

      </Modal>
    );
  }
}

export default DropTarget(sortableItemsTypes.STEP, getBoxTarget('#projects-settings-modal-box .modal-box-content', 30), (connect) => ({
  connectDropTarget: connect.dropTarget(),
}))(ProjectsSettings);

ProjectsSettings.defaultProps = {
  projectMembers: [],
  selectedUsers: [],
  isOpen: false,
  isLoading: false,
  onClose: _.noop,
  openUpgradeModal: _.noop,
};

ProjectsSettings.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  openUpgradeModal: PropTypes.func,
};
