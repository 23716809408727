/* eslint-disable react/destructuring-assignment,max-len */
/* eslint-disable react/jsx-indent */

'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import autobind from 'autobind-decorator';
import { noop } from 'lodash';
import { Modal } from '@uxpin/shared-components';
import { modalsIds } from '../../../constants/modal.constants';
import { COPY_TYPE } from '../../../constants/designSystems.constants';
import routes from '../../../constants/routes.constants';
import { goTo } from '../../../utils/router';
import { preventDefaultEvent } from '../../../utils/common';

export default class ModalboxDesignLimitReachedComponent extends React.Component {
  constructor(props) {
    super(props);

    this.closeHelper = noop;

    this.MODAL = {
      WRAPPER_ID: modalsIds.MODALBOX_DESIGN_LIMIT_REACHED,
      CLASS_NAMES: 'ds-limits-modal-box',
    };

    this.COPY = {
      [COPY_TYPE.DESIGN_SYSTEM]: {
        HEADER: <h2>
          Your account has reached
          <br />
          its design system limit
                </h2>,
        TEXT: <p>
          You can still work on your existing design systems. If you upgrade to Enterprise, you&#39;ll have no user, prototype
          <br />
          {' '}
          or design system limits.
              </p>,
      },
      [COPY_TYPE.PROTOTYPE]: {
        HEADER: <h2>
          Your account has reached
          <br />
          its prototype limit
                </h2>,
        TEXT: <p>
          Don&#39;t worry – you can still work on your existing prototypes.
          <br />
          For more space, you can archive or delete some older prototypes or upgrade to higher plan. If you upgrade to Enterprise, you&#39;ll have no user or prototype limits.
              </p>,
      },
    };
  }

  @autobind
  primaryAction(e) {
    preventDefaultEvent(e);
    this.props.openUpgradeModal();
    this.onClose();
  }

  @autobind
  onClose(e) {
    const { onClose, showSystemsListOnClose } = this.props;

    preventDefaultEvent(e);
    onClose(e);

    if (showSystemsListOnClose) {
      goTo(routes.DESIGN_SYSTEMS);
    }
    this.closeHelper.hideModal();
  }

  @autobind
  renderHeader() {
    const { type } = this.props;

    return this.COPY[type].HEADER;
  }

  renderButtons() {
    return (
      <ul className="btns-group">
        <li>
          <a href="#close" onClick={this.onClose} className="gray-style">Stay on current plan</a>
        </li>
        <li>
          <button
            type="button"
            className="btn-solid btn-wide blue"
            onClick={this.primaryAction}>
Upgrade your account
          </button>
        </li>
      </ul>
    );
  }

  render() {
    const { type } = this.props;

    return (
      <Modal
        ref={(c) => { this.closeHelper = c; }}
        wrapperId={this.MODAL.WRAPPER_ID}
        classNames={this.MODAL.CLASS_NAMES}
        isOpen={this.props.isOpen}
        renderTitle={this.renderHeader}
        onClose={this.onClose}>
        <div className="limits-content">
          {this.COPY[type].TEXT}
          {this.renderButtons()}
        </div>
      </Modal>
    );
  }
}

ModalboxDesignLimitReachedComponent.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  openUpgradeModal: PropTypes.func,
  type: PropTypes.string,
  showSystemsListOnClose: PropTypes.bool,
};

ModalboxDesignLimitReachedComponent.defaultProps = {
  onClose: noop,
  openUpgradeModal: noop,
  type: COPY_TYPE.PROTOTYPE,
  showSystemsListOnClose: false,
};
