/* eslint-disable max-len */

'use strict';

import 'core-js/stable';
import 'regenerator-runtime/runtime';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router, Redirect, browserHistory } from 'react-router';
import config from 'react-global-configuration';
import './initConfig';
import { forceLoadProximaNova } from './utils/request';
import { dashboard } from './controllers/dashboardStore';
import appRoutes from './Routes';
import { googleAnalytics, adwords, microsoftClarity } from './utils/analyticsManager';
import rollbarConfig from './utils/rollbar';
import routes from './constants/routes.constants';
import { callDashFunction } from './controllers/actions/externalCall.actions';
import { marketingTrackPageViewed } from './utils/marketingTrackPageViewed';

if (process.env.NODE_ENV === 'production') {
  const Rollbar = require('rollbar'); // eslint-disable-line global-require
  window.Rollbar = new Rollbar(rollbarConfig);
}

forceLoadProximaNova();
googleAnalytics.initialize();
adwords.initialize();
microsoftClarity.initialize();

if (window.location.host === 'dashboard.uxpin.com') {
  if (window.location.pathname.indexOf('/dashboard') === 0) {
    window.location.href = `https://app.uxpin.com${window.location.pathname}`;
  } else {
    window.location.href = `https://app.uxpin.com/dashboard${window.location.pathname}`;
  }
}

function renderRedirect() {
  const { BASE_URL } = config.get();

  if (BASE_URL !== '/') {
    return (
      <div>
        <Redirect from={`/${routes.PROJECTS}`} to={`${BASE_URL}${routes.PROJECTS}`} />
        <Redirect from={`/${routes.PROJECT}/:projectId`} to={`${BASE_URL}${routes.PROJECT}/:projectId`} />
        <Redirect from={`/${routes.MODAL}/:modalId`} to={`${BASE_URL}${routes.MODAL}/:modalId`} />
        <Redirect from={`/${routes.LIMITS_EXCEEDED}/:planType/:limit`} to={`${BASE_URL}${routes.LIMITS_EXCEEDED}/:planType/:limit`} />
        <Redirect from={`/${routes.INACTIVE_ACCOUNT}`} to={`${BASE_URL}${routes.INACTIVE_ACCOUNT}`} />
      </div>
    );
  }

  return null;
}

const userId = dashboard.getState().currentUser.userId;

ReactDOM.render(
  <Provider store={dashboard}>
    <Router history={browserHistory}>
      {renderRedirect()}
      {appRoutes}
      {marketingTrackPageViewed({ browserHistory, userId })}
    </Router>
  </Provider>,
  document.getElementById('react-container')
);

window.callDashFunction = callDashFunction;
