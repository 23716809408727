/* eslint-disable react/destructuring-assignment */

'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import config from 'react-global-configuration';
import autobind from 'autobind-decorator';
import { noop } from 'lodash';
import { Modal } from '@uxpin/shared-components';
import { modalsIds } from '../../../constants/modal.constants';
import { AccountSetupForm } from '../../containers/AccountSetupForm';
import { goToExternal } from '../../../utils/router';
import routes from '../../../constants/routes.constants';
import { FEATURE_STATUS } from '../../../constants/features.constants';
import { preventDefaultEvent } from '../../../utils/common';

export default class ModalboxOnboardingComponent extends React.Component {
  constructor(props) {
    super(props);

    this.closeHelper = noop;

    this.state = {
      isCloseBlocked: true,
    };

    this.MODAL = {
      WRAPPER_ID: modalsIds.ONBOARDING_MODAL,
      CLASS_NAMES: 'onboarding-modal',
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.isOpen === false && nextProps.isOpen) {
      this.handleKissmetricsOpen(nextProps);
    }
  }

  @autobind
  onAccountSetupSubmit() {
    const { setAccountSetupFormAsFilled, features } = this.props;

    setAccountSetupFormAsFilled()
      .then(() => {
        if (features && features.onboarding_ds_scraper === FEATURE_STATUS.ENABLED) {
        // Temp. solution, solves the "user clicking the back button" issue and relies
        // on proper redirects in the Layout component
          goToExternal(`${config.get('APP_URL')}/dashboard/${routes.ONBOARDING_START}`);
        } else {
          this.onClose();
        }
      });
  }

  @autobind
  onClose(e) {
    preventDefaultEvent(e);
    this.props.onClose();
    this.closeHelper.hideModal();
  }

  renderAccountSetupForm() {
    return (
      <AccountSetupForm
        onSubmit={this.onAccountSetupSubmit}
      />
    );
  }

  @autobind
  renderOnboarding() {
    return (
      <div className={this.MODAL.CLASS_NAMES}>
        <h2 className="header">Finish setting up your account</h2>
        {this.renderAccountSetupForm()}
      </div>
    );
  }

  @autobind
  render() {
    return (
      <Modal
        ref={(c) => { this.closeHelper = c; }}
        wrapperId={this.MODAL.WRAPPER_ID}
        isOpen={this.props.isOpen}
        onClose={this.onClose}
        isCloseBlocked={this.state.isCloseBlocked}
        classNames="full-screen onboarding-team-size">
        <a href="#uxpin" className="icon-general-uxpin-logo only-icon-font extra-icon">UXPin</a>
        {this.renderOnboarding()}
      </Modal>
    );
  }
}

ModalboxOnboardingComponent.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  setAccountSetupFormAsFilled: PropTypes.func,
  features: PropTypes.object,
};

ModalboxOnboardingComponent.defaultProps = {
  onClose: noop,
  setAccountSetupFormAsFilled: noop,
  features: {},
};
