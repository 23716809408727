/* eslint-disable max-len */
/* eslint-disable radix */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */

'use strict';

import React from 'react';
import autobind from 'autobind-decorator';
import _ from 'lodash';
import { ModalboxGeneralMessage } from '@uxpin/shared-components';
import InsideProject from '../../InsideProject/InsideProject';
import {
  uploadStaticFiles,
  acceptedFileTypes,
  acceptedFileSize,
  allowedOctetFileExtensions,
  isPsd,
  isUXPinFile,
  isUXPinPluginFile,
  isSketchFile,
  getStandarizedExtension,
  OCTET_STREAM,
  BASIC_FILE_TYPES,
} from '../../../../utils/uploadFiles';
import { redirectToNotFoundView } from '../../../../utils/router';
import ModalboxDownloadPlugin from '../../Modalboxes/ModalboxDownloadPlugin';
import { isUserLogged, logOut } from '../../../../utils/account';

export default class ProjectsRoute extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalboxGeneralMessageOpen: false,
      message: '',
      modalboxGeneralBtnLabel: '',
      modalboxGeneralBtnAction: null,
      isModalboxDownloadPluginOpen: false,
    };
  }

  @autobind
  openModalboxGeneralMessage(message, btnLabel, btnAction) {
    this.setState({
      isModalboxGeneralMessageOpen: true,
      message,
      modalboxGeneralBtnLabel: btnLabel,
      modalboxGeneralBtnAction: btnAction,
    });
  }

  @autobind
  closeModalboxGeneralMessage() {
    this.setState({
      isModalboxGeneralMessageOpen: false,
    });
  }

  @autobind
  closeModalboxDownloadPlugin() {
    this.setState({
      isModalboxDownloadPluginOpen: false,
    });
  }

  @autobind
  openModalboxDownloadPlugin() {
    this.setState({
      isModalboxDownloadPluginOpen: true,
    });
  }

  @autobind
  openProjectSettings(e) {
    e.preventDefault();
    const { params: { projectId }, isProjectStatusEnabled, openFeatureUpgrade } = this.props;

    if (!isProjectStatusEnabled) {
      openFeatureUpgrade();
      return;
    }

    this.props.openProjectSettings(projectId);
  }

  componentWillMount() {
    isUserLogged().then(() => {
      const {
        params: { projectId },
        getProjectData,
        getActivityFeed,
        resetActivityFeedData,
        fetchProjects,
        projectGroups,
        groupsExpandedMode,
        groupsOrder,
      } = this.props;

      if (_.isNaN(parseInt(projectId, 10))) {
        redirectToNotFoundView();
        return;
      }

      if (!projectGroups.length) {
        fetchProjects(groupsExpandedMode, groupsOrder);
      }

      resetActivityFeedData();
      getActivityFeed(projectId);
      getProjectData(projectId);
    }).catch(logOut);
  }

  componentWillUnmount() {
    this.props.closeOpenedModals();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.params.projectId !== nextProps.params.projectId) {
      isUserLogged().then(() => {
        this.props.resetActivityFeedData();
        this.props.getActivityFeed(nextProps.params.projectId);
        this.props.getProjectData(nextProps.params.projectId);
        this.props.fetchDocumentsCount();
      }).catch(logOut);
    }

    if (!nextProps.channels.length && !nextProps.isChannelsListLoading && nextProps.slackState.authorized) {
      nextProps.getChannelsList(nextProps.params.projectId);
    }
  }

  getInvalidFileTypeCopy() {
    const { isSketchUploadAllowed } = this.props;
    const sketchFileType = isSketchUploadAllowed ? ' and Sketch files.' : '.';

    return `The file you’re trying to import is not supported. We support the following file formats: ${BASIC_FILE_TYPES}${sketchFileType}`;
  }

  @autobind
  uploadFiles(files, e) {
    const {
      createDocumentByUploadFile,
      updateUploadDocumentProgress,
      createUploadedDocument,
      params,
    } = this.props;

    const idDocument = _.uniqueId('upload_file_');
    e.persist();

    if (!this.validateFilesUpload(files)) {
      return;
    }

    createDocumentByUploadFile(idDocument, files);

    uploadStaticFiles(files, (progress) => {
      updateUploadDocumentProgress(idDocument, progress >= 100 ? 99 : progress);
    }).then((data) => {
      createUploadedDocument(idDocument, params.projectId, data, () => this.onUploadError(idDocument, files, e));
    }).catch(() => this.onUploadError(idDocument, files, e));
  }

  validateFilesUpload(files) {
    if (!files.length) {
      return false;
    }

    const { isSketchUploadAllowed } = this.props;
    const isForbiddenFileExtension = files.some(({ name, type }) => (
      (!type || type === OCTET_STREAM) && !_.includes(allowedOctetFileExtensions, getStandarizedExtension(name))
    ));
    const isSomeFilePsdOrSketch = files.some((file) => (
      isPsd(file) || (!isSketchUploadAllowed && isSketchFile(file))
    ));

    if (isForbiddenFileExtension) {
      this.openModalboxGeneralMessage(this.getInvalidFileTypeCopy());

      return false;
    }

    if (isSomeFilePsdOrSketch) {
      this.openModalboxGeneralMessage('To import Photoshop or Sketch file you need to generate *.uxpin import file using our plugin.', 'Get plugin', () => {
        this.closeModalboxGeneralMessage();
        this.openModalboxDownloadPlugin();
      });

      return false;
    }

    if (files.some((file) => isUXPinPluginFile(file)) && files.length > 1) {
      this.openModalboxGeneralMessage('You can upload only one *.uxpin file');
      return false;
    }

    if (files.some((file) => isUXPinFile(file)) && files.length > 1) {
      this.openModalboxGeneralMessage('You can upload only one *.uxp file');
      return false;
    }

    return true;
  }

  getFileType(fileTypes) {
    if (fileTypes.length === 1) {
      return fileTypes[0];
    }

    if (fileTypes.every((file) => file === fileTypes[0])) {
      return `multiple - {${fileTypes[0]}}`;
    }

    return 'multiple';
  }

  onUploadError(idDocument, files, e) {
    const { setDocumentError, removeDocumentPermanently } = this.props;

    const tryAgain = () => {
      removeDocumentPermanently(idDocument);
      this.uploadFiles(files, e);
    };

    const cancel = () => {
      removeDocumentPermanently(idDocument);
    };

    setDocumentError(idDocument, tryAgain, cancel);
  }

  @autobind
  onDropRejected(rejectedFiles) {
    const sizeLimit = Math.round(acceptedFileSize / 1024 / 1024);
    const isNotAcceptedFileType = _.some(rejectedFiles, ({ type }) => !_.includes(acceptedFileTypes, type));
    const errorMsg = isNotAcceptedFileType
      ? this.getInvalidFileTypeCopy()
      : `File size can't be larger than ${sizeLimit}MB.`;
    this.openModalboxGeneralMessage(errorMsg);
  }

  render() {
    const {
      canAddEditDeleteProjectStatus,
      channels,
      createDocument,
      currentAddedDocument,
      currentStage,
      currentStatus,
      currentUserData,
      documentsCount,
      hasAccessToEditor,
      insideProjectViewMode,
      isAccountParked,
      isDocumentAdding,
      isDocumentUploadEnabled,
      isFreePlan,
      isLoading,
      isMessageSending,
      isMessageSendingError,
      isProjectStatusEnabled,
      isUXDocumentsEnabled,
      params,
      progress,
      project,
      projectsList,
      selectedChannel,
      sendMessageToChannel,
      showAddPrototypesLimitsModal,
      isSketchUploadAllowed,
      slackState,
      totalDocumentsCount,
      isTrial,
      isMergeLimitedLibrariesEnabled,
      isMergeDisabled,
      openModalboxAddMergeLibrary,
      documents,
      filterType,
      favsPrototypes,
    } = this.props;

    if (!_.isInteger(parseInt(params.projectId))) {
      return null;
    }

    return (
      <main className="main-content">
        <InsideProject
          accept={acceptedFileTypes.join(',')}
          minSize={1}
          maxSize={acceptedFileSize}
          disablePreview
          onDropAccepted={this.uploadFiles}
          onDropRejected={this.onDropRejected}
          isMessageSendingError={isMessageSendingError}
          currentUserData={currentUserData}
          canAddEditDeleteProjectStatus={canAddEditDeleteProjectStatus}
          hasAccessToEditor={hasAccessToEditor}
          project={project}
          documentsCount={documentsCount}
          projectsList={projectsList}
          isLoading={isLoading}
          isAccountParked={isAccountParked}
          isDocumentUploadEnabled={isDocumentUploadEnabled && hasAccessToEditor}
          channels={channels}
          slackState={slackState}
          selectedChannel={selectedChannel}
          sendMessageToChannel={sendMessageToChannel}
          isMessageSending={isMessageSending}
          currentStatus={currentStatus}
          currentStage={currentStage}
          createDocument={createDocument}
          isDocumentAdding={isDocumentAdding}
          isMergeDisabled={isMergeDisabled}
          isMergeLimitedLibrariesEnabled={isMergeLimitedLibrariesEnabled}
          currentAddedDocument={currentAddedDocument}
          progress={progress}
          insideProjectViewMode={insideProjectViewMode}
          openProjectSettings={this.openProjectSettings}
          isProjectStatusEnabled={isProjectStatusEnabled}
          isUXDocumentsEnabled={isUXDocumentsEnabled}
          totalDocumentsCount={totalDocumentsCount}
          showAddPrototypesLimitsModal={showAddPrototypesLimitsModal}
          isFreePlan={isFreePlan}
          isSketchUploadAllowed={isSketchUploadAllowed}
          isTrial={isTrial}
          openModalboxAddMergeLibrary={openModalboxAddMergeLibrary}
          documents={documents}
          filterType={filterType}
          favsPrototypes={favsPrototypes}
        />
        <ModalboxGeneralMessage
          isOpen={this.state.isModalboxGeneralMessageOpen}
          onClose={this.closeModalboxGeneralMessage}
          onAccept={this.state.modalboxGeneralBtnAction || this.closeModalboxGeneralMessage}
          title="Invalid file"
          btnLabel={this.state.modalboxGeneralBtnLabel}
          message={this.state.message}
        />
        <ModalboxDownloadPlugin
          isOpen={this.state.isModalboxDownloadPluginOpen}
          onClose={this.closeModalboxDownloadPlugin}
        />
      </main>
    );
  }
}
