import { connect } from 'react-redux';
import { some } from 'lodash';
import DesignSystemsComponent from '../components/DesignSystems/DesignSystems';
import { fetchDesignSystems, getTemplateSystem, isLimitReached } from '../../controllers/actions/designSystems.actions';
import { openModal } from '../../controllers/actions/modal.actions';
import { modalsIds } from '../../constants/modal.constants';
import { COPY_TYPE, STATUS, TEMPLATES } from '../../constants/designSystems.constants';

export const mapStateToProps = (state) => {
  const { data: currentUserData } = state.currentUser;
  const { systems, templateData } = state.designSystems;
  const { features } = state.features;
  const { accountPlan, isDuringFetchingAccountPlan } = state.accountSettings;
  const limit = accountPlan ? accountPlan.design_systems_limit : undefined;
  const usedDesignSystems = accountPlan ? accountPlan.used_design_systems : undefined;
  const isDuplicationInProgress = some(systems, (system) => system.status === STATUS.DUPLICATING);

  return {
    currentUserData,
    features,
    systems,
    limit,
    usedDesignSystems,
    isDuringFetchingAccountPlan,
    templateData,
    isDuplicationInProgress,
  };
};

export const mapDispatchToProps = (dispatch) => ({
  getDesignSystems: () => {
    dispatch(fetchDesignSystems());
  },
  showDesignLimitModal: () => dispatch(openModal(modalsIds.MODALBOX_DESIGN_LIMIT_REACHED, {
    type: COPY_TYPE.DESIGN_SYSTEM,
  })),
  getDemoTemplate: () => {
    dispatch(getTemplateSystem(TEMPLATES.BOOTSTRAP));
  },
  isDesignSystemsLimitReached: () => (
    dispatch(isLimitReached())
  ),
});

export const DesignSystems = connect(mapStateToProps, mapDispatchToProps)(DesignSystemsComponent);
