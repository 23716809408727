
export const GLOBAL_LIBRARIES = [
  // {
  //   label: 'Material Design',
  //   icon: 'ds-icon--font__brand-storybook',
  //   name: 'Material UI',
  // },
  // {
  //   label: 'Ant-Design',
  //   icon: 'ds-icon--font__brand-npm',
  //   name: 'Ant-Design',
  // },
  // {
  //   label: 'Fluent UI',
  //   icon: 'ds-icon--font__brand-npm',
  //   name: 'Fluent UI',
  // },
  {
    label: 'Ant Design v5',
    icon: 'ds-icon--font__git',
    name: 'Ant Design v5',
    isLimitedLibrary: true,
  },
  {
    label: 'MUI v5',
    icon: 'ds-icon--font__git',
    name: 'MUI v5',
  },
  {
    label: 'React Bootstrap v2',
    icon: 'ds-icon--font__git',
    name: 'React Bootstrap v2',
    isLimitedLibrary: true,
  },
  {
    label: 'Tailwind UI',
    icon: 'ds-icon--font__git',
    name: 'Tailwind UI',
    isLimitedLibrary: true,
  },
  // {
  //   label: 'UXPin Boilerplate',
  //   icon: 'ds-icon--font__git',
  //   name: 'UXPin Boilerplate',
  // },
];

export const ITEM_TYPES = {
  STORYBOOK_COMPONENT: 'storybook-component',
};

export const MERGE_ERROR_TYPES = {
  LIBRARY_ADDING: 'library-adding',
  LIBRARY_DELETING: 'library-deleting',
  LIBRARY_UPDATING: 'library-updating',
  LIBRARY_LIMIT_REACHED: 'library-limit-reached',
  INVALID_URL: 'INVALID_URL',
};

export const LIBRARY_TYPES = {
  CODE_SYNC: 'code-sync',
  STORYBOOK: 'storybook',
};
