/* global console */

'use strict';

import { statusActions } from '../../constants/status.constants';
import { request } from '../../utils/request';

export const fetchStatus = () => (dispatch) => request.get('/status')
  .then((data) => {
    dispatch({
      type: statusActions.FETCH_MAINTENANCE_SETTINGS,
      maintenanceSettings: data.maintenanceSettings,
    });
  })
  .catch((e) => {
    console.error(e); // eslint-disable-line no-console
  });
