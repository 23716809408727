/* eslint-disable max-len */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */

'use strict';

import React from 'react';
import { Modal } from '@uxpin/shared-components';
import autobind from 'autobind-decorator';

export default class ModalboxTermsOfService extends React.Component {
  constructor(props) {
    super(props);

    this.MODAL = {
      WRAPPER_ID: 'tos-modal-box',
      CLASS_NAMES: 'center-buttons modal-box-text-content no-image marketing-promo-modal-box',
    };
  }

  @autobind
  onClose(e) {
    if (e) {
      e.preventDefault();
    }

    this.props.onClose();
  }

  render() {
    return (
      <Modal
        wrapperId={this.MODAL.WRAPPER_ID}
        classNames={this.MODAL.CLASS_NAMES}
        title="Update to our Terms of Service and Privacy Policy"
        isOpen={this.props.isOpen}
        isCloseBlocked
        onClose={this.onClose}>
        <section className="modal-box-content">
          <div className="update-info">
            <p>
              Good news, UXPin becomes more and more global! In order to comply with the U.S. and European law regulations, we changed our Terms of Service and Privacy Policy.
              Hungry for details? Please visit:
            </p>
            <ul>
              <li><a className="blue-link" href="https://www.uxpin.com/community/tutorials/terms-of-service/" target="_blank">Changes overview</a></li>
              <li><a className="blue-link" href="https://www.uxpin.com/tos.html" target="_blank">Updated Terms of Service</a></li>
              <li><a className="blue-link" href="https://www.uxpin.com/privacy.html" target="_blank">Updated Privacy Policy</a></li>
            </ul>
            <p>
              Do you want to know more? Drop us a line at
              {' '}
              <a className="blue-link" href="mailto:hello@uxpin.com">hello@uxpin.com</a>
              {' '}
              and we will walk you through the changes.
            </p>
          </div>
        </section>
        <footer>
          <ul className="btns-group">
            <li>
              <a href="#close" className="btn-solid" onClick={this.onClose}>I accept the changes. Take me to the app</a>
            </li>
          </ul>
        </footer>
      </Modal>
    );
  }
}
