/* eslint-disable max-len,react/button-has-type */
/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/require-default-props */

'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import autobind from 'autobind-decorator';
import Truncate from '../../Helpers/Truncate';
import { getMixpanel } from '../../../../../../shared/mixpanel';

export default class ProjectPending extends React.Component {
  constructor(props) {
    super(props);

    this.project = {
      NAME_MAX_LENGTH: 30,
    };
  }

  componentDidMount() {
    const {
      idProject,
      shouldStartCheckingDuplicationStatus,
      hasDuplicationStatusRequestBeenSent,
      hasTemporaryId,
      requestCheckingDuplicationStatus,
    } = this.props;

    if (shouldStartCheckingDuplicationStatus && !hasTemporaryId && !hasDuplicationStatusRequestBeenSent) {
      requestCheckingDuplicationStatus(idProject);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.shouldStartCheckingDuplicationStatus && !nextProps.hasTemporaryId && !this.props.hasDuplicationStatusRequestBeenSent && !nextProps.hasDuplicationStatusRequestBeenSent) {
      nextProps.requestCheckingDuplicationStatus(nextProps.idProject);
    }
  }

  @autobind
  tryAgain(e) {
    e.preventDefault();
    e.stopPropagation();
    if (!this.props.error) { return; }

    this.props.error.tryAgain();
  }

  @autobind
  cancel(e) {
    e.preventDefault();
    e.stopPropagation();
    if (!this.props.error) { return; }

    this.props.error.cancel();
  }

  @autobind
  restoreProject(e) {
    const { restoreProject, idProject, stopActionOnProject, isArchived } = this.props;
    e.preventDefault();
    stopActionOnProject(idProject);
    restoreProject(idProject, isArchived);
  }

  @autobind
  removeProject(e) {
    // eslint-disable-next-line react/prop-types
    const { removeProjectPermanently, idProject, stopActionOnProject } = this.props;
    e.preventDefault();
    removeProjectPermanently(idProject);
    getMixpanel((mixpanel) => {
      mixpanel.track('project_deleted');
    });
    stopActionOnProject(idProject);
  }

  shouldComponentUpdate(nextProps) {
    return Object.keys(this.props).some((propName) => !_.isEqual(this.props[propName], nextProps[propName]));
  }

  render() {
    const { projectName, removingCounter } = this.props;

    return (
      <section className="project-pending-info">
        <h5 className="delete-info hidden-info" title={projectName} ref="deleteInfo">
          Project&nbsp;&#34;
          <Truncate maxLength={this.project.NAME_MAX_LENGTH}>
            {projectName}
          </Truncate>
          &#34;&nbsp;has been deleted. You&nbsp;can&nbsp;still&nbsp;
          <a href="#undo-delete" onClick={this.restoreProject} className="blue-link underline-link">Undo.</a>
          <a
            href="#execute"
            onClick={this.removeProject}
            title="Execute"
            className="execute icon-general-close only-icon-font">
            Execute
          </a>
        </h5>
        <section className="error-message hidden-info">
          <h5 className="info">Sorry, something went wrong</h5>
          <footer>
            <button onClick={this.tryAgain} className="btn-ghost white btn-wide icon-general-revert with-icon">Try again</button>
            <p className="cancel">
              <a onClick={this.cancel} href="#cancel" className="underline-link icon-general-close">Cancel</a>
            </p>
          </footer>
        </section>
        <p className="pending-info">
          <span>
            {removingCounter}
s
          </span>
        </p>
      </section>
    );
  }
}

ProjectPending.propTypes = {
  projectName: PropTypes.string.isRequired,
  stopActionOnProject: PropTypes.func.isRequired,
  restoreProject: PropTypes.func.isRequired,
  shouldStartCheckingDuplicationStatus: PropTypes.bool,
  idProject: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  hasTemporaryId: PropTypes.bool,
  removingCounter: PropTypes.number,
  error: PropTypes.object,
  hasDuplicationStatusRequestBeenSent: PropTypes.bool,
  requestCheckingDuplicationStatus: PropTypes.func,
  isArchived: PropTypes.bool,
};

ProjectPending.defaultProps = {
  projectName: '',
  stopActionOnProject: _.noop,
  restoreProject: _.noop,
  idProject: 0,
  removingCounter: 0,
  requestCheckingDuplicationStatus: _.noop,
  isArchived: false,
};
