/* eslint-disable react/destructuring-assignment,jsx-a11y/iframe-has-title */
/* eslint-disable react/prop-types */

'use strict';

import React from 'react';
import { Modal } from '@uxpin/shared-components';
import autobind from 'autobind-decorator';
import classnames from 'classnames';
import config from 'react-global-configuration';

export default class ModalboxSpecModePromoUpgrade extends React.Component {
  constructor(props) {
    super(props);
    this.MODAL = {
      WRAPPER_ID: 'upgrade-plan-modal-box',
      CLASS_NAMES: ['iframe-modal-box'],
      URL: `${config.get('APP_URL')}/dms/DashboardModals/PromoSpecUpgrade/?withoutCloseIcon=true`,
    };

    this.state = {
      isLoading: false,
    };
  }

  getModalClasses() {
    return classnames('modal-box-content', { loading: this.state.isLoading });
  }

  @autobind
  onOpen() {
    this.setState({
      isLoading: true,
    });
  }

  @autobind
  onLoad() {
    this.setState({
      isLoading: false,
    });
  }

  render() {
    return (
      <Modal
        wrapperId={this.MODAL.WRAPPER_ID}
        classNames={this.MODAL.CLASS_NAMES}
        title={this.MODAL.TITLE}
        isOpen={this.props.isOpen}
        isCloseBlocked={this.props.isCloseBlocked}
        onOpen={this.onOpen}
        onClose={this.props.onClose}>
        <section className={this.getModalClasses()}>
          <iframe
            src={this.MODAL.URL}
            onLoad={this.onLoad}
            frameBorder="0"
          />
        </section>
      </Modal>
    );
  }
}
