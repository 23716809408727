import { connect } from 'react-redux';
import ModalboxAddOnsFollowUpUpgradeComponent from '../../../components/Modalboxes/Promo/ModalboxAddOnsFollowUpUpgrade';
import { closeAddOnUpgradeModal, applyAddOn } from '../../../../controllers/actions/marketing.actions';
import { planPeriods } from '../../../../constants/accountSettings.constants';

export const mapStateToProps = (state) => {
  const { accountPlan } = state.accountSettings;

  const isMonthly = accountPlan && accountPlan.plan_period === planPeriods.MONTH;
  const planName = accountPlan && accountPlan.name;

  return {
    isMonthly,
    planName,
  };
};

export const mapDispatchToProps = (dispatch) => ({
  closeAddOnUpgradeModal: () => {
    dispatch(closeAddOnUpgradeModal());
  },
  applyAddOn: (addOnCode) => dispatch(applyAddOn(addOnCode)),
});

// eslint-disable-next-line max-len
export const ModalboxAddOnsFollowUpUpgrade = connect(mapStateToProps, mapDispatchToProps)(ModalboxAddOnsFollowUpUpgradeComponent);
