'use strict';

import thunk from 'redux-thunk';
import { createStore, combineReducers, applyMiddleware } from 'redux';
import { LibraryPermissions, Prismic } from '@uxpin/shared-components';

import { projectsReducer } from './reducers/projects.reducer';
import designSystemsReducer from './reducers/designSystems.reducer';
import { searchReducer } from './reducers/search.reducer';
import { projectActionsReducer } from './reducers/projectActions.reducer';
import { projectsGroupsReducer } from './reducers/projectsGroups.reducer';
import { permissionsReducer } from './reducers/permissions.reducer';
import { currentUserReducer } from './reducers/currentUser.reducer';
import { rolesReducer } from './reducers/roles.reducer';
import { projectsGroupsManagementReducer } from './reducers/projectsGroupsManagement.reducer';
import { insideProjectReducer } from './reducers/insideProject.reducer';
import { projectStatusReducer } from './reducers/projectStatus.reducer';
import { documentActionsReducer } from './reducers/documentsActions.reducer';
import { activityFeedReducer } from './reducers/activityFeed.reducer';
import { slackReducer } from './reducers/slack.reducer';
import { featuresReducer } from './reducers/features.reducer';
import { membersReducer } from './reducers/members.reducer';
import { modalReducer } from './reducers/modal.reducer';
import { shareReducer } from './reducers/share.reducer';
import { feedbackReducer } from './reducers/feedback.reducer';
import { stakeholdersReducer } from './reducers/stakeholders.reducer';
import { upgradeModalReducer, upgradeModalSpecModePromoReducer } from './reducers/upgradeModal.reducer';
import { accountSettingsReducer } from './reducers/accountSettings.reducer';
import { projectSelectorReducer } from './reducers/projectSelector.reducer';
import { statusReducer } from './reducers/status.reducer';
import { marketingReducer } from './reducers/marketing.reducer';
import { settingsReducer } from './reducers/settings.reducer';
import { pricingReducer } from './reducers/pricing.reducer';

import errorHandler from '../utils/errorHandleMiddleware';


const createStoreWithMiddleware = applyMiddleware(thunk, errorHandler)(createStore);

const dashboardApp = combineReducers({
  projects: projectsReducer,
  designSystems: designSystemsReducer,
  search: searchReducer,
  projectActions: projectActionsReducer,
  projectsGroups: projectsGroupsReducer,
  projectStatus: projectStatusReducer,
  projectsGroupsManagement: projectsGroupsManagementReducer,
  permissions: permissionsReducer,
  currentUser: currentUserReducer,
  roles: rolesReducer,
  members: membersReducer,
  modal: modalReducer,
  insideProject: insideProjectReducer,
  slack: slackReducer,
  features: featuresReducer,
  upgradeModal: upgradeModalReducer,
  upgradeModalSpecMode: upgradeModalSpecModePromoReducer,
  feedback: feedbackReducer,
  share: shareReducer,
  activityFeed: activityFeedReducer,
  documentActions: documentActionsReducer,
  projectSelector: projectSelectorReducer,
  prismicContent: Prismic.reducer,
  accountSettings: accountSettingsReducer,
  stakeholders: stakeholdersReducer,
  status: statusReducer,
  marketing: marketingReducer,
  settings: settingsReducer,
  libraryPermissions: LibraryPermissions.reducer,
  pricing: pricingReducer,
});

export const dashboard = createStoreWithMiddleware(
  dashboardApp,
  // eslint-disable-next-line no-underscore-dangle
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
