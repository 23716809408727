/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */

'use strict';

import React from 'react';
import { triggerTypes } from '@uxpin/shared-components';
import _ from 'lodash';
import Stakeholder from '../../../containers/ApprovalProcess/components/Stakeholder';

export default class StakeholdersView extends React.Component {
  openModalboxAddStakeholder(e, stakeholder) {
    this.props.openModalboxAddStakeholder(e, stakeholder);
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  getRemovedStakeholders(props) {
    return _.without(props.stakeholders.map((stakeholder) => (stakeholder.isRemoved ? stakeholder : null)), null);
  }

  componentWillReceiveProps(nextProps) {
    const removedStakeholder = this.getRemovedStakeholders(nextProps);

    if (removedStakeholder.length) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        removedStakeholder.forEach((stakeholder) => {
          if (stakeholder.time && stakeholder.time >= 0) {
            nextProps.countTimeToRemoveStakeholder(stakeholder.id);
          }
        });
      }, 1000);
    } else {
      clearTimeout(this.timeout);
    }
  }

  render() {
    return (
      <section className="stakeholders-view">
        <ul className="item-list">
          {this.props.stakeholders.map((stakeholder, id) => (
            <Stakeholder
              // eslint-disable-next-line react/no-array-index-key
              key={id}
              document={this.props.document}
              stakeholder={stakeholder}
              triggerType={triggerTypes.HOVER}
              autoFit
              openModalboxSendRequest={this.props.openModalboxSendRequest}
              openModalboxAddStakeholder={(e) => this.openModalboxAddStakeholder(e, stakeholder)}
            />
          ))}
        </ul>
      </section>
    );
  }
}
