'use strict';

import _ from 'lodash';
import { stakeholdersActions } from '../../constants/stakeholders.constants';

const {
  ADD_STAKEHOLDER,
  ASSIGN_STAKEHOLDER_DATA,
  CLEAR_ERROR,
  CLEAR_REQUEST_ERROR,
  CLEAR_STAKEHOLDERS,
  CLOSE_EDIT_MODAL,
  COUNT_TIME_TO_REMOVE_STAKEHOLDER,
  FETCH_STAKEHOLDERS,
  MARK_STAKEHOLDER_AS_REMOVED,
  REMOVE_STAKEHOLDER,
  REQUEST_FETCHING_STAKEHOLDERS,
  REQUEST_MODIFYING_STAKEHOLDERS,
  RESTORE_STAKEHOLDER,
  SENDING_REQUEST,
  SEND_REQUEST,
  SET_ERROR,
  SET_REQUEST_ERROR,
} = stakeholdersActions;

function removeStakeholder(stakeholders, id) {
  return stakeholders.filter((stakeholder) => stakeholder.id !== id);
}

function findStakeholder(stakeholders, id) {
  return _.first(stakeholders.filter((stakeholder) => stakeholder.id === id));
}

function assignDataToStakeholder(stakeholders, id, data) {
  return stakeholders.map((stakeholder) => {
    if (stakeholder.id === id) {
      const newStakeholder = Object.assign({}, stakeholder);
      _.assign(newStakeholder, data);
      return newStakeholder;
    }

    return stakeholder;
  });
}


const actionFetchStakeholders = (state, action) => (
  Object.assign({}, state, {
    stakeholders: action.stakeholders,
    isLoading: false,
  })
);

const actionRequestFetchingStakeholders = (state) => (
  Object.assign({}, state, {
    isLoading: true,
  })
);

const actionAssignStakeholderData = (state, action) => (
  Object.assign({}, state, {
    stakeholders: assignDataToStakeholder(state.stakeholders, action.id, action.data),
    isStakeholderBeingModified: false,
  })
);

const actionMarkStakeholderAsRemoved = (state, action) => (
  Object.assign({}, state, {
    stakeholders: assignDataToStakeholder(state.stakeholders, action.id, { isRemoved: true, time: 10 }),
  })
);

const actionCountTimeToRemoveStakeholder = (state, action) => {
  const stakeholder = findStakeholder(state.stakeholders, action.id);

  return Object.assign({}, state, {
    stakeholders: assignDataToStakeholder(state.stakeholders, action.id, { time: stakeholder.time - 1 }),
  });
};

const actionRestoreStakeholder = (state, action) => (
  Object.assign({}, state, {
    stakeholders: assignDataToStakeholder(state.stakeholders, action.id, { isRemoved: false, time: null }),
  })
);

const actionRequestModifyingStakeholders = (state) => (
  Object.assign({}, state, {
    isStakeholderBeingModified: true,
    isStakeholderModificationError: false,
  })
);

const actionAddStakeholder = (state, action) => (
  Object.assign({}, state, {
    stakeholders: [...state.stakeholders, action.data],
    isStakeholderBeingModified: false,
  })
);

const actionSetError = (state) => (
  Object.assign({}, state, {
    isStakeholderBeingModified: false,
    isStakeholderModificationError: true,
  })
);

const actionClearError = (state) => (
  Object.assign({}, state, {
    isStakeholderModificationError: false,
  })
);

const actionRemoveStakeholder = (state, action) => (
  Object.assign({}, state, {
    stakeholders: removeStakeholder(state.stakeholders, action.id),
  })
);

const actionClearStakeholders = (state) => (
  Object.assign({}, state, {
    stakeholders: [],
  })
);

const actionCloseEditModal = (state) => (
  Object.assign({}, state, {
    isStakeholderBeingModified: false,
  })
);

const actionSendingRequest = (state) => (
  Object.assign({}, state, {
    isRequestBeingModified: true,
  })
);

const actionSendRequest = (state) => (
  Object.assign({}, state, {
    isRequestBeingModified: false,
    isRequestSendingError: false,
  })
);

const actionSetRequestError = (state) => (
  Object.assign({}, state, {
    isRequestBeingModified: false,
    isRequestSendingError: true,
  })
);

const actionClearRequestError = (state) => (
  Object.assign({}, state, {
    isRequestSendingError: false,
  })
);

const stakeholdersHandler = {
  [ADD_STAKEHOLDER]: actionAddStakeholder,
  [ASSIGN_STAKEHOLDER_DATA]: actionAssignStakeholderData,
  [CLEAR_ERROR]: actionClearError,
  [CLEAR_REQUEST_ERROR]: actionClearRequestError,
  [CLEAR_STAKEHOLDERS]: actionClearStakeholders,
  [CLOSE_EDIT_MODAL]: actionCloseEditModal,
  [COUNT_TIME_TO_REMOVE_STAKEHOLDER]: actionCountTimeToRemoveStakeholder,
  [FETCH_STAKEHOLDERS]: actionFetchStakeholders,
  [MARK_STAKEHOLDER_AS_REMOVED]: actionMarkStakeholderAsRemoved,
  [REMOVE_STAKEHOLDER]: actionRemoveStakeholder,
  [REQUEST_FETCHING_STAKEHOLDERS]: actionRequestFetchingStakeholders,
  [REQUEST_MODIFYING_STAKEHOLDERS]: actionRequestModifyingStakeholders,
  [RESTORE_STAKEHOLDER]: actionRestoreStakeholder,
  [SENDING_REQUEST]: actionSendingRequest,
  [SEND_REQUEST]: actionSendRequest,
  [SET_ERROR]: actionSetError,
  [SET_REQUEST_ERROR]: actionSetRequestError,
};

const handleStakeholdersAction = (state, action) => {
  if (!_.has(stakeholdersHandler, action.type)) {
    return state;
  }

  return stakeholdersHandler[action.type](state, action);
};

export function stakeholdersReducer(state = {
  stakeholders: [],
  isLoading: false,
  isStakeholderModificationError: false,
  isStakeholderBeingModified: false,
  isRequestBeingModified: false,
  isRequestSendingError: false,
}, action) {
  return handleStakeholdersAction(state, action);
}
