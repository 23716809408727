import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { getImagePath } from '../../../../utils/url';

const SelectBox = ({ iconFilename, onClick, optionName, selected, text, value }) => {
  const classes = classnames('option', {
    selected,
  });

  const onSelect = () => onClick(value);

  const renderIcon = () => (
    <figure className="logo">
      <img
        src={getImagePath(`onboarding/${iconFilename}`)}
        className="logo-image"
        alt={text}
        width={30}
        height={30}
      />
    </figure>
  );

  return (
    <label htmlFor={value} className={classes}>
      <input
        type="checkbox"
        id={value}
        name={optionName}
        value={value}
        onChange={onSelect}
        defaultChecked={selected}
      />
      {renderIcon()}
      <span className="option-name">{text}</span>
    </label>
  );
};

SelectBox.propTypes = {
  text: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
  iconFilename: PropTypes.string.isRequired,
  optionName: PropTypes.string,
};

SelectBox.defaultProps = {
  optionName: '',
};

export default SelectBox;
