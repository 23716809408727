import { connect } from 'react-redux';
import ProjectPreviewComponent from '../components/ProjectsList/components/ProjectPreview';

export const mapStateToProps = (state) => {
  const { selectedProjects } = state.projectSelector;

  return {
    selectedProjects,
  };
};

export const mapDispatchToProps = () => ({});

export const ProjectPreview = connect(mapStateToProps, mapDispatchToProps)(ProjectPreviewComponent);
