/* eslint-disable react/jsx-closing-tag-location,max-len */

'use strict';

import React from 'react';
import _ from 'lodash';
import autobind from 'autobind-decorator';
import { ModalboxGeneralMessage } from '@uxpin/shared-components';

export default class ModalboxProjectChangeGroupInfo extends ModalboxGeneralMessage {
  constructor(props) {
    super(props);

    this.modalId = _.uniqueId();

    this.MODAL = {
      WRAPPER_ID: `general-message-modal-box-${this.modalId}`,
      CLASS_NAMES: ['modal-box-medium-width'],
    };
  }

  @autobind
  renderDescription() {
    const { projects, projectsWithGroups, projectGroupName } = this.props;
    const messages = [];

    if (projects.length) {
      messages.push(<p key={0}>You have moved <strong>{projects.map((project) => project.name).join(', ')}</strong> project{ projects.length > 1 ? 's' : ''}
        into the <strong>{projectGroupName}</strong> group.
        All users in this group will now have access to { projects.length > 1 ? 'these' : 'this'} project{ projects.length > 1 ? 's' : ''}.</p>);
    }

    if (projectsWithGroups.length) {
      messages.push(<p key={1}><strong>{projectsWithGroups.map((project) => project.name).join(', ')}</strong>
        project{ projectsWithGroups.length > 1 ? 's' : ''} are in a different groups.
        If you move it to current  group, users from another group will see it as ungrouped, and all users of
        <strong>{projectGroupName}</strong> going to be added to { projects.length > 1 ? 'these' : 'this'} project{ projectsWithGroups.length > 1 ? 's' : '' }
      </p>);
    }

    return (<div>{messages}</div>);
  }

  render() {
    return super.render();
  }
}

ModalboxProjectChangeGroupInfo.defaultProps = {
  title: '',
  projects: [],
  projectsWithGroups: [],
  onAccept: _.noop,
  onClose: _.noop,
};
