import { connect } from 'react-redux';
import DesignSystemItemComponent from '../components/DesignSystems/components/DesignSystemItem';
import * as modal from '../../controllers/actions/modal.actions';
import { modalsIds } from '../../constants/modal.constants';
import { updateLibraryName } from '../../controllers/actions/designSystems.actions';

export const mapDispatchToProps = (dispatch) => ({
  openModalboxDesignSystemDelete: (system) => {
    dispatch(modal.openModal(modalsIds.MODALBOX_DESIGN_SYSTEM_DELETE, system));
  },

  openModalboxDesignSystemPermissions: (system) => {
    dispatch(modal.openModal(modalsIds.MODALBOX_DESIGN_SYSTEM_PERMISSIONS, system));
  },

  updateLibraryName: (idLibrary, name) => {
    dispatch(updateLibraryName(idLibrary, name));
  },
});

export const DesignSystemItem = connect(null, mapDispatchToProps)(DesignSystemItemComponent);
