/* eslint-disable react/destructuring-assignment */

'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import autobind from 'autobind-decorator';
import classnames from 'classnames';
import {
  noop, mapValues, has, size, pickBy, identity, forEach,
} from 'lodash';
import Form from '../../Helpers/validation/Form';
import {
  roleField, teamSizeField, problemField, companyNameField,
} from './helpers/ExtendTrialFormFields';
import { extendTrialFormAllowedFields, extendTrialFormFieldsCount } from '../../../../constants/extendTrial.constants';
import { preventDefaultEvent } from '../../../../utils/common';

export default class ExtendTrialFormComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      // eslint-disable-next-line react/no-unused-state
      formValid: false,
      isFormFilled: false,
      processing: false,
    };

    this.fieldData = {};
    this.formData = {};
    this.fieldComponentsFunctions = {
      role: roleField,
      teamSize: teamSizeField,
      problem: problemField,
      companyName: companyNameField,
    };
    this.fieldRenderFunctions = [];

    // set form data using allowed fields only:
    this.setFormData(extendTrialFormAllowedFields.slice(), props.fields);
  }

  @autobind
  onOpen() {
    this.customForm = {};
  }

  @autobind
  handleKissmetricsOnSubmit(newValues) {
    const propertyNames = {
      role: 'role',
      teamSize: 'team size',
      problem: 'what brought you',
      companyName: 'company name',
    };

    const properties = {};

    forEach(newValues, (value, key) => {
      properties[`Onboarding - ${propertyNames[key]}`] = value;
    });
  }

  setFormData(allowedFields, chosenFields) {
    if (allowedFields.length < 1) {
      return;
    }

    const field = allowedFields.shift();

    if (chosenFields.indexOf(field) !== -1) {
      this.formData[field] = '';
      this.fieldRenderFunctions.push(this.fieldComponentsFunctions[field]);
    }

    this.setFormData(allowedFields, chosenFields);
  }

  @autobind
  renderFormFields() {
    const fieldData = this.props.accountProspectData;

    return (
      <div>
        {this.fieldRenderFunctions.map(
          (renderField) => renderField(this.updateFormFilled, fieldData)
        )}
      </div>
    );
  }

  getCurrentFormValues() {
    return mapValues(this.customForm.fields, (field) => (field.state.value));
  }

  getNonEmptyFormValues() {
    const values = this.getCurrentFormValues();
    return pickBy(values, identity);
  }

  isFormFilled() {
    const values = this.getNonEmptyFormValues();
    return size(values) === extendTrialFormFieldsCount;
  }

  @autobind
  updateFormFilled() {
    const isFormFilled = this.isFormFilled();

    if (isFormFilled !== this.state.isFormFilled) {
      this.setState({
        isFormFilled,
      });
    }
  }

  @autobind
  submitForm() {
    if (this.state.isFormFilled) {
      this.setState({
        processing: true,
      });
      this.customForm.handleSubmit();
    }
  }

  getUpdatedFormData(formData) {
    const newFormData = {};

    // eslint-disable-next-line no-return-assign
    extendTrialFormAllowedFields.map((key) => (newFormData[key] = this.props.accountProspectData[key] || ''));
    Object.assign(newFormData, formData);

    return newFormData;
  }

  @autobind
  handleSuccess(formData) {
    const { savePartialLeadForm, onSubmit } = this.props;
    const newFormData = this.getUpdatedFormData(formData);
    const newValues = this.getNonEmptyFormValues();

    savePartialLeadForm(newFormData)
      .then((data) => {
        if (!has(data, 'error')) {
          this.handleKissmetricsOnSubmit(newValues);
          onSubmit();
        }
      });
  }

  @autobind
  cancel(e) {
    preventDefaultEvent(e);
    this.props.onCancel();
  }

  @autobind
  render() {
    return (
      <Form
        ref={(el) => { this.customForm = el; }}
        checkMode="onBlur"
        customSubmitBtn
        onSuccess={this.handleSuccess}
        autoComplete="off"
        autofocus>
        {this.renderFormFields()}

        <ul className="btns-group">
          <li>
            <a
              href="#close"
              className="gray-style cancel-button"
              onClick={this.cancel}>
              Thanks, maybe later
            </a>
          </li>
          <li>
            <button
              type="button"
              // eslint-disable-next-line max-len
              className={classnames('btn-solid', 'btn-wide', 'blue', { inactive: !this.state.isFormFilled, working: this.state.processing })}
              onClick={this.submitForm}>
              Extend trial
            </button>
          </li>
        </ul>
      </Form>
    );
  }
}

ExtendTrialFormComponent.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  savePartialLeadForm: PropTypes.func.isRequired,
  fields: PropTypes.array.isRequired,
  accountProspectData: PropTypes.object,
};

ExtendTrialFormComponent.defaultProps = {
  onSubmit: noop,
  onCancel: noop,
  accountProspectData: {},
};
