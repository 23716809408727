import { connect } from 'react-redux';
import ModalboxProUpsellComponent from '../../../components/Modalboxes/Promo/ModalboxProUpsell';
import { modalsIds } from '../../../../constants/modal.constants';
import { closeModal } from '../../../../controllers/actions/modal.actions';
import { saveSettings } from '../../../../controllers/actions/settings.actions';
import { upsellCampaign2019 } from '../../../../constants/marketing.constants';

const { PRO_UPSELL_MODAL_SEEN } = upsellCampaign2019;

export const mapStateToProps = (state) => {
  const { openedModalsIds } = state.modal;
  const { data: settings, areSettingsFetched } = state.settings;

  const modalSeen = areSettingsFetched && settings[PRO_UPSELL_MODAL_SEEN];
  const isOpen = !!openedModalsIds[modalsIds.PRO_UPSELL];

  return {
    isOpen,
    areSettingsFetched,
    modalSeen,
  };
};

export const mapDispatchToProps = (dispatch) => ({
  onClose: () => {
    dispatch(closeModal(modalsIds.PRO_UPSELL));
  },
  saveAsSeen: () => {
    dispatch(saveSettings(PRO_UPSELL_MODAL_SEEN, 'true'));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalboxProUpsellComponent);
