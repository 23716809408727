'use strict';

import { statusActions } from '../../constants/status.constants';

export function statusReducer(state = {
  maintenanceSettings: {
    isActive: false,
    id: null,
    startTime: null,
    endTime: null,
  },
}, action) {
  switch (action.type) {
    case statusActions.FETCH_MAINTENANCE_SETTINGS:
      return Object.assign({}, state, {
        maintenanceSettings: action.maintenanceSettings,
      });
    default:
      return state;
  }
}
