import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { LibraryPermissions } from '@uxpin/shared-components';
import { settingsName } from '../../constants/settings.constants';
import { onMergeRequestSubmit } from './MergeLibrarySetting';

const DesignSystemLibraryPermissions = connect(
  (state) => ({
    accessScope: state.libraryPermissions.accessScope,
    currentUserId: state.currentUser.data.id,
    currentUserEmail: state.currentUser.data.email,
    editScope: state.libraryPermissions.editScope,
    error: state.libraryPermissions.errorLibrary,
    idActiveAccount: state.currentUser.data.idActiveAccount,
    isLibraryDeleting: state.libraryPermissions.isLibraryDeleting,
    isLibraryProcessing: state.libraryPermissions.isLibraryProcessing,
    isMergeEnabled: false,
    isMergeRequestSubmitted: !!state.settings.data[settingsName.MERGE_FROM_SUBMITTED],
    membersData: state.members.membersData,
  }),
  (dispatch) => ({
    actions: bindActionCreators({
      clearLibraryError: LibraryPermissions.actions.clearLibraryError,
      requestSetViewPermissions: LibraryPermissions.actions.requestSetViewPermissions,
      requestSetEditPermissions: LibraryPermissions.actions.requestSetEditPermissions,
      onMergeRequestSubmit,
    }, dispatch),
  })
)(LibraryPermissions.Modalbox);

export default DesignSystemLibraryPermissions;
