import { connect } from 'react-redux';
import { find } from 'lodash';
import ModalboxMergeLibrarySetting from '../components/Modalboxes/ModalboxMergeLibrarySetting';
import { modalsIds } from '../../constants/modal.constants';
import { closeModal } from '../../controllers/actions/modal.actions';
import { FEATURE_STATUS } from '../../constants/features.constants';

export const mapStateToProps = (state) => {
  const designSystemId = state.modal.data[modalsIds.MODALBOX_MERGE_SETTING] || null;
  const openedModalsId = state.modal.openedModalsIds[modalsIds.MODALBOX_MERGE_SETTING];
  const designSystemData = state.designSystems
    ? find(state.designSystems.libraries, (library) => library.id === designSystemId)
    : {};
  const isMergeEnabled = state.features.features.merge === FEATURE_STATUS.ENABLED;

  return {
    isOpen: openedModalsId || false,
    designSystemData: designSystemData || {},
    currentUserEmail: '',
    idActiveAccount: 0,
    isMergeRequestSubmitted: false,
    isMergeEnabled,
    isGitOnlyEnabled: isMergeEnabled && state.features.features.merge_git_only === FEATURE_STATUS.ENABLED,
    isPackageIntegrationOnlyEnabled: isMergeEnabled
      && state.features.features.merge_package_integration_only === FEATURE_STATUS.ENABLED,
  };
};

export const mapDispatchToProps = (dispatch) => ({
  onClose: () => {
    dispatch(closeModal(modalsIds.MODALBOX_MERGE_SETTING));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalboxMergeLibrarySetting);
