'use strict';

import { membersActions } from '../../constants/members.constants';

export function membersReducer(state = {
  membersData: [],
  projectMembers: [],
  isProjectMembersLoading: false,
}, action) {
  switch (action.type) {
    case membersActions.FETCH_ACCOUNT_MEMBERS:
      return Object.assign({}, state, {
        membersData: action.data,
      });
    case membersActions.REQUEST_FETCH_PROJECT_MEMBERS:
      return Object.assign({}, state, {
        isProjectMembersLoading: true,
      });
    case membersActions.FETCH_PROJECT_MEMBERS:
      return Object.assign({}, state, {
        projectMembers: action.data,
        isProjectMembersLoading: false,
      });
    default:
      return state;
  }
}
