'use strict';

import _ from 'lodash';
import { stakeholdersActions } from '../../constants/stakeholders.constants';
import { insideProjectActions } from '../../constants/insideProject.constants';
import { badgeLabels } from '../../constants/projects.constants';
import { request } from '../../utils/request';

export const requestFetchingStakeholders = () => ({
  type: stakeholdersActions.REQUEST_FETCHING_STAKEHOLDERS,
});

export const requestModifyingStakeholders = () => ({
  type: stakeholdersActions.REQUEST_MODIFYING_STAKEHOLDERS,
});

export const sendingRequest = () => ({
  type: stakeholdersActions.SENDING_REQUEST,
});

export const setRequestError = () => ({
  type: stakeholdersActions.SET_REQUEST_ERROR,
});

export const clearRequestError = () => ({ type: stakeholdersActions.CLEAR_REQUEST_ERROR });

export const restoreStakeholder = (idApproval, id) => (dispatch) => {
  request.post(`/approvals/${idApproval}/stakeholders/${id}/restore`);
  dispatch({
    type: stakeholdersActions.RESTORE_STAKEHOLDER,
    id,
  });
};

export const countTimeToRemoveStakeholder = (id) => ({
  type: stakeholdersActions.COUNT_TIME_TO_REMOVE_STAKEHOLDER,
  id,
});

export const updateStakeholder = (idApproval, id, data) => (dispatch) => {
  dispatch(requestModifyingStakeholders());
  request.put(`/approvals/${idApproval}/stakeholders/${id}`, {
    body: _.pickBy(data, (value) => value !== ''),
  })
    .then((response) => {
      dispatch({
        type: stakeholdersActions.ASSIGN_STAKEHOLDER_DATA,
        id,
        data: response,
      });
    })
    .catch(() => {
      dispatch({
        type: stakeholdersActions.SET_ERROR,
      });
    });
};

export const clearStakeholders = () => ({
  type: stakeholdersActions.CLEAR_STAKEHOLDERS,
});

export const startRemovingStakeholder = (idApproval, id) => (dispatch) => {
  request.delete(`/approvals/${idApproval}/stakeholders/${id}`);
  dispatch({ type: stakeholdersActions.MARK_STAKEHOLDER_AS_REMOVED, id });
};

export const removeStakeholder = (id) => ({ type: stakeholdersActions.REMOVE_STAKEHOLDER, id });

export const clearError = () => ({ type: stakeholdersActions.CLEAR_ERROR });

export const closeEditModal = () => ({ type: stakeholdersActions.CLOSE_EDIT_MODAL });

const modifyStatus = (status) => {
  let newStatus = status.toLowerCase();
  newStatus = newStatus === badgeLabels.inactive ? 'None' : newStatus;
  newStatus = newStatus.charAt(0).toUpperCase() + newStatus.slice(1);

  return newStatus;
};

export const sendRequest = (idApproval, idRequest, data) => (dispatch) => {
  dispatch(sendingRequest());

  request.post(`/approvals/${idApproval}/requests/${idRequest}/send`, {
    body: {
      body: data.body,
      url: data.url,
      urlHash: data.urlHash,
    },
  })
    .then(() => {
      request.get(`/approvals/${idApproval}`)
        .then((response) => {
          dispatch({
            type: stakeholdersActions.FETCH_STAKEHOLDERS,
            stakeholders: response.stakeholders,
          });
          dispatch({
            type: stakeholdersActions.SEND_REQUEST,
          });
        });
    })
    .catch(() => {
      dispatch({
        type: stakeholdersActions.SET_REQUEST_ERROR,
      });
    });
};

export const restartRequest = (idApproval, idRequest, data, approvalUrl) => (dispatch) => {
  let newRequestId;
  let newRequestUrl;
  let newUrlHash;
  const newMessageBody = data.body;

  dispatch(sendingRequest());

  request.post(`/approvals/${idApproval}/requests/${idRequest}/restart`, {
    body: {
      urlHash: data.urlHash,
    } })
    .then((response) => {
      newRequestUrl = [approvalUrl, '/', response.urlHash].join('');
      newRequestId = response.id;
      newUrlHash = response.urlHash;
    })
    .then(() => {
      // eslint-disable-next-line max-len
      dispatch(sendRequest(idApproval, newRequestId, {
        body: newMessageBody,
        url: newRequestUrl,
        currentStatus: data.currentStatus,
        urlHash: newUrlHash,
      }));
    })
    .catch(() => {
      dispatch({
        type: stakeholdersActions.SET_REQUEST_ERROR,
      });
    });
};

export const addStakeholder = (idApproval, data) => (dispatch) => {
  dispatch(requestModifyingStakeholders());

  request.post(`/approvals/${idApproval}/stakeholders`, {
    body: [_.pickBy(data, (value) => value !== '')],
  }).then(() => {
    request.get(`/approvals/${idApproval}`)
      .then((response) => {
        dispatch({
          type: stakeholdersActions.FETCH_STAKEHOLDERS,
          stakeholders: response.stakeholders,
        });
        dispatch(closeEditModal());
      });
  }).catch(() => {
    dispatch({
      type: stakeholdersActions.SET_ERROR,
    });
  });
};

export const fetchStakeholders = (idDocument, idApproval) => (dispatch) => {
  dispatch(requestFetchingStakeholders());

  const promise = new Promise((resolve, reject) => {
    if (!idApproval) {
      request.post('/approvals', {
        body: { idDocument },
      }).then((data) => {
        dispatch({
          type: insideProjectActions.SET_DOCUMENT_APPROVAL,
          idDocument,
          idApproval: data.idApproval,
        });
        resolve(data);
      }).catch((e) => { reject(e); });
    } else {
      resolve({ idApproval });
    }
  });

  promise.then((data) => request.get(`/approvals/${data.idApproval}`)).then((data) => {
    dispatch({
      type: stakeholdersActions.FETCH_STAKEHOLDERS,
      stakeholders: data.stakeholders,
    });
  });
};

export const restartApproval = (idDocument, idApproval) => (dispatch) => {
  dispatch(sendingRequest());

  request.post(`/approvals/${idApproval}/restart`)
    .then(() => {
      dispatch({
        type: stakeholdersActions.SEND_REQUEST,
      });
      dispatch(fetchStakeholders(idDocument, idApproval));
    })
    .catch(() => {
      dispatch(setRequestError());
    });
};
